<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!districtId">Add </span>
                <span *ngIf="districtId">Edit </span> District
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/listStateData']">Manage District List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!districtId">Add </span>
                    <span *ngIf="districtId">Edit </span> District
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">Country </label>
                        <div class="col-sm-12 ">
                            <!-- <mat-select type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="Enter  Country  " name="locationCountry"
                                formControlName="locationCountry" required>
                                <mat-option value="India">India</mat-option>
                            </mat-select> -->
                            <mat-select  placeholder="Select Country"
                                formControlName="countryId" class="form-control" required countrySelect
                                (openedChange)="myInputCountry.focus();"
                                (closed)="myInputCountry.value='';countrySearch(myInputCountry.value)">
                                <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control"
                                    #myInputCountry focused="'true'" type="text" 
                                    autocomplete="off"  (keydown)="$event.stopPropagation()">
                                <mat-option *ngFor="let data of countryList" [value]="data.countryId">{{data.countryName}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('countryId').valid && formGroup.get('countryId').touched">
                                    Please Enter Country</span>
                            </mat-error>
                        </div>
                    </div>
  
                    <div class="form-group col-sm-6 row">
                      <label class="col-sm-12">Zone </label>
                      <div class="col-sm-12 ">
                          <!-- <mat-select type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                              placeholder="Enter  Country  " name="locationCountry"
                              formControlName="locationCountry" required>
                              <mat-option value="India">India</mat-option>
                          </mat-select> -->
                          <mat-select  placeholder="Select Zone"
                              formControlName="zoneId" class="form-control" required zoneSelect
                              (openedChange)="myInputZone.focus();"
                              (closed)="myInputZone.value='';countrySearch(myInputZone.value)">
                              <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control"
                                  #myInputZone focused="'true'" type="text" 
                                  autocomplete="off"  (keydown)="$event.stopPropagation()">
                              <mat-option *ngFor="let data of zoneList" [value]="data.zoneId">{{data.zoneName}}
                              </mat-option>
                          </mat-select>
                          <mat-error>
                              <span
                                  *ngIf="!formGroup.get('zoneId').valid && formGroup.get('zoneId').touched">
                                  Please Enter Zone</span>
                          </mat-error>
                      </div>
                  </div>
                  
                  <div class="form-group col-sm-6 row">
                    <label class="col-sm-12">State </label>
                    <div class="col-sm-12 ">
                        <!-- <mat-select type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                            placeholder="Enter  Country  " name="locationCountry"
                            formControlName="locationCountry" required>
                            <mat-option value="India">India</mat-option>
                        </mat-select> -->
                        <mat-select  placeholder="Select State"
                            formControlName="stateId" class="form-control" required zoneSelect
                            (openedChange)="myInputState.focus();"
                            (closed)="myInputState.value='';countrySearch(myInputState.value)">
                            <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control"
                                #myInputState focused="'true'" type="text" 
                                autocomplete="off"  (keydown)="$event.stopPropagation()">
                            <mat-option *ngFor="let data of stateList" [value]="data.stateId">{{data.stateName}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            <span
                                *ngIf="!formGroup.get('stateId').valid && formGroup.get('stateId').touched">
                                Please Enter State</span>
                        </mat-error>
                    </div>
                </div>

                

                   <div class="form-group row col-sm-6">
                        <label class="col-sm-12">District Name </label>
                        <div class="col-sm-12 ">
                            <input type="text"  class="form-control" value=""
                                placeholder="Enter District Name" name="districtName" formControlName="districtName" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('districtName').valid && formGroup.get('districtName').touched">
                                    Please Enter District Name</span>
                            </mat-error>
                        </div>
                    </div> 
                    <!-- <div class="form-group row col-sm-6">
                      <label class="col-sm-12">State Code </label>
                      <div class="col-sm-12 ">
                          <input type="text"  class="form-control" value=""
                              placeholder="Enter State Code" name="stateCode" formControlName="stateCode" required>
                          <mat-error>
                              <span *ngIf="!formGroup.get('stateCode').valid && formGroup.get('stateCode').touched">
                                  Please Enter State Code</span>
                          </mat-error>
                      </div>
                  </div>  -->
                </div>
                <div class="">
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                        *ngIf="!districtId">SUBMIT</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                        *ngIf="districtId">UPDATE</button>
                </div>
            </form>
        </div>
    </div>
  </div>