import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../service/language/language.service';
import { MasterDataService } from '../../../service/masterData/master-data.service';
import { DynamicLabelClass } from '../../../helper/userLevelLink';

@Component({
  selector: 'app-add-update-blog',
  templateUrl: './add-update-blog.component.html',
  styleUrls: ['./add-update-blog.component.scss']
})
export class AddUpdateBlogComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;

  viewID: any;
  fileData: File = null;
  testImgURL: any;
  questionImage: any;
  testImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList: Array<any> = [];
  jobplanLst: Array<any> = [];
  page: number = 0;
  size: number = 500;
  qustGropList: Array<any> = [];
  qustGropList1: Array<any> = [];
  qGroup: any;
  qustTypeList: Array<any> = [];
  qustTypeList1: Array<any> = [];
  qType: any;
  arrayOptions: Array<any> = [];
  oprincustome: Array<any> = [];
  resultKewords: Array<any> = [];
  pdata: any;
  seletedOption: number = 0;
  myModel: any;
  config: any;
  addnewOption: number = 2;
  submitted = false;
  addBtnHide: boolean = false;
  selectedAreas: Array<any> = [];
  CorrecTanswerNotSlect = false;
  CorrectAnserArray = [false, false, false, false, false];
  typeSelect: boolean;
  lang: any;
  keywaordList: any;
  keywaordList1: any;
  allKeywords: any;
  allComplete: boolean = false;
  menus: any;
  mrunal = [];
  keyWordListShow = false;
  capDivVal: boolean = false;
  jobDivVal: boolean = false;
  laguageName: any;
  options: any;
  jobplanList: any;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  mediaUrl: any;
  allMediaType: any;
  adminMediaId: any;
  mediaTitle: any;
  mediaType: any;
  redirectUrl: any;
  media: any;
  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.viewID = this.route.snapshot.params['id'];
    this.adminMediaId = this.route.snapshot.params['id'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.allMediaType = [
      { value: 'BLOG', name: 'BLOG' },
      { value: 'MEDIA', name: 'MEDIA' },
      { value: 'NEWS_EVENT', name: 'News & Events' }
    ];
    this.initializeForm();
    this.arrayOptions = [];
  }

  search(query: string): any {
    const result = this.select(query.toLowerCase());
    if (query == '') {
      this.qustGropList = this.selectedAreas;
    } else {
      this.qustGropList = result;
    }
  }
  select(query: string): string[] {
    const result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      blogTitle: ['', Validators.required],
      heading: ['', Validators.required],
      subHeading: ['', Validators.required],
      description:['', Validators.required],
      media: [''],
    });

    if (this.viewID) {
      this.patchData();
    }
  }
  get f() {
    return this.formGroup.controls;
  }
  get t() {
    return this.f.options as FormArray;
  }

  removeOption(i): any {
    this.formGroup.removeControl(i);
  }

  // get data from jobCategoryId
  competancySelect: boolean;
  keywordds: any;
  keywordBtnHide: boolean = false;
  iskeyWord: any;
  keywordAllBtnHide: boolean = false;
  radioValue: number;

  patchData(): any {
    this.masterDataservice.getBlogMediaById(this.viewID).subscribe(
      (res) => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          (this.viewID = this.pdata.response.blogId),
            (this.countryFlagImgURL = this.pdata.response.file);
          this.formGroup.patchValue({
            blogId: this.pdata.response.blogId,
            blogTitle: this.pdata.response.blogTitle,
            heading: this.pdata.response.heading,
            subHeading: this.pdata.response.subHeading,
            description:this.pdata.response.description,
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        }
      },
      (error) => console.log(error)
    );
  }

  onSubmit(): any {
    console.log(this.formGroup.value);
    var id:any = 0;
    if (this.formGroup.valid) {
      const formData: FormData = new FormData();
      formData.append('blogId', id);
      formData.append('blogTitle', this.formGroup.value.blogTitle);
      formData.append('heading', this.formGroup.value.heading);
      formData.append('subHeading', this.formGroup.value.subHeading);
      formData.append('description', this.formGroup.value.description);
      if (this.fileData) {
        formData.append('file', this.fileData);
      }
      this.masterDataservice.addUpdateBlogMedia(formData).subscribe((res: any) => {
        if (res.status == 0) {
          this.snackBar.open('Blog Upload Successfully !!', '', {
            duration: 3000,
            panelClass: ['success-snackbar'],
          });
          this.router.navigate(['/blog-list']);
        } else {
          this.snackBar.open(res.response, '', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        }
      },
      (err) => {
          this.snackBar.open(err.error.response,'',{
          duration: 3000,
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
          panelClass: ['error-snackbar']
          });
        });
    } else {
      this.snackBar.open('Please fill all details.', '', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }
  updateMedia(): any {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      if (this.viewID) {
        const formData: FormData = new FormData();
        formData.append('blogId', this.viewID);
        formData.append('blogTitle', this.formGroup.value.blogTitle);
        formData.append('heading', this.formGroup.value.heading);
        formData.append('subHeading', this.formGroup.value.subHeading);
        formData.append('description', this.formGroup.value.description);
        if (this.fileData) {
          formData.append('file', this.fileData);
        }
        this.masterDataservice.addUpdateBlogMedia(formData).subscribe((res: any) => {
          if (res.status == 0) {
            this.snackBar.open('Blog Update Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
            this.router.navigate(['/blog-list']);
          } else {
            this.snackBar.open(res.response.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          }
        });
      }
    } else {
      this.snackBar.open('Please fill all details.', '', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  firstSpaceRemoveValid(e): any {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

  countryFlagReset() {
    this.countryFlagImgURL = '';
    this.myInputVariable.nativeElement.value = '';
  }
  countryFlagSelct(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };
    }
    this.newimgeuplod = true;
  }
}