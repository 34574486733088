import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonFucntionService {

  constructor() { }

  public removeHtmlTag(html) {
    let args = ['b', 'i', 'u', 'ul', 'li', 'br', 'ol', 'div', 'span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7'];
    return html.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
      return args.includes(tag) ? '<' + endMark + tag + '>' : '';
    }).replace(/<!--.*?-->/g, '');
  }

  public removeAttributeFromHtml(e) {

    // console.log(this.strip_tags(e.target.innerHTML, 'b', 'i', 'ul' , 'li'))
    // return this.strip_tags(e.target.innerHTML, 'b', 'i', 'ul' , 'li')
    document.querySelectorAll('.mrin .angular-editor-textarea font').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('face');
      item.removeAttribute('color');

    })
    document.querySelectorAll('.mrin .angular-editor-textarea div').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea table').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    });
    document.querySelectorAll('.mrin .angular-editor-textarea tr').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea td').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea p').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea span').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');

    })
    document.querySelectorAll('.mrin .angular-editor-textarea img').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('src');
      item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea mat-checkbox').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea mat-form-field').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea input').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea select').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea button').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea label').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea a').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('href');
      item.remove();
    });
    document.querySelectorAll('.mrin .angular-editor-textarea svg').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();
    });

    document.querySelectorAll(".mrin .angular-editor-textarea div, .mrin .angular-editor-textarea label, .mrin .angular-editor-textarea a ,.mrin .angular-editor-textarea button ,.mrin .angular-editor-textarea select , .mrin .angular-editor-textarea input , .mrin .angular-editor-textarea mat-checkbox , .mrin .angular-editor-textarea span ").forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    return
  }
  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

