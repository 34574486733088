import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';
import { CandidateService } from 'src/app/service/candidateDetails/candidate.service';
import { LanguageService } from 'src/app/service/language/language.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-contact-view-list',
  templateUrl: './contact-view-list.component.html',
  styleUrls: ['./contact-view-list.component.scss']
})
export class ContactViewListComponent implements OnInit {
  dataSource = new MatTableDataSource();
  allCandidateDetails: any;
  firstName: any;
  lastName: any;
  name: any;
  mobileNumber: any;
  email: any;
  requestType: any;
  submittedDate: any;
  userRoleType: any;
  source: any;
  message: any;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public langService : LanguageService,
              private candidateService: CandidateService,
              public dialog: MatDialog,
              private router: Router) { }
  expandedElement: (query: string) => void;
  viewID: any;
  data: any;
  pdata: any;
  submitted = false;



  ngOnInit(): void {  
    this.viewID = this.route.snapshot.params['id'];  
    if (this.viewID){
      this.patchData();
    }
   
  }



  // get data from id
  patchData(){
    this.candidateService.getContactDetailsById(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0 ){
          this.allCandidateDetails  = this.pdata.response;
          this.firstName = this.pdata.response.firstName;
          this.lastName = this.pdata.response.lastName; 
          this.name = this.pdata.response.name;   
          this.email = this.pdata.response.email;
          this.mobileNumber = this.pdata.response.mobileNumber;
          this.requestType = this.pdata.response.requestType;
          this.userRoleType = this.pdata.response.userRoleType;
          this.submittedDate = this.pdata.response.submittedDate;
          this.source = this.pdata.response.source;
          this.message = this.pdata.response.message;

          // this.dataSource = new MatTableDataSource(this.data.response.dataSet);
          // this.dataSource.sort = this.sort;
         // this.address = "";
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));

  }
  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
  }
  
}
