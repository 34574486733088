<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;" >
   <div class="page-header px-0">
     <h3 class="page-title"><span  *ngIf="!roleId">Add </span>
       <span  *ngIf="roleId">Edit </span> Role & Access</h3>
     <ol class="breadcrumb mb-0">
       <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
       <li class="breadcrumb-item " [routerLink]="['/rollList']" >Manage Role & Access</li>
       <li class="breadcrumb-item active" >
         <span  *ngIf="!roleId">Add </span>
         <span  *ngIf="roleId">Edit </span>
         Role & Access </li>
     </ol>
   </div>
 </div>
 <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="row">
        <div class="form-group row col-sm-6">
          <label class="col-sm-12">Role Name </label>
          <div class="col-sm-12">
              <input class="form-control"
               type="text"
               (keydown)="firstSpaceRemoveValid($event)"
               value="" placeholder="Enter role name" name="roleName"
                     formControlName="roleName" required>
            <mat-error>
                 <span *ngIf="!formGroup.get('roleName').valid && formGroup.get('roleName').touched">
                                Please enter role name</span>
            </mat-error>
          </div>
        </div>

        <div class="form-group row col-sm-6">
          <label class="col-sm-12">Role Description </label>
          <div class="col-sm-12 ">
              <input
              class="form-control"
              type="text"
              (keydown)="firstSpaceRemoveValid($event)"
              value="" placeholder="Enter role description" name="roleDescription"
                     formControlName="roleDescription" required>
            <mat-error>
                 <span *ngIf="!formGroup.get('roleDescription').valid && formGroup.get('roleDescription').touched">
                                Please enter role description</span>
            </mat-error>
          </div>
        </div>

        <div class="form-group row col-sm-12">
          <h3 class="col-sm-12">Role Access </h3>
          <div class="col-sm-12">
            <div >
              <h4>Dashboard</h4>
              <div *ngFor="let d of dashboardlist; let i=index" class="col-sm-4">
                <label class="custom_checkbox">
                  <input type="checkbox" [value]="d.value"
                         [checked] = "menus.indexOf(d.value) >= 0"
                         (change)="onCheckboxChange($event,i)" />
                  {{d.label}}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div >
          </div>

          <div class="col-sm-12">
            <h4>Manage Teams</h4>
            <div class="row">
              <div *ngFor="let d of manageTeam; let i=index" class="col-sm-4">
                <label class="custom_checkbox">
                  <input type="checkbox" [value]="d.value"
                         [checked] = "menus.indexOf(d.value) >= 0"
                         (change)="onCheckboxChange($event,i)" />
                  {{d.label}}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div >
          </div>

          <div class="col-sm-12">
              <h4>Manage System Setting</h4>
              <div class="row" >
              <div *ngFor="let d of manageRoleSetting; let i=index" class="col-sm-4">
                <label class="custom_checkbox">
                  <input type="checkbox" [value]="d.value"
                         [checked] = "menus.indexOf(d.value) >= 0"
                         (change)="onCheckboxChange($event,i)" />
                  {{d.label}}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div >
          </div>

          <div class="col-sm-12">
            <h4>Master Data</h4>
            <div class="row" >
              <div *ngFor="let d of manageMaster; let i=index" class="col-sm-4">
                <label class="custom_checkbox">
                  <input type="checkbox" [value]="d.value"
                         [checked] = "menus.indexOf(d.value) >= 0"
                         (change)="onCheckboxChange($event,i)" />
                  {{d.label}}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div >
          </div>

          <div class="col-sm-12">
            <h4>Personality Test</h4>
            <div class="row">
              <div *ngFor="let d of managePersonalityTest; let i=index" class="col-sm-4">
                <label class="custom_checkbox">
                  <input type="checkbox" [value]="d.value"
                         [checked] = "menus.indexOf(d.value) >= 0"
                         (change)="onCheckboxChange($event,i)" />
                  {{d.label}}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div >
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <button type="submit" class="btn btn-danger pd-x-20 mg-t-10 mar15" routerLink="/rollList" tabindex="0">Back</button>
        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!roleId">Submit</button>
        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="roleId">Update</button>
      </div>

      </form>




    </div>
  </div>

</div>
