import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { MemberServiceService } from '../../servicesMember/member-service.service';

@Component({
  selector: 'app-add-edit-discount',
  templateUrl: './add-edit-discount.component.html',
  styleUrls: ['./add-edit-discount.component.scss']
})
export class AddEditDiscountComponent implements OnInit {

  id: any;
  fDate: any;
  startdateVlaidtion: any;
  expMinDateFlag: boolean = false;
  startDateMinDateFlag: boolean = false;
  today = new Date();
  showCompanyList: boolean = false;
  companyList = [];
  countryList = [];

  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public memberShipService: MemberServiceService,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private masterDataservice: MasterDataService,
    private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  config: any;



  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.initializeForm();
    this.getAllCompanyList();
    this.fDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.countryListData();
  }


  // To initialize Form
  initializeForm() {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.formGroup = this.fb.group({
      couponCode: ['', Validators.required],
      couponDescription: ['', Validators.required],
      timesCanbeUsed: ['', Validators.required],
      couponValue: ['', Validators.required],
      couponType: ['', Validators.required],
      usedCoupons: ['', Validators.required],
      startDate: ['', Validators.required],
      autoExpiryDate: ['', Validators.required],
      companyAppliesTo: [],
      appliesTo: ['ALL', Validators.required],
      couponAppliesTo: ['MEMBERSHIP', Validators.required],
      countryAppliesTo: ['']
    });
    if (this.id) {
      this.patchData();
    }
  }
  patchData() {
    this.memberShipService.getCouponById(this.id).subscribe((res: any) => {
      if (res.status == 0) {
        this.formGroup.patchValue({
          couponCode: res.response.couponCode,
          couponDescription: res.response.couponDescription,
          timesCanbeUsed: res.response.timesCanbeUsed,
          couponValue: res.response.couponValue,
          couponType: res.response.couponType,
          usedCoupons: res.response.usedCoupons,
          appliesTo: res.response.appliesTo,
          couponAppliesTo: res.response.couponAppliesTo,
          startDate: this.datepipe.transform(res.response.startDate, 'yyyy-MM-dd'),
          autoExpiryDate: this.datepipe.transform(res.response.autoExpiryDate, 'yyyy-MM-dd'),
        })
        if (res.response.companyAppliesTo) {
          var arr = res.response.companyAppliesTo.split(',');
          for (let a in arr) { arr[a] = parseInt(arr[a]); }
          this.formGroup.patchValue({ companyAppliesTo: arr });
        }
        if (res.response.countryAppliesTo) {
          var arr2 = res.response.countryAppliesTo.split(',');
          for (let a in arr2) { arr2[a] = parseInt(arr2[a]); }
          this.formGroup.patchValue({ countryAppliesTo: arr2 });
        }
        if (res.response.appliesTo == 'INDIVIDUAL') {
          this.showCompanyList = true;
        } else {
          this.showCompanyList = false;
        }
      } else {
        this.snackBar.open(res.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    }, (error: any) => {
      this.snackBar.open(error.error.response, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }
  countryListData() {
    this.masterDataservice.getAllCountryNew().subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.countryList = this.data.response;
      }
    })

  }
  applyForChange(e) {
    console.log(e);
    if (e.value == 'INDIVIDUAL') {
      this.showCompanyList = true;
    } else {
      this.showCompanyList = false;
    }
  }
  getAllCompanyList() {
    this.masterDataservice.getAllUserList(0, 5000).subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.companyList = this.data.response.dataSet.filter((d) => {
          if (d.userRoleType == 'RECRUITER' && d.companyName != null) {
            return d;
          }
        });

      }

    });
  }

  onSubmit() {
    console.log(this.formGroup)
    if(this.showCompanyList == true){
      this.formGroup.controls.countryAppliesTo.setValidators(null);
      this.formGroup.controls.countryAppliesTo.updateValueAndValidity();
      this.formGroup.get('countryAppliesTo').clearValidators();
    } else {
      this.formGroup.controls.companyAppliesTo.setValidators(null);
      this.formGroup.controls.companyAppliesTo.updateValueAndValidity();
      this.formGroup.get('companyAppliesTo').clearValidators();
    }

    if (this.formGroup.valid) {
      var payload = {
        "appliesTo": this.formGroup.value.appliesTo,
        "autoExpiryDate": new Date(this.formGroup.value.autoExpiryDate).getTime(),
        "couponCode": this.formGroup.value.couponCode,
        "couponDescription": this.formGroup.value.couponDescription,
        "couponId": this.id ? this.id : 0,
        "couponType": this.formGroup.value.couponType,
        "couponValue": this.formGroup.value.couponValue,
        "startDate": new Date(this.formGroup.value.startDate).getTime(),
        "status": "ACTIVE",
        "timesCanbeUsed": this.formGroup.value.timesCanbeUsed,
        "totalCoupons": this.formGroup.value.totalCoupons,
        "usedCoupons": this.formGroup.value.usedCoupons,
        "couponAppliesTo": this.formGroup.value.couponAppliesTo,
        "companyAppliesTo": this.showCompanyList == true ? (this.formGroup.value.companyAppliesTo).toString() : null,
        "countryAppliesTo": this.showCompanyList == false ? (this.formGroup.value.countryAppliesTo).toString() : null,
      }
      if (this.id) {
        this.memberShipService.editCoupon(payload).subscribe((data: any) => {
          if (data.status == 0) {
            this.snackBar.open('Updated Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listDiscount']);
          }
          else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      } else {
        this.memberShipService.addCoupon(payload).subscribe((data: any) => {
          if (data.status == 0) {
            this.snackBar.open('Added Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listDiscount']);
          }
          else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      }
    } else {
      this.formGroup.markAllAsTouched();
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  startDateValidtion(e): any {
    console.log(e.target.value);
    this.startdateVlaidtion = e.target.value;
    var dt = new Date(e.target.value);
    dt.setDate(dt.getDate() + 1);
    var changeDate = this.datepipe.transform(dt, 'yyyy-MM-dd');

    const d = this.datepipe.transform(this.today, 'yyyy-MM-dd');
    console.log(d);
    if (d >= changeDate) {
      this.expMinDateFlag = true;
    } else {
      this.expMinDateFlag = false;
    }

  }
  endDateValidtion(e) {
    var dt = new Date(e.target.value);
    dt.setDate(dt.getDate() + 1);
    var changeDate = this.datepipe.transform(dt, 'yyyy-MM-dd');

    const d = this.datepipe.transform(this.formGroup.controls.autoExpiryDate.value, 'yyyy-MM-dd');
    console.log(d);
    if (d >= changeDate) {
      this.startDateMinDateFlag = true;
    } else {
      this.startDateMinDateFlag = false;
    }
  }


}
