import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import { LanguageService } from 'src/app/service/language/language.service';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';

@Component({
  selector: 'app-add-update-skill-set',
  templateUrl: './add-update-skill-set.component.html',
  styleUrls: ['./add-update-skill-set.component.scss']
})
export class AddUpdateSkillSetComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  skillSetId: any;
  fileData: File = null;
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private formBuilder: FormBuilder,
              public langService :LanguageService,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  dynamicForm: FormGroup;
  LangList=[];
  config:any;
  

  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.skillSetId = this.route.snapshot.params['id'];
    this.dynamicForm = this.fb.group({
      multiLanguageNames: new FormArray([]),
    });
    if (this.skillSetId){
      this.patchSkillData();
    }
    this.LangList = JSON.parse(this.langService.getLngList());
    console.log(this.LangList);
    this.LangListLoad(this.LangList.length);

  }
  get t() { return  this.dynamicForm.controls.multiLanguageNames as FormArray; }

  LangListLoad(e) {
   const numberOfTickets = e
   if (this.t.length < numberOfTickets) {
       for (let i = this.t.length; i < numberOfTickets; i++) {
           this.t.push(this.formBuilder.group({
             languageId:[this.LangList[i].languageId],
             skillName: [ '' , Validators.required],
             skillDescription: [ '' , Validators.required],
           }));

       }
       // this.dynamicForm.patchValue(this.payloaddd);
   } else {
       for (let i = this.t.length; i >= numberOfTickets; i--) {
           this.t.removeAt(i);
       }
   }
}



  // get data from jobCategoryId
  patchSkillData(){
    this.masterService.getSkillSetById(this.skillSetId)
      .subscribe(res => {
        this.data = res;
        console.log(this.data.response);
        if(this.data.status == 0 ){
          this.dynamicForm.patchValue(this.data.response);
          this.dynamicForm.patchValue({
            multiLanguageNames:this.data.response,
          })
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.dynamicForm.valid) {
      if (this.skillSetId){
        this.masterService.updateSkillSet(this.skillSetId, this.dynamicForm.value)
          .subscribe(data => {
              this.snackBar.open('Skill Set Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listSkillSet']);
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{
        this.masterService.createSkills(this.dynamicForm.value).subscribe(() => {
          this.snackBar.open('Skill Set Inserted Successfully !!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/listSkillSet']);
        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
