import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MasterDataService } from "../../../../service/masterData/master-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "src/app/service/language/language.service";

@Component({
  selector: "app-add-update-education",
  templateUrl: "./add-update-education.component.html",
  styleUrls: ["./add-update-education.component.scss"],
})
export class AddUpdateEducationComponent implements OnInit {
  LangList = [];
  degreeId: number;
  dynamicForm: FormGroup;
  submitted = false;
  data: any;
  pdata: any;
  radioValue: number = 0;
  levelOfEducationList: string[];
  constructor(
    public langService: LanguageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.degreeId = this.route.snapshot.params["id"];
    this.dynamicForm = this.formBuilder.group({
      degreeName: ["", Validators.required],
      degreeId: [0],
      levelOfEducation: ['', Validators.required],
    });
   
    if (this.degreeId) {
      this.patchForm();
    }
    this.levelOfEducationList= ['SECONDARY','HIGH_SECONDARY','GRADUATION','POST_GRADUATIONJ','PHD', 'POST_DOCTORATE','DIPLOMA'];
    

  }
  get f() {
    return this.dynamicForm.controls;
  }

  patchForm() {
    this.masterService
      .getEducationAndDegreeById(this.degreeId)
      .subscribe((res) => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          var data = this.pdata.response;
          this.dynamicForm.patchValue({
            degreeName: data.degreeName,
           degreeId: data.degreeId
          });
          this.radioValue = data.value;
        } else {
          this.snackBar.open("response Status -1 ", "", {
            duration: 3000,
            panelClass: ["error-snackbar"],
          });
        }
      });
  }
  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.dynamicForm.valid) {
      // if (this.degreeId) {
      //   this.masterService
      //     .updateEducationAndDegree(this.degreeId, this.dynamicForm.value)
      //     .subscribe(
      //       (data) => {
      //         this.snackBar.open(
      //           "Education  Details Update Successfully !!",
      //           "",
      //           {
      //             duration: 3000,
      //             panelClass: ["success-snackbar"],
      //           }
      //         );
      //         this.router.navigate(["/listEducation"]);
      //       },
      //       (err) => {
      //         this.snackBar.open(err.error.message, "", {
      //           duration: 3000,
      //           verticalPosition: "top",
      //           horizontalPosition: "center",
      //           panelClass: ["error-snackbar"],
      //         });
      //       }
      //     );
      // }
      // else {
      // var paylode = {
      //   degreeName: this.dynamicForm.value.degreeName,
      //   degreeId: this.dynamicForm.value.degreeId
      // };
      this.masterService
        .createEducationAndDegree(this.dynamicForm.value)
        .subscribe((res) => {
          this.data = res;
          if (this.data.status == 0) {
            if (this.degreeId) {
              this.snackBar.open("Education  Inserted Successfully !!", "", {
                duration: 3000,
                panelClass: ["success-snackbar"],
              });
              this.router.navigate(["/listEducation"]);
            } else {
              this.snackBar.open(
                "Education  Details Update Successfully !!",
                "",
                {
                  duration: 3000,
                  panelClass: ["success-snackbar"],
                }
              );
              this.router.navigate(["/listEducation"]);
            }
          } else {
            this.snackBar.open(this.data.response, "", {
              duration: 3000,
              panelClass: ["error-snackbar"],
            });
          }
        });
      // }
    } else {
      this.snackBar.open("Please Enter All Details", "", {
        duration: 3000,
        panelClass: ["error-snackbar"],
      });
    }
  }
}
