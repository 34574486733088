import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { JobTestQuestionManageService } from 'src/app/service/jobPostTestandQuestion/job-test-question-manage.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

export interface DialogData {
  TestId: any;
  lang:any;
  title:any;
  jobCategory:any;
}


@Component({
  selector: 'app-copy-button-popup',
  templateUrl: './copy-button-popup.component.html',
  styleUrls: ['./copy-button-popup.component.scss']
})
export class CopyButtonPopupComponent implements OnInit {
  selectedAreas: any[] = [];
  jobCatList: any[] = [];
  qType: any;
  page: number = 0;
  size: number = 1000;
  Jcat: any;
  JobQuniError: boolean;
  selectJobCat: any;
  Ddata: any;
  mainJobCat = [];

  constructor(private formBuilder: FormBuilder,
              private masterDataservice: MasterDataService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private router: Router,
              private jobpostQuestionTestService : JobTestQuestionManageService,
              public dialogRef: MatDialogRef<CopyButtonPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.questionTypeList();
    console.log(this.data);
  }
  questionTypeList(): any{
    this.masterDataservice.getAllCategoryList(this.page, this.size, this.data.lang , true).subscribe(res => {
      this.qType = res;
      if ( this.qType.status == 0 ){
        this.jobCatList = this.qType.response.dataSet.filter((d) => {
          // && d.isTestCreated == false
          if (d.isSubCategory == true ) {
            return d;
          }
        });
        this.mainJobCat = this.jobCatList;
        this.selectedAreas = this.jobCatList;
        console.log(this.jobCatList);
      }
    });
  }
  search(query: string): any{
    console.log('query', query);
    let result = this.select(query.toLowerCase());
    if ( query == '' ){
      this.jobCatList = this.selectedAreas;
    } else {
      if (result.length == 0){
        this.jobCatList = this.mainJobCat;
      } else {
        this.jobCatList = result;
      }

    }
    console.log(this.jobCatList);
  }
  select(query: string): string[]{
    let result: string[] = [];
    for (let a of this.selectedAreas){
      if (a.displayName.toLowerCase().indexOf(query) > -1){
        result.push(a)
      }
    }

    console.log(result);
    return result
  }
  jobcatSelection(e): any{
    this.jobpostQuestionTestService.jobcatgorySelection(e.value, this.data.lang).subscribe( res => {
      this.Jcat = res;
      if (this.Jcat.status == 0){
        if (this.Jcat.response == true){
          this.JobQuniError = true;
          this.snackBar.open('You have already Created a Asssement Test in this category - You cant create More than one Test in One SubCategory', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        } else {
         this.selectJobCat = e.value;
         this.JobQuniError = false;
        }
      } else {
        this.JobQuniError = false;
        this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
      }

    });
  }

  submit(type): any{
    if (this.selectJobCat){
      const payload = {
        assesmentTestId: this.data.TestId,
        jobCategoryId: this.selectJobCat,
      };
      this.jobpostQuestionTestService.copyTest(payload).subscribe( (res:any) => {
        this.Ddata = res;
        if(res.status == 0){
          if (type == '1'){
            this.dialogRef.close(false);
            this.router.navigate(['/editJobTestCreation', res.response, '1']);
          } else {
            this.dialogRef.close(true);
          }
        } else {
          this.snackBar.open(res.response, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
       
      } , (error: any) =>{
        this.snackBar.open(error.error.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      } );
    }
  }

}
