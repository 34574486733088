import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-add-update-ptcategory',
  templateUrl: './add-update-ptcategory.component.html',
  styleUrls: ['./add-update-ptcategory.component.scss']
})
export class AddUpdatePTCategoryComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;
  personalityTestCategoryId: any;
  fileData: File = null;
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;

  ngOnInit(): void {
    this.personalityTestCategoryId = this.route.snapshot.params['id'];
    console.log(this.personalityTestCategoryId);
    this.initializeForm();
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      testCategoryName: [ '' , Validators.required],
      description: [ '' , Validators.required],
    });

    if (this.personalityTestCategoryId){
      this.patchTestCategoryData();
    }
  }

  // get data from jobCategoryId
  patchTestCategoryData(){
    this.masterService.getPersonalityTestCategoryById(this.personalityTestCategoryId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.formGroup.patchValue({
            testCategoryName: this.data.response.testCategoryName ,
            description: this.data.response.description ,
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const payload = {
        testCategoryName: this.formGroup.value.testCategoryName,
        description: this.formGroup.value.description,
      }
      if (this.personalityTestCategoryId){
        this.masterService.updatePersonalitytestCategory(this.personalityTestCategoryId, payload)
          .subscribe(data => {
              this.snackBar.open('Personality Test Category Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listPTcategory']);
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{

      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
