import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-common-alert-popup',
  templateUrl: './common-alert-popup.component.html',
  styleUrls: ['./common-alert-popup.component.scss']
})
export class CommonAlertPopupComponent implements OnInit {

  constructor( private snackBar: MatSnackBar,
               private route: ActivatedRoute,
               private router: Router,
               public dialogRef: MatDialogRef<CommonAlertPopupComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
