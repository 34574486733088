import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterDataService } from '../../../service/masterData/master-data.service';

@Component({
  selector: 'app-reject-job-popup',
  templateUrl: './reject-job-popup.component.html',
  styleUrls: ['./reject-job-popup.component.scss']
})
export class RejectJobPopupComponent implements OnInit {
  formGroup: FormGroup;
  submitted = false;

  constructor( public dialogRef: MatDialogRef<RejectJobPopupComponent>,
    private masterDataservice: MasterDataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log("popupdata",this.data);
    this.formGroup = this.formBuilder.group({
      comment: ['', Validators.required],
    
    })
  }

  submit():any {
    this.submitted = true;
    if (this.formGroup.valid) {  
       var payload = {
        "comment": this.formGroup.value.comment,
        "jobId": this.data.jobId,
        "status": "Rejected"
      }
      this.masterDataservice.getJobByActive(payload).subscribe((res: any) => {
        if (res.status === 0) {
          this.cancel();
          this.snackBar.open('Job Rejected Successfully!!', '', {
            duration: 3000,
            panelClass: ['success-snackbar'],
          });
          this.router.navigate(['/job-approval-list']);
        }
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
