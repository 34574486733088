import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';

@Component({
  selector: 'app-add-update-question-type',
  templateUrl: './add-update-question-type.component.html',
  styleUrls: ['./add-update-question-type.component.scss']
})
export class AddUpdateQuestionTypeComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;
  questionTypeId: any;
  fileData: File = null;

  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  config: any;

  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.questionTypeId = this.route.snapshot.params['id'];
    console.log(this.questionTypeId);
    this.initializeForm();
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      questionType: [ '' , Validators.required],
      typeDescription: [ '' , Validators.required],
    });

    if (this.questionTypeId){
      this.patchQuestionData();
    }
  }

  // get data from question type id
  patchQuestionData(){
    this.masterService.getQuestionTypeDetailsById(this.questionTypeId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.formGroup.patchValue({
            questionType: this.data.response.questionType ,
            typeDescription: this.data.response.typeDescription ,
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const payload = {
        questionType: this.formGroup.value.questionType,
        typeDescription: this.formGroup.value.typeDescription,
      }
      if (this.questionTypeId){
        this.masterService.updateQuestionTypeDeatils(this.questionTypeId, payload)
          .subscribe((data:any) => {
               if(data.status == 0)
               {
                 this.snackBar.open('Question Type Details Update Successfully !!', '', {
                   duration: 3000,
                   panelClass: ['success-snackbar']
                 });
                 this.router.navigate(['/listQuestionType']);
               }
               else {
                 this.snackBar.open(data.response, '', {
                   duration: 3000,
                   panelClass: ['error-snackbar']
                 });
               }

            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{
        this.masterService.createQuestionType(payload).subscribe((data:any) => {
            if(data.status == 0)
            {
              this.snackBar.open('Question Type Inserted Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listQuestionType']);
            }
            else {
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }

        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }
  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }
  onPaste(event: ClipboardEvent, formcontrolname) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    console.log(pastedText);
    event.preventDefault();
    this.formGroup.controls[formcontrolname].setValue(pastedText);
  }
}
