<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Manage Employer List</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active" [routerLink]="['/editTeam']">Manage Employer List</li>
        </ol>
    </div>
</div>
<!--Page Header-->
<!-- <div class="mt-2 cardNA" *ngIf="!dataSource4.data.length">
	<h5 class="mb-1">Data not Available</h5>
</div> -->
<form [formGroup]="searchForm">
    <div class="col-sm-12">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header class="border-bottom">
                    <mat-expansion-title>
                      <h5 class="page-title">Advanced Search</h5>
                    </mat-expansion-title>
                </mat-expansion-panel-header>
                <div class="row justify-content-start">
                  <div class="col-3 mt-4 text-left">
                    <div class="input-group">
                      <input class="form-control" formControlName="name" placeholder="Enter Name">
                    </div>
                  </div>
                  <div class="col-3 mt-4 text-left">
                    <div class="input-group">
                      <input class="form-control" formControlName="companyName" placeholder="Enter Company Name">
                    </div>
                  </div>
                  <div class="col-3 mt-4 text-left">
                    <div class="input-group">
                      <input class="form-control" formControlName="MobileNo" placeholder="Enter Mobile No.">
                    </div>
                  </div>
                  <div class="col-3 mt-4 text-left">
                    <div class="input-group">
                      <input class="form-control" formControlName="emailId" placeholder="Enter Email Id">
                    </div>
                  </div>
                  <div class="col-3 mt-4 text-left">
                    <div class="input-group">
                      <input class="form-control" formControlName="source" placeholder="Enter Source">
                    </div>
                  </div>
                  <div class="col-3 mt-4 text-left">
                    <div class="input-group">
                      <mat-select
                      placeholder="Search by District "
                      class="form-control"
                      formControlName="district"
                      (openedChange)="myInputDistrict.focus()"
                      (closed)="myInputDistrict.value = ''; stateSearch(myInputDistrict.value)">
                      <input
                        style="margin: 10px; width: 96%"
                        class="customeSearchDropdown myInput form-control"
                        #myInputDistrict
                        focused="'true'"
                        type="text"
                        (keyup)="districtSearch($event.target.value)"
                        autocomplete="off"
                        placeholder="search District"
                        (keydown)="$event.stopPropagation()"
                      />
                      <mat-option
                        *ngFor="let data of districtList"
                        [value]="data.name">
                        {{ data.name }}</mat-option>
                  </mat-select>
                    </div>
                  </div>
                  <div class="col-3 mt-4 text-left">
                    <div class="input-group">
                      <mat-select
                        placeholder="Search by State "
                        class="form-control"
                        formControlName="state"
                        (openedChange)="myInputState.focus()"
                        (closed)="myInputState.value = ''; stateSearch(myInputState.value)">
                        <input
                          style="margin: 10px; width: 96%"
                          class="customeSearchDropdown myInput form-control"
                          #myInputState
                          focused="'true'"
                          type="text"
                          (keyup)="stateSearch($event.target.value)"
                          autocomplete="off"
                          placeholder="search State"
                          (keydown)="$event.stopPropagation()"
                        />
                        <mat-option
                          *ngFor="let data of stateList"
                          [value]="data.stateId">
                          {{ data.stateName }}</mat-option>
                      </mat-select>
                    </div>
                  </div>
                  <div class="col-3 text-end mt-4" style="text-align: end;">
                    <button class="btn btn-danger mr-2" (click)="resetForm()">Reset</button>
                    <button class="btn btn-primary" (click)="tabelLoad(0)">Search</button>
                  </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="card mt-3">
            <div class="c_table">
                <div class="mat-elevation-z8">
                    <!-- <div class="col-6 mt-4">
                        <form [formGroup]="searchForm">
                            <div class="input-group">
    
                                <mat-select placeholder="Search by State "  class="form-control" formControlName="state" (openedChange)="myInputState.focus();" (closed)="myInputState.value='';stateSearch(myInputState.value)" style="margin: 10px;" (selectionChange)="tabelLoad(0)">
                                    <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control" #myInputState focused="'true'" type="text" (keyup)="stateSearch($event.target.value)" autocomplete="off" placeholder="search State" (keyup.enter)="tabelLoad(0)"
                                        (keydown)="$event.stopPropagation()">
                                    <mat-option *ngFor="let data of stateList" [value]="data.stateId">
                                        {{data.stateName}}</mat-option>
                                </mat-select>
    
    
                                 <span class="input-group-btn"><button class="btn btn-primary" (click)="tabelLoad(0)" style="margin: 10px;" >Search</button></span> 
                            </div>
                            <div class="d-flex" style="margin-left: auto;" >
    
                            
                                <div class="col-6" style="margin-left: 900px"  >
                                    <input  style="margin: 10px; width: 96%; "  class="form-control"   formControlName="name" (keyup)="tabelLoad(0)"placeholder="Search Employer">
                                </div>
                            </div>
                        </form>
                    </div> -->
                    <div class="col-12 mt-4">
                          <div class="row">
                            <div class="col-3 text-left">
                                <div class="input-group">
                                  <mat-select class="form-control" formControlName="downloadFile">
                                    <mat-option value="CSV">CSV</mat-option>
                                    <mat-option value="XLSX">XLSX</mat-option>
                                    <mat-option value="XLS">XLS</mat-option>
                                  </mat-select>
                                </div>
                              </div>
                              <div class="col-3 text-left">
                                <button class="btn btn-primary" (click)="downloadFile()">Download File</button>
                              </div>
                              <div class="col-3"></div>
                            <div class="col-3 text-end">
                              <input
                                style="margin: 10px; width: 96%"
                                class="form-control"
                                formControlName="name"
                                (keyup)="applyFilter($event.target.value)"
                                placeholder="Search Employee"
                              />
                            </div>
                          </div>
                        
                      </div>
                    <div class="d-flex table-responsive p-3">
                        <div class="mr-2">
    
                        </div>
                        <!-- <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                            <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                        </div> -->
                    </div>
                    <div class="d-flex table-responsive p-3">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="srno">
                                <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                                <td mat-cell *matCellDef="let element;let j = index" style="padding: 4px 3px !important;">
                                    {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                                </td>
                            </ng-container>
    
                            <div>
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
                                    <td mat-cell *matCellDef="let element">{{element.id}}</td>
                                </ng-container>
    
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> name</th> 
                                    <td mat-cell *matCellDef="let element">{{element.firstName | titlecase}} {{element.lastName | titlecase}} </td>
                                </ng-container>
                                <ng-container matColumnDef="companyName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="!element.companyName">NA</span>
                                        <span *ngIf="element.companyName">{{element.companyName}}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Email</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.email">{{element.email}}</span>
                                        <span *ngIf="!element.email">NA</span>
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="source">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Source</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="!element.source">-</span>
                                        <span *ngIf="element.source">{{element.source}}</span>
                                    </td>
                                </ng-container>
    
                                <ng-container matColumnDef="mobileNumber">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> mobile Number</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.mobileNumber">{{element.mobileNumber}}</span>
                                        <span *ngIf="!element.mobileNumber">NA</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="district">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                      District
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                      {{ element.district?element.district:'NA' }}
                                    </td>
                                  </ng-container>
                                <ng-container matColumnDef="stateName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> state</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.stateName">{{element.stateName}}</span>
                                        <span *ngIf="!element.stateName">NA</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="jobRole">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> User Role</th>
                                    <td mat-cell *matCellDef="let element">{{element.userRoleType}}</td>
                                </ng-container>
    
                                <ng-container matColumnDef="roleId">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span>roleId</span>
    
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.roleId}}</td>
                                </ng-container>
                                <ng-container matColumnDef="createddate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        <span>Created Date</span>
    
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.createdEpochTime | date:'medium'}}
                                    </td>
                                </ng-container>
    
                            </div>
    
                            <ng-container matColumnDef="status">
                                <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let element">
                                    <span>
                              <mat-slide-toggle name="status"  (change)="changeStatus($event,element)" [checked]="element.isActive"></mat-slide-toggle>
                              </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                                <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                                    <div class="d-flex justify-content-center">
                                        <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/edit-user', element.id]">
                                            <img src="../../../../assets/img/eye.svg" alt="eye">
                                        </span>
                                        <span class="btn btn-sm btn-danger rounded-lg" (click)="deleteUsers(element.id)"><i
                                        class="fas fa-trash"></i></span>
                                    </div>
    
    
                                </td>
                            </ng-container>
    
    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
    
                    </div>
                    <mat-paginator
                      #paginatorRef
                      [pageSize]="100"
                      (page)="onChangePage($event)"
                      showFirstLastButtons
                    ></mat-paginator>
    
                </div>
            </div>
        </div>
    </div>
</form>