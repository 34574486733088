import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterDataService } from '../../../../service/masterData/master-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/service/language/language.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';

@Component({
  selector: 'app-add-or-update-cometency',
  templateUrl: './add-or-update-cometency.component.html',
  styleUrls: ['./add-or-update-cometency.component.scss']
})
export class AddOrUpdateCometencyComponent implements OnInit {
  viewId: any;
  categoryList: Array<any[]>;
  private roleData: any;
  categoryList1: [];
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList = [];
  dynamicForm: FormGroup;
  submitted = false;
  pdata: any;
  apps = [];
  appCtrl: FormControl;
  filterArrayLength = 21;
  SelectAutoSelectID: any;
  slecteddo: any;
  mainCategoryList: any;
  config: any;

  constructor(public langService: LanguageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router) {
    this.appCtrl = new FormControl();
  }

  ngOnInit(): any {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.viewId = this.route.snapshot.params['id'];
    this.getAllCategoryList();
    this.dynamicForm = this.formBuilder.group({
      parentId: ['0'],
      //keywordName: ['', Validators.required],
      multiLanguageNames: new FormArray([]),

    });
    this.LangList = JSON.parse(this.langService.getLngList());
    console.log(this.LangList);
    this.LangListLoad(this.LangList.length);
    
    // for (let i = 0; i < this.payloaddd.multiLanguageNames.length; i++) {
    //     this.dynamicForm.patchValue(this.payloaddd.multiLanguageNames);
    //  }
  }

  // convenience getters for easy access to form fields
  get f(): any { return this.dynamicForm.controls; }
  get t(): any {
    //  console.log(this.f.multiLanguageNames);
    return this.f.multiLanguageNames as FormArray;
  }

  patchForm(): any {
    this.masterService.CompetecyCategoryById(this.viewId).subscribe(res => {
      this.pdata = res;
      if (this.pdata.status == 0) {
        console.log(this.pdata)
        const patchsortdat = this.pdata.response.multiLanguageNames.sort((a, b) => (a.languageId - b.languageId))
        this.dynamicForm.patchValue({
          parentId: this.pdata.response.parentId == this.pdata.response.competencyCategoryId ? 0 : this.pdata.response.parentId,
          multiLanguageNames: patchsortdat,
        });
        console.log(this.dynamicForm.value)
        // this.dynamicForm.patchValue(this.pdata.response);
        // this.SelectAutoSelectID = this.pdata.response.parentName;
        // this.dynamicForm.controls['appCtrl'].patchValue(this.pdata.response.parentName);
      }
    });
    // this.langService.getJobCategoryByIdForEdit(this.viewId).subscribe(res=>{
    //   this.pdata = res;
    // if(this.pdata.status == 0){
    //   for(let b of this.apps){
    //     if(b.parentId == this.pdata.response.parentId){
    //       this.appCtrl.patchValue(b.displayName);
    //     }
    //   }
    //   //  this.appCtrl.patchValue(this.pdata.response.parentName);

    //   // this.dynamicForm.controls.appCtrl.setValue(this.settingDetail.defaultDealerId);
    //   // if(this.pdata.response.parentId != 0){

    //   // }

    //   // this.dynamicForm.patchValue(this.pdata.response);
    //   // this.parentDropShow = true;
    //   // this.isActive = true;
    // } else {
    //   this.snackBar.open('response Status -1 ', '', {
    //     duration: 3000,
    //     panelClass: ['error-snackbar']
    //   });
    // }
    // });
  }

  LangListLoad(e): any {
    const numberOfTickets = e;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.formBuilder.group({
          languageId: [this.LangList[i].languageId],
          name: ['', Validators.required],
          descriptionName: ['', Validators.required],
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }
  getAllCategoryList(): any {
    let dd = [];
    this.masterService.getAllCompetencyCategory(0, 100, 'en').subscribe(res => {
      this.roleData = res;
      if (this.roleData.status == 0) {
        console.log()
        dd = this.roleData.response.dataSet.filter((d) => {
          console.log(d.isSubCategory);
          if (d.isSubCategory == false) {
            return d;
          }
        });
        this.mainCategoryList = dd;
        this.apps = dd;
        this.categoryList = dd;
        console.log(this.categoryList);
        if (this.viewId) {
          this.patchForm();
        }
        // this.categoryList = this.appCtrl.valueChanges
        // .pipe(
        //   startWith(''),
        //   map(app => app ? this.filterApps(app) : this.apps.slice())
        // );
        // this.categoryList = this.roleData.response.dataSet;
        // console.log(this.categoryList);
      } else {
        this.snackBar.open('response Status -1 ', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    })
  }
  // parentSubSelection(e){
  //   console.log(e);
  //   const d = e.checked;
  //   if(d == true){
  //     this.parentDropShow = true;
  //   } else {
  //     this.parentDropShow = false;
  //   }
  // }

  search(query: string): any {
    console.log('query', query)
    let result = this.select(query.toLowerCase());
    if (query == '') {
      this.categoryList = this.mainCategoryList;
    } else {
      this.categoryList = result;
    }
  }
  select(query: string): any[] {
    let result: any[] = [];
    for (let a of this.mainCategoryList) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
  // searchQuestionGrop(query: string, index){
  //   console.log('query', query)
  //   let result = this.QuestionFromselect(query, index);
  //   if ( query == ''){
  //     this.qustGropList[index] = this.questionAreas[index];
  //   } else {
  //     this.qustGropList[index] = result;
  //   }
  // }

  onSubmit(): any {
    this.submitted = true;
    console.log(this.dynamicForm.valid);
    console.log(JSON.stringify(this.dynamicForm.value, null, 4));
    this.dynamicForm.value.multiLanguageNames.sort((a, b) => (a.languageId - b.languageId));
    // this.dynamicForm.controls['parentId'].setValue(this.SelectAutoSelectID);
    if (this.dynamicForm.valid) {
      if (this.viewId) {
        this.masterService.updateCompetencyCategory(this.viewId, this.dynamicForm.value)
          .subscribe((data: any) => {
            if (data.status == 0) {
              this.snackBar.open('Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listCompetencyCategory']);
            }
            else {
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }

          },
            (err) => {
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else {
        this.masterService.addCompetencyCategory(this.dynamicForm.value).subscribe(res => {
          this.data = res;
          if (this.data.status == 0) {
            this.snackBar.open('Inserted Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listCompetencyCategory']);
          }
          else {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

    console.log(JSON.stringify(this.dynamicForm.value, null, 4))
    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.dynamicForm.value, null, 4));
  }

  onReset(): any {
    // reset whole form back to initial state
    this.submitted = false;
    this.dynamicForm.reset();
    this.t.clear();
  }

  onClear(): any {
    // clear errors and reset ticket fields
    this.submitted = false;
    this.t.reset();
  }

  onEnter(e: any): any {
    // console.log(e);
    this.SelectAutoSelectID = e.parentId;

    // this.dynamicForm.controls['parentId'].setValue(e.parentId);
  }
  filterApps(name: any): any {
    console.log(name);
    if (name == '') {
      return this.apps;
    } else {
      return this.apps.filter(p => p.displayName.indexOf(name.toLowerCase()) === 0)
    }
    ;
  }
  firstSpaceRemoveValid(e): any {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
}
