<div>
    <div class="popupheader">
      <div class="head_img">
        <img *ngIf="data.type == 'confirmation' " src="../../../assets/img/Confirmation.png" alt="confirm">
        <img *ngIf="data.type == 'alert' " src="../../../assets/img/alert.png" alt="confirm">
        <img *ngIf="data.type == 'success' " src="../../../assets/images/Congratulations.png" alt="confirm">
      </div>
    </div>
    <div class="popupBody">
      <p *ngIf="data.type == 'confirmation' ">Confirm !</p>
      <p *ngIf="data.type == 'alert' ">Alert !</p>
      <p *ngIf="data.type == 'success' ">Congratulations !</p>
      <div>{{data.message}}</div>
    </div>
    <div class="popupFooter" mat-dialog-actions>
      <div *ngIf="data.type == 'confirmation'">
        <button class="btn mar15" (click)="confirm()">Confirm</button>
        <button class="btn-danger" (click)="cancel()">Cancel</button>
      </div>
      <div *ngIf="data.type == 'alert' || data.type == 'success' ">
        <button class="btn-danger" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
  