<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!viewId">Add </span>
                <span *ngIf="viewId">Edit </span> candidate ocen
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/listCandidateOcean']">candidate ocen List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!viewId">Add </span>
                    <span *ngIf="viewId">Edit </span>
                    recruiter ocen
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">

            <form [formGroup]="dynamicForm" appInvalidControlScroll>
                <div class="form-group ">
                    <label class="col-sm-12">Select ocean category</label>
                    <div class="col-sm-12">
                        <mat-select formControlName="personalityId" required placeholder="please select ocean "
                            class="form-control">
                            <mat-option *ngFor="let r of ocenCatgoryList" [value]="r.personalityTestCategoryId"
                                [aria-label]="r.personalityTestCategoryId">
                                {{r.testCategoryName}}
                            </mat-option>
                        </mat-select>
                        
                        <mat-error>
                            <span *ngIf="!dynamicForm.get('personalityId').valid && dynamicForm.get('personalityId').touched">
                                Please select personalityId name</span>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group row ">
                    <label class="col-sm-12">
                        personalityScore</label>
                    <div class="col-sm-12 ">
                        <mat-radio-group aria-label="Select an option" formControlName="personalityScore">
                            <mat-radio-button [value]="first">1</mat-radio-button>
                            <mat-radio-button [value]="secound">2</mat-radio-button>
                            <mat-radio-button [value]="three">3</mat-radio-button>
                            <mat-radio-button [value]="four">4</mat-radio-button>
                            <mat-radio-button [value]="five">5</mat-radio-button>
                        </mat-radio-group>

                        <mat-error>
                            <span *ngIf="!dynamicForm.get('personalityScore').valid && dynamicForm.get('personalityScore').touched">
                                Please enter job Industry name</span>
                        </mat-error>
                    </div>
                </div>
                <div class="row">
                    <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-6">
                        <div [formGroup]="ticket" class="col-sm-12">

                            <div class="form-group row ">
                                <label class="col-sm-12">
                                    {{LangList[i].languageName}} Job Category Industry Description </label>
                                <div class="col-sm-12 ">
                                    <textarea type="text" formControlName="message" required
                                    rows="8"  style="width: 100%;" 
                                    [ngClass]="{ 'is-invalid': submitted && ticket.get('message').errors }"
                                     placeholder="Please Enter Job Category Industry Description"
                                    name="message" required>
                </textarea>
                                    <!-- <angular-editor formControlName="message" required
                                        [ngClass]="{ 'is-invalid': submitted && ticket.get('message').errors }"
                                        [config]="config" placeholder="Please Enter Job Category Industry Description"
                                        name="message">
                                    </angular-editor> -->

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10 " (click)="onSubmit()"
                    *ngIf="!viewId">Submit</button>
                <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10 " (click)="onSubmit()"
                    *ngIf="viewId">Update</button>
            </form>




        </div>
    </div>

</div>