import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  
  getAllActiveJobs() {
    return this.http.get(`${environment.baseurl1}/dashboard/getAdminDashboardCount`);
  }
  getLatestCandidate() {
    return this.http.get(`${environment.baseurl1}/dashboard/getLatestCandidate`);
  }
  getLatestRecruiter() {
    return this.http.get(`${environment.baseurl1}/dashboard/getLatestEmployer`);
  }
  getJobPostedStatus(startDate,endDate){
    return this.http.get(`${environment.baseurl1}/dashboard/getPostedJobCountByDate?startDate=`+startDate+`&endDate=`+endDate);
  }
  getSubscription(startDate, endDate){
        // @ts-ignore
    return this.http.get(`${environment.baseurl1}/v1/companySubscription/getSubscriptionStatus?startDate=`+startDate+`&endDate=`+endDate);
  }
  getLatestSubscriptionStatus(){
    return this.http.get(`${environment.baseurl1}/v1/companySubscription/getSubscriptionStatus`)
  }
}
