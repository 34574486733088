import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { LanguageService } from 'src/app/service/language/language.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';
@Component({
  selector: 'app-add-policy',
  templateUrl: './add-policy.component.html',
  styleUrls: ['./add-policy.component.scss'],
})
export class AddPolicyComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  hide = true;
  LangList: [];
  formGroup: FormGroup;
  data: any;
  pdata: any;
  privacyPolicyId: any;
  submitted = false;
  status: boolean;
  alluserType: any;
  id: any;
  policyType: any;
  isActive: boolean;
  companyList: any;
  companyListClone: any = [];
  page: any;
  size: any;
  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Page Description...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['backgroundColor']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  ngOnInit(): void {
    this.privacyPolicyId = this.route.snapshot.params['id'];
    this.initializeForm();
    this.alluserType = [
      { value: 'EMPLOYER', name: 'EMPLOYER' },
      { value: 'CANDIDATE', name: 'CANDIDATE' },
    ];
    this.policyType = [
      { value: 'TermsConditions', name: 'Terms & Conditions' },
      { value: 'PrivacyPolicy', name: 'Privacy Policy' },
    ];
  }
  
  get f() {
    return this.formGroup.controls;
  }
  get t() {
    return this.f.planList as FormArray;
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      privacyPolicyId: [0, Validators.required],
      policyType: ['', Validators.required],
      policyDescription: ['', Validators.required],
      userType: ['', Validators.required],
    });
    if (this.privacyPolicyId) {
      this.getPrivacyPolicyId();
    }
  }

  getPrivacyPolicyId() {
    this.masterService.getPrivacyPolicyId(this.privacyPolicyId).subscribe(
      (res) => {
        this.data = res;
        if (this.data.status == 0) {
          const data = this.data.response;
          this.formGroup.patchValue({
            privacyPolicyId: this.data.response.privacyPolicyId,
            userType: this.data.response.userType,
            policyType: this.data.response.policyType,
            policyDescription: this.data.response.policyDescription,
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        }
        console.log(this.id);
      },
      (error) => console.log(error)
    );
  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

  onSubmit(): any {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.masterService
        .addUserPolicy(this.formGroup.value)
        .subscribe((res) => {
          this.data = res;
          if (this.data.status == 0) {
            this.snackBar.open('User policy Created  Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
            this.router.navigate(['/user-policy']);
          } else {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          }
        });
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }
 
}
