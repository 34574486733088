<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Manage Job List</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active">Manage Job List</li>
        </ol>
    </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
    <div class="card">
        <div class="col-lg-10">
            <div class="responce-banner mb-4">
                <h3 style="margin-top: 10px">Manage Job List</h3>
                <form [formGroup]="advancedform">
                    <div class="input-group">


                        <mat-select placeholder="Search Location" class="form-control" (openedChange)="myInputCity.focus();" formControlName="cityId" (closed)="myInputCity.value='';citySearch(myInputCity.value)" style="margin: 10px;">
                            <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control" #myInputCity focused="'true'" type="text" (keyup)="citySearch($event.target.value)" autocomplete="off" placeholder="search Location" (keyup.enter)="searchBySkill()"
                                (keydown)="$event.stopPropagation()">
                            <mat-option *ngFor="let data of cityList" [value]="data.cityId">
                                {{data.cityName}}</mat-option>
                        </mat-select>

                        <mat-select placeholder="Select Job Category" formControlName="jobCategoryId" class="form-control" (openedChange)="myInputCategory.focus();" (closed)="myInputCategory.value='';categorySearch(myInputCategory.value)" style="margin: 10px;">
                            <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control" #myInputCategory focused="'true'" type="text" (keyup)="categorySearch($event.target.value)" autocomplete="off" placeholder="search" (keyup.enter)="searchBySkill()"
                                (keydown)="$event.stopPropagation()">
                            <mat-option *ngFor="let data of jobCategoryList" [value]="data.jobCategoryId">{{data.displayName}}
                            </mat-option>
                        </mat-select>
                        <span class="input-group-btn"><button class="btn btn-primary" (click)="searchBySkill()" style="margin: 10px;" >Search</button></span>
                    </div>
                </form>


            </div>
        </div>
        <div class="c_table">
            <div class="mat-elevation-z8">
                <div class="col-12 mt-4 mb-4">
                    <div class="row">
                        <div class="col-6">
                            <form [formGroup]="searchForm">
                                <div class="row">
                                    <div class="col-6 text-left">
                                        <div class="input-group">
                                        <mat-select class="form-control" formControlName="downloadFile">
                                            <mat-option value="CSV">CSV</mat-option>
                                            <mat-option value="XLSX">XLSX</mat-option>
                                            <mat-option value="XLS">XLS</mat-option>
                                        </mat-select>
                                        </div>
                                    </div>
                                    <div class="col-6 text-left">
                                        <button class="btn btn-primary" (click)="downloadFile()">Download File</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                      <div class="col-3"></div>
                      <div class="col-3 text-end">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                      </div>
                    </div>
                </div>
                <!-- <div class="d-flex table-responsive p-3">
                    <div class="mr-3">
                        <button mat-raised-button color="primary" (click)="ExportTOExcel()">Export as Excel</button></div>
                    <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                    </div>
                </div> -->
                <div class="example-container mat-elevation-z8 " #TABLE>
                    <table mat-table #table [dataSource]="dataSource" matSort class="table-striped contact-table">
                        <ng-container matColumnDef="srno">
                            <th width="60px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                            <td mat-cell *matCellDef="let element;let j = index">
                                {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                            </td>
                        </ng-container>

                        <div>

                            <ng-container matColumnDef="companyName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name</th>
                                <td mat-cell *matCellDef="let element">{{element.companyName }} </td>
                            </ng-container>
                            <ng-container matColumnDef="jobTitle">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Title</th>
                                <td mat-cell *matCellDef="let element">{{element.jobTitle }} </td>
                            </ng-container>

                            <ng-container matColumnDef="jobType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Type</th>
                                <td mat-cell *matCellDef="let element">{{element.jobInternshipType }} </td>
                            </ng-container>

                            <ng-container matColumnDef="jobCategory">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Category</th>
                                <td mat-cell *matCellDef="let element">{{element.jobCategory}}</td>
                            </ng-container>




                            <ng-container matColumnDef="city">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> City</th>
                                <td mat-cell *matCellDef="let element">
                                    <p class="mb-0">{{element.city}}</p>
                                </td>
                            </ng-container>



                            <ng-container matColumnDef="postDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Post Date</th>
                                <td mat-cell *matCellDef="let element">{{element.postedDate | date:'dd-MM-yyyy'}}</td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
                                <td mat-cell *matCellDef="let element">
                                    <span style="color: #fcb32d;" *ngIf="element.status == 'Pending'">{{element.status }}</span>
                                    <span style="color: green;" *ngIf="element.status =='Active' ">{{element.status }}</span>
                                    <span style="color: red;" *ngIf="element.status =='Expired' ">{{element.status }}</span>
                                    <span style="color: red;" *ngIf="element.status =='Rejected' ">{{element.status }}</span>
                                </td>

                            </ng-container>

                        </div>





                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>





            </div>


        </div>
    </div>
</div>