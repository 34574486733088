import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';
import { CommonFucntionService } from 'src/app/service/commonFuncaiton/common-fucntion.service';
import { JobTestQuestionManageService } from 'src/app/service/jobPostTestandQuestion/job-test-question-manage.service';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { ImageQuestionPopupComponent } from '../image-question-popup/image-question-popup.component';

@Component({
  selector: 'app-add-update-job-test-creation',
  templateUrl: './add-update-job-test-creation.component.html',
  styleUrls: ['./add-update-job-test-creation.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AddUpdateJobTestCreationComponent implements OnInit {


  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private el: ElementRef,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    public dialog: MatDialog,
    public commonFunctionservice: CommonFucntionService,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    private router: Router) { }
  get f() { return this.dyanmicQuestionForm.controls; }
  get t() { return this.f.assesmentTestMultiQuestions as FormArray; }

  expandedElement: (query: string) => void;
  viewID: any;
  fileData: File = null;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  dyanmicQuestionForm: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList = [];
  page: any;
  size: any;
  qustGropList = [];
  qustGropList0 = [];
  qustGropList1 = [];
  qustGropList2 = [];
  qustGropList3 = [];
  qustGropList4 = [];
  qustGropList5 = [];

  qGroup: any;
  jobCatList = [];
  qType: any;
  arrayOptions = [];
  pdata: any;
  seletedOption: any;
  myModel: any;
  config: any;
  optionconfig: any;
  addnewOption: any;
  submitted: boolean = false;
  selectLang: any;
  firstFormSubmited: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'assesmentQuestionId', 'question', 'createdEpochTime', 'questionLevel', 'action', 'addedBy'];
  columnsToDisplay = ["seqNo", "question"];
  dataSource = new MatTableDataSource<any>();
  dataSource1 = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  QGData: any;
  mm: boolean = false;
  assesmentviewID: any;
  createTestData: any;
  testQData: any;
  firstCreateData: any;
  checkboxSelectedCount: number = 0;
  checkboxSelectArray = [];
  ToatlQuestionNo: number;
  QuestionSubmitBtnHide: boolean = false;
  isTestCreateSuccess: boolean = false;
  SelectedMainQuestionList: any[] = [];
  selectedQuestionIds: any[] = [];
  Jcat: any;
  languageId: any;
  excellentPassDescription: any;
  normalPassingScoreMax: any;
  numberOfRetry: any;
  excellentPassScore: any;
  jobcategoryName: any;
  testDescriptionForCompany: any;
  testDescriptionForCandidate: any;
  maxTimeInMin: any;
  failResultDescription: any;
  normalPassingScoreMin: any;
  pererror: boolean = false;
  JobQuniError: boolean = false;
  excellentPassScoreError: boolean = false;
  addOptionButtonHide: boolean = false;
  selectedAreas: any[] = [];

  questionAreas: any[] = [];
  customQuestionGroupArray: any[] = [];
  selectedlanguageCode: any;
  selectedLangName: any;
  maxScoreDisable: boolean = true;
  excellentScoreDisable: boolean = true;
  UpdateCreatedTest: boolean = false;
  selectedTab = 0;
  moreCountthanQuestion: boolean = false;
  passResultDescription: any;
  title: any;
  subquestionCount = 0;

  //  question
  item: any;
  isShown: boolean = false;
  companyId: any;
  jobId: any;
  jobSubcategory: any;

  fileData2: any;
  thumbImagePath: any;
  thumbImageURL: any;
  newthumbImageupload: any;

  testdescription = `
  <h5><span><font size="3">Test heading E.x.  people need solid language </font></span></h5>

<p><span>&nbsp;</span></p>

<p><b><span>Purpose</span></b><span>: Please add test purpose.<o:p></o:p></span></p>

<p><span>&nbsp;</span></p>

<p><b><span>About
the Test:</span></b><span>&nbsp;please add test about details like E.x.This
is an untimed test with 25 questions.<o:p></o:p></span></p>

<p><span>&nbsp;</span></p>

<p><span>People
scoring high on th Test should be able to spot and
correct and good For jobs<o:p></o:p></span></p>`;

  // description base on 
    english_fail_description= `Candidates that do not pass this test will often need assistance from others to help complete task.`;
    english_pass_description= `Low scoring candidate may not be able to perform tasks to the same standard as high scorers.`;
    english_excellent_description= `High scoring candidates are quicker, more capable in accomplishing tasks and easier to train.`;

    spanish_fail_description= `los candidatos con puntuaciones altas son más rápidos, más capaces de realizar tareas y más fáciles de entrenar.`;
    spanish_pass_description= ` bajos: Es posible que los candidatos con puntajes bajos no puedan realizar tareas con el mismo estándar que los puntajes altos.`;
    spanish_excellent_description= `los candidatos que no aprueben esta prueba a menudo necesitarán la ayuda de otros para completar la tarea.`;

    polish_fail_description= `Kandydaci, którzy nie zdają, często będą potrzebować pomocy innych, aby pomóc w wykonaniu zadania.`;
    polish_pass_description= `Kandydat o niskiej punktacji może nie być w stanie wykonać zadań w takim samym standardzie jak wysoki strzelcy.`;
    polish_excellent_description= `Kandydaci o wysokich wynikach są szybsi, sprawniejsi w realizacji zadań i łatwiejszy do szkolenia.`;


  ngOnInit(): void {

    // const userDetails  = JSON.parse(this.loginSignupService.getMyProfiileDetailsLocalStorage());
    // this.companyId = userDetails.companyId;
    this.firstFormSubmited = false;
    this.addnewOption = 1;
    this.config = new DynamicLabelClass().testTagsConfiguration;
    this.optionconfig = new DynamicLabelClass().ckeditorconfigForOption;
    this.seletedOption = 0;
    this.page = 0;
    this.size = 500;
    this.viewID = this.route.snapshot.params['id'];


    this.selectedTab = this.route.snapshot.params['tab'];
    this.jobId = this.route.snapshot.params['jobId'];
    // this.jobSubcategory =  this.route.snapshot.params['jobSubcategory'];

    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    this.questionTypeList(this.selectLang);

    // if(!(this.jobId == 0 || this.jobId == '0')){
    //   // this.jobcatSelection(this.jobSubcategory);
    // } else {

    // }
    // this.selectLang = this.LangList[0].languageCode;
    console.log(this.LangList);
    // this.questionGroupList();
    this.openContentOne(this.selectedTab);

    this.initializeForm();
    this.arrayOptions = [];
    // this.addOption();

  }

  LangChange(e): any {
    for (let l of this.LangList) {
      if (l.languageId == e.value) {
        this.selectLang = l.languageCode;
        this.questionTypeList(this.selectLang);
        if(l.languageCode == 'en'){
          this.formGroup.patchValue({
            failResultDescription: this.english_fail_description,
            passResultDescription: this.english_pass_description,
            excellentPassDescription : this.english_excellent_description,
          });
        } else if(l.languageCode == 'es'){
          // spainish
          this.formGroup.patchValue({
            failResultDescription: this.spanish_fail_description,
            passResultDescription: this.spanish_pass_description,
            excellentPassDescription : this.spanish_excellent_description,
          });
        } else if(l.languageCode == 'pl'){
          // "pl"
          this.formGroup.patchValue({
            failResultDescription: this.polish_fail_description,
            passResultDescription: this.polish_pass_description,
            excellentPassDescription : this.polish_excellent_description,
          });
        }

        // this.formGroup.patchValue({
        //   failResultDescription: this.english_fail_description,
        //   passResultDescription: this.english_pass_description,
        //   excellentPassDescription : this.english_excellent_description,
        // })
      }
    }

  }
  questionTypeList(selectLang): any {
    this.masterDataservice.getAllCategoryList(this.page, this.size, selectLang, true).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {
        this.jobCatList = this.qType.response.dataSet.filter((d) => {
          // && d.isTestCreated == false
          if (d.isSubCategory == true) {
            return d;
          }
        });
        console.log(this.jobCatList)
        if (!(this.jobId == 0 || this.jobId == '0')) {

          this.formGroup.patchValue({
            jobCategoryId: Number(this.jobSubcategory),
          });
        }

        this.selectedAreas = this.jobCatList;

        console.log(this.jobCatList);
      }
    });
  }

  search(query: string): any {
    const se = query.toLowerCase();
    let result = this.select(se);
    if (query == '') {
      this.jobCatList = this.selectedAreas;
    } else {
      this.jobCatList = result;
    }
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
  searchQuestionGrop(query: string, index): any {
    console.log('query', query)
    let result = this.QuestionFromselect(query, index);
    if (query == '') {
      this.qustGropList[index] = this.questionAreas[index];
    } else {
      this.qustGropList[index] = result;
    }
  }
  QuestionFromselect(query: string, index): string[] {
    let result: string[] = [];
    for (let a of this.questionAreas[index]) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
  minScrorValidation($event): any {
    const entervalue = $event.target.value;
    if (this.formGroup.controls.normalPassingScoreMin.value != '') {
      this.maxScoreDisable = false;
    } else {
      this.maxScoreDisable = true;
    }
  }



  questionGroupList(index): any {
    this.masterDataservice.getAllQuestionLevelList(this.page, this.size, this.selectLang).subscribe(res => {
      this.qGroup = res;
      if (this.qGroup.status == 0) {
        console.log(this.qGroup.response);
        this.qustGropList[index] = this.qGroup.response.dataSet.filter(d => {
          if (this.customQuestionGroupArray.length > 0) {
            for (let b of this.customQuestionGroupArray) {
              if (b != d.assesmentQuestionId) {
                return d;
              }
            }
          } else {
            return d;
          }
        });
        console.log(this.qustGropList[index]);
        this.questionAreas[index] = this.qustGropList[index];
      }
    });
  }
  
  // To initialize Form
  initializeForm(): any {
  
    this.formGroup = this.fb.group({
      title: ['', Validators.required],
      languageId: ['', Validators.required],
      jobCategoryId: ['', Validators.required],
      testDescriptionForCompany: ['', Validators.required],
      testDescriptionForCandidate: ['', Validators.required],
      totalNumberOfQuestions: ['', Validators.required],
      maxTimeInMin: ['', Validators.required],
      numberOfRetry: ['', Validators.required],
      normalPassingScoreMin: [60, Validators.required],   
      normalPassingScoreMax: [80, Validators.required], 
      excellentPassScore: [90, Validators.required],
      failResultDescription: [this.english_fail_description, Validators.required],
      passResultDescription: [this.english_pass_description, Validators.required],   
      excellentPassDescription: [this.english_excellent_description, Validators.required],
    });

    this.dyanmicQuestionForm = this.fb.group({
      assesmentTestMultiQuestions: new FormArray([]),
    });

    if (!(this.jobId == 0 || this.jobId == '0')) {
      for (let b of this.LangList) {
        if (b.languageCode == this.selectLang) {
          this.formGroup.patchValue({
            languageId: b.languageId
          })
        }
      }

    }
    if (this.viewID) {
      this.patchData();
    }
    this.LangListLoad(this.addnewOption);
  }

  removeOption(i): any {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e): any {
    const numberOfTickets = e
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.fb.group({
          groupId: null,
          questionIds: [],
          flag: false,
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  // get data from jobCategoryId
  patchData(): any {
    this.firstFormSubmited = true;
    this.jobpostQuestionTestService.getAssesmentTestBystId(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0) {

          this.formGroup.patchValue(this.pdata.response);
          this.ToatlQuestionNo = this.pdata.response.totalNumberOfQuestions;
          this.jobSubcategory = this.pdata.response.jobCategoryId;
          this.languageId = this.pdata.response.languageId;
          this.LangChange(this.languageId);
          this.title = this.pdata.response.title;
          for (let l of this.LangList) {
            if (l.languageId == this.languageId) {
              this.selectedLangName = l.languageName;
              this.selectLang = l.languageCode;
              this.questionTypeList(this.selectLang);
            }
          }
          this.jobcategoryName = this.pdata.response.jobcategoryName;

          this.testDescriptionForCompany = this.pdata.response.testDescriptionForCompany;
          this.testDescriptionForCandidate = this.pdata.response.testDescriptionForCandidate;
          this.maxTimeInMin = this.pdata.response.maxTimeInMin;
          this.normalPassingScoreMax = this.pdata.response.normalPassingScoreMax;
          this.normalPassingScoreMin = this.pdata.response.normalPassingScoreMin;
          this.excellentPassDescription = this.pdata.response.excellentPassDescription;
          this.numberOfRetry = this.pdata.response.numberOfRetry;
          this.excellentPassScore = this.pdata.response.excellentPassScore;
          this.passResultDescription = this.pdata.response.passResultDescription;
          this.failResultDescription = this.pdata.response.failResultDescription;
          this.UpdateCreatedTest = this.pdata.response.isTestCreateSuccess;
          this.thumbImageURL = this.pdata.response.answerImageUrl;

        } else {
          // this.dialogService.ServerOpen(this.pdata.response , 'error');
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));


    // for test question
    this.jobpostQuestionTestService.getAssestmentTestDetailsById(this.viewID).subscribe(res => {
      this.testQData = res;
      if (this.testQData.status == 0) {
        console.log(this.testQData);
        this.LangListLoad(this.testQData.response.length == 0 ? 1 : this.testQData.response.length);
        this.addnewOption = this.testQData.response.length == 0 ? 1 : this.testQData.response.length;
        if (this.testQData.response.length == 0) {
          this.questionGroupList(0);
          this.checkboxSelectArray = [];
        } else {
          const er = [...this.testQData.response]
          // const assetiQuestions= [
          // {  assesmentTestMultiQuestions:{er}}
          // ];
          // console.log(assetiQuestions);
          const d = {
            assesmentTestMultiQuestions: er,
          }
          console.log(d);

          // console.log(Object.keys(assetiQuestions));
          // console.log(this.testQData.response);
          this.dyanmicQuestionForm.patchValue(d);
          this.testQData.response.forEach((b, i) => {
            console.log(b);
            this.questionCreate(b.groupId, i, '');
            ((this.dyanmicQuestionForm.get('assesmentTestMultiQuestions') as FormArray).at(i) as FormGroup).get('flag').patchValue(true);
            // ((this.dyanmicQuestionForm.get('assesmentTestMultiQuestions') as FormArray).at(i) as FormGroup).get('questionIds').patchValue(b.questionIds);
            this.questionGroupList(i);
            this.customQuestionGroupArray.push(b.groupId);

            for (let q of b.questionIds) {
              this.checkboxSelectArray.push(q);
              // this.subquestionCount =  this.subquestionCount +
            }
          }
          );
          console.log(this.checkboxSelectArray);
          this.countLogic(this.checkboxSelectArray, 'patch');
          console.log(this.customQuestionGroupArray);
          // this.checkboxSelectedCount = this.checkboxSelectArray.length;
          // this.checkboxSelectedCount  = this.subquestionCount;


        }
        console.log(this.QuestionSubmitBtnHide);
        // for(const [b, index] of this.testQData.response){
        //   console.log(Object.keys(b));
        //   console.log(Object.values(b));
        //   console.log(b);
        //   console.log(index);
        //   // console.log(Object.keys(this.testQData.response));
        // this.questionCreate(b.groupId,0);
        // }
      }

    });
  }

  countLogic(mainArraay, type) {
    // mainArraay.forEach((e,i) => {
    //   this.jobpostQuestionTestService.getSubQuestionByParentId(mainArraay[i]).subscribe( res => {

    //     this.subquestionCount = this.subquestionCount + res.response.length;

    //     if((i+1) == mainArraay.length){
    //       if(type == 'delete'){
    //         this.checkboxSelectedCount = mainArraay.length - this.subquestionCount;
    //         this.subquestionCount = this.checkboxSelectedCount ;
    //       } else {
    //         this.checkboxSelectedCount = mainArraay.length + this.subquestionCount;
    //       this.subquestionCount = this.checkboxSelectedCount ;
    //       }

    //     }
    //     this.subquestionCount = this.checkboxSelectedCount;
    //   });
    // });

    // console.log(this.checkboxSelectArray);

    console.log(mainArraay.length);
    mainArraay.forEach((e, i) => {
      this.jobpostQuestionTestService.getSubQuestionByParentId(mainArraay[i]).subscribe(res => {
        console.log(res.response);
        if (res.response.length > 0) {
          this.subquestionCount = this.subquestionCount + res.response.length - 1;
        } else {
          this.subquestionCount = this.subquestionCount + res.response.length;
        }

        console.log(i);
        if ((i + 1) == mainArraay.length) {
          console.log(i);
          console.log(mainArraay.length);
          console.log(this.subquestionCount);
          this.checkboxSelectedCount = mainArraay.length + this.subquestionCount;
          this.subquestionCount = this.checkboxSelectedCount;

          if (this.ToatlQuestionNo > this.checkboxSelectedCount) {
            this.QuestionSubmitBtnHide = false;
            this.moreCountthanQuestion = true;
          } else {
            this.moreCountthanQuestion = false;
            this.QuestionSubmitBtnHide = true;
          }
        }
      });
    });


 



  }
  // strip_tags(html,) {
  //   let args = [ 'b', 'i', 'u', 'ul' , 'li' , 'br' , 'ol' , 'div' , 'span' , 'p' , 'h1' , 'h2' , 'h3' , 'h4' , 'h5' , 'h6' , 'h7' ];
  //   return html.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
  //     return args.includes(tag) ? '<' + endMark + tag + '>' :'';
  //   }).replace(/<!--.*?-->/g, ''); 
  // }
  onSubmit(submiType) {
    this.submitted = true;
    this.formGroup.controls.testDescriptionForCompany.setValue(this.formGroup.value.testDescriptionForCompany == null || this.formGroup.value.testDescriptionForCompany == '' ? null : this.commonFunctionservice.removeHtmlTag(this.formGroup.value.testDescriptionForCompany) );
    this.formGroup.controls.testDescriptionForCandidate.setValue(this.formGroup.value.testDescriptionForCandidate == null || this.formGroup.value.testDescriptionForCandidate == '' ? null : this.commonFunctionservice.removeHtmlTag(this.formGroup.value.testDescriptionForCandidate) );
    this.formGroup.controls.passResultDescription.setValue(this.formGroup.value.passResultDescription == null || this.formGroup.value.passResultDescription == '' ? null : this.commonFunctionservice.removeHtmlTag(this.formGroup.value.passResultDescription) );
    this.formGroup.controls.failResultDescription.setValue(this.formGroup.value.failResultDescription == null || this.formGroup.value.failResultDescription == '' ? null : this.commonFunctionservice.removeHtmlTag(this.formGroup.value.failResultDescription) );
     this.formGroup.controls.excellentPassDescription.setValue(this.formGroup.value.excellentPassDescription == null || this.formGroup.value.excellentPassDescription == '' ? null : this.commonFunctionservice.removeHtmlTag(this.formGroup.value.excellentPassDescription) );
    // this.strip_tags(this.formGroup.value.question, 'b', 'i', 'ul' , 'li' , 'br' , 'div' , 'span' , 'p')
    if (this.formGroup.valid) {
      if (this.viewID) {
        
        this.jobpostQuestionTestService.editJobTestCreationFirst(this.viewID, this.formGroup.value)
          .subscribe((data: any) => {
            // this.router.navigate(['/editJobTestCreation', this.viewID, '1', this.jobId]);
            if (data.status == 0) {
              // this.snackBar.open('Update Successfully !!', '', {
              //   duration: 3000,
              //   panelClass: ['success-snackbar']
              // });
              this.router.navigate(['/editJobTestCreation', this.viewID, '1']);
              window.scrollTo(0, 0);
              this.firstFormSubmited = true;
              // this.openContentOne('1');
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['/editJobTestCreation', this.viewID, '1']);
            } else {
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
              // this.dialogService.ServerOpen(data.response , 'error');
            }
          },
            (err) => {
              // this.dialogService.ServerOpen(err.error.response , 'serverError');
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['error-snackbar']
              });
            }
          );
      } else {
        this.jobpostQuestionTestService.addJobTestCreationFirst(this.formGroup.value).subscribe((res: any) => {
          this.firstCreateData = res;
          if (this.firstCreateData.status == 0) {
            this.viewID = this.firstCreateData.response;
            this.ToatlQuestionNo = this.formGroup.controls.totalNumberOfQuestions.value;
            // this.snackBar.open(' Successfully !!', '', {
            //   duration: 3000,
            //   panelClass: ['success-snackbar']
            // });
            this.router.navigate(['/editJobTestCreation', this.viewID, '1']);
            window.scrollTo(0, 0);
            this.firstFormSubmited = true;
            // this.openContentOne('1');
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/editJobTestCreation', this.viewID, '1']);
          } else {
            // this.snackBar.open(this.firstCreateData.response, '', {
            //   duration: 3000,
            //   panelClass: ['error-snackbar']
            // });
            this.firstFormSubmited = false;
          }

        });
      }

    } else {
      // window.scrollTo(0, 0)
      this.formGroup.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      // this.dialogService.ServerOpen('Please Enter All Details', 'serverError');
      // this.snackBar.open('Please Enter All Details', '', {
      //   duration: 3000,
      //   panelClass: ['error-snackbar']
      // });
    }
  }

  firstSpaceRemoveValid(e): any {
    return this.commonFunctionservice.firstSpaceRemoveValid(e);
    // if (e.which === 32 && e.target.selectionStart === 0) {
    //   return false;
    // }
  }
  numberOnly(event): boolean {
    return this.commonFunctionservice.numberOnly(event);
    // const charCode = (event.which) ? event.which : event.keyCode;
    // if (charCode > 31 && (charCode < 47 || charCode > 57)) {
    //   return false;
    // }
    // return true;
  }
  maxScrorValidation($event: any): any {
    const entervalue = $event.target.value;
    if (entervalue != '') {
      this.excellentScoreDisable = false;
    } else {
      this.excellentScoreDisable = true;
    }
    if (entervalue != null || entervalue !== '' || entervalue.length <= 3) {
      if (entervalue == 0) {
        return false;
      }
      if (Number(this.formGroup.controls.normalPassingScoreMin.value) <= Number(entervalue) && entervalue < 101) {
        this.pererror = false;
        this.excellentScoreDisable = false;
        this.formGroup.controls.normalPassingScoreMax.setErrors(null);
      } else {
        this.pererror = true;
        this.excellentScoreDisable = true;
        this.formGroup.controls.normalPassingScoreMax.setErrors({ incorrect: true });
      }

    }
  }
  ExcellentPassScoreValidation($event: any): any {
    const entervalue = $event.target.value;
    if (entervalue != null || entervalue !== '' || entervalue.length <= 3) {
      // console.log(this.formGroup.controls.normalPassingScoreMax.value);
      // console.log(entervalue);
      if (entervalue == 0) {
        return false;
      }
      if (Number(this.formGroup.controls.normalPassingScoreMax.value) <= Number(entervalue) && entervalue < 101) {
        console.log(this.excellentPassScoreError)
        this.excellentPassScoreError = false;
        this.formGroup.controls.excellentPassScore.setErrors(null);
      } else {
        console.log(this.excellentPassScoreError);
        this.excellentPassScoreError = true;
        this.formGroup.controls.excellentPassScore.setErrors({ incorrect: true });
      }
    }
  }
  optionCreate(e): any {
    console.log(e);
  }
  newOptionadd(): any {
    console.log(this.addnewOption);
    console.log(this.dyanmicQuestionForm.controls['assesmentTestMultiQuestions'].value.slice(-1)[0])
    console.log(this.dyanmicQuestionForm.value);
    // this.logSelection(this.addnewOption - 1);
    // this.customQuestionGroupArray.push();
    if (this.dyanmicQuestionForm.value.assesmentTestMultiQuestions.length == 0) {
      this.questionGroupList(this.addnewOption);
      // this.customQuestionGroupArray.push(lastQuestionGroup.groupId);
      this.addnewOption = this.addnewOption + 1;
      this.LangListLoad(this.addnewOption);
      this.dataSource[0] = new MatTableDataSource([]);
    } else {

      this.dataSource[this.addnewOption] = new MatTableDataSource([]);
      const lastQuestionGroup = this.dyanmicQuestionForm.controls['assesmentTestMultiQuestions'].value.slice(-1)[0];
      if (lastQuestionGroup.groupId && lastQuestionGroup.questionIds) {
        this.questionGroupList(this.addnewOption);
        this.customQuestionGroupArray.push(lastQuestionGroup.groupId);
        this.addnewOption = this.addnewOption + 1;
        this.LangListLoad(this.addnewOption);
        // this.questionGroupList(this.addnewOption);
        this.addOptionButtonHide = false;
      } else {
        alert('Please select questiongroup and select anyOne quesiton first');
        // this.dialogService.open('please select group and question first' , 'alert');
      }
    }
  }
  onSubmitQuestion(e): any {
    console.log(this.dyanmicQuestionForm.value);
    if (this.ToatlQuestionNo == this.checkboxSelectedCount) {
      this.isTestCreateSuccess = true;
    } else {
      this.isTestCreateSuccess = false;
    }
    if (this.ToatlQuestionNo < this.checkboxSelectedCount) {
      // this.snackBar.open('Question count not matching', '', {
      //   duration: 3000,
      //   panelClass: ['error-snackbar']
      // });
      return;
    }
    if (this.viewID) {
      this.jobpostQuestionTestService.UpdateTestCreationQuestion(this.viewID, this.dyanmicQuestionForm.value, this.isTestCreateSuccess).subscribe(res => {
        this.createTestData = res;
        if (this.createTestData.status == 0) {
          this.selectedQuestionIds = [];
          if (e == 1 || e == '1' || e == 2 || e == '2') {
            const d = e == '1' ? 'test Created Successfully' : 'draft Successfully';
            // this.snackBar.open(d, '', {
            //   duration: 3000,
            //   panelClass: ['success-snackbar']
            // });
            // if(this.jobId != 0 && e ==1){
            //   this.publishTestForJobCreation(this.viewID);
            // } else {
            //   this.router.navigate(['/listJobTestCreation']);
            // }
            this.router.navigate(['/listJobTestCreation', this.selectLang]);

          }
        } else {
          // this.snackBar.open('Something went Wrong server status -1', '', {
          //   duration: 3000,
          //   panelClass: ['success-snackbar']
          // });
        }
      });
    } else {
      this.jobpostQuestionTestService.addQuestionCreateTest(this.viewID, this.dyanmicQuestionForm.value, this.isTestCreateSuccess).subscribe(res => {
        this.createTestData = res;
        if (this.createTestData.status == 0) {
          if (e == 1 || e == '1') {
            // this.snackBar.open('test Created Successfully', '', {
            //   duration: 3000,
            //   panelClass: ['error-snackbar']
            // });
            if (this.jobId != 0 && e == 1) {
              this.publishTestForJobCreation(this.viewID);

            } else {
              this.router.navigate(['/listJobTestCreation', this.selectLang]);
            }
            // this.router.navigate(['/listJobTestCreation']);
          }
        } else {
          // this.snackBar.open('Something went Wrong server status -1', '', {
          //   duration: 3000,
          //   panelClass: ['success-snackbar']
          // });
        }
      });
    }
  }

  questionCreate(e, index, iddd): any {
    console.log(e);
    console.log(iddd);
    const QuestId = e;
    // this.customQuestionGroupArray.push(e);
    this.jobpostQuestionTestService.getQuestionListByGroupId(this.page, this.size, this.selectLang, QuestId).subscribe(res => {
      this.QGData = res;
      console.log(this.QGData);
      const TestQuestionList = this.QGData.response.dataSet.filter((d) => {
        if (d.isActive == true) {
          return d;
        }
      });

      this.dataSource[index] = new MatTableDataSource(TestQuestionList);
      this.selection[index] = new SelectionModel(true, [this.dataSource[index]]);
    });
  }

  getSubQuestionByParentId(e): any {
    console.log(e);
    // this.mm[index]= true;
    this.jobpostQuestionTestService.getSubQuestionByParentId(e).subscribe(res => {
      console.log(res.response);
      this.dataSource1 = new MatTableDataSource(res.response);
    });
  }

  isAllSelected(index): any {
    const numSelected = this.selection[index].selected.length;
    const numRows = this.dataSource[index].data.length;
    return numSelected === numRows;
  }
  masterToggle(index): any {
    this.isAllSelected(index) ?
      this.selection[index].clear() :
      this.dataSource[index].data.forEach(row => this.selection[index].select(row));
  }

  logSelection(index): any {
    console.log(index);
    console.log(this.selection[index].selected);
    console.log(this.dyanmicQuestionForm.value);
    this.dyanmicQuestionForm.value.assesmentTestMultiQuestions.forEach((b, i) => {
      if (i == index) {
        console.log(this.selection[index].selected);
        let ops = (this.selection[index].selected).slice(1);
        console.log(ops);
        if (ops == []) {
          // if blank
          console.log(((this.dyanmicQuestionForm.get('assesmentTestMultiQuestions') as FormArray).at(index) as FormGroup).get('questionIds').setValue(b.questionIds));
        } else {
          // if new element present
          this.selectedQuestionIds = b.questionIds;
          ops.forEach((f, i) => {
            if (this.selectedQuestionIds.indexOf(ops[i]) >= 0) {
              console.log(this.selectedQuestionIds[i]);
            } else {
              this.checkboxSelectArray.push(ops[i]);
              this.selectedQuestionIds.push(ops[i]);
            }
          });
        }
      }
    }
    );
    ((this.dyanmicQuestionForm.get('assesmentTestMultiQuestions') as FormArray).at(index) as FormGroup).get('questionIds').setValue(this.selectedQuestionIds);
  }


  formValueQustionSet(index): any {
    ((this.dyanmicQuestionForm.get('assesmentTestMultiQuestions') as FormArray).at(index) as FormGroup).get('questionIds').setValue((this.selection[index].selected).slice(1));
  }
  checkboxclick(row, e, index, id): any {
    if (e) {
      this.selection[index].toggle(id);
      const selectInd = this.checkboxSelectArray.indexOf(id);
      if (selectInd >= 0) {
        this.checkboxSelectArray.splice(selectInd, 1);
      } else {
        this.checkboxSelectArray.push(id);
      }

      // for set value array
      this.dyanmicQuestionForm.value.assesmentTestMultiQuestions.forEach((b, i) => {
        if (i == index) {
          const mm = b.questionIds != '' ? b.questionIds : [];
          this.selectedQuestionIds = mm == null ? [] : mm;
          const setfrmval = this.selectedQuestionIds.indexOf(id);
          if (setfrmval >= 0) {
            this.selectedQuestionIds.splice(setfrmval, 1);
          } else {
            this.selectedQuestionIds.push(id);
          }
        }
      });
    }
    if (e.checked == true) {
      this.subquestionCount = this.subquestionCount + row.countOfQuestion;
    }
    else {
      this.subquestionCount = this.subquestionCount - row.countOfQuestion;
    }
    this.checkboxSelectedCount = this.subquestionCount;
    if (this.ToatlQuestionNo > this.checkboxSelectedCount) {
      this.QuestionSubmitBtnHide = false;
    } else {
      console.log(this.ToatlQuestionNo);
      this.QuestionSubmitBtnHide = true;
    }
    ((this.dyanmicQuestionForm.get('assesmentTestMultiQuestions') as FormArray).at(index) as FormGroup).get('questionIds').setValue(this.selectedQuestionIds);
    console.log(this.dyanmicQuestionForm.value);
  }
  removeElemt(index): any {
    console.log(this.selection[index].selected);
  }

  jobcatSelection(e): any {
    console.log(e.value)
    this.jobpostQuestionTestService.jobcatgorySelection(e.value, this.selectLang).subscribe(res => {
      this.Jcat = res;
      if (this.Jcat.status == 0) {
        if (this.Jcat.response == true) {
          this.formGroup.controls.jobCategoryId.setErrors({ incorrect: true });
          this.JobQuniError = true;
          // this.snackBar.open('You have already Created a Asssement Test in this category - You cant create More than one Test in One SubCategory', '', {
          //   duration: 3000,
          //   panelClass: ['error-snackbar']
          // });
        } else {
          this.formGroup.controls.jobCategoryId.setErrors(null);
          this.JobQuniError = false;
        }
      } else {
        this.formGroup.controls.jobCategoryId.setErrors(null);
        this.JobQuniError = false;
        // this.snackBar.open('response Status -1 ', '', {
        //   duration: 3000,
        //   panelClass: ['error-snackbar']
        // });
      }

    });
  }


  demo(e): any {
    const d = e.target.value;
  }
  openContentOne(e): any {
    this.selectedTab = e;
  }

  imageQuestionPreview(element): any {
    const dialogRef = this.dialog.open(ImageQuestionPopupComponent, {
      width: '800px',
      height: 'auto',
      data: {
        question: element.question,
        qImage: element.questionImage,
      },
      panelClass: 'defaultClass',
    });
    dialogRef.afterClosed().subscribe(sresult => {
      // console.log(sresult);
      if (sresult == true) {
      }
    });
  }
  publishTestForJobCreation(id) {
    this.jobpostQuestionTestService.publishTestById(id).subscribe((res: any) => {
      if (res.status == 0) {
        this.router.navigate(['listJobTestCreation', this.selectLang]);
      } else {
        // this.snackBar.open(res.response, '', {
        //   duration: 3000,
        //   panelClass: ['error-snackbar']
        // });
      }
    })

  }
  onPaste(event: ClipboardEvent, formcontrolname) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    console.log(pastedText);
    event.preventDefault();
    this.formGroup.controls[formcontrolname].setValue(pastedText);
  }
  deleteQuestionGroup(i) {

    if (confirm("Are you sure you want to remove Question group ?")) {
      const removeSelectedId = this.dyanmicQuestionForm.controls['assesmentTestMultiQuestions'].value[i].questionIds;
      const removeQuestionGroup = this.dyanmicQuestionForm.controls['assesmentTestMultiQuestions'].value[i].groupId;

      console.log(this.t.controls.length)

      console.log(this.checkboxSelectArray);
      // this.dataSource[i-1] =  new MatTableDataSource([]);

      // if(this.t.controls.length){

      // } else {

      // }

      if (removeSelectedId != null) {
        this.checkboxSelectArray = this.checkboxSelectArray.filter(val => !removeSelectedId.includes(val));
      }

      console.log(this.checkboxSelectArray);
      this.addnewOption = this.addnewOption - 1;
      this.subquestionCount = 0;
      this.checkboxSelectedCount = 0;
      this.customQuestionGroupArray = this.customQuestionGroupArray.filter(val => {
        if (removeQuestionGroup != val) {
          return val;
        }
      });
      console.log(this.customQuestionGroupArray)
      this.countLogic(this.checkboxSelectArray, 'delete');
      this.t.removeAt(i);
      // console.log(this.dyanmicQuestionForm.controls['assesmentTestMultiQuestions'].value)
      this.dyanmicQuestionForm.controls['assesmentTestMultiQuestions'].value.slice(i, 1);
      for (let b of this.t.controls) {
        if (i <= this.t.controls.length) {
          this.dataSource[i] = this.dataSource[i + 1];
          // this.dataSource[this.t.controls.length - 1] = new MatTableDataSource([]);
          i++;
        }
      }
    }


    // console.log(this.t);
    // console.log( this.dyanmicQuestionForm.controls['assesmentTestMultiQuestions'].value)

  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }

  removeAtt(e) {
    document.querySelectorAll('.mrin .angular-editor-textarea font').forEach(item => {
      // item.remove()
      // item.removeAttribute('style');
      item.removeAttribute('class');
      // item.removeAttribute('face');
       item.removeAttribute('color');
      
    })
    document.querySelectorAll('.mrin .angular-editor-textarea div').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea table').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    });
    document.querySelectorAll('.mrin .angular-editor-textarea tr').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea td').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea p').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea span').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');

    })
    document.querySelectorAll('.mrin .angular-editor-textarea img').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('src');
      // item.remove();      
    })
    document.querySelectorAll('.mrin .angular-editor-textarea mat-checkbox').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea mat-form-field').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea input').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea select').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea button').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea label').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea a').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('href');
      // item.remove();
    })
    document.querySelectorAll(".mrin .angular-editor-textarea div, .mrin .angular-editor-textarea label, .mrin .angular-editor-textarea a ,.mrin .angular-editor-textarea button ,.mrin .angular-editor-textarea select , .mrin .angular-editor-textarea input , .mrin .angular-editor-textarea mat-checkbox , .mrin .angular-editor-textarea span ").forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    // this.jobDescriptionFromGroup.controls[formcontrolname].setValue(pastedText);
    // return d;
  }


}