import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormControl } from "@angular/forms";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { MatDialog } from "@angular/material/dialog";
import { CommonConfirmPopupComponent } from "src/app/component/common-confirm-popup/common-confirm-popup.component";
@Component({
  selector: "app-list-media",
  templateUrl: "./list-media.component.html",
  styleUrls: ["./list-media.component.scss"],
})
export class ListMediaComponent implements OnInit {
  page: number;
  size: number;
  allMedia: any;
  currentSize: number;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private masterDataservice: MasterDataService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.page = 0;
    this.size = 12;
    this.currentSize = this.size;
    this.tabelLoad(this.page);
  }

  tabelLoad(page) {
    this.masterDataservice
      .getAllMediaList(page, this.currentSize)
      .subscribe((res: any) => {
        if (res.status == 0) {
          this.allMedia = res.response.dataSet;
          /* this.allMedia = res.response.dataSet.filter((d) => {
          if (d.isDeleted == false) {
            return d;
          }
        });*/
          /* this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.number;
        this.totalpageLenght = this.data.response.totalPages;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;*/
        } else {
          this.snackBar.open(res.response, "", {
            duration: 3000,
            panelClass: ["error-snackbar"],
          });
        }
      });
  }

  deleteMedia(id) {
    // if (confirm('Are you sure delete media?')) {
    // const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
    //   width: "600px",
    //   height: "auto",
    //   data: {
    //     message: "Are you sure wants to delete?",
    //     type: "confirmation",
    //   },
    //   panelClass: "confirmpopup",
    // });
    this.masterDataservice.deleteAdminMedia(id).subscribe((res: any) => {
      if (res.status == 0) {
        this.tabelLoad(this.page);
        this.snackBar.open("Deleted Successfully !", "", {
          duration: 3000,
          panelClass: ["success-snackbar"],
        });
      } else {
        this.snackBar.open(res.response, "", {
          duration: 3000,
          panelClass: ["error-snackbar"],
        });
      }
    });
    // }
    // else {

    // }
  }

  loadMore(currentSize) {
    this.currentSize = currentSize + this.size;
    this.tabelLoad(this.page);
    console.log(this.currentSize);
  }

  applyFilter(value: any) {}

  changeMediaStatus(adminMediaId) {
    this.masterDataservice
      .changeMediaStatus(adminMediaId)
      .subscribe((res: any) => {
        if (res.status == 0) {
          this.tabelLoad(this.page);
          this.snackBar.open("Media status change successfully.", "", {
            duration: 3000,
            panelClass: ["success-snackbar"],
          });
        } else {
          this.snackBar.open(res.response, "", {
            duration: 3000,
            panelClass: ["error-snackbar"],
          });
        }
      });
  }
}
