import { Component, OnInit } from '@angular/core';
import {MemberServiceService} from '../servicesMember/member-service.service';
@Component({
  selector: 'app-membership-list',
  templateUrl: './membership-list.component.html',
  styleUrls: ['./membership-list.component.scss']
})
export class MembershipListComponent implements OnInit {
  memberKeyList =[
    {
      "membershipBenefit": {
        "membershipBenefitId": 2,
        "benefitName": "Free Period",
        "benefitType": "Text",
        "sequenceNo": 2
      },
      "benefitValue": "None"
    },
    {
      "membershipBenefit": {
        "membershipBenefitId": 2,
        "benefitName": "Free Period",
        "benefitType": "Text",
        "sequenceNo": 2
      },
      "benefitValue": "None"
    },
  ];
  plandetails = [
   ]
  constructor(public memberShipService : MemberServiceService) { }

  ngOnInit(): void {
    this.lableNameList();
    this.getList();
  }
  lableNameList(){ 
    this.memberShipService.MemberShipLableName().subscribe((res:any)=>{
      if(res.value == 'error'){
        alert('status -1');
      } else {
        this.memberKeyList = res.value; 
      }
      // if(res == 0){     
      //   console.log(res.response)
      //   this.memberKeyList = res.response;
        
      // } else {
      //   alert(res.response);
      // }
    } , (error:any)=>{
      alert(error.error.message);
    }
  );
  }
  getList(){ 
    this.memberShipService.getallMemeberShipdetails().subscribe((res:any)=>{
      if(res.status == 0){     
        console.log(res.response)
        this.plandetails = res.response;
        this.plandetails.sort((a, b) => a.membershipBenefitId - b.membershipBenefitId);
      } else {
        alert(res.response);
      }
    } , (error:any)=>{
      alert(error.error.message);
    }
  );
  }
  isNumber(e){
   
    const d= ( isNaN(Number(e)));
    return d;
  }
}
