import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {map, startWith} from "rxjs/operators";
import { LanguageService } from 'src/app/service/language/language.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-list-question-level',
  templateUrl: './list-question-level.component.html',
  styleUrls: ['./list-question-level.component.scss']
})
export class ListQuestionLevelComponent implements OnInit {

  dataSource = new MatTableDataSource();
  competencyList = [];
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  page: number;
  size: number;
  displayedColumns: string [];
  totalpageLenght: number;
  totalrecordLength: number;
  private data: any;
  filteredApps: Observable<any[]>;
  apps = []
  langList =[];
  selectLang: any;
  @ViewChild('attachments1') myInputVariable: ElementRef;
  fileData: File = null;
  countryFlagImagePath: any;
  BData: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              public langService :LanguageService,
              private masterDataservice: MasterDataService)
  { this.appCtrl = new FormControl();}

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.langList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.langList[0].languageCode;

    // this.langList = JSON.parse(this.langService.getLngList());
    // this.selectLang = this.langList[0].languageCode;
    this.displayedColumns = ['srno', 'groupName','countOfQuestion' ,'actions'];
    // this.tabelLoad(this.page, this.size , this.selectLang);
  }

  tabelLoad(page,selectLang) {
    this.masterDataservice.getAllQuestionLevelList(page,this.size , selectLang).subscribe(res=>{
      this.data = res;
      if( this.data.status == 0 ){
        this.totalrecordLength = 0;
        this.page = 0;
        this.totalpageLenght = 0;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        // this.dataSource.paginator = this.paginator;
        this.apps = this.data.response.dataSet;
        this.apps = this.data.response;
        this.filteredApps = this.appCtrl.valueChanges .pipe(
          startWith(''),
          map(app => app ? this.filterApps(app) : this.apps.slice())
        );
      }
    });

  }

  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.competencyCategoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }

  ngAfterViewInit(){  
    this.tabelLoad(this.page , this.selectLang); 
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  LangChange(e){
    console.log(e);
     this.tabelLoad(0, e.value);
  }
  bulkUpload(){
    if(this.fileData)
      {
        const formData: FormData = new FormData();
        formData.append('file' , this.fileData);
        formData.append('isForceAddNewlanguage' , 'false');        
        this.masterDataservice.jobCategoryBulkUpload(formData).subscribe(res=>{
          this.BData =res;
          if(this.BData.status == 0){
            this.snackBar.open('File Successfuly uploaded.', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.tabelLoad(this.page,this.selectLang);
          } else {
            this.snackBar.open(this.BData.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        },(error)=>{
          this.snackBar.open(error.error.message, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        });
        
      }
    // jobCategoryBulkUpload
  }

  fileSelect(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      this.bulkUpload();
      // tslint:disable-next-line: variable-name
      /*reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };*/
    }
    // this.newimgeuplod = true;
    console.log(this.fileData);
  }

  deleteSkillSet(questionGroupId) {
    this.masterDataservice.deleteQuesitongroup(questionGroupId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.tabelLoad(0 , this.selectLang);
          this.snackBar.open('Deleted successfully !!!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        } else {
          this.snackBar.open(res.response, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }


}
