<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;" >
      <div class="page-header px-0">
        <h3 class="page-title">
          <span *ngIf="!viewID">Add New</span>
          <span *ngIf="viewID">Edit</span>
          Payment</h3>
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
          <li class="breadcrumb-item ">
            <span [routerLink]="['/paymentList']">List Of Payment</span>
          </li>
          <li class="breadcrumb-item active">
               <span >
                <span *ngIf="!viewID">Add New</span>
                <span *ngIf="viewID">Edit</span>
                Payment</span>
          </li>
        </ol>
      </div>
    </div>
    <!--Page Header-->
    <div class="card">
      <div class="card-body">
        <form [formGroup]="formGroup"  appInvalidControlScroll>
            <!-- Payment Gateway Name -->
            <div class="row">
              <div class="form-group col-sm-6">
                <div style="width: 100%;">
                  <div class="form-group row">
                    <label class="col-sm-12">Payment Gateway Name</label>
                    <div class="col-sm-12">
                      <input type="text"
                              class="form-control"
                              (keydown)="firstSpaceRemoveValid($event)"
                              value="" placeholder="Enter Payment Gateway Name" name="name"
                              formControlName="name" required>
                              <mat-error>
                                <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">
                                  Please Enter Payment Gateway Name</span>
                              </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Payment Gateway Nick Name -->
              <div class="form-group col-sm-6">
                <div style="width: 100%;">
                  <div class="form-group row">
                    <label class="col-sm-12">Payment Gateway Short Name</label>
                    <div class="col-sm-12">
                      <input type="text"
                              class="form-control"
                              (keydown)="firstSpaceRemoveValid($event)"
                              value="" placeholder="Enter Payment Gateway Nick Name" name="nickName"
                              formControlName="nickName" required>
                              <mat-error>
                                <span *ngIf="!formGroup.get('nickName').valid && formGroup.get('nickName').touched">
                                  Please Enter Payment Gateway Short Name</span>
                              </mat-error>
                    </div>
                  </div>
                </div>
              </div>
               <!-- Desciption -->
               <div class="form-group row col-sm-12">
                <label class="col-sm-12">Desciption</label>
                <div class="col-sm-12">
                    <textarea
                      style="width: 100%;"
                      rows="5"
                      formControlName="description" required
                      placeholder="Please Enter Description"
                      name="description">
                    </textarea>
                  <mat-error>
                        <span *ngIf="!formGroup.get('description').valid && formGroup.get('description').touched">
                          Please Enter Desciption</span>
                  </mat-error>
                </div>
              </div>
              <!-- API Key -->
              <div class="form-group col-sm-6">
                <div style="width: 100%;">
                  <div class="form-group row">
                    <label class="col-sm-12">API Key</label>
                    <div class="col-sm-12">
                      <input type="password"
                              class="form-control"
                              (keydown)="firstSpaceRemoveValid($event)"
                              value="" placeholder="Enter API Key" name="apiKey"
                              formControlName="apiKey" required>
                        <mat-error>
                          <span *ngIf="!formGroup.get('apiKey').valid && formGroup.get('apiKey').touched">
                            Please Enter API Key</span>
                        </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <!-- User Id -->
              <div class="form-group col-sm-6">
                <div style="width: 100%;">
                  <div class="form-group row">
                    <label class="col-sm-12">User Id</label>
                    <div class="col-sm-12">
                      <input type="password"
                              class="form-control"
                              (keydown)="firstSpaceRemoveValid($event)"
                              value="" placeholder="Enter User Id" name="userId"
                              formControlName="userId" required>
                        <mat-error>
                          <span *ngIf="!formGroup.get('userId').valid && formGroup.get('userId').touched">
                            Please Enter User Id</span>
                        </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Password -->
              <div class="form-group col-sm-6">
                <div style="width: 100%;">
                  <div class="form-group row">
                    <label class="col-sm-12">Password</label>
                    <div class="col-sm-12">
                      <input type="password"
                              class="form-control"
                              (keydown)="firstSpaceRemoveValid($event)"
                              value="" placeholder="Enter Password" name="password"
                              formControlName="password" required>
                              <mat-error>
                                <span *ngIf="!formGroup.get('password').valid && formGroup.get('password').touched">
                                  Please Enter Password</span>
                              </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Secret Key -->
              <div class="form-group col-sm-6">
                <div style="width: 100%;">
                  <div class="form-group row">
                    <label class="col-sm-12">Secret Key</label>
                    <div class="col-sm-12">
                      <input type="password"
                              class="form-control"
                              (keydown)="firstSpaceRemoveValid($event)"
                              value="" placeholder="Enter Secret Key" name="secretKey"
                              formControlName="secretKey" required>
                        <mat-error>
                          <span *ngIf="!formGroup.get('secretKey').valid && formGroup.get('secretKey').touched">
                            Please Enter Secret Key</span>
                        </mat-error>
                    </div>
                  </div>
                </div>
              </div>
            <!-- Currency Supported -->
            <div class="form-group col-sm-6">
                <div style="width: 100%;">
                    <div class="form-group row">
                        <label class="col-sm-12">Currency Supported</label>
                        <div class="col-sm-12 ">
                            <mat-select multiple             
                                formControlName="currency"
                                placeholder="Please Select Currency"
                                class="form-control" required>                  
                                <mat-option *ngFor="let r of currencyList"
                                            [value]="r.currencyId" [aria-label]="r.currencyId">
                                {{r.code}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                              <span *ngIf="!formGroup.get('currency').valid && formGroup.get('currency').touched">
                                Please Enter currency</span>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Country Supported -->
            <div class="form-group col-sm-6">
                <div style="width: 100%;">
                    <div class="form-group row">
                        <label class="col-sm-12">Country Supported</label>
                        <div class="col-sm-12 ">
                            <mat-select multiple             
                                formControlName="country"
                                placeholder="Please Select Country"
                                class="form-control" required>                  
                                <mat-option *ngFor="let r of countryList"
                                    [value]="r.countryId" [aria-label]="r.countryId">
                                    {{r.countryName}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                              <span *ngIf="!formGroup.get('country').valid && formGroup.get('country').touched">
                                Please Enter country</span>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Status -->
            <!-- <div class="form-group row col-sm-12">
                <label class="col-sm-3">Status</label>
               <div class="col-sm-9 ">
                <mat-radio-group aria-label="Select an option"
                formControlName="status" required>
                    <mat-radio-button value="true" [checked]="formGroup.get('status').value == true">Active</mat-radio-button>
                    <mat-radio-button value="false" [checked]="formGroup.get('status').value == false">Inactive</mat-radio-button>
                  </mat-radio-group>
                  <mat-error>
                       <span *ngIf="!formGroup.get('status').valid && formGroup.get('status').touched">
                                      Please Enter Status</span>
                  </mat-error>
                </div>
            </div> -->
            <!-- Gateway Icon / Logo  -->
            <div class="form-group row col-sm-12">
                <label class="col-sm-12">Payment Gateway Image</label>
                <div class="col-sm-12 row">
                  <div class="col-sm-6">
                    <label for="imageUrl" class="custom-file-upload">
                      <i class="fa fa-cloud-upload"></i> Custom Upload
                    </label>
                    <input type="file" name="imageUrl" #file formControlName="imageUrl"
                           accept="image/x-png,image/jpeg"
                           class="imageUploadcss" #attachments1 id="imageUrl"
                           (change)="imageSelect($event,file.files)" />
                  </div>
                  <div class="col-sm-6" *ngIf="imgURL">
                    <div class="close" (click)="imgReset()"><i
                      class="fas fa-times-circle"></i></div>
                    <div class="image-preview">
                      <img [src]="imgURL" height="100" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <button [routerLink]="['/paymentList']" type="button" class="btn btn-danger  pd-x-20 mg-t-10 mar15">Back</button>
          <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!viewID">Submit</button>
          <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="viewID">Update</button>
        </form>
      </div>
    </div>
  </div>