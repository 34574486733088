import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MasterDataService } from "../../../../service/masterData/master-data.service";
import { LanguageService } from "src/app/service/language/language.service";
@Component({
  selector: "app-employer-plan-add-update",
  templateUrl: "./employer-plan-add-update.component.html",
  styleUrls: ["./employer-plan-add-update.component.scss"],
})
export class EmployerPlanAddUpdateComponent implements OnInit {
  @ViewChild("attachments1") myInputVariable: ElementRef;
  hide = true;
  LangList: [];
  formGroup: FormGroup;
  data: any;
  pdata: any;
  id: any;
  submitted = false;
  status: boolean;
  radioValue1: Boolean;
  radioValue2: Boolean;
  radioValue3: Boolean;
  radioValue4: Boolean;
  radioValue5: Boolean;
  radioValue6: Boolean;
  radioValue7: Boolean;
  isActive: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router,
    public langService: LanguageService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.initializeForm();
  }
  get f() {
    return this.formGroup.controls;
  }
  get t() {
    return this.f.planList as FormArray;
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      id: [0, Validators.required],
      planName: ["", Validators.required],
      planDescription: ["", Validators.required],
      monthlyCost: ["", Validators.required],
      subscriptionDuration: ["", Validators.required],
      numbersOfUsers: ["", Validators.required],
      numberOfJobPosting: ["", Validators.required],
      // numberOfJobPostingType: ["true", Validators.required],
      numberOfResumeAccess: ["", Validators.required],
      // numberOfResumeAccessType: ["true", Validators.required],
      bulkEmail: ["", Validators.required],
      // bulkEmailType: ["true", Validators.required],
      bulkSms: ["", Validators.required],
      // bulkSmsType: ["true", Validators.required],
     // cvInInbox: ["true", Validators.required],
      // candidateCalling: ["true", Validators.required],
      // interviewScheduled: ["true", Validators.required],
    });
    if (this.id) {
      this.patchPlanData();
    }
  }
  // get data from Plan ID
  patchPlanData() {
    this.masterService.getEmployerPlanDetailsById(this.id).subscribe(
      (res) => {
        this.data = res;
        if (this.data.status == 0) {
          var data = this.data.response;
          this.formGroup.patchValue({
            value: this.data.response.value,
            id: this.data.response.id,
            planName: this.data.response.planName,
            planDescription: this.data.response.planDescription,
            monthlyCost: this.data.response.monthlyCost,
            subscriptionDuration: this.data.response.subscriptionDuration,
            numbersOfUsers: this.data.response.numbersOfUsers,
            numberOfJobPosting: this.data.response.numberOfJobPosting,
            numberOfJobPostingType: this.data.response.numberOfJobPostingType,
            numberOfResumeAccess: this.data.response.numberOfResumeAccess,
            numberOfResumeAccessType:
              this.data.response.numberOfResumeAccessType,
            bulkEmail: this.data.response.bulkEmail,
            bulkEmailType: this.data.response.bulkEmailType,
            bulkSms: this.data.response.bulkSms,
            bulkSmsType: this.data.response.bulkSmsType,
            // cvInInbox: this.data.response.cvInInbox,
            // candidateCalling: this.data.response.candidateCalling,
            // interviewScheduled: this.data.response.interviewScheduled,
          });
          this.radioValue1 = this.data.response.numberOfJobPostingType;
          this.radioValue2 = this.data.response.numberOfResumeAccessType;
          this.radioValue3 = this.data.response.bulkEmailType;
          this.radioValue4 = this.data.response.bulkSmsType;
          //this.radioValue5 = this.data.response.cvInInbox;
          // this.radioValue6 = this.data.response.candidateCalling;
          // this.radioValue7 = this.data.response.interviewScheduled;
        } else {
          this.snackBar.open("response Status -1 ", "", {
            duration: 3000,
            panelClass: ["error-snackbar"],
          });
        }
        console.log(this.id);
      },
      (error) => console.log(error)
    );
  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      if (this.id) {
        this.masterService
          .updateEmployerPlanDeatils(this.id, this.formGroup.value)
          .subscribe(
            (data: any) => {
              this.snackBar.open("Plan Details Update Successfully !!", "", {
                duration: 3000,
                panelClass: ["success-snackbar"],
              });
              this.router.navigate(["/employerPlanList"]);
            },
            (err) => {
              this.snackBar.open(err.error.message, "", {
                duration: 3000,
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: ["error-snackbar"],
              });
            }
          );
      } else {
        this.masterService
          .createEmployerPlan(this.formGroup.value)
          .subscribe((res) => {
            this.data = res;
            if (this.data.status == 0) {
              this.snackBar.open("Plan Created  Successfully !!", "", {
                duration: 3000,
                panelClass: ["success-snackbar"],
              });
              this.router.navigate(["/employerPlanList"]);
            } else {
              this.snackBar.open(this.data.response, "", {
                duration: 3000,
                panelClass: ["error-snackbar"],
              });
            }
          });
      }
    } else {
      this.snackBar.open("Please Enter All Details", "", {
        duration: 3000,
        panelClass: ["error-snackbar"],
      });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }
}
