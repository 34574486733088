<div class="p-4">
    <div class="admin-dashboard-header m-b-30">
        <h2>ROJGAR MANTRA ANALYTICS</h2>
    </div>
    <div class="row">
        <div class="col-lg-12 admin-dashboard-content">
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-1 m-b-30">
                        <div class="card-body">
                            <img src="assets/img/young-businessman.png" alt="" class="card-img img-fluid">
                            <h3 class="card-title">TOTAL CANDIDATE</h3>
                            <h2 class="card-text">{{totalCandidate}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-2 m-b-30">
                        <div class="card-body">
                            <img src="assets/img/employee-white.png" alt="" class="card-img img-fluid" style="transform: rotate(-90deg);">
                            <h3 class="card-title">TOTAL EMPLOYER</h3>
                            <h2 class="card-text">{{totalEmployer}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-3 m-b-30">
                        <div class="card-body">
                            <img src="assets/img/list-white.png" alt="" class="card-img img-fluid">
                            <h3 class="card-title">TOTAL ACTIVE JOB POST</h3>
                            <h2 class="card-text">{{totalActiveJob}} / {{totalPostedJob}}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-4 m-b-30">
                        <div class="card-body">
                            <img src="assets/img/user-white.png" alt="" class="card-img img-fluid">
                            <h3 class="card-title">SUBSCRIBED CANDIDATE/EMPLOYER </h3>

                            <h2 class="card-text">{{totalSubscribedCandidate}}/{{totalSubscribedEmployer}}</h2>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-7">
            <div class="card white-bg-card m-b-30" style="min-height: 480px;">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <!-- job post status -->
                            <h4 class="card-title">Job Posted Status</h4>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row">
                                        <form [formGroup]="formGroup">
                                            <mat-form-field appearance="fill">
                                                <mat-label>Enter a date range</mat-label>
                                                <mat-date-range-input [rangePicker]="picker1">
                                                    <input matStartDate placeholder="Start date" formControlName="startDate">
                                                    <input matEndDate placeholder="End date" formControlName="endDate">
                                                </mat-date-range-input>
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-date-range-picker #picker1></mat-date-range-picker>
                                            </mat-form-field>
                                        </form>
                                        <!-- <input class="col-md-6 form-control" type="date" id="startdate" name="startdate">
                              <input class="col-md-6 form-control" type="date" id="endDate" name="endDate"> -->
                                        <!-- <mat-form-field class="col-md-6">
                                 <input matInput [matDatepicker]="picker" [max]="minDate" placeholder="From date" onchange="filterData()">
                                 <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                 <mat-datepicker #picker ></mat-datepicker>
                               </mat-form-field>
                              <mat-form-field class="col-md-6">
                                 <input matInput [matDatepicker]="picker1" [max]="maxDate" placeholder="To date">
                                 <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                 <mat-datepicker #picker1 ></mat-datepicker>
                               </mat-form-field> -->
                                    </div>

                                </div>
                                <div class="col-md-4">
                                    <button class="btn outline-btn w-100" (click)="getJobPostedStatus()">Search Job</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="chart">
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions" [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend" [fill]="chartOptions.fill" [stroke]="chartOptions.stroke"
                            [tooltip]="chartOptions.tooltip" [xaxis]="chartOptions.xaxis">
                        </apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="card white-bg-card m-b-30 subcribe-table">
                <div class="card-header border-bottom">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <h4 class="card-title">Subscription Status</h4>
                        </div>
                    </div>
                    <div class="row align-items-center">

                        <div class="col-md-8">
                            <form [formGroup]="form">
                                <mat-form-field appearance="fill">
                                    <mat-label>Enter a date range</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input matStartDate placeholder="Start date" (keyup.enter)="getSubscriptionStatus()" formControlName="startDate" required>
                                        <input matEndDate placeholder="End date" (keyup.enter)="getSubscriptionStatus()" formControlName="endDate" required>
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>

                                </mat-form-field>
                            </form>
                        </div>
                        <div class="col-md-4">
                            <button class="btn outline-btn w-100" (click)="getSubscriptionStatus()">Filter</button>

                        </div>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Recruiter</th>
                                    <th>Candidate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let allSubscriptioni of allSubscription">
                                    <td>{{allSubscriptioni.date | date:'dd-MM-yyyy'}}</td>
                                    <td>{{allSubscriptioni.countRecruiter}}</td>
                                    <td>{{allSubscriptioni.countCandidate}}</td>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="admin-dashboard-header m-b-30">
        <h2 class="mb-0">CRM INSIGHTS</h2>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="card insight-card m-b-30">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="">
                            <div class="img-circle border-success">
                                <img src="assets/img/cost.png" alt="" class="img-fluid insight-card-img">
                            </div>
                        </div>
                        <div class="card-content">
                            <h3>EMPLOYER PLAN REQUEST</h3>
                            <span style="cursor: pointer;" class="badge badge-success" [routerLink]="['/customize-plan-list']">Detail</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card insight-card m-b-30">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="">
                            <div class="img-circle border-danger">
                                <img src="assets/img/curriculum-resume.png" alt="" class="img-fluid insight-card-img">
                            </div>
                        </div>
                        <div class="card-content">
                            <h3>RESUME BOOSTED</h3>
                            <span class="badge badge-danger" style="cursor: pointer;" [routerLink]="['/rusume-boosted-list']">Detail</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card insight-card m-b-30">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="">
                            <div class="img-circle border-warning">
                                <img src="assets/img/resume-builder.png" alt="" class="img-fluid insight-card-img">
                            </div>
                        </div>
                        <div class="card-content">
                            <h3>RESUME BUILDER</h3>
                            <span class="badge badge-warning" style="cursor: pointer;" [routerLink]="['/requestCvList']">Detail</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card insight-card m-b-30">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="">
                            <div class="img-circle border-primary">
                                <img src="assets/img/msg-call.png" alt="" class="img-fluid insight-card-img">
                            </div>
                        </div>
                        <div class="card-content">
                            <h3>CONTACT US</h3>
                            <span class="badge badge-primary" style="cursor: pointer;" [routerLink]="['/contact-us']">Detail</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="admin-dashboard-header m-b-30">
        <h2>LATEST UPDATE</h2>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="card white-bg-card m-b-30">
                <div class="card-header border-bottom">
                    <div class="d-flex align-items-center justify-content-between">
                        <h4 class="card-title">Latest Candidate</h4>
                        <button class="btn outline-btn" [routerLink]="['/candidate-list']">View All</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive update-table">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th width="30%">Candidate Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Subscription</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr *ngFor="let col of email">
                            <td>{{col.email}}</td>
                            <td>{{col.isActive}}</td>
                         </tr> -->
                                <tr *ngFor="let col of latestCandidate">
                                    <td>
                                        <div class="media-box">
                                            <img class="media-avatar img-fluid" src="assets/img/user-circle.png" alt="">
                                            <div class="media-box-body">
                                                <a href="#">{{col.name}}</a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{col.email}}</td>
                                    <!-- <td class="text-center">{{col.subscriptionStatus}}</td> -->
                                    <td *ngIf="col.isActive === true">Active</td>
                                    <td *ngIf="col.isActive === false">Inactive</td>
                                    <td>{{col.subscriptionStatus}}</td>
                                    <!-- <td *ngFor="let col of email">
                              {{col.email}}
                            </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card white-bg-card m-b-30">
                <div class="card-header border-bottom">
                    <div class="d-flex align-items-center justify-content-between">
                        <h4 class="card-title">Latest Recruiter</h4>
                        <button class="btn outline-btn" [routerLink]="['/user-list']">View All</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive update-table">
                        <table class="table table-striped ">
                            <thead>
                                <tr>
                                    <th width="30%">Company Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Subscription</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let col of latestRecruiter">
                                    <td>
                                        <div class="media-box">
                                            <img class="media-avatar img-fluid" src="assets/img/user-circle.png" alt="">
                                            <div class="media-box-body">
                                                <a href="#">{{col.name}}</a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{col.email}}</td>
                                    <!-- <td class="text-center">{{col.isActive}}</td> -->
                                    <td *ngIf="col.isActive === true">Active</td>
                                    <td *ngIf="col.isActive === false">Inactive</td>
                                    <td>{{col.subscriptionStatus}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>