import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LanguageService} from "../../../service/language/language.service";
import {JobTestQuestionManageService} from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";

@Component({
  selector: 'app-list-interview-question',
  templateUrl: './list-interview-question.component.html',
  styleUrls: ['./list-interview-question.component.scss']
})
export class ListInterviewQuestionComponent implements OnInit {

  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number = 0;
  size: number =30;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId:any;
  LangList=[];
  selectLang: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('attachments1') myInputVariable: ElementRef;
  fileData: File = null;
  countryFlagImagePath: any;
  BData: any;

  constructor(private router: Router,
              private snackBar: MatSnackBar,
              public langService :LanguageService,
              private formBuilder: FormBuilder,
              private jobpostQuestionTestService :JobTestQuestionManageService) { this.appCtrl = new FormControl(); }


  ngOnInit(): void {

    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    this.displayedColumns = ['interviewQuestionId','parentcategory', 'displayName', 'question','createdEpochTime' ,'actions'];
    // this.tabelLoad(this.page, this.selectLang);
  }

  tabelLoad(page, lang){
    this.jobpostQuestionTestService.getAllInterviewQuestion(page, this.size, lang,'false').subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
      }
    });
}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  oldpermissionChange (e , element) {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
        this.jobpostQuestionTestService.QuestionStatusChnage(element.assesmentQuestionId).subscribe((data) => {
          // @ts-ignore
          if (data == 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(this.page, this.selectLang);
            this.snackBar.open('status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {
        this.jobpostQuestionTestService.QuestionStatusChnage(element.assesmentQuestionId).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });

          } else {
            e.source.checked = true;
            this.tabelLoad(this.page, this.selectLang);
            this.snackBar.open('status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = false;
      }
    }

  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.groupName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any) {
    // console.log(e);
    this.selectproduct = e.groupName;
    this.selectedId = e.assesmentQuestionId;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search(){

    this.router.navigate(['editIndusrty',this.selectedId]);

  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage(){
    this.tabelLoad(0 , this.selectLang);
  }
  lastpage(){
    this.tabelLoad(this.totalpageLenght, this.selectLang);
  }
  prevpage(){
    const d = this.page - 1;
    if (d > 0){
      this.tabelLoad(d, this.selectLang);
    }
  }
  nextpage(){
    const d = this.page + 1;
    if ( d < this.totalpageLenght){
      this.tabelLoad(d , this.selectLang);
    }
  }

  LangChange(e){
    this.selectLang =e.value;
    this.tabelLoad(this.page,this.selectLang);
  }

  bulkUpload(){
    if(this.fileData)
    {
      const formData: FormData = new FormData();
      formData.append('file' , this.fileData);
      formData.append('isForceAddNewlanguage' , 'false');
      this.jobpostQuestionTestService.bulkUploadJobPostQuestion(formData).subscribe(res=>{
        this.BData =res;
        if(this.BData.status == 0){
          this.snackBar.open('File Successfuly uploaded.', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.tabelLoad(0, this.selectLang);
        } else {
          this.snackBar.open(this.BData.response, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      },(error)=>{
        this.snackBar.open(error.error.message, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      });

    }
    // jobCategoryBulkUpload
  }

  fileSelect(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      this.bulkUpload();
      // tslint:disable-next-line: variable-name
      /*reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };*/
    }
    // this.newimgeuplod = true;
    console.log(this.fileData);
  }

  delete(interviewQuestionId) {
      if (confirm(' Are you sure ?')) {
        this.jobpostQuestionTestService.deleteInterviewQuestion(interviewQuestionId)
          .subscribe(res => {
            this.data = res;
            if(this.data.status == 0 ){
              this.tabelLoad(0 , this.selectLang);
              this.snackBar.open('deleted succesfully!!!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
            } else {
              this.snackBar.open('response Status -1 ', '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }
          }, error => console.log(error));
      }
  }
  ngAfterViewInit(){  
    this.tabelLoad(this.page , this.selectLang);
  }
  onChangePage(e){
    console.log(e);
    // this.page =e.pageIndex;
    this.tabelLoad(e.pageIndex , this.selectLang);
  }

}
