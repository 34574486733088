import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { MemberServiceService } from '../servicesMember/member-service.service';

@Component({
  selector: 'app-membership-add-edit',
  templateUrl: './membership-add-edit.component.html',
  styleUrls: ['./membership-add-edit.component.scss']
})
export class MembershipAddEditComponent implements OnInit {
  viewID: any;
  form: FormGroup;
  planName: any;
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    public memberShipService: MemberServiceService) {

  }
  ngOnInit() {
    this.viewID = this.route.snapshot.params['id'];
    this.form = this.fb.group({
      'membershipName': [],
      'membershipBenefits': this.fb.array([
        this.initX()
      ])
    });

    this.getList();
    // this.form.valueChanges.subscribe(data => this.validateForm());
    // this.validateForm();
  }
  getList() {

    this.memberShipService.getPlanDetailsById(this.viewID).subscribe((res: any) => {
      console.log(res);
      if (res.status == 0) {
        this.planName = res.response.membershipName;
        this.addX(res.response.membershipBenefits.length, res.response);
        // console.log(res.response[0]) 
        this.form.patchValue(res.response);
      } else {
        alert(res.response);
      }
    }, (error: any) => {
      alert(error.error.message);
    }
    );
  }
  initX() {
    return this.fb.group({
      //  ---------------------forms fields on x level ------------------------
      'benefitValue': [''],
      'membershipBenefitId': [],
      'membershipBenefitValueId': [],
      'membershipId': [],
      // ---------------------------------------------------------------------
      'membershipBenefit': this.fb.group({
        'membershipBenefitId': [''],
        'benefitName': [''],
        'benefitType': [''],
        'sequenceNo': [''],
      })
    });
  }

  initY() {
    return this.fb.group({
      //  ---------------------forms fields on y level ------------------------
      'membershipBenefitId': [''],
      'benefitName': [''],
      'benefitType': [''],
      'sequenceNo': [''],
      'dummybeniValue': []
      // ---------------------------------------------------------------------
      // 'Zs': this.fb.array([
      //   this.initZ()
      // ])
    })
  }

  initZ() {
    return this.fb.group({
      //  ---------------------forms fields on z level ------------------------
      'Z': ['Z', [Validators.required, Validators.pattern('[0-9]{3}')]],
      // ---------------------------------------------------------------------
    })
  }

  addX(e, data) {
    const control = <FormArray>this.form.controls['membershipBenefits'];

    for (let i = 1; i < e; i++) {
      control.push(this.initX());

      if (i == e - 1) {
        console.log(data);

        this.form.patchValue(data);
      }
    }

    // control.push(this.initX());
  }


  addY(ix) {
    const control = (<FormArray>this.form.controls['membershipBenefits']).at(ix).get('membershipBenefit') as FormArray;
    control.push(this.initY());
  }

  addZ(ix, iy) {
    const control = ((<FormArray>this.form.controls['membershipBenefits']).at(ix).get('membershipBenefit') as FormArray).at(iy).get('Zs') as FormArray;
    control.push(this.initZ());
  }


  deme(e) {
    console.log(e)
  }

  newsaverror = [];
  save() {

    console.log(this.form.value);
    for (let b of this.form.value.membershipBenefits) {
      console.log(b)
      this.newsaverror.push({ membershipBenefitValueId: b.membershipBenefitValueId, membershipBenefitId: b.membershipBenefit.membershipBenefitId, membershipId: b.membershipId, benefitValue: b.benefitValue })
    }

    console.log(this.newsaverror);
    const mainArray = [
      { 'membershipBenefitValue': this.newsaverror }
    ]
    console.log(mainArray[0])
    this.memberShipService.savePlan(this.newsaverror).subscribe( (res:any)=>{
      if(res.status == 0){
        this.snackBar.open(res.response, '', {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/membershipList']);
      } else {
        alert(res.response);
      }

    } , (error:any)=>{
      alert(error.error.response)
    });

  }

}
