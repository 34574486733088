<div class="container-fluid">
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
                <span _ngcontent-sri-c262="" class="ng-star-inserted">View</span> Contact Detail
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span _ngcontent-sri-c262="" tabindex="0" ng-reflect-router-link="/home">Dashboard</span></li>
                <li class="breadcrumb-item"><span _ngcontent-sri-c262="" tabindex="0" [routerLink]="['/contactList']">Contact List</span></li>
                <li class="breadcrumb-item active">
                    <span>
            <span class="ng-star-inserted">View</span> Contact Details
                    </span>
                </li>
            </ol>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="table-responsive contact-list-view-card">
                                <table class="table table-bordered mb-0">
                                    <tbody>
                                        <tr>
                                            <td>Type:</td>
                                            <td>
                                                <span *ngIf="userRoleType === true">EM</span>
                                                <span *ngIf="userRoleType === false">CN</span>
                                                <span *ngIf="userRoleType === null">WB</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Name:</td>
                                            <td>{{name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><span [innerHtml]="email"></span></td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Number:</td>
                                            <td> <span [innerHtml]="mobileNumber"></span></td>
                                        </tr>
                                        <tr>
                                            <td>Response Type:</td>
                                            <td>{{requestType}}</td>
                                        </tr>
                                        <tr>
                                            <td>Source:</td>
                                            <td>{{source}}</td>
                                        </tr>
                                        <tr>
                                            <td>Query Message:</td>
                                            <td>{{message}}</td>
                                        </tr>
                                        <tr>
                                            <td>Date:</td>
                                            <td>{{submittedDate | date:'dd-MM-yyyy'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>