<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!privacyPolicyId">Add </span>
                <span *ngIf="privacyPolicyId">Edit </span>User policy</h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/user-policy']">Manage User Policy</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!privacyPolicyId">Add </span>
                    <span *ngIf="privacyPolicyId">Edit </span> User Policy </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">

                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">User Type</label>
                        <div class="col-sm-12 ">
                            <mat-select formControlName="userType" required [ngClass]="{ 'is-invalid': submitted && formGroup.get('userType').errors }" placeholder="Please Select User Type" class="form-control">
                                <mat-option *ngFor="let r of alluserType" [value]="r.value" [aria-label]="r.value">
                                    {{r.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span *ngIf="!formGroup.get('userType').valid && formGroup.get('userType').touched">
                      Please Select User Type</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Policy Type</label>
                        <div class="col-sm-12 ">
                            <mat-select formControlName="policyType" required [ngClass]="{ 'is-invalid': submitted && formGroup.get('policyType').errors }" placeholder="Please Select Policy Type" class="form-control">
                                <mat-option *ngFor="let r of policyType" [value]="r.value" [aria-label]="r.value">
                                    {{r.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span *ngIf="!formGroup.get('policyType').valid && formGroup.get('policyType').touched">
                      Please Select Policy Type</span>
                            </mat-error>

                        </div>
                    </div>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12"> Policy Description</label>
                        <div class="col-sm-12 ">
                            <!-- <textarea type="text" class="form-control" value="" placeholder="Enter Policy Description" name="policyDescription" formControlName="policyDescription" required>
                                </textarea> -->
                            <angular-editor formControlName="policyDescription" [config]="config" name="text"></angular-editor>
                            <mat-error>
                                <span *ngIf="!formGroup.get('policyDescription').valid && formGroup.get('policyDescription').touched">
                                    Please Enter Policy Description  </span>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="">
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!privacyPolicyId" style="margin-right: 40px;"  >SUBMIT</button>
                    <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"  style="margin-right: 40px;" [routerLink]="['/user-policy']">BACK</button>

                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="privacyPolicyId">UPDATE</button>


                </div>
            </form>
        </div>
    </div>
</div>