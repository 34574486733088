<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!id">Add </span>
                <span *ngIf="id">Edit </span> Candidate Plan</h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/candidate-plan-list']">Manage Membership List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!id">Add </span>
                    <span *ngIf="id">Edit </span> Candidate Plan </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">Plan Name </label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Plan Name " name="planName" formControlName="planName" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('planName').valid && formGroup.get('planName').touched">
                                    Please Enter Plan Name</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Plan Description </label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Plan Description" name="planDescription" formControlName="planDescription" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('planDescription').valid && formGroup.get('planDescription').touched">
                                    Please Enter Plan Description</span>
                            </mat-error>

                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12"> Cost </label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Monthly Cost" name="monthlyCost" formControlName="monthlyCost" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('monthlyCost').valid && formGroup.get('monthlyCost').touched">
                                    Please Enter  Cost</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Duration(In Days)</label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter TrialPeriod-NoCost" name="subscriptionDuration" formControlName="subscriptionDuration" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('subscriptionDuration').valid && formGroup.get('subscriptionDuration').touched">
                                    Please Enter  Duration in days</span>
                            </mat-error>
                        </div>
                    </div>
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Basic Resume Builder</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="basicResumeBuilder">
                                <mat-radio-button value="true" [checked]="radioValue1 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue1 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Video Resume</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="videoResume">
                                <mat-radio-button value="true" [checked]="radioValue2 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue2 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Resume Booster</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="resumeBooster">
                                <mat-radio-button value="true" [checked]="radioValue3 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue3 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Search Jobs</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="searchJobs">
                                <mat-radio-button value="true" [checked]="radioValue4 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue4 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->

                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Apply Jobs</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="applyJobs">
                                <mat-radio-button value="true" [checked]="radioValue5 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue5 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Job Alert</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="jobAlert">
                                <mat-radio-button value="true" [checked]="radioValue6 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue6 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Skill Assessment Test*</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="skillAssesmentTest">
                                <mat-radio-button value="true" [checked]="radioValue7 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue7 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->

                </div>
                <div class="">
                    <button type="submit" class="btn btn-danger pd-x-20 mg-t-10" routerLink="/candidate-plan-list" tabindex="0" style="margin-right: 10px;">Back</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!id">SUBMIT</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="id">UPDATE</button>
                </div>
            </form>
        </div>
    </div>
</div>