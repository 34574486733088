<div class="container-fluid">
 <!--Page Header-->
 <div class="col-sm-12" style="display: inline-block;" >
  <div class="page-header px-0">
    <h3 class="page-title"><span  *ngIf="!id">Add </span>
      <span  *ngIf="id">Edit </span> Team</h3>
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
      <li class="breadcrumb-item " [routerLink]="['/teamList']" >Manage Team List</li>
      <li class="breadcrumb-item active" >
        <span  *ngIf="!id">Add </span>
        <span  *ngIf="id">Edit </span>
        Team </li>
    </ol>
  </div>
</div>
<!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="formGroup"  >
        <div class="row">
        <div class="form-group col-sm-6 row">
          <label class="col-sm-12">UserName </label>
          <div class="col-sm-12 ">
              <input  type="text"
              (keydown)="firstSpaceRemoveValid($event)"
              class="form-control"
              value="" placeholder="Enter Username" name="name"
                     formControlName="name" required>
            <mat-error>
                 <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">
                                Please Enter Username</span>
            </mat-error>
          </div>
        </div>
        <div class="form-group row col-sm-6">
          <label class="col-sm-12">Email </label>
          <div class="col-sm-12 ">
              <input type="email"  value=""
              class="form-control"
              (keyup)="emailCheckUnique()"
              [pattern]="emailPattern"
              placeholder="Enter Email-id" name="email"
                     formControlName="email" *ngIf="!id" required>

            <input type="email"  value=""
                   class="form-control"
                   (keyup)="emailCheckUnique()"
                   [pattern]="emailPattern"
                   placeholder="Enter Email-id" name="email"
                   formControlName="email" *ngIf="id" readonly>
            <mat-error>
              <span *ngIf="emailpresent">Email Id Already Present</span>
              <span style="text-align: left;"
                    *ngIf="!formGroup.get('email').valid && formGroup.get('email').touched">Please
                                enter Email-id </span>
            </mat-error>
            <mat-error *ngIf="email.errors?.notEquivalent">
           <div>Valid Email address E.x: admin@xyz.com</div>
          </mat-error>
          </div>
        </div>
        <div class="form-group row col-sm-6">
          <label class="col-sm-12">Mobile Number </label>
          <div class="col-sm-12 ">
              <input type="text"  value=""
              class="form-control"
                     (keypress)="numberOnly($event)"
                     placeholder="Enter Mobile Number" name="mobileNumber"
                     formControlName="mobileNumber" required>
            <mat-error>
                            <span *ngIf="!formGroup.get('mobileNumber').valid && formGroup.get('mobileNumber').touched">
                                Please enter Mobile Number</span>
            </mat-error>
          </div>
        </div>
        <div class="form-group col-sm-6 password_fl row" *ngIf="!id">
          <label class="col-sm-12">password </label>
          <div class="col-sm-12 ">
              <input (keydown)="keyDownFunction($event)"
              class="form-control" placeholder="Enter password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
              <button  class="eyeIcon btn" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            <mat-error>
                    <span *ngIf="!formGroup.get('password').valid && formGroup.get('password').touched">
                                Please enter password</span>
            </mat-error>
          </div>
        </div>
        <div class="form-group col-sm-6 row">
          <label class="col-sm-12">Role  </label>
          <div class="col-sm-12 ">
         <mat-select formControlName="roleId" required
              placeholder="Select User Role "
                          class="form-control" >
                <mat-option *ngFor="let r of roleList"
                            [value]="r.roleId" [aria-label]="r.roleId">
                  {{r.roleName}}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!formGroup.get('roleId').valid && formGroup.get('roleId').touched">
                            Please Select User Role </span>
        </mat-error>
          </div>
        </div>
</div>
<div class="">
    <button type="submit" class="btn btn-danger pd-x-20 mg-t-10 mar15" routerLink="/teamList" tabindex="0">Back</button>
    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!id">SUBMIT</button>
    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="id">UPDATE</button>


</div>

      </form>




    </div>
  </div>
</div>
