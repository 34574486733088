<div class="bg-gray-200">
    <div class="modal d-block pos-static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">Copy Test</h6>
                    <button aria-label="Close" [mat-dialog-close]="false" class="close" data-dismiss="modal" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="list-unstyled mb-0">
                        <!-- <li class="row border-bottom-0">
                          <div class="col-sm-3 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Language Name</div>
                          <div class="col-sm-9 mb-2">{{selectedLangName}}</div>
                        </li> -->
                        <li class="row border-bottom-0">
                            <div class="col-sm-3 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Selected Test title:</div>
                            <div class="col-sm-9 mb-2">{{data.title}}</div>
                          </li>
                        <li class="row border-bottom-0">
                          <div class="col-sm-3 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2"> Selected Job Category 
                              of Test
                             </div>
                          <div class="col-sm-9 mb-2">{{data.jobCategory}}</div>
                        </li>
                        </ul>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12">Select New Category</label>
                        <!-- <button class="customeToolTip"  
                        matTooltip="Please select job category"
                        matTooltipPosition="above"
                        aria-label="Please select job category">
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                        </button> -->
                       <div class="col-sm-12 ">
                        <mat-select   
                        (openedChange)="myInput.focus()"
                        (closed)="myInput.value='';search(myInput.value)" 
                        (selectionChange)="jobcatSelection($event)"                       
                       required
                      placeholder="Please Select New Category"
                        class="form-control" > 
                        <input class="myInput form-control" #myInput  type="text" 
                        (keydown)="$event.stopPropagation()"
                        (keyup)="search($event.target.value)"  placeholder="search">
                        <!-- <mat-select-filter [array]="variables" (filteredReturn)="filteredList1 =$event"></mat-select-filter> -->
              <!-- <mat-option value="0">-- Reset List--</mat-option> -->
                        <mat-option *ngFor="let t of jobCatList"      
                          [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                {{t.displayName}}
              </mat-option>
            </mat-select>
                         
                          <mat-error *ngIf="JobQuniError == true">
                            <div>Please Select New Category</div>  
                           </mat-error>
                        </div>
                      </div>  
                </div>
                <div class="modal-footer">
                    <button (click)="submit('0')" class="btn btn-indigo" type="button">Copy Test </button> 
                    <button (click)="submit('1')" class="btn btn-success pd-x-25" type="button">Copy Test & Edit </button> 
                    <button class="btn btn-outline-light" [mat-dialog-close]="false" type="button">Close</button>
                </div>
            </div>
        </div>
    </div>
    </div>
    <!-- modal-wrapper-demo -->
    <!-- <div class="tx-center pd-y-20 bg-gray-200 border-top">
    <a class="btn btn-primary" data-target="#modaldemo3" data-toggle="modal" href="">View Live Demo</a>
    </div> -->
    <!-- pd-y-30 -->
  