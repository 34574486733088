import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterDataService } from '../../../../service/masterData/master-data.service';
import { LanguageService } from 'src/app/service/language/language.service';
@Component({
  selector: 'app-add-update-candidate-plan',
  templateUrl: './add-update-candidate-plan.component.html',
  styleUrls: ['./add-update-candidate-plan.component.scss'],
})
export class AddUpdateCandidatePlanComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  hide = true;
  LangList: [];
  formGroup: FormGroup;
  data: any;
  pdata: any;
  id: any;
  submitted = false;
  status: boolean;
  radioValue1: any;
  radioValue2: any;
  radioValue3: any;
  radioValue4: any;
  radioValue5: any;
  radioValue6: any;
  radioValue7: any;

  isActive: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router,
    public langService: LanguageService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.initializeForm();
  }
  get f() {
    return this.formGroup.controls;
  }
  get t() {
    return this.f.planList as FormArray;
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      // planId:[0 ,Validators.required],
      id: [0, Validators.required],
      planName: ['', Validators.required],
      planDescription: ['', Validators.required],
      monthlyCost: ['', Validators.required],
      subscriptionDuration: ["", Validators.required],
      // basicResumeBuilder: ['', Validators.required],
      videoResume: ['', Validators.required],
      resumeBooster: ['', Validators.required],
      // searchJobs: ['', Validators.required],
      // applyJobs: ['', Validators.required],
      jobAlert: ['', Validators.required],
      // skillAssesmentTest: ['', Validators.required],
    });
    if (this.id) {
      this.patchPlanData();
    }
  }
  // get data from Plan ID
  patchPlanData(): any {
    this.masterService.getCandidatePlanDetailsById(this.id).subscribe(
      (res) => {
        this.data = res;
        if (this.data.status == 0) {
          const data = this.data.response;
          this.formGroup.patchValue({
            value: this.data.response.value,
            planId: this.data.response.planId,
            id: this.data.response.id,
            planName: this.data.response.planName,
            planDescription: this.data.response.planDescription,
            monthlyCost: this.data.response.monthlyCost,
            subscriptionDuration: this.data.response.subscriptionDuration,
            // basicResumeBuilder: this.data.response.basicResumeBuilder,
            videoResume: this.data.response.videoResume,
            resumeBooster: this.data.response.resumeBooster,
            // searchJobs: this.data.response.searchJobs,
            // applyJobs: this.data.response.applyJobs,
            jobAlert: this.data.response.jobAlert,
            // skillAssesmentTest: this.data.response.skillAssesmentTest,
          });
          // this.radioValue1 = this.data.response.basicResumeBuilder;
          this.radioValue2 = this.data.response.videoResume;
          this.radioValue3 = this.data.response.resumeBooster;
          // this.radioValue4 = this.data.response.searchJobs;
          // this.radioValue5 = this.data.response.applyJobs;
          this.radioValue6 = this.data.response.jobAlert;
          // this.radioValue7 = this.data.response.skillAssesmentTest;
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        }
        console.log(this.id);
      },
      (error) => console.log(error)
    );
  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      if (this.id) {
        this.masterService
          .updateCandidatePlanDeatils(this.id, this.formGroup.value)
          .subscribe(
            (data: any) => {
              this.snackBar.open(
                ' Candidate Plan Details Update Successfully !!',
                '',
                {
                  duration: 3000,
                  panelClass: ['success-snackbar'],
                }
              );
              this.router.navigate(['/candidate-plan-list']);
            },
            (err) => {
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['error-snackbar'],
              });
            }
          );
      } else {
        this.masterService
          .createCandidatePlan(this.formGroup.value)
          .subscribe((res) => {
            this.data = res;
            if (this.data.status == 0) {
              this.snackBar.open(
                ' Candidate Plan Created  Successfully !!',
                '',
                {
                  duration: 3000,
                  panelClass: ['success-snackbar'],
                }
              );
              this.router.navigate(['/candidate-plan-list']);
            } else {
              this.snackBar.open(this.data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar'],
              });
            }
          });
      }
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }
}
