import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {CandidateService} from "../../../service/candidateDetails/candidate.service";

@Component({
  selector: 'app-candidate-assessment-test-result',
  templateUrl: './candidate-assessment-test-result.component.html',
  styleUrls: ['./candidate-assessment-test-result.component.scss']
})
export class CandidateAssessmentTestResultComponent implements OnInit {
  viewID: any;
  data: Object;
  displayedColumns: string[] = ['srno', 'category', 'testName', 'date','actions' ];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selectedLang = 'en';
  resultView:boolean = false;
  overallResult:any;

  constructor( private route: ActivatedRoute,
    private candidateService: CandidateService) { }

  ngOnInit(): void {
    this.viewID = this.route.snapshot.params['id'];
    this.getAllPreEmploymentTest();
  }
  getAllPreEmploymentTest(){
    var selectedData = [];
    this.candidateService.getAllPreEmploymentTest(this.selectedLang,this.viewID).subscribe( (res:any) =>{
      if(res.status == 0 ){
        var data = res.response;
        data.forEach(element => { if(element.candidateTesStatus=='Completed') selectedData.push(element); });
        this.dataSource = new MatTableDataSource(selectedData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    })
  }
  getAllPreEmploymentResult(id){
    this.candidateService.getPreEmploymentResult(id).subscribe( (res:any) =>{
      if(res.status == 0 ){
        this.overallResult = res.response;
      }
    })
  }
}
