import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private http: HttpClient) { }
  getManageLanguageData(id) {
    return this.http.get(`${environment.baseurl}/getManageLanguageDetails?languageId=` + id);
  }
  getAllManageLanguagesByAppName(name,isWeb,page,size) {
    return this.http.get(`${environment.baseurl}/getAllManageLanguagesByAppName?appName=`+ name+`&isWeb=` + isWeb+`&page=` + page+`&size=` + size);
  }
  getManageLanguagesByAppNameAndPageName(name,isWeb,pageName,page,size){
    return this.http.get(`${environment.baseurl}/getManageLanguagesByAppNameAndPageNameAndtype?appName=`+ name+`&isWeb=` + isWeb+`&pageName=` + pageName+`&page=` + page+`&size=` + size);
  }
  publishJson(appName,langId,isWeb){
    // @ts-ignore
    return this.http.put(`${environment.baseurl}/publishTheJsonFileByAppName?appName=`+ appName+`&languageId=` + langId+`&isWeb=` + isWeb);
  }
  addLanguage(data){
      return this.http.post(`${environment.baseurl}/addLanguageForJson` ,data);
  }
  getPageName(name){
    return this.http.get(`${environment.baseurl}/getPageNamesIfAlredyExists?pageName=`+ name);
  }
  uploadLanguageFile(data){
    return this.http.post(`${environment.baseurl}/languageJsonBulkUpload`,data);
  }
  getJsonForExcel(appName,langId,isWeb){
    return this.http.get(`${environment.baseurl}/downloadExcelFileForJson?appName=`+ appName+`&languageId=` + langId+`&isWeb=` + isWeb);
  }
}
