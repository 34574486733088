<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
  <div class="page-header px-0">
    <h3 class="page-title">List Of Job Test Question</h3>
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
      <li class="breadcrumb-item active">List Of Job Test Question</li>
    </ol>
  </div>
</div>
<!--Page Header-->

<div class="col-sm-12">
  <div class="card mb-3">
    <h3 class="col-sm-12 mt-3 text-upper">Advance Filter</h3>
    <div class="d-flex table-responsive p-3">
      <div class="mr-2 col-sm-2">
        <!-- (selectionChange)="LangChange($event)" -->
        <mat-select (selectionChange)="LangChange($event)" [(ngModel)]="selectLang" class="form-control">
          <mat-option *ngFor="let r of LangList" [value]="r.languageCode" [aria-label]="r.languageCode">
            {{r.languageName}}
          </mat-option>
        </mat-select>
      </div>
      <div class="mr-2 col-sm-3">
        <mat-select 
        (closed)="myInput.value='';search(myInput.value)" 
        (openedChange)="myInput.focus()" [(ngModel)]="selectQuestionGroup"
          placeholder="Please Select Question Group" class="form-control">
          <input class="myInput form-control" #myInput focused="'true'" type="text"
          (keydown)="$event.stopPropagation()"  
          (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
          <mat-option value=''>All</mat-option>
          <mat-option *ngFor="let r of qustGropList" [value]="r.questionGroupId" [aria-label]="r.questionGroupId">
            {{r.groupName}}
          </mat-option>
        </mat-select>
      </div>
      <div class="mr-2 border-0 d-none d-md-block">
        <input class="form-control" [(ngModel)]="searchQuestionText" placeholder="Search Here">
      </div>
      <div class="col-sm-2">
        <div class="btn btn-primary btn-sm rounded-lg fs-13" (click)="submit()">Search </div>
      </div>
      <div class="col-sm-2">
        <div class="btn btn-danger btn-sm rounded-lg fs-13" (click)="reset()">Reset </div>
      </div>



    </div>
  </div>
  <div class="card">
    <div class="c_table">
      <div class="mat-elevation-z8">
        <div class="d-flex table-responsive p-3">
          <div class="mr-2">
            <div [routerLink]="['/addJobTestQuestion']" class="btn btn-primary btn-sm rounded-lg fs-13" matTooltip="Add new question" matTooltipClass='blue-tooltip'
            matTooltipPosition="above">
              <span >
                <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                Add</span>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="btn btn-primary btn-sm rounded-lg fs-13" (click)="questionImageUploadPopup()" >Question upload </div>
          </div>
          <!-- <div class="mr-2 col-sm-2">
            <mat-select [ngModel]="selectLang"  class="form-control">
              <mat-option *ngFor="let r of LangList" [value]="r.languageCode" [aria-label]="r.languageCode">
                {{r.languageName}}
              </mat-option>
            </mat-select>
          </div>
          <div class="mr-2 col-sm-3">
            <mat-select (openedChange)="myInput.focus()"
                  formControlName="questionGroupId" required placeholder="Please Select Question Group"
                  class="form-control">
                  <input 
                  [(ngModel)]="selectQuestionGroup"
                  class="myInput form-control" #myInput focused="'true'" type="text"
                    (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                  <mat-option *ngFor="let r of qustGropList" [value]="r.questionGroupId"
                    [aria-label]="r.questionGroupId">
                    {{r.groupName}}
                  </mat-option>
                </mat-select>
            <!- <mat-select  (selectionChange)="LangChange($event)" class="form-control">
              <mat-option *ngFor="let r of LangList" [value]="r.languageCode" [aria-label]="r.languageCode">
                {{r.languageName}}
              </mat-option>
            </mat-select> ->
          </div>
          
          <div class="mr-2 border-0 d-none d-md-block">
            <input class="form-control" [(ngModel)]="searchQuestionText" placeholder="Search Here">
          </div>
          <div class="col-sm-2">
            <div class="btn btn-primary btn-sm rounded-lg fs-13" (click)="submit()" >Search </div>
          </div> -->

          <!-- <div class="ml-auto col-sm-2">
            <div class="btn btn-primary btn-sm rounded-lg fs-13" 
            matTooltip="Import Quesiton " matTooltipClass='blue-tooltip'
                              matTooltipPosition="above">
              <span>
                <label for="file" style="margin: 0;">
                  <i class="fa fa-cloud-upload"></i> Import
                </label>
                <input type="file" name="country" #file
                  accept=".csv,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="imageUploadcss" #attachments1 id="file" (change)="fileSelect($event,file.files)" />
              </span>
            </div>
          </div> -->
          <!-- <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
            <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
          </div> -->
        </div>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" *ngIf="dataSource.data.length > 0 ">
          <ng-container matColumnDef="srno">
            <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
            <td mat-cell *matCellDef="let element;let j = index">
              <!-- <span *ngIf="page == 0" > {{j + 1}}</span>
                    <span *ngIf="page != 0" >{{((page * size) +   (j + 1))}}</span> -->
              {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
            </td>
          </ng-container>

          <div>
            
            <ng-container matColumnDef="assesmentQuestionId">
              <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Question Id</th>
              <td width="80px" mat-cell *matCellDef="let element">{{element.assesmentQuestionId}}</td>
            </ng-container>
            <ng-container matColumnDef="groupName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Name</th>
              <td mat-cell *matCellDef="let element">{{element.groupName}}</td>
            </ng-container>
            <ng-container matColumnDef="createdEpochTime">
              <th width="180px" mat-header-cell *matHeaderCellDef mat-sort-header> create date</th>
              <td width="180px" mat-cell *matCellDef="let element">{{element.createdEpochTime  | date:'medium' }}</td>
            </ng-container>
            <ng-container matColumnDef="questionLevel">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Question Type</th>
              <td mat-cell *matCellDef="let element">{{element.questionLevel}}</td>
            </ng-container>


            <ng-container matColumnDef="question">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Question Title</th>
              <td mat-cell *matCellDef="let element">
                <span class="questionTitleREmove" [innerHTML]="truncateChar(element.question)"></span>
                <!-- <img *ngIf="element.questionImage != null" [src]="element.questionImage" height="40px" alt="question">                    -->
              </td>
            </ng-container>

            <ng-container matColumnDef="addedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> addedBy</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex jusify-content-center">
                  <span *ngIf="element.addedBy == 'ADMIN' " class="badge badge-success">{{element.addedBy}}</span>
                  <span *ngIf="element.addedBy != 'ADMIN' " class="badge badge-warning">{{element.addedBy}}</span>
                </div>
              </td>
            </ng-container>
          </div>
          <ng-container matColumnDef="isActive">
            <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              <span>
                <mat-slide-toggle (change)="oldpermissionChange($event,element)" name="isActive"
                  [checked]="element.isActive"></mat-slide-toggle>
              </span>

            </td>
          </ng-container>



          <ng-container matColumnDef="actions">
            <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row ;let j = index;let element">
              <div class="d-flex justify-content-center">
                <!-- badge badge-success btn btn-sm btn-primary rounded-lg -->
                <span class="btn btn-sm btn-primary rounded-lg "
                matTooltip="View/edit Question" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                  *ngIf="!(element.questionLevel == 'COMPREHENSIVE' || element.questionLevel == 'COMPREHENSIVE_IMAGE')"
                  [routerLink]="['/editJobTestQuestion', element.assesmentQuestionId]">
                  <img src="../../../../assets/img/eye.svg" alt="eye">
                </span>
                <span class="btn btn-sm btn-primary rounded-lg"
                matTooltip="edit/view Comprehsive Quesiton" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                  *ngIf="(element.questionLevel == 'COMPREHENSIVE' || element.questionLevel == 'COMPREHENSIVE_IMAGE') "
                  [routerLink]="['/editComprehnsionQuestion', element.assesmentQuestionId]">

                  <img src="../../../../assets/img/eye.svg" alt="eye">
                </span>
                <span class="btn btn-indigo pd-x-20 btn-sm rounded-lg mar5" style="width: 30px;"
                matTooltip="View Question Image" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                  *ngIf="!(element.questionImage == '' || element.questionImage == null) "
                  (click)="imageQuestionPreview(element)"> <img src="../../../../assets/img/image-2-fill.svg"
                    width="30px" alt="eye">
                </span>
                <span class="btn btn-sm btn-danger rounded-lg mar5"
                matTooltip="Delete Question" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                  (click)="deleteQuestionbyId(element.assesmentQuestionId)"><i class="fas fa-trash"></i></span>
              </div>


            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <app-nodata *ngIf="dataSource.data.length == 0 "></app-nodata>

        <mat-paginator #paginatorRef (page)="onChangePage($event)" [length]="totalpageLenght" [pageSize]="30"
          [pageSizeOptions]="[30]" showFirstLastButtons></mat-paginator>
        <!-- <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator> -->
        <!-- <div class="d-flex justify-content-end align-items-center">
                <div class="c_page"><b>Current Page: </b> {{page}} </div>
                <div class="t_recd" ><b>Total Records: </b>  {{totalrecordLength}}</div>
                <ul class="pagination">
                  <li (click)="firstpage()" ><i class="fas fa-angle-double-left" ></i></li>
                  <li (click)="prevpage()" ><i class="fas fa-arrow-left"></i></li>
                  <li (click)="nextpage()" ><i class="fas fa-arrow-right" ></i> </li>
                  <li (click)="lastpage()"> <i class="fas fa-angle-double-right"></i></li>
              </ul>
              </div> -->

      </div>


    </div>
  </div>
</div>