<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">Edit</span>
        Video Interview Question
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span  *ngIf="!viewID" [routerLink]="['/listvideoInterviewQuestion' ,'en']">List Of Video Interview Question</span>
          <span  *ngIf="viewID" [routerLink]="['/listvideoInterviewQuestion' ,'en']">List Of Video Interview Question</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!viewID">Add New</span>
            <span *ngIf="viewID">Edit</span>
            Video Interview Question</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="formGroup" appInvalidControlScroll>
        <div class="row">
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Language Name</label>
            <div class="col-sm-12 ">
              <!-- (selectionChange)="onLanguageChange($event.value)" -->
              <mat-select [disabled]="viewID" 
              (selectionChange)="onLanguageChange($event.value)"
                formControlName="languageId" required
                [ngClass]="{ 'is-invalid': submitted && formGroup.get('languageId').errors }"
                placeholder="Please Select Language Name" class="form-control">
                <mat-option *ngFor="let r of LangList" [value]="r" [aria-label]="r.languageId">
                  {{r.languageName}}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!formGroup.get('languageId').valid && formGroup.get('languageId').touched">
                  Please Select Language Name </span>
              </mat-error>

            </div>
          </div>

          <!--          <div class="form-group row col-sm-12">-->
          <!--            <label class="col-sm-12">Question Type</label>-->
          <!--            <div class="col-sm-12 ">-->
          <!--              <mat-select-->
          <!--                formControlName="questionTypeId" required-->
          <!--                (selectionChange)="QuestionTypeSelectionDropDown($event)"-->
          <!--                placeholder="Please Select Question Type"-->
          <!--                class="form-control" >-->
          <!--                <mat-option *ngFor="let t of qustTypeList1"-->
          <!--                            [value]="t.questionTypeId" [aria-label]="t.qustTypeList1">-->
          <!--                  {{t.questionType}}-->
          <!--                </mat-option>-->
          <!--              </mat-select>-->
          <!--              <mat-error>-->
          <!--                     <span *ngIf="!formGroup.get('questionTypeId').valid && formGroup.get('questionTypeId').touched">-->
          <!--                      Please Select Question Type </span>-->
          <!--              </mat-error>-->
          <!--            </div>-->
          <!--          </div>-->



          <div class="form-group row col-sm-12">
            <label class="col-sm-12">competency Category</label>
            <div class="col-sm-12 ">
              <!-- (selectionChange)="onItemChange($event.value)" -->
              <mat-select 
             
              
                 [multiple]="!viewID"
                [disabled]="viewID"
                [ngClass]="{ 'is-invalid': submitted && formGroup.get('competencyCategoryId').errors }"
                formControlName="competencyCategoryId" required placeholder="Please Select competency Category"
                class="form-control">
                <!--
                  (openedChange)="myInput.focus()"
                (closed)="myInput.value='';competncysearch(myInput.value)"
                  <input class="myInput form-control" #myInput focused="'true'" type="text"
                (keydown)="$event.stopPropagation()"  
                (keyup)="competncysearch($event.target.value)" autocomplete="off" placeholder="search"> -->
                <mat-checkbox *ngIf="!viewID" class="example-margin" [checked]="allComplete" (change)="SelectAll(compentcyCategoryList, $event)">Select All
                </mat-checkbox>
                <mat-option *ngFor="let r of compentcyCategoryList" [value]="r.competencyCategoryId"
                  [aria-label]="r.competencyCategoryId">
                  {{r.displayName}}
                </mat-option>
              </mat-select>
              <mat-error>
                <span
                  *ngIf="!formGroup.get('competencyCategoryId').valid && formGroup.get('competencyCategoryId').touched">
                  Please Select Campetency Category</span>
              </mat-error>
            </div>
          </div>

          <!-- <div class="form-group row col-sm-12" *ngIf="jobDivVal">
            <label class="col-sm-12">Job Category</label>
            <div class="col-sm-12 ">
              <mat-select (selectionChange)="onItemChange($event.value)" (openedChange)="myInput.focus()"
                (closed)="myInput.value='';search(myInput.value)"
                [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobCategoryId').errors }"
                formControlName="jobCategoryId" required placeholder="Please Select Job Category" class="form-control">
                <input class="myInput form-control" #myInput focused="'true'" type="text"
                  (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                <mat-option *ngFor="let r of compentcyCategoryList1" [value]="r.jobCategoryId"
                  [aria-label]="r.jobCategoryId">
                  {{r.displayName}}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!formGroup.get('jobCategoryId').valid && formGroup.get('jobCategoryId').touched">
                  Please Select job Category</span>
              </mat-error>
            </div>
          </div> -->


          <div class="form-group row col-sm-12" *ngIf="keywordBtnHide == true">
            <label class="col-sm-12">Campetancy Keywords</label>
            <div class="col-sm-12">
              <mat-checkbox class="example-margin" [checked]="allComplete" (change)="setAll($event)">Select All
              </mat-checkbox>
              <section class="example-section">
                <mat-checkbox class="example-margin customcheckboxKeyword" *ngFor="let e of keywaordList1; let i=index"
                  [value]="e" [checked]="resultKewords.indexOf(e) >= 0" (change)="onCheckboxChangeNew($event,i,e)">
                  {{ e }}
                </mat-checkbox> &nbsp;&nbsp;&nbsp;
              </section>
            </div>
          </div>


          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Question</label>
            <div class="col-sm-12" [ngClass]="{'is-invalid': submitted && formGroup.get('question').errors }">
              <angular-editor formControlName="question" required class="mrin"
                (oninput)="removeAtt($event , 'question')" (focus)="removeAtt($event,'question')"
                (blur)="removeAtt($event,'question')" (paste)="removeAttpasete($event,'question')"
                (keypress)="removeAtt($event,'question')"
                [ngClass]="{'is-invalid': submitted && formGroup.get('question').errors }"
                placeholder="Please Enter Question" [config]="config" name="question"></angular-editor>
              <mat-error>
                <span *ngIf="!formGroup.get('question').valid && formGroup.get('question').touched">
                  Please Enter Question</span>
              </mat-error>
            </div>
          </div>
          <!-- 20-7-2021 AS PER DISCUSSION REMOVING OPTION ANSWER ONLY QUESTION WILL THERE KEYOWORDS ALSO  -->

          <!-- <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-12">
            <div [formGroup]="ticket" class="col-sm-12 row align-items-center" style="width: 100%;">
              <div class="form-group row col-sm-12">
                <label class="col-sm-12">
                  option{{i+1}} </label>
                <div class="col-sm-12 ">
                  <input type="text" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && ticket.get('response').errors }"
                    (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter option" name="response"
                    formControlName="response" [required]="typeSelect == false">
                  <mat-error>
                    <span *ngIf="submitted && ticket.get('response').errors">
                      Please Enter Option Name </span>
                  </mat-error>
                </div>
              </div>
              <!- <div class="check-top row col-sm-1 ">
                <mat-checkbox class="example-margin"
                              [ngClass]="{'is-invalid': CorrecTanswerNotSlect == true}"
                              formControlName="valid"
                              [checked]="CorrectAnserArray[i] == true"
                              (change)="onCheckboxChange($event,i)"
                >
                </mat-checkbox>

              </div>->
            </div>
          </div>
          <mat-error *ngIf="CorrecTanswerNotSlect == true">
            Please Select Any one CheckBox
          </mat-error>
          <div class="col-sm-12 justify-content-end d-flex" *ngIf="addBtnHide == false">
            <button type="button" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="newOptionadd()">
              <i class="fa fa-plus" aria-hidden="true"></i>Add New Option
            </button>
          </div> -->


   <!-- 20-7-2021 AS PER DISCUSSION REMOVING OPTION ANSWER ONLY QUESTION WILL THERE KEYOWORDS ALSO  -->

        </div>

        <button [routerLink]="['/listvideoInterviewQuestion' , 'en']" type="button"
          class="btn btn-danger  pd-x-20 mg-t-10 mar15">Back</button>
        <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="!viewID">Submit</button>
        <!--        <button (click)="onSubmit('1')" type="submit" class="btn btn-success pd-x-20 mg-t-10 mar15" *ngIf="!viewID && typeSelect == false">Submit & Next</button>-->
        <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="viewID && typeSelect == false">Update</button>
      </form>

    </div>
  </div>

</div>