import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  Ldata: any;
  ListLang=[];
  private headers = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) { }

  // ONLY ACTIVE STATUS LANGUGAE LIST DATA
  getAllLanguageList() : Observable<any> {
    return this.http.get(`${environment.baseurl}/getAllLanguage`,{headers: this.headers})
    .pipe( map((data: any) => {
      if(data.status == 0){
        localStorage.setItem('LanguageList',JSON.stringify(data.response.dataSet));        
        return {value: data.response.dataSet};
      }
    })
    );
}
// FOR LANGUGAE LIST API FOR STATUS
getLangListMaster() : Observable<any> {
  return this.http.get(`${environment.baseurl}/getLanguages`,{headers: this.headers})
  .pipe( map((data: any) => {
    if(data.status == 0){
      const activelist = data.response.dataSet.filter(d=>{
        if(d.isActive == true){
          return d;
        }
      });
      const sortLangList=  activelist.sort((a, b) => (a.languageId - b.languageId));
      localStorage.setItem('LanguageList',JSON.stringify(sortLangList));    
      return {value: data.response.dataSet};
    }
  })
  );
}
  getLngList(){
    return localStorage.getItem('LanguageList');
  }
  AddLang(data){
    return this.http.post<any>(`${environment.baseurl}/addLanguage`,data);
  }
  getJobCategoryByIdForEdit(id){
    return this.http.get<any>(`${environment.baseurl}/getJobCategoryByIdForEdit?id=`+id);
  }
  languageStatusChange(langId){
    return this.http.put<any>(`${environment.baseurl}/changeLanguageStatus?languageId=`+langId,'');
  }
  deletelanguageById(langId){
    return this.http.delete<any>(`${environment.baseurl}/deleteLanguageById?languageId=`+langId)
  }
  
}
