import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DynamicLabelClass} from "../../../../helper/userLevelLink";

@Component({
  selector: 'app-add-update-role',
  templateUrl: './add-update-role.component.html',
  styleUrls: ['./add-update-role.component.scss']
})
export class AddUpdateRoleComponent implements OnInit {
  roleList: any;
  data: any;
  menus: Array<any> = [];
  sidebar: Array<any> = [];
  marketingTeam: Array<any> = [];
  access = false;
  roleName: any;
  Id: any;
  loggedInUser: any;
  userName: any;
  roleId: any;
  demo: any;
  roleData: any;
  roleNameEdit = false;
  roleNameChange: any;
  dashboardlist = [];
  manageTeam = [];
  managePersonalityTest = [];
  reports = [];
  customerSupport = [];
  manageMaster = [];
  manageSetting = [];
  manageRoleSetting = [];
  manageLocation = [];
  manageIOT = [];
  roleType: any;
  status: boolean;
  roleDescription: any;
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  ngOnInit(): void {
    this.roleId = this.route.snapshot.params['id'];
    this. initializeForm();
    this.sidebar = new DynamicLabelClass().sidebar;
    this.marketingTeam = this.sidebar;
    this.dashboardlist =  this.sidebar.slice(0 , 1);
    this.manageTeam =  this.sidebar.slice(1 , 2);
    console.log(this.manageTeam);
    this.manageRoleSetting = this.sidebar.slice(2 , 4);
    this.manageMaster =  this.sidebar.slice(4 , 9);
    this.managePersonalityTest =  this.sidebar.slice(9 , 11);
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      roleName: [ '' , Validators.required],
      roleDescription: [ '' , Validators.required],
    });

    if (this.roleId){
        this.getAccessById();
    }
  }

  getAccessById() {
    this.masterService.getRoleByid(this.roleId).subscribe(data => {
      this.roleData = data;
      if (this.roleData.status == 0) {
        this.formGroup.patchValue({
          roleName:this.roleData.response.roleName,
          roleDescription:this.roleData.response.roleDescription
        });
        this.access = true;
        this.roleNameEdit = true;
        this.menus = this.roleData.response.access;
        console.log(this.menus);
      } else {

      }

    }, (error) => {
      this.snackBar.open('Error from the Server', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }

  onCheckboxChange(e, i) {
    if (e.target.checked) {
      this.menus.push(e.target.value);
    } else {
      var index = this.menus.indexOf(e.target.value);
      this.menus.splice(index, 1);
    }
    console.log(this.menus);
  }


  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }

  onSubmit() {
    const payload = {
    roleName: this.formGroup.value.roleName,
    roleDescription: this.formGroup.value.roleDescription,
    access: this.menus,
    };

    if(this.roleId) {
    this.masterService.updateRole(this.roleId,payload).subscribe(data => {
    this.snackBar.open('Role & Access Update Successfully.', '', {
    duration: 3000,
    panelClass: ['success-snackbar']
    });
    this.router.navigate(['/rollList']);
    },
    (err) => {
    this.snackBar.open(err.error.response,'',{
    duration: 3000,
    verticalPosition: 'top', // 'top' | 'bottom'
    horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
    panelClass: ['error-snackbar']
    });
    }
    );

    }else{
    this.masterService.createRole(payload).subscribe(data => {
    this.snackBar.open('Role & Access Inserted Successfully.', '', {
    duration: 3000,
    panelClass: ['success-snackbar']
    });
    this.router.navigate(['/rollList']);
    },
    (err) => {
    this.snackBar.open(err.error.response,'',{
    duration: 3000,
    verticalPosition: 'top', // 'top' | 'bottom'
    horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
    panelClass: ['error-snackbar']
    });
    }
    );
    }


    }
}
