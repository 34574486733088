<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;" >
      <div class="page-header px-0">
        <h3 class="page-title">
        
          <span >Edit </span>List Personality Test Category</h3>
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
          <li class="breadcrumb-item " [routerLink]="['/listPTcategory']" >List Personality Test Category </li>
          <li class="breadcrumb-item active" >  
            <span  >Edit </span>
            Personality Test Category </li>
        </ol>
      </div>
    </div>       
    <!--Page Header-->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="row">
        <div class="form-group row col-sm-6">
        <label class="col-sm-12">Enter personality test category name</label>
          <div class="col-sm-12 ">            
              <input 
              class="form-control"
              type="text"  value="" placeholder="Enter personality test category name" name="testCategoryName"
                     formControlName="testCategoryName" required>            
            <mat-error>
                 <span *ngIf="!formGroup.get('testCategoryName').valid && formGroup.get('testCategoryName').touched">
                                Please enter personality test category name</span>
            </mat-error>
          </div>
        </div>
        <div class="form-group row col-sm-6">
          <label class="col-sm-12">personality test category description</label>
          <div class="col-sm-12">            
              <textarea 
              class="form-control"
              type="text"  value="" placeholder="Enter personality test category description" name="description"
                     formControlName="description" required>
                     </textarea>
            
            <mat-error>
             <span *ngIf="!formGroup.get('description').valid && formGroup.get('description').touched">
                                Please enter personality test category description</span>
            </mat-error>
          </div>
        </div>
        <div class="">
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="personalityTestCategoryId">Update</button>
        </div>
       
      </form>




    </div>
  </div>

</div>
