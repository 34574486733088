import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }
  getProfileDetailsById(id) {
    return this.http.get(`${environment.baseurl}/getUserById?id=`+id);
  }
  createUser(data){
   return this.http.post(`${environment.baseurl}/createUser` ,data);  
  }
  updateProfileDeatils(id, data){
    return this.http.put(`${environment.baseurl}/userUpdate?id=`+id, data);
  }
  getAllRoleList(page, size){
    return this.http.get(`${environment.baseurl}/getAllRoles?page=`+page +`&size=` +size)
  }
  getRoleDeatils(id){
    return this.http.get(`${environment.baseurl}/getRoleById?roleId=`+id);
  }


}
