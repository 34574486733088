<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
      <h3 class="page-title"><span  *ngIf="!questionTypeId">Add </span>
        <span  *ngIf="questionTypeId">Edit </span> Question Type</h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listQuestionType']" >List Question Type </li>
        <li class="breadcrumb-item active" >
          <span  *ngIf="!questionTypeId">Add </span>
          <span  *ngIf="questionTypeId">Edit </span>
          Question Type </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->

  <div class="card">
    <div class="card-body">

      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" >
        <div class="row">
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">question type</label>
            <div class="col-sm-12 ">
                <input
                class="form-control"
                type="text"  value=""
                (keydown)="firstSpaceRemoveValid($event)"
                placeholder="Enter question type" name="questionType"
                       formControlName="questionType" required>

              <mat-error>
                   <span *ngIf="!formGroup.get('questionType').valid && formGroup.get('questionType').touched">
                                  Please enter question type</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Question type description</label>
            <div class="col-sm-12">
              <angular-editor
              (viewMode)="true" tabIndex="1" (keydown)="firstSpaceRemoveValid($event)"            
                [config]="config"
                (paste)="onPaste($event , 'typeDescription')"
                type="text"  value="" placeholder="Enter question type description" name="typeDescription"
                       formControlName="typeDescription" required></angular-editor>
              <mat-error>
                     <span *ngIf="!formGroup.get('typeDescription').valid && formGroup.get('typeDescription').touched">
                      Enter Question answer Explanation</span>
              </mat-error>
            </div>
          </div>
        </div>
      <div>
        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!questionTypeId">Submit</button>
        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="questionTypeId">Update</button>
      </div>
      </form>




    </div>
  </div>

</div>
