<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!id">Add </span>
        <span *ngIf="id">Edit </span> Template
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/notification-template']">Manage Template</li>
        <li class="breadcrumb-item active">
          <span *ngIf="!id">Add </span>
          <span *ngIf="id">Edit </span>
          Template
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="d-flex">
    <div class="col-sm-8">
      <div class="card">
        <div class="card-body">
          <form id="msform" [formGroup]="addTemplate" appInvalidControlScroll>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label colonclass text-right">Select Language</label>
              <div class="col-sm-10">
                <mat-form-field appearance="outline">
                  <mat-select required [(ngModel)]="selectLanguage" formControlName="languageId">
                    <mat-option value="0">--Select Language -- </mat-option>
                    <mat-option *ngFor="let l of LangList" [value]="l.languageId">
                      {{l.languageName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error>
                  <span *ngIf="!addTemplate.get('languageId').valid && addTemplate.get('languageId').touched">Please
                    Select language</span>
                </mat-error>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label text-right colonclass "> Template Name</label>
              <div class="col-sm-10">
                <mat-form-field appearance="outline">
                  <mat-label>Enter Template Name</mat-label>
                  <input matInput type="text" placeholder="Enter Template Name" formControlName="templateName"
                    name="templateName" required>
                </mat-form-field>
                <mat-error>
                  <span *ngIf="!addTemplate.get('templateName').valid && addTemplate.get('templateName').touched">Please
                    Enter Template Name</span>
                </mat-error>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label colonclass text-right">Enter Subject </label>
              <div class="col-sm-10">
                <mat-form-field appearance="outline">
                  <mat-label>Enter Subject </mat-label>
                  <input matInput type="text" placeholder="Enter Subject" formControlName="subject" name="subject"
                    required>
                </mat-form-field>
                <mat-error>
                  <span *ngIf="!addTemplate.get('subject').valid && addTemplate.get('subject').touched">Please
                    enter Subject</span>
                </mat-error>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label colonclass text-right">Enter To </label>
              <div class="col-sm-10">
                <mat-form-field appearance="outline">
                  <mat-label>Enter To </mat-label>
                  <input matInput type="text" placeholder="Enter To" formControlName="toRecipent" name="toRecipent"
                    required>
                </mat-form-field>
                <mat-error>
                  <span *ngIf="!addTemplate.get('toRecipent').valid && addTemplate.get('toRecipent').touched">Please
                    enter To</span>
                </mat-error>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label colonclass text-right">Select Role</label>
              <div class="col-sm-10">
                <mat-form-field appearance="outline">
                  <mat-select required [(ngModel)]="selectRole" (selectionChange)="roleChange($event.value)"
                    formControlName="roles">
                    <mat-option value="0">--Select Role -- </mat-option>
                    <mat-option *ngFor="let b of roles" [value]="b.value">
                      {{b.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error>
                  <span *ngIf="!addTemplate.get('roles').valid && addTemplate.get('roles').touched">Please
                    Select Role</span>
                </mat-error>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label colonclass text-right">Select Type of Template</label>
              <div class="col-sm-10">
                <mat-form-field appearance="outline">
                  <mat-select required [(ngModel)]="selectType" (selectionChange)="templateTypeChange($event.value)"
                    formControlName="typeOfTemplate">
                    <mat-option value="0">--Select Type of Template -- </mat-option>
                    <mat-option *ngFor="let b of typeoftemp" [value]="b.value">
                      {{b.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error>
                  <span
                    *ngIf="!addTemplate.get('typeOfTemplate').valid && addTemplate.get('typeOfTemplate').touched">Please
                    Select Type of Template</span>
                </mat-error>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label colonclass text-right">Select Scenarios</label>
              <div class="col-sm-10">
                <mat-form-field appearance="outline">
                  <mat-select [(ngModel)]="selectscn" required formControlName="scenerios">
                    <mat-option value="0"> -- Select Scenarios --</mat-option>
                    <mat-option *ngFor="let s of scenariolist" [value]="s.value">
                      {{s.name}}
                    </mat-option>

                  </mat-select>
                </mat-form-field>
                <mat-error>
                  <span *ngIf="!addTemplate.get('scenerios').valid && addTemplate.get('scenerios').touched">Please
                    Select Scenario </span>
                </mat-error>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label colonclass text-right">Template Body</label>
              <div class="col-sm-10">
                <angular-editor formControlName="text" [config]="config" name="text"></angular-editor>
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Enter Page Description</mat-label>
                    <textarea cols="3" matInput name="PageDescription"
                    formControlName="PageDescription" required>  </textarea>
                </mat-form-field> -->
                <mat-error>
                  <span *ngIf="!addTemplate.get('text').valid && addTemplate.get('text').touched">Please
                    Enter Template Body</span>
                </mat-error>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label colonclass text-right"> Template Status </label>
              <div class="col-sm-10">
                <mat-slide-toggle name="isActive" formControlName="isActive" [checked]="isActive"
                  title="Template status">
                </mat-slide-toggle>
                <mat-error>
                  <span *ngIf="!addTemplate.get('isActive').valid && addTemplate.get('isActive').touched">Please
                    enter email Status</span>
                </mat-error>

              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <button type="submit" class="btn btn-danger pd-x-20" routerLink="/notification-template" tabindex="0">Back</button>
                <button class="btn btn-main-primary mar15" (click)="saveTemplate()">
                  <span *ngIf="!id">Submit</span>
                  <span *ngIf="id">Update</span>
                </button>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div style="padding: 0;" class="col-sm-4">
      <div class="card">
        <div class="card-body" style="padding: 10px 3px; font-size: 15px;">
          <h5 class="headEmail">Candidate Key Details :</h5>
          <div class="d-flex" style="margin: 4px 0;" *ngFor="let b of parameterlist">
            <div class="col-sm-6 colonclass " style="text-transform: capitalize;color: #cac7c7;">
              <b>{{b.name}}</b>
            </div>
            <div class="col-sm-6">
              {{b.value}}
            </div>
          </div>
          <h5 class="headEmail">Comapany Key Details :</h5>
          <div class="d-flex" style="margin: 4px 0;" *ngFor="let p of parameterRecruiterlist">
            <div class="col-sm-6 colonclass " style="text-transform: capitalize;color: #cac7c7;">
              <b>{{p.name}}</b>
            </div>
            <div class="col-sm-6">
              {{p.value}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>