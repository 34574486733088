import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-add-editocen-candidatedescription',
  templateUrl: './add-editocen-candidatedescription.component.html',
  styleUrls: ['./add-editocen-candidatedescription.component.scss']
})
export class AddEditocenCANDIDATEDescriptionComponent implements OnInit {

  viewId: any;
  LangList = [];
  config: any;
  ocenCatgoryList = [];

  constructor(private snackBar: MatSnackBar,
    public langService: LanguageService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router) { }

  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  dynamicForm: FormGroup;
  // firstval = ['1', '2', '3'];
  // secondval = ['2', '3', '4'];
  // thirdval = ['3', '4', '5'];
  firstval = '1-3';
  secondval = '2-4';
  thirdval = '3-5';

  first: number = 1;
  secound :number =2;
  three: number = 3;
  four: number = 4;
  five: number = 5;

  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.viewId = (this.route.snapshot.params['id'] == undefined || this.route.snapshot.params['id']  == null || this.route.snapshot.params['id'] == 'undefined') ? 0 : this.route.snapshot.params['id'] ;
    console.log(this.viewId);
    this.ocencategory();
    this.initializeForm();
  }
  ocencategory() {
    this.masterService.getAllPersonalityCategory().subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.ocenCatgoryList = this.data.response.dataSet;

      }
    })
  }

  // To initialize Form
  initializeForm() {
    this.dynamicForm = this.fb.group({
      resultMasterId: [this.viewId],
      personalityScore: ['1', Validators.required],
      personalityId: ['', Validators.required],
      multiLanguage: new FormArray([]),
    });

    if (this.viewId) {
      this.patchData();
    }
    this.LangList = JSON.parse(this.langService.getLngList());
    console.log(this.LangList);
    this.LangListLoad(this.LangList.length);
  }
  // get f() { return this.dynamicForm.controls; }
  get t() { return this.dynamicForm.controls.multiLanguage as FormArray; }

  LangListLoad(e) {
    const numberOfTickets = e
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.formBuilder.group({
          languageId: [this.LangList[i].languageId],
          message: ['', Validators.required],
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  // get data from jobCategoryId
  patchData() {
    this.masterService.getCandidateocenById(this.viewId)
      .subscribe(res => {
        this.data = res;
        if (this.data.status == 0) {
          this.dynamicForm.patchValue(
            this.data.response
       
          );

          this.dynamicForm.patchValue({
            multiLanguage: this.data.response.multiLanguage
          })


        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.dynamicForm.valid) {
      if (this.viewId) {
        this.masterService.addCandidateocen( this.dynamicForm.value)
          .subscribe((data: any) => {
            if (data.status == 0) {
              this.snackBar.open(' Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listCandidateOcean']);
            }
            else {
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }

          },
            (err) => {
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else {
        this.masterService.addCandidateocen(this.dynamicForm.value).subscribe((data: any) => {
          if (data.status == 0) {
            this.snackBar.open(' Inserted Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listCandidateOcean']);
          }
          else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
}

