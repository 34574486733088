<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!skillSetId">Add </span>
                <span *ngIf="skillSetId">Edit </span> Skill
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/listSkillSet']"> List Skill</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!skillSetId">Add </span>
                    <span *ngIf="skillSetId">Edit </span> Skill
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">

            <form [formGroup]="dynamicForm" class="row" appInvalidControlScroll>
                <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-6">
                    <div [formGroup]="ticket" class="col-sm-12">
                        <div class="form-group row ">
                            <label class="col-sm-12">
                {{LangList[i].languageName}} skill Name</label>
                            <div class="col-sm-12 ">
                                <input [ngClass]="{ 'is-invalid': submitted && ticket.get('skillName').errors }" type="text" class="form-control" value="" placeholder="Enter skill name" name="name" formControlName="skillName" required>
                            </div>
                            <mat-error>
                                <span *ngIf="!ticket.get('skillName').valid && ticket.get('skillName').touched">
                  Please enter skillName</span>
                            </mat-error>
                        </div>
                        <div class="form-group row ">
                            <label class="col-sm-12">
                {{LangList[i].languageName}} skill Description </label>
                            <div class="col-sm-12 ">
                                <angular-editor [ngClass]="{ 'is-invalid': submitted && ticket.get('skillDescription').errors }" formControlName="skillDescription" required [config]="config" placeholder="Please Enter skill Description" name="skillDescription"></angular-editor>
                                <mat-error>
                                    <span *ngIf="!ticket.get('skillDescription').valid && ticket.get('skillDescription').touched">
                    Please enter skillDescription</span>
                                </mat-error>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-sm-12">
                    <button type="submit" (click)="onSubmit()" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!skillSetId">Submit</button>
                    <button type="submit" (click)="onSubmit()" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="skillSetId">Update</button>
                </div>

            </form>




        </div>
    </div>

</div>