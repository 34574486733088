import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {LoginService} from "../../service/login/login.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {ComponentType} from "@angular/cdk/overlay";
import { LanguageService } from 'src/app/service/language/language.service';
import {EncryptDecryptServiceService} from "../../service/encrypt-decrypt/encrypt-decrypt-service.service";

class T {
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  logndata: any;
  firstFormGroup: FormGroup;
  // @ViewChild("username", {static : true}) username: any;
  retryError = false;
  private PopupalertComponent: ComponentType<T>;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder,
      private langService :LanguageService,
      private router: Router,
      private loginService: LoginService, private snackBar: MatSnackBar,
      private encryptDecryptService: EncryptDecryptServiceService,
    ) { }


  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
   
     const loginpayload ={
      email: this.firstFormGroup.value.username,
     // password: this.firstFormGroup.value.password
     password: this.encryptDecryptService.encrypt(this.firstFormGroup.value.password),
     };

     this.loginService.login(loginpayload).subscribe(data => {
       this.logndata = data;

       // @ts-ignore
       if (data && data.value === 'Not Authorized') {
       this.snackBar.open('Wrong Username or Password Enter , try Again', '', {
           duration: 3000,
           panelClass: ['error-snackbar']
        });
         this.retryError = true;

       } else if(this.logndata && this.logndata.value  === 'Inactive'){
         this.snackBar.open('User is Inactive , Contact To Your Admin and Activate Your Account', '', {
           duration: 3000,
           panelClass: ['error-snackbar']
         });
         this.retryError = true;
       } else {
         // @ts-ignore
         if (data && data.value === 'Another User LoggedIn') {
                                //  this.anotherUserLoggedIn(); 
                                } else {

                                  this.snackBar.open('Login Successfully', '', {
           duration: 3000,
           panelClass: ['success-snackbar']
         });
         this.langService.getAllLanguageList().subscribe((res:any)=>{
          
         });
                                  this.router.navigate(['/home']);
       }}
     }, (error => {
      this.snackBar.open('Please Enter correct details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      //  const dialogRefInnerElse = this.dialog.open(this.PopupalertComponent, {
      //    width: '500px',
      //    height: 'auto',
      //    panelClass: 'alertbox',
      //    data: {
      //      message: '',
      //      method: 'error',
      //      head: 'Error',
      //      htmlinner: error.error.response,
      //    }
      //  });

      //  dialogRefInnerElse.afterClosed().subscribe(result => {
      //    // console.log('The dialog was closed');
      //  });
       // alert('Some error happened, please retry');
     }));
  }


  forgotpassword() {
    // const dialogRef = this.dialog.open(ForgotpasswordComponent, {
    //   width: '500px',
    //   height: 'auto',
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log('The dialog was closed');
    // });
  }

  // keyDownFunction(event) {
  //   if (event.keyCode === 13) {
  //     this.onSubmit();
  //   }
  // }
  // ngAfterViewInit(){
  //   // this.username.nativeElement.focus();
  // }

  // private anotherUserLoggedIn() {

  // }
}
