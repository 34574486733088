import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-candidateocen-description-list',
  templateUrl: './candidateocen-description-list.component.html',
  styleUrls: ['./candidateocen-description-list.component.scss']
})
export class CANDIDATEocenDescriptionListComponent implements OnInit {

  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number = 0 ;
  size: number = 10;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId:any;
  LangList=[];
  selectLang: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private router: Router,
              private snackBar: MatSnackBar,
              public langService :LanguageService,
              private formBuilder: FormBuilder,
              private masterDataservice :MasterDataService) { }

  ngOnInit(): void {
   
    this.LangList = JSON.parse(this.langService.getLngList());  
    this.selectLang = this.LangList[0].languageCode;
    this.displayedColumns = ['srno', 'category',  'personalityScore','actions'];
    // this.tabelLoad(this.page, this.selectLang);
  }


  tabelLoad(page, lang){
    this.masterDataservice.getAllCandidateocenList(page, this.size, lang).subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
     
      }
    })


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 
  oldpermissionChange (e , element) {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
        this.masterDataservice.changeJobIndustryStatus(element.jobIndustryId).subscribe((data) => {
          // @ts-ignore
          if (data == 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(0, this.selectLang);
            this.snackBar.open('Job industry status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {
        this.masterDataservice.changeJobIndustryStatus(element.jobIndustryId).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });

          } else {
            e.source.checked = true;
            this.tabelLoad(0, this.selectLang);
            this.snackBar.open('Job industry status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = false;
      }
    }

  }

 


  // delete(jobIndustryId) {
  //   this.masterDataservice.deleteJobIndustry(jobIndustryId)
  //     .subscribe(res => {
  //       this.data = res;
  //       if(this.data.status == 0 ){
  //         this.tabelLoad(0 , this.selectLang);
  //         this.snackBar.open('Job industry deleted succesfully!!!', '', {
  //           duration: 3000,
  //           panelClass: ['success-snackbar']
  //         });
  //       } else {
  //         this.snackBar.open('response Status -1 ', '', {
  //           duration: 3000,
  //           panelClass: ['error-snackbar']
  //         });
  //       }
  //     }, error => console.log(error));
  // }
  LangChange(e){
    console.log(e);
    this.tabelLoad(0,e.value);
  }
  ngAfterViewInit() {
    this.tabelLoad(this.page, this.selectLang);
  }
  onChangePage(e) {
    this.page = e.pageIndex;
    this.tabelLoad(e.pageIndex, this.selectLang);
  }
}