<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">Edit</span>
        Job Board
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listJobBoard']">List Of Job Board</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!viewID">Add New</span>
            <span *ngIf="viewID">Edit</span>
            Job Board</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="formGroup" appInvalidControlScroll>
        <!-- Job Plan -->
        <div class="row">
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Job Plan<span>*</span></label>
            <div class="col-sm-6 ">
              <mat-select formControlName="freeJobPlan" required
                [ngClass]="{ 'is-invalid': submitted && formGroup.get('freeJobPlan').errors }"
                placeholder="Please Select Job Plan" class="form-control" multiple>
                <mat-option *ngFor="let r of jobplanList" [value]="r.planId" [aria-label]="r.planId">
                  {{r.planName}}
                </mat-option>
              </mat-select>
              <mat-error>
                <span
                  *ngIf="(submitted && formGroup.get('freeJobPlan').errors) || !formGroup.get('freeJobPlan').valid && formGroup.get('freeJobPlan').touched">
                  Please Select Job Plan </span>
              </mat-error>
            </div>
          </div>
        </div>

        <!-- Job Board -->
        <div class="row">
          <div *ngFor="let ticket of jb.controls; let i = index" class="form-group col-sm-4">
            <div [formGroup]="ticket" style="width: 100%;">
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Job Board Name<span>*</span></label>
                <div class="col-sm-12 ">
                  <input type="text" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                    placeholder="Enter Job Board Name" name="value"
                    [ngClass]="{ 'is-invalid': submitted && ticket.get('value').errors }" formControlName="value">
                  <mat-error>
                    <span
                      *ngIf="(submitted && ticket.get('value').errors) || !ticket.get('value').valid && ticket.get('value').touched">
                      Please enter Job Board Name</span>
                  </mat-error>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- Job Board Description -->
        <div class="row">
          <div *ngFor="let ticket of jd.controls; let i = index" class="form-group col-sm-6">
            <div [formGroup]="ticket" style="width: 100%;">
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Job Board Description<span>*</span></label>
                <div class="col-sm-12 ">
                  <angular-editor formControlName="value" placeholder="Please Enter Job Board Description"
                    [ngClass]="{ 'is-invalid': submitted && ticket.get('value').errors }" [config]="config"
                    name="jobBoardDescription"></angular-editor>
                  <mat-error>
                    <span
                      *ngIf="(submitted && ticket.get('value').errors) || !ticket.get('value').valid && ticket.get('value').touched">
                      Please enter Job Board Description</span>
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- Logo -->
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Logo<span>*</span></label>
            <div class="col-sm-12 row">
              <div class="col-sm-6">
                <label for="country" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Custom Upload
                </label>
                <input type="file" name="country" #file formControlName="countryFlag" accept="image/x-png"
                  class="imageUploadcss" #attachments1 id="country" (change)="countryFlagSelct($event,file.files)" />
              </div>
              <div class="col-sm-6" *ngIf="countryFlagImgURL">
                <div class="close" (click)="countryFlagReset()"><i class="fas fa-times-circle"></i></div>
                <div class="image-preview">
                  <img [src]="countryFlagImgURL" height="100" />
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <mat-error>
                <span *ngIf="submitted && !countryFlagImgURL">
                  Please Select Logo</span>
              </mat-error>
              <mat-error>
                <span *ngIf="checksize" style="margin-left: 15px;">Maximum size should be 225*75 px</span>
              </mat-error>
            </div>

          </div>
          <!-- ThumbImage -->
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Thumb Image<span>*</span></label>
            <div class="col-sm-12 row">
              <div class="col-sm-6">
                <label for="thumbImage" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Custom Upload
                </label>
                <input type="file" name="thumbImage" #file2 formControlName="thumbImage" accept="image/x-png"
                  class="imageUploadcss" #attachments2 id="thumbImage"
                  (change)="thumbImageSelect($event,file2.files)" />
              </div>
              <div class="col-sm-6" *ngIf="thumbImageURL">
                <div class="close" (click)="thumbImageReset()"><i class="fas fa-times-circle"></i></div>
                <div class="image-preview">
                  <img [src]="thumbImageURL" height="100" />
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <mat-error>
                <span *ngIf="submitted && !thumbImageURL">
                  Please Select Thumb Image</span>
              </mat-error>
            </div>
          </div>
        </div>
        <!-- Website URL -->
        <div class="row">
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Website URL<span>*</span></label>
            <div class="col-sm-12 ">
              <input type="text" class="form-control" value="" placeholder="Enter Website URL" name="websiteUrl"
                formControlName="websiteUrl"
                [ngClass]="{ 'is-invalid': submitted && formGroup.get('websiteUrl').errors }">
              <mat-error>
                <span
                  *ngIf="(submitted && formGroup.get('websiteUrl').errors) || !formGroup.get('websiteUrl').valid && formGroup.get('websiteUrl').touched">
                  Please Enter Website URL</span>
              </mat-error>

            </div>
          </div>
        </div>
        <!-- Currency -->
        <div class="row">
          <div *ngFor="let ticket of currency.controls; let i = index" class="form-group col-sm-3">
            <div [formGroup]="ticket" style="width: 100%;">
              <div class="form-group row">
                <label class="col-sm-12">
                  Plan Price in {{ticket.get('code').value}}({{ticket.get('symbol').value}}) <span>*</span></label>
                <div class="col-sm-12 ">
                  <input type="number" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                    placeholder="Enter Plan Price" name="value" formControlName="value"
                    [ngClass]="{ 'is-invalid': submitted && ticket.get('value').errors }">
                  <mat-error>
                    <span
                      *ngIf="(submitted && ticket.get('value').errors) || !ticket.get('value').valid && ticket.get('value').touched">
                      Please Enter Plan Price in {{ticket.get('code').value}}({{ticket.get('symbol').value}})</span>
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button [routerLink]="['/listJobBoard']" type="button"
          class="btn btn-danger  pd-x-20 mg-t-10 mar15">Back</button>
        <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="!viewID">Submit</button>
        <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="viewID">Update</button>
      </form>

    </div>
  </div>

</div>