import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MasterDataService} from '../../../../service/masterData/master-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LanguageService} from '../../../../service/language/language.service';

@Component({
  selector: 'app-add-update-competency-keyword',
  templateUrl: './add-update-competency-keyword.component.html',
  styleUrls: ['./add-update-competency-keyword.component.scss']
})
export class AddUpdateCompetencyKeywordComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  fileData: File = null;
  categoryList: any[];
  competencyCategoryList: any[];
  private roleData: any;
  competencyCategoryId: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  LangList = [];

  constructor(public langService: LanguageService,
              private fb: FormBuilder,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private masterService: MasterDataService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  public show = false;
  public show1 = true;

  ngOnInit(): void {
    this.competencyCategoryId = this.route.snapshot.params.id;
    this.initializeForm();
    this.getAllCompetencyLists();
  }

  // tslint:disable-next-line:typedef
  initializeForm() {
    this.formGroup = this.fb.group({
      cvaCompetencyCategoryId: ['', Validators.required],
      languageNames: new FormArray([]),
    });
    if (this.competencyCategoryId){
      this.patchCompetancyData();
    }
    this.LangList = JSON.parse(this.langService.getLngList());
    console.log(this.LangList);
    this.LangListLoad(this.LangList.length);

  }

  // get f() { return this.dynamicForm.controls; }
  // tslint:disable-next-line:typedef
  get t() { return  this.formGroup.controls.languageNames as FormArray; }

  // tslint:disable-next-line:typedef
  LangListLoad(e) {
    const numberOfTickets = e;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.formBuilder.group({
          languageId: [this.LangList[i].languageId],
          name: [ '' , Validators.required],
          descriptionName: [ '' , Validators.required],
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  // tslint:disable-next-line:typedef
  patchCompetancyData(){
    this.masterService.getRestrictedKeywordById(this.competencyCategoryId)
      .subscribe(res => {
        this.data = res;
        // tslint:disable-next-line:triple-equals
        if (this.data.status == 0 ){
          // @ts-ignore
          this.dynamicForm.patchValue(
            this.data.response

          );
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }


  // tslint:disable-next-line:typedef
  getAllCompetencyLists() {
    this.competencyCategoryList = [];
    this.masterService.getAllCompetencyCategory(0, 2000, 'en').subscribe(res => {
      this.roleData = res;
      // tslint:disable-next-line:triple-equals
      if (this.roleData.status == 0) {
        this.competencyCategoryList = this.roleData.response.dataSet.filter((d) => {
          // tslint:disable-next-line:triple-equals
          if (d.isSubCategory == true) {
            return d;
          }
        });
      } else {
        this.snackBar.open('response Status -1 ', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }


  onSubmit() {
    if (this.formGroup.valid) {
      this.masterService.createCompetencyKeyword(this.formGroup.value).subscribe((data: any) => {
        if (data.status === 0) {
          this.snackBar.open('Competancy Keyword Inserted Successfully !!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/listCompetancyKeywords']);
        } else {
          this.snackBar.open(data.response, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      });
    }
    else{
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

  selectedValue(e) {
    if (e.value == true)
    {
      // this.show = !this.show;
      this.show = true;
      this.show1 = false;
    }
    else {
      this.show = false;
      this.show1 = true;
    }

  }

  firstSpaceRemoveValid($event: KeyboardEvent) {

  }
}
