import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from "../../../service/language/language.service";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import {MatDialog} from "@angular/material/dialog";
import {JobTestQuestionManageService} from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";

@Component({
  selector: 'app-view-user-details',
  templateUrl: './view-user-details.component.html',
  styleUrls: ['./view-user-details.component.scss']
})
export class ViewUserDetailsComponent implements OnInit {
  formGroup: any;
  page:any;
  size:any;
  viewID: any;
  selectedTab: any;
  LangList: any;
  selectLang: any;
  pdata: any;
  allRecruiterDetails: any;
  country: any;
  countryName: any;
  cityName: any;
  firstName: any;
  lastName: any;
  profileImage: any;
  jobRole: any;
  aboutMe: any;
  location: any;
  mobileNumber: any;
  email: any;
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public langService : LanguageService,
              private masterDataservice: MasterDataService,
              public dialog: MatDialog,
              private router: Router) { }

  ngOnInit(): void {
    this.page = 0;
    this.size = 1000;
    this.viewID = this.route.snapshot.params['id'];
    this.selectedTab = this.route.snapshot.params['tab'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    this.initializeForm();
  }


  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      firstName: [ '' , Validators.required],
      lastName: [ '' , Validators.required],
      email: [ '' , Validators.required],
      mobileNumber: [ '' , Validators.required],
      jobRole: [ '' , Validators.required],
      location: [ '' , Validators.required],
      aboutMe: [ '' , Validators.required],
      cityName: [ '' , Validators.required],
      countryName: [ '' , Validators.required],
    });
    if (this.viewID){
      this.patchData();
    }
  }
  patchData(){
    this.masterDataservice.getTeamsDetailsByCompanyIdData(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0 ){
          this.formGroup.patchValue(this.pdata.response);
          this.allRecruiterDetails  = this.pdata.response;
          this.profileImage = this.pdata.response.profileImage;
          this.firstName = this.pdata.response.firstName;
          this.lastName = this.pdata.response.lastName;
          this.jobRole = this.pdata.response.jobRole;
          this.aboutMe = this.pdata.response.aboutMe;
          this.location = this.pdata.response.location;
          this.cityName = this.pdata.response.cityName;
          this.countryName = this.pdata.response.countryName;
          this.mobileNumber = this.pdata.response.mobileNumber;
          this.email = this.pdata.response.email;
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));

  }
}
