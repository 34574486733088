import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HomeService } from "src/app/service/home/home.service";
@Component({
  selector: "app-job-details-details",
  templateUrl: "./job-details-details.component.html",
  styleUrls: ["./job-details-details.component.scss"],
})
export class JobDetailsDetailsComponent implements OnInit {
  id: any;
  jobsData: any;
  benifits: any;
  specificSkill: any;
  noOfOpening: any;
  zipcode: any;
  jobHeadline: any;
  role: any;
  candidateCallTime: any;
  expMin: any;
  expMax: any;
  jobDetails: any;
  jobRequirement: any;
  salaryType: any;
  salaryRangeTo: any;
  education: any;
  jobEndDate: any;
  companyName: any;
  personName: any;
  phoneNumber: any;
  email: any;
  jobAddress: any;
  questionnaireId: any;
  response: any;
  dateJobPosted: any;
  educationName: any;
  salaryTypeName: any;
  jobCategoryNam: any;
  skillsName: any;
  employmentTypeName: any;
  cityName: any;
  stateName: any;
  questionnaireList: any;
  skillsList: any;
  createdEpochTime: any;
  updatedEpochTime: any;
  salaryMin: any;
  salaryMax: any;
  viewID: any;
  jobId: any;
  companyId: any;
  page: any = [];
  size: any = [];
  status: any;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private masterDataservice: MasterDataService,
    private homeService: HomeService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.jobId = this.route.snapshot.params["jobId"];
    this.getJobPostDetails();
    // this.getLatestRecruiter(this.page, this.size);
  }
  // getLatestRecruiter(page, size: any): any {
  //   this.masterDataservice.getAllUserList(this.page, size).subscribe((res) => {});
  // }
  getJobPostDetails(): any {
    this.masterDataservice.getJobDetailsById(this.jobId).subscribe(
      (res: any) => {
        if (res.status == 0) {
          this.jobId = res.response.jobId;
          this.noOfOpening = res.response.noOfOpening;
          this.zipcode = res.response.zipcode;
          this.jobHeadline = res.response.jobHeadline;
          this.role = res.response.role;
          this.candidateCallTime = res.response.candidateCallTime;
          this.expMin = res.response.expMin;
          this.expMax = res.response.expMax;
          this.jobDetails = res.response.jobDetails;
          this.jobRequirement = res.response.jobRequirement;
          this.salaryType = res.response.salaryType;
          this.salaryRangeTo = res.response.salaryRangeTo;
          this.education = res.response.education;
          this.jobEndDate = res.response.jobEndDate;
          this.companyName = res.response.companyName;
          this.personName = res.response.personName;
          this.phoneNumber = res.response.phoneNumber;
          this.email = res.response.email;
          this.jobAddress = res.response.jobAddress;
          this.questionnaireId = res.response.questionnaireId;
          this.response = res.response.response;
          this.dateJobPosted = res.response.dateJobPosted;
          this.educationName = res.response.educationName;
          this.salaryTypeName = res.response.salaryTypeName;
          this.jobCategoryNam = res.response.jobCategoryNam;
          this.skillsName = res.response.skillsName;
          this.employmentTypeName = res.response.employmentTypeName;
          this.cityName = res.response.cityName;
          this.stateName = res.response.stateName;
          this.questionnaireList = res.response.jobquestionnaireList;
          this.skillsList = res.response.skillsName.split(",");
          this.createdEpochTime = res.response.createdEpochTime;
          this.updatedEpochTime = res.response.updatedEpochTime;
          this.status = res.response.status;
          this.salaryMin = res.response.salaryMin;
          this.salaryMax = res.response.salaryMax;
        } else {
          alert("Data not available.");
        }
      },
      (error: any) => {
        alert(error.error.message);
      }
    );
  }

  onSubmit(): any {
    this.masterDataservice.getJobByActive(this.jobId).subscribe((res: any) => {
      if (res.status === 0) {
        this.snackBar.open("Job Posted Successfully!!", "", {
          duration: 3000,
          panelClass: ["success-snackbar"],
        });
        this.router.navigate(["/user-list"]);
      }
    });
  }
}
