import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, private router: Router,
    private snackBar: MatSnackBar,) { }

  login(data) {
    return this.http.post<any>(`${environment.baseurl}/auth/authenticate`, data)
      .pipe(
        map( LData => {
          console.log(LData);
          if (LData.status === 0) {
            localStorage.setItem('Id', LData.response.id);
            localStorage.setItem('token',LData.response.token);
            return {value: '0'};
          } else {
            return {value: '1' , Error: LData.Error};
          }
        })
      );
  }
  loginId(){
    const userId = localStorage.getItem('Id');
    return userId;
  }
    logoutFromButton(): void {

    this.http.get(`${environment.baseurl}/logout`).subscribe(data => {
    }, error => {});
    localStorage.removeItem('token');
    localStorage.removeItem('dynamicLabelName');
    localStorage.removeItem('accesslist');
    this.loggedIn.next(false);
  }

}
