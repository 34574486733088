<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!locationId">Add </span>
                <span *ngIf="locationId">Edit </span> Location
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/location-list']">Manage Location List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!locationId">Add </span>
                    <span *ngIf="locationId">Edit </span> Location
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">Country </label>
                        <div class="col-sm-12 ">
                            <!-- <mat-select type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="Enter  Country  " name="locationCountry"
                                formControlName="locationCountry" required>
                                <mat-option value="India">India</mat-option>
                            </mat-select> -->
                            <mat-select  placeholder="Select Country"
                                formControlName="locationCountry" class="form-control" required countrySelect
                                (openedChange)="myInputCountry.focus();"
                                (closed)="myInputCountry.value='';countrySearch(myInputCountry.value)">
                                <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control"
                                    #myInputCountry focused="'true'" type="text" 
                                    autocomplete="off"  (keydown)="$event.stopPropagation()">
                                <mat-option *ngFor="let data of countryList" [value]="data.countryId">{{data.countryName}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('locationCountry').valid && formGroup.get('locationCountry').touched">
                                    Please Enter Country</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">State </label>
                        <div class="col-sm-12 ">
                            <!-- <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="Enter State" name="locationState" formControlName="locationState" required> -->
                                <mat-select  placeholder="Select State"
                                formControlName="locationState" class="form-control" (openedChange)="myInputState.focus();"
                                (closed)="myInputState.value='';stateSearch(myInputState.value)"
                                 required>
                                <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control"
                                    #myInputState focused="'true'" type="text" (keyup)="stateSearch($event.target.value)"
                                    autocomplete="off" placeholder="search" (keydown)="$event.stopPropagation()">
                                <mat-option *ngFor="let data of stateList" [value]="data.stateId">{{data.stateName}}
                                </mat-option>
                            </mat-select>
                                <mat-error>
                                <span
                                    *ngIf="!formGroup.get('locationState').valid && formGroup.get('locationState').touched">
                                    Please Enter State </span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12"> Name </label>
                        <div class="col-sm-12 ">
                            <input type="text"  class="form-control" value=""
                                placeholder="Enter Name" name="name" formControlName="name" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">
                                    Please Enter Name</span>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="">
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                        *ngIf="!locationId">SUBMIT</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                        *ngIf="locationId">UPDATE</button>
                </div>
            </form>
        </div>
    </div>
</div>