import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {map, startWith} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { LanguageService } from 'src/app/service/language/language.service';

@Component({
  selector: 'app-list-restricted-keyword',
  templateUrl: './list-restricted-keyword.component.html',
  styleUrls: ['./list-restricted-keyword.component.scss']
})
export class ListRestrictedKeywordComponent implements OnInit {

  dataSource = new MatTableDataSource();
  competencyList = [];
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  page: number;
  size: number;
  displayedColumns: string [];
  totalpageLenght: number;
  totalrecordLength: number;
  private data: any;
  filteredApps: Observable<any[]>;
  apps = []
  langList =[];
  selectLang: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('attachments1') myInputVariable: ElementRef;
  fileData: File = null;
  countryFlagImagePath: any;
  BData: any;
  
  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              public langService :LanguageService,
              private masterDataservice: MasterDataService)
  { this.appCtrl = new FormControl();}

  ngOnInit(): void {
    this.page = 0;
    this.size = 20000;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.langList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.langList[0].languageCode;
    this.displayedColumns = ['srno', 'restrictedKeywordName', 'description', 'actions'];
    this.tabelLoad(this.page, this.selectLang);
  }

  tabelLoad(page, lang) {
    this.masterDataservice.getAllRestrictedKeywordList(page,this.size, lang).subscribe(res=>{
      this.data = res;
      if( this.data.status == 0 ){
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.apps = this.data.response.dataSet;
        this.filteredApps = this.appCtrl.valueChanges .pipe(
          startWith(''),
          map(app => app ? this.filterApps(app) : this.apps.slice())
        );
      }
    });

  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.competencyCategoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }

  deleteRestrictedDetails(restrictedKeyWordId) {
    if (confirm('Active Are you sure')) {
    this.masterDataservice.deleteRestrictedKeywordDetails(restrictedKeyWordId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.tabelLoad(this.page, this.selectLang);
          this.snackBar.open('Restricted keyword deleted succesfully!!!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
    }
  }
  bulkUpload(){
    if(this.fileData)
      {
        const formData: FormData = new FormData();
        formData.append('file' , this.fileData);
        formData.append('isForceAddNewlanguage' , 'false');        
        // this.masterDataservice.restriKeybuilkupload(formData).subscribe(res=>{
        //   this.BData =res;
        //   if(this.BData.status == 0){
        //     this.snackBar.open('File Successfuly uploaded.', '', {
        //       duration: 3000,
        //       panelClass: ['success-snackbar']
        //     });
        //     this.tabelLoad(this.page, this.selectLang);
        //   } else {
        //     this.snackBar.open(this.BData.response, '', {
        //       duration: 3000,
        //       panelClass: ['error-snackbar']
        //     });
        //   }
        // },(error)=>{
        //   this.snackBar.open(error.error.message, '', {
        //     duration: 3000,
        //     panelClass: ['error-snackbar']
        //   });
        // });
        
      }
  }

  fileSelect(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      this.bulkUpload();
    }
    console.log(this.fileData);
  }
  LangChange(e){
    this.selectLang = e.value;
    this.tabelLoad(this.page,this.selectLang);
  }
  ngAfterViewInit() {
    this.tabelLoad(this.page, this.selectLang);
  }
  onChangePage(e) {
    this.page = e.pageIndex;
    this.tabelLoad(e.pageIndex, this.selectLang);
  }
}
