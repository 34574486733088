import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { JobTestQuestionManageService } from 'src/app/service/jobPostTestandQuestion/job-test-question-manage.service';
import { LanguageService } from 'src/app/service/language/language.service';
import { ManageJobsService } from 'src/app/service/ManageJobs/manage-jobs.service';

@Component({
  selector: 'app-list-job-free-services',
  templateUrl: './list-job-free-services.component.html',
  styleUrls: ['./list-job-free-services.component.scss']
})


export class ListJobFreeServicesComponent implements OnInit {

  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList = [];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number = 0;
  size: number = 30;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId: any;
  LangList = [];
  selectLang: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('attachments1') myInputVariable: ElementRef;
  fileData: File = null;
  countryFlagImagePath: any;
  BData: any;

  constructor(private router: Router,
    private snackBar: MatSnackBar,
    public langService: LanguageService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public manageJobfreeservice : ManageJobsService
   ) { this.appCtrl = new FormControl(); }


  ngOnInit(): void {

    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.route.snapshot.params['lang'];
    this.displayedColumns = ['id', 'serviceType', 'name', 'websiteName','createdEpochTime', 'isActive','actions'];
  }

  tabelLoad(page, lang) {
    this.manageJobfreeservice.getAllList().subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.number;
        this.totalpageLenght = this.data.response.totalElements;
        this.dataSource = new MatTableDataSource(this.data.response);
         this.dataSource.sort = this.sort;
         this.dataSource.paginator = this.paginator;
      } else {
        
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  oldpermissionChange(e, element) {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
        this.manageJobfreeservice.statusChange(element.id).subscribe((data) => {
          // @ts-ignore
          if (data == 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(this.page, this.selectLang);
            this.snackBar.open('status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {
        this.manageJobfreeservice.statusChange(element.id).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });

          } else {
            e.source.checked = true;
            this.tabelLoad(this.page, this.selectLang);
            this.snackBar.open('status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = false;
      }
    }

  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if (name == '') {
      return this.apps;
    } else {
      return this.apps.filter(p => p.groupName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any) {
    // console.log(e);
    this.selectproduct = e.groupName;
    this.selectedId = e.id;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search() {

    this.router.navigate(['editIndusrty', this.selectedId]);

  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage() {
    this.tabelLoad(0, this.selectLang);
  }
  lastpage() {
    this.tabelLoad(this.totalpageLenght, this.selectLang);
  }
  prevpage() {
    const d = this.page - 1;
    if (d > 0) {
      this.tabelLoad(d, this.selectLang);
    }
  }
  nextpage() {
    const d = this.page + 1;
    if (d < this.totalpageLenght) {
      this.tabelLoad(d, this.selectLang);
    }
  }

  LangChange(e) {
    this.selectLang = e.value;
    this.tabelLoad(this.page, this.selectLang);
  }

 
 

  delete(interviewQuestionId) {
    if (confirm(' Are you sure ?')) {
      this.manageJobfreeservice.deleteJobfressService(interviewQuestionId)
        .subscribe(res => {
          this.data = res;
          if (this.data.status == 0) {
            this.tabelLoad(this.page, this.selectLang);
            this.snackBar.open('deleted succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          } else {
            this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }, error => console.log(error));
    }
  }
  ngAfterViewInit() {
    this.tabelLoad(this.page, this.selectLang);
  }
  onChangePage(e) {
    console.log(e);
    // this.page =e.pageIndex;
    this.tabelLoad(e.pageIndex, this.selectLang);
  }

}
