<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!zoneId">Add </span>
                <span *ngIf="zoneId">Edit </span> Zone
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/listStateData']">Manage Zone List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!zoneId">Add </span>
                    <span *ngIf="zoneId">Edit </span> Zone
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">Country </label>
                        <div class="col-sm-12 ">
                            <!-- <mat-select type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="Enter  Country  " name="locationCountry"
                                formControlName="locationCountry" required>
                                <mat-option value="India">India</mat-option>
                            </mat-select> -->
                            <mat-select  placeholder="Select Country"
                                formControlName="countryId" class="form-control" required countrySelect
                                (openedChange)="myInputCountry.focus();"
                                (closed)="myInputCountry.value='';countrySearch(myInputCountry.value)">
                                <input style="margin: 10px; width: 96%;" class="customeSearchDropdown myInput form-control"
                                    #myInputCountry focused="'true'" type="text" 
                                    autocomplete="off"  (keydown)="$event.stopPropagation()">
                                <mat-option *ngFor="let data of countryList" [value]="data.countryId">{{data.countryName}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('countryId').valid && formGroup.get('countryId').touched">
                                    Please Enter Country</span>
                            </mat-error>
                        </div>
                    </div>
                    
                   <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Zone Name </label>
                        <div class="col-sm-12 ">
                            <input type="text"  class="form-control" value=""
                                placeholder="Enter Zone Name" name="zoneName" formControlName="zoneName" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('zoneName').valid && formGroup.get('zoneName').touched">
                                    Please Enter Zone Name</span>
                            </mat-error>
                        </div>
                    </div> 
                </div>
                <div class="">
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                        *ngIf="!zoneId">SUBMIT</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                        *ngIf="zoneId">UPDATE</button>
                </div>
            </form>
        </div>
    </div>
  </div>