<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!id">Add </span>
        <span *ngIf="id">Edit </span> Coupon
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listDiscount']">Coupon List</li>
        <li class="breadcrumb-item active">
          <span *ngIf="!id">Add </span>
          <span *ngIf="id">Edit </span>
          Coupon
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="formGroup" appInvalidControlScroll>
        <div class="row">
          <div class="form-group row col-sm-12">
            <label class="col-sm-3">Apply for </label>
            <div class="col-sm-9 ">
              <mat-radio-group aria-label="Select an option" (change)="applyForChange($event)"
                formControlName="appliesTo" required>
                <mat-radio-button value="INDIVIDUAL">Individual / Selected Company</mat-radio-button>
                <mat-radio-button value="ALL">All recruiters</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-group row col-sm-12">
            <label class="col-sm-3">Apply for Coupon</label>
            <div class="col-sm-9 ">
              <mat-radio-group aria-label="Select an option" formControlName="couponAppliesTo" required>
                <mat-radio-button value="MEMBERSHIP">Membership</mat-radio-button>
                <mat-radio-button value="JOBPOST">Job Post</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-group row col-sm-12" *ngIf="showCompanyList == true">
            <label class="col-sm-12">Company</label>
            <div class="col-sm-12 ">
              <mat-select multiple
              [required]="showCompanyList == true"   
              formControlName="companyAppliesTo" placeholder="Select Company" class="form-control">
                <mat-option *ngFor="let r of companyList" [value]="r.companyId" [aria-label]="r.companyId">
                  {{r.companyName}}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!formGroup.get('companyAppliesTo').valid && formGroup.get('companyAppliesTo').touched">
                  Please select company </span>
              </mat-error>

            </div>
          </div>
          <div class="form-group row col-sm-12" *ngIf="showCompanyList == false">
            <label class="col-sm-12">Country</label>
            <div class="col-sm-12 ">
              <mat-select      
              [required]="showCompanyList == false"       
              [ngClass]="{ 'is-invalid': !formGroup.get('countryAppliesTo').valid && formGroup.get('countryAppliesTo').touched }"
              multiple formControlName="countryAppliesTo" placeholder="Select Country" class="form-control">
                <mat-option *ngFor="let r of countryList" [value]="r.countryId" [aria-label]="r.countryId">
                  {{r.countryName}}
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!formGroup.get('countryAppliesTo').valid && formGroup.get('countryAppliesTo').touched">
                  Please select Country</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Coupon Code</label>
            <div class="col-sm-12 ">
              <input type="text" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                placeholder="Enter Coupon Code" name="couponCode" formControlName="couponCode" required>
              <mat-error>
                <span *ngIf="!formGroup.get('couponCode').valid && formGroup.get('couponCode').touched">
                  Please Enter Coupon Code</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">No. of Times used by Same user</label>
            <div class="col-sm-12 ">
              <input type="number" class="form-control" value="" placeholder="Enter No. of Times used by Same user"
                name="timesCanbeUsed" (keydown)="firstSpaceRemoveValid($event)" formControlName="timesCanbeUsed"
                required>
              <mat-error>
                <span *ngIf="!formGroup.get('timesCanbeUsed').valid && formGroup.get('timesCanbeUsed').touched">
                  Please Enter No. of Times used by Same user</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Coupon Type</label>
            <div class="col-sm-12 ">
              <mat-select formControlName="couponType" required placeholder="Please Select Coupon Type"
                class="form-control">
                <mat-option value="FIXED" aria-label="FIXED">
                  FIXED
                </mat-option>
                <mat-option value="PERCENTAGE" aria-label="PERCENTAGE">
                  PERCENTAGE
                </mat-option>
                <!-- <mat-option *ngFor="let r of LangList"
                            [value]="r.languageId" [aria-label]="r.languageId">
                  {{r.languageName}}
                </mat-option> -->
              </mat-select>
              <mat-error>
                <span *ngIf="!formGroup.get('couponType').valid && formGroup.get('couponType').touched">
                  Please Select Coupon Type </span>
              </mat-error>

            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Coupon Value</label>
            <div class="col-sm-12 ">
              <input type="number" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                placeholder="Enter couponValue" name="couponValue" formControlName="couponValue" required>
              <mat-error>
                <span *ngIf="!formGroup.get('couponValue').valid && formGroup.get('couponValue').touched">
                  Please enter Coupon Value</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Start Date</label>
            <div class="col-sm-12 ">
              <input type="date" [min]="fDate" (change)="startDateValidtion($event)" class="form-control" value=""
                placeholder="Enter startDate" name="startDate" formControlName="startDate" required>
              <mat-error>
                <div *ngIf="expMinDateFlag">
                  Date should not be less then Today </div>
                <div *ngIf="!formGroup.get('startDate').valid && formGroup.get('startDate').touched">
                  Please enter Start Date</div>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Expiry Date</label>
            <div class="col-sm-12 ">
              <input type="date" class="form-control" [min]="startdateVlaidtion" (change)="endDateValidtion($event)"
                (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter autoExpiryDate"
                name="autoExpiryDate" formControlName="autoExpiryDate" required>
              <mat-error>
                <div *ngIf="startDateMinDateFlag">
                  Date should not be less than end date </div>
                <div *ngIf="!formGroup.get('autoExpiryDate').valid && formGroup.get('autoExpiryDate').touched">
                  Please enter Expiry Date</div>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Used Coupons</label>
            <div class="col-sm-12 ">
              <input type="number" class="form-control" value="" placeholder="Please Enter usedCoupons"
                name="usedCoupons" (keydown)="firstSpaceRemoveValid($event)" formControlName="usedCoupons" required>
              <mat-error>
                <span *ngIf="!formGroup.get('usedCoupons').valid && formGroup.get('usedCoupons').touched">
                  Please Enter Used Coupons</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Coupon Desciption</label>
            <div class="col-sm-12">
              <textarea 
              [ngClass]="{ 'is-invalid': !formGroup.get('couponDescription').valid && formGroup.get('couponDescription').touched }"
              style="width: 100%;" rows="5" formControlName="couponDescription" required
                placeholder="Please Enter couponDescription" name="couponDescription">
                </textarea>
              <mat-error>
                <span *ngIf="!formGroup.get('couponDescription').valid && formGroup.get('couponDescription').touched">
                  Please Enter Coupon Desciption</span>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="">
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!id"
            (click)="onSubmit()">Submit</button>
          <button type="submit" class="btn btn-success" (click)="onSubmit()" *ngIf="id">Update</button>
        </div>
      </form>
    </div>
  </div>

</div>