import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {ProfileService} from 'src/app/service/profile.service';

@Component({
  selector: 'app-add-update-profile',
  templateUrl: './add-update-profile.component.html',
  styleUrls: ['./add-update-profile.component.scss']
})
export class AddUpdateProfileComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  id: any;
  fileData: File = null;
  aadharFileName: string;
  aadharImgURL: any;
  aadharImagePath: any;
  newimgeuplod: any;

  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private profileService :ProfileService,
              private route: ActivatedRoute,
              private router: Router) { }

  formGroup: FormGroup;
  data: any;
  roleData: any;
  roleList = [];
  addedState: any;
  status: boolean;
  demo = [];
  isActive: boolean;
  emailpresent: boolean;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getAllRoleList();
    this.initializeForm();
   
 }
 getAllRoleList(){
   this.roleList =[];
  //  this.profileService.getAllRoleList(0, 100).subscribe( res=>{
  //    this.roleData = res;
  //    if(this.roleData.status == 0){
  //     this.roleList = this.roleData.response;
  //    } else {
  //     this.snackBar.open('response Status -1 ', '', {
  //       duration: 3000,
  //       panelClass: ['error-snackbar']
  //     });
  //    }
  //  })
 }


 // To initialize Form
 initializeForm() {
  this.formGroup = this.fb.group({
      name: [ '' , Validators.required],
      email: [ '' , Validators.required],
      mobileNumber: [ '', Validators.required],
      isActive: [ '' , Validators.required],
      roleId: [ '', Validators.required],
      profileImage : [],
    });
    if (this.id){
      this.patchUSerData();
    }
}
 // get
 patchUSerData(){
  this.profileService.getProfileDetailsById(this.id)
  .subscribe(res => {
    this.data = res;
    if(this.data.status == 0 ){
      this.formGroup.patchValue({
        name: this.data.response.name ,
        email: this.data.response.email ,
        mobileNumber :this.data.response.mobileNumber,
        isActive: this.data.response.isActive ,
        roleId :this.data.response.roleId,  
      });
    } else {
      this.snackBar.open('response Status -1 ', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }   
  }, error => console.log(error));
 }
 aadharReset() {
  this.aadharImgURL = '';
  this.myInputVariable.nativeElement.value = '';
}
adhacardselct(event, files) {
  if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.aadharImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
          this.aadharImgURL = reader.result;
      };
  }
  this.newimgeuplod = true;
}

onSubmit() {
  if (this.aadharImgURL == null || this.aadharImgURL === undefined || this.aadharImgURL === '') {
    this.formGroup.controls.profileImage.setErrors({ notfoundAdharImage: true });
 } else {
     this.formGroup.controls.profileImage.setErrors(null);
 }
  if (this.formGroup.valid) {    
    const formData: FormData = new FormData();
    formData.append('name' , this.formGroup.value.name);
    formData.append('email' , this.formGroup.value.email);
    formData.append('mobileNumber' , this.formGroup.value.mobileNumber);
    formData.append('isActive' , this.formGroup.value.isActive);
    formData.append('roleId' , this.formGroup.value.roleId);
    formData.append('ProfileImage', this.fileData);
    
    if (this.id){
      this.profileService.updateProfileDeatils(this.id, formData)
      .subscribe(data => {  
        this.snackBar.open('Successfully Updated ', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });       
        // this.router.navigate(['/cs_gstsetting']);  
        },       
        (err) => {
          this.snackBar.open(err.error.message,'',{
            duration: 3000,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
            panelClass: ['error-snackbar']
          });
        }
      );

    } else{
      const data = this.formGroup.value;
      this.profileService.createUser(formData).subscribe(() => {
        this.snackBar.open('Successfully ', '', {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
  
        // this.router.navigate(['/cs_gstsetting']); 
      });
    }
    
  } else {
    this.snackBar.open('Please Enter All Details', '', {
      duration: 3000,
      panelClass: ['error-snackbar']
    });
  }

}

  gotoList() {
    this.router.navigate(['/cs_gstsetting']);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57  )) {
      return false;
    }
    return true;
  }
  emailCheckUnique() {

    if (this.formGroup.controls.email.value.match(this.emailPattern) == null){
      this.formGroup.controls.email.setErrors({ notEquivalent: true });
    } else {
      this.formGroup.controls.email.setErrors(null);
      if (this.data.authType == '0'){
    //   this.authservice.getEmail(this.formGroup.value.email).subscribe(res => {
    //     this.eData = res;
    //     if (this.eData.response == false) {
    //         this.formGroup.controls.email.setErrors(null);
    //         this.emailpresent = false;
    //        
    //     } else {
    //       this.emailpresent = true;
    //       this.formGroup.controls.email.setErrors({ emailfound: true });
    //       this.snackBar.open('Email Id Already Present', '', {
    //             duration: 3000,
    //             verticalPosition: 'top',
    //             panelClass: ['error-snackbar']
    //         });
    //     }
    // });
  }
    }

}


}
