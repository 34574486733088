import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-add-update-job-category',
  templateUrl: './add-update-job-category.component.html',
  styleUrls: ['./add-update-job-category.component.scss']
})
export class AddUpdateJobCategoryComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  jobCategoryId: any;
  fileData: File = null;

  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  ngOnInit(): void {
    this.jobCategoryId = this.route.snapshot.params['id'];
    console.log(this.jobCategoryId);
    this.initializeForm();
  }



  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      categoryName: [ '' , Validators.required],
      categoryDescription: [ '' , Validators.required],
      
    });

    if (this.jobCategoryId){
      this.patchCategoryData();
    }
  }

  // get data from jobCategoryId
  patchCategoryData(){
    this.masterService.getCategoryDetailsById(this.jobCategoryId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.formGroup.patchValue({
            categoryName: this.data.response.parentId,
            categoryDescription: this.data.response.categoryDescription,
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const payload = {
        categoryName: this.formGroup.value.categoryName,
        categoryDescription: this.formGroup.value.categoryDescription,
        parentId: 0,
      }
      if (this.jobCategoryId){
        this.masterService.updateCategoryDeatils(this.jobCategoryId, this.formGroup.value)
          .subscribe((data:any) => {
              if(data.status == 0)
              {
                this.snackBar.open('Job Category Details Update Successfully !!', '', {
                  duration: 3000,
                  panelClass: ['success-snackbar']
                });
                this.router.navigate(['/listjobcategory']);
              }
              else{
                this.snackBar.open(data.response, '', {
                  duration: 3000,
                  panelClass: ['error-snackbar']
                });
              }

            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{
        this.masterService.createCategory(payload).subscribe((data:any) => {
          if(data.status == 0)
          {
            this.snackBar.open('Job Category Inserted Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listjobcategory']);
          }
          else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
