import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';
import { LanguageService } from 'src/app/service/language/language.service';

@Component({
  selector: 'app-add-update-question-level',
  templateUrl: './add-update-question-level.component.html',
  styleUrls: ['./add-update-question-level.component.scss']
})
export class AddUpdateQuestionLevelComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  questionGroupId: any;
  fileData: File = null;
  config: any;

  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private formBuilder: FormBuilder,
              public langService :LanguageService,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  dynamicForm: FormGroup;
  LangList=[];

  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.questionGroupId = this.route.snapshot.params['id'];
    this.dynamicForm = this.fb.group({
      multiLanguageNames: new FormArray([]),
    });
    if (this.questionGroupId){
      this.patchSkillData();
    }
    this.LangList = JSON.parse(this.langService.getLngList());
    console.log(this.LangList);
    this.LangListLoad(this.LangList.length);

  }
  get t() { return  this.dynamicForm.controls.multiLanguageNames as FormArray; }

  LangListLoad(e) {
   const numberOfTickets = e;
   if (this.t.length < numberOfTickets) {
       for (let i = this.t.length; i < numberOfTickets; i++) {
           this.t.push(this.formBuilder.group({
             languageId:[this.LangList[i].languageId],
             groupName: [ '' , Validators.required],
             groupDescription: [ '' , Validators.required],
           }));

       }
       // this.dynamicForm.patchValue(this.payloaddd);
   } else {
       for (let i = this.t.length; i >= numberOfTickets; i--) {
           this.t.removeAt(i);
       }
   }
}



  // get data from jobCategoryId
  patchSkillData(){
    this.masterService.getQuestionLevelDetailsById(this.questionGroupId)
      .subscribe(res => {
        this.data = res;
        console.log(this.data.response);
        if(this.data.status == 0 ){
          this.dynamicForm.patchValue(this.data.response);
          this.dynamicForm.patchValue({
            multiLanguageNames:this.data.response,
          })
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.dynamicForm.valid) {
      if (this.questionGroupId){
        
        this.masterService.updateQuestionGroup(this.questionGroupId, this.dynamicForm.value)
          .subscribe(data => {
              this.snackBar.open(' Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listQuestionGroup']);
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{
        this.masterService.createQuestionLevel(this.dynamicForm.value).subscribe(() => {
          this.snackBar.open('Inserted Successfully !!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/listQuestionGroup']);
        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }


  // @ViewChild('attachments1') myInputVariable: ElementRef;
  // questionGroupId: any;
  // fileData: File = null;

  // constructor(private snackBar: MatSnackBar,
  //             private fb: FormBuilder,
  //             private masterService :MasterDataService,
  //             private route: ActivatedRoute,
  //             private router: Router) { }
  // formGroup: FormGroup;
  // data: any;
  // addedState: any;
  // status: boolean;
  // isActive: boolean;
  // emailpresent: boolean;
  // config: any;

  // ngOnInit(): void {
  //   this.config = new DynamicLabelClass().ckeditorconfig;
  //   this.questionGroupId = this.route.snapshot.params['id'];
  //   this.initializeForm();
  // }
  // // To initialize Form
  // initializeForm() {
  //   this.formGroup = this.fb.group({
  //     groupName: [ '' , Validators.required],
  //     groupDescription: [ '' , Validators.required],
  //   });

  //   if (this.questionGroupId){
  //     this.patchQuestionLevelData();
  //   }
  // }

  // // get data from question level id
  // patchQuestionLevelData(){
  //   this.masterService.getQuestionLevelDetailsById(this.questionGroupId)
  //     .subscribe(res => {
  //       this.data = res;
  //       if(this.data.status == 0 ){
  //         this.formGroup.patchValue({
  //           groupName: this.data.response.groupName ,
  //           groupDescription: this.data.response.groupDescription ,
  //         });
  //       } else {
  //         this.snackBar.open('response Status -1 ', '', {
  //           duration: 3000,
  //           panelClass: ['error-snackbar']
  //         });
  //       }
  //     }, error => console.log(error));
  // }

  // onSubmit() {
  //   if (this.formGroup.valid) {
  //        if(this.questionGroupId ){
  //       this.masterService.updateQuestionGroup(this.formGroup.value , this.questionGroupId).subscribe( (res:any) => {

  //         if(res.status == 0){
  //           this.snackBar.open('Update Successfully Successfully !!', '', {
  //             duration: 3000,
  //             panelClass: ['success-snackbar']
  //           });
  //           this.router.navigate(['/listQuestionGroup']);
  //         } else {
  //           this.snackBar.open(res.response, '', {
  //             duration: 3000,
  //             panelClass: ['error-snackbar']
  //           });
  //         }
          
  //       } , (error:any) =>{
  //         this.snackBar.open(error.error.response, '', {
  //           duration: 3000,
  //           panelClass: ['error-snackbar']
  //         });
  //       });

  //     } else {
  //       this.masterService.createQuestionLevel(this.formGroup.value).subscribe((res:any) => {
  //         if(res.status == 0){
  //           this.snackBar.open('Test Level Inserted Successfully !!', '', {
  //             duration: 3000,
  //             panelClass: ['success-snackbar']
  //           });
  //           this.router.navigate(['/listQuestionGroup']);
  //         } else {
  //           this.snackBar.open(res.response, '', {
  //             duration: 3000,
  //             panelClass: ['error-snackbar']
  //           });
  //         }
         
  //       }, (error:any) =>{
  //         this.snackBar.open(error.error.response, '', {
  //           duration: 3000,
  //           panelClass: ['error-snackbar']
  //         });
  //       });
  //     }
       

  //   } else {
  //     this.snackBar.open('Please Enter All Details', '', {
  //       duration: 3000,
  //       panelClass: ['error-snackbar']
  //     });
  //   }

  // }
  // firstSpaceRemoveValid(e){
  //   if (e.which === 32 &&  e.target.selectionStart === 0) {
  //     return false;
  //   }
  // }
  // onPaste(event: ClipboardEvent, formcontrolname) {
  //   let clipboardData = event.clipboardData;
  //   let pastedText = clipboardData.getData('text');
  //   console.log(pastedText);
  //   event.preventDefault();
  //   this.formGroup.controls[formcontrolname].setValue(pastedText);
  // }


}
