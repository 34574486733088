<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!viewID">Add </span>
        <span *ngIf="viewID">Edit {{planName}} </span>  Member Plan
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/membershipList']"> Plan list</li>

      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <!-- <h1 class="title">Plan Name : {{planName}}</h1> -->
      <br>
      <!--  -->
      <form [formGroup]="form">
        <!-- <h2> Plan Name: {{membershipName}}</h2> -->
        <!-- <input type="text" placeholder="membershipname" formControlName="membershipName"> -->
        <!-- X -->
        <div formArrayName="membershipBenefits" class="row">

          <div class="col-sm-12 mb-3" *ngFor="let X of form['controls'].membershipBenefits['controls']; let ix=index">
            <div formGroupName="{{ix}}" class="Xs d-flex align-items-center">
              <label class="col-sm-3">{{X.controls.membershipBenefit.controls.benefitName.value}}</label>
              <!-- <input type="text" placeholder="benefitValue" formControlName="benefitValue"> -->
              <!-- Y -->
              <div class="col-sm-9">
                <div *ngIf=" X.controls.membershipBenefit.controls.benefitType.value =='YesNo'">
                  <mat-select formControlName="benefitValue" class="form-control">
                    <mat-option value="Yes">
                      Yes
                     </mat-option>
                     <mat-option value="No">
                       No
                      </mat-option>
                  </mat-select>
                  <!-- <mat-form-field appearance="fill" >
                  <mat-label>benefitValue</mat-label>
                  <mat-select formControlName="benefitValue">
                    <mat-option value="yes">
                     yes
                    </mat-option>
                    <mat-option value="no">
                      no
                     </mat-option>
                  </mat-select>
                </mat-form-field>        -->
                </div>
                <div *ngIf=" X.controls.membershipBenefit.controls.benefitType.value =='DropDown'">
                  <mat-select formControlName="benefitValue" class="form-control">
                    <mat-option value="Monthly">
                      Monthly
                     </mat-option>
                     <mat-option value="Yearly">
                      Yearly
                      </mat-option>
                  </mat-select>
                </div>
                <div *ngIf="X.controls.membershipBenefit.controls.benefitType.value =='Text' || X.controls.membershipBenefit.controls.benefitType.value =='Discount' || X.controls.membershipBenefit.controls.benefitType.value =='Currency'">
                  <div class="">
                    <!-- <label class="col-sm-12">question type</label> -->
                    <div class="">
                        <input
                        class="form-control"
                        [type]="(X.controls.membershipBenefit.controls.benefitType.value =='Text' ? 'text' : 'number') "   value=""
                       
                        [placeholder]="'Enter '+ (X.controls.membershipBenefit.controls.benefitName.value) " name="questionType"
                        formControlName="benefitValue" >
        
                      
                    </div>
                  </div>
                  <!-- <input placeholder="benefitValue" formControlName="benefitValue"> -->
                </div>

              </div>
              

              <!-- {{ deme(X.controls.membershipBenefit.controls.benefitName.value)}} -->
              <!-- <div formArrayName="membershipBenefit"> -->
                <!-- <input type="text" placeholder="membershipBenefitId" formControlName="membershipBenefitId"> -->
                <!-- <mat-label>{{X.controls.benefitName}}</mat-label> -->
                <!-- <input type="text" placeholder="benefitName" formControlName="benefitName"> -->
                <!-- {{  (X.controls.membershipBenefit.controls.benefitType.value)}} -->

                <!-- <input type="text" placeholder="benefitType" formControlName="benefitType"> -->
                <!-- <input type="text" placeholder="sequenceNo" formControlName="sequenceNo"> -->
                <!-- <div *ngFor="let Y of X['controls'].membershipBenefit['controls']; let iy=index">
              <div formGroupName="{{iy}}" class="Ys">
                <input type="text" placeholder="membershipBenefitId" formControlName="membershipBenefitId">
                <input type="text" placeholder="benefitName" formControlName="benefitName">
                <input type="text" placeholder="benefitType" formControlName="benefitType">
                <input type="text" placeholder="sequenceNo" formControlName="sequenceNo">
                

              </div>
            </div> -->
                <!-- <hr> -->
                <!-- <input type="button" (click)="addY(ix)" value="Add Y"> -->
              <!-- </div> -->
              <!-- Y End-->
            </div>
          </div>
          <!-- <input type="button" (click)="addX('1')" value="Add X"> -->
        </div>
        <!-- X End -->
        <br>
        <br>
        <div class="d-flex">

          <div class="btn btn-danger mar15" [routerLink]="['/membershipList']">Back</div>
          <div class="btn btn-primary" (click)="save()">
            <span *ngIf="viewID">Update</span>
            <span *ngIf="!viewID">Save</span>
            </div>
          
        </div>
        
      </form>

      <!--  -->

    </div>
  </div>

</div>