import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { MasterDataService } from "src/app/service/masterData/master-data.service";
import { MatDialog } from "@angular/material/dialog";
import { CommonConfirmPopupComponent } from "src/app/component/common-confirm-popup/common-confirm-popup.component";
import * as XLSX from "xlsx";
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-list-rusume-boosted',
  templateUrl: './list-rusume-boosted.component.html',
  styleUrls: ['./list-rusume-boosted.component.scss']
})
export class ListRusumeBoostedComponent implements OnInit {
  @ViewChild("TABLE") table: ElementRef;
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList = [];
  prdlist = [];
  private data: any;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  cityList: any = [];
  cityListClone: any = [];
  jobCategoryList: any = [];
  jobCategoryListClone: any = [];
  jobCategoryId: any;
  cityId: any;
  searchForm: FormGroup;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private masterDataservice: MasterDataService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.displayedColumns = [
      "srno",
      "name",
      "mobile",
      "email",
      "city",
      "district",
      "state",
      "startDate",
      "expiryDate",
      "status"
    ];
    this.searchForm = this.formBuilder.group({
      downloadFile: ["CSV"]
    });
    this.getAllResumeBoosted(this.page,this.size);
  }

  getAllResumeBoosted(page,size) {
    this.masterDataservice.getResumeReboosted(page, size)
      .subscribe((res) => {
        this.data = res;
        if (this.data.status == 0) {
          this.totalrecordLength = this.data.response.totalElements;
          this.page = this.data.response.number;
          this.totalpageLenght = this.data.response.totalPages;
          this.dataSource = new MatTableDataSource(this.data.response.dataSet);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      });
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ExportTOExcel() {
    window.location.href = `${environment.baseurl1}/candidate/exportResumeboostedCandidateListExcel?extension=`+this.searchForm.value.downloadFile;
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
    //   this.table.nativeElement
    // );
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, "Job");
    // XLSX.writeFile(wb, "JobList.xlsx");
  }
  filterApps(name: any) {
    const filterValue = name.toLowerCase();
    if (name == "") {
      return this.apps;
    } else {
      return this.apps.filter((p) =>
        p.name.toLowerCase().includes(filterValue)
      );
    }
  }
  onEnter(e: any) {
    this.selectproduct = e.name;
    this.selectedId = e.id;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search() {
    this.router.navigate(["editTeam", this.selectedId]);
  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

 
}
