<div>
    <div class="popheader">
      <div>Upload Language File</div>
      <div class="close" [mat-dialog-close]="false">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div class="popbody">
      <mat-dialog-content>
        <div class="poupBody row" class="uploadvidelist">
             <div>
                <div style="margin-top: 10px;
                border: 1px solid #dfdada;
                padding: 20px;
                border-radius: 16px;">
                  <label for="excelFile">
                    <div class="s3_inner">
                      <p class="head-1">
                      </p>
                      <div class="vi-logo">
                        <i class="fas fa-video"></i>
                      </div>
                      <p class="um-1">Upload Excel File</p>
                    </div>
                  </label>
                  <p *ngIf="fileData">{{fileName}}</p>
                  <input type="file" accept=".xlsx, .xls" name="excelFile" #file 
                    class="imageUploadcss" #attachments3 id="excelFile" (change)="onSelectFile($event)" />
                </div>
              </div>
            </div>
      </mat-dialog-content>
    </div>
    <div class="popupFooter d-flex align-items-center">
      <button class="btn btn-primary mar15" *ngIf="fileData" (click)="submit()">Submit </button>
        <button class="btn btn-danger mar15" [mat-dialog-close]="false">Cancel</button>
    </div>
  </div>
