<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Manage Job Category</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active">Manage Job Category</li>
        </ol>
    </div>
</div>
<!--Page Header-->

<div class="col-sm-12">
    <div class="card">
        <div class="c_table">
            <div class="mat-elevation-z8">
                <div class="d-flex table-responsive p-3">
                    <div class="mr-2">
                        <div [routerLink]="['/addjobsubcategory']" class="btn btn-primary btn-sm rounded-lg fs-13">
                            <span>
                <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                Add Job Category</span>
                        </div>
                    </div>
                    <div class="mr-2 col-sm-2">
                        <mat-select [ngModel]="selectLang" (selectionChange)="LangChange($event)" class="form-control">
                            <mat-option *ngFor="let r of langList" [value]="r.languageCode" [aria-label]="r.languageCode">
                                {{r.languageName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="mr-2 col-sm-2">
                        <!-- <div class="btn btn-primary btn-sm rounded-lg fs-13">
              <span>
               

                <label for="file" style="margin: 0;">
                  <i class="fa fa-cloud-upload"></i> Import Category
                </label>
                <input type="file" name="country" #file
                  accept=".csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="imageUploadcss" #attachments1 id="file" (change)="fileSelect($event,file.files)" />
              </span>
            </div> -->
                    </div>

                    <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                    </div>
                </div>


                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="srno">
                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                        <td mat-cell *matCellDef="let element;let j = index">
                            <!-- <span *ngIf="page == 0" > {{j + 1}}</span>
                  <span *ngIf="page != 0" >{{((page * size) +   (j + 1))}}</span> -->
                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                        </td>
                    </ng-container>
                    <div>
                        <ng-container matColumnDef="parentName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Parent Category</th>
                            <td mat-cell *matCellDef="let element">{{element.parentName}}</td>
                        </ng-container>

                        <ng-container matColumnDef="categoryName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name </th>
                            <td mat-cell *matCellDef="let element">{{element.categoryName}}</td>
                        </ng-container>
                        <div>
                            <ng-container matColumnDef="subCategoryName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Category </th>
                                <td mat-cell *matCellDef="let element">{{element.subCategoryName}}</td>
                            </ng-container>

                            <!--                <ng-container matColumnDef="displayName">-->
                            <!--                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Display Name </th>-->
                            <!--                  <td mat-cell *matCellDef="let element">{{element.displayName}}</td>-->
                            <!--                </ng-container>-->
                        </div>
                    </div>
                    <ng-container matColumnDef="isActive">
                        <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            <span>
                <mat-slide-toggle (change)="oldpermissionChange($event,element)" name="isActive"
                  [checked]="element.isActive"></mat-slide-toggle>
              </span>

                        </td>
                    </ng-container>



                    <ng-container matColumnDef="actions">
                        <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                            <div class="d-flex justify-content-center">
                                <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/editjobsubcategory', element.jobCategoryId]">
                  <img src="../../../../assets/img/eye.svg" alt="eye">
                </span>
                                <span class="btn btn-sm btn-danger rounded-lg" (click)="delete(element.jobCategoryId)"><i
                    class="fas fa-trash"></i></span>



                            </div>


                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <!-- <div class="d-flex justify-content-end align-items-center">
              <div class="c_page"><b>Current Page: </b> {{page}} </div>
              <div class="t_recd" ><b>Total Records: </b>  {{totalrecordLength}}</div>
              <ul class="pagination">
                <li (click)="firstpage()" ><i class="fas fa-angle-double-left" ></i></li>
                <li (click)="prevpage()" ><i class="fas fa-arrow-left"></i></li>
                <li (click)="nextpage()" ><i class="fas fa-arrow-right" ></i> </li>
                <li (click)="lastpage()"> <i class="fas fa-angle-double-right"></i></li>
            </ul>
            </div> -->


                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons>
                </mat-paginator>

            </div>


        </div>
    </div>
</div>