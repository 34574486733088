import { Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import{TranslationService} from 'src/app/service/translation/translation.service';
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-edit-translation',
  templateUrl: './edit-translation.component.html',
  styleUrls: ['./edit-translation.component.scss']
})
export class EditTranslationComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  data;
  appName:any;
  isWeb:boolean = false;
  pageNameList:any = [];
  selectedPageName:any;
  dataLanguage:any;
  languageDisplay:any;
  page = 0;
  size = 2000;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(public translationService:TranslationService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar) { 
    }

  ngOnInit(): void {
    this.isWeb = this.route.snapshot.params['isWeb'];
    this.appName = this.route.snapshot.params['appName'];
    this.tabelLoad(this.appName,this.isWeb);
  }

  tabelLoad(appName,isWeb){
    this.translationService.getAllManageLanguagesByAppName(appName, isWeb,this.page,this.size).subscribe( res =>{
      this.data = res;
      if(this.data.status == 0 ){
        this.displayedColumns=[];
        this.languageDisplay=[];
          this.displayedColumns.push('srno');
          this.displayedColumns.push('pageName');
          this.displayedColumns.push('keyName');
          this.dataLanguage=this.data.response.dataSet; 
          this.dataLanguage[0].manageLanguageDetails.forEach(element => {
            this.displayedColumns.push(element.languageName);
            this.languageDisplay.push(element.languageName);
          });
          this.displayedColumns.push('action');
          this.dataSource = new MatTableDataSource(this.data.response.dataSet);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          var nameList=[];
          if(this.pageNameList.length==0){
            nameList.push('All');
            this.data.response.dataSet.forEach(element => {
              nameList.push(element.pageName);
            });
            nameList.forEach((c) => {
                if (!this.pageNameList.includes(c)) {
                  this.pageNameList.push(c);
                }
            });
            this.selectedPageName = 'All';
          }
      }
      else {
        this.snackBar.open(this.data.status, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    })
  }
  pageNameChange(e):any{
    this.selectedPageName =e.value;
    if(this.selectedPageName == 'All'){
      this.tabelLoad(this.appName,this.isWeb);
    } else{
      this.filter(this.appName,this.isWeb,this.selectedPageName);
    }
  }
  filter(appName,isWeb,pageName):any{
    this.translationService.getManageLanguagesByAppNameAndPageName(appName, isWeb,pageName,this.page,this.size).subscribe( res =>{
      this.data = res;
      if(this.data.status == 0 ){
          this.dataSource = new MatTableDataSource(this.data.response.dataSet);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
      else {
        this.snackBar.open(this.data.status, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  save(obj):any{
    var payload ={
        "appName": obj.appName,
        "keyName": obj.keyName,
        "languageMangeId": obj.languageMangeId,
        "languageNameDetails": [],
        "pageName": obj.pageName,
        "useLocation": obj.useLocation
    }
    obj.manageLanguageDetails.forEach(element => {
      payload.languageNameDetails.push(
        {
          "languageId": element.languageId,
          "value": element.value
        }
      )
    });
    this.translationService.addLanguage(payload).subscribe( res =>{
      this.data = res;
      if(this.data.status == 0 ){
        this.snackBar.open(this.data.response, '', {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
        if(this.selectedPageName == 'All'){
          this.tabelLoad(this.appName,this.isWeb);
        } else{
          this.filter(this.appName,this.isWeb,this.selectedPageName);
        }
      }
      else {
        this.snackBar.open(this.data.status, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    })
  }
}

