import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from "../../../service/language/language.service";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import {DynamicLabelClass} from "../../../helper/userLevelLink";

@Component({
  selector: 'app-add-update-job-board',
  templateUrl: './add-update-job-board.component.html',
  styleUrls: ['./add-update-job-board.component.scss']
})
export class AddUpdateJobBoardComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;
  @ViewChild('attachments2') myInputVariable2: ElementRef;
  viewID: any;
  fileData: File = null;
  testImgURL: any;
  questionImage: any;
  testImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList: Array<any> = [];
  jobplanLst: Array<any> = [];
  page: number = 0;
  size: number = 500;
  qustGropList: Array<any> = [];
  qustGropList1: Array<any> = [];
  qGroup: any;
  qustTypeList: Array<any> = [];
  qustTypeList1: Array<any> = [];
  qType: any;
  arrayOptions: Array<any> = [];
  oprincustome: Array<any> = [];
  resultKewords: Array<any> = [];
  pdata: any;
  seletedOption: number = 0;
  myModel: any;
  config: any;
  addnewOption: number = 2;
  submitted = false;
  addBtnHide: boolean = false;
  selectedAreas: Array<any> = [];
  CorrecTanswerNotSlect = false;
  CorrectAnserArray = [false, false, false, false, false];
  typeSelect: boolean;
  lang: any;
  keywaordList: any;
  keywaordList1: any;
  allKeywords: any;
  allComplete: boolean = false;
  menus: any;
  mrunal = [];
  keyWordListShow = false;
  capDivVal: boolean = false;
  jobDivVal: boolean = false;
  laguageName: any;
  options: any;
  jobplanList: any;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  selectLang:any;
  fileData2:any;
  thumbImagePath:any;
  thumbImageURL:any;
  newthumbImageupload:any;
  checksize:boolean = false;
  width: number;
  height: number;

  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public langService: LanguageService,
              private masterDataservice: MasterDataService,
              private router: Router) { }
  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.viewID = this.route.snapshot.params['id'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    this.getAllPlanList(this.selectLang);
    this.initializeForm();
  }

  getAllPlanList(langCode): any{
    this.masterDataservice.getAllPlanList(langCode).subscribe(res => {
      this.qGroup = res;
      console.log(this.qGroup );
      if ( this.qGroup.status == 0 ){
        this.jobplanList = this.qGroup.response.filter((d) => {
          if (d.isActive == true) {
            return d;
          }
        });
        this.selectedAreas = this.jobplanList;
      }
    });
  }

  search(query: string): any{
    const result = this.select(query.toLowerCase());
    if ( query == ''){
      this.qustGropList = this.selectedAreas;
    } else {
      this.qustGropList = result;
    }
  }
  select(query: string): string[]{
    const result: string[] = [];
    for (let a of this.selectedAreas){
      if (a.groupName.toLowerCase().indexOf(query) > -1){
        result.push(a);
      }
    }
    return result;
  }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      freeJobPlan: ['', Validators.required],
      jobBoardName: new FormArray([]),
      jobBoardDescription: new FormArray([]),
      websiteUrl: ['', Validators.required],
      countryFlag: ['', Validators.required],
      currency: new FormArray([]),
      thumbImage:['', Validators.required]
    });
    this.LangListLoad(this.LangList.length);
    this.setCurrencyData();
    if (this.viewID){
      this.patchData();
    }
  }
  get f() { return this.formGroup.controls; }
  get jb() : FormArray { return this.formGroup.get("jobBoardName") as FormArray;}
  get jd() : FormArray { return this.formGroup.get("jobBoardDescription") as FormArray;}
  get currency() : FormArray {
    return this.formGroup.get("currency") as FormArray;
  }
  setCurrencyData():any{
    this.currency.insert(0,this.fb.group({code: ['USD'],countryName: ['United States of America'],currencyId: [4],id: [0],name: ['Dollars'],addonJobBoardId: [0],symbol: ['$'],value: [0, Validators.required]}));
    this.currency.insert(1,this.fb.group({code: ['GBP'],countryName: ['United Kingdom'],currencyId: [2],id: [0],name: ['Pounds'],addonJobBoardId: [0],symbol: ['£'],value: [0, Validators.required]})); 
    this.currency.insert(2,this.fb.group({code: ['EUR'],countryName: ['Euro'],currencyId: [1],id: [0],name: ['Euro'],addonJobBoardId: [0],symbol: ['€'],value: [0, Validators.required]})); 
    this.currency.insert(3,this.fb.group({code: ['PLN'],countryName: ['Poland'],currencyId: [3],id: [0],name: ['Zlotych'],addonJobBoardId: [0],symbol: ['zł'],value: [0, Validators.required]}));  
  }
  removeOption(i): any {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e): any {
    const numberOfTickets = e;
    if (this.jb.length < numberOfTickets) {
      for (let i = this.jb.length; i < numberOfTickets; i++) {
        this.jb.insert(i,this.fb.group({value: ['', Validators.required]}));
        this.jd.insert(i,this.fb.group({value: ['', Validators.required]}));
      }
    } else {
      for (let i = this.jb.length; i >= numberOfTickets; i--) {
        this.jb.removeAt(i);
        this.jd.removeAt(i);
      }
    }
  }

  patchData(): any{
    var jobBoardNameList=[];
    var jobBoardDescList =[];
    this.masterDataservice.getJobBoardById(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0 ){
          var multiLangList = this.pdata.response.multiLanguages;
          this.LangListLoad(this.LangList.length);
          this.countryFlagImgURL = this.pdata.response.logo;
          this.thumbImageURL = this.pdata.response.thumbImage;
          this.jobplanLst = [];
          const dd = this.pdata.response.freeJobPlan;
          for (let b of dd) {
            this.jobplanLst.push(b.jobPlanId);
          }
          multiLangList.forEach((element,j) => {
            jobBoardNameList.push({value:element.jobBoardName});
            jobBoardDescList.push({value:element.jobBoardDescription});
          });
          this.formGroup.patchValue({
              jobBoardName: jobBoardNameList,
             jobBoardDescription: jobBoardDescList,
             websiteUrl: this.pdata.response.websiteUrl,
            freeJobPlan: this.jobplanLst,
            currency: this.pdata.response.currency
          });

        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }


  onSubmit(submiType): any {
    this.submitted = true;
    var arr = [];
    for (let i = arr.length; i < this.LangList.length; i++) {
      arr.push({
          "addedBy": "ADMIN",
          "addonJobBoardId": 0,
          "apiKey": "",
          "apiOtherDetails": "",
          "apiPassword": "",
          "apiUrl": "",
          "id": 0,
          "isActive": true,
          "jobBoardDescription": (((this.formGroup.get('jobBoardDescription') as FormArray).at(i) as FormGroup).value).value,
          "jobBoardEndpoint": "",
          "jobBoardName": (((this.formGroup.get('jobBoardName') as FormArray).at(i) as FormGroup).value).value,
          "languageId": this.LangList[i].languageId,
          "url": "",
      })
    }
      const payload = {
        currency:this.formGroup.value.currency,
        freeJobPlan:this.formGroup.value.freeJobPlan,
        websiteUrl:this.formGroup.value.websiteUrl,
        addonJobBoardMultiLanguageList:arr,
      };
      console.log("payload",payload);
      const data: FormData = new FormData();
      data.append('logo', this.fileData);
      data.append('thumbImage',this.fileData2);
      data.append('addonJobRequest', JSON.stringify(payload).toString());
      if (this.formGroup.valid) {  
        if (this.viewID){
          this.masterDataservice.updateJobBoard(this.viewID,data)
            .subscribe((data:any) => {
                if (data.status == 0){
                  this.snackBar.open('Update Job Board Successfully !!', '', {
                    duration: 3000,
                    panelClass: ['success-snackbar']
                  });
                  this.router.navigate(['/listJobBoard']);
                } else {
                  this.snackBar.open(data.response.response, '', {
                    duration: 3000,
                    panelClass: ['error-snackbar']
                  });
                }
  
              },
              (err) => {
                this.snackBar.open(err.error.message, '', {
                  duration: 3000,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
                  panelClass: ['error-snackbar']
                });
              }
            );
  
        } else{
          this.masterDataservice.addJobBoard(data).subscribe( (res:any) => {
            if (res.status == 0){
              this.snackBar.open('Job Board Insert Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
             this.router.navigate(['/listJobBoard']);
            } else {
              this.snackBar.open(res.response.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }
          });
        }
       }
  }

  firstSpaceRemoveValid(e): any{
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }

  countryFlagReset() {
    this.countryFlagImgURL = '';
    this.myInputVariable.nativeElement.value = '';
  }

  countryFlagSelct(event, files) {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };

      const URL = window.URL || window.webkitURL;
      const Img = new Image();
  
      const filesToUpload = (event.target.files);
      Img.src = URL.createObjectURL(filesToUpload[0]);
  
      Img.onload = (e: any) => {
        this.width = e.path[0].width;
        this.height = e.path[0].height;
        console.log('width&height',this.width,this.height); 
        
        //width => 225 between 250 & height => 50 between 65
        if (this.width <= 225 && this.height <= 75) {
          this.fileData = event.target.files[0] as File as File;
          this.checksize = false;
          this.countryFlagImgURL = reader.result;
        }
        else {
          this.checksize = true;
          this.countryFlagImgURL = '';
        }
      }
    }
    this.newimgeuplod = true;
  }

  thumbImageSelect(event, files) {
    if (event.target.files.length > 0) {
      this.fileData2 = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.thumbImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.thumbImageURL = reader.result;
      };
    }
    this.newthumbImageupload = true;
  }
  thumbImageReset() {
    this.thumbImageURL = '';
    this.myInputVariable2.nativeElement.value = '';
  }

  onLanguageChange(value) {
    this.laguageName = value.languageCode;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57  )) {
      return false;
    }
    return true;
  }
}
