<div class="container-fluid">
   <!--Page Header-->
   <div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
      <h3 class="page-title">
    Edit Personality Question </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/mangeQuestion']" >List Personality Question </li>
        <li class="breadcrumb-item active" >          
          <span  >Edit </span>
          Personality  Question  </li>
      </ol>
    </div>
  </div>       
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="row">        
        <div class="form-group row col-sm-6">
          <label class="col-sm-12">Select Personality Category</label>
          <div class="col-sm-12">
  
              <mat-select formControlName="categoryTypeId" required
              placeholder=""
                          class="form-control" >
                          <mat-option value='0'>--Select Category--</mat-option>
                <mat-option *ngFor="let r of categoryList"
                            [value]="r.personalityTestCategoryId" 
                            [aria-label]="r.personalityTestCategoryId">
                  {{r.testCategoryName}}
                </mat-option>
              </mat-select>
  
          </div>
        </div>
        <div class="form-group row col-sm-6">
          <label class="col-sm-12">personality test question name</label>
          <div class="col-sm-12">       
              <input 
              class="form-control"
              type="text"  value="" placeholder="Enter personality test question name" name="questionName"
                     formControlName="questionName" required>
            
            <mat-error>
                 <span *ngIf="!formGroup.get('questionName').valid && formGroup.get('questionName').touched">
                                Please enter question name</span>
            </mat-error>
          </div>
        </div>
        <div class="form-group row col-sm-6">
          <label class="col-sm-12"> Description</label>
          <div class="col-sm-12 ">            
              <input 
              class="form-control"
              type="text"  value="" placeholder="Enter description" name="description"
                     formControlName="description" required>
            
            <mat-error>
             <span *ngIf="!formGroup.get('description').valid && formGroup.get('description').touched">
                                Please enter description</span>
            </mat-error>
          </div>
        </div>
        </div>
        <div>
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="questionId">Update</button>
        </div>
        
      </form>
    </div>
  </div>

</div>
