import { ConstantPool } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';
import { JobTestQuestionManageService } from 'src/app/service/jobPostTestandQuestion/job-test-question-manage.service';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-addupdate-job-post-question',
  templateUrl: './addupdate-job-post-question.component.html',
  styleUrls: ['./addupdate-job-post-question.component.scss']
})
export class AddupdateJobPostQuestionComponent implements OnInit {
  // @ViewChild('attachments1') myInputVariable: ElementRef;

  viewID: any;
  fileData: File = null;
  testImgURL: any;
  questionImage: any;
  testImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList: Array<any> = [];
  page: number = 0;
  size: number = 500;
  qustGropList: Array<any> = [];
  qGroup: any;
  qustTypeList: Array<any> = [];
  qType: any;
  arrayOptions: Array<any> = [];
  oprincustome: Array<any> = [];
  pdata: any;
  seletedOption: number = 0;
  myModel: any;
  config: any;
  optionconfig: any;
  addnewOption: number = 2;
  submitted = false;
  addBtnHide: boolean = false;
  selectedAreas: Array<any> = [];
  CorrecTanswerNotSlect = false;
  CorrectAnserArray = [false, false, false, false, false];
  typeSelect: boolean;
  selectedQuestionTypeId: any;
  fileName: any;
  fileType: any;
  url: any;
  format: any;
  images = [];
  newBlobArray = [];
  answerBlobArray = [];
  Answerimages = [];
  GetTimeQuestionImage = [];
  AnswerGetTimeQuestionImage = [];
  selectLang: any;
  fileData2: any;
  thumbImagePath: any;
  thumbImageURL: any;
  newthumbImageupload: any;
  @ViewChild('attachments2') myInputVariable2: ElementRef;
  answergettimeImage: any;


  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private el: ElementRef,
    private masterDataservice: MasterDataService,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    private router: Router) { }

  ngOnInit(): void {

    this.config = new DynamicLabelClass().ckeditorconfig;
    this.optionconfig = new DynamicLabelClass().ckeditorconfigForOption;
    this.viewID = this.route.snapshot.params['id'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    console.log(this.LangList)
    this.questionGroupList(0, 300, this.selectLang);
    this.questionTypeList();
    this.initializeForm();
    this.arrayOptions = [];
  }
  questionTypeList(): any {
    this.masterDataservice.getAllQuestionTypeList(this.page, this.size).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {
        this.qustTypeList = this.qType.response.dataSet.filter((d) => {
          if (d.isActive == true) {
            if (!(d.questionTypeId == 10 || d.questionTypeId == 7 || d.questionTypeId == 5 || d.questionTypeId == 4)) {
              return d;
            }

          }
        });
        console.log(this.qustTypeList);
      }
    });
  }

  questionGroupList(page, size, selectLang): any {
    this.masterDataservice.getAllQuestionLevelList(page, size, selectLang).subscribe(res => {
      this.qGroup = res;
      if (this.qGroup.status == 0) {
        this.qustGropList = this.qGroup.response.dataSet;
        this.selectedAreas = this.qustGropList;
        console.log(this.qustGropList)
      }
    });
  }
  search(query: string): any {
    const result = this.select((query).toLowerCase());
    if (query == '') {
      this.qustGropList = this.selectedAreas;
    } else {
      this.qustGropList = result;
    }
  }
  select(query: string): string[] {
    const result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      question: ['', Validators.required],
      languageId: ['', Validators.required],
      questionGroupId: ['', Validators.required],
      // options: this.fb.array([]),
      questionImage: [''],
      companyId: [0],
      questionTypeId: ['', Validators.required],
      options: new FormArray([]),
      correctAnswer: [''],
      answerExplanation: ['', Validators.required],
      parentId: [0],
    });

    if (this.viewID) {
      this.patchData();
    }
    this.LangListLoad(this.addnewOption);
  }
  get f() { return this.formGroup.controls; }
  get t() { return this.f.options as FormArray; }

  removeOption(i): any {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e): any {
    const numberOfTickets = e;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.fb.group({
          response: [''],
          valid: false,
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  // addOption() {
  //   const control = <FormArray>this.formGroup.get("options");
  //   const newGroup = this.fb.group({
  //     response: "",
  //     valid: ""
  //   });
  //   control.push(newGroup);
  //   this.arrayOptions.push(this.formGroup.controls.options.value);
  // }


  // get data from jobCategoryId


  patchData(): any {
    this.jobpostQuestionTestService.getJobPostQuestById(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          if (this.pdata.response.questionLevel == 'COMPREHENSIVE' || this.pdata.response.questionLevel == 'COMPREHENSIVE_IMAGE') {
            this.typeSelect = true;
            this.questionImage = this.pdata.response.imageUrl;
            this.GetTimeQuestionImage = this.pdata.response.questionImages; 
            this.thumbImageURL = this.pdata.response.answerImageUrl;
            this.formGroup.patchValue({
              question: this.pdata.response.question,
              languageId: this.pdata.response.languageId,
              questionGroupId: this.pdata.response.questionGroupId,
              questionImage: this.pdata.response.questionImage,
              options: [],
              questionTypeId: this.pdata.response.questionTypeId,
              correctAnswer: this.pdata.response.correctAnswer,
              answerExplanation: this.pdata.response.answerExplanation,
              companyId: this.pdata.response.companyId,
              parentId: this.pdata.response.parentId,
            });
          } else {
            this.typeSelect = false;
            if (!(this.pdata.response.options == null || this.pdata.response.options == undefined || this.pdata.response.options == '')) {
              this.LangListLoad(this.pdata.response.options.length);
              this.addnewOption = this.pdata.response.options.length;
              const ddd = this.pdata.response.options;
              this.myModel = this.pdata.response.correctAnswer;
              this.thumbImageURL = this.pdata.response.answerImageUrl;
              const newaray = [];
              let m: any;
              let ctans = false;
              ddd.forEach((b, i) => {
                if (b == this.myModel) {
                  m = true;
                  this.CorrectAnserArray.splice(i, 1, true);
                } else {
                  m = '';
                  this.CorrectAnserArray.splice(i, 1, false);
                }
                console.log(this.CorrectAnserArray)
                newaray.push({
                  'response': b,
                  'valid': m,
                });
              });
              console.log(this.CorrectAnserArray)
              this.questionImage = this.pdata.response.imageUrl;
              this.GetTimeQuestionImage = this.pdata.response.questionImages;
              console.log(this.pdata.response.options);
  
              this.formGroup.patchValue({
                question: this.pdata.response.question,
                languageId: this.pdata.response.languageId,
                questionGroupId: this.pdata.response.questionGroupId,
                questionImage: this.pdata.response.questionImage,
                options: newaray,
                questionTypeId: this.pdata.response.questionTypeId,
                correctAnswer: this.pdata.response.correctAnswer,
                answerExplanation: this.pdata.response.answerExplanation,
                companyId: this.pdata.response.companyId,
                parentId: this.pdata.response.parentId,
              });
            } else {
              this.snackBar.open('Option value receiving null not able to set option value ', '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }
          }
          
          // this.LangListLoad(this.pdata.response.options.length);


        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  demmsds(e , controlnam){
    const d = this.strip_tags(e.target.innerHTML, 'b', 'i', 'ul' , 'li' , 'br' , 'div' , 'span' , 'p');
    console.log(d)
    if(d != ''){
      this.formGroup.controls[controlnam].setValue(d);
    }
   
  }

 strip_tags(html, ...args) {
  return html.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
    return args.includes(tag) ? '<' + endMark + tag + '>' :'';
  }).replace(/<!--.*?-->/g, '');
}
  onSubmit(submiType): any {

   
    this.formGroup.controls.answerExplanation.setValidators(null);
    this.formGroup.controls.answerExplanation.updateValueAndValidity();
    this.formGroup.get('answerExplanation').clearValidators();
    // console.log(this.htmlplainconvertor( this.formGroup.value.question))
    if (this.typeSelect == true) {
      //  essay question parent add
      // this.formGroup.controls.correctAnswer.setValue('comprehsiveselect');


      // this.formGroup.controls.options.setValidators(null);
      this.t.removeAt(1);
      this.t.removeAt(0);
      this.oprincustome = [];
      this.formGroup.get('options').clearValidators();
      this.formGroup.get('options').updateValueAndValidity();

      this.formGroup.controls.answerExplanation.setValidators(null);
      this.formGroup.controls.answerExplanation.updateValueAndValidity();
      this.formGroup.get('answerExplanation').clearValidators();

      this.formGroup.get('correctAnswer').clearValidators();
      this.formGroup.controls.correctAnswer.updateValueAndValidity();
      this.formGroup.controls.correctAnswer.setValidators(null);


      // this.formGroup.controls.options.removeValidator('required');

      // this.formGroup.value.options.setValue((oprincustome).toString());
      // this.formGroup.controls.answerExplanation.setValue('comprehsiveselect');
    } else {
      //  other type question
      if (!(this.thumbImageURL == null || this.thumbImageURL == '' || this.thumbImageURL == undefined)) {
        this.formGroup.controls.answerExplanation.setValidators(null);
        this.formGroup.controls.answerExplanation.updateValueAndValidity();
        this.formGroup.get('answerExplanation').clearValidators();
      }
      console.log(this.CorrectAnserArray)
      if (this.CorrectAnserArray.length > 0) {
        if (this.CorrectAnserArray.indexOf(true) >= 0) {
          const dddsd = this.CorrectAnserArray.indexOf(true);
          const crtAnsdd = this.formGroup.value.options[dddsd].response;
          this.formGroup.controls.correctAnswer.setValue(crtAnsdd);
          const dd = this.formGroup.value.options;
          this.oprincustome = [];
          for (let b of dd) {
            // this.oprincustome.push(b.response);
            this.oprincustome.push({ option: this.strip_tags(b.response, 'b', 'i', 'ul' , 'li' , 'br' , 'div' , 'span' , 'p')  });
          }
        }
        else {
          this.CorrecTanswerNotSlect = true;
          this.snackBar.open('Please Select Correct Answer', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });

          return;
        }

      } else {
        const mm = this.formGroup.controls.correctAnswer.value;
        if (mm == '' || mm == null) {
          this.CorrecTanswerNotSlect = true;
        } else {
          this.CorrecTanswerNotSlect = false;
        }
        const d = (mm == '' || mm == null) ? 'Please Select Correct Answer' : 'Please Enter All Details';
        this.snackBar.open(d, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });

        return
      }
      console.log(this.oprincustome)
      //  this.formGroup.patchValue({
      //   options:this.oprincustome});
    }
    // this.formGroup.controls.options.setValue(this.oprincustome);

    console.log(this.formGroup.value);
    console.log(this.formGroup)

    if (this.selectedQuestionTypeId == 2 || this.selectedQuestionTypeId == 8) {

      if ((this.newBlobArray.length == 0)) {
        this.snackBar.open('Selected Quesiton group Imagebase Quesiton so,please Upload Question Image', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
        return;
      }
    }
    if (this.formGroup.valid) {
      const requests = {
        requests: this.oprincustome,
      };
      console.log(this.formGroup.value.question.replace(/<img[^>]*>/g,""))
      const formData: FormData = new FormData();
      formData.append('assesmentQuestionId', (this.viewID == null || this.viewID == undefined || this.viewID == '') ? 0 : this.viewID);
      formData.append('question', this.strip_tags(this.formGroup.value.question, 'b', 'i', 'ul' , 'li' , 'br' , 'div' , 'span' , 'p'));
      formData.append('languageId', this.formGroup.value.languageId);
      formData.append('questionGroupId', this.formGroup.value.questionGroupId);
      formData.append('questionTypeId', this.formGroup.value.questionTypeId);
      formData.append('opt', JSON.stringify(requests));
      formData.append('correctAnswer', this.formGroup.value.correctAnswer);
      formData.append('answerExplanation', this.formGroup.value.answerExplanation == null ? this.formGroup.value.answerExplanation : this.strip_tags(this.formGroup.value.answerExplanation, 'b', 'i', 'ul' , 'li' , 'br' , 'div' , 'span' , 'p'));
      formData.append('companyId', this.formGroup.value.companyId == null ? 0 : this.formGroup.value.companyId);
      formData.append('parentId', this.formGroup.value.parentId == null ? 0 : this.formGroup.value.parentId);
      if (this.fileData2) {
        formData.append('answerImage', this.fileData2);
      }
      if (this.newBlobArray.length > 0) {
        for (let b of this.newBlobArray) {
          formData.append('questionImage', b);
        }
      }     

      if (this.viewID) {
        this.jobpostQuestionTestService.editJobPostQuestion(this.viewID, formData)
          .subscribe((data: any) => {

            if (data.status == 0) {
              this.images = [];
              this.newBlobArray = [];
              this.GetTimeQuestionImage = [];
              this.CorrectAnserArray = [false, false, false, false, false];
              this.fileData2 = null;
              this.thumbImagePath = null;
              this.thumbImageURL = null;
              window.scrollTo(0, 0);
              this.snackBar.open('Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              if (submiType == 0) {
                if (this.typeSelect == true) {
                  this.router.navigate(['/editComprehnsionQuestion/' + this.viewID]);
                }
                else {
                  this.router.navigate(['/listjobTestQuestion']);
                }

                //  this.router.navigate(['/listjobTestQuestion']);
              } else {
                this.submitted = false;
                this.formGroup.controls.question.reset();
                this.formGroup.controls.answerExplanation.reset();
                this.formGroup.controls.correctAnswer.reset();
                this.t.reset();
                this.questionImage = null;
                this.fileData = null;
              }
            } else {
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }
          },
            (err:any) => {
              this.snackBar.open(err.error.message == '' ? 'server error': err.error.message , '', {
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else {
        this.jobpostQuestionTestService.addJobPostQuestion(formData).subscribe(res => {
          console.log(res.response);

          if (res.status == 0) {
            this.images = [];
            this.newBlobArray = [];
            this.GetTimeQuestionImage = [];
            this.fileData2 = null;
            this.thumbImagePath = null;
            this.thumbImageURL = null;
            this.CorrectAnserArray = [false, false, false, false, false];
            window.scrollTo(0, 0);
            this.snackBar.open(' Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            if (submiType == 0) {
              if (this.typeSelect == true) {
                this.router.navigate(['/editComprehnsionQuestion/' + res.response]);
              }
              else {
                this.router.navigate(['/listjobTestQuestion']);
              }

            } else {
              this.submitted = false;
              this.formGroup.controls.question.reset();
              this.formGroup.controls.answerExplanation.reset();
              this.formGroup.controls.correctAnswer.reset();
              this.t.reset();
              this.questionImage = null;
              this.fileData = null;
            }
          } else {
            this.snackBar.open(res.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        } , (error: any)=>{
          this.snackBar.open(error.error.message == '' ? 'server error': error.error.message , '', {
            duration: 3000,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
            panelClass: ['error-snackbar']
          });
        });
      }

    } else {
      this.submitted = true;
      this.formGroup.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      const mm = this.formGroup.controls.correctAnswer.value;
      if (mm == '' || mm == null) {
        this.CorrecTanswerNotSlect = true;
      } else {
        this.CorrecTanswerNotSlect = false;
      }
      const d = (mm == '' || mm == null) ? 'Please Select Correct Answer' : 'Please Enter All Details';
      if (mm == "") {
        this.snackBar.open('Please Select Correct Answer', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
      this.snackBar.open('Please Fill All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      return;
    }
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(100),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
    // firstInvalidControl.focus(); //without smooth behavior
  }
  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  firstSpaceRemoveValid(e): any {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  QuestionTypeSelectionDropDown(e): any {
    console.log(e)
    this.selectedQuestionTypeId = e.value;
    console.log(e);
    if (e.value == 3 || e.value == 9) {
      this.typeSelect = true;
    } else {
      this.typeSelect = false;
    }
  }
  newOptionadd(): any {
    if (this.addnewOption < 5) {
      this.addnewOption = this.addnewOption + 1;
      console.log(this.addnewOption);
      this.LangListLoad(this.addnewOption);
      this.addBtnHide = false;
    } else {
      this.addBtnHide = true;
    }
    if (this.addnewOption == 5) {
      this.addBtnHide = true;
    } else {
      this.addBtnHide = false;
    }

  }

  onCheckboxChange(e, i): any {
    console.log(e);
    console.log(this.addnewOption);
    // this.f.options[i].setValue({
    //   response: [''],
    //       valid: true,
    // })

    this.CorrecTanswerNotSlect = false;
    // console.log(this.CorrectAns[i]);
    // this.CorrectAns[i]= e.checked;
    // console.log( this.CorrectAns[i]);
    if (e.checked) {
      this.CorrectAnserArray.splice(i, 1, true);
      let m = 0;
      for (m = 0; m < 5; m++) {
        if (m !== i) {
          this.CorrectAnserArray.splice(m, 1, false);
        }
      }
      // this.CorrectAns[i] =e.target.checked;
      // this.days.push(e.target.value);
    } else {
      // this.days.splice(this.rightsaName[i].value, 1);
    }

    console.log(this.CorrectAnserArray);

  }
    b64toBlob(dataURI): any {
      const byteString = atob(dataURI.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      console.log(dataURI)
      // [blob], this.dat.imageName, {type: 'image/png'}
      return new File([ia], "mrunal.png", { type: 'image/png' });

      // return new Blob([ab], "mrunal.png", { type: 'image/jpeg' });
    }


  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          console.log(event);
          this.images.push(event.target.result);
          console.log(this.b64toBlob(event.target.result))
          this.newBlobArray.push(this.b64toBlob(event.target.result))
          // console.log(this.newBlobArray)
          // this.formGroup.patchValue({
          //   questionImage: this.newBlobArray,
          // });
          // this.formGroup.controls.questionImage.setValue(this.newBlobArray);
        }
        console.log(this.newBlobArray)
        reader.readAsDataURL(event.target.files[i]);
      }
    }
    // if (event.target.files.length > 0) {
    //   this.fileData = event.target.files[0] as File as File;
    //   const reader = new FileReader();
    //   this.testImagePath = files;
    //   reader.readAsDataURL(event.target.files[0]);
    //   // tslint:disable-next-line: variable-name
    //   reader.onload = (_event) => {
    //     this.questionImage = reader.result;
    //   };
    // }
    // this.newimgeuplod = true;

    // console.log(this.fileData);
  }

  testImageReset(): any {
    // this.questionImage = '';
    // this.myInputVariable.nativeElement.value = '';
  }

  deleteOption(i) {
    this.t.removeAt(i);
    this.addnewOption = this.addnewOption - 1;
    if (this.addnewOption == 5) {
      this.addBtnHide = true;
    } else {
      this.addBtnHide = false;
    }
  }
  removeUploadedImages(i) {
    this.images.splice(i, 1);
  }
  removeGetImage(id, i) {
    if (confirm('Are you sure Want to delete Question Image ?')) {
      this.jobpostQuestionTestService.deleteQuestionImage(id).subscribe((res: any) => {
        if (res.status == 0) {
          this.snackBar.open('delete successfully ', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.GetTimeQuestionImage = this.GetTimeQuestionImage.filter(d => {
            if (id != d.id) {
              return d;
            }
          });
        } else {

        }
      }, (error: any) => {

      })
    }
  }
  removeAtt(e) {
    // console.log(this.strip_tags(e.target.innerHTML, 'b', 'i', 'ul' , 'li'))
    // return this.strip_tags(e.target.innerHTML, 'b', 'i', 'ul' , 'li')
    document.querySelectorAll('.mrin .angular-editor-textarea div').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea table').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    });
    document.querySelectorAll('.mrin .angular-editor-textarea tr').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea td').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea p').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea span').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');

    })
    document.querySelectorAll('.mrin .angular-editor-textarea img').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('src');
      item.remove();      
    })
    document.querySelectorAll('.mrin .angular-editor-textarea mat-checkbox').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea mat-form-field').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea input').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea select').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea button').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea label').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea a').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('href');
      item.remove();
    });
    document.querySelectorAll('.mrin .angular-editor-textarea svg').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.remove();
    });
    
    document.querySelectorAll(".mrin .angular-editor-textarea div, .mrin .angular-editor-textarea label, .mrin .angular-editor-textarea a ,.mrin .angular-editor-textarea button ,.mrin .angular-editor-textarea select , .mrin .angular-editor-textarea input , .mrin .angular-editor-textarea mat-checkbox , .mrin .angular-editor-textarea span ").forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    // this.jobDescriptionFromGroup.controls[formcontrolname].setValue(pastedText);
    // return d;
  }

  LangChange(e) {
    this.selectLang = e.value;
    for(let b of this.LangList){
      if(b.languageId == this.selectLang){
        this.questionGroupList(0, 500, b.languageCode);
      }
    }
  
  }

  AnswerRemoveUploadedImages(i) {
    this.Answerimages.splice(i, 1);
  }
  AnsweRremoveGetImage(id, i) {
    if (confirm('Are you sure Want to delete Question Image ?')) {
      this.jobpostQuestionTestService.deleteQuestionImage(id).subscribe((res: any) => {
        if (res.status == 0) {
          this.snackBar.open('delete successfully ', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.AnswerGetTimeQuestionImage = this.AnswerGetTimeQuestionImage.filter(d => {
            if (id != d.id) {
              return d;
            }
          });
        } else {

        }
      }, (error: any) => {

      })
    }
  }
  // AnswerfileChangeEvent(event: any): void {
  //   if (event.target.files && event.target.files[0]) {
  //     var filesAmount = event.target.files.length;
  //     for (let i = 0; i < filesAmount; i++) {
  //       var reader = new FileReader();
  //       reader.onload = (event: any) => {
  //         this.Answerimages.push(event.target.result);
  //         this.answerBlobArray.push(this.b64toBlob(event.target.result));
  //       }
  //       reader.readAsDataURL(event.target.files[i]);
  //     }
  //   }
  // }
  AnswerfileChangeEvent(event, files) {
    if (event.target.files.length > 0) {
      this.fileData2 = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.thumbImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.thumbImageURL = reader.result;
      };
    }
    this.newthumbImageupload = true;
  }
  thumbImageReset() {
    this.thumbImageURL = '';
    this.myInputVariable2.nativeElement.value = '';
    
  }

  

}
