<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">Edit</span>
        Interview Setting
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listInterviewSetting']">List Of Interview Setting</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!viewID">Add New</span>
            <span *ngIf="viewID">Edit</span>
            Interview Setting</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body" style="padding: 0;">

      <div class="" style="padding: 1.25em;">
        <form [formGroup]="formGroup" appInvalidControlScroll>
          <div class="row">

            <div class="form-group row col-sm-12">
              <div class="form-group row col-sm-6">
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">Job Category</label>
                  <div class="col-sm-12 ">
                    <mat-select (openedChange)="myInput.focus()" (closed)="myInput.value='';search(myInput.value)"
                      [disabled]="viewID" (selectionChange)="jobcatSelection($event)"
                      [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobcategoryId').errors }"
                      formControlName="jobcategoryId" required placeholder="Please select job category"
                      class="form-control">
                      <input class="myInput form-control" #myInput focused="'true'" type="text"
                      (keydown)="$event.stopPropagation()"
                      (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                      <mat-option *ngFor="let t of jobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                        {{t.displayName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      <span *ngIf="!formGroup.get('jobcategoryId').valid && formGroup.get('jobcategoryId').touched">
                        Please select job category </span>
                    </mat-error>
                    <mat-error *ngIf="JobQuniError == true">
                      <div>Please Select other Category</div>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group row col-sm-6">
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">Job Sub Category</label>
                  <div class="col-sm-12 ">
                    <mat-select (openedChange)="myInputsub.focus()" [disabled]="viewID"
                      (closed)="myInputsub.value='';jobsubsearch(myInputsub.value)"
                      [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobSubCategoryId').errors }"
                      formControlName="jobSubCategoryId" required placeholder="Please select job subcategory"
                      class="form-control" [multiple]="!viewID">

                      <input class="myInput form-control" #myInputsub focused="'true'" type="text"
                      (keydown)="$event.stopPropagation()"  
                      (keyup)="jobsubsearch($event.target.value)" autocomplete="off" placeholder="search">
                      <mat-checkbox *ngIf="!viewID" class="example-margin" [checked]="allComplete" (change)="SelectAllJobCat(subjobCatList, $event)">Select All
                      </mat-checkbox>
                      <mat-option *ngFor="let t of subjobCatList" [value]="t.jobCategoryId"
                        [aria-label]="t.jobCategoryId">
                        {{t.displayName}}
                      </mat-option>
                    </mat-select>

                    <!-- <mat-select (openedChange)="myInputsub.focus()"
                      (closed)="myInputsub.value='';jobsubsearch(myInputsub.value)"
                      [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobSubCategoryId').errors }"
                      formControlName="jobSubCategoryId" required placeholder="Please select job subcategory"
                      class="form-control" *ngIf="viewID">

                      <input class="myInput form-control" #myInputsub focused="'true'" type="text"
                        (keyup)="jobsubsearch($event.target.value)" autocomplete="off" placeholder="search">
                     <mat-option *ngFor="let t of subjobCatList" [value]="t.jobCategoryId"
                        [aria-label]="t.jobCategoryId">
                        {{t.displayName}}
                      </mat-option>
                    </mat-select> -->

                    <mat-error>
                      <span
                        *ngIf="!formGroup.get('jobSubCategoryId').valid && formGroup.get('jobSubCategoryId').touched">
                        Please select job sub category </span>
                    </mat-error>
                    <mat-error *ngIf="JobQuniError == true">
                      <div>Please Select other SubCategory</div>
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>


            <div class="form-group row col-sm-12 icc_dive">
              <div class="form-group row col-sm-6">
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">Enter Duration </label>
                  <div class="col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>Enter Duration </mat-label>
                      <input matInput type="text" value="" placeholder="Enter duration in min" name="durationInMin"
                        formControlName="durationInMin" (keypress)="numberOnly($event)" required>
                    </mat-form-field>
                    <mat-error>
                      <span *ngIf="!formGroup.get('durationInMin').valid && formGroup.get('durationInMin').touched">
                        Please enter duration</span>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group row col-sm-6">
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">Number of Retry</label>
                  <div class="col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>Enter Number of Retry </mat-label>
                      <input matInput type="text" value="" placeholder="Enter Number of Retry" name="noOfRetry"
                        formControlName="noOfRetry" (keypress)="numberOnly($event)" required>
                    </mat-form-field>
                    <mat-error>
                      <span *ngIf="!formGroup.get('noOfRetry').valid && formGroup.get('noOfRetry').touched">
                        Please enter no of retry</span>
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row col-sm-12 icc_dive">
              <div class="form-group row col-sm-6">
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">Enter Number of multiple Choice Question</label>
                  <div class="col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>Enter Number of multiple Choice Question </mat-label>
                      <input matInput type="text" value="" placeholder="Enter Number of multiple Choice Question"
                        name="cv" formControlName="noOfMcq" (keypress)="numberOnly($event)" required>
                    </mat-form-field>
                    <mat-error>
                      <span *ngIf="!formGroup.get('noOfMcq').valid && formGroup.get('noOfMcq').touched">
                        Please enter number of multiple Choice Question</span>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group row col-sm-6">
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">No of Video Questions</label>
                  <div class="col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>Enter interview </mat-label>
                      <input matInput type="text" value="" placeholder="Enter no of video question"
                        name="noOfVideoQuestions" formControlName="noOfVideoQuestions" (keypress)="numberOnly($event)"
                        required>
                    </mat-form-field>
                    <mat-error>
                      <span
                        *ngIf="!formGroup.get('noOfVideoQuestions').valid && formGroup.get('noOfVideoQuestions').touched">
                        Please enter no of video question</span>
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>





          </div>

          <button routerLink="/listInterviewSetting" type="button"
            class="btn btn-main-warning pd-x-20 mg-t-10">Back</button>&nbsp;&nbsp;
          <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
            *ngIf="!viewID">Submit</button>
          <!-- <button (click)="onSubmit('1')" type="submit" class="btn btn-success pd-x-20 mg-t-10 mar15" >Submit & Next</button> -->
          <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
            *ngIf="viewID">Update</button>
        </form>
        <!-- <div>
          <button mat-raised-button (click)="openContentOne('1')"> go Back content 1</button>
        </div> -->
      </div>

      <!-- *ngIf="firstFormSubmited == true" -->

    </div>

  </div>


</div>