import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from 'src/app/service/language/language.service';

@Component({
  selector: 'app-add-update-contract-type',
  templateUrl: './add-update-contract-type.component.html',
  styleUrls: ['./add-update-contract-type.component.scss']
})
export class AddUpdateContractTypeComponent implements OnInit {
  LangList=[];
  contractTypeId:number;
  dynamicForm: FormGroup;
  submitted = false;
  data: any;
  pdata:any;
  radioValue:number=0;
  constructor(public langService :LanguageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService :MasterDataService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.contractTypeId = this.route.snapshot.params['id']; 
      this.dynamicForm = this.formBuilder.group({ 
        contractTypeMultiLanguageList: new FormArray([]),
        value:[0, Validators.required]
      });    
      this.LangList = JSON.parse(this.langService.getLngList());
      this.LangListLoad(this.LangList.length);
      if(this.contractTypeId){
        this.patchForm();
      }
  }
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.contractTypeMultiLanguageList as FormArray; }
  LangListLoad(e) {
    const numberOfTickets = e
    if (this.t.length < numberOfTickets) {
        for (let i = this.t.length; i < numberOfTickets; i++) {
            this.t.push(this.formBuilder.group({
              languageId:[this.LangList[i].languageId],              
              contractType: [ '' , Validators.required],  
              contractTypeId:0,
              id:0,
              value:0, 
              isActive: true,          
            }));
        } 
    } else {
        for (let i = this.t.length; i >= numberOfTickets; i--) {
            this.t.removeAt(i);
        }
    }
  }
  patchForm(){
    this.masterService.getContractTypeById(this.contractTypeId).subscribe(res=>{
      this.pdata = res;
    if(this.pdata.status == 0){
      var data = this.pdata.response;
      this.dynamicForm.patchValue({
        contractTypeMultiLanguageList:data.contractTypes
      });
      this.radioValue=data.value;
    } else {
      this.snackBar.open('response Status -1 ', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
    });
  }
  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.dynamicForm.valid) {   
      if (this.contractTypeId){
        this.masterService.updateContractType(this.contractTypeId, this.dynamicForm.value)
          .subscribe(data => {
              this.snackBar.open('Cotract Type Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listContractType']);
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['error-snackbar']
              });
            }
          );
      } else{
        this.masterService.createContractType(this.dynamicForm.value).subscribe(res => {
          this.data=res;
          if(this.data.status ==  0 ) {
            this.snackBar.open('Contract Type Inserted Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listContractType']);
          }
            else {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']});
          }

        });
      }
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }
}
