import { ContentObserver } from '@angular/cdk/observers';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { JobTestQuestionManageService } from 'src/app/service/jobPostTestandQuestion/job-test-question-manage.service';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-publish-test-view',
  templateUrl: './publish-test-view.component.html',
  styleUrls: ['./publish-test-view.component.scss']
})
export class PublishTestViewComponent implements OnInit {
  viewID: any;
  selectedTab: any;
  pData: any;
  selectedLang: any;
  data: any;
  questionDataList: Array<any>;
  LangList: Array<any>;
  selectLangIsoCode: string = 'en';


  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public langService: LanguageService,
              private masterDataservice: MasterDataService,
              private jobpostQuestionTestService: JobTestQuestionManageService,
              private router: Router) { }

  ngOnInit(): void {
    this.viewID = this.route.snapshot.params['id'];
    this.selectedTab = this.route.snapshot.params['tab'];
    this.LangList = JSON.parse(this.langService.getLngList());
    console.log(this.LangList); 
    this.pageDetails(this.viewID);
  }
  pageDetails(id): any{
    this.jobpostQuestionTestService.getPusblishTestDetailsById(id).subscribe(res => {
      this.data = res;
      if (this.data.status == 0){
        const langId = this.data.response.assesmentTest.languageId;
        for (let b of this.LangList){
          if (langId == b.languageId){
            this.selectedLang = b.languageName;
            this.selectLangIsoCode = b.languageCode;
          }
        }
        this.pData = this.data.response.assesmentTest;
        this.questionDataList = this.data.response.questionDetails;
        console.log(this.questionDataList)
        let sum = 1;
        for(let b of this.questionDataList){
          if(b.comprehensiveQuestionDetails.length >0){
            for(let c of b.comprehensiveQuestionDetails)
            c.questionDetails.forEach(v => {v.qnosort = sum; sum++});           
          } 
          if(b.nonCoprehensiveDetails.length >0) {
            b.nonCoprehensiveDetails.forEach(v => {v.qnosort = sum; sum++})
          }
        }
        console.log(this.questionDataList)

      } else {
        this.snackBar.open('Server status -1', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }

    });
  }
  questionreplace(e){
    return e;
    // return e.replaceAll(/\n/g, "<br/>");
  }

}
