import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateService } from '../../../service/candidateDetails/candidate.service';
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: 'app-candidate-cv-score-view',
  templateUrl: './candidate-cv-score-view.component.html',
  styleUrls: ['./candidate-cv-score-view.component.scss'],
})
export class CandidateCvScoreViewComponent implements OnInit {
  resumeId: any;
  isCvUploaded: any;
  uploadCVViewper = false;
  // uploadCvUrl: any;
  candidateId: any;
  userData: any;
  userProfile: any;
  url: any;
  uploadCvUrl: any;
  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private candidateService: CandidateService
  ) {}

  ngOnInit(): void {
    this.candidateId = this.route.snapshot.params['id'];
    this.pacthData();
  }

  pacthData(): void {
    this.candidateService
      .getcandidateResume(this.candidateId)
      .subscribe((res: any) => {
        if (res.status === 0) {
          if (res.response.videoResume) {
            const url = res.response.videoResume;
            this.uploadCvUrl = url;
          } else {
            this.snackBar.open( 'Candidate Video Resume Not Available !!', "", {
              duration: 3000,
              panelClass: ["error-snackbar"],
            });
          }
          // console.log('::::::::::::::::::=========', this.uploadCvUrl);
          // this.profileService.emitJobNextBtnFn(7);
          // window.scrollTo(0, 0);
        }
      });
  }
}
