import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-add-update-question',
  templateUrl: './add-update-question.component.html',
  styleUrls: ['./add-update-question.component.scss']
})
export class AddUpdateQuestionComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;
  questionId: any;
  fileData: File = null;
  private categoryData: Object;
  categoryList: any;
  private roleList: any[];
  private roleData: any;
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;

  ngOnInit(): void {
    this.questionId = this.route.snapshot.params['id'];
    this.initializeForm();
    this.getAllCategoryList();
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      categoryTypeId: [ '' , Validators.required],
      questionName: [ '' , Validators.required],
      description: [ '' , Validators.required],
    });

    if (this.questionId){
      this.patchQuestionData();
    }
  }

  getAllCategoryList(){
    this.categoryList =[];
    this.masterService.getAllPersonalityCategory().subscribe( res=>{
      this.roleData = res;
      if(this.roleData.status == 0){
        this.categoryList = this.roleData.response.dataSet;
      } else {
        this.snackBar.open('response Status -1 ', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    })
  }

  // get data from jobCategoryId
  patchQuestionData(){
    this.masterService.getQuestionDetailsById(this.questionId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.formGroup.patchValue({
            questionName: this.data.response.questionName,
            description: this.data.response.description,
            categoryTypeId: this.data.response.categoryTypeId
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const payload = {
        questionName: this.formGroup.value.questionName,
        categoryTypeId: this.formGroup.value.categoryTypeId,
        description: this.formGroup.value.description,
      }
      if (this.questionId){
        this.masterService.updatePersonalityQuestion(this.questionId, payload)
          .subscribe(data => {
              this.snackBar.open('Question Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/mangeQuestion']);
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{

      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
