import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-add-update-country-data',
  templateUrl: './add-update-country-data.component.html',
  styleUrls: ['./add-update-country-data.component.scss']
})
export class AddUpdateCountryDataComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  countryId: any;
  fileData: File = null;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  ngOnInit(): void {
    this.countryId = this.route.snapshot.params['id'];
    this.initializeForm();
  }


  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      shortName: [ '' , Validators.required],
      countryName: [ '' , Validators.required],
      phoneCode: [ '' , Validators.required],
      countryFlag: [ ''],
      vatPercentage: [ '' , Validators.required],

    });

    if (this.countryId){
      this.patchCountryData();
    }
  }

  // get data from jobCategoryId
  patchCountryData(){
    this.masterService.getCountryNewById(this.countryId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.formGroup.patchValue({
            countryName: this.data.response.countryName ,
            shortName: this.data.response.shortName ,
            phoneCode: this.data.response.phoneCode,
            vatPercentage: this.data.response.vatPercentage,
          });
          this.countryFlagImgURL = this.data.response.icon;
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  countryFlagReset() {
    this.countryFlagImgURL = '';
    this.myInputVariable.nativeElement.value = '';
  }
  countryFlagSelct(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };
    }
    this.newimgeuplod = true;
  }


  onSubmit() {
    if (this.formGroup.valid) {
      const formData: FormData = new FormData();
      if(this.countryId) {formData.append('countryId' , this.countryId);}
      formData.append('shortName' , this.formGroup.value.shortName);
      formData.append('countryName' , this.formGroup.value.countryName);
      formData.append('phoneCode' , this.formGroup.value.phoneCode);
      formData.append('vatPercentage' , this.formGroup.value.vatPercentage);
      if(this.fileData){formData.append('icon' , this.fileData);}

      if (this.countryId){
        this.masterService.updateNewCountry(formData)
          .subscribe((data:any) => {
              if(data.status == 0)
              {
                this.snackBar.open('Country Details Update Successfully !!', '', {
                  duration: 3000,
                  panelClass: ['success-snackbar']
                });
                this.router.navigate(['/listCountryData']);
              }
              else{
                this.snackBar.open(data.response, '', {
                  duration: 3000,
                  panelClass: ['error-snackbar']
                });
              }

            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{
        this.masterService.createNewCountry(formData).subscribe((data:any) => {
          if(data.status == 0)
          {
            this.snackBar.open('Country Inserted Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listCountryData']);
          }
          else{
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }
  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57  )) {
      return false;
    }
    return true;
  }

}
