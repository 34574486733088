<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">Edit</span>
        job test creation
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listJobTestCreation' , selectLang]">List Of test creation</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!viewID">Add New</span>
            <span *ngIf="viewID">Edit</span>
            job test creation</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body" style="padding: 0;">
      <mat-tab-group [(selectedIndex)]="selectedTab">
        <mat-tab label="Test">
          <div class="" style="padding: 1.25em;">
            <form [formGroup]="formGroup" appInvalidControlScroll>
              <div class="row">
                <div class="d-flex row" style="width: 100%;">
                  <div class="form-group  col-sm-5">
                    <label class="col-sm-12">Language Name</label>
                    <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="Please Select Language Name" matTooltipPosition="above"
                      aria-label="Please Select Language Name">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                    <div class="col-sm-12 ">
                      <mat-select [disabled]="viewID" formControlName="languageId" required
                        (selectionChange)="LangChange($event)" placeholder="Please Select Language Name"
                        [ngClass]="{ 'is-invalid': submitted && formGroup.get('languageId').errors }"
                        class="form-control">
                        <mat-option *ngFor="let r of LangList" [value]="r.languageId" [aria-label]="r.languageId">
                          {{r.languageName}}
                        </mat-option>
                      </mat-select>
                      <mat-error>
                        <span *ngIf="!formGroup.get('languageId').valid && formGroup.get('languageId').touched">
                          Please Select Language Name </span>
                      </mat-error>
                    </div>
                  </div>
                  <div class="form-group  col-sm-7">
                    <label class="col-sm-12">Job Category</label>
                    <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="Please select job category" matTooltipPosition="above"
                      aria-label="Please select job category">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                    <div class="col-sm-12 ">
                      <mat-select (closed)="myInput.value='';search(myInput.value)" (openedChange)="myInput.focus()"
                        (selectionChange)="jobcatSelection($event)"
                        [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobCategoryId').errors }"
                        formControlName="jobCategoryId" required placeholder="Please select job category"
                        class="form-control">
                        <input class="myInput form-control" #myInput focused="'true'" type="text"
                        (keydown)="$event.stopPropagation()"  
                        (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                        <!-- <mat-select-filter [array]="variables" (filteredReturn)="filteredList1 =$event"></mat-select-filter> -->
                        <mat-option *ngFor="let t of jobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                          {{t.displayName}}
                        </mat-option>
                      </mat-select>
                      <mat-error>
                        <span *ngIf="!formGroup.get('jobCategoryId').valid && formGroup.get('jobCategoryId').touched">
                          Please select job category </span>
                      </mat-error>
                      <mat-error *ngIf="JobQuniError == true">
                        <div>Please Select other Category or change Language</div>
                      </mat-error>
                    </div>
                  </div>
                </div>
                
                <div class="form-group col-sm-12 row">
                  <label class="col-sm-12">Test Title<span class="red-text">*</span></label>
                  <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="Test Title" matTooltipPosition="above"
                    aria-label="Test Title">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                  </button>
                  <div class="col-sm-12 ">
                    <input type="text" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && formGroup.get('title').errors }" value=""
                      placeholder="Enter Test Title*" name="title" formControlName="title" required>
                    <mat-error>
                      <span *ngIf="!formGroup.get('title').valid && formGroup.get('title').touched">
                        Please Enter Test Title</span>
                    </mat-error>

                  </div>
                </div>

                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">Test Description for Company <span class="red-text">*</span></label>
                  <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="Test Description for Company" matTooltipPosition="above"
                    aria-label="Test Description for Company">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                  </button>
                  <div class="col-sm-12 ">
                    <angular-editor 
                    (mouseenter) ="removeAtt($event)"
                    (click)="removeAtt($event)"
                    (keydown)="firstSpaceRemoveValid($event)" class="mrin" (oninput)="removeAtt($event)"
                      (focus)="removeAtt($event)" (blur)="removeAtt($event)" (paste)="removeAtt($event)"
                      (keypress)="removeAtt($event)"
                      [ngClass]="{ 'is-invalid':submitted && formGroup.get('testDescriptionForCompany').errors }"
                      formControlName="testDescriptionForCompany" required
                      placeholder="Please Enter Test Description for Company*" [config]="config"
                      name="testDescriptionForCompany"></angular-editor>
                    <mat-error>
                      <span
                        *ngIf="!formGroup.get('testDescriptionForCompany').valid && formGroup.get('testDescriptionForCompany').touched">
                        Please Enter Test Description for Company</span>
                    </mat-error>
                  </div>
                </div>
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">test Description For Candidate <span class="red-text">*</span></label>
                  <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="test Description For Candidate " matTooltipPosition="above"
                    aria-label="test Description For Candidate*">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                  </button>
                  <div class="col-sm-12 ">
                    <angular-editor 
                    (mouseenter) ="removeAtt($event)"
                    (click)="removeAtt($event)"
                    (keydown)="firstSpaceRemoveValid($event)" class="mrin" (oninput)="removeAtt($event)"
                      (focus)="removeAtt($event)" (blur)="removeAtt($event)" (paste)="removeAtt($event)"
                      (keypress)="removeAtt($event)"
                      [ngClass]="{ 'is-invalid': submitted && formGroup.get('testDescriptionForCandidate').errors }"
                      formControlName="testDescriptionForCandidate" required
                      placeholder="Please Enter Test Description For Candidate" [config]="config"
                      name="testDescriptionForCandidate"></angular-editor>
                    <mat-error>
                      <span
                        *ngIf="!formGroup.get('testDescriptionForCandidate').valid && formGroup.get('testDescriptionForCandidate').touched">
                        Please Test Description For Candidate</span>
                    </mat-error>
                  </div>
                </div>
                <div class="d-flex" style="width: 100%;">
                  <div class="form-group col-sm-4 row">
                    <label class="col-sm-10">total Number Of Questions<span class="red-text">*</span> </label>
                    <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="total Number Of Questions" matTooltipPosition="above"
                      aria-label="total Number Of Questions*">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                    <div class="col-sm-12 ">
                      <input type="number" min="1" max="99" minlength="1" maxlength="99" step="0" oninput="this.value = this.value.replace(/^[0]*?$/g, '');
                         this.value = this.value.replace(/^[.][.][.]*?$/g, '');" (keypress)="numberOnly($event)"
                        [ngClass]="{ 'is-invalid': submitted && formGroup.get('totalNumberOfQuestions').errors }"
                        (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                        placeholder="Enter total Number Of Questions*" name="totalNumberOfQuestions"
                        formControlName="totalNumberOfQuestions" required>
                      <mat-error>
                        <span
                          *ngIf="!formGroup.get('totalNumberOfQuestions').valid && formGroup.get('totalNumberOfQuestions').touched">
                          Please Enter total Number Of Questions</span>
                      </mat-error>
                    </div>
                  </div>
                  <div class="form-group col-sm-4 row">
                    <label class="col-sm-12">Max Time In Minute<span class="red-text">*</span> </label>
                    <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="max Time In Minute" matTooltipPosition="above"
                      aria-label="max Time In Minute">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                    <div class="col-sm-12 ">
                      <input type="number" oninput="this.value = this.value.replace(/^[0]*?$/g, '');
                         this.value = this.value.replace(/(\..*)\./g, '$0');" min="1" max="99" minlength="1"
                        maxlength="99" step="0" (keydown)="firstSpaceRemoveValid($event)" class="form-control"
                        (keypress)="numberOnly($event)"
                        [ngClass]="{ 'is-invalid': submitted && formGroup.get('maxTimeInMin').errors }" value=""
                        placeholder="Enter Max Time In Minute*" name="maxTimeInMin" formControlName="maxTimeInMin"
                        required>
                      <mat-error>
                        <span *ngIf="!formGroup.get('maxTimeInMin').valid && formGroup.get('maxTimeInMin').touched">
                          Please Enter Max Time In Minute</span>
                      </mat-error>
                    </div>
                  </div>
                  <div class="form-group col-sm-4 row">
                    <label class="col-sm-12">Number Of Retry <span class="red-text">*</span></label>
                    <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="number Of Retry" matTooltipPosition="above"
                      aria-label="number Of Retry">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                    <!-- 
                      first zero remove
                      oninput="this.value = this.value.replace(/^[0]*?$/g, '');
                    this.value = this.value.replace(/(\..*)\./g, '$0');" -->
                    <div class="col-sm-12 ">
                      <input type="number" min="0" max="99" minlength="1"
                        maxlength="99" step="0" (keypress)="numberOnly($event)" (keydown)="firstSpaceRemoveValid($event)"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formGroup.get('numberOfRetry').errors }" value=""
                        placeholder="Enter number Of Retry*" name="numberOfRetry" formControlName="numberOfRetry"
                        required>
                      <mat-error>
                        <span *ngIf="!formGroup.get('numberOfRetry').valid && formGroup.get('numberOfRetry').touched">
                          Please Enter number Of Retry</span>
                      </mat-error>
                    </div>
                  </div>
                </div>
                
                <div class="d-flex mt-3" style="width: 100%;">
                  <div class="form-group col-sm-4 row">
                    <label class="col-sm-12">Passing Score Minimum(%) <span class="red-text">*</span></label>
                    <button class="customeToolTip" matTooltipClass='blue-tooltip' 
                    matTooltip="(pass rate)paasing rate minimum score E.x. if you enter 60 means morethan(>) 60% you will pass and below(<) 60%* you will fail" matTooltipPosition="above"
                      aria-label="normal Passing Score Minimum*">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                    <div class="col-sm-12 ">
                      <input type="number" #passminscrore oninput="this.value = this.value.replace(/^[0]*?$/g, '');
                         this.value = this.value.replace(/(\..*)\./g, '$0');" AddUpdateJobTestCreationComponent
                        (keyup)="minScrorValidation($event)" (keypress)="numberOnly($event)"
                        (keydown)="firstSpaceRemoveValid($event)" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formGroup.get('normalPassingScoreMin').errors }" value=""
                        placeholder="Enter Passing Score Minimum*" name="normalPassingScoreMin"
                        formControlName="normalPassingScoreMin" required>
                      <mat-error>
                        <span *ngIf="passminscrore.value > 100">
                          not more than 100%
                        </span>
                        <span
                          *ngIf="!formGroup.get('normalPassingScoreMin').valid && formGroup.get('normalPassingScoreMin').touched">
                          Please Enter normal Passing Score Minimum</span>
                      </mat-error>
                    </div>
                  </div>
                  <div class="form-group col-sm-4 row">
                    <label class="col-sm-12">Passing Score Maximum(%)<span class="red-text">*</span> </label>
                    <button class="customeToolTip" matTooltipClass='blue-tooltip' 
                    matTooltip="(very Good score) paasing rate maximum score E.x. if you enter 80% means 60% - 80% very Good score" matTooltipPosition="above"
                      aria-label="Passing Score Maximum">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                    <div class="col-sm-12 ">
                      <!-- [readOnly]="maxScoreDisable == true && (viewID == null)" -->
                      <input type="number" oninput="this.value = this.value.replace(/^[0]*?$/g, '');
                         this.value = this.value.replace(/(\..*)\./g, '$0');"
                        onKeyPress="if(this.value.length == 3 && this.value >= 100  ) return false;" min="1" max="100"
                        minlength="1" maxlength="3" step="0" 
                        (keypress)="numberOnly($event)" (keyup)="maxScrorValidation($event)"
                        (keydown)="firstSpaceRemoveValid($event)" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formGroup.get('normalPassingScoreMax').errors }" value=""
                        placeholder="Enter Passing Score Maximum*" name="normalPassingScoreMax"
                        formControlName="normalPassingScoreMax" required>
                      <mat-error>
                        <span
                          *ngIf=" 
                            (formGroup.get('normalPassingScoreMax')?.errors?.morethan == false) && 
                            !formGroup.get('normalPassingScoreMax').valid && formGroup.get('normalPassingScoreMax').touched">
                          Please Enter normal Passing Score Maximum </span>
                      </mat-error>
                      <mat-error *ngIf="pererror == true ">
                        <div>Enter Value Minimum {{passminscrore.value}} score(%) and Maximum 100%
                          <!-- Enter Value more than Minimum {{passminscrore.value}} score(%) and Maximum 100 -->
                        </div>
                      </mat-error>
                      <!-- <mat-error>
                            <span *ngIf="!formGroup.get('normalPassingScoreMax').valid && formGroup.get('normalPassingScoreMax').touched">
                                           Please Enter normal Passing Score Maximum</span>
                       </mat-error> -->
                      <!-- <mat-error *ngIf="pererror == true">
                      <div>Enter Value more than Minimum score</div>
                     </mat-error> -->
                    </div>
                  </div>
                  <div class="form-group col-sm-4 row">
                    <label class="col-sm-12">excellent Pass Score(%)<span class="red-text">*</span></label>
                    <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="more than 90% excellent Pass Score" matTooltipPosition="above"
                      aria-label="Button that displays a tooltip in various positions">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                    <div class="col-sm-12 ">
                      <!-- [readOnly]="excellentScoreDisable == true && (viewID == null)" -->
                      <input type="number" oninput="this.value = this.value.replace(/^[0]*?$/g, '');
                         this.value = this.value.replace(/(\..*)\./g, '$0');"
                        onKeyPress="if(this.value.length == 3 ) return false;" min="1" max="100" minlength="1"
                        maxlength="3" step="0" 
                        (keypress)="numberOnly($event)" (keydown)="firstSpaceRemoveValid($event)"
                        (keyup)="ExcellentPassScoreValidation($event)" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formGroup.get('excellentPassScore').errors }" value=""
                        placeholder="Enter excellent Pass Score*" name="excellentPassScore"
                        formControlName="excellentPassScore" required>
  
                      <mat-error>
                        <span
                          *ngIf="
                                    excellentPassScoreError == false &&
                                    !formGroup.get('excellentPassScore').valid && formGroup.get('excellentPassScore').touched">
                          Please Enter excellent Pass Score</span>
                      </mat-error>
                      <mat-error *ngIf="excellentPassScoreError == true">
                        <div> Enter Value maximum score and not more than 100%</div>
                      </mat-error>
  
                      <mat-error>
                        <span
                          *ngIf="!formGroup.get('excellentPassScore').valid && formGroup.get('excellentPassScore').touched">
                        </span>
                      </mat-error>
                      <mat-error *ngIf="excellentPassScoreError == true">
                        <div>Enter Value more than min score</div>
                      </mat-error>
                    </div>
                  </div>
                </div>
                

               
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">pass Result Description <span class="red-text">*</span></label>
                  <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="pass Result Description" matTooltipPosition="above"
                    aria-label="pass Result Description">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                  </button>
                  <div class="col-sm-12 ">
                    <angular-editor
                    (mouseenter) ="removeAtt($event)"
                    (click)="removeAtt($event)"
                    (keydown)="firstSpaceRemoveValid($event)" class="mrin" (oninput)="removeAtt($event)"
                      (focus)="removeAtt($event)" (blur)="removeAtt($event)" (paste)="removeAtt($event)"
                      (keypress)="removeAtt($event)"
                      [ngClass]="{ 'is-invalid':submitted && formGroup.get('passResultDescription').errors }"
                      formControlName="passResultDescription" required
                      placeholder="Please Enter pass Result Description*" [config]="config"
                      name="passResultDescription">
                    </angular-editor>
                    <mat-error>
                      <span
                        *ngIf="!formGroup.get('passResultDescription').valid && formGroup.get('passResultDescription').touched">
                        Please Enter pass Result Description</span>
                    </mat-error>
                  </div>
                </div>
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">fail Result Description<span class="red-text">*</span> </label>
                  <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="fail Result Description" matTooltipPosition="above"
                    aria-label="fail Result Description">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                  </button>
                  <div class="col-sm-12 ">
                    <angular-editor
                    (mouseenter) ="removeAtt($event)"
                    (click)="removeAtt($event)"
                    (keydown)="firstSpaceRemoveValid($event)" class="mrin" (oninput)="removeAtt($event)"
                      (focus)="removeAtt($event)" (blur)="removeAtt($event)" (paste)="removeAtt($event)"
                      (keypress)="removeAtt($event)"
                      [ngClass]="{ 'is-invalid':submitted && formGroup.get('failResultDescription').errors }"
                      formControlName="failResultDescription" required
                      placeholder="Please Enter fail Result Description*" [config]="config"
                      name="failResultDescription">
                    </angular-editor>
                    <mat-error>
                      <span
                        *ngIf="!formGroup.get('failResultDescription').valid && formGroup.get('failResultDescription').touched">
                        Please fail Result Description</span>
                    </mat-error>
                  </div>
                </div>
                <div class="form-group row col-sm-12">
                  <label class="col-sm-12">excellent Pass Description<span class="red-text">*</span></label>
                  <button class="customeToolTip" matTooltipClass='blue-tooltip' matTooltip="excellent Pass Description " matTooltipPosition="above"
                    aria-label="excellent Pass Description ">
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                  </button>
                  <div class="col-sm-12 ">
                    <angular-editor 
                    (mouseenter) ="removeAtt($event)"
                    (click)="removeAtt($event)"
                    (keydown)="firstSpaceRemoveValid($event)" class="mrin" (oninput)="removeAtt($event)"
                      (focus)="removeAtt($event)" (blur)="removeAtt($event)" (paste)="removeAtt($event)"
                      (keypress)="removeAtt($event)"
                      [ngClass]="{ 'is-invalid':submitted && formGroup.get('excellentPassDescription').errors }"
                      formControlName="excellentPassDescription" required
                      placeholder="Please Enter excellent Pass Description*" [config]="config"
                      name="excellentPassDescription"></angular-editor>
                    <mat-error>
                      <span
                        *ngIf="!formGroup.get('excellentPassDescription').valid && formGroup.get('excellentPassDescription').touched">
                        Please excellent Pass Description</span>
                    </mat-error>
                  </div>
                </div>


              </div>
              <div class="d-flex justify-content-end">
                <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                *ngIf="!viewID">Submit</button>
              <!-- <button (click)="onSubmit('1')" type="submit" class="btn btn-success pd-x-20 mg-t-10 mar15" >Submit & Next</button> -->
              <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                *ngIf="viewID">Update</button>
              </div>
              
            </form>
            <!-- <div>
              <button mat-raised-button (click)="openContentOne('1')"> go Back content 1</button>
            </div> -->
          </div>
        </mat-tab>
        <mat-tab label="Test Question Add" *ngIf="viewID">
          <div class="">
            <mat-sidenav-container class="example-container" hasBackdrop="true">
              <mat-sidenav-content>
                <div class="bg-white">
                  <form class="card-body" [formGroup]="dyanmicQuestionForm" *ngIf="firstFormSubmited == true">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <div class="text-upper page-title">Add Question To Test</div>
                      <button class="btn mar15 btn-main-primary border-radius16" (click)="sidenav.toggle()">
                        preview
                      </button>
                    </div>

                    <div *ngFor="let ticket of t.controls; let i = index ;let l = last " class="form-group customBl">
                      <div [formGroup]="ticket" class="align-items-center" style="width: 100%;">
                        <div class="form-group ">
                          <label class="col-sm-12 d-flex justify-content-between">
                            <span class="text-upper page-title">{{i+1}}. Question Group</span>
                            <span matTooltip="Delete Test Group" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above" class="btn-sm red-text delete-btn"
                              (click)="deleteQuestionGroup(i)"><i class="fas fa-trash"></i></span>
                          </label>
                          <div class="col-sm-12 ">

                            <mat-select disableRipple [disabled]="ticket.get('flag').value == true || l == false "
                              [ngClass]="{ 'is-invalid': submitted && ticket.get('groupId').errors , 'lastgroup': l == true }"
                              (selectionChange)="questionCreate($event.value ,i,ticket.get('groupId'));ticket.get('flag').value = true"
                              formControlName="groupId" required (openedChange)="QuestionGropInput.focus()"
                              placeholder="Please Select Question Group" class="form-control">
                              <input class="QuestionGropInput form-control" #QuestionGropInput focused="'true'"
                                type="text" (keyup)="searchQuestionGrop($event.target.value,i)" autocomplete="off"
                                placeholder="search">
                              <mat-option *ngFor="let r of qustGropList[i]"
                                [disabled]="customQuestionGroupArray.indexOf(r.questionGroupId) >= 0"
                                [value]="r.questionGroupId" [aria-label]="r.questionGroupId">
                                {{r.groupName}}
                              </mat-option>
                            </mat-select>
                            <mat-error>
                              <span
                                *ngIf="!formGroup.get('jobCategoryId').valid && formGroup.get('jobCategoryId').touched">
                                Please select job category </span>
                            </mat-error>
                          </div>
                        </div>
                        <div class="tablescroll ">

                          <table mat-table [dataSource]="dataSource[i]" multiTemplateDataRows>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="select">
                              <th width="80px" mat-header-cell *matHeaderCellDef>
                                <!-- <mat-checkbox (change)="$event ? masterToggle(i) : null"
                                [checked]="selection[i].hasValue() && isAllSelected(i)"
                                              [indeterminate]="selection[i].hasValue() && !isAllSelected(i)">
                                </mat-checkbox> -->
                              </th>
                              <td width="80px" mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                  [disabled]="QuestionSubmitBtnHide == true &&  checkboxSelectArray.indexOf(row.assesmentQuestionId) < 0"
                                  (change)="checkboxclick(row, $event, i,row.assesmentQuestionId)"
                                  [checked]="selection[i].isSelected(row.assesmentQuestionId) || checkboxSelectArray.indexOf(row.assesmentQuestionId) >= 0">
                                </mat-checkbox>


                                <!-- (change)="$event ? selection[i].toggle(row.assesmentQuestionId) : null" -->
                              </td>
                            </ng-container>

                            <!-- Position Column -->
                            <ng-container matColumnDef="position">
                              <th width="80px" mat-header-cell *matHeaderCellDef>Sr.No. </th>
                              <td width="80px" mat-cell *matCellDef="let element;let df = dataIndex; let jd = index">
                                {{df+1}}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="createdEpochTime">
                              <th width="180px" mat-header-cell *matHeaderCellDef>created date </th>
                              <td width="180px" mat-cell *matCellDef="let element;">
                                {{element.createdEpochTime | date:'medium'}}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="questionLevel">
                              <th width="80px" mat-header-cell *matHeaderCellDef> question Type </th>
                              <td width="80px" mat-cell *matCellDef="let element;">
                                {{element.questionLevel}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="question">
                              <th mat-header-cell *matHeaderCellDef> Question Title </th>
                              <td mat-cell *matCellDef="let element">
                                <span class="questionTitleREmove" [innerHtml]="element.question"></span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="assesmentQuestionId">
                              <th width="80px" mat-header-cell *matHeaderCellDef> Question Id </th>
                              <td width="80px" mat-cell *matCellDef="let element"> {{element.assesmentQuestionId}} </td>
                            </ng-container>

                            <ng-container matColumnDef="addedBy">
                              <th width="80" mat-header-cell *matHeaderCellDef> added By </th>
                              <td width="80" mat-cell *matCellDef="let element">
                                <span class="addlabel" *ngIf="element.addedBy != null ">
                                  {{element.addedBy}}
                                </span>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                              <th width="80" mat-header-cell *matHeaderCellDef>Action </th>
                              <td width="80" mat-cell *matCellDef="let search ;let element; let j = index;">
                                <div class="d-flex align-items-center">
                                  <div matTooltip="Preview Comprehsive Question" matTooltipClass='blue-tooltip'
                                    matTooltipPosition="above" class="blueclr ble-view"
                                    *ngIf="element.questionLevel === 'COMPREHENSIVE' || element.questionLevel === 'COMPREHENSIVE_IMAGE' "
                                    (click)="getSubQuestionByParentId(element.assesmentQuestionId)"
                                    (click)="expandedElement = expandedElement === search ? null : search">
                                    <i class="fas fa-eye"></i>
                                  </div>
                                  <div class="blueclr" matTooltip="Preview Image Question"
                                    matTooltipClass='blue-tooltip' matTooltipPosition="above"
                                    *ngIf="!(element.questionImage == '' || element.questionImage == null) "
                                    (click)="imageQuestionPreview(element)">
                                    <i class="fas fa-image"></i>
                                  </div>
                                </div>

                            </ng-container>

                            <ng-container matColumnDef="expandedDetail">
                              <td mat-cell *matCellDef="let search;let element"
                                [attr.colspan]="displayedColumns.length">

                                <div class="example-element-detail"
                                  *ngIf="element.questionLevel === 'COMPREHENSIVE' || element.questionLevel === 'COMPREHENSIVE_IMAGE' "
                                  [@detailExpand]="search === expandedElement ? 'expanded' : 'collapsed'">
                                  <label class="main-content-label">Sub Question Details</label>
                                  <table mat-table [dataSource]="dataSource1" style="border: 1px solid #ddd;
                                          margin: 10px;">
                                    <ng-container matColumnDef="seqNo">
                                      <th width="80px" *matHeaderCellDef> Sr. No </th>
                                      <td width="80px" *matCellDef="let element;let innerdf = index">
                                        {{ innerdf + 1 }}
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="question">
                                      <th width="90%" *matHeaderCellDef> Question </th>
                                      <td width="90%" *matCellDef="let element">
                                        <span class="questionTitleREmove" [innerHtml]="element.question"></span>
                                      </td>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>


                                  </table>
                                  <div *ngIf="dataSource1.data.length == 0 ">
                                    <h1 class="nodata text-center">No Sub Questions </h1>
                                  </div>

                                </div>
                              </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="nocolorRow"
                              [class.example-expanded-row]="expandedElement === search">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row">
                            </tr>

                            <!-- (click)="selection[i].toggle(row.assesmentQuestionId)" -->
                          </table>
                          <ul class="" *ngIf="dataSource[i]?.data.length == 0 || dataSource[i]?.data == undefined "
                            class="parsley-errors-list filled">
                            <li class="col-sm-12 parsley-required" style="text-align: center">No Question available.</li>
                          </ul>

                        </div>
                        <!-- <button  mat-raised-button (click)="logSelection(i)">Console Log Selected</button> -->
                        <!-- <button (click)="removeElemt(i)">REmove Elements for this group</button>
                        <button  mat-raised-button (click)="logSelection(i)">Console Log Selected</button> -->
                        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->

                        <!-- <div class="form-group row col-sm-11">
                    <label class="col-sm-12">
                       option{{i+1}} </label>
                    <div class="col-sm-12 ">
                        <input  type="text"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && ticket.get('response').errors }"
                        (keydown)="firstSpaceRemoveValid($event)"
                        value="" placeholder="Enter option" name="response"
                               formControlName="response" required>
                       <mat-error>
                                <span *ngIf="submitted && ticket.get('response').errors">
                                  Please Enter Option Name </span>
                           </mat-error>
                    </div>
                  </div>
                   <div class="check-top row col-sm-1 ">
                    <mat-checkbox class="example-margin"
                    formControlName="valid" >
          </mat-checkbox>
                  </div> -->
                      </div>
                    </div>
                    <!-- <div class="col-sm-12 justify-content-between align-items-center d-flex ">
                      <div class="countposition" [ngClass]="{ 'morecount': checkboxSelectedCount > ToatlQuestionNo }">
                        <div class="main-content-label mb-0 mg-t-8">Question Count :
                          <h4 style="display: inline-block;">{{checkboxSelectedCount}} </h4>/ {{ToatlQuestionNo}}
                        </div>                        
                      </div>
                      <button *ngIf=" (checkboxSelectedCount < ToatlQuestionNo)" type="button"
                        class="btn mar15 btn-main-primary border-radius16" (click)="newOptionadd()">
                        <i class="fa fa-plus" aria-hidden="true"></i>Add New group
                      </button>
                    </div> -->
                    <br>

                    <div class="d-flex justify-content-between align-items-center mt-3 mb-3">
                      <div>
                        <button class="countposition btn btn-danger pd-x-20 mg-t-10 mar15"
                          [ngClass]="{ 'btn-warning': checkboxSelectedCount > ToatlQuestionNo }">
                          <div style="margin-top: -3px;" class="">Question Count :
                            <h4 style="display: inline-block;">{{checkboxSelectedCount}} </h4>/ {{ToatlQuestionNo}}
                          </div>
                        </button>
                        <button *ngIf=" (checkboxSelectedCount < ToatlQuestionNo)" type="button"
                          class="btn mar15 btn-main-primary border-radius16 mr-5 mg-t-10" (click)="newOptionadd()">
                          <i class="fa fa-plus" aria-hidden="true"></i>Add New group
                        </button>
                      </div>
                      <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-danger pd-x-20 mg-t-10"
                          [routerLink]="['/listJobTestCreation' , selectLang]">Back
                          to test list</button>
                        <button type="button" class="btn btn-info pd-x-20 mg-t-10 mar15" (click)="openContentOne('0')">
                          go Back
                          Test Details </button>
                        <!-- <button (click)="onSubmitQuestion('0')" type="submit" class="btn border-radius16 " *ngIf="!viewID && QuestionSubmitBtnHide == false">Question Submit</button>
                      <button (click)="onSubmitQuestion('0')" type="submit" class="btn border-radius16 mar15" *ngIf="QuestionSubmitBtnHide == false">Question Submit & Next</button> -->
                        <button (click)="onSubmitQuestion('2')" type="submit"
                          class="btn btn-secondary pd-x-20 mg-t-10 mar15"
                          *ngIf="QuestionSubmitBtnHide == false">Draft</button>
                        <!-- <button (click)="onSubmitQuestion('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10 " *ngIf="viewID && QuestionSubmitBtnHide == false">Question Update</button> -->

                        <button (click)="onSubmitQuestion('1')" type="submit" class="btn btn-success pd-x-20 mg-t-10"
                          *ngIf="checkboxSelectedCount == ToatlQuestionNo">
                          <span>
                            {{UpdateCreatedTest == true ? 'Update Test' : 'Create Test'}}
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>

                </div>
              </mat-sidenav-content>
              <mat-sidenav #sidenav position="end" mode="over" fixedTopGap="0" fixedBottomGap="0"
                fixedInViewport="true">
                <div class="">
                  <div class="" style="padding: 1.25em;     margin-top: 70px;">
                    <div *ngIf="viewID">
                      <div class="mt-1 product-spec">
                        <label class="main-content-label h1">Test Details</label>
                        <div class="pro_detail">
                          <ul class="list-unstyled mb-0">
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Language Name
                              </div>
                              <div class="col-sm-12  mb-2">{{selectedLangName}}</div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Job Parent Category</div>
                              <div class="col-sm-12  mb-2">{{jobcategoryName}}</div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Title</div>
                              <div class="col-sm-12  mb-2">{{title}}</div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Test Description
                                For
                                Company</div>
                              <div class="col-sm-12  mb-2 testViewInherit"><span [innerHtml]="testDescriptionForCompany"></span></div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Test Description
                                For
                                Candidate</div>
                              <div class="col-sm-12  mb-2 testViewInherit" ><span [innerHtml]="testDescriptionForCandidate"></span></div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Total Number Of
                                Questions</div>
                              <div class="col-sm-12  mb-2">{{ToatlQuestionNo}}</div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Max Time (Minute)
                              </div>
                              <div class="col-sm-12  mb-2">{{maxTimeInMin}}</div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Normal Passing
                                Score
                                (Min% - Max%)</div>
                              <div class="col-sm-12  mb-2">{{normalPassingScoreMin}}% - {{normalPassingScoreMax}}%
                              </div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Excellent Pass
                                Score
                              </div>
                              <div class="col-sm-12  mb-2">{{excellentPassScore}}% </div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Pass Result
                                Description</div>
                              <div class="col-sm-12  mb-2 testViewInherit"><span [innerHtml]="passResultDescription"></span></div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Fail Result
                                Description</div>
                              <div class="col-sm-12  mb-2 testViewInherit"><span [innerHtml]="failResultDescription"></span></div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Excellent Pass
                                Description</div>
                              <div class="col-sm-12  mb-2 testViewInherit"><span [innerHtml]="excellentPassDescription"></span></div>
                            </li>
                            <li class="row border-bottom-0">
                              <div class="col-sm-12 h2 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Number Of Retry
                              </div>
                              <div class="col-sm-12  mb-2">{{numberOfRetry}}</div>
                            </li>

                          </ul>
                        </div>
                      </div>




                    </div>
                  </div>
                </div>
              </mat-sidenav>
            </mat-sidenav-container>
          </div>


          <br>

        </mat-tab>

      </mat-tab-group>


      <!-- *ngIf="firstFormSubmited == true" -->

    </div>

  </div>


</div>