<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
                <span *ngIf="!viewID">Add New</span>
                <span *ngIf="viewID">View</span> Employer Details
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item ">
                    <span [routerLink]="['/user-list']">List Of Employer</span>
                </li>
                <li class="breadcrumb-item active">
                    <span>
            <span *ngIf="!viewID">Add New</span>
                    <span *ngIf="viewID">View</span> Employer Details</span>
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="row">
        <div class="col-lg-3">
            <div class="card mg-b-20">
                <div class="card-body">
                    <div class="pl-0">
                        <div class="main-profile-overview">
                            <div class="main-img-user profile-user">
                                <img alt="Responsive image" src="../../assets/img/account_blue.svg" *ngIf="allRecruiterData == null || allRecruiterData == undefined">
                                <img alt="" *ngIf="allRecruiterData != null || allRecruiterData != undefined" src="{{ this.allRecruiterData }}">
                                <a href="JavaScript:void(0);" class="fas fa-camera profile-edit"></a>
                            </div>
                            <div class="justify-content-between mg-b-20 mt-2 text-center">
                                <div>
                                    <h5 class="main-profile-name"> <span [innerHtml]="companyName"></span></h5>
                                    <p class="main-profile-name-text text-muted"><span [innerHtml]="rojgarmantraId"></span></p>
                                    <p class="main-profile-name-text text-muted">Employer</p>
                                </div>
                            </div>
                            <div class="mt-2 text-center">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer p-0">
                </div>
            </div>
            <div class="card mg-b-20">
                <div class="card-body">
                    <div class="main-content-label tx-13 mg-b-25"> Social </div>
                    <div class="main-profile-social-list">
                        <div class="row mb-3">
                            <div class="col-md-2">
                                <div class="media-icon bg-info-transparent text-info">
                                    <i class="fa fa-twitter"></i>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="media-body">
                                    <span>Twitter</span>
                                    <a href="{{allRecruiterDetails?.twitter}}" target="_blank">
                                        <span *ngIf="!twitter">NA</span>
                                        <span *ngIf="twitter">{{twitter}}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="row  mb-3">
                            <div class="col-md-2">
                                <div class="media-icon bg-warning-transparent text-warning">
                                    <i class="fa fa-facebook"></i>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="media-body">
                                    <span>Facebook Id</span>
                                    <a href="{{allRecruiterDetails?.facebook}}" target="_blank">
                                        <span *ngIf="!facebook">NA</span>
                                        <span *ngIf="facebook">{{facebook}}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="row  mb-3">
                            <div class="col-md-2">
                                <div class="media-icon bg-success-transparent text-success">
                                    <i class="fa fa-instagram"></i>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="media-body">
                                    <span>Instagram</span>
                                    <a href="{{allRecruiterDetails?.instagram}}" target="_blank">
                                        <span *ngIf="!instagram">NA</span>
                                        <span *ngIf="instagram">{{instagram}}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="row  mb-3">
                            <div class="col-md-2">
                                <div class="media-icon bg-info-transparent text-info">
                                    <i class="fa fa-linkedin"></i>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="media-body">
                                    <span>Linked In</span>
                                    <a href="{{allRecruiterDetails?.linkedIn}}" target="_blank">
                                        <span *ngIf="!linkedIn">NA</span>
                                        <span *ngIf="linkedIn">{{linkedIn}}</span>
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- main-profile-social-list -->
                </div>
            </div>
        </div>

        <div class="card col-lg-9" style="margin-bottom: 19px;">
            <div class="card-body" style="padding: 0;">

                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="Company Details">
                        <div class="" style="padding: 1.25em;">
                            <div *ngIf="viewID">
                                <div class="mt-1 product-spec">
                                    <label class="main-content-label tx-13 mg-b-20">About</label>
                                    <div class="main-profile-bio mb-0">

                                        <p class="mb-0" [innerHtml]="companyDescription"><span></span>

                                        </p>
                                    </div>
                                    <br>
                                    <label class="main-content-label tx-13 mg-b-20">Company Details</label>
                                    <div class="card-body border-top">
                                        <div class="table-responsive">
                                            <table class="table row table-borderless mb-0">
                                                <tbody class="col-lg-12 col-xl-6" style="padding: 10px !important;">
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">Company Name : </span> <span [innerHtml]="companyName"></span>
                                                        </td>
                                                    </tr>
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">Website : </span>
                                                            <span *ngIf="!website">NA</span>
                                                            <span *ngIf="website">{{website}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">Address Line 1 : </span>
                                                            <br> <span *ngIf="!currentAddress">NA</span>
                                                            <span *ngIf="currentAddress">{{currentAddress}}</span>
                                                            <br>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody class="col-lg-12 col-xl-6 p-0 border-top-0" style="padding: 10px !important;">
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">GST Number : </span> <span [innerHtml]="gstNumber"></span> </td>
                                                    </tr>
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">Office Address : </span>
                                                            <br> <span *ngIf="!officeAddress">NA</span>
                                                            <span *ngIf="officeAddress">{{officeAddress}}</span>
                                                            <br>

                                                        </td>
                                                    </tr>
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">Address Line 2: </span>
                                                            <br> <span *ngIf="!registeredAddress">NA</span>
                                                            <span *ngIf="registeredAddress">{{registeredAddress}}</span>
                                                            <br>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody class="col-lg-12 col-xl-6 p-0 border-top-0" style="padding: 10px !important;">


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="card-body border-top">
                                        <label class="main-content-label tx-13 mg-b-20">Contact</label>
                                        <div class="main-profile-contact-list d-md-flex">
                                            <div class="media">
                                                <div class="media-icon bg-primary-transparent text-primary">
                                                    <i class="fa fa-phone"></i>
                                                </div>
                                                <div class="media-body">
                                                    <span>Mobile</span>
                                                    <div>
                                                        <span *ngIf="!phoneNumber">NA</span>
                                                        <span *ngIf="phoneNumber">{{phoneNumber}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="media">
                                                <div class="media-icon bg-success-transparent text-success">
                                                    <i class="fa fa-slack"></i>
                                                </div>
                                                <div class="media-body">
                                                    <span>Email Id</span>
                                                    <div>
                                                        <span *ngIf="!email">NA</span>
                                                        <span *ngIf="email">{{email}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="media">
                                                <div class="media-icon bg-info-transparent text-info">
                                                    <i class="fa fa-map-marker"></i>
                                                </div>
                                                <div class="media-body">
                                                    <span>Current Address</span>
                                                    <div>
                                                        <span *ngIf="!currentAddress">NA</span>
                                                        <span *ngIf="currentAddress">{{currentAddress}}</span>
                                                        <span>{{cityName}}, {{stateName}}</span><span></span><br>
                                                        <span>{{postalCode}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- main-profile-contact-list -->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <!-- <mat-tab label="Documents" *ngIf="viewID">
              <br>
              <form [formGroup]="dyanmicQuestionForm" >
                <div>
                  <div class="col-sm-12 main-content-label mb-3">Document Details</div>
                </div>
                <div class="c_table">
                  <div class="mat-elevation-z8">
                    <div class="d-flex table-responsive p-3">
                      <div class="mr-2">

                      </div>
                      <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">

                      </div>
                    </div>
                    <table mat-table [dataSource]="dataSource" matSort >
                      <ng-container matColumnDef="srno" >
                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                        <td mat-cell *matCellDef="let element;let j = index">
                          {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                        </td>
                      </ng-container>
                      <div>
                        <ng-container matColumnDef="document">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Image</th>
                          <td mat-cell *matCellDef="let element">
                            <img alt="Responsive image" class="img-thumbnail" style="width:100px; height:100px;" src="{{element.companyDocument}}" alt="" *ngIf="element.companyDocument.substr((element.companyDocument.lastIndexOf('.') + 1)) != 'pdf'">
                            <img alt="Responsive image" class="img-thumbnail" style="width:100px; height:100px;" src="../../assets/img/file.png" alt="" *ngIf="element.companyDocument.substr((element.companyDocument.lastIndexOf('.') + 1)) == 'pdf'">
                          </td>
                        </ng-container>
                      </div>
                      <ng-container matColumnDef="action">
                        <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                          <div class="d-flex justify-content-center">
                              <span class="btn btn-sm btn-primary rounded-lg mar15" (click)="documentpopup(element)">
                              <i class="fas fa-eye"></i>
                            </span>
                            <span  style="width: 30px;" class="btn btn-sm btn-success rounded-lg" (click)="acceptStatus(element)"><i
                              class="fas fa-check"></i></span>&nbsp;&nbsp;

                            <span  class="btn btn-sm btn-danger rounded-lg" (click)="rejectStatus(element)"><i
                              class="fas fa-times"></i></span>
                          </div>
                        </td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>

                  </div>
                </div>
                <br>
              </form>
            </mat-tab>
            <mat-tab label="Teams">
              <div class="card-body">
                <div class="row row-sm">
                  <div class="col-6 col-md-3" *ngFor="let g of teamDetails">
                    <div class="card shadow-none">
                      <div class="card-body text-center">
                        <img alt="Responsive image" class="avatar-xxl rounded-circle" src="../../assets/img/account_blue.svg" *ngIf="g.profileImage == null || g.profileImage == undefined">
                        <img alt="Responsive image" class="avatar-xxl rounded-circle" src="{{g.profileImage}}" *ngIf="g.profileImage != null || g.profileImage != undefined">
                        <h6 class="mb-0 mt-3 nkp" routerLink="/view-user/{{g.id}}">{{ g.firstName }} {{ g.lastName }} </h6> <span class="tx-13 text-muted">{{ g.jobRole }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>-->
                    <mat-tab label="Jobs">
                        <div class="jobs-dashboard">
                            <div class="">
                                <div class="">
                                    <div class="">
                                        <div class="main-content">
                                            <table mat-table [dataSource]="dataSource" matSort class="table-striped contact-table">
                                                <!-- <ng-container matColumnDef="srno" >
                          <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                          <td mat-cell *matCellDef="let element;let j = index">                         
                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                          </td>
                        </ng-container> -->
                                                <ng-container matColumnDef="jobTitle">
                                                    <th width="" mat-header-celpostedDatel *matHeaderCellDef mat-sort-header> Job Title</th>
                                                    <td mat-cell *matCellDef="let element;let j = index">
                                                        <p class="mb-0">{{element.role}}</p>
                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="category">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <p class="mb-0">{{element.category}}</p>
                                                    </td>
                                                </ng-container> -->
                                                <ng-container matColumnDef="noOfHiring">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> No Of Hiring</th>
                                                    <td mat-cell *matCellDef="let element">{{element.noOfHiring }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="postedDate">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Posted Date</th>
                                                    <td mat-cell *matCellDef="let element">{{element.dateJobPosted | date:'dd-MM-yyyy'}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="jobEndDate">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>job End Date</th>
                                                    <td mat-cell *matCellDef="let element">{{element.jobEndDate | date:'dd-MM-yyyy'}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="noOfResponse">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> No Of Response</th>
                                                    <td mat-cell *matCellDef="let element">{{element.response}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="status">
                                                    <th width="" mat-header-cell *matHeaderCellDef>Status</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <span style="color: #fcb32d;" *ngIf="element.status == 'Pending'">{{element.status }}</span>
                                                        <span style="color: green;" *ngIf="element.status =='Active' ">{{element.status }}</span>
                                                        <span style="color: red;" *ngIf="element.status =='Expired' ">{{element.status }}</span>
                                                        <span style="color: red;" *ngIf="element.status =='Rejected' ">{{element.status }}</span>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="action">
                                                    <th width="" mat-header-cell *matHeaderCellDef>View Job </th>
                                                    <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                                                        <div class="d-flex justify-content-center">
                                                            <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/view-job-details/', viewID,element.jobId]">
                                                          <img src="../../../../assets/img/eye.svg" alt="eye">
                                                        </span>
                                                        </div>

                                                    </td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                            </table>
                                            <!-- <mat-paginator  #paginatorRef [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40,50,100,200,300,400]" showFirstLastButtons></mat-paginator> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Team Members">
                        <div class="jobs-dashboard">
                            <div class="">
                                <div class="">
                                    <div class="">
                                        <div class="main-content">
                                            <table mat-table [dataSource]="dataSource1" matSort class="table-striped contact-table">
                                                <!-- <ng-container matColumnDef="srno">
                          <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                          <td mat-cell *matCellDef="let element;let j = index">
                              {{ (paginatorRef1.pageIndex * paginatorRef1.pageSize) + (j + 1) }}
                          </td>
                      </ng-container> -->
                                                <ng-container matColumnDef="name">
                                                    <th width="" mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                                                    <td mat-cell *matCellDef="let element;let j = index">
                                                        <p class="mb-0">{{element.employerName}}</p>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="gender">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <p class="mb-0">{{element.gender}}</p>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="mobileNo">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile No</th>
                                                    <td mat-cell *matCellDef="let element">{{element.mobileNumber}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="location">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
                                                    <td mat-cell *matCellDef="let element">{{element.city}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="designation">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Designation</th>
                                                    <td mat-cell *matCellDef="let element">{{element.designationName}}</td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                                            </table>
                                            <!-- <mat-paginator  #paginatorRef1 [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40,50,100,200,300,400]" showFirstLastButtons></mat-paginator> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Subscription Details">
                        <div class="jobs-dashboard">
                            <div class="">
                                <div class="">
                                    <div class="">
                                        <div class="main-content">
                                            <table mat-table [dataSource]="dataSource2" matSort class="table-striped contact-table">
                                                <ng-container matColumnDef="srno">
                                                    <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                                                    <td mat-cell *matCellDef="let element;let j = index">
                                                        {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="planName">
                                                    <th width="" mat-header-cell *matHeaderCellDef mat-sort-header>Plan Name</th>
                                                    <td mat-cell *matCellDef="let element;let j = index">
                                                        <p class="mb-0">{{element.planName}}</p>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="planStartDate">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Start Date</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <p class="mb-0">{{element.startDate | date:'dd-MM-yyyy'}}</p>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="expiryDate">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <p class="mb-0">{{element.expiryDate | date:'dd-MM-yyyy'}}</p>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="subscriptionStatus">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Subscription Status</th>
                                                    <td mat-cell *matCellDef="let element">{{element.subscriptionStatus}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="paymentStatus">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Status</th>
                                                    <td mat-cell *matCellDef="let element">{{element.paymentStatus }} </td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                                            </table>
                                            <mat-paginator #paginatorRef [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40,50,100,200,300,400,500]" showFirstLastButtons>
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Statistics">
                        <div class="jobs-dashboard">
                            <div class="">
                                <div class="">
                                    <div class="">
                                        <div class="main-content">
                                            <div class="d-flex justify-content-end mb-4">
                                                <!-- <button class="btn btn-primary" style="color: #fff; border-radius: 5px;" (click)="downloadPDF()">Download Report</button> -->
                                            </div>
                                            <div #content class="content">
                                                <table mat-table [dataSource]="dataSource3" matSort class="table-striped contact-table">
                                                    <ng-container matColumnDef="srno">
                                                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                                                        <td mat-cell *matCellDef="let element;let j = index">
                                                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="month">
                                                        <th width="" mat-header-cell *matHeaderCellDef mat-sort-header>Month</th>
                                                        <td mat-cell *matCellDef="let element;let j = index">
                                                            <p class="mb-0">{{element.month}}</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="jobPosting">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Posting</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p class="mb-0">{{element.postedJobs}}/{{element.jobsLimit}}</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="noOfLogin">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>No Of Login</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p class="mb-0">{{element.loginMembers}}/{{element.loginLimit}}</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="sms">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>SMS</th>
                                                        <td mat-cell *matCellDef="let element">{{element.usedSMS}}/{{element.smsLimit}} </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="bulkEmail">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bulk Email</th>
                                                        <td mat-cell *matCellDef="let element">{{element.usedEmail}}/{{element.emailLimit }}</td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="databaseAccess">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Database Access</th>
                                                        <td mat-cell *matCellDef="let element">{{element.resumesViewed}}</td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

                                                </table>
                                                <mat-paginator #paginatorRef [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40,50,100,200,300,400]" showFirstLastButtons></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <!-- *ngIf="firstFormSubmited == true" -->
            </div>

        </div>
    </div>


</div>