import { B } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberServiceService {


  constructor(private http: HttpClient, private router: Router,) { }
  getallMemeberShipdetails(): Observable<any> {
    return this.http.get<any>(`${environment.baseurl}/getAllMemberships`).pipe(map((data: any) => {
      if (data.status == 0) {
        console.log(data)
        for (let b of data.response) {
          b.membershipBenefits.sort((a, b) => { return a.membershipBenefit.sequenceNo - b.membershipBenefit.sequenceNo });
        }
      }
      return data;
    }));
  }
  MemberShipLableName(): Observable<any> {
    return this.http.get<any>(`${environment.baseurl}/getAllMembershipBenefits`).pipe(map((data: any) => {
      if (data.status == 0) {
        const filterlist = data.response.sort((a, b) => { return a.sequenceNo - b.sequenceNo; })
        return { value: filterlist };
      } else {
        return { value: 'error' };
      }
    }));
  }
  getPlanDetailsById(Id) {
    return this.http.get<any>(`${environment.baseurl}/getMembership/` + Id).pipe(map((data: any) => {
      if (data.status == 0) {
        console.log(data)
       
          data.response.membershipBenefits.sort((a, b) => { return a.membershipBenefit.sequenceNo - b.membershipBenefit.sequenceNo });
        
      }
      return data;
    }));
  }
  savePlan(data) {
    return this.http.post<any>(`${environment.baseurl}/saveMembershipBenefitValue`, data);
  }

  // DISCOUNT/COUPON API START
  discountList(page, size) {
    return this.http.get<any>(`${environment.baseurl}/getAllCoupons?page=` + page + '&size=' + size);
  }
  deleteDiscount(couponId) {
    return this.http.delete<any>(`${environment.baseurl}/deleteCoupon/` + couponId)
  }
  stausChangeDiscount(couponId) {
    return this.http.put<any>(`${environment.baseurl}/changeStatus/` + couponId, '');
  }
  addCoupon(data) {
    return this.http.post<any>(`${environment.baseurl}/addCoupon`, data);
  }
  editCoupon(data) {
    return this.http.put<any>(`${environment.baseurl}/editCoupon`, data);
  }
  getCouponById(couponId) {
    return this.http.get<any>(`${environment.baseurl}/getCoupon/` + couponId);
  }
  getCompanyList(page, size) {
    return this.http.get<any>(`${environment.baseurl}/getCompanyList?page=` + page + '&size=' + size);
  }

  // DISCOUNT?COUPON API END


  // VOUCHER START
  voucherList(page, size) {
    return this.http.get<any>(`${environment.baseurl}/getAllVouchers?page=` + page + '&size=' + size);
  }
  deleteVoucher(voucherId) {
    return this.http.delete<any>(`${environment.baseurl}/deleteVoucher/` + voucherId)
  }
  stausChangeVoucher(voucherId) {
    return this.http.put<any>(`${environment.baseurl}/changeVoucherStatus/` + voucherId, '');
  }
  addVoucher(data) {
    return this.http.post<any>(`${environment.baseurl}/addVoucher`, data);
  }
  editVoucher(data) {
    return this.http.put<any>(`${environment.baseurl}/editVoucher`, data);
  }
  getVoucherById(voucherId) {
    return this.http.get<any>(`${environment.baseurl}/getVoucher/` + voucherId);
  }

  // VOUCHER END

}
