<div class="c_table" *ngIf="!cvScoreView">
    <div class="mat-elevation-z8">
        <div class="d-flex table-responsive p-3">
            <div class="mr-2">

            </div>
            <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
            </div>
        </div>
        <!-- <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="srno" >
          <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
          <td mat-cell *matCellDef="let element;let j = index">
            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
          </td>
        </ng-container>

        <div>
          <ng-container matColumnDef="cvName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>cv Name</th>
            <td mat-cell *matCellDef="let element">{{element.cvName}}</td>
          </ng-container>



          
        </div>

        <ng-container matColumnDef="actions">
          <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
            <div class="d-flex justify-content-center">
                <span class="btn btn-sm btn-primary rounded-lg mar15" matTooltip="Download CV" (click)="downloadCV(element.id)" matTooltipPosition="above">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </span>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator> -->
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Sr No</th>
                    <th scope="col">CV Name</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Video Resume</td>
                    <td>
                        <!-- <span>NA</span> -->
                        <span class="btn btn-sm btn-primary rounded-lg mar15" (click)="VideoCV()">
<i class="fas fa-eye"></i></span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Auto Generate Resume</td>
                    <td>
                        <!-- <span *ngIf="systemGeneratedResume == null">NA</span> -->
                        <span class="btn btn-sm btn-primary rounded-lg mar15" (click)="systemCV()">
<i class="fas fa-eye"></i></span>
                    </td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Uploaded Resume</td>
                    <td>


                        <!-- <span *ngIf="fileResume == null"> NA</span> -->

                        <span class="btn btn-sm btn-primary rounded-lg mar15" (click)="downloadCV()">
<i class="fa fa-download" aria-hidden="true"></i></span>


                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- CV Score -->
<app-candidate-cv-score-view *ngIf="cvScoreView"></app-candidate-cv-score-view>
<div *ngIf="cvScoreView" class="c-btn">
    <button class="btn btn-primary btn-sm rounded-lg" (click)="cvScoreView=false;">Cancel</button>
</div>