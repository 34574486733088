<!-- <div class="container">
    <div class="col-sm-12">
        <div class="">
            <div class="white-bg">
                <div class="mb-5 text-center">
                    <div class="row mb-4">
                        <div class="col-md-8 mx-auto">
                            <h2 class="text2 text-color text-center text-uppercase mb-5">Video Resume</h2>
                            <video [src]="uploadCvUrl" width="100%" height="205" autoplay controls></video>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button type="submit" class="btn button-2 ms-3" [routerLink]="['/candidate/cv']"> Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="container">
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
                <span>View</span> Video Resume CV</h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item ">
                    <span [routerLink]="['/view-candidate/',candidateId]">Candidate Details</span>
                </li>
                <li class="breadcrumb-item active">
                    <span>
            <span>View</span> Candidate Video Resume</span>
                </li>
            </ol>
        </div>
    </div>
    <div class="">
        <div class="white-bg">
            <div class="mb-5 text-center">
                <div class="row mb-4">
                    <div class="col-md-8 mx-auto">
                        <h2 class="text2 text-color text-center text-uppercase mt-3 mb-3">Video Resume</h2>
                        <video [src]="uploadCvUrl" width="100%" height="205" autoplay controls></video>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center mb-3">
                        <button type="submit" class="btn btn-primary btn-sm rounded-lg fs-13" [routerLink]="['/view-candidate/', candidateId]"> Back To Candidate List</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>