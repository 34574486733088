import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-list-all-zone-data',
  templateUrl: './list-all-zone-data.component.html',
  styleUrls: ['./list-all-zone-data.component.scss']
})
export class ListAllZoneDataComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject;
  constructor(
    private fb: FormBuilder,
    private masterDataService:MasterDataService,) { }

  stateId: any;
  formGroup: FormGroup;
dataSource = new MatTableDataSource;
  page: number;
  size: number;
  displayedColumns: string[];
  private data: any;
  @ViewChild(MatSort, {static:true}) sort:MatSort;
  @ViewChild(MatPaginator, {static:true}) paginator:MatPaginator;


  ngOnInit(): void {
    this.page = 0;
    this.size = 500; 
    this.displayedColumns = ['srno','zoneName','countryName','actions'];
    this.tableLoad(this.page);
  }

  //Add by vivek
  //To initialize Form
ngOnDestroy(): void {
  this.destroy$.next();
  this.destroy$.complete();
}

tableLoad(page){
  this.masterDataService.getAllZone().pipe(takeUntil(this.destroy$)).subscribe(res =>{
    this.data = res;
    if(this.data.status == 0 ){
      this.dataSource = new MatTableDataSource(this.data.response);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    }
    
  })
}

 //To Submit Form Data
 applyFilter(filterValue:String){
  this.dataSource.filter = filterValue.trim().toLowerCase();
   }

   firstSpaceRemoveValid(e){
     if(e.which === 32 && e.target.selectionStart === 0)
       return false;
   }


}
