<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Manage Contact List</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active" [routerLink]="['/editTeam']">Manage Contact List</li>
        </ol>
    </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
    <div class="card">
        <div class="c_table">
            <div class="mat-elevation-z8">
                <div class="d-flex table-responsive p-3">
                    <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                    </div>
                </div>
                <table mat-table [dataSource]="dataSource" matSort class="table-striped contact-table">
                    <ng-container matColumnDef="srno">
                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                        <td mat-cell *matCellDef="let element;let j = index">
                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                        </td>
                    </ng-container>

                    <div>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.userRoleType === true">EM</span>
                                <span *ngIf="element.userRoleType === false">CN</span>
                                <span *ngIf="element.userRoleType === null">WB</span>
                            </td>

                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                            <td mat-cell *matCellDef="let element">{{element.name }} </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                            <td mat-cell *matCellDef="let element">{{element.email}}</td>
                        </ng-container>


                        <ng-container matColumnDef="mobileNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number</th>
                            <td mat-cell *matCellDef="let element">{{element.mobileNumber}}</td>
                        </ng-container>

                        <ng-container matColumnDef="requestType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Request Type</th>
                            <td mat-cell *matCellDef="let element">
                                <p class="mb-0">{{element.requestType}}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="source">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Source</th>
                            <td mat-cell *matCellDef="let element">
                                <p class="mb-0">{{element.source}}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
                            <td mat-cell *matCellDef="let element">{{element.submittedDate | date:'dd-MM-yyyy'}}</td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="roleId">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <span>roleId</span>
  
                              </th>
                              <td mat-cell *matCellDef="let element">{{element.roleId}}</td>
                            </ng-container> -->
                    </div>
                    <ng-container matColumnDef="isActive">
                        <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            <span>
                                <mat-slide-toggle (change)="oldpermissionChange($event,element)" name="isActive"
                                [checked]="element.isActive"></mat-slide-toggle>
                              </span>

                        </td>
                    </ng-container>



                    <ng-container matColumnDef="actions">
                        <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                            <div class="d-flex justify-content-center">
                                <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/contactViewList', element.requestId]">
                                <img src="../../../../assets/img/eye.svg" alt="eye">
                              </span>
                                <span class="btn btn-sm btn-danger rounded-lg" (click)="deleteContact(element.requestId)"><i
                                  class="fas fa-trash"></i></span>
                            </div>


                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>





            </div>


        </div>
    </div>
</div>