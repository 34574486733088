import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {JobTestQuestionManageService} from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {DomSanitizer} from "@angular/platform-browser";


export interface DialogData {
  TestId: any;
  lang:any;
  title:any;
  jobCategory:any;
}

@Component({
  selector: 'app-document-popup',
  templateUrl: './document-popup.component.html',
  styleUrls: ['./document-popup.component.scss']
})
export class DocumentPopupComponent implements OnInit {

  selectedAreas: any[] = [];
  jobCatList: any[] = [];
  qType: any;
  page: number = 0;
  size: number = 1000;
  mainJobCat = [];
  public pendingStatus: any;
  public  approvedStatus: any;
  public rejectedStatus: any;
  dataSource = new MatTableDataSource<any>();
  showingIframData: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private masterDataservice: MasterDataService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer: DomSanitizer,
              private jobpostQuestionTestService : JobTestQuestionManageService,
              public dialogRef: MatDialogRef<DocumentPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.questionTypeList();
    console.log(this.data);
  }

  questionTypeList(): any{
    this.masterDataservice.getAllCategoryList(this.page, this.size, this.data.lang , true).subscribe(res => {
      this.qType = res;
      if ( this.qType.status == 0 ){
        this.jobCatList = this.qType.response.dataSet.filter((d) => {
          // && d.isTestCreated == false
          if (d.isSubCategory == true ) {
            return d;
          }
        });
        this.mainJobCat = this.jobCatList;
        this.selectedAreas = this.jobCatList;
        console.log(this.jobCatList);
      }
    });
  }
  search(query: string): any{
    console.log('query', query);
    let result = this.select(query);
    if ( query == '' ){
      this.jobCatList = this.selectedAreas;
    } else {
      if (result.length == 0){
        this.jobCatList = this.mainJobCat;
      } else {
        this.jobCatList = result;
      }

    }
    console.log(this.jobCatList);
  }
  select(query: string): string[]{
    let result: string[] = [];
    for (let a of this.selectedAreas){
      if (a.displayName.toLowerCase().indexOf(query) > -1){
        result.push(a)
      }
    }

    console.log(result);
    return result
  }

  getPoaDocumentByCompanyIdData(companyId){
    this.masterDataservice.getPoaDocumentByCompanyId(companyId).subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.dataSource = new MatTableDataSource(this.data.response);
      }
    })

  }

  rejectStatus(id,companyId) {
    if (confirm('Are you sure document reject ?')) {
      this.masterDataservice.documentStatusChangedById(id,'rejected').subscribe( res =>{
        this.data =res;
        if(this.data.status == 0 ){
          this.getPoaDocumentByCompanyIdData(companyId);
          this.dialogRef.close();
          this.snackBar.open('Document Rejected Successfully', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        }
        else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      })
    }
    else
    {

    }
  }

  acceptStatus(id,companyId) {
    if (confirm('Are you sure document approved ?')) {
      this.masterDataservice.documentStatusChangedById(id, 'approved').subscribe(res => {
        this.data = res;
        if (this.data.status == 0) {
          this.getPoaDocumentByCompanyIdData(companyId);
          this.dialogRef.close();
          this.snackBar.open('Document Approved Successfully', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      })
    }
    else
    {

    }
  }

  cleanURL(cvUploadUrl) {
    const dddd = 'https://docs.google.com/viewerng/viewer?url=' + cvUploadUrl + '&embedded=true';
    return this.sanitizer.bypassSecurityTrustResourceUrl(dddd);
  }

}
