import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import { LanguageService } from 'src/app/service/language/language.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import {CommonConfirmPopupComponent} from 'src/app/component/common-confirm-popup/common-confirm-popup.component';

@Component({
  selector: 'app-list-all-employment-type',
  templateUrl: './list-all-employment-type.component.html',
  styleUrls: ['./list-all-employment-type.component.scss']
})
export class ListAllEmploymentTypeComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  page : number;
  size: number;
  langList =[];
  selectLang: any;
  searchcatogry: FormGroup;
  totalpageLenght: number;
  totalrecordLength: number;
  private data: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
  constructor(private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public langService :LanguageService,
    private masterDataservice :MasterDataService,
    public dialog: MatDialog)
{ }

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.langList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.langList[0].languageCode;
    this.displayedColumns = ['srno', 'employementType', 'isActive', 'actions'];
    this.tabelLoad(this.page,this.selectLang);
  }
  tabelLoad(page , selectLang){
    this.masterDataservice.getAllEmploymentTypeList(page, this.size, selectLang).subscribe( res =>{
      this.data =res;
      if  (this.data.status == 0 ){
        this.totalrecordLength = 0;
        this.page = 0;
        this.totalpageLenght = 1;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;  
      } else {
        this.snackBar.open('server status -1', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    },(error)=>{
      this.snackBar.open(error.error.message, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    })
  }
  LangChange(e){
    this.selectLang= e.value;
    this.tabelLoad(this.page, this.selectLang);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  delete(id) {
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: "Are you sure wants to delete?",
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.masterDataservice.deleteEmploymentTypeById(id).subscribe( res =>{
          this.data = res;
          if(this.data.status == 0 ){
            this.snackBar.open('Deleted Successfully !', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.tabelLoad(this.page, this.selectLang);
          }
        })
      }
    });
  }
  changeStatus(e,element):any{
    var msg;
    if(element.isActive){
      msg = 'Are you sure wants to Deactivate status?'
    } else{
      msg = 'Are you sure wants to Activate status?'
    }
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: msg,
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.masterDataservice.updateEmploymentTypeStatus(element.employmentTypeId).subscribe( res =>{
          this.data = res;
          if(this.data.status == 0 ){
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.tabelLoad(this.page, this.selectLang);
          }
        })
      } else{
        e.source.checked= element.isActive;
      }
    });
  }
}
