import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from "../../service/language/language.service";
import {MasterDataService} from "../../service/masterData/master-data.service";
import {DynamicLabelClass} from "../../helper/userLevelLink";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {J} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;

  viewID: any;
  fileData: File = null;
  testImgURL: any;
  questionImage: any;
  testImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList: Array<any> = [];
  page: number = 0;
  size: number = 500;
  qustGropList: Array<any> = [];
  qustGropList1: Array<any> = [];
  qGroup: any;
  qustTypeList: Array<any> = [];
  qustTypeList1: Array<any> = [];
  qType: any;
  arrayOptions: Array<any> = [];
  oprincustome: Array<any> = [];
  resultKewords: Array<any> = [];
  pdata: any;
  seletedOption: number = 0;
  myModel: any;
  config: any;
  addnewOption: number = 2;
  submitted = false;
  addBtnHide: boolean = false;
  selectedAreas: Array<any> = [];
  CorrecTanswerNotSlect = false;
  CorrectAnserArray = [false, false, false, false, false];
  typeSelect: boolean;
  lang: any;
  keywaordList: any;
  keywaordList1: any;
  allKeywords: any;
  allComplete: boolean = false;
  menus: any;
  mrunal = [];
  keyWordListShow = false;
  capDivVal: boolean = false;
  jobDivVal: boolean = false;
  laguageName: any;
  options: any;
  map: any;
  keysOfMap: string[];
  allValues: any;
  newData: any;
  updatedData: any;
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public langService: LanguageService,
              private masterDataservice: MasterDataService,
              private router: Router,private httpService: HttpClient,) { }


  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.viewID = this.route.snapshot.params['id'];
    this.LangList = JSON.parse(this.langService.getLngList());
    //this.getAllDataFile();
    this.initializeForm();
    this.gatData();
    this.arrayOptions = [];
  }

  getAllDataFile(){
    console.log('Hello');
    this.httpService.get('./assets/i18n/en.json').subscribe(
      data => {
        this.data = data as string [];	 // FILL THE ARRAY WITH DATA.
        this.getDataNew(this.data);
      },
      (err: HttpErrorResponse) => {
        console.log (err.message);
      }
    );
  }

  getDataNew(arrya)
  {
    this.map = arrya;
    this.keysOfMap = Object.keys(arrya);
    console.log(this.keysOfMap);
    //const arrayOfArrays: any[] = [];
    // tslint:disable-next-line: forin
    /*for (const keyOfMap in keysOfMap){
      const valueMission = this.map[keysOfMap[keyOfMap]];
      for(let m of Object.values(valueMission)){
        const d = Object.values(m);
        // console.log(d[1].careAlert);
        if(d[1].careAlert == true || d[1].careAlert == 'true'){
          this.optimseCareAlertNpiList.push(Object.keys(valueMission));
          this.sumcount = this.sumcount + 1;
        }
      }*/
     /* arrayOfArrays.push({
        name: keysOfMap[keyOfMap],
        data : valueMission,
      });*/
   // }

   /* this.arrayOfArrays = arrayOfArrays;
    this.keysOfMap = keysOfMap;
    var merged = [].concat.apply([], this.optimseCareAlertNpiList);
    localStorage.setItem("optimzecareNpiList" , merged.toString());
    this.alertEmitservice.balance(this.sumcount);*/

  }


  search(query: string): any{
    const result = this.select(query.toLowerCase());
    if ( query == ''){
      this.qustGropList = this.selectedAreas;
    } else {
      this.qustGropList = result;
    }
  }
  select(query: string): string[]{
    const result: string[] = [];
    for (let a of this.selectedAreas){
      if (a.groupName.toLowerCase().indexOf(query) > -1){
        result.push(a);
      }
    }
    return result;
  }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      languageId: [ '' , Validators.required],
      keyValues: ['', Validators.required],
      options: new FormArray([]),
    });


   // this.LangListLoad(this.addnewOption);
  }
  get f() { return this.formGroup.controls; }
  get t() { return this.f.options as FormArray; }

  removeOption(i): any {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e): any {
    const numberOfTickets = e;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.fb.group({
          response: [ ''],
          valid: false,
        }));
      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }



  onSubmit(): any {
    const formData: FormData = new FormData();
    formData.append('value' ,  this.newData);
    formData.append('fileName' ,  'en');
    this.updateData(formData);
  }

  firstSpaceRemoveValid(e): any{
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }

  newOptionadd(): any{
    if (this.addnewOption < 5){
      this.addnewOption = this.addnewOption + 1;
      console.log(this.addnewOption);
      this.LangListLoad(this.addnewOption);
      this.addBtnHide = false;
    } else {
      this.addBtnHide = true;
    }
    if (this.addnewOption == 5){
      this.addBtnHide = true;
    } else {
      this.addBtnHide = false;
    }

  }

  setAll(e: any) {
    if (e.checked) {
      console.log(this.keywaordList1);
      this.resultKewords = this.mrunal;
      this.allComplete = true;
    } else {
      this.allComplete = false;
      this.resultKewords = [];
    }
    console.log(this.resultKewords);
    console.log(this.keywaordList1);
  }


  setAllMe(e, key): any {
    if (e.checked) {
      this.resultKewords.push(key);
    } else {
      console.log(key);
      console.log(this.resultKewords);
      this.resultKewords.splice(key, 1);
    }
    //this.allComplete =e.checked;
    console.log(this.keywaordList1);
    console.log(this.resultKewords);

  }

  onCheckboxChangeNew(e, i, key) {
    console.log(this.keywaordList);
    console.log(this.resultKewords);
    if (e.checked) {
      this.allComplete = false;
      this.resultKewords.push(key);
    } else {
      var index = this.resultKewords.indexOf(key);
      this.resultKewords.splice(index, 1);
    }
    if (this.keywaordList1.length == this.resultKewords.length){
      this.allComplete = true;
    } else {
      this.allComplete = false;
    }

    console.log(this.resultKewords);
  }

  onLanguageChange(value) {
    this.laguageName = value.languageCode;
  }

  onKeyChange(value) {
    this.allValues = [];
    this.allValues = this.data[value];
    console.log(this.data[value]);
    /*const newLeng = Object.entries(this.data[value]).length;
    this.LangListLoad(newLeng);
    console.log(newLeng);
    console.log(this.data[value]);*/
  }

  valueUpdate(e,i, key):any{
    console.log(e.target.value);
    const d = e.target.value
   console.log(i);
    console.log(this.allValues[key] = d)
    console.log(this.data[key]);
    //this.newData = (this.data[key] = this.allValues);
    console.log(this.data[key] = this.allValues);
    this.newData = JSON.stringify(this.data);
    const formData: FormData = new FormData();
    formData.append('value' ,  this.newData);
    formData.append('fileName' ,  'en');
    this.updateData(formData);
  }

  updateData(formdata)
  {
    this.masterDataservice.updateJsonValues(formdata).subscribe((data: any) => {
        this.updatedData =    data.response;
        console.log(this.updatedData);
        if(data.status === 0)
        {
          this.snackBar.open('Json update Successfully.', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });

        }
        else {
          this.snackBar.open(data.response , '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }

      },
      (err) => {
        this.snackBar.open(err.error.message, '', {
          duration: 3000,
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
          panelClass: ['error-snackbar']
        });
      }
    );
  }

  gatData()
  {
    this.masterDataservice.getStringJson().subscribe((data: any) => {
        //this.updatedData = data.response;
        this.data = JSON.parse(data.response) as string [];	 // FILL THE ARRAY WITH DATA.
        this.getDataNew(this.data);
        console.log(this.data);
      },
      (err) => {
        this.snackBar.open(err.error.message, '', {
          duration: 3000,
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
          panelClass: ['error-snackbar']
        });
      }
    );
  }


}
