import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-add-language',
  templateUrl: './add-language.component.html',
  styleUrls: ['./add-language.component.scss']
})
export class AddLanguageComponent implements OnInit {


  id: any;

  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private langService :LanguageService,
              private route: ActivatedRoute,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;


  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    this.initializeForm();
  }


  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      languageCode: [ '' , Validators.required],
      languageName: [ '' , Validators.required],
    });

    if (this.id){
      // this.patchIndustryData();
    }
  }


  onSubmit() {
    if (this.formGroup.valid) {

        this.langService.AddLang(this.formGroup.value).subscribe( (data: any) => {
          if(data.status == 0)
          {
            this.snackBar.open('Language Inserted Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listLang']);
          }
          else{
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });


    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }

  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }
}
