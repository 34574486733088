import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MasterDataService } from '../../../service/masterData/master-data.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonConfirmPopupComponent } from 'src/app/component/common-confirm-popup/common-confirm-popup.component';

@Component({
  selector: 'app-job-approoval-list',
  templateUrl: './job-approoval-list.component.html',
  styleUrls: ['./job-approoval-list.component.scss'],
})
export class JobApproovalListComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList = [];
  prdlist = [];
  private data: any;
  seachcatogry: FormGroup;
  searchForm: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  stateList: any = [];
  stateListClone: any = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ids:any = [];
  jobList:any=[];

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private masterDataservice: MasterDataService
  ) {
    this.appCtrl = new FormControl();
  }
  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.displayedColumns = [
      'srno',
      'role',
      'jobType',
      'noOfHiring',
      'dateJobPosted',
      'response',
      'status',
      'action',
    ];
    this.tabelLoad();
  }

  tabelLoad(): any {
    this.masterDataservice.getAllJobApproval().subscribe((res) => {
      this.data = res;
      if (this.data.status === 0) {
        this.jobList = this.data.response;
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.number;
        this.totalpageLenght = this.data.response.totalPages;
        this.dataSource = new MatTableDataSource(this.data.response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  applyFilter(filterValue: string): any {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterApps(name: any): any {
    const filterValue = name.toLowerCase();
    if (name === '') {
      return this.apps;
    } else {
      return this.apps.filter((p) =>
        p.name.toLowerCase().includes(filterValue)
      );
    }
  }
  onEnter(e: any): any {
    this.selectproduct = e.name;
    this.selectedId = e.id;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search(): any {
    this.router.navigate(['editTeam', this.selectedId]);
  }
  keyDownFunction(event): any {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage(): any {
    this.tabelLoad();
  }
  lastpage(): any {
    this.tabelLoad();
  }
  prevpage(): any {
    const d = -1;
    if (d > 0) {
      this.tabelLoad();
    }
  }
  nextpage(): any {
    const d = +1;
    if (d < this.totalpageLenght) {
      this.tabelLoad();
    }
  }

  ngAfterViewInit(): any {
    this.dataSource.sort = this.sort;
  }
  
  // newcheckbox(e):any{
  //   const selectedva = e.source.value;
  //   if (e.checked) {
  //   }
  // }

  postAllJobs(): any {
    this.ids=[];
    this.jobList.forEach((obj:any)=> {
      this.ids.push(obj.jobId);
    });
    this.masterDataservice.postAllApprovalJobs((this.ids).toString()).subscribe((res) => {
      this.data = res;
      if (this.data.status === 0) {
        this.tabelLoad();
      }
    });
  }
}
