import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatChipsModule} from '@angular/material/chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { HomeComponent } from './component/homePage/home/home.component';
import { HeaderComponent } from './component/shared/header/header.component';
import { FooterComponent } from './component/shared/footer/footer.component';
import { SidebarComponent } from './component/shared/sidebar/sidebar.component';
import { AddUpdateProfileComponent } from './component/profile/add-update-profile/add-update-profile.component';
import { ChangePasswordComponent } from './component/profile/change-password/change-password.component';
import { TeamListComponent } from './component/manageTeam/team-list/team-list.component';
import { AddUpdateTeamComponent } from './component/manageTeam/add-update-team/add-update-team.component';
import { RoleListComponent } from './component/manageSystem/ManageRoleAccess/role-list/role-list.component';
import { AddUpdateRoleComponent } from './component/manageSystem/ManageRoleAccess/add-update-role/add-update-role.component';
import { LoginComponent } from './component/login/login.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { MultilevelMenuService, NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { RouterModule } from '@angular/router';
import { ListAllJobCatogeryComponent } from './component/manageMasterData/jobCategory/list-all-job-catogery/list-all-job-catogery.component';
import { AddUpdateJobCategoryComponent } from './component/manageMasterData/jobCategory/add-update-job-category/add-update-job-category.component';
import { AddUpdateIndustryComponent } from './component/manageMasterData/Industry/add-update-industry/add-update-industry.component';
import { ListAllIndustryComponent } from './component/manageMasterData/Industry/list-all-industry/list-all-industry.component';
import { ListAllCountryDataComponent } from './component/manageMasterData/countryData/list-all-country-data/list-all-country-data.component';
import { AddUpdateCountryDataComponent } from './component/manageMasterData/countryData/add-update-country-data/add-update-country-data.component';
import { ListAllSkillSetComponent } from './component/manageMasterData/skillSet/list-all-skill-set/list-all-skill-set.component';
import { AddUpdateSkillSetComponent } from './component/manageMasterData/skillSet/add-update-skill-set/add-update-skill-set.component';
import { ListPTCategoryComponent } from './component/PersonalityTest/personalityTestcatogery/list-ptcategory/list-ptcategory.component';
import { AddUpdatePTCategoryComponent } from './component/PersonalityTest/personalityTestcatogery/add-update-ptcategory/add-update-ptcategory.component';
import { MangeQuestionComponent } from './component/PersonalityTest/mange-question/mange-question.component';
import {AddUpdateJobSubcategoryComponent} from "./component/manageMasterData/jobSubCategory/add-update-job-subcategory/add-update-job-subcategory.component";
import {ListAllJobSubcategoryComponent} from "./component/manageMasterData/jobSubCategory/list-all-job-subcategory/list-all-job-subcategory.component";
import {AddUpdateQuestionComponent} from "./component/PersonalityTest/add-update-question/add-update-question.component";
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { AddOrUpdateCometencyComponent } from './component/manageMasterData/competencyTestCategory/add-or-update-cometency/add-or-update-cometency.component';
import { ListCompetencyCategoryComponent } from './component/manageMasterData/competencyTestCategory/list-competency-category/list-competency-category.component';
import { AddLanguageComponent } from './component/manageLanguage/add-language/add-language.component';
import { ListLanguageComponent } from './component/manageLanguage/list-language/list-language.component';
import { ListCompetencyKeywordComponent } from './component/manageMasterData/competencyKeywords/list-competency-keyword/list-competency-keyword.component';
import { AddUpdateCompetencyKeywordComponent } from './component/manageMasterData/competencyKeywords/add-update-competency-keyword/add-update-competency-keyword.component';
import { AddUpdateRestrictedKeywordComponent } from './component/manageMasterData/restrictedKeywords/add-update-restricted-keyword/add-update-restricted-keyword.component';
import { ListRestrictedKeywordComponent } from './component/manageMasterData/restrictedKeywords/list-restricted-keyword/list-restricted-keyword.component';
import { ViewCompetencyKeywordComponent } from './component/manageMasterData/competencyKeywords/view-competency-keyword/view-competency-keyword.component';
import { ListQuestionTypeComponent } from './component/manageMasterData/questionType/list-question-type/list-question-type.component';
import { AddUpdateQuestionTypeComponent } from './component/manageMasterData/questionType/add-update-question-type/add-update-question-type.component';
import { AddUpdateQuestionLevelComponent } from './component/manageMasterData/questionLevel/add-update-question-level/add-update-question-level.component';
import { ListQuestionLevelComponent } from './component/manageMasterData/questionLevel/list-question-level/list-question-level.component';
import { CommonAlertPopupComponent } from './component/common-alert-popup/common-alert-popup.component';
import { CommonConfirmPopupComponent } from './component/common-confirm-popup/common-confirm-popup.component';
import { AddupdateJobPostQuestionComponent } from './component/managejobPostQuestion/addupdate-job-post-question/addupdate-job-post-question.component';
import { ListJobPostQuestionComponent } from './component/managejobPostQuestion/list-job-post-question/list-job-post-question.component';
import { ListJobPostTestComponent } from './component/managejobPostQuestion/list-job-post-test/list-job-post-test.component';
import { AddUpdateJobTestCreationComponent } from './component/managejobPostQuestion/add-update-job-test-creation/add-update-job-test-creation.component';
import {JwttokenInterceptor} from '../app/helper/jwtToken/jwttoken.interceptor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TestCreationPipeLineComponent } from './component/managejobPostQuestion/test-creation-pipe-line/test-creation-pipe-line.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CopyButtonPopupComponent } from './component/managejobPostQuestion/copy-button-popup/copy-button-popup.component';
import { AddUpdateSubComprehnsiveComponent } from './component/managejobPostQuestion/add-update-sub-comprehnsive/add-update-sub-comprehnsive.component';
import { ListInterviewQuestionComponent } from './component/managejobPostQuestion/list-interview-question/list-interview-question.component';
import { AddUpdateInterviewQuestionComponent } from './component/managejobPostQuestion/add-update-interview-question/add-update-interview-question.component';
import { PublishTestViewComponent } from './component/managejobPostQuestion/publish-test-view/publish-test-view.component';
import { ImageQuestionPopupComponent } from './component/managejobPostQuestion/image-question-popup/image-question-popup.component';
import { ListPersonalityCategoryValuesComponent } from './component/managePersonalityCategoryValues/list-personality-category-values/list-personality-category-values.component';
import { AddUpdatePersonalityCategoryValuesComponent } from './component/managePersonalityCategoryValues/add-update-personality-category-values/add-update-personality-category-values.component';
import { UserListComponent } from './component/manageUser/user-list/user-list.component';
import { AddUpdateUserComponent } from './component/manageUser/add-update-user/add-update-user.component';
import { DocumentPopupComponent } from './component/manageUser/document-popup/document-popup.component';
import { ListIccComponent } from './component/manageICC/list-icc/list-icc.component';
import { AddUpdateIccComponent } from './component/manageICC/add-update-icc/add-update-icc.component';
import { AddUpdateVideoInterviewQuestionComponent } from './component/managejobPostQuestion/add-update-video-interview-question/add-update-video-interview-question.component';
import { LisVideoInterviewQuestionComponent } from './component/managejobPostQuestion/lis-video-interview-question/lis-video-interview-question.component';
import { ListSettingInterviewQuestionComponent } from './component/interviewSetting/list-setting-interview-question/list-setting-interview-question.component';
import { AddUpdateSettingInterviewQuestionComponent } from './component/interviewSetting/add-update-setting-interview-question/add-update-setting-interview-question.component';
import { AddUpdateJobPlanComponent } from './component/manageJobPlans/add-update-job-plan/add-update-job-plan.component';
import { ListJobPlanComponent } from './component/manageJobPlans/list-job-plan/list-job-plan.component';
import { ListJobBoardComponent } from './component/manageJobBoard/list-job-board/list-job-board.component';
import { AddUpdateJobBoardComponent } from './component/manageJobBoard/add-update-job-board/add-update-job-board.component';
import { TranslationComponent } from './component/translation/translation.component';
import { ViewUserDetailsComponent } from './component/manageUser/view-user-details/view-user-details.component';
import { JobDetailsDetailsComponent } from './component/manageUser/job-details-details/job-details-details.component';
import { CandidateListComponent } from './component/manageCandidate/candidate-list/candidate-list.component';
import { CandidateViewComponent } from './component/manageCandidate/candidate-view/candidate-view.component';
import { CandidateCvListComponent } from './component/manageCandidate/candidate-cv-list/candidate-cv-list.component';
import { CandidateCvViewComponent } from './component/manageCandidate/candidate-cv-view/candidate-cv-view.component';
import { CandidatePersonalityTestResultComponent } from './component/manageCandidate/candidate-personality-test-result/candidate-personality-test-result.component';
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import { AddUpdateTemplateComponent } from './component/manageNotificationTemplate/add-update-template/add-update-template.component';
import { ListTemplateComponent } from './component/manageNotificationTemplate/list-template/list-template.component';
import { AddUpdateMediaComponent } from './component/manageMedia/add-update-media/add-update-media.component';
import { ListMediaComponent } from './component/manageMedia/list-media/list-media.component';
import { MembershipListComponent } from './component/memberShipDetails/membership-list/membership-list.component';
import { MembershipAddEditComponent } from './component/memberShipDetails/membership-add-edit/membership-add-edit.component';
import { ManageLanguageComponent } from './component/manageTranslation/manage-language/manage-language.component';
import { EditTranslationComponent } from './component/manageTranslation/edit-translation/edit-translation.component';
import { AddTranslationComponent } from './component/manageTranslation/add-translation/add-translation.component';
import { UploadLanguageFileComponent } from './component/manageTranslation/upload-language-file/upload-language-file.component';
import { VoucherListComponent } from './component/memberShipDetails/Voucher/voucher-list/voucher-list.component';
import { AddEditVoucherComponent } from './component/memberShipDetails/Voucher/add-edit-voucher/add-edit-voucher.component';
import { ListDiscountComponent } from './component/memberShipDetails/discount/list-discount/list-discount.component';
import { AddEditDiscountComponent } from './component/memberShipDetails/discount/add-edit-discount/add-edit-discount.component';
import { CandidateCvScoreViewComponent } from './component/manageCandidate/candidate-cv-score-view/candidate-cv-score-view.component';
import { CandidateAssessmentTestResultComponent } from './component/manageCandidate/candidate-assessment-test-result/candidate-assessment-test-result.component';
import { ListAllEmploymentTypeComponent } from './component/manageMasterData/employementType/list-all-employment-type/list-all-employment-type.component';
import { AddUpdateEmploymentTypeComponent } from './component/manageMasterData/employementType/add-update-employment-type/add-update-employment-type.component';
import { AddUpdateContractTypeComponent } from './component/manageMasterData/contractType/add-update-contract-type/add-update-contract-type.component';
import { ListAllContractTypeComponent } from './component/manageMasterData/contractType/list-all-contract-type/list-all-contract-type.component';
import { ListAllEducationLevelComponent } from './component/manageMasterData/educationLevel/list-all-education-level/list-all-education-level.component';
import { AddUpdateEducationLevelComponent } from './component/manageMasterData/educationLevel/add-update-education-level/add-update-education-level.component';
import { ListAllWorkingConditionComponent } from './component/manageMasterData/workingCondition/list-all-working-condition/list-all-working-condition.component';
import { AddUpdateWorkingConditionComponent } from './component/manageMasterData/workingCondition/add-update-working-condition/add-update-working-condition.component';
import { AddUpdatePayOfferedComponent } from './component/manageMasterData/payOffered/add-update-pay-offered/add-update-pay-offered.component';
import { ListAllPayOfferedComponent } from './component/manageMasterData/payOffered/list-all-pay-offered/list-all-pay-offered.component';
import { ListAllBenefitsComponent } from './component/manageMasterData/benefits/list-all-benefits/list-all-benefits.component';
import { AddUpdateBenefitsComponent } from './component/manageMasterData/benefits/add-update-benefits/add-update-benefits.component';
import { AddUpdatePaymentComponent } from './component/managePayment/add-update-payment/add-update-payment.component';
import { PaymentListComponent } from './component/managePayment/payment-list/payment-list.component';
import { SpinnerComponent } from './component/spinner/spinner.component';
import {CustomSpinnerInterceptor} from './helper/customSpinner/custom-spinner.interceptor';
import { NodataComponent } from './component/nodata/nodata.component';
import { InvalidControlScrollContainerDirective } from './directive/InvalidControlScroll/invalid-control-scroll-container.directive';
import { InvalidControlScrollDirective } from './directive/InvalidControlScroll/invalid-control-scroll.directive';
import { QuestionImportpopupComponent } from './component/managejobPostQuestion/question-importpopup/question-importpopup.component';
import { CategoryJobCreationReportComponent } from './component/manageMasterData/jobCategory/category-job-creation-report/category-job-creation-report.component';
import { ListJobFreeServicesComponent } from './component/manageJobPlans/manageJobFreeService/list-job-free-services/list-job-free-services.component';
import { AddEditJobFreeServicesComponent } from './component/manageJobPlans/manageJobFreeService/add-edit-job-free-services/add-edit-job-free-services.component';
import { OcenDescriptionListComponent } from './component/managePersonalityCategoryValues/ocen-description-list/ocen-description-list.component';
import { AddEditocenDescriptionComponent } from './component/managePersonalityCategoryValues/add-editocen-description/add-editocen-description.component';
import { AddEditocenCANDIDATEDescriptionComponent } from './component/managePersonalityCategoryValues/add-editocen-candidatedescription/add-editocen-candidatedescription.component';
import { CANDIDATEocenDescriptionListComponent } from './component/managePersonalityCategoryValues/candidateocen-description-list/candidateocen-description-list.component';
import { EmployerPlanListComponent } from './component/mangeMembership/EmployerPlan/employer-plan-list/employer-plan-list.component';
import { EmployerPlanAddUpdateComponent } from './component/mangeMembership/EmployerPlan/employer-plan-add-update/employer-plan-add-update.component';
import { AddUpdateCandidatePlanComponent } from './component/mangeMembership/CandidatePlan/add-update-candidate-plan/add-update-candidate-plan.component';
import { ListCandidatePlanComponent } from './component/mangeMembership/CandidatePlan/list-candidate-plan/list-candidate-plan.component';
import { AddUpdateAddonPlanComponent } from './component/mangeMembership/AddonPlan/add-update-addon-plan/add-update-addon-plan.component';
import { ListAddonPlanComponent } from './component/mangeMembership/AddonPlan/list-addon-plan/list-addon-plan.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatNativeDateModule } from '@angular/material/core';
import { ContactListComponent } from './component/ManageContact/contact-list/contact-list.component';
import { ContactViewListComponent } from './component/ManageContact/contact-view-list/contact-view-list.component';
import { AddUpdateLocationComponent } from './component/manageMasterData/manageLocation/add-update-location/add-update-location.component';
import { ListLocationComponent } from './component/manageMasterData/manageLocation/list-location/list-location.component';
import { ManageDiscountComponent } from './component/mangeMembership/Manage-Discount/manage-discount/manage-discount.component';
import { DiscountListComponent } from './component/mangeMembership/Manage-Discount/discount-list/discount-list.component';
import { RequestCvComponent } from './component/request-cv/request-cv.component';
import { ManageJobReportComponent } from './component/manage-job-report/manage-job-report.component';
import { AddUpdateEducationComponent } from './component/manageMasterData/educations/add-update-education/add-update-education.component';
import { ListAllEducationComponent } from './component/manageMasterData/educations/list-all-education/list-all-education.component';
import { AddUpdateCustomizPlanComponent } from './component/mangeMembership/custimize-plan/add-update-customiz-plan/add-update-customiz-plan.component';
import { ListCustomizPlanComponent } from './component/mangeMembership/custimize-plan/list-customiz-plan/list-customiz-plan.component';
import { AddUpdateContactComponent } from './component/manageDashboardContact/add-update-contact/add-update-contact.component';
import { ListContactdashboardComponent } from './component/manageDashboardContact/list-contactdashboard/list-contactdashboard.component';
import { AddUpdateRusumeBoostedComponent } from './component/manageRusumeBoosted/add-update-rusume-boosted/add-update-rusume-boosted.component';
import { ListRusumeBoostedComponent } from './component/manageRusumeBoosted/list-rusume-boosted/list-rusume-boosted.component';
import { SafePipe } from './component/shared/safe.pipe';
import { DateAsAgoPipe } from './component/shared/date-as-ago.pipe';
import { RecuiterMembershipComponent } from './component/manage-reports/recuiter-membership/recuiter-membership.component';
import { JobApproovalListComponent } from './component/job-approoval/job-approoval-list/job-approoval-list.component';
import { JobApproovalViewComponent } from './component/job-approoval/job-approoval-view/job-approoval-view.component';
import { CandidateMembershipComponent } from './component/manage-job-report/candidate-membership/candidate-membership.component';
import { UserPolicyComponent } from './component/user-policy/user-policy/user-policy.component';
import { AddPolicyComponent } from './component/user-policy/add-policy/add-policy.component';
import { AddUpdateBlogComponent } from './component/manageBlog/add-update-blog/add-update-blog.component';
import { BlogViewListComponent } from './component/manageBlog/blog-view-list/blog-view-list.component';
import { DailyLimitComponent } from './component/daily-limit/daily-limit.component';
import { DummyExcelSheetComponent } from './component/dummy-excel-sheet/dummy-excel-sheet.component';
import { ListNewsEventComponent } from './component/manageNewsEvent/list-news-event/list-news-event.component';
import { AddUpdateNewsEventComponent } from './component/manageNewsEvent/add-update-news-event/add-update-news-event.component';
import { RejectJobPopupComponent } from './component/job-approoval/reject-job-popup/reject-job-popup.component';
import { ListAllStateDataComponent } from './component/manageMasterData/stateData/list-all-state-data/list-all-state-data.component';
import { AddUpdateStateDataComponent } from './component/manageMasterData/stateData/add-update-state-data/add-update-state-data.component';
import { AddUpdateZoneDataComponent } from './component/manageMasterData/zoneData/add-update-zone-data/add-update-zone-data.component';
import { ListAllZoneDataComponent } from './component/manageMasterData/zoneData/list-all-zone-data/list-all-zone-data.component';
import { AddUpdateDistrictDataComponent } from './component/manageMasterData/districtData/add-update-district-data/add-update-district-data.component';
import { ListAllDistrictDataComponent } from './component/manageMasterData/districtData/list-all-district-data/list-all-district-data.component';
import { AddUpdateCityDataComponent } from './component/manageMasterData/cityData/add-update-city-data/add-update-city-data.component';
import { ListAllCityDataComponent } from './component/manageMasterData/cityData/list-all-city-data/list-all-city-data.component';
@NgModule({
  declarations: [
    AppComponent,
    DateAsAgoPipe,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AddUpdateProfileComponent,
    ChangePasswordComponent,
    TeamListComponent,
    AddUpdateTeamComponent,
    RoleListComponent,
    AddUpdateRoleComponent,
    LoginComponent,
    ListAllJobCatogeryComponent,
    AddUpdateJobCategoryComponent,
    AddUpdateIndustryComponent,
    ListAllIndustryComponent,
    ListAllCountryDataComponent,
    AddUpdateCountryDataComponent,
    ListAllSkillSetComponent,
    AddUpdateSkillSetComponent,
    ListPTCategoryComponent,
    AddUpdatePTCategoryComponent,
    MangeQuestionComponent,
    AddUpdateJobSubcategoryComponent,
    ListAllJobSubcategoryComponent,
    AddUpdateQuestionComponent,
    AddOrUpdateCometencyComponent,
    ListCompetencyCategoryComponent,
    AddLanguageComponent,
    ListLanguageComponent,
    ListCompetencyKeywordComponent,
    AddUpdateCompetencyKeywordComponent,
    AddUpdateRestrictedKeywordComponent,
    ListRestrictedKeywordComponent,
    ViewCompetencyKeywordComponent,
    ListQuestionTypeComponent,
    AddUpdateQuestionTypeComponent,
    AddUpdateQuestionLevelComponent,
    ListQuestionLevelComponent,
    CommonAlertPopupComponent,
    CommonConfirmPopupComponent,
    AddupdateJobPostQuestionComponent,
    ListJobPostQuestionComponent,
    ListJobPostTestComponent,
    AddUpdateJobTestCreationComponent,
    TestCreationPipeLineComponent,
    CopyButtonPopupComponent,
    AddUpdateSubComprehnsiveComponent,
    PublishTestViewComponent,
    ImageQuestionPopupComponent,
    ListInterviewQuestionComponent,
    AddUpdateInterviewQuestionComponent,
    ListPersonalityCategoryValuesComponent,
    AddUpdatePersonalityCategoryValuesComponent,
    UserListComponent,
    AddUpdateUserComponent,
    DocumentPopupComponent,
    ListIccComponent,
    AddUpdateIccComponent,
    AddUpdateVideoInterviewQuestionComponent,
    LisVideoInterviewQuestionComponent,
    ListSettingInterviewQuestionComponent,
    AddUpdateSettingInterviewQuestionComponent,
    AddUpdateJobPlanComponent,
    ListJobPlanComponent,
    ListJobBoardComponent,
    AddUpdateJobBoardComponent,
    TranslationComponent,
    ViewUserDetailsComponent,
    JobDetailsDetailsComponent,
    CandidateListComponent,
    CandidateViewComponent,
    CandidateCvListComponent,
    CandidateCvViewComponent,
    CandidatePersonalityTestResultComponent,
    AddUpdateTemplateComponent,
    ListTemplateComponent,
    AddUpdateMediaComponent,
    ListMediaComponent,
    MembershipListComponent,
    MembershipAddEditComponent,
    ManageLanguageComponent,
    EditTranslationComponent,
    AddTranslationComponent,
    UploadLanguageFileComponent,
    VoucherListComponent,
    AddEditVoucherComponent,
    ListDiscountComponent,
    AddEditDiscountComponent,
    CandidateCvScoreViewComponent,
    CandidateAssessmentTestResultComponent,
    ListAllEmploymentTypeComponent,
    AddUpdateEmploymentTypeComponent,
    AddUpdateContractTypeComponent,
    ListAllContractTypeComponent,
    ListAllEducationLevelComponent,
    AddUpdateEducationLevelComponent,
    ListAllWorkingConditionComponent,
    AddUpdateWorkingConditionComponent,
    AddUpdatePayOfferedComponent,
    ListAllPayOfferedComponent,
    ListAllBenefitsComponent,
    AddUpdateBenefitsComponent,
    AddUpdatePaymentComponent,
    PaymentListComponent,
    SpinnerComponent,
    NodataComponent,
    InvalidControlScrollContainerDirective,
    InvalidControlScrollDirective,
    QuestionImportpopupComponent,
    CategoryJobCreationReportComponent,
    ListJobFreeServicesComponent,
    AddEditJobFreeServicesComponent,
    OcenDescriptionListComponent,
    AddEditocenDescriptionComponent,
    AddEditocenCANDIDATEDescriptionComponent,
    CANDIDATEocenDescriptionListComponent,
    EmployerPlanListComponent,
    EmployerPlanAddUpdateComponent,
    AddUpdateCandidatePlanComponent,
    ListCandidatePlanComponent,
    AddUpdateAddonPlanComponent,
    ListAddonPlanComponent,
    ContactListComponent,
    ContactViewListComponent,
    AddUpdateLocationComponent,
    ListLocationComponent,
    ManageDiscountComponent,
    DiscountListComponent,
    RequestCvComponent,
    ManageJobReportComponent,
    AddUpdateEducationComponent,
    ListAllEducationComponent,
    AddUpdateCustomizPlanComponent,
    ListCustomizPlanComponent,
    AddUpdateContactComponent,
    ListContactdashboardComponent,
    AddUpdateRusumeBoostedComponent,
    ListRusumeBoostedComponent,
    SafePipe,
    RecuiterMembershipComponent,
    JobApproovalListComponent,
    JobApproovalViewComponent,
    CandidateMembershipComponent,
    UserPolicyComponent,
    AddPolicyComponent,
    AddUpdateBlogComponent,
    BlogViewListComponent,
    DailyLimitComponent,
    DummyExcelSheetComponent,
    ListNewsEventComponent,
    AddUpdateNewsEventComponent,
    RejectJobPopupComponent,
    ListAllStateDataComponent,
    AddUpdateStateDataComponent,
    AddUpdateZoneDataComponent,
    ListAllZoneDataComponent,
    AddUpdateDistrictDataComponent,
    ListAllDistrictDataComponent,
    AddUpdateCityDataComponent,
    ListAllCityDataComponent,
    // DateAgoPipe
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgMaterialMultilevelMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTabsModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    AngularEditorModule,
    MatTooltipModule,
    NgxSliderModule,
    NgApexchartsModule,
    MatNativeDateModule
  ],
  exports: [RouterModule],
  providers: [
    MultilevelMenuService ,
    MatDatepickerModule,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwttokenInterceptor, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: CustomSpinnerInterceptor, multi: true},
   // {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
