import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-category-job-creation-report',
  templateUrl: './category-job-creation-report.component.html',
  styleUrls: ['./category-job-creation-report.component.scss']
})
export class CategoryJobCreationReportComponent implements OnInit {

  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId:any;
  selectLang: any = 'en';
  langList= [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              public langService :LanguageService,
              private masterDataservice :MasterDataService) { this.appCtrl = new FormControl(); }

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.displayedColumns = ['jobCategoryName', 'jobSubCategoryName','icc', 'personality','testAssesement'];
    this.langList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.langList[0].languageCode;
    this.tabelLoad(this.page, this.selectLang);
  }


  tabelLoad(page ,selectlang){
    this.masterDataservice.getjobCategoryReport(page, this.size,selectlang).subscribe( (res:any) =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.totalrecordLength = 0;
        this.page = 0;
        this.totalpageLenght = 1;
        this.dataSource = new MatTableDataSource(this.data.response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } else {
        this.snackBar.open(res.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
    } 
  }, (error:any)=>{
    this.snackBar.open(error.error.message == '' ? 'server error' : error.error.message, '', {
      duration: 3000,
      panelClass: ['error-snackbar']
    });
    });


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  LangChange(e){
    console.log(e);
    this.selectLang = e.value;
    this.tabelLoad(this.page , this.selectLang)
  }


}
