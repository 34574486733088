<div class="bg-gray-200">
  <div class="modal d-block pos-static">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-header">
          <h6 class="modal-title">Document</h6>
          <button aria-label="Close" [mat-dialog-close]="false" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <img alt="Responsive image" style="text-align: center" class="img-thumbnail" src="{{data.companyDocument}}" alt="" *ngIf="data.iFrameShow == false">
          <iframe [src]="cleanURL(data.companyDocument)" width="750" height="400" *ngIf="data.iFrameShow == true"></iframe>
        </div>
        <div class="modal-footer">
          <span (click)="acceptStatus(data.id, data.companyId)" class="btn btn-success" type="button" *ngIf="data.isPending">Approved </span>
          <span (click)="rejectStatus(data.id, data.companyId)"  class="btn btn-danger" type="button" *ngIf="data.isPending">Reject</span>
          <span  class="btn btn-success" type="button" *ngIf="data.isApproved">Document Approved </span>
          <span  class="btn btn-danger" type="button" *ngIf="data.isRejected">Document Rejected</span>
          <button class="btn btn-outline-light" [mat-dialog-close]="false" type="button">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- modal-wrapper-demo -->
<!-- <div class="tx-center pd-y-20 bg-gray-200 border-top">
<a class="btn btn-primary" data-target="#modaldemo3" data-toggle="modal" href="">View Live Demo</a>
</div> -->
<!-- pd-y-30 -->
