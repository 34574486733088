<div class="left-sidenav">
    <div class="main-menu-inner ">
        <div class="menu-body side-menu slimscroll ">
            <h3>MAIN</h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='dashbaord' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>

            <h3>Manage Team </h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='manageTeam' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>

            <h3>Manage User </h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='manageUser' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>

            <!-- MEMBERSHIP START -->
            <!--  <h3>Manage Membership</h3>
      <ng-material-multilevel-menu
        [configuration]='config'
        [items]='membership'
        (selectedItem)="selectedItem($event)">
      </ng-material-multilevel-menu>-->
            <!-- MEMBERSHIP END -->
            <h3>User Request </h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='userRequest' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>


            <h3>System Setting</h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='ManageSystemSetting' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>


            <h3>Reports</h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='reports' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>
            <h3>Manage Membership</h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='ManageMembership' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>


            <h3>Master Data</h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='MangeMasterData' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu>

            <!-- <h3>Manage Translation</h3>
      <ng-material-multilevel-menu
      [configuration]='config'
      [items]='ManageTranslationData'
      (selectedItem)="selectedItem($event)">
      </ng-material-multilevel-menu>
-->
            <!-- <h3>Manage Plans</h3>
      <ng-material-multilevel-menu
        [configuration]='config'
        [items]='MangeJobPlans'
        (selectedItem)="selectedItem($event)">
      </ng-material-multilevel-menu>-->

            <!--  <h3>Personality Test</h3>
      <ng-material-multilevel-menu
      [configuration]='config'
      [items]='PersonalityTest'
      (selectedItem)="selectedItem($event)">
      </ng-material-multilevel-menu>

      <h3>Test Assesment</h3>
      <ng-material-multilevel-menu
      [configuration]='config'
      [items]='TestAssesment'
      (selectedItem)="selectedItem($event)">
      </ng-material-multilevel-menu>
-->

            <!-- <h3>Manage Interview Question</h3>
      <ng-material-multilevel-menu
        [configuration]='config'
        [items]='InterviewQuestion'
        (selectedItem)="selectedItem($event)">
      </ng-material-multilevel-menu>-->

            <!-- <h3>Manage Payment</h3>
            <ng-material-multilevel-menu [configuration]='config' [items]='payment' (selectedItem)="selectedItem($event)">
            </ng-material-multilevel-menu> -->

            <br>
            <br>
        </div>
    </div>
</div>