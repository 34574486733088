import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {map, startWith} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {Observable} from "rxjs/internal/Observable";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-list-all-job-catogery',
  templateUrl: './list-all-job-catogery.component.html',
  styleUrls: ['./list-all-job-catogery.component.scss']
})
export class ListAllJobCatogeryComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId:any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private masterDataservice :MasterDataService) { this.appCtrl = new FormControl(); }

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.displayedColumns = ['srno', 'categoryName', 'subCategoryName', 'isActive', 'actions'];
    this.tabelLoad(this.page);
  }


  tabelLoad(page){
    this.masterDataservice.getAllJobCategoryDetailsList(page, this.size,'en').subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.totalrecordLength = 0;
        this.page = 0;
        this.totalpageLenght = 1;
        this.dataSource = new MatTableDataSource(this.data.response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      //   this.apps = this.data.response.dataSet;
      //   this.filteredApps = this.appCtrl.valueChanges .pipe(
      //     startWith(''),
      //     map(app => app ? this.filterApps(app) : this.apps.slice())
      //   );
      // } else {
      //   this.snackBar.open('server status -1', '', {
      //     duration: 3000,
      //     panelClass: ['error-snackbar']
      //   });
      }
    })


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  delete(rowid: number) {
    if (rowid > -1) {
      this.dataSource.data.splice(rowid, 1);
      this.dataSource._updateChangeSubscription();
    }
  }
  oldpermissionChange (e , element) {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
        this.masterDataservice.categoryStatuChange(element.jobCategoryId).subscribe((data) => {
          // @ts-ignore
          if (data == 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(this.page);
            this.snackBar.open('Job category status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {
        this.masterDataservice.categoryStatuChange(element.jobCategoryId).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });

          } else {
            e.source.checked = true;
            this.tabelLoad(this.page);
            this.snackBar.open('Job category status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = false;
      }
    }

  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.categoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any) {
    // console.log(e);
    this.selectproduct = e.categoryName;
    this.selectedId = e.jobCategoryId;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search(){

    this.router.navigate(['editjobcategory',this.selectedId]);

  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage(){
    this.tabelLoad(0);
  }
  lastpage(){
    this.tabelLoad(this.totalpageLenght);
  }
  prevpage(){
    const d = this.page - 1;
    if (d > 0){
      this.tabelLoad(d);
    }
  }
  nextpage(){
    const d = this.page + 1;
    if ( d < this.totalpageLenght){
      this.tabelLoad(d);
    }
  }


  deleteCategoryDetails(jobCategoryId) {
    this.masterDataservice.deleteCategoryDetails(jobCategoryId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.tabelLoad(this.page);
          this.snackBar.open('Job Category deleted succesfully!!!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

}
