import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptServiceService {
  
  private key = CryptoJS.enc.Utf8.parse(environment.EncryptKey);
  private iv = CryptoJS.enc.Utf8.parse(environment.EncryptIV);


  constructor() { }

  encrypt(text){
    console.log(CryptoJS.pad);
    //var hash = CryptoJS.SHA1(this.key);
    // //var key = CryptoJS.lib.WordArray.create(hash.words.slice(0, 16 / 4));
    // const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
    //       keySize: 16,	
    //       mode:CryptoJS.mode.ECB       
    // });
    //console.log(this.key.toString(),'Encrypted :' , encrypted.toString());

    /*var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
      keySize: 128/8, 
      iv:this.iv, mode: CryptoJS.mode.ECB, padding:CryptoJS.pad.Pkcs7});
*/
 //     var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key);
      // var encrypted = CryptoJS.AES.encrypt(text, environment.EncryptKey);
      // encrypted = encrypted.toString();
      // console.log('Encrypted :' , encrypted);
      var encrypted = CryptoJS.AES.encrypt(text, environment.EncryptKey);

      return encrypted.toString();
  }
  
}
