<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!viewId">Add </span>
        <span *ngIf="viewId">Edit </span>Service Free Jobs
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listJobFreeService']">Service Free Jobs List</li>
        <li class="breadcrumb-item active"><span *ngIf="!viewId">Add </span><span *ngIf="viewId">Edit
          </span> Service Free Jobs </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="dynamicForm" appInvalidControlScroll>
        <div class="row">
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Service Type</label>
            <div class="col-sm-12 ">
              <mat-select [ngClass]="{ 'is-invalid': submitted && dynamicForm.get('serviceType').errors }"
                formControlName="serviceType" required placeholder="Please Select serviceType" class="form-control">
                <mat-option value="SOCIAL_MEDIA" aria-label="Social media">
                  Social media
                </mat-option>
                <mat-option value="JOB_BORAD" aria-label="PERCENTAGE">
                  Job Boards
                </mat-option>
                <mat-option value="XML_FEEDS" aria-label="Social media">
                  XML Feeds
                </mat-option>
              </mat-select>
              <mat-error>
                <span *ngIf="!dynamicForm.get('serviceType').valid && dynamicForm.get('serviceType').touched">
                  Please Select Service Type </span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">

            <label class="col-sm-12">Country</label>
            <div class="col-sm-12 ">
              <mat-select [ngClass]="{ 'is-invalid': submitted && dynamicForm.get('serviceType').errors }" multiple
                formControlName="countrySupported" placeholder="Please Select Country" class="form-control">
                <mat-option *ngFor="let r of countryList" [value]="r.countryId" [aria-label]="r.countryId">
                  {{r.countryName}}
                </mat-option>
              </mat-select>

            </div>
            <mat-error>
              <span *ngIf="!dynamicForm.get('serviceType').valid && dynamicForm.get('serviceType').touched">
                Please Select Country </span>
            </mat-error>

          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Name</label>
            <div class="col-sm-12 ">
              <input type="text" [ngClass]="{ 'is-invalid': submitted && dynamicForm.get('name').errors }"
                class="form-control" (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter Name"
                name="name" formControlName="name" required>
              <mat-error>
                <span *ngIf="!dynamicForm.get('name').valid && dynamicForm.get('name').touched">
                  Please enter name</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">website Name</label>
            <div class="col-sm-12 ">
              <input type="text" [ngClass]="{ 'is-invalid': submitted && dynamicForm.get('websiteName').errors }"
                class="form-control" (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter websiteName"
                name="websiteName" formControlName="websiteName" required>
              <mat-error>
                <span *ngIf="!dynamicForm.get('websiteName').valid && dynamicForm.get('websiteName').touched">
                  Please enter websiteName</span>
              </mat-error>
            </div>
          </div>

          <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-6">
            <div [formGroup]="ticket" class="col-sm-12" style="width: 100%;">
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} description </label>
                <div class="col-sm-12 ">
                  <textarea type="text" [ngClass]="{ 'is-invalid': submitted && ticket.get('description').errors }"
                    class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                    placeholder="Enter description" name="description" formControlName="description" required>
</textarea>

                </div>
                <mat-error>
                  <span *ngIf="!ticket.get('description').valid && ticket.get('description').touched">
                    Please enter description</span>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Status</label>
            <div class="col-sm-12 ">
              <mat-slide-toggle formControlName="status">Status</mat-slide-toggle>
            </div>
          </div>
          <!-- <div class="form-group row col-sm-12">
            <label class="col-sm-12"> Image</label>
            <div class="col-sm-12 row">
              <div class="col-sm-6">
                <label for="companyLogo" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Custom Upload
                </label>
                <input type="file" name="companyLogo" #file formControlName="companyLogo"
                  accept="image/x-png,image/jpeg" class="imageUploadcss" #attachments1 id="companyLogo"
                  (change)="countryFlagSelct($event,file.files)" />
              </div>
              <div class="col-sm-6" *ngIf="countryFlagImgURL">
                <div class="close" (click)="countryFlagReset()"><i class="fas fa-times-circle"></i></div>
                <div class="image-preview">
                  <img [src]="countryFlagImgURL" height="100" />
                </div>
              </div>
            </div>
            <div class="red-text col-sm-12">Note* : Upload 100px X 100px (Width X Height) size image for better view</div>
          </div> -->
        </div>
        <div class="">
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()"
            *ngIf="!viewId">Submit</button>
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()"
            *ngIf="viewId">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>