import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../service/language/language.service";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { MatDialog } from "@angular/material/dialog";
import { JobTestQuestionManageService } from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { DynamicLabelClass } from "../../../helper/userLevelLink";
import { ImageQuestionPopupComponent } from "../../managejobPostQuestion/image-question-popup/image-question-popup.component";

@Component({
  selector: 'app-add-update-icc',
  templateUrl: './add-update-icc.component.html',
  styleUrls: ['./add-update-icc.component.scss']
})
export class AddUpdateIccComponent implements OnInit {
  totalIccValue: number = 0;
  FinalIccValue: number;
  preemployeementtest: any;
  emotions: any;
  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    public dialog: MatDialog,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    private router: Router) { }
  get f() { return this.dyanmicQuestionForm.controls; }
  get t() { return this.f.assesmentTestMultiQuestions as FormArray; }
  columnsToDisplay = ["seqNo", "question"];
  expandedElement: (query: string) => void;
  viewID: any;
  fileData: File = null;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  dyanmicQuestionForm: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList = [];
  page: any;
  size: any;
  qustGropList = [];
  qustGropList0 = [];
  qustGropList1 = [];
  qustGropList2 = [];
  qustGropList3 = [];
  qustGropList4 = [];
  qustGropList5 = [];

  qGroup: any;
  jobCatList = [];
  subjobCatList = [];
  qType: any;
  arrayOptions = [];
  pdata: any;
  seletedOption: any;
  myModel: any;
  config: any;
  addnewOption: any;
  submitted = false;
  selectLang: any;
  firstFormSubmited: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'position', 'question', 'action'];
  dataSource = new MatTableDataSource<any>();
  dataSource1 = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  QGData: any;
  mm: boolean = false;
  assesmentTestId: any;
  createTestData: any;
  testQData: any;
  firstCreateData: any;
  checkboxSelectedCount: number = 0;
  checkboxSelectArray = [];
  ToatlQuestionNo: number;
  QuestionSubmitBtnHide: boolean = false;
  isTestCreateSuccess: boolean = false;
  SelectedMainQuestionList: any[] = [];
  dsdsdsdsdsdsdsd: any[] = [];
  Jcat: any;
  languageId: any;
  excellentPassDescription: any;
  normalPassingScoreMax: any;
  numberOfRetry: any;
  excellentPassScore: any;
  jobcategoryName: any;
  testDescriptionForCompany: any;
  testDescriptionForCandidate: any;
  maxTimeInMin: any;
  failResultDescription: any;
  normalPassingScoreMin: any;
  pererror: boolean = false;
  JobQuniError: boolean = false;
  excellentPassScoreError: boolean = false;
  addOptionButtonHide: boolean = false;
  selectedAreas: any[] = [];

  questionAreas: any[] = [];
  questionAreas1: any[] = [];
  questionAreas2: any[] = [];
  questionAreas3: any[] = [];
  questionAreas4: any[] = [];
  questionAreas5: any[] = [];
  customQuestionGroupArray: any[] = [];
  selectedlanguageCode: any;
  selectedLangName: any;
  maxScoreDisable: boolean = true;
  excellentScoreDisable: boolean = true;
  UpdateCreatedTest: boolean = false;
  selectedTab = 0;
  moreCountthanQuestion: boolean = false;
  passResultDescription: any;
  title: any;
  subquestionCount = 0;
  subjobCategoryId: any;
  jobSubCategoryId: any;

  //  question
  item: any;
  openness: any[] = [];
  opennesValues: Array<any> = [];
  conscientiousness: any[] = [];
  extroversion: any[] = [];
  agreeableness: any[] = [];
  neuroticism: any[] = [];
  subcatclone= [];
  mainCreatList= [];

  ngOnInit(): void {
    this.firstFormSubmited = false;
    this.addnewOption = 1;
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.seletedOption = 0;
    this.page = 0;
    this.size = 500;
    this.viewID = this.route.snapshot.params['id'];
    this.selectedTab = this.route.snapshot.params['tab'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    console.log(this.LangList);
    // this.questionGroupList();
    this.openContentOne(this.selectedTab);
    this.questionTypeList(this.selectLang);
    this.initializeForm();
    this.arrayOptions = [];
    this.openness = ['1', '2', '3', '4', '5'];
    // this.addOption();
  }

  LangChange(e) {
    for (let l of this.LangList) {
      if (l.languageId == e.value) {
        this.selectLang = l.languageCode;
        this.questionTypeList(this.selectLang);
      }
    }

  }
  questionTypeList(selectLang) {
    this.masterDataservice.getAllCategoryList(this.page, this.size, selectLang, true).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {

        this.jobCatList = this.qType.response.dataSet.filter((d) => {
          // && d.isTestCreated == false
          if (d.isSubCategory == false) {
            return d;
          }
        });
        this.selectedAreas = this.jobCatList;
        console.log(this.jobCatList);
      }
    });
  }


  subJobcategoryList(selectLang, parentId) {
    this.masterDataservice.getAllCategoryList(this.page, this.size, selectLang, false).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {

        let filtersubcat = this.qType.response.dataSet.filter((d) => {
          // && d.isTestCreated == false
          if (d.parentId == parentId) {
            return d;
          }
        });
        if (this.viewID) {
          this.subjobCatList = filtersubcat;
        } else {
          this.subjobCatList = filtersubcat.filter(e => this.mainCreatList.map((o) => o.jobSubCategoryId).indexOf(e.jobCategoryId) < 0);
        }
        this.subcatclone = this.subjobCatList; 
      
      }
    });
  }

  search(query: string) {
    console.log('query', query)
    let result = this.select(query.toLowerCase());
    if (query == '') {
      this.jobCatList = this.selectedAreas;
    } else {
      this.jobCatList = result;
    }
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
  subcatsearch(query: string) {
    console.log('query', query)
    let result = this.subselect(query.toLowerCase());
    if (query == '') {
      this.subjobCatList = this.subcatclone;
    } else {
      this.subjobCatList = result;
    }
  }
  subselect(query: string): string[] {
    let result: string[] = [];
    for (let a of this.subcatclone) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
  searchQuestionGrop(query: string, index) {
    console.log('query', query)
    let result = this.QuestionFromselect(query, index);
    if (query == '') {
      this.qustGropList[index] = this.questionAreas[index];
    } else {
      this.qustGropList[index] = result;
    }
  }
  QuestionFromselect(query: string, index): string[] {
    let result: string[] = [];
    for (let a of this.questionAreas[index]) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
  minScrorValidation($event) {
    const entervalue = $event.target.value;
    if (this.formGroup.controls.normalPassingScoreMin.value != '') {
      this.maxScoreDisable = false;
    } else {
      this.maxScoreDisable = true;
    }
  }



  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      jobCategoryId: ['', Validators.required],
      jobSubCategoryId: ['', Validators.required],
      cv: ['', Validators.required],
      personality: ['', Validators.required],
      preEmploymentTest: ['', Validators.required],
      interview: ['', Validators.required],
      emotion: ['', Validators.required],
    });

    this.dyanmicQuestionForm = this.fb.group({
      assesmentTestMultiQuestions: new FormArray([]),
    });

   
    this.LangListLoad(this.addnewOption);
    this.maincreat();
  }

  removeOption(i) {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e) {
    const numberOfTickets = e
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.fb.group({
          groupId: null,
          questionIds: [],
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }



  // get data from jobCategoryId
  menus: Array<any> = [];
  menusone: Array<any> = [];
  menustwo: Array<any> = [];
  menusthree: Array<any> = [];
  menusfour: Array<any> = [];
  patchData() {
    this.firstFormSubmited = true;
    this.masterDataservice.getICCValuesById(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          this.formGroup.patchValue(this.pdata.response);
          this.subJobcategoryList(this.selectLang, this.pdata.response.jobCategoryId);
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));


  }



  onSubmit() {
    this.totalIccValue = parseInt(this.formGroup.value.cv) + parseInt(this.formGroup.value.emotion) + parseInt(this.formGroup.value.interview) + parseInt(this.formGroup.value.personality) + parseInt(this.formGroup.value.preEmploymentTest);
    console.log(this.totalIccValue);
    if (this.totalIccValue == 100) {
      if (this.viewID) {
        const payload = {
          jobCategoryId: this.formGroup.value.jobCategoryId,
          jobSubCategoryId: this.formGroup.value.jobSubCategoryId,
          cv: this.formGroup.value.cv,
          emotion: this.formGroup.value.emotion,
          interview: this.formGroup.value.interview,
          personality: this.formGroup.value.personality,
          preEmploymentTest: this.formGroup.value.preEmploymentTest,
          id: this.viewID,
        };
        this.masterDataservice.editICCValues(payload).subscribe((data: any) => {
          if (data.status === 0) {
            this.snackBar.open('ICC Update Successfully.', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/list-iccvalues']);
          } else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }


        },
          (err) => {
            this.snackBar.open(err.error.message, '', {
              duration: 3000,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
              panelClass: ['error-snackbar']
            });
          }
        );

      } else {

        const payload = {
          jobCategoryId: this.formGroup.value.jobCategoryId,
          jobSubCategoryId: this.formGroup.value.jobSubCategoryId,
          cv: this.formGroup.value.cv,
          emotion: this.formGroup.value.emotion,
          interview: this.formGroup.value.interview,
          personality: this.formGroup.value.personality,
          preEmploymentTest: this.formGroup.value.preEmploymentTest,
        };
        this.masterDataservice.addICCValues(payload).subscribe((data: any) => {
          if (data.status === 0) {
            this.snackBar.open('ICC Inserted Successfully.', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/list-iccvalues']);
          }
          else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        },
          (err) => {
            this.snackBar.open(err.error.message, '', {
              duration: 3000,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
              panelClass: ['error-snackbar']
            });
          }
        );
      }
    }
    else {
      this.snackBar.open('Total percentage mismatch..', '', {
        duration: 3000,
        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
        panelClass: ['error-snackbar']
      });
    }

  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }


  jobcatSelection(e) {
    this.subJobcategoryList(this.selectLang, e.value);
  }

  demo(e) {
    const d = e.target.value;
    // console.log(d);
    // if(d > 0) return false;

  }
  openContentOne(e) {
    this.selectedTab = e;
  }

  imageQuestionPreview(element): any {
    const dialogRef = this.dialog.open(ImageQuestionPopupComponent, {
      width: '800px',
      height: 'auto',
      data: {
        question: element.question,
        qImage: element.questionImage,
      },
      panelClass: 'defaultClass',
    });
    dialogRef.afterClosed().subscribe(sresult => {
      // console.log(sresult);
      if (sresult == true) {
      }
    });
  }

  onCheckboxChangeOne(e, i) {
    if (e.target.checked) {
      /*if(this.opennesValues.length < 3)
      {*/
      this.opennesValues.push(e.target.value);
      /* }
       else {
         this.snackBar.open('Only select three values.', '', {
           duration: 3000,
           panelClass: ['error-snackbar']
         });
       }*/


    } else {
      var index = this.opennesValues.indexOf(e.target.value);
      this.opennesValues.splice(index, 1);
    }
    console.log(this.opennesValues);

  }

  onCheckboxChangeTwo(e, i) {
    if (e.target.checked) {
      this.conscientiousness.push(e.target.value);
    } else {
      var index = this.conscientiousness.indexOf(e.target.value);
      this.conscientiousness.splice(index, 1);
    }
    console.log(this.conscientiousness);

  }
  onCheckboxChangeThree(e, i) {
    if (e.target.checked) {
      this.extroversion.push(e.target.value);
    } else {
      var index = this.extroversion.indexOf(e.target.value);
      this.extroversion.splice(index, 1);
    }
    console.log(this.extroversion);

  }
  onCheckboxChangeFour(e, i) {
    if (e.target.checked) {
      this.agreeableness.push(e.target.value);
    } else {
      var index = this.agreeableness.indexOf(e.target.value);
      this.agreeableness.splice(index, 1);
    }
    console.log(this.agreeableness);

  }
  onCheckboxChangeFive(e, i) {
    if (e.target.checked) {
      this.neuroticism.push(e.target.value);
    } else {
      var index = this.neuroticism.indexOf(e.target.value);
      this.neuroticism.splice(index, 1);
    }
    console.log(this.neuroticism);

  }

  onSearchChange(value) {
    this.totalIccValue = this.totalIccValue + parseInt(value);
    console.log(value);
    console.log(this.totalIccValue);
  }

  maincreat(){
    this.masterDataservice.getAllICCValues(0, 500).subscribe( (res:any) =>{
      if(res.status == 0 ){
    
        this.mainCreatList = res.response.dataSet;
        if (this.viewID) {
          this.patchData();
        }
     
      }
    })
  }
}
