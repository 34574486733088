<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
      <h3 class="page-title">
       <!-- <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">Edit</span>-->
        Translation</h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span >Translation</span>
        </li>
        <li class="breadcrumb-item active">
             <span >
             <!-- <span *ngIf="!viewID">Add New</span>
              <span *ngIf="viewID">Edit</span>-->
             Translation</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="formGroup" >
        <div class="row">

          <!--<div class="form-group row col-sm-12">
            <label class="col-sm-12">Language Name</label>
            <div class="col-sm-12 ">
              <mat-select
                (selectionChange)="onLanguageChange($event.value)"
                formControlName="languageId" required
                [ngClass]="{ 'is-invalid': submitted && formGroup.get('languageId').errors }"
                placeholder="Please Select Language Name"
                class="form-control" >
                <mat-option *ngFor="let r of LangList"
                            [value]="r.languageId" [aria-label]="r.languageId">
                  {{r.languageName}}
                </mat-option>
              </mat-select>
              <mat-error>
                     <span *ngIf="!formGroup.get('languageId').valid && formGroup.get('languageId').touched">
                      Please Select Language Name </span>
              </mat-error>

            </div>
          </div>-->



          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Key Values</label>
            <div class="col-sm-12 ">
              <mat-select
                (selectionChange)="onKeyChange($event.value)"
                formControlName="keyValues" required
                [ngClass]="{ 'is-invalid': submitted && formGroup.get('keyValues').errors }"
                placeholder="Please Select Key"
                class="form-control" >
                <mat-option *ngFor="let r of keysOfMap; let i = index"
                            [value]="r" [aria-label]="r">
                  {{r}}
                </mat-option>
              </mat-select>
              <mat-error>
                     <span *ngIf="!formGroup.get('keyValues').valid && formGroup.get('keyValues').touched">
                      Please Select Key </span>
              </mat-error>

            </div>
          </div>
    </div>




        <div *ngFor="let ticket of  allValues | keyvalue; let i = index;" class="form-group row col-sm-12">
          <div class="col-sm-12 row align-items-center" style="width: 100%;">
            <div class="form-group row col-sm-12">
              <label class="col-sm-12">
                {{ticket.key}} </label>
              <div class="col-sm-12 ">
                <input  type="text"
                        (change)="valueUpdate($event , i ,ticket.key )"
                        class="form-control"
                        value="{{ticket.value}}" placeholder="Enter plan feature" name="response"
                         >
              </div>
            </div>

          </div>
        </div>


        <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10">Save</button>
      </form>

    </div>
  </div>

</div>
