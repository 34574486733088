<div class="bg-gray-200">
    <div class="modal d-block pos-static">
      <div role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">Question Import </h6>
            <button aria-label="Close" class="close" [mat-dialog-close]="false" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <mat-dialog-content>
              <div class="row mb-3">
                <div class="col-sm-12 row mb-3 ">
                  <div class="col-sm-3">Language List</div>
                  <div class="col-sm-9">
                    <mat-select (selectionChange)="LangChange($event)" 
                    placeholder="Please Select language"                  
                    [(ngModel)]="selectLang" class="form-control">
                      <mat-option *ngFor="let r of LangList" [value]="r" [aria-label]="r.languageId">
                        {{r.languageName}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <div class="col-sm-12 row mb-3">
                  <div class="col-sm-3">Question Type </div>
                  <div class="col-sm-9">
                    <mat-select [(ngModel)]="selectQuestionType" placeholder="Please Select Question Type"
                      class="form-control">
                      <mat-option *ngFor="let t of qustTypeList" [value]="t.questionTypeId"
                        [aria-label]="t.questionTypeId">
                        {{t.questionType}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <div class="col-sm-12 row mb-3">
                  <div class="col-sm-3">Question Group </div>
                  <div class="col-sm-9">
                    <mat-select (openedChange)="myInput.focus()" [(ngModel)]="selectQuestionGroup"
                    (closed)="myInput.value='';search(myInput.value)" 
                      placeholder="Please Select Question Group" class="form-control">
                      <input class="myInput form-control" #myInput focused="'true'" type="text"
                      (keydown)="$event.stopPropagation()"  
                      (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">

                      <mat-option *ngFor="let r of qustGropList" [value]="r.questionGroupId"
                        [aria-label]="r.questionGroupId">
                        {{r.groupName}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <div class="col-sm-12 row mb-3">
                  <div class="col-sm-3">Question Upload file</div>
                  <div class="col-sm-9 row">
                    <div class="col-sm-4">
                      <div class="col-sm-2">
                        <div class="btn btn-primary btn-sm rounded-lg fs-13">
                          <span>
                            <label for="file" style="margin: 0;">
                              <i class="fa fa-cloud-upload"></i> Import
                            </label>
                            <input type="file" name="country" #file
                              accept=".csv,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              class="imageUploadcss" #attachments1 id="file" (change)="fileSelect($event,file.files)" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <div *ngIf="fileData">
                        {{fileData.name}}
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </mat-dialog-content>
          </div>
          <div class="modal-footer">
            <button class="btn btn-indigo" type="submit" (click)="save()">Save changes</button>
            <button class="btn btn-outline-light" [mat-dialog-close]="false" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>