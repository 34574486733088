<div class="container-fluid">
    <div class="py-4">
        <div class="row mt-2">
            <div class="col-md-11">
                <h3 class="page-title">Job Description</h3>
                <!-- <p class="text4 text-color-dark" style="cursor: pointer;" [routerLink]="['/edit-user', id]"><img src="assets/img/web-icons/Back.png" alt="" class="me-1 cursor-pointer">
                    <a></a> Back
                </p> -->
            </div>
        </div>
        <div class="white-bg mt-4 mb-4">
            <h3 class="headingTitle mb-1">{{jobHeadline}}</h3>
            <p class="text4"><img src="assets/img/web-icons/company.png" alt="" class="me-2">
                <span *ngIf="companyName == null || companyName == '' || companyName == undefined ">NA</span>
                <span *ngIf="companyName != null || companyName != '' || companyName != undefined">{{companyName}}</span>
            </p>
            <div class="border-bottom py-3 mb-4">
                <p class="text4 mb-0">
                    <span *ngIf="jobDetails == null || jobDetails == '' || jobDetails == undefined">NA</span>
                    <span *ngIf="jobDetails != null || jobDetails != '' || jobDetails != undefined" [innerHTML]="jobDetails"></span>
                </p><br>
                <p class="text4 mb-0">
                    <span *ngIf="!jobRequirement">NA</span>
                    <span *ngIf="jobRequirement" [innerHTML]="jobRequirement"></span>
                </p>
            </div>



            <div class="response-user-detail">
                <ul>
                    <li><img src="assets/img/web-icons/checked.png" alt="" class="me-2">
                        <span *ngIf="!expMin">NA</span>
                        <span *ngIf="expMin">{{expMin}}-{{expMax}}
                          years</span>
                    </li>
                    <li><img src="assets/img/web-icons/package.png" alt="" class="me-2">
                        <span *ngIf="!salaryMin">NA</span>
                        <span *ngIf="salaryMin">{{salaryMin}}-{{salaryMax}}
                          {{salaryTypeName}}</span>
                    </li>
                    <li><img src="assets/img/web-icons/pin.png" alt="" class="me-2">
                        <span *ngIf="stateName == null || stateName == '' || stateName == undefined">NA</span>
                        <span *ngIf="stateName != null || stateName != '' || stateName != undefined">{{stateName}},{{cityName}}</span>
                    </li>
                    <li><img src="assets/img/web-icons/location.png" alt="" class="me-2">
                        <span *ngIf="noOfOpening == null || noOfOpening == '' || noOfOpening == undefined">NA</span>
                        <span *ngIf="noOfOpening != null || noOfOpening != '' || noOfOpening != undefined">{{noOfOpening}}
                          Openings</span>
                    </li>
                    <li><img src="assets/img/web-icons/checked.png" alt="" class="me-2">
                        <span>{{updatedEpochTime | date:'medium' | dateAsAgo }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="white-bg mb-4">
            <h3 class="headingTitle2 mb-2">Job Description</h3>
            <div class="row mb-3">
                <div class="col-lg-2">
                    <label class="text4">Job Type :</label>
                </div>
                <div class="col-lg-10">
                    <p class="mb-0 text3">

                        <span *ngIf="!employmentTypeName">{{jobInternshipType?jobInternshipType:'JOB'}}, NA</span>
                        <span *ngIf="employmentTypeName">{{jobInternshipType?jobInternshipType:'JOB'}}, {{employmentTypeName}}</span>
                    </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2">
                    <label class="text4">Job Start Date :</label>
                </div>
                <div class="col-lg-10">
                    <p class="mb-0 text3">{{createdEpochTime | date:'dd-MM-yyyy' }}</p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2">
                    <label class="text4">Job End Date :</label>
                </div>
                <div class="col-lg-10">
                    <p class="mb-0 text3">{{jobEndDate | date:'dd-MM-yyyy'}}</p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2">
                    <label class="text4">Experience :</label>
                </div>
                <div class="col-lg-10">
                    <p class="mb-0 text3">
                        <span *ngIf="!expMin">NA</span>
                        <span *ngIf="expMin">{{expMin}}-{{expMax}}
                          yrs</span>
                    </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2">
                    <label class="text4">Qualifications :</label>
                </div>
                <div class="col-lg-10">
                    <p class="mb-0 text3">

                        <span *ngIf="!educationName">NA</span>
                        <span *ngIf="educationName">{{educationName}}</span>
                    </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-2">
                    <label class="text4">Key Skills :</label>
                </div>
                <div class="col-lg-10">

                    <p class="mb-0 text3">
                        <span *ngIf="skillsName == null || skillsName == '' || skillsName == undefined">NA</span>
                        <span *ngIf="skillsName != null || skillsName != '' || skillsName != undefined">{{skillsName}}</span>
                    </p>

                </div>
            </div>
            <div class="row">
                <div class="col-lg-2">
                    <label class="text4">Post Date :</label>
                </div>
                <div class="col-lg-10">
                    <p class="mb-0 text3">{{updatedEpochTime | date:'dd-MM-yyyy' }}</p>
                </div>
            </div>
        </div>
        <div class="white-bg mb-4">
            <h3 class="headingTitle2 mb-2">About Company</h3>
            <div class="row mb-3">
                <div class="col-lg-3">
                    <label class="text4">Company Name :</label>
                </div>
                <div class="col-lg-9">
                    <p class="mb-0 text3">
                        <span *ngIf="companyName == null || companyName == '' || companyName == undefined">NA</span>
                        <span *ngIf="companyName != null || companyName != '' || companyName != undefined">{{companyName}}</span>
                    </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3">
                    <label class="text4">Contact Email Address :</label>
                </div>
                <div class="col-lg-9">
                    <p class="mb-0 text3">
                        <span *ngIf="email == null || email == '' || email == undefined">NA</span>
                        <span *ngIf="email != null || email != '' || email != undefined">{{email}}</span>
                    </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3">
                    <label class="text4">Company Address :</label>
                </div>
                <div class="col-lg-9">
                    <p class="mb-0 text3">
                        <span *ngIf="cityName == null || cityName == '' || cityName == undefined">NA</span>
                        <span *ngIf="cityName != null || cityName != '' || cityName != undefined">{{cityName}}</span>
                    </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3">
                    <label class="text4">Contact Number :</label>
                </div>
                <div class="col-lg-9">
                    <p class="mb-0 text3">

                        <span *ngIf="phoneNumber == null || phoneNumber == '' || phoneNumber == undefined">NA</span>
                        <span *ngIf="phoneNumber != null || phoneNumber != '' || phoneNumber != undefined">{{phoneNumber}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="white-bg mb-4">
            <h3 class="headingTitle mb-4">IT Skills</h3>
            <div class="col-md-10">
                <!-- <a class="skill-bg mb-3 me-3" *ngFor="let element of skillsList; let i = index">{{element}}</a> -->
                <ul>
                    <li *ngFor="let element of skillsList; let i = index" style="display: inline-block; margin-bottom: 30px;     margin: 10px;">
                        <a class="skill-bg mb-3 me-3">{{element}}</a>

                    </li>
                </ul>
            </div>
            <!-- <div class="row mb-4">
              <h3 class="headingTitle mb-4">Industry</h3>
              <div class="response-skills">
                  <ul>
                      <li>Software Development</li>
                  </ul>
              </div>
          </div> -->
        </div>
        <div class="white-bg" *ngIf="!questionnaireList.length">
            <div class="questionaire-candidate">
                <h2 class="headingTitle2" style="text-align: center;">Questions Not Available </h2>

            </div>
        </div>
        <div class="white-bg" *ngIf="questionnaireList.length">
            <div class="questionaire-candidate">
                <h2 class="headingTitle2">Questions</h2>
                <div *ngFor="let data of questionnaireList; let i =index">
                    <h2 class="headingTitle2">{{data.questionnaireName}}</h2>
                    <div class="questions border-bottom mb-3" *ngFor="let element of data.questionDetails; let i = index">
                        <h2 class="mb-2">{{i+1}}.{{element.question}}</h2>
                        <p *ngFor="let element of element.options; let i = index">{{element.option}}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="text-center">
        <button type="submit" class="btn btn-danger pd-x-20 mg-t-10 mb-4" routerLink="/job-approval-list" tabindex="0" style="margin-right: 10px;">Back</button>
        <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10  mb-4"  style="margin-right: 10px;">POST JOB</button>
        <button type="submit" class="btn btn-danger pd-x-20 mg-t-10 mb-4" (click)="rejectJob()">Reject Job</button>
    </div>
</div>