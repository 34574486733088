<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
               Manage MemberShip 
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item ">
                    <span [routerLink]="['/user-list']">List Of Membership</span>
                </li>                
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="col-sm-12">
        <div class="card mg-b-20">
            <div class="card-body">
                <div class="d-flex">
                    <div class="miandiv">
                        <div class="text-border"></div>  
                        <div class="memberKey" *ngFor="let m of memberKeyList ">                           
                            <b>{{ m.benefitName}}</b> 
                        </div>
                    </div>
                    <div class="miandiv d-flex">
                        <div class="plandetails" *ngFor="let m of plandetails"> 
                            <div class="text-border page-title">{{m.membershipName}} </div>   
                            <div *ngFor="let d of m.membershipBenefits" class="text-border d-flex justify-content-center align-items-center">
                           
                               <span class="currency"  *ngIf="isNumber(d.benefitValue) == false  && d.membershipBenefit.benefitType == 'Currency'  &&  d.membershipBenefit.benefitName == 'Price in GBP'"><i class="fas fa-pound-sign"></i>&nbsp;</span>
                               <span class="currency" *ngIf="isNumber(d.benefitValue) == false && d.membershipBenefit.benefitType == 'Currency' &&  d.membershipBenefit.benefitName == 'Price in EUR'"><i class="fas fa-euro-sign"></i>&nbsp;</span>
                               <span class="currency" *ngIf="isNumber(d.benefitValue) == false && d.membershipBenefit.benefitType == 'Currency' && d.membershipBenefit.benefitName == 'Price in USD' "><i class="fas fa-dollar-sign"></i>&nbsp;</span>
                               <span class="currency" *ngIf="isNumber(d.benefitValue) == false && d.membershipBenefit.benefitType == 'Currency' && d.membershipBenefit.benefitName == 'Price in PLN'">zł &nbsp;</span>
                             
                                <span *ngIf="d.benefitValue == 'Yes' || d.benefitValue == 'No' ">
                                    <span class="yes" *ngIf="d.benefitValue == 'Yes' "><i class="fas fa-check-circle" ></i></span>
                                    <span class="no" *ngIf="d.benefitValue == 'No' "><i class="fas fa-times-circle" ></i></span>
                                    </span>
                                <span *ngIf="!(d.benefitValue == 'Yes' || d.benefitValue == 'No' )">
                                    {{d.benefitValue}}
                                </span>
                                <span *ngIf="d.membershipBenefit.benefitType == 'Discount' ">%</span>
                                
                            
                            </div> 
                            <div class="text-center mt-3 mb-3" >
                                <span class="btn btn-sm" [routerLink]="['/addEditMemberShip' , m.membershipId]" >Edit</span>
                                </div>                                                  
                               
                        </div>
                        
                        
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>