<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
        <h3 class="page-title">Update {{appName}} Language File</h3>
        <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item active"><span [routerLink]="['/manageLanguage']">Manage Language</span></li>
        <li class="breadcrumb-item active">Update {{appName}} Language File</li>
        </ol>
    </div>
</div>       
<!--Page Content -->
<div class="col-sm-12">
    <div class="card">
        <div class="c_table">
        <div class="mat-elevation-z8">
            <div class="d-flex table-responsive p-3">
            <div class="mr-2">
                <label>Page Name</label>
                <div style="width: 250px;">
                    <mat-select [ngModel]="selectedPageName"
                    (selectionChange)="pageNameChange($event)"  
                    placeholder="Select Page Name"
                                class="form-control" >
                    <mat-option *ngFor="let r of pageNameList"
                                [value]="r" [aria-label]="r">
                        {{r}}
                    </mat-option>
                    </mat-select>
                </div>
            </div>                     
            <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                <input class="form-control" (keyup)="applyFilter($event.target.value)" 
                 placeholder="Search Here">
            </div>
            </div>
            <!-- <form [formGroup]="formGroup"> -->
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="srno">
                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                        <td mat-cell *matCellDef="let element;let j = index">
                        {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                        </td>
                    </ng-container>
                    <!-- Page Name -->
                    <ng-container matColumnDef="pageName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Page Name</th>
                        <td mat-cell *matCellDef="let element">{{element.pageName}}</td>
                    </ng-container>
                    <!-- Key Name -->
                    <ng-container matColumnDef="keyName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Key Name</th>
                        <td mat-cell *matCellDef="let element">{{element.keyName}}</td>
                    </ng-container>
                    <!-- Language Column -->
                    <ng-container matColumnDef="{{list}}" *ngFor="let list of languageDisplay;let i = index;">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{list}}</th>
                            <td mat-cell *matCellDef="let element">
                                <input type="text" [ngModel]="element.manageLanguageDetails[i].value" style="margin: 3px 0px;"
                                    class="form-control" placeholder="Enter Value" (keyup)="element.manageLanguageDetails[i].value=$event.target.value">
                            </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th width="80px" mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="d-flex justify-content-center">
                                <span class="btn btn-sm btn-primary rounded-lg mar15" (click)="save(element)" matTooltip="Save Language Value" matTooltipPosition="above">
                                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                </span>
                                <span class="btn btn-sm btn-primary rounded-lg mar15" [matTooltip]="element.updatedEpochTime | date:'mediumDate'" matTooltipPosition="above">
                                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            <!-- </form> -->
            
            <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>
        </div>
        </div>  
    </div>
</div>

