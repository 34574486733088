import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CandidateService {
  constructor(private http: HttpClient) {}
  getAllCandidateList(page, size, state ,name, MobileNo, emailId, source, district): any {
    return this.http.get(
      `${environment.baseurl1}/candidate/getAllCandidateUser?page=` + page + `&size=` +  size +`&state=` +  state +`&name=` +  name
      +`&MobileNo=` +  MobileNo +`&emailId=` +  emailId +`&source=` +  source +`&district=` +  district
    );
  }
  getCandidateDetailsById(id) {
    return this.http.get(
      `${environment.baseurl1}/candidate/getProfileByCandidateId?id=` + id
    );
  }
  getStatisticsById(candidateId): any {
    return this.http.get(
      `${environment.baseurl}/v1/companySubscription/getCandidateStatisticsDetails?candidateId=` +
      candidateId
    );
  }
  getAllCvByCandidateId(id) {
    return this.http.get(
      `${environment.baseurl1}/candidate/getAllCvByCandidateId?candidateId=` +
        id
    );
  }
  getCvById(id) {
    return this.http.get(
      `${environment.baseurl1}/candidate/getCvById?id=` + id
    );
  }
  getPersonalityResult(candiadateId) {
    return this.http.get(
      `${environment.baseurl2}/candidate/getResultByCandidateId?candidateId=` +
        candiadateId
    );
  }
  getAllCompetencyKeywordList(lang) {
    return this.http.get(
      `${environment.baseurl2}/getAllCompetencyByParentChildDetails?isoCode=` +
        lang
    );
  }
  getAllPreEmploymentTest(lang, id) {
    return this.http.get(
      `${environment.baseurl2}/getPreEmploymentTest?isoCode=` +
        lang +
        `&candidateId=` +
        id
    );
  }
  getPreEmploymentResult(id) {
    return this.http.get(
      `${environment.baseurl2}/getPreEmployementResult?employmentTestId=` + id
    );
  }
  getContactDetailsById(requestId) {
    return this.http.get(
      `${environment.baseurl1}/dashboard/getUsersDetailsById?requestId=` +
        requestId
    );
  }
  getcandidateResume(candidateId: any): any {
    return this.http.get(
      `${environment.baseurl1}/candidate/getCandidateResume?candidateId=` +
        candidateId
    );
  }
  exportCandidateListExcel(extension: any): any {
    return this.http.get(
      `${environment.baseurl1}/candidate/exportCandidateListExcel?extension=` + extension
    );
  }
}
