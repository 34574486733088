<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">List Of Country Data</h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listCountryData']">List Of Country Data</span>
        </li>
        <li class="breadcrumb-item active">
          <span>List Of Country Data</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" appInvalidControlScroll>
        <div class="row">
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Country Name</label>
            <!-- <label for="example-text-input" class="col-sm-3 col-form-label text-right colonclass">Country Name </label> -->
            <div class="col-sm-12 ">
              <!-- <mat-form-field appearance="outline">
                <mat-label>Enter country name </mat-label> -->
              <input type="text" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                placeholder="Enter country name" name="countryName" formControlName="countryName" required>
              <!-- </mat-form-field> -->
              <mat-error>
                <span *ngIf="!formGroup.get('countryName').valid && formGroup.get('countryName').touched">
                  Please enter country name</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Country Code</label>
            <!-- <label for="example-text-input" class="col-sm-3 col-form-label text-right colonclass">Country Code </label> -->
            <div class="col-sm-12 ">
              <!-- <mat-form-field appearance="outline">
              <mat-label>Enter country code </mat-label> -->
              <input class="form-control" type="text" (keydown)="firstSpaceRemoveValid($event)" value=""
                placeholder="Enter country code" name="phoneCode" formControlName="phoneCode" required>
              <!-- </mat-form-field> -->
              <mat-error>
                <span *ngIf="!formGroup.get('phoneCode').valid && formGroup.get('phoneCode').touched">
                  Please enter country code</span>
              </mat-error>
            </div>
          </div>


          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Country Short Name</label>
            <div class="col-sm-12">
              <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                placeholder="Enter country short name" name="shortName" formControlName="shortName" required>
              <mat-error>
                <span *ngIf="!formGroup.get('shortName').valid && formGroup.get('shortName').touched">
                  Please enter country short name</span>
              </mat-error>
            </div>
          </div>

          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Vat(%)</label>
            <div class="col-sm-12">
              <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                placeholder="Enter vat in percentage" name="vatPercentage" formControlName="vatPercentage" required>
              <mat-error>
                <span *ngIf="!formGroup.get('vatPercentage').valid && formGroup.get('vatPercentage').touched">
                  Please enter vat in percentage</span>
              </mat-error>
            </div>
          </div>
          <!--<div class="form-group row col-sm-6">
          <label class="col-sm-12">Country ISO</label>
          <div class="col-sm-12">
              <input  type="text"
              (keydown)="firstSpaceRemoveValid($event)"
              class="form-control"
              value="" placeholder="Enter country iso" name="countryIso"
                     formControlName="countryIso"  (keypress)="numberOnly($event)" required>
            <mat-error>
             <span *ngIf="!formGroup.get('countryIso').valid && formGroup.get('countryIso').touched">
                                Please enter country iso</span>
            </mat-error>
          </div>
        </div>-->

          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Country Flag</label>
            <!-- <label class="col-sm-3 col-form-label text-right">Country Flag :</label> -->
            <div class="col-sm-12 row">
              <div class="col-sm-6">
                <label for="country" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Custom Upload
                </label>
                <input type="file" name="country" #file formControlName="countryFlag" accept="image/x-png,image/jpeg"
                  class="imageUploadcss" #attachments1 id="country" (change)="countryFlagSelct($event,file.files)" />
              </div>
              <div class="col-sm-6" *ngIf="countryFlagImgURL">
                <div class="close" (click)="countryFlagReset()"><i class="fas fa-times-circle"></i></div>
                <div class="image-preview">
                  <img [src]="countryFlagImgURL" height="100" />
                </div>


              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!countryId">Submit</button>
        <button type="submit" class="btn btn-primary" *ngIf="countryId">Update</button>
      </form>




    </div>
  </div>

</div>