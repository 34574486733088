import {Component, Input, OnInit, Output} from '@angular/core';
import {CandidateService} from "../../../service/candidateDetails/candidate.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import {DomSanitizer} from "@angular/platform-browser";
class EventEmitter {
}
@Component({
  selector: 'app-candidate-cv-view',
  templateUrl: './candidate-cv-view.component.html',
  styleUrls: ['./candidate-cv-view.component.scss']
})
export class CandidateCvViewComponent implements OnInit {
  @Input() cvId:number;
  @Input() downloadCVPdf:boolean;
  @Input() perCV;
  @Input() uploadCV;
  @Output() toggle = new EventEmitter();
  cvData:any;
  langList:any;
  selectedLangCode:any;
  compentencyData:any=[];
  showingData:boolean =false;
  skills:any;
  certificates:any;
  mainCompatcyList: any;
  candidateId: any;
  showingIframData: boolean =false;
  cvUploadUrl: any;
  resumeId: any;
  isCvUploaded: any;
  uploadCVViewper = false;
  // uploadCvUrl: any;
  userData: any;
  userProfile: any;
  url: any;
  uploadCvUrl: any;
  constructor(
              private candidateService: CandidateService,
              private masterService: MasterDataService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.candidateId = this.route.snapshot.params['id'];
    this.selectedLangCode = 'en';
    this.pacthData();
    // this.compentacyKeywordList(this.selectedLangCode);
  }

  // getCvById(id):any{
  //   this.candidateService.getCvById(id).subscribe( (res: any) => {
  //     if (res.status == 0){
  //       this.cvData = res.response;
  //       this.candidateId = res.response.candidateId;
  //       if(res.response.cvUploadUrl == null) {
  //         this.showingData = true;
  //         this.showingIframData = false;
  //         this.cvUploadUrl = '';
  //       }else{
  //         this.showingData = false;
  //         this.showingIframData = true;
  //         this.cvUploadUrl = res.response.cvUploadUrl;
  //       }

  //       if(this.cvData.educations){
  //         this.cvData.educations.forEach(element => {
  //           if(element.fromDate != null){
  //             element.fromDate = this.convertDate(element.fromDate);
  //           } if(element.toDate != null){
  //             element.toDate = this.convertDate(element.toDate);
  //           }
  //         });
  //       }
  //       if(this.cvData.experiences){
  //         this.cvData.experiences.forEach(element => {
  //           if(element.fromDate != null){
  //             element.fromDate = this.convertDate(element.fromDate);
  //           } if(element.toDate != null){
  //             element.toDate = this.convertDate(element.toDate);
  //           }
  //         });
  //       }
  //       if(this.cvData.additionalSkill){
  //         this.skills =this.cvData.additionalSkill.split(',');
  //       }
  //       if(this.cvData.certificates){
  //         this.certificates =this.cvData.certificates.split(',');
  //       }
  //       if(this.cvData.languages){
  //         this.cvData.languages.forEach(element => {
  //           const arr =[];
  //           for(let i=1; i<=parseInt(element.rating); i++){
  //             arr.push(i);
  //           }
  //           element.rating = arr;
  //         });
  //       }

  //     } else {
  //       console.log('Data not available.');
  //     }
  //   } , (error: any) => {
  //     console.log(error.error.message);
  //   });
  // }

  // convertDate(date){
  //   const convertdate = new Date(date);
  //   var month = convertdate.getMonth()+1;
  //   var day = convertdate.getUTCDate();
  //   var year = convertdate.getUTCFullYear();
  //   return (day +'/' + month +'/' + year);
  // }
  // compentacyKeywordList(lang): any{
  //   this.candidateService.getAllCompetencyKeywordList(lang).subscribe((res: any) =>{
  //     if(res.status == 0){
  //       this.mainCompatcyList = res.response;
  //       if(this.cvData.compatencies){
  //         var Arr = this.cvData.compatencies.split(',');
  //         this.mainCompatcyList.forEach(element => {
  //           element.childDetails.forEach(data => {
  //             Arr.forEach(item => {
  //               if(data.competencyCategoryId == item){
  //                 this.compentencyData.push(data.displayName);
  //               }
  //             });
  //           });
  //         });
  //       }

  //     } else {
  //       console.log('Data not available.');
  //     }
  //   } , (error: any) => {
  //     console.log(error.error.message);
  //   });
  // }

  // cleanURL(cvUploadUrl) {
  //   const dddd = 'https://docs.google.com/viewerng/viewer?url=' + cvUploadUrl + '&embedded=true';
  //   console.log(dddd);
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(dddd);
  // }

  pacthData(): void {
    this.candidateService
      .getcandidateResume(this.candidateId)
      .subscribe((res: any) => {
        if (res.status === 0) {
          if (res.response.systemGeneratedResume) {
            const url = res.response.systemGeneratedResume;
            this.uploadCvUrl = url;
          } else {
            // this.matDialogService.openCommonDialog('System Generated Resume Not Availble');
          }
          // console.log('::::::::::::::::::=========', this.uploadCvUrl);
          // this.profileService.emitJobNextBtnFn(7);
          // window.scrollTo(0, 0);
        }
      });
  }

}
