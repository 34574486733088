<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!questionGroupId">Add </span>
        <span *ngIf="questionGroupId">Edit </span> Question Group
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listQuestionGroup']">List Question Group</li>
        <li class="breadcrumb-item active">
          <span *ngIf="!questionGroupId">Add </span>
          <span *ngIf="questionGroupId">Edit </span>
          Question Group
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <!-- <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">group Name</label>
               <div class="col-sm-12">
                   <input 
                   class="form-control"
                   type="text"  value="" placeholder="Enter groupName" name="groupName"
                   [readOnly]="(questionGroupId)"     
                   (keydown)="firstSpaceRemoveValid($event)"
                   formControlName="groupName" required >
                 
                 <mat-error>
                      <span *ngIf="!formGroup.get('groupName').valid && formGroup.get('groupName').touched">
                                     Please enter group Name</span>
                 </mat-error>
               </div>
             </div>
             <div class="form-group row col-sm-12">
               <label class="col-sm-12">group Description</label>
               <div class="col-sm-12">
                <angular-editor
                (viewMode)="true" tabIndex="1" (keydown)="firstSpaceRemoveValid($event)"
                  value="" placeholder="Enter Question answer Explanation"
                  [config]="config"
                  (paste)="onPaste($event , 'groupDescription')"
                  [readOnly]="(questionGroupId)"     
                  type="text"  value="" placeholder="Enter Group Description " name="groupDescription"
                         formControlName="groupDescription" required></angular-editor>

          
                 
                 <mat-error>
                  <span *ngIf="!formGroup.get('groupDescription').valid && formGroup.get('groupDescription').touched">
                                     Please enter group Description</span>
                 </mat-error>
               </div>
             </div>
        </div>
      
        <div>
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!questionGroupId">Submit</button>
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="questionGroupId">Update</button>
          <button routerLink="/listQuestionGroup" class="btn btn-warning pd-x-20 mg-t-10 mar15" >Back</button>
        </div>
        
      </form> -->
      <form [formGroup]="dynamicForm" >
        <div class="row">
          <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-6">
            <div [formGroup]="ticket" class="col-sm-12">
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Group Name</label>
                <div class="col-sm-12 ">
                  <input type="text" class="form-control" value="" placeholder="Enter group name" name="name"
                    formControlName="groupName" required>
                </div>
              </div>
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Group Description </label>
                <div class="col-sm-12 ">
                  <angular-editor [config]="config" formControlName="groupDescription" required
                    placeholder="Please Enter group Description" name="groupDescription"></angular-editor>
                </div>
              </div>
  
            </div>
          </div>
        </div>
        
        <div>
          <button type="submit" (click)="onSubmit()" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="!questionGroupId">Submit</button>
        <button type="submit" (click)="onSubmit()" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="questionGroupId">Update</button>
        </div>
        
      </form>




    </div>
  </div>

</div>