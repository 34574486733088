<div class="container-fluid">
  <!-- breadcrum start -->
  <div class="row">
    <div class="col-sm-12">
      <div class="page-title-box">
        <div class="float-right">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item">Mange Team </li> -->
            <li class="breadcrumb-item "  [routerLink]="'/home'">
              <i class="fas fa-home" ></i></li> 
            <li class="breadcrumb-item "  [routerLink]="'/listjobcategory'">Job Category List</li>
            <li class="breadcrumb-item">
              <span *ngIf="!jobCategoryId">Add New </span> <span *ngIf="jobCategoryId">Edit </span> Job Category</li>
          </ol>
        </div>
        <h4 class="page-title"> <span *ngIf="!jobCategoryId">Add New </span> <span *ngIf="jobCategoryId">Edit </span> Job Category</h4>
      </div>
    </div>
  </div>
  <!-- breadcrum end -->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="form-group row">
          <label for="example-text-input" class="col-sm-3 col-form-label text-right colonclass">Job Category Name </label>
          <div class="col-sm-9 ">
            <mat-form-field appearance="outline">
              <mat-label>Enter job category name </mat-label>
              <input matInput type="text"  value="" placeholder="Enter job category name" name="categoryName"
                     formControlName="categoryName" required>
            </mat-form-field>
            <mat-error>
                 <span *ngIf="!formGroup.get('categoryName').valid && formGroup.get('categoryName').touched">
                                Please enter job category name</span>
            </mat-error>
          </div>
        </div>
        <div class="form-group row">
          <label for="example-text-input" class="col-sm-3 col-form-label text-right colonclass">Job Category Description </label>
          <div class="col-sm-9 ">
            <mat-form-field appearance="outline">
              <mat-label>Enter job category description </mat-label>
              <input matInput type="text"  value="" placeholder="Enter job category description" name="categoryDescription"
                     formControlName="categoryDescription" required>
            </mat-form-field>
            <mat-error>
             <span *ngIf="!formGroup.get('categoryDescription').valid && formGroup.get('categoryDescription').touched">
                                Please enter job category description</span>
            </mat-error>
          </div>
        </div>
        
        <button type="submit" class="btn btn-success" *ngIf="!jobCategoryId">Submit</button>
        <button type="submit" class="btn btn-success" *ngIf="jobCategoryId">Update</button>
      </form>




    </div>
  </div>

</div>
