<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Manage Job Approval List</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active" [routerLink]="['/editTeam']">Manage Job Approval List</li>
        </ol>
    </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
    <div class="card">
        <div class="c_table">
            <div class="mat-elevation-z8">
                <div class="col-6 mt-4">
                    <span class="btn btn-sm btn-primary rounded-lg" style="margin-right: 5px;" *ngIf="jobList.length"
                    matTooltip="Post All Approval Jobs"
                    matTooltipPosition="above" (click)="postAllJobs()">Post All Jobs</span>
                </div>
                <div class="d-flex table-responsive p-3">
                    <div class="mr-2">

                    </div>
                    <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                    </div>
                </div>
                <table mat-table [dataSource]="dataSource" matSort>
                    <!-- <ng-container matColumnDef="select">
                        <th width="60px" mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let element ; let i = index;">
                          <mat-checkbox (change)="newcheckbox($event,element.jobId)" [value]="element.jobId"></mat-checkbox>
                        </td>
                      </ng-container> -->
                    <ng-container matColumnDef="srno">
                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                        <td mat-cell *matCellDef="let element;let j = index" style="padding: 4px 3px !important;">

                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                        </td>
                    </ng-container>

                    <div>
                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
                            <td mat-cell *matCellDef="let element">{{element.role}}</td>
                        </ng-container>

                        <ng-container matColumnDef="jobType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Type</th>
                            <td mat-cell *matCellDef="let element">{{element.jobInternshiptype?element.jobInternshiptype:'JOB'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="noOfHiring">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>No Of Hiring</th>
                            <td mat-cell *matCellDef="let element">{{element.noOfHiring}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="dateJobPosted">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Posted date</th>
                            <td mat-cell *matCellDef="let element">{{element.dateJobPosted | date:'dd-MM-yyyy'}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="response">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>no of response</th>
                            <td mat-cell *matCellDef="let element">{{element.response}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> status</th>
                            <td mat-cell style="color: #fcb32d;" *matCellDef="let element">{{element.status}}
                            </td>
                        </ng-container>

                    </div>
                    <ng-container matColumnDef="action">
                        <th width="100px" mat-header-cell *matHeaderCellDef>View Job</th>
                        <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                            <div class="d-flex justify-content-center">
                                <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/job-description', element.jobId]">
                              <img src="../../../../assets/img/eye.svg" alt="eye">
                            </span>
                            </div>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>

            </div>
        </div>
    </div>
</div>