
<div class="row">
  <div class="col-sm-12">
    <div class="page-title-box">
      <div class="float-right">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a  [routerLink]="'/home'">
            <i class="fas fa-home" ></i> </a></li>

        </ol>
      </div>
      <h4 class="page-title"> List Of Category </h4>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">

    <mat-card>
      <mat-card-content>
        <div class="c_table">
          <div class="mat-elevation-z8">
            <div class="row filterblock align-items-center">
              <div class="col-sm-4 pull-left">
                <mat-form-field appearance="outline" class="table_filter">
                  <mat-label>Search </mat-label>
                  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                </mat-form-field>
                <!-- <form class="searchform" [formGroup]="seachcatogry" (keypress)="keyDownFunction($event)">
                  <input  aria-label="Apps" [matAutocomplete]="auto"
                          [formControl]="appCtrl"
                          placeholder="Search Job Category"
                          [(ngModel)]="selectproduct"
                  >
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (onSelectionChange)="onEnter(app)"
                                *ngFor="let app of filteredApps | async" [value]="app.categoryName"
                                class="pad-none td-search-option">
                      <div class="">

                        <div>{{app.categoryName}}</div>
                      </div>

                    </mat-option>
                  </mat-autocomplete>

                </form> -->
              </div>
              <div class="col-sm-5 pull-right">
                <div class="pull-right" >
                  <div class="btn btn-primary">
                    <a   [routerLink]="['/addjobcategory']" >
                      <i class="fas fa-plus"></i>Add New  </a>
                  </div>
                </div>

              </div>
            </div>


            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
              <ng-container matColumnDef="srno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                <td mat-cell *matCellDef="let element;let j = index">
                  {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                </td>
              </ng-container>

              <div>

                <ng-container matColumnDef="categoryName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Category Name</th>
                  <td mat-cell *matCellDef="let element">{{element.categoryName}}</td>
                </ng-container>

                <ng-container matColumnDef="categoryDescription">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                  <td mat-cell *matCellDef="let element">{{element.categoryDescription}}</td>
                </ng-container>

              </div>
              <div>

                <ng-container matColumnDef="subCategoryName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Category </th>
                  <td mat-cell *matCellDef="let element">{{element.subCategoryName}}</td>
                </ng-container>

<!--                <ng-container matColumnDef="displayName">-->
<!--                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Display Name </th>-->
<!--                  <td mat-cell *matCellDef="let element">{{element.displayName}}</td>-->
<!--                </ng-container>-->
              </div>
              <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                            <span >
                              <mat-slide-toggle (change)="oldpermissionChange($event,element)" name="isActive"
                                                [checked]="element.isActive"></mat-slide-toggle>
                            </span>

                </td>
              </ng-container>



              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                  <div class="d-flex justify-content-center">
                              <span class="btn btn-sm btn-primary mar15"
                                    [routerLink]="['/editjobcategory', element.jobCategoryId]">
                              <i class="fas fa-user-edit"></i></span>
                    <span  class="btn btn-sm btn-danger" (click)="deleteCategoryDetails(element.jobCategoryId)"><i
                      class="fas fa-trash"></i></span>

                  </div>


                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- <div class="d-flex justify-content-end align-items-center">
              <div class="c_page"><b>Current Page: </b> {{page}} </div>
              <div class="t_recd" ><b>Total Records: </b>  {{totalrecordLength}}</div>
              <ul class="pagination">
                <li (click)="firstpage()" ><i class="fas fa-angle-double-left" ></i></li>
                <li (click)="prevpage()" ><i class="fas fa-arrow-left"></i></li>
                <li (click)="nextpage()" ><i class="fas fa-arrow-right" ></i> </li>
                <li (click)="lastpage()"> <i class="fas fa-angle-double-right"></i></li>
            </ul>
            </div> -->


            <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>

          </div>


        </div>
      </mat-card-content>
    </mat-card>

  </div>

</div>
