import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MasterDataService {
  constructor(private http: HttpClient) {}
  getAllTeamList(page, size) {
    return this.http.get(
      `${environment.baseurl}/getAllUsers?page=` + page + `&size=` + size
    );
  }
  getAllContactList(page, size) {
    return this.http.get(
      `${environment.baseurl}/dashboard/getAllConatctUsReq?page=` +
        page +
        `&size=` +
        size
    );
  }

  teamStatuChange(id) {
    // @ts-ignore
    return this.http.put(`${environment.baseurl}/changeUserStatus?id=` + id);
  }
  planStatuChange(id: any) {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeMembershipPlanStaus?id=` + id
    );
  }
  getAllRoleList(page, size) {
    return this.http.get(
      `${environment.baseurl}/getAllRoles?page=` + page + `&size=` + size
    );
  }
  createRole(data) {
    return this.http.post(`${environment.baseurl}/addRoleTypes`, data);
  }
  getRoleByid(id) {
    return this.http.get(`${environment.baseurl}/getRoleById?roleId=` + id);
  }
  getProfileDetailsById(id) {
    return this.http.get(`${environment.baseurl}/getUserById?id=` + id);
  }
  createUser(data) {
    return this.http.post(`${environment.baseurl}/createUser`, data);
  }
  updateProfileDeatils(id, data) {
    return this.http.put(`${environment.baseurl}/userUpdate?id=` + id, data);
  }
  deleteUsersDetails(id) {
    return this.http.delete(`${environment.baseurl}/deleteUser?id=` + id);
  }
  deleteRoleDetails(id) {
    return this.http.delete(
      `${environment.baseurl}/deleteRoleById?roleId=` + id
    );
  }
  roleStatusChange(id) {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeRoleTypeStatus?roleId=` + id
    );
  }
  updateRole(id, data) {
    return this.http.put(
      `${environment.baseurl}/updateRoleDetails?roleId=` + id,
      data
    );
  }
  // job category start
  getAllCategoryList(page, size, lang, isActive) {
    return this.http.get(
      `${environment.baseurl}/getAlljobCategory?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang +
        "&isActive=" +
        isActive
    );
  }
  getAllJobCategoryDetailsList(page, size, code) {
    return this.http.get(
      `${environment.baseurl}/getJobCategoryDetailsList?isoCode=` + code
    );
  }
  getCategoryDetailsById(id) {
    return this.http.get(
      `${environment.baseurl}/getJobCategoryByid?jobCategoryId=` + id
    );
  }
  createCategory(data) {
    return this.http.post(`${environment.baseurl}/saveNewJobCategory`, data);
  }
  updateCategoryDeatils(jobCategoryId: any, data: any) {
    //@ts-ignore
    return this.http.put(
      `${environment.baseurl}/updateJobCategoryDetails?jobCategoryId=` +
        jobCategoryId,
      data
    );
  }
  jobCategoryBulkUpload(data) {
    return this.http.post(`${environment.baseurl}/uploadJobCategoryBulk`, data);
  }
  getjobCategoryReport(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getjobCategoryReport?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang
    );
  }

  // job category end
  updateCompetencyCategory(id, data) {
    return this.http.put(
      `${environment.baseurl}/updateCvaCompetencyCategory?id=` + id,
      data
    );
  }
  deleteCategoryDetails(id) {
    return this.http.delete(
      `${environment.baseurl}/deleteJobcategory?jobcategoryId=` + id
    );
  }
  categoryStatuChange(id) {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeJobCategoryStatus?jobcategoryId=` + id
    );
  }

  getAlljobIndustry(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getAlljobIndustry?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang
    );
  }
  getJobIndustryByid(id) {
    return this.http.get(
      `${environment.baseurl}/getJobIndustryByIdEdit?industryId=` + id
    );
  }
  createJobIndustry(data) {
    return this.http.post(`${environment.baseurl}/addjobIndustry`, data);
  }
  updateJobIndustryDetails(id, data) {
    return this.http.put(
      `${environment.baseurl}/updateJobIndustryDetails?jobIndustryId=` + id,
      data
    );
  }
  deleteJobIndustry(id) {
    return this.http.delete(
      `${environment.baseurl}/deleteJobIndustry?jobIndustryId=` + id
    );
  }
  changeJobIndustryStatus(id) {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeJobIndustryStatus?jobIndustryId=` + id
    );
  }

  getAllSkills(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getAllSkillSets?page=` +
        page +
        `&size=` +
        size +
        `&isoCode=` +
        lang
    );
  }
  getSkillSetById(id) {
    return this.http.get(
      `${environment.baseurl}/getSkillSetById?skillSetId=` + id
    );
  }
  createSkills(data) {
    return this.http.post(`${environment.baseurl}/addSkillSet`, data);
  }
  updateSkillSet(id, data) {
    return this.http.put(
      `${environment.baseurl}/updateSkillSet?skillSetID=` + id,
      data
    );
  }
  deleteSkillSet(id) {
    return this.http.delete(
      `${environment.baseurl}/deleteSkillSet?skillSetID=` + id
    );
  }
  changeSkillSetStatus(id) {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeSkillSetStatus?skillSetId=` + id
    );
  }

  getAllCountry(page, size) {
    return this.http.get(
      `${environment.baseurl}/getAllCountryData?page=` + page + `&size=` + size
    );
  }

  getAllCountryNew() {
    return this.http.get(`${environment.baseurl}/address/getAllCountry`);
  }

  getCountryById(id) {
    return this.http.get(
      `${environment.baseurl}/getCountryDataById?countryDataId=` + id
    );
  }

  getCountryNewById(id) {
    return this.http.get(
      `${environment.baseurl}/address/getCountryById?id=` + id
    );
  }

  createCountry(data) {
    return this.http.post(`${environment.baseurl}/addCountryData`, data);
  }

  createNewCountry(data) {
    return this.http.post(`${environment.baseurl}/address/addCountry`, data);
  }

  updateCountry(id, data) {
    return this.http.put(
      `${environment.baseurl}/updateCountry?countryId=` + id,
      data
    );
  }

  updateNewCountry(data) {
    return this.http.put(`${environment.baseurl}/address/updateCountry`, data);
  }

  deleteCountry(id) {
    return this.http.delete(
      `${environment.baseurl}/address/deleteCountryDataById?countryId=` + id
    );
  }
  changeCountryStatus(id) {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeCountryStatus?countryId=` + id
    );
  }

  /////Location  Start////

  getAllLocation(page, size) {
    return this.http.get(
      `${environment.baseurl}/address/getAllManageLocation?page=` +
        page +
        `&size=` +
        size
    );
  }
  getLocationNewById(locationId: any) {
    return this.http.get(
      `${environment.baseurl}/address/getManageLocationId?locationId=` +
        locationId
    );
  }
  createNewLocation(data: any) {
    return this.http.post(
      `${environment.baseurl}/address/manageLocation`,
      data
    );
  }
  updateLocation(data: any, locationId: any) {
    return this.http.put(
      `${environment.baseurl}/address/updateManageLocation`,
      data
    );
  }
  getAllState() {
    return this.http.get(
      `${environment.baseurl}/address/getStateByCountry?countryId=101`
    );
  }
  getAllCountryLocation() {
    return this.http.get(`${environment.baseurl}/address/getAllCountry`);
  }

  deleteLocation(locationId: any) {
    return this.http.delete(
      `${environment.baseurl}/address/deleteManageLocation?locationId=` +
        locationId
    );
  }

  //// End Location
  getAllPersonalityCategory() {
    return this.http.get(`${environment.baseurl}/getAllPersonalityCategory`);
  }
  getPersonalityTestCategoryById(id) {
    return this.http.get(
      `${environment.baseurl}/getPersonalityTestCategoryById?id=` + id
    );
  }
  updatePersonalitytestCategory(id, data) {
    return this.http.put(
      `${environment.baseurl}/updatePersonalitytestCategory?id=` + id,
      data
    );
  }
  // recruiter ocean descriptio

  getAllRecruiterocenList(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getAllRecruiterOcean?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang
    );
  }
  getRecruiterocenById(id) {
    return this.http.get(
      `${environment.baseurl}/getRecruiterOceanById?id=` + id
    );
  }
  updateRecruiterocen(id, data) {
    return this.http.post(
      `${environment.baseurl}/addRecruiterOcean?id=` + id,
      data
    );
  }
  addRecruiterocen(data) {
    return this.http.post(`${environment.baseurl}/addRecruiterOcean`, data);
  }
  // candidiate ocean description start
  getAllCandidateocenList(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getAllCandidateOcean?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang
    );
  }
  getCandidateocenById(id) {
    return this.http.get(
      `${environment.baseurl}/getCandidateOceanById?id=` + id
    );
  }
  updatCandidateocen(id, data) {
    return this.http.put(
      `${environment.baseurl}/addCandidateOcean?id=` + id,
      data
    );
  }
  addCandidateocen(data) {
    return this.http.post(`${environment.baseurl}/addCandidateOcean`, data);
  }
  //   candidiate ocean description end

  getAllQuestions(page, size) {
    return this.http.get(
      `${environment.baseurl}/getAllQuestions?page=` + page + `&size=` + size
    );
  }
  getQuestionDetailsById(id) {
    return this.http.get(
      `${environment.baseurl}/getQuestionDetailsById?qId=` + id
    );
  }
  updatePersonalityQuestion(id, data) {
    return this.http.put(
      `${environment.baseurl}/updatePersonalityQuestion?questionId=` + id,
      data
    );
  }

  getAllCompetencyCategory(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getAllCvaCompetencyCategory?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang
    );
  }
  CompetecyCategoryById(id) {
    return this.http.get(
      `${environment.baseurl}/getCvaCompetecyCategoryByIdForEdit?id=` + id
    );
  }
  // getAllCompetencyCategoryList(page, size, lang) {
  //   return this.http.get(`${environment.baseurl}/getCompetencyCategoryDetailsList?page=` + page + `&size=` + size +'&isoCode='+lang);
  // }

  deleteCometencyCategory(competencyCategoryId) {
    return this.http.delete(
      `${environment.baseurl}/deleteCompetencyCategoryById?id=` +
        competencyCategoryId
    );
  }

  changeCompetencyCategoryStaus(id) {
    return this.http.put(
      `${environment.baseurl}/changeCvaCategoryStatus?id=` + id,
      ""
    );
  }
  addCompetencyCategory(data) {
    return this.http.post(
      `${environment.baseurl}/addCvaCompetencyCategory`,
      data
    );
  }

  getCompetencyDetailsById(id) {
    return this.http.get(
      `${environment.baseurl}/getCvaCompetencyCategoryById?id=` + id
    );
  }

  getAllCompetencyKeywordList(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getCompetencyKeywordsWithCategories?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang
    );
  }

  createCompetencyKeyword(data) {
    return this.http.post(
      `${environment.baseurl}/addCvaCompetencyKeyWords`,
      data
    );
  }

  getCompetencyKeywordDetailsById(id) {
    return this.http.get(
      `${environment.baseurl}/getCompetencyKeyWordByCompetencyId?id=` + id
    );
  }

  getCompetencyKeywordDetailsByIdNew(id, lang) {
    return this.http.get(
      `${environment.baseurl}/getCompetencyKeyWordByCompetencyId?id=` +
        id +
        `&isoCode=` +
        lang
    );
  }
  getAllQuestionTypeList(page, size) {
    return this.http.get(
      `${environment.baseurl}/getAllQuestionType?page=` + page + `&size=` + size
    );
  }

  questionStatuChange(id) {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeStatusQuestionType?questionTypeId=` + id
    );
  }
  deleteQuestionType(questionTypeId) {
    return this.http.delete(
      `${environment.baseurl}/deleteQuestionType?questionTypeId=` +
        questionTypeId
    );
  }
  updateQuestionTypeDeatils(id, data) {
    return this.http.put(
      `${environment.baseurl}/updateQuestionType?questionTypeId=` + id,
      data
    );
  }
  createQuestionType(data) {
    return this.http.post(`${environment.baseurl}/addQuestionType`, data);
  }
  getQuestionTypeDetailsById(id) {
    return this.http.get(
      `${environment.baseurl}/getQuestionTypeById?questionTypeId=` + id
    );
  }

  // QUESTION GROUP START
  getAllQuestionLevelList(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getAllQuestionGroup?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang
    );
  }
  createQuestionLevel(data) {
    return this.http.post(`${environment.baseurl}/addQuestionGroup`, data);
  }
  updateQuestionGroup(groupId, data) {
    return this.http.put(
      `${environment.baseurl}/updateQuestionGroup?groupId=` + groupId,
      data
    );
  }
  getQuestionLevelDetailsById(id) {
    return this.http.get(
      `${environment.baseurl}/getQuestionGroupById?groupId=` + id
    );
  }
  deleteQuesitongroup(groupId) {
    return this.http.delete<any>(
      `${environment.baseurl}/deleteQuestionGroupById?groupId=` + groupId
    );
  }
  // QUESTION GROUP END

  //  restiction start
  getAllRestrictedKeywordList(page, size, lang) {
    return this.http.get(
      `${environment.baseurl}/getAllRestrictedKeywords?page=` +
        page +
        `&size=` +
        size +
        "&isoCode=" +
        lang
    );
  }
  createRestrictedKeyword(data) {
    return null;
    //return this.http.post(`${environment.baseurl}/addRestrictedKeywords?isBulkUpload=`+status, data);
  }
  updateRestrictedKeyword(data, status) {
    return null;
    // return this.http.put(`${environment.baseurl}/addRestrictedKeywords?isBulkUpload=`+status, data);
  }
  RestrictedKeywordBulkUpload(data, status) {
    return null;
    // return this.http.post(`${environment.baseurl}/addRestrictedKeywordsBulkUpload=`+status, data);
  }
  getRestrictedKeywordById(id) {
    return null;
    // return this.http.get(`${environment.baseurl}/getRestrictedKeywordsbyId?ID=`+id);
  }
  deleteRestrictedKeywordDetails(restrictedKeyWordId) {
    return this.http.delete(
      `${environment.baseurl}/deleteRestrictedKeywords?id=` +
        restrictedKeyWordId
    );
  }

  getAllPersonalitySelectyion(page, size) {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getPersonalitySelection`
    );
  }
  addPersonalitySelection(data) {
    return this.http.post(
      `${environment.baseurl}/jobPostMaster/addPersonalitySelection`,
      data
    );
  }
  getPersonalitySelectyionById(id) {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getPersonalitySelectionById?id=` +
        id
    );
  }
  editPersonalitySelectyion(id, data) {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/updatePersonalitySelection?id=` +
        id,
      data
    );
  }
  deletePersonalitySelection(id) {
    return this.http.delete(
      `${environment.baseurl}/jobPostMaster/deletePersonalitySelectionById?id=` +
        id
    );
  }
  userStatuChange(userId: any): any {
    // @ts-ignore
    return this.http.get(
      `${environment.baseurl}/candidate/ActiveDeactiveAnyUser?userId=` + userId
    );
  }

  deleteRecruiter(userId: any): any {
    // @ts-ignore
    return this.http.delete(
      `${environment.baseurl}/recruiter/deleteRecruiterById?id=` + userId
    );
  }

  deleteCandidate(userId: any): any {
    // @ts-ignore
    return this.http.delete(
      `${environment.baseurl}/candidate/deleteCandidate?id=` + userId
    );
  }

  getAllUserList1(page, size, state,name, MobileNo, emailId, source, district,companyName): any {
    return this.http.get(
      `${environment.baseurl}/recruiter/getAllRecruiterUser?page=` + page + `&size=` +  size +`&state=` +  state +`&name=` +  name
      +`&MobileNo=` +  MobileNo +`&emailId=` +  emailId +`&source=` +  source +`&district=` +  district + `&companyName=` + companyName
    );
  }
  getAllJobApproval(): any {
    return this.http.get(`${environment.baseurl}/subUser/getPendingJobs`);
  }

  postAllApprovalJobs(ids): any {
    return this.http.get(`${environment.baseurl}/jobPostMaster/approveAllJobPost?jobIds=`+ ids);
  }

  getAllUserList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/recruiter/getAllRecruiterUser?page=0&size=5000`
    );
  }

  getCompanyDetailsById(id): any {
    return this.http.get(
      `${environment.baseurl}/recruiter/getCompanyByUserId?id=` + id
    );
  }
  getJobByCompanyId(companyId): any {
    return this.http.get(
      `${environment.baseurl}/subUser/getJobByCompanyId?companyId=` + companyId
    );
  }
  getTeamMemberId(companyId, page, size): any {
    return this.http.get(
      `${environment.baseurl}/subUser/getTeamsByCompanyId?page=0&size=5000&companyId=` +
        companyId
    );
  }
  getPlanSubscriptionById(companyId, page, size): any {
    return this.http.get(
      `${environment.baseurl}/v1/companySubscription/getPlan?page=0&size=5000&companyId=` +
        companyId
    );
  }
  getStatisticsById(companyId): any {
    return this.http.get(
      `${environment.baseurl}/v1/companySubscription/getMonthlyUtilization?companyId=` +
        companyId
    );
  }
  getPoaDocumentByCompanyId(id): any {
    return this.http.get(
      `${environment.baseurl}/recruiter/getPoaDocumentByCompanyId?companyId=` +
        id
    );
  }

  documentStatusChangedById(id, data): any {
    // @ts-ignore
    return this.http.post(
      `${environment.baseurl}/recruiter/isPoaDocumentVerified?isVerified=` +
        data +
        `&id=` +
        id
    );
  }

  getAllICCValues(page, size): any {
    return this.http.get(`${environment.baseurl}/jobPostMaster/getIccAll`);
  }
  addICCValues(data): any {
    return this.http.post(`${environment.baseurl}/jobPostMaster/addIcc`, data);
  }
  getICCValuesById(id): any {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getIccById?id=` + id
    );
  }
  editICCValues(data): any {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/updateIcc`,
      data
    );
  }
  deleteICCValues(id): any {
    return this.http.delete(
      `${environment.baseurl}/jobPostMaster/deleteIccById?id=` + id
    );
  }

  getAllIInterviewSettings(page, size): any {
    return this.http.get(`${environment.baseurl}/getAllInterViewSettings`);
  }
  addInterviewSetting(data, id): any {
    return this.http.post(
      `${environment.baseurl}/saveInterviewQuestionSetting?settingId=` + id,
      data
    );
  }
  getInterviewSettingById(id): any {
    return this.http.get(
      `${environment.baseurl}/getInterviewQuestionSettingDetailsById?settingId=` +
        id
    );
  }
  deleteInterviewSetting(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteTheInterViewQuestionSetting?settingId=` + id
    );
  }

  cvaCategoryKeywordbuilkupload(data): any {
    return this.http.post(
      `${environment.baseurl}/bulkUploadCompetencyKeyword`,
      data
    );
  }

  getAllPlanList(id): any {
    return this.http.get(`${environment.baseurl}/getAllJobPlans?isoCode=` + id);
  }

  addJobPlan(data): any {
    return this.http.post<any>(`${environment.baseurl}/addJobPlans`, data);
  }

  updateJobPlan(id, data): any {
    return this.http.put<any>(
      `${environment.baseurl}/updateByPlanId?planId=` + id,
      data
    );
  }

  getJobPlanById(id): any {
    return this.http.get(`${environment.baseurl}/getJobPlanById?planId=` + id);
  }
  jobPlanStatusChange(id): any {
    // @ts-ignore
    return this.http.post<any>(
      `${environment.baseurl}/chnageStatus?planId=` + id
    );
  }
  deleteJobPlanDetails(id): any {
    return this.http.delete(
      `${environment.baseurl}/delteByPlanId?planId=` + id
    );
  }
  getAllJobBoardList(code): any {
    return this.http.get(
      `${environment.baseurl}/getAllJobBoard?isoCode=` + code
    );
  }
  getJobBoardById(id): any {
    return this.http.get(`${environment.baseurl}/getJobBoardById?id=` + id);
  }
  jobBoardStatusChange(id): any {
    // @ts-ignore
    return this.http.put<any>(
      `${environment.baseurl}/changeStatusJobBoard?id=` + id
    );
  }
  deleteJobBoardDetails(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteJobBoardById?id=` + id
    );
  }
  addJobBoard(data): any {
    return this.http.post<any>(
      `${environment.baseurl}/saveAddonJobBoard`,
      data
    );
  }
  updateJobBoard(id, data): any {
    return this.http.put<any>(
      `${environment.baseurl}/updateJobBoard?id=` + id,
      data
    );
  }
  updateJsonValues(data): any {
    return this.http.post<any>(
      `${environment.baseurl}/saveJsonFileToCloud`,
      data
    );
  }
  getStringJson(): any {
    return this.http.get<any>(
      `${environment.baseurl}/getJsonStriongDetails?file=en`
    );
  }
  getTeamsByCompanyIdData(infoId): any {
    return this.http.get(
      `${environment.baseurl}/subUser/getTeamsByCompanyId?companyId=` + infoId
    );
  }
  getTeamsDetailsByCompanyIdData(teamId): any {
    return this.http.get(
      `${environment.baseurl}/subUser/getRecruiterSubUserById?id=` + teamId
    );
  }
  getJobDetailsById(jobId): any {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getJobPostById?jobId=` + jobId
    );
  }

  getJobByActive(body): any {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/approveJobPost`,body
    );
  }
  getAllJobsByCompanyId(infoId, page, size): any {
    return this.http.get(
      `${environment.baseurl}/subUser/getJobsByCompanyId?companyId=` +
        infoId +
        `&page=` +
        page +
        `&size=` +
        size
    );
  }
  getAllMediaList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/adminMedia?page=` + page + `&size=` + size
    );
  }
  deleteAdminMedia(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteAdminMediaById?id=` + id
    );
  }
  addMedia(data): any {
    return this.http.post<any>(`${environment.baseurl}/adminMedia`, data);
  }
  updateMedia(data): any {
    // @ts-ignore
    return this.http.put<any>(`${environment.baseurl}/adminMediaUpdate`, data);
  }
  getMediaById(id): any {
    return this.http.get<any>(
      `${environment.baseurl}/getAdminMediaById?id=` + id
    );
  }
  changeMediaStatus(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeAdminMediaStatus?id=` + id
    );
  }
  getAllTemplateList(page, size, isoCode): any {
    return this.http.get(
      `${environment.baseurl}/emailtemplate?page=` +
        page +
        `&size=` +
        size +
        `&isoCode=` +
        isoCode
    );
  }
  saveTemplate(data): any {
    return this.http.post<any>(`${environment.baseurl}/emailtemplate`, data);
  }
  updateTeplate(data): any {
    return this.http.put<any>(`${environment.baseurl}/emailtemplate`, data);
  }
  deleteTemplate(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteTemplateById?id=` + id
    );
  }
  getTemplateDetails(id): any {
    return this.http.get(`${environment.baseurl}/emailTemplateById?id=` + id);
  }
  // Employement Type
  getAllEmploymentTypeList(page, size, isoCode): any {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getEmploymentType?page=` +
        page +
        `&size=` +
        size +
        `&isoCode=` +
        isoCode
    );
  }
  createEmploymentType(data): any {
    return this.http.post(
      `${environment.baseurl}/jobPostMaster/addEmploymentType`,
      data
    );
  }
  updateEmploymentType(id, data): any {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/updateEmploymentType?employmentTypeId=` +
        id,
      data
    );
  }
  getEmploymentTypeById(id): any {
    return this.http.get<any>(
      `${environment.baseurl}/jobPostMaster/getEmploymentTypeById?employmentTypeId=` +
        id
    );
  }
  deleteEmploymentTypeById(id): any {
    return this.http.delete(
      `${environment.baseurl}/jobPostMaster/deleteEmploymentTypeById?employmentTypeId=` +
        id
    );
  }
  updateEmploymentTypeStatus(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/changeEmploymentStatusById?employmentTypeId=` +
        id
    );
  }
  // Contract Type
  getAllContractTypeList(page, size, isoCode): any {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getContrctType?page=` +
        page +
        `&size=` +
        size +
        `&isoCode=` +
        isoCode
    );
  }
  createContractType(data): any {
    return this.http.post(
      `${environment.baseurl}/jobPostMaster/addContrctType`,
      data
    );
  }
  updateContractType(id, data): any {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/updateContrctType?contractTypeId=` +
        id,
      data
    );
  }
  getContractTypeById(id): any {
    return this.http.get<any>(
      `${environment.baseurl}/jobPostMaster/getContrctTypeById?contractTypeId=` +
        id
    );
  }
  deleteContractTypeById(id): any {
    return this.http.delete(
      `${environment.baseurl}/jobPostMaster/deleteContrctTypeById?contractTypeId=` +
        id
    );
  }
  updateContractTypeStatus(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/changeContractStatus?contractTypeId=` +
        id
    );
  }
  // Working Condition
  getAllWorkingConditionList(page, size, isoCode): any {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getWorkingConditions?page=` +
        page +
        `&size=` +
        size +
        `&isoCode=` +
        isoCode
    );
  }
  createWorkingConditions(data): any {
    return this.http.post(
      `${environment.baseurl}/jobPostMaster/addWorkingConditions`,
      data
    );
  }
  updateWorkingCondition(id, data): any {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/updateWorkingCondition?workingConditionId=` +
        id,
      data
    );
  }
  getWorkingConditionById(id): any {
    return this.http.get<any>(
      `${environment.baseurl}/jobPostMaster/getWorkingConditionById?workingConditionId=` +
        id
    );
  }
  deleteWorkingConditionById(id): any {
    return this.http.delete(
      `${environment.baseurl}/jobPostMaster/deleteWorkingConditionById?workingConditionId=` +
        id
    );
  }
  updateWorkingConditionStatus(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/changeWorkingConditionStatus?workingConditionId=` +
        id
    );
  }
  // Pay Offered
  getAllSalaryTypeList(page, size, isoCode): any {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getSalaryType?page=` +
        page +
        `&size=` +
        size +
        `&isoCode=` +
        isoCode
    );
  }
  createSalaryType(data): any {
    return this.http.post(
      `${environment.baseurl}/jobPostMaster/addSalaryType`,
      data
    );
  }
  updateSalaryType(id, data): any {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/updateSalaryType?salaryTypeId=` +
        id,
      data
    );
  }
  getSalaryTypeById(id): any {
    return this.http.get<any>(
      `${environment.baseurl}/jobPostMaster/getSalaryTypeById?salaryTypeId=` +
        id
    );
  }
  deleteSalaryTypeById(id): any {
    return this.http.delete(
      `${environment.baseurl}/jobPostMaster/deleteSalaryTypeById?salaryTypeId=` +
        id
    );
  }
  updateSalaryTypeStatus(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/changeSalaryTypeStatus?salaryTypeId=` +
        id
    );
  }
  // Benefits
  getAllBenefitsList(page, size, isoCode): any {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getBenefits?page=` +
        page +
        `&size=` +
        size +
        `&isoCode=` +
        isoCode
    );
  }
  createBenefits(data): any {
    return this.http.post(
      `${environment.baseurl}/jobPostMaster/addBenefits`,
      data
    );
  }
  updateBenefits(id, data): any {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/updateBenefit?benefitId=` + id,
      data
    );
  }
  getBenefitsById(id): any {
    return this.http.get<any>(
      `${environment.baseurl}/jobPostMaster/getBenefitById?benefitId=` + id
    );
  }
  deleteBenefitsById(id): any {
    return this.http.delete(
      `${environment.baseurl}/jobPostMaster/deleteBenefitById?benefitId=` + id
    );
  }
  updateBenefitsStatus(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/changeBenefitStatus?benefitId=` + id
    );
  }
  // Education Level
  getAllEducationList(page, size, isoCode): any {
    return this.http.get(
      `${environment.baseurl}/jobPostMaster/getEducation?page=` +
        page +
        `&size=` +
        size +
        `&isoCode=` +
        isoCode
    );
  }
  createEducationLevel(data): any {
    return this.http.post(
      `${environment.baseurl}/jobPostMaster/addEducation`,
      data
    );
  }
  updateEducation(id, data): any {
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/updateEducation?educationId=` + id,
      data
    );
  }
  getEducationById(id): any {
    return this.http.get<any>(
      `${environment.baseurl}/jobPostMaster/getEducationById?id=` + id
    );
  }
  deleteEducationById(id): any {
    return this.http.delete(
      `${environment.baseurl}/jobPostMaster/deleteEducationById?educationalId=` +
        id
    );
  }
  updateEducationStatus(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/jobPostMaster/changeEducationStatus?educationId=` +
        id
    );
  }

  // edication & Degree

  getAllEducationAndDegreeList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getAllDegreeList?page=` + page + `&size=` + size
    );
  }
  deleteEducationandDegreeById(id): any {
    return this.http.delete(`${environment.baseurl}/deleteDegreeById?id=` + id);
  }
  createEducationAndDegree(data): any {
    return this.http.post(`${environment.baseurl}/addDegreeList`, data);
  }
  updateEducationAndDegree(id, data): any {
    return this.http.put(`${environment.baseurl}/addDegreeList?id=` + id, data);
  }
  getEducationAndDegreeById(id): any {
    return this.http.get<any>(`${environment.baseurl}/getDegreeById?id=` + id);
  }
  updateEducationAndDegreeStatus(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeDegreeStatusById?id=` + id
    );
  }
  // Membership Employer Plan
  getEmployerPlanDetailsById(id): any {
    return this.http.get(
      `${environment.baseurl}/getMembershipPlanById?id=` + id
    );
  }
  updateEmployerPlanDeatils(id, data): any {
    return this.http.put(
      `${environment.baseurl}/updateMembershipPlan?id=` + id,
      data
    );
  }
  createEmployerPlan(data): any {
    return this.http.post(`${environment.baseurl}/addMembershipPlan`, data);
  }

  deleteEmployerPlanDetails(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteMembershipPlan?id=` + id
    );
  }

  getAllEmployerPlanList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getAllMembershipPlans?page=` +
        page +
        `&size=` +
        size
    );
  }
  getAllEmpPlanList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getAllMembershipPlans?page=` +
        page +
        `&size=` +
        size
    );
  }

  // Membership Candidate Plan
  getCandidatePlanDetailsById(id): any {
    return this.http.get(
      `${environment.baseurl}/getCandidateMembershipPlanById?id=` + id
    );
  }
  updateCandidatePlanDeatils(id, data): any {
    return this.http.put(
      `${environment.baseurl}/updateCandidateMembershipPlan?id=` + id,
      data
    );
  }
  createCandidatePlan(data): any {
    return this.http.post(
      `${environment.baseurl}/addCandidateMembershipPlan`,
      data
    );
  }
  getAllCandidatePlanList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getAllCandidateMembershipPlans?page=` +
        page +
        `&size=` +
        size
    );
  }
  deleteCandidatePlanDetails(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteCandidateMembershipPlan?id=` + id
    );
  }
  // End Candidate Plan Membership
  getAllCustomizePlanList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getCustomizedAllMembershipPlans?page=` +
        page +
        `&size=` +
        size
    );
  }
  getAllCompany(): any {
    return this.http.get(
      `${environment.baseurl}/recruiter/getAllRecruiterUserCompany`
    );
  }
  // Membership Candidate Plan
  getAddonPlanDetailsById(id): any {
    return this.http.get(
      `${environment.baseurl}/getCandidateOnPlanById?id=` + id
    );
  }
  updateAddonPlanDeatils(id, data): any {
    return this.http.put(
      `${environment.baseurl}/updateCandidateOnPlan?id=` + id,
      data
    );
  }
  createAddonPlan(data): any {
    return this.http.post(`${environment.baseurl}/addCandidateOnPlan`, data);
  }
  getAllAddonPlanList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getAllCandidatesOnPlan?page=` +
        page +
        `&size=` +
        size
    );
  }
  deleteAddonPlanDetails(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteCandidateOnPlan?id=` + id
    );
  }

  //// End Addon Plan Membership//////////////
  deleteContactDetails(requestId): any {
    return this.http.delete(
      `${environment.baseurl}/dashboard/deleteContactUsReq?requestId=` +
        requestId
    );
  }

  // Manage Discount Coupon
  createDiscountCoupon(data): any {
    return this.http.post(`${environment.baseurl}/addDiscount`, data);
  }
  updateDiscountCoupon(discountId, data): any {
    return this.http.put(
      `${environment.baseurl}/updateDiscount?id=` + discountId,
      data
    );
  }
  getAllDiscountCoupon(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getDiscountList?page=` + page + `&size=` + size
    );
  }
  deleteDiscountCoupon(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteByDiscountId?id=` + id
    );
  }
  getByIdDiscountCoupon(id): any {
    return this.http.get(`${environment.baseurl}/getByDiscountId?id=` + id);
  }
  teamStatuChange1(id): any {
    // @ts-ignore
    return this.http.put(
      `${environment.baseurl}/addDiscountWithOption?id=` + id
    );
  }
  //  End Manage Discount Coupon

  getCandidateRequestCV(page: any, size: any): any {
    return this.http.get(
      `${environment.baseurl}/candidate/getcandidateCV?page=` +
        page +
        `&size=` +
        size
    );
  }

  manageJobReport(jobCategoryId, cityId): any {
    return this.http.get(
      `${environment.baseurl}/subUser/getJobsByCategoryAndLocation?jobCategoryId=` +
        jobCategoryId +
        `&cityId=` +
        cityId
    );
  }
  getAllRecruiterMembership(): any {
    return this.http.get(
      `${environment.baseurl}/v1/companySubscription/getCompanyMembershipReport`
    );
  }
  getAllRecruiterMembership1(subscriptionStatus: any): any {
    return this.http.get(
      `${environment.baseurl}/v1/companySubscription/getCompanyMembershipType?subscriptionStatus=` +
        subscriptionStatus
    );
  }
  getAllCandidateMembership(): any {
    return this.http.get(
      `${environment.baseurl}/v1/companySubscription/getCandidateReport`
    );
  }
  getAllCandidateMembership1(subscriptionStatus: any): any {
    return this.http.get(
      `${environment.baseurl}/v1/companySubscription/getCandidateMembershipType?subscriptionStatus=` +
        subscriptionStatus
    );
  }
  manageJob(): any {
    return this.http.get(
      `${environment.baseurl}/subUser/getJobsByCategoryAndLocation`
    );
  }

  getAllcityByCountry(): any {
    return this.http.get(
      `${environment.baseurl}/address/getCityByCountry?countryId=` + 101
    );
  }
  getAllStateByCountry(): any {
    return this.http.get(
      `${environment.baseurl}/address/getStateByCountry?countryId=` + 101
    );
  }

  getAllDistrict(): any {
    return this.http.get(
      `${environment.baseurl}/address/getAllDistrict`
    );
  }

  // contact Us
  addUpdateConatct(data): any {
    return this.http.post(
      `${environment.baseurl}/createUpdateContactUsNew`,
      data
    );
  }
  getContactById(id): any {
    return this.http.get(`${environment.baseurl}/getContactUsNewById?id=` + id);
  }
  getAllContactDahboardList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getAllContactUsNew?page=` + page + `&size=` + size
    );
  }
  deleteContactDahboard(id): any {
    return this.http.delete(
      `${environment.baseurl}/deleteContactUsNewById?id=` + id
    );
  }

  // User policy
  addUserPolicy(data): any {
    return this.http.post(
      `${environment.baseurl}/addUpdatePrivacyPolicy`,
      data
    );
  }
  getPrivacyPolicyId(privacyPolicyId): any {
    return this.http.get(`${environment.baseurl}/getPrivacyPolicyById?privacyPolicyId=` + privacyPolicyId);
  }
  getAllPolicy(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getAllContactUsNew?page=` + page + `&size=` + size
    );
  }
  deletePolicy(privacyPolicyId): any {
    return this.http.delete(
      `${environment.baseurl}/deletePrivacyPolicy?privacyPolicyId=` + privacyPolicyId
    );
  }
  getAllPolicyList(page, size): any {
    return this.http.get(
      `${environment.baseurl}/getListPrivacyPolicy?page=` + page + `&size=` + size
    );
  }

  // Blog
  getAllBlogMedia(page, size) {
    return this.http.get(
      `${environment.baseurl}/getAllBlogMedia?page=` + page + `&size=` + size 
    );
  }

  changeBlogMediaStatusById(id) {
    //@ts-ignore
    return this.http.put(
      `${environment.baseurl}/changeBlogMediaStatusById?blogId=` + id
    );
  }

  getBlogMediaById(id) {
    return this.http.get(
      `${environment.baseurl}/getBlogMediaById?blogId=` + id
    );
  }

  deleteBlogMediaById(blogId): any {
    return this.http.delete(
      `${environment.baseurl}/deleteBlogMediaById?blogId=` + blogId
    );
  }

  addUpdateBlogMedia(data) {
    return this.http.post(`${environment.baseurl}/addUpdateBlogMedia`, data);
  }
  dailylimit(data): any {
    return this.http.post<any>(`${environment.baseurl}/addUpdateApplicationLimit`, data);
  }
  getdailylimit(){
    return this.http.get(`${environment.baseurl}/getApplicationLimit` )
  }

  getResumeReboosted(page, size): any {
    return this.http.get(
      `${environment.baseurl}/candidate/getResumeReboostedCandidate?page=` + page +
        `&size=` + size
    );
  }

  //add By Vivek
  getAllStateNew() {
    return this.http.get(
      `${environment.baseurl}/address/getAllState`
    );
  }
  getStateByCountry(id:number): any {
    return this.http.get(
      `${environment.baseurl}/address/getStateByCountry?countryId=` + id
    );
  }
  getDistrictByState(id:number): any {
    return this.http.get(
      `${environment.baseurl}/address/getDistrictBystateId?stateId=` + id
    );
  }

  getBlockCityByDistrict(id:number): any {
    return this.http.get(
      `${environment.baseurl}/address/getBlockCityByDistrict?districtId=` + id
    );
  }
  getAllCity1(): any {
    return this.http.get(
      `${environment.baseurl}/address/getAllCity`
    );
  }

  getAllStateNew1(): any {
    return this.http.get(
      `${environment.baseurl}/address/getAllState`
    );
  }

  createNewState(data: any) {
    return this.http.post(
      `${environment.baseurl}/address/manageState`,data
    );
  }

  getStateById(id) {
    return this.http.get(
      `${environment.baseurl}/address/getStateById?id=` + id
    );
  }
  getAllZone() {
    return this.http.get(
      `${environment.baseurl}/address/getAllZone`
    );
  }

  createZone(data: any) {
    return this.http.post(
      `${environment.baseurl}/address/manageZone`,data
    );
  }

  getZoneById(id) {
    return this.http.get(
      `${environment.baseurl}/address/getZoneById?id=` + id
    );
  }

  getZoneByCountryId(id) {
    return this.http.get(
      `${environment.baseurl}/address/getZoneByCountryId?id=` + id
    );
  }

  getAllManageDistrict(districtName:string,page: number, size: number) {
    return this.http.get(
      `${environment.baseurl}/address/getAllManageDistrict?districtName=`+districtName+`&page=` +
        page +
        `&size=` +
        size
    );
  }

  getStateByZone(id) {
    return this.http.get(
      `${environment.baseurl}/address/getStateByZone?id=` + id
    );
  }
  createDistrict(data: any) {
    return this.http.post(
      `${environment.baseurl}/address/manageDistrict`,data
    );
  }

  getdistrictById(id) {
    return this.http.get(
      `${environment.baseurl}/address/getdistrictById?id=` + id
    );
  }

  getAllManageCity(cityName:string,page: number, size: number) {
    return this.http.get(
      `${environment.baseurl}/address/getAllManageCity?cityName=`+cityName+`&page=` +
        page +
        `&size=` +
        size
    );
  }

  createCity(data: any) {
    return this.http.post(
      `${environment.baseurl}/address/manageCity`,data
    );
  }

  getCityById(id) {
    return this.http.get(
      `${environment.baseurl}/address/getCityById?id=` + id
    );
  }
}
