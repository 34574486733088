<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">Edit</span>
        Job Plan
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listJobPlans']">List Of Job Plans</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!viewID">Add New</span>
            <span *ngIf="viewID">Edit</span>
            Job Plan</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="formGroup" appInvalidControlScroll>
        <!-- Plan Name -->
        <div class="row">
          <div *ngFor="let ticket of planNameArray.controls; let i = index" class="form-group col-sm-4">
            <div [formGroup]="ticket" style="width: 100%;">
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Plan Name </label>
                <div class="col-sm-12 ">
                  <input type="text" 
                  [ngClass]="{ 'is-invalid': submitted && ticket.get('planName').errors }"
                  class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                    placeholder="Enter Plan Name" name="planName" formControlName="planName">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Plan Description </label>
                <div class="col-sm-12 ">
                  <angular-editor formControlName="planDescription" placeholder="Please Enter Plan Description" [config]="config"
                    name="planDescription"></angular-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Plan Description -->
        <!-- <div class="row">
          <div *ngFor="let ticket of pd.controls; let i = index" class="form-group col-sm-6">
            <div [formGroup]="ticket" style="width: 100%;">
              <div class="form-group row">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Plan Description </label>
                <div class="col-sm-12 ">
                  <angular-editor formControlName="value" placeholder="Please Enter Plan Description" [config]="config"
                    name="value"></angular-editor>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Currency -->
        <div class="row">
          <div *ngFor="let ticket of currency.controls; let i = index" class="form-group col-sm-3">
            <div [formGroup]="ticket" style="width: 100%;">
              <div class="form-group row">
                <label class="col-sm-12">
                  Plan Price in {{ticket.get('code').value}}({{ticket.get('symbol').value}}) </label>
                <div class="col-sm-12 ">
                  <input type="number" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                    placeholder="Enter Plan Price" name="value" formControlName="value">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Feature -->
        <div class="">
          <div class="d-flex row label_flex">
            <label class="col-sm-3" *ngFor="let b of LangList" >
              {{b.languageName}} Feature 
            </label>
            <label class="col-sm-2"><span class="">service type</span></label>
            <label class="col-sm-1"></label>
          </div>

          <div *ngFor="let ticket of t.controls; let i = index">
            <div [formGroup]="ticket" class="row align-items-center">
              <div class="d-flex row align-items-center" *ngFor="let data of ticket.controls?.featureLangList.controls; let j = index"
                class="form-group col-sm-3" style=" padding: 0 15px;">
                <div [formGroup]="data" style="width: 100%;">
                  <div class="form-group row">
                    <!-- <label class="col-sm-12">
                      {{LangList[j].languageName}} Feature {{i+1}}</label> -->
                    <div class="col-sm-12 ">
                      <input type="text" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                        placeholder="Enter plan feature" name="featureName" formControlName="featureName"
                        required>
                    </div>
                  </div>
                 
                </div>
              </div>
              <!-- <div style="padding: 0 15px;" style="display: none;">
                <mat-checkbox class="example-margin" formControlName="isLink" [checked]="isLinkeCheck" >Is Link
                </mat-checkbox>
              </div> -->
              
              <div class="form-group col-sm-2" style=" padding: 0 15px; margin-top: -14px;">
                <!--  *ngIf="ticket.get('isLink').value == true" <label class="col-sm-12">Service Type</label> -->
                <div class="">
                  <mat-select 
                    formControlName="serviceType" required placeholder="Please Select serviceType" class="form-control">
                    <mat-option value="0" aria-label="0">
                  None
                    </mat-option>
                    <mat-option value="SOCIAL_MEDIA" aria-label="Social media">
                      Social media
                    </mat-option>
                    <mat-option value="JOB_BORAD" aria-label="PERCENTAGE">
                      Job Boards
                    </mat-option>
                    <mat-option value="XML_FEEDS" aria-label="Social media">
                      XML Feeds
                    </mat-option>
                  </mat-select>
                  <!-- <mat-error>
                    <span *ngIf="!dynamicForm.get('serviceType').valid && dynamicForm.get('serviceType').touched">
                      Please Select Service Type </span>
                  </mat-error> -->
                </div>
              </div>
              <div class=" col-sm-1 " *ngIf="!(i == 0 || i ==1)">
                <i class="fas fa-trash" style="margin-top: -20px;
                cursor: pointer;
                color: #e85975;
                display: inherit;" (click)="deleteOption(i)"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 justify-content-end d-flex" *ngIf="addBtnHide == false">
            <button type="button" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="newOptionadd()">
              <i class="fa fa-plus" aria-hidden="true"></i>Add New feature
            </button>
          </div>
        </div>
        <button [routerLink]="['/listJobPlans']" type="button"
          class="btn btn-danger  pd-x-20 mg-t-10 mar15">Back</button>
        <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="!viewID">Submit</button>
        <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="viewID">Update</button>
      </form>
    </div>
  </div>
</div>