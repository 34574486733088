<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
                <span *ngIf="!viewID">Add New</span>
                <span *ngIf="viewID">Edit</span> ICC
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item ">
                    <span [routerLink]="['/list-iccvalues']">List Of ICC</span>
                </li>
                <li class="breadcrumb-item active">
                    <span>
            <span *ngIf="!viewID">Add New</span>
                    <span *ngIf="viewID">Edit</span> ICC
                    </span>
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body" style="padding: 0;">

            <div class="" style="padding: 1.25em;">
                <form [formGroup]="formGroup">
                    <div class="row">

                        <div class="form-group row col-sm-12">
                            <div class="form-group row col-sm-6">
                                <div class="form-group row col-sm-12">
                                    <label class="col-sm-12">Job Category</label>
                                    <div class="col-sm-12 ">
                                        <mat-select (openedChange)="myInput.focus()" (closed)="myInput.value='';search(myInput.value)" (selectionChange)="jobcatSelection($event)" [disabled]="viewID" [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobCategoryId').errors }" formControlName="jobCategoryId"
                                            required placeholder="Please select job category" class="form-control">
                                            <input class="myInput form-control" #myInput focused="'true'" type="text" (keydown)="$event.stopPropagation()" (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                                            <!-- <mat-select-filter [array]="variables" (filteredReturn)="filteredList1 =$event"></mat-select-filter> -->
                                            <mat-option *ngFor="let t of jobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                                                {{t.displayName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>
                                            <span *ngIf="!formGroup.get('jobCategoryId').valid && formGroup.get('jobCategoryId').touched">
                        Please select job category </span>
                                        </mat-error>
                                        <mat-error *ngIf="JobQuniError == true">
                                            <div>Please Select other Category</div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row col-sm-6">
                                <div class="form-group row col-sm-12">
                                    <label class="col-sm-12">Job Sub Category</label>
                                    <div class="col-sm-12 ">
                                        <mat-select (openedChange)="myInputsubcat.focus()" (closed)="myInputsubcat.value='';subcatsearch(myInput.value)" [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobSubCategoryId').errors }" formControlName="jobSubCategoryId" required placeholder="Please select job subcategory"
                                            class="form-control" [multiple]="!viewID" [disabled]="viewID">

                                            <input class="myInput form-control" #myInputsubcat focused="'true'" type="text" (keydown)="$event.stopPropagation()" (keyup)="subcatsearch($event.target.value)" autocomplete="off" placeholder="search">
                                            <!-- <mat-select-filter [array]="variables" (filteredReturn)="filteredList1 =$event"></mat-select-filter> -->
                                            <mat-option *ngFor="let t of subjobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                                                {{t.displayName}}
                                            </mat-option>
                                        </mat-select>

                                        <!-- <mat-select (openedChange)="myInput.focus()" (closed)="myInput.value='';search(myInput.value)"
                      [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobSubCategoryId').errors }"
                      formControlName="jobSubCategoryId" required placeholder="Please select job subcategory"
                      class="form-control" *ngIf="viewID">

                      <input class="myInput form-control" #myInput focused="'true'" type="text"
                        (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                      <mat-option *ngFor="let t of subjobCatList" [value]="t.jobCategoryId"
                        [aria-label]="t.jobCategoryId">
                        {{t.displayName}}
                      </mat-option>
                    </mat-select> -->

                                        <mat-error>
                                            <span *ngIf="!formGroup.get('jobSubCategoryId').valid && formGroup.get('jobSubCategoryId').touched">
                        Please select job sub category </span>
                                        </mat-error>
                                        <mat-error *ngIf="JobQuniError == true">
                                            <div>Please Select other SubCategory</div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row col-sm-12 icc_dive">
                            <div class="form-group row col-sm-6">
                                <div class="form-group row col-sm-12">
                                    <label class="col-sm-12">CV(%)</label>
                                    <div class="col-sm-12">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Enter CV </mat-label>
                                            <input matInput type="text" value="" placeholder="Enter cv" name="cv" formControlName="cv" (keyup)="numberOnly($event)" maxlength="2" required>
                                        </mat-form-field>
                                        <mat-error>
                                            <span *ngIf="!formGroup.get('cv').valid && formGroup.get('cv').touched">
                        Please enter cv</span>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row col-sm-6">
                                <div class="form-group row col-sm-12">
                                    <label class="col-sm-12">Personality(%)</label>
                                    <div class="col-sm-12">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Enter Personality </mat-label>
                                            <input matInput type="text" value="" placeholder="Enter personality" name="personality" formControlName="personality" maxlength="2" (keyup)="numberOnly($event)" required>
                                        </mat-form-field>
                                        <mat-error>
                                            <span *ngIf="!formGroup.get('personality').valid && formGroup.get('personality').touched">
                        Please enter personality</span>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row col-sm-12 icc_dive">
                            <div class="form-group row col-sm-6">
                                <div class="form-group row col-sm-12">
                                    <label class="col-sm-12">Pre Employeement Test(%)</label>
                                    <div class="col-sm-12">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Enter Pre Employeement Test </mat-label>
                                            <input matInput type="text" value="" placeholder="Enter pre employeement test" name="preEmploymentTest" maxlength="2" formControlName="preEmploymentTest" (keyup)="numberOnly($event)" required>
                                        </mat-form-field>
                                        <mat-error>
                                            <span *ngIf="!formGroup.get('preEmploymentTest').valid && formGroup.get('preEmploymentTest').touched">
                        Please enter preemployeementtest</span>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row col-sm-6">
                                <div class="form-group row col-sm-12">
                                    <label class="col-sm-12">Interview(%)</label>
                                    <div class="col-sm-12">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Enter interview </mat-label>
                                            <input matInput type="text" value="" placeholder="Enter interview" name="interview" formControlName="interview" maxlength="2" (keyup)="numberOnly($event)" required>
                                        </mat-form-field>
                                        <mat-error>
                                            <span *ngIf="!formGroup.get('interview').valid && formGroup.get('interview').touched">
                        Please enter interview</span>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row col-sm-12 icc_dive">
                            <div class="form-group row col-sm-6">
                                <div class="form-group row col-sm-12">
                                    <label class="col-sm-12">Emotions(%)</label>
                                    <div class="col-sm-12">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Enter emotions </mat-label>
                                            <input matInput type="text" value="" placeholder="Enter emotion" name="emotion" formControlName="emotion" maxlength="2" (keyup)="numberOnly($event)" required>
                                        </mat-form-field>
                                        <mat-error>
                                            <span *ngIf="!formGroup.get('emotion').valid && formGroup.get('emotion').touched">
                        Please enter emotion</span>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!viewID">Submit</button>
                    <!-- <button (click)="onSubmit('1')" type="submit" class="btn btn-success pd-x-20 mg-t-10 mar15" >Submit & Next</button> -->
                    <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="viewID">Update</button>
                </form>
                <!-- <div>
          <button mat-raised-button (click)="openContentOne('1')"> go Back content 1</button>
        </div> -->
            </div>

            <!-- *ngIf="firstFormSubmited == true" -->

        </div>

    </div>


</div>