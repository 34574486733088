<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">Daily Job Limit</h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <!-- <li class="breadcrumb-item " [routerLink]="['/contact-us']">Manage Contact Us List</li> -->
                <li class="breadcrumb-item active">
                    <!-- <span >Add </span>
                    <span >Edit </span> Conatct Us </li> -->
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="rewardSettingForm" >
                <div class="row">

                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">Daily Job Limit </label>
                        <div class="col-sm-12 ">
                            <input type="text" class="form-control"  placeholder="Enter Daily Job Limit " formControlName="applicationLimit" >
                           
                        </div>
                    </div>
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Landline Number </label>
                        <div class="col-sm-12 ">
                            <input type="text" class="form-control" value="" placeholder="Enter Landline Number" name="landline" formControlName="landline" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('landline').valid && formGroup.get('landline').touched">
                                    Please Enter landline Number</span>
                            </mat-error>

                        </div>
                    </div> -->
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12"> Tollfree Number</label>
                        <div class="col-sm-12 ">
                            <input type="text" class="form-control" value="" placeholder="Enter Tollfree Number" name="tollFreeNo" formControlName="tollFreeNo" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('tollFreeNo').valid && formGroup.get('tollFreeNo').touched">
                                    Please Enter Tollfree Number </span>
                            </mat-error>
                        </div>
                    </div> -->
                </div>
                <div class="">
                    <!-- <button type="submit" class="btn btn-danger pd-x-20 mg-t-10 mar15" routerLink="/contact-us" tabindex="0">Back</button> -->
                    <button  type="submit" (click)="submit()"class="btn btn-main-primary pd-x-20 mg-t-10" >SUBMIT</button>
                    <!-- <button  type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" >UPDATE</button> -->
                </div>
            </form>
        </div>
    </div>
</div>
