<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
        <h3 class="page-title">Manage Contract Type</h3>
        <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item active">Manage Contract Type</li>
        </ol>
    </div>
</div> 
      
<!--Page Content-->
    <div class="col-sm-12">
        <div class="card">
          <div class="c_table">
            <div class="mat-elevation-z8">
              <div class="d-flex table-responsive p-3">
                <div class="mr-2">
                  <div class="btn btn-primary btn-sm rounded-lg fs-13" [routerLink]="['/addContractType']">
                    <span>
                     <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                      Add</span>
                  </div>
                </div> 
                <div class="mr-2 col-sm-2">
                  <mat-select [ngModel]="selectLang"
                  (selectionChange)="LangChange($event)"    
                  class="form-control" >
                    <mat-option *ngFor="let r of langList"      
                                [value]="r.languageCode" [aria-label]="r.languageCode">
                    {{r.languageName}}
                    </mat-option>
                </mat-select>
                </div>
                <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                  <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                </div>
              </div>
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="srno">
                  <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                  <td mat-cell *matCellDef="let element;let j = index">
                    {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="contractType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract Type</th>
                    <td mat-cell *matCellDef="let element">{{element.contractType}}</td>
                  </ng-container>
                <ng-container matColumnDef="isActive">
                  <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span>
                    <mat-slide-toggle name="isActive"  (change)="changeStatus($event,element)" [checked]="element.isActive"></mat-slide-toggle>
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                    <div class="d-flex justify-content-center">
                        <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/editContractType', element.contractTypeId]">                            
                        <img src="../../../../assets/img/eye.svg" alt="eye">
                        </span>
                        <span  class="btn btn-sm btn-danger rounded-lg" (click)="delete(element.contractTypeId)"><i
                        class="fas fa-trash"></i></span>
                    </div>
                  </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>
            </div>
          </div> 
        </div> 
  </div>
  
