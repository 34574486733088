<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!contractTypeId">Add </span>
        <span *ngIf="contractTypeId">Edit </span>Contract Type
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listContractType']">Contract Type List</li>
        <li class="breadcrumb-item active"><span *ngIf="!contractTypeId">Add </span><span *ngIf="contractTypeId">Edit
          </span> Contract Type </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <form [formGroup]="dynamicForm">
        <div class="row">
          <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-6">
            <div [formGroup]="ticket" class="col-sm-12" style="width: 100%;">
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Contract Type Name </label>
                <div class="col-sm-12 ">
                  <input type="text" 
                  [ngClass]="{ 'is-invalid': submitted && ticket.get('contractType').errors }"
                  class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                    placeholder="Enter Contract Type name" name="contractType" formControlName="contractType" required>
                </div>
                <mat-error>
                  <span *ngIf="!ticket.get('contractType').valid && ticket.get('contractType').touched">
                    Please enter contractType</span>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Add Other Field</label>
            <div class="col-sm-12 ">
              <mat-radio-group aria-label="Select an option" formControlName="value">
                <mat-radio-button value="1" [checked]="radioValue == 1">Yes</mat-radio-button>
                <mat-radio-button value="0" [checked]="radioValue == 0">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="">
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()"
            *ngIf="!contractTypeId">Submit</button>
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()"
            *ngIf="contractTypeId">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>