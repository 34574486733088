<div>
    <div class="row">
        <div class="col-12 mar15 mt-4">
            <h3 class="">Job Rejection</h3>
        </div>
    </div>
    <div class="popupBody">
        <div class="form-group ">
            <form [formGroup]="formGroup">
                <label class="">Comment</label>
                <div class="">
                    <textarea type="text" rows="5" [ngClass]="{ 'is-invalid': submitted && formGroup.get('comment').errors }"
                            class="form-control" value="" formControlName="comment" required placeholder="Enter Job rejection comment" name="comment">
                    </textarea>
                    <mat-error>
                        <span *ngIf="(submitted && formGroup.get('comment').errors) || !formGroup.get('comment').valid && formGroup.get('comment').touched">
                            Please Enter Comment</span>
                    </mat-error>
                </div>
            </form>
        </div>
    </div>
    <div class="popupFooter" mat-dialog-actions>
      <div>
        <button class="btn mar15" (click)="submit()">Confirm</button>
        <button class="btn-danger" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
  