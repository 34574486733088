<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!parentId">Add </span>
        <span *ngIf="parentId">Edit</span>
        job Comprehension question
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listjobTestQuestion']">List Of Comprehension question for</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!parentId">Add New</span>
            <span *ngIf="parentId">Edit</span>
            job Comprehension question</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->

  <div class="">
    <mat-sidenav-container class="example-container" hasBackdrop="true">
      <mat-sidenav-content>

    <div class="">
      <div class="card mb-4" *ngIf="dataSource.data.length > 0">
        <div class="card-body">
          <div>
            <label class="main-content-label col-sm-12">SubQuestion List </label>
            <div style="">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                         The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="srno">
                  <th width="80px" mat-header-cell *matHeaderCellDef> sr .No. </th>
                  <td mat-cell *matCellDef="let element ;let j = index">
                    {{j+1}}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="question">
                  <th mat-header-cell *matHeaderCellDef> Question </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="questionTitleREmove" [innerHtml]="element.question"> </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="createdEpochTime">
                  <th width="180px" mat-header-cell *matHeaderCellDef> Created Date</th>
                  <td width="180px" mat-cell *matCellDef="let element ;let j = index">
                    {{element.createdEpochTime | date:'medium'}}
                  </td>
                </ng-container>

                
                <!-- Symbol Column -->
                <ng-container matColumnDef="action">
                  <th width="80px" mat-header-cell *matHeaderCellDef> action </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex justify-content-center">
                      <span class="btn btn-sm btn-primary rounded-lg" (click)="patchChild(element.assesmentQuestionId)">
                        <img src="../../../../assets/img/eye.svg" alt="eye">
                      </span>
                      <span class="btn btn-sm btn-danger rounded-lg mar15"
                        (click)="deleteQuestion(element.assesmentQuestionId)">
                        <i class="fas fa-trash"></i>
                      </span>
                    </div>

                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

            </div>

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="col-sm-12 row justify-content-between">
            <div class="main-content-label">Add new SubQuestion</div>
            <button class="btn mar15 btn-main-primary border-radius16" (click)="sidenav.toggle()">
              preview Parent Question
            </button>
          </div>
         
          <form [formGroup]="formGroup" appInvalidControlScroll>
            <div class="row">
              <div class="form-group row col-sm-12">
                <label class="col-sm-12">Question</label>
                <div class="col-sm-12" [ngClass]="{'is-invalid': submitted && formGroup.get('question').errors }">
                  <angular-editor class="mrin  questionTitleREmove" (oninput)="removeAtt($event)" (focus)="removeAtt($event)"
                    (blur)="removeAtt($event)" (paste)="removeAtt($event)" (keypress)="removeAtt($event)"
                    formControlName="question" required
                    [ngClass]="{'is-invalid': submitted && formGroup.get('question').errors }"
                    placeholder="Please Enter Question" [config]="config" name="question"></angular-editor>
                  <mat-error>
                    <span *ngIf="!formGroup.get('question').valid && formGroup.get('question').touched">
                      Please Enter Question</span>
                  </mat-error>
                </div>
              </div>
              <div class="form-group row col-sm-12">
                <label class="col-sm-3">Image</label>
                <div class="col-sm-9 row">
                  <div class="col-sm-3">
                    <label for="imgquestion" class="custom-file-upload">
                      <i class="fa fa-cloud-upload"></i> Custom Upload
                    </label>
                    <input type="file" name="imgquestion" formControlName="questionImage" multiple=""
                      accept="image/x-png,image/jpeg" class="imageUploadcss" id="imgquestion"
                      (change)="fileChangeEvent($event)" />
                  </div>
                  <div class="col-sm-9 row" *ngIf="images.length > 0 "
                    style="background-color: rgba(28, 117, 188, 0.1098039216);">
                    <div *ngFor='let url of images;let i = index;' class="uplodedImg col-sm-3 postiion-relative">
                      <div class="close" (click)="removeUploadedImages(i)"><i class="fas fa-times-circle"></i></div>
                      <img [src]="url" height="150">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" *ngIf="GetTimeQuestionImagechild.length > 0">
                <div class="row" style="background-color: rgba(28, 117, 188, 0.1098039216);">
                  <div *ngFor='let url of GetTimeQuestionImagechild;let i = index;'
                    class="uplodedImg col-sm-3 postiion-relative">
                    <div class="close" (click)="removeGetImage(url.id , i)"><i class="fas fa-times-circle"></i></div>
                    <img [src]="url.assesmentQuestionImage" height="150">
                  </div>
                </div>
              </div>

              <div class="form-group row col-sm-12">
                <label class="col-sm-12">Question's answer Explanation</label>
                <div class="col-sm-12 ">
                  <angular-editor class="mrin questionTitleREmove"  (oninput)="removeAtt($event)" (focus)="removeAtt($event)"
                    (blur)="removeAtt($event)" (paste)="removeAtt($event)" (keypress)="removeAtt($event)"
                  value=""
                    placeholder="Enter Question answer Explanation" name="answerExplanation"
                    formControlName="answerExplanation" 
                 
                     [config]="config"></angular-editor>
                     <!-- [ngClass]="{ 'is-invalid': submitted && !(thumbImageURL) && formGroup.get('answerExplanation').errors }" 
                     [required]="typeSelect == false || ! (thumbImageURL == null || thumbImageURL == '' || thumbImageURL == undefined)"    <mat-error>
                    <span
                      *ngIf="!thumbImageURL && !formGroup.get('answerExplanation').valid && formGroup.get('answerExplanation').touched">
                      Enter Question answer Explanation</span>
                  </mat-error> -->
                </div>
              </div>
              <!-- ThumbImage -->
              <div class="form-group row col-sm-12">
                <label class="col-sm-3">Answer Uploaded Images</label>
                <div class="col-sm-9 row align-items-center">
                  <div class="col-sm-6">
                    <label for="thumbImage" class="custom-file-upload">
                      <i class="fa fa-cloud-upload"></i> Answer Upload
                    </label>
                    <input type="file" name="thumbImage" #file2 accept="image/x-png" class="imageUploadcss"
                      #attachments2 id="thumbImage" (change)="AnswerfileChangeEvent($event,file2.files)" />
                  </div>
                  <div class="col-sm-6" *ngIf="thumbImageURL">
                    <div class="close" (click)="thumbImageReset()"><i class="fas fa-times-circle"></i></div>
                    <div class="image-preview">
                      <img [src]="thumbImageURL" height="100" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-12">
            <div [formGroup]="ticket" class="col-sm-12 row align-items-center" style="width: 100%;">
              <div class="form-group row col-sm-11">
                <label class="col-sm-12">
                  option{{i+1}} </label>
                <div class="col-sm-12 ">
                  <input  type="text"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && ticket.get('response').errors }"
                          (keydown)="firstSpaceRemoveValid($event)"
                          value="" placeholder="Enter option" name="response"
                          formControlName="response" required>
                  <mat-error>
                            <span *ngIf="submitted && ticket.get('response').errors">
                              Please Enter Option Name </span>
                  </mat-error>
                </div>
              </div>
              <div class="check-top row col-sm-1 ">
                <mat-checkbox class="example-margin"
                              [ngClass]="{'is-invalid': CorrecTanswerNotSlect == true}"
                              formControlName="valid"
                              [checked]="CorrectAnserArray[i] == true"
                              (change)="onCheckboxChange($event,i)"
                >
                </mat-checkbox>
    
              </div>
            </div>
          </div>
          <mat-error *ngIf="CorrecTanswerNotSlect == true">
            Please Select Any one CheckBox
          </mat-error> -->

              <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-12">
                <div [formGroup]="ticket" class="col-sm-12 row align-items-center" style="width: 100%;">
                  <div class="form-group row col-sm-10">
                    <label class="col-sm-12">
                      option{{i+1}} </label>
                    <div class="col-sm-12 optioneditor"> 
                      <!-- <angular-editor [ngClass]="{ 'is-invalid': submitted && ticket.get('response').errors }"
                        
                      
                      (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter option" name="response"
                        id="optinda{{i}}" formControlName="response" [required]="typeSelect == false" class="mrin"
                        (oninput)="firstSpaceRemoveValid($event)" (focus)="removeAtt($event)" (blur)="removeAtt($event)"
                        (paste)="removeAtt($event)" (keypress)="firstSpaceRemoveValid($event)" [required]="typeSelect == false"
                        [config]="optionconfig">
                      </angular-editor> -->
                      <input type="text" class="form-control customInput"
                            [ngClass]="{ 'is-invalid': submitted && ticket.get('response').errors }"
                            (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter option" name="response"
                            id="optinda{{i}}" formControlName="response" [required]="typeSelect == false">
                      <mat-error>
                        <span *ngIf="submitted && ticket.get('response').errors">
                          Please Enter Option Name </span>
                      </mat-error>
                    </div>
                  </div>
                  <div class=" col-sm-1 ">
                    <mat-checkbox class="example-margin" [ngClass]="{'is-invalid': CorrecTanswerNotSlect == true}"
                      formControlName="valid" [checked]="CorrectAnserArray[i] == true"
                      (change)="onCheckboxChange($event,i)">
                    </mat-checkbox>

                  </div>
                  <div class=" col-sm-1 " *ngIf="!(i == 1 || i == 0)">
                    <i class="fas fa-trash" (click)="deleteOption(i)"></i>
                  </div>
                </div>
              </div>
              <mat-error *ngIf="CorrecTanswerNotSlect == true">

              </mat-error>
              <div class="col-sm-12 justify-content-end d-flex" *ngIf="addBtnHide == false">
                <button type="button" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="newOptionadd()">
                  <i class="fa fa-plus" aria-hidden="true"></i>Add New Option
                </button>
              </div>
            </div>

            <button [routerLink]="['/listjobTestQuestion']" type="button"
              class="btn btn-danger  pd-x-20 mg-t-10 mar15">Back</button>
            <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
              *ngIf="!childId">Submit</button>
            <button (click)="onSubmit('1')" type="submit" class="btn btn-success pd-x-20 mg-t-10 mar15">
              <span *ngIf="!childId">Submit</span> <span *ngIf="childId">Update</span> & Next</button>
            <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
              *ngIf="childId">Update</button>
          </form>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
  <mat-sidenav #sidenav position="end" mode="over" fixedTopGap="0" fixedBottomGap="0"
    fixedInViewport="true">
    <div class="">
      <div class="card">
        <div class="card-body">
          <div class="mt-1 product-spec">
            <div class="d-flex justify-content-between ">
              <label class="main-content-label">Parent Question</label>
              <div class="">
                <span class="btn btn-sm btn-primary rounded-lg" [routerLink]="['/editJobTestQuestion',parentId ]">
                  <img src="../../../../assets/img/eye.svg" alt="eye">
                </span>
              </div>
            </div>

            <div class="pro_detail">
              <ul class="list-unstyled mb-0">
                <li class="row border-bottom-0">
                  <div class="col-sm-3 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">Language Name</div>
                  <div class="col-sm-9 mb-2">{{selectedLangName}}</div>
                </li>
                <li class="row border-bottom-0">
                  <div class="col-sm-3 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2"> question Type </div>
                  <div class="col-sm-9 mb-2">Comprehension</div>
                </li>
                <li class="row border-bottom-0">
                  <div class="col-sm-3 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2"> Group Type</div>
                  <div class="col-sm-9 mb-2">{{questGroupName}}</div>
                </li>
                <li class="row border-bottom-0">
                  <div class="col-sm-3 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">question</div>
                  <div class="col-sm-9 mb-2">
                    <span [innerHtml]="question"></span>
                  </div>
                </li>
                <li class="row border-bottom-0">
                  <div class="col-sm-3 tx-gray-600 tx-12 tx-medium tx-uppercase mb-2">question image</div>
                  <div class="col-sm-9 mb-2" *ngFor="let b of GetTimeQuestionImage ">
                    <img [src]="b.assesmentQuestionImage" alt="parent question" height="200px">
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </mat-sidenav>
</mat-sidenav-container>

  </div>



  <br>



</div>