import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-test-creation-pipe-line',
  templateUrl: './test-creation-pipe-line.component.html',
  styleUrls: ['./test-creation-pipe-line.component.scss']
})
export class TestCreationPipeLineComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  QGData: any;
  
  constructor() { }

  ngOnInit(): void {
  }

  questionCreate(e){
    // const d = e.value;
    // this.jobpostQuestionTestService.getQuestionListByGroupId(this.page, this.size,this.selectLang, d).subscribe( res=>{
    //   this.QGData = res;
    //   this.dataSource = new MatTableDataSource(this.QGData.response.dataSet);
      
    // });
    
  
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  
  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }
  
}
