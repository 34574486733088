<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
                <span *ngIf="!viewID">Add New</span>
                <span *ngIf="viewID">Edit</span> Blog
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item ">
                    <span [routerLink]="['/blog-list']">List Of Blog</span>
                </li>
                <li class="breadcrumb-item active">
                    <span>
              <span *ngIf="!viewID">Add New</span>
                    <span *ngIf="viewID">Edit</span> Blog
                    </span>
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12">Blog Title<span>*</span></label>
                        <div class="col-sm-12 ">
                            <mat-select formControlName="blogTitle" required [ngClass]="{ 'is-invalid': submitted && formGroup.get('blogTitle').errors }" placeholder="Please Select Blog Title" class="form-control">
                                <mat-option *ngFor="let r of allMediaType" [value]="r.value" [aria-label]="r.value">
                                    {{r.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span *ngIf="!formGroup.get('blogTitle').valid && formGroup.get('blogTitle').touched">
                                    Please Enter Blog Title</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12">Heading<span>*</span></label>
                        <div class="col-sm-12 ">
                            <input type="text" class="form-control" value="" placeholder="Enter Heading" name="response" formControlName="heading">
                            <mat-error>
                                <span *ngIf="!formGroup.get('heading').valid && formGroup.get('heading').touched">
                                    Please Enter Heading</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12">Sub Heading<span>*</span></label>
                        <div class="col-sm-12 ">
                            <input type="text" class="form-control" value="" placeholder="Enter Sub Heading" name="response" formControlName="subHeading">
                            <mat-error>
                                <span *ngIf="!formGroup.get('subHeading').valid && formGroup.get('subHeading').touched">
                                    Please Enter Sub Heading</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12">Description<span>*</span></label>
                        <div class="col-sm-12 ">
                            <angular-editor formControlName="description" placeholder="Please Enter Description" [config]="config"
                                name="description"></angular-editor>
                            <!-- <textarea type="text" class="form-control" rows="5" placeholder="Enter Description" formControlName="description"></textarea> -->
                            <!-- <input type="text" class="form-control" value="" placeholder="Enter Description" name="response" formControlName="description"> -->
                            <mat-error>
                                <span *ngIf="!formGroup.get('description').valid && formGroup.get('description').touched">
                                    Please Enter Description</span>
                            </mat-error>
                        </div>
                    </div>

                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Media<span>*</span></label>
                        <div class="col-sm-12 row">
                            <div class="col-sm-6">
                                <label for="media" class="custom-file-upload">
                                    <i class="fa fa-cloud-upload"></i>Custom Upload
                                </label>
                                <input type="file" name="country" #file formControlName="media" accept="image/x-png,image/jpeg" class="imageUploadcss" #attachments1 id="media" (change)="countryFlagSelct($event,file.files)" />
                            </div>
                            <div class="col-sm-6" *ngIf="countryFlagImgURL">
                                <div class="close" (click)="countryFlagReset()"><i class="fas fa-times-circle"></i></div>
                                <div class="image-preview">
                                    <img *ngIf="countryFlagImgURL == null" height="100" />
                                    <img *ngIf="countryFlagImgURL != null" [src]="countryFlagImgURL" height="100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button [routerLink]="['/blog-list']" type="submit" class="btn btn-danger  pd-x-20 mg-t-10 mar15">Back</button>
                <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!viewID">Submit</button>
                <button (click)="updateMedia()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="viewID">Update</button>
            </form>
        </div>
    </div>
</div>