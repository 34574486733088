import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MasterDataService } from "../../../../service/masterData/master-data.service";
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-add-update-location',
  templateUrl: './add-update-location.component.html',
  styleUrls: ['./add-update-location.component.scss']
})
export class AddUpdateLocationComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  hide = true;
  LangList: [];
  formGroup: FormGroup;
  data: any;
  pdata: any;
  locationId: any;
  submitted = false;
  status: boolean;
  isActive: boolean;
  stateList: any = [];
  stateListClone: any = [];
  countryList: any = [];
  countryListClone: any = [];
  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.locationId = this.route.snapshot.params['id'];
    this.initializeForm();
    this.getAllState();
    this.getAllCountry();
  }
  get f() { return this.formGroup.controls; }


  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      locationId: [0],
      locationCountry: ['', Validators.required],
      locationState: ['', Validators.required],
      name: ['', Validators.required],

    });
    if (this.locationId) {
      this.patchPlanData();
    }
  }
  // get data from Plan ID
  patchPlanData() {
    this.masterService.getLocationNewById(this.locationId)
      .subscribe(res => {
        this.data = res;
        if (this.data.status == 0) {
          var data = this.data.response;
          this.formGroup.patchValue({
            locationId: this.data.response.locationId,
            locationCountry: this.data.response.locationCountry,
            locationState: this.data.response.locationState,
            name: this.data.response.name,
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
        console.log(this.locationId);
      }, error => console.log(error));
  }

  // firstSpaceRemoveValid(e) {
  //   if (e.which === 32 && e.target.selectionStart === 0) {
  //     return false;
  //   }
  // }

  getAllState(): any {
    this.masterService.getAllState().subscribe((data: any) => {
      if (data.status == 0) {
        this.stateList = data.response;
        this.stateListClone = this.stateList;
      }
    });
  }
  stateSearch(query: string): any {
    const d = query.toLowerCase();
    const result = this.stateSelect(d);
    if (query == '') {
      this.stateList = this.stateListClone;
    } else {
      // @ts-ignore
      this.stateList = result;
    }
  }
  stateSelect(query: string): any[] {
    const result: any[] = [];
    for (const b of this.stateListClone) {
      // @ts-ignore
      if (b.stateName.toLowerCase().indexOf(query) > -1) {
        result.push(b);
      }
    }
    return result;
  }


  getAllCountry() {
    this.masterService.getAllCountryLocation().subscribe((data: any) => {
      if (data.status == 0) {
        this.countryList = data.response;
        this.countryListClone = this.countryList;
      }
    });
  }
  countrySelect(query: string): any[] {
    const result: any[] = [];
    for (const b of this.countryListClone) {
      // @ts-ignore
      if (b.countryName.toLowerCase().indexOf(query) > -1) {
        result.push(b);
      }
    }
    return result;
  }
  countrySearch(query: string): any {
    const d = query.toLowerCase();
    const result = this.countrySelect(d);
    if (query == '') {
      this.countryList = this.countryListClone;
    } else {
      // @ts-ignore
      this.countryList = result;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.formGroup.valid) {   
      if (this.locationId){
        this.masterService.updateLocation(this.formGroup.value, this.locationId)
          .subscribe((data:any) => {
              this.snackBar.open('Location Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/location-list']);
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['error-snackbar']
              });
            }
          );
      } else{
        this.masterService.createNewLocation(this.formGroup.value).subscribe(res => {
          this.data=res;
          if(this.data.status ==  0 ) {
            this.snackBar.open('Location Created  Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/location-list']);
          }
            else {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']});
          }
        });
      }
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

}


