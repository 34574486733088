import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../service/language/language.service";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { JobTestQuestionManageService } from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import { DynamicLabelClass } from "../../../helper/userLevelLink";
import { CommonFucntionService } from 'src/app/service/commonFuncaiton/common-fucntion.service';

@Component({
  selector: 'app-add-update-interview-question',
  templateUrl: './add-update-interview-question.component.html',
  styleUrls: ['./add-update-interview-question.component.scss']
})
export class AddUpdateInterviewQuestionComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;

  viewID: any;
  fileData: File = null;
  testImgURL: any;
  questionImage: any;
  testImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList: Array<any> = [];
  page: number = 0;
  size: number = 500;
  compentcyCategoryList: Array<any> = [];
  jobcategoryList: Array<any> = [];
  qGroup: any;
  qustTypeList: Array<any> = [];
  qustTypeList1: Array<any> = [];
  qType: any;
  arrayOptions: Array<any> = [];
  oprincustome: Array<any> = [];
  resultKewords: Array<any> = [];
  pdata: any;
  seletedOption: number = 0;
  myModel: any;
  config: any;
  addnewOption: number = 2;
  submitted = false;
  addBtnHide: boolean = false;
  selectedAreas: Array<any> = [];
  CorrecTanswerNotSlect = false;
  CorrectAnserArray = [false, false, false, false, false];
  typeSelect: boolean;
  lang: any;
  keywaordList: any;
  keywaordList1: any;
  allKeywords: any;
  allComplete: boolean = false;
  menus: any;
  mrunal = [];
  keyWordListShow = false;
  capDivVal: boolean = false;
  jobDivVal: boolean = false;
  laguageName: any;
  typeOfQuestion: number;
  crattedInterviewList = [];
  comptencyListClone = [];
  uniq :any;
  searchenter: boolean = false;
  demoList = [];
  allSelected = [];

  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    public commonFucntion: CommonFucntionService,
    private router: Router) { }


  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.viewID = this.route.snapshot.params['id'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.laguageName = 'en';
    //this.questionGroupList();
    // this.createdInterview();
    this.questionTypeList();
    // this.getAllCvaCompetencyCategory();
     this.getAllJobCategory();

    this.initializeForm();
    this.arrayOptions = [];
  }
  questionTypeList(): any {
    this.masterDataservice.getAllQuestionTypeList(this.page, this.size).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {
        this.qustTypeList1 = this.qType.response.dataSet.slice(0, 2);
        this.qustTypeList = this.qType.response.dataSet.filter((d) => {
          if (d.isActive == true) {
            return d;
          }
        });
      }
    });
  }


  getAllJobCategory(): any {
    console.log(this.laguageName);
    this.masterDataservice.getAllCategoryList(this.page, this.size, this.laguageName , true).subscribe(res => {
      this.qGroup = res;
      if (this.qGroup.status == 0) {
        this.jobcategoryList = this.qGroup.response.dataSet.filter((d) => {
          if (d.isSubCategory == true) {
            return d;
          }
        });
        // console.log(this.crattedInterviewList)
        // if (this.viewID) {
        //   this.jobcategoryList = filtersubcat;
        // } else {
        //   this.jobcategoryList = filtersubcat.filter(e => this.crattedInterviewList.map((o) => o.jobCategoryId).indexOf(e.jobCategoryId) < 0);
        // }
        console.log(this.jobcategoryList)
        this.selectedAreas = this.jobcategoryList;
      }
    });
  }

  getAllCvaCompetencyCategory(): any {
    this.masterDataservice.getAllCompetencyCategory(this.page, this.size, this.laguageName).subscribe(res => {
      this.qGroup = res;
      if (this.qGroup.status == 0) {
        let compatnecyfilter = this.qGroup.response.dataSet.filter((d) => {
          if (d.isSubCategory == true) {
            return d;
          }
        });
        //this.compentcyCategoryList = this.qGroup.response.dataSet;
        if (this.viewID) {
          this.compentcyCategoryList = compatnecyfilter;
        } else {
          this.compentcyCategoryList = compatnecyfilter.filter(e => this.crattedInterviewList.map((o) => o.competencyCategoryId).indexOf(e.competencyCategoryId) < 0);
        }
        this.comptencyListClone = this.compentcyCategoryList;
      }
    });
  }
  competncysearch(jobcategoryList , query: string): any {
    const result = this.competncyselect(query.toLowerCase());
    if (query == '') {
      this.compentcyCategoryList = this.comptencyListClone;
    } else {
      this.compentcyCategoryList = result;
    }
  }
  competncyselect(query: string): string[] {
    const result: string[] = [];
    for (let a of this.comptencyListClone) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  search(query: string): any {
    const result = this.select(query.toLowerCase());
    if (query == '') {
      this.searchenter = false;
      console.log(this.formGroup.value.jobCategoryId);
       this.jobcategoryList = this.selectedAreas;
      
    } else {
      this.searchenter = true;
      this.demoList = result;
      console.log(this.demoList)
      // this.jobcategoryList = result;
    }
  }
  select(query: string): string[] {
    const result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      question: ['', Validators.required],
      languageId: ['', Validators.required],
      competencyCategoryId: [''],
      jobCategoryId: [''],
      questionImage: [''],
      companyId: [0],
      TypeOfQuestion: ['1'],
      //questionTypeId: [ '' , Validators.required],
      options: new FormArray([]),
      correctAnswer: ['', Validators.required],
      answerExplanation: [''],
      parentId: [0],
    });

    if (this.viewID) {
      this.patchData();
    }
    this.LangListLoad(this.addnewOption);
  }
  get f() { return this.formGroup.controls; }
  get t() { return this.f.options as FormArray; }

  removeOption(i): any {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e): any {
    const numberOfTickets = e;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.fb.group({
          response: [''],
          valid: false,
        }));
      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  // get data from jobCategoryId
  competancySelect: boolean;
  keywordds: any;
  keywordBtnHide: boolean = false;
  iskeyWord: any;
  keywordAllBtnHide: boolean = false;
  radioValue: number;

  patchData(): any {
    this.jobpostQuestionTestService.getInterviewquestById(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          this.keywordds = this.pdata.response.keywords;
          if (this.pdata.response.questionLevel == 'COMPREHENSIVE') {
            this.typeSelect = true;
          } else {
            this.typeSelect = false;
          }
          this.LangListLoad(this.pdata.response.options.length);
          this.addnewOption = this.pdata.response.options.length;

          this.myModel = this.pdata.response.correctAnswer;
          const ddd = this.pdata.response.options;
          const newaray = [];
          let m: any;
          let ctans = false;
          ddd.forEach((b, i) => {
            if (b == this.myModel) {
              m = true;
              this.CorrectAnserArray.splice(i, 1, true);
            } else {
              m = '';
              this.CorrectAnserArray.splice(i, 1, false);
            }
            newaray.push({
              'response': b,
              'valid': m,
            });
          });

          this.keywordds = this.pdata.response.keywords;
          this.resultKewords = this.keywordds;

          //this.setAll(true);
          if (this.pdata.response.typeOfQuestion == 'GENERAL') {
            this.typeOfQuestion = 1;
          } else if (this.pdata.response.typeOfQuestion == 'COMPETENCY') {
            this.typeOfQuestion = 2;
          }
          else {
            this.typeOfQuestion = 3;
          }

          this.formGroup.patchValue({
            question: this.pdata.response.question,
            languageId: this.pdata.response.languageId,
            competencyCategoryId: this.pdata.response.competencyCategoryId,
            jobCategoryId: this.pdata.response.jobCategoryId,
            // questionImage: this.pdata.response.questionImage,
            TypeOfQuestion: this.typeOfQuestion,
            options: newaray,
            correctAnswer: this.pdata.response.correctAnswer,
            answerExplanation: this.pdata.response.answerExplanation,
            companyId: this.pdata.response.companyId,
            parentId: this.pdata.response.parentId,
          });

          for (let l of this.LangList) {
            if (this.pdata.response.languageId == l.languageId) {
              this.laguageName = l.languageCode;
              this.formGroup.patchValue({
                languageId: l,
              })
            }
          }

          if (this.pdata.response.typeOfQuestion == 'GENERAL') {
            this.radioValue = 1;
            this.getAllJobCategory();
            this.jobDivVal = true;
            this.capDivVal = false;
          }
          else if (this.pdata.response.typeOfQuestion == 'COMPETENCY') {
            this.radioValue = 2;
            this.onItemChange(this.pdata.response.competencyCategoryId);
            this.getAllCvaCompetencyCategory();
            this.capDivVal = true;
            this.jobDivVal = false;
          }
          else if (this.pdata.response.typeOfQuestion == 'OTHER') {
            this.radioValue = 3;
          }


        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }


  onSubmit(submiType): any {
    this.formGroup.controls.answerExplanation.setValidators(null);
    this.formGroup.controls.answerExplanation.updateValueAndValidity();
    this.formGroup.get('answerExplanation').clearValidators();

    if (this.formGroup.value.TypeOfQuestion == '1') {
      this.formGroup.controls.competencyCategoryId.setValidators(null);
      this.formGroup.controls.competencyCategoryId.updateValueAndValidity();
      this.formGroup.get('competencyCategoryId').clearValidators();
    } else if (this.formGroup.value.TypeOfQuestion == '2') {
      this.formGroup.controls.jobCategoryId.setValidators(null);
      this.formGroup.controls.jobCategoryId.updateValueAndValidity();
      this.formGroup.get('jobCategoryId').clearValidators();
    } else {
      this.formGroup.controls.competencyCategoryId.setValidators(null);
      this.formGroup.controls.competencyCategoryId.updateValueAndValidity();
      this.formGroup.get('competencyCategoryId').clearValidators();
      this.formGroup.controls.jobCategoryId.setValidators(null);
      this.formGroup.controls.jobCategoryId.updateValueAndValidity();
      this.formGroup.get('jobCategoryId').clearValidators();
    }

    if (this.CorrectAnserArray.length > 0) {
      if (this.CorrectAnserArray.indexOf(true) >= 0) {
        const dddsd = this.CorrectAnserArray.indexOf(true);
        const crtAnsdd = this.formGroup.value.options[dddsd].response;
        this.formGroup.controls.correctAnswer.setValue(crtAnsdd);
        const dd = this.formGroup.value.options;
        this.oprincustome = [];
        for (let b of dd) {
          this.oprincustome.push(this.commonFucntion.removeHtmlTag(b.response));
        }
      }

    } else {
      const mm = this.formGroup.controls.correctAnswer.value;

      if (mm == '' || mm == null) {
        this.CorrecTanswerNotSlect = true;
      } else {
        this.CorrecTanswerNotSlect = false;
      }
      const d = (mm == '' || mm == null) ? 'Please  Select Correct Answer' : 'Please Enter All Details';
      this.snackBar.open(d, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      return
    }
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      if(!(this.viewID)){
        this.uniq = this.formGroup.value.jobCategoryId.reduce((uniqArr, item) => {
          return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]}
          ,[]
          );
      }
      
      const formData: FormData = new FormData();
      formData.append('question', this.commonFucntion.removeHtmlTag(this.formGroup.value.question));
      formData.append('languageId', this.formGroup.value.languageId.languageId);
      // @ts-ignore
      formData.append('questionTypeId', 4);
      if (this.formGroup.value.TypeOfQuestion == 1) {
        formData.append('jobCategoryId', !this.viewID ? this.uniq : this.formGroup.value.jobCategoryId);
        // @ts-ignore
        formData.append('isGeneralQuestion', 'GENERAL');
      }

      if (this.formGroup.value.TypeOfQuestion == 2) {
        formData.append('compenetencyId', this.formGroup.value.competencyCategoryId);
        // @ts-ignore
        formData.append('isGeneralQuestion', 'COMPETENCY');
      }

      if (this.formGroup.value.TypeOfQuestion == 3) {
        // @ts-ignore
        formData.append('isGeneralQuestion', 'OTHER');
      }

      // @ts-ignore
      formData.append('isVideoQuestion', false);
      formData.append('companyId', this.formGroup.value.companyId == null ? 0 : this.formGroup.value.companyId);
      formData.append('correctAnswer', this.formGroup.value.correctAnswer);
      formData.append('answerExplanation', this.commonFucntion.removeHtmlTag(this.formGroup.value.answerExplanation));
      formData.append('parentId', this.formGroup.value.parentId == null ? 0 : this.formGroup.value.parentId);
      formData.append('options', (this.oprincustome).toString());
      // @ts-ignore
      formData.append('keywords', this.resultKewords);

      if (this.viewID) {
        this.jobpostQuestionTestService.editInterviewQuestion(this.viewID, formData)
          .subscribe((res: any) => {

            if (res.status == 0) {
              this.snackBar.open('Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              if (submiType == 0) {
                this.router.navigate(['/listInterviewQuestion']);
              } else {
                this.submitted = false;
                this.formGroup.controls.question.reset();
                this.formGroup.controls.answerExplanation.reset();
                this.formGroup.controls.correctAnswer.reset();
                this.t.reset();
              }
            } else {
              this.snackBar.open('server status -1', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
            }



          },
            (err) => {
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else {
        this.jobpostQuestionTestService.addInterviewQuestion(formData).subscribe((res: any) => {
          if (res.status == 0) {
            this.snackBar.open(' Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            if (submiType == 0) {
              this.router.navigate(['/listInterviewQuestion']);
            } else {
              this.submitted = false;
              this.formGroup.controls.question.reset();
              this.formGroup.controls.answerExplanation.reset();
              this.formGroup.controls.correctAnswer.reset();
              this.t.reset();
            }
          } else {
            this.snackBar.open('server status -1', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      }

    } else {
      this.submitted = true;
      const mm = this.formGroup.controls.correctAnswer.value;
      console.log(mm)
      if (mm == '' || mm == null) {
        this.CorrecTanswerNotSlect = true;
      } else {
        this.CorrecTanswerNotSlect = false;
      }
      const d = (mm == '' || mm == null) ? 'Please  Select Correct Answer' : 'Please Enter All Details';
      this.snackBar.open(d, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      return;
    }
  }

  firstSpaceRemoveValid(e): any {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  QuestionTypeSelectionDropDown(e): any {
    console.log(e);
    if (e.value == 5) {
      this.keywordBtnHide = true;
      this.iskeyWord = 1;
    } else {
      this.keywordBtnHide = false;
      this.iskeyWord = 0;
    }
  }
  newOptionadd(): any {
    if (this.addnewOption < 5) {
      this.addnewOption = this.addnewOption + 1;
      console.log(this.addnewOption);
      this.LangListLoad(this.addnewOption);
      this.addBtnHide = false;
    } else {
      this.addBtnHide = true;
    }
    if (this.addnewOption == 5) {
      this.addBtnHide = true;
    } else {
      this.addBtnHide = false;
    }

  }

  onCheckboxChange(e, i): any {
    console.log(e);
    console.log(this.addnewOption);

    this.CorrecTanswerNotSlect = false;
    if (e.checked) {
      this.CorrectAnserArray.splice(i, 1, true);
      let m = 0;
      for (m = 0; m < 5; m++) {
        if (m !== i) {
          this.CorrectAnserArray.splice(m, 1, false);
        }
      }
      // this.CorrectAns[i] =e.target.checked;
      // this.days.push(e.target.value);
    } else {
      // this.days.splice(this.rightsaName[i].value, 1);
    }

    console.log(this.CorrectAnserArray);

  }

  testImageSelct(event, files): any {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.testImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
        this.questionImage = reader.result;
      };
    }
    this.newimgeuplod = true;

    console.log(this.fileData);
  }

  testImageReset(): any {
    this.questionImage = '';
    this.myInputVariable.nativeElement.value = '';
  }

  onItemChange(id) {
    console.log(id);
    this.masterDataservice.getCompetencyKeywordDetailsByIdNew(id, 'en').subscribe(res => {
      this.qGroup = res;
      if (this.qGroup.status == 0) {
        this.keyWordListShow = true;
        this.competancySelect = true;
        console.log(this.qGroup.response);
        const d = this.qGroup.response.replace(/\s/g, '');
        if (this.qGroup.respons != "") {
          this.keywaordList = d.split(",");
          this.keywaordList1 = d.split(",");
          this.mrunal = this.keywaordList;
          this.selectedAreas = this.compentcyCategoryList;
          console.log(d);
          if (d != "") {
            if (this.iskeyWord == 0) {
              this.keywordBtnHide = false;
            } else {
              //this.keywordBtnHide = true;
            }
          } else {
            this.keywordBtnHide = false;
          }


          if (this.keywaordList1.length == this.keywordds.length) {
            this.allComplete = true;
          } else {
            this.allComplete = false;
          }
        }
        // this.allComplete = true;this.oprincustome
      }
      //this.setAll(true);

    });
  }



  setAll(e: any) {
    if (e.checked) {
      console.log(this.keywaordList1);
      this.resultKewords = this.mrunal;
      this.allComplete = true;
    } else {
      this.allComplete = false;
      this.resultKewords = [];
    }
    console.log(this.resultKewords);
    console.log(this.keywaordList1);
  }


  setAllMe(e, key): any {
    if (e.checked) {
      this.resultKewords.push(key);
    } else {
      console.log(key);
      console.log(this.resultKewords);
      this.resultKewords.splice(key, 1);
    }
    //this.allComplete =e.checked;
    console.log(this.keywaordList1);
    console.log(this.resultKewords);

  }

  onCheckboxChangeNew(e, i, key) {
    console.log(this.keywaordList);
    console.log(this.resultKewords);
    if (e.checked) {
      this.allComplete = false;
      this.resultKewords.push(key);
    } else {
      var index = this.resultKewords.indexOf(key);
      this.resultKewords.splice(index, 1);
    }
    if (this.keywaordList1.length == this.resultKewords.length) {
      this.allComplete = true;
    } else {
      this.allComplete = false;
    }

    console.log(this.resultKewords);
  }

  TypeOfQuestionChange(e) {

    if (e.value == 1) {
      this.capDivVal = false;
      this.jobDivVal = true;
      this.keywordBtnHide = false;
    }

    if (e.value == 2) {
      this.capDivVal = true;
      this.jobDivVal = false;
      this.keywordBtnHide = false;
    }

    if (e.value == 3) {
      this.capDivVal = false;
      this.jobDivVal = false;
      this.keywordBtnHide = false;
    }

  }

  onLanguageChange(value) {
    this.laguageName = value.languageCode;
    // this.createdInterview();
     this.getAllJobCategory();
    // this.getAllCvaCompetencyCategory(); 
  }
  createdInterview() {
    this.jobpostQuestionTestService.getAllInterviewQuestion(0, 500, this.laguageName, 'false').subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.crattedInterviewList = this.data.response.dataSet;
        this.getAllJobCategory();
      }
    });
  }

  removeAtt(e) {
    console.log(e);
    this.commonFucntion.removeAttributeFromHtml(e);
  }
  SelectAllJobCat(jobcategoryList , e:any){

    console.log(jobcategoryList.length);
    if(e.checked == true){
      let d =  [];
      for(let b of this.jobcategoryList){
        d.push(b.jobCategoryId);
      }
      console.log(d);
      this.allSelected = d;
      this.formGroup.controls['jobCategoryId'].setValue(d);
    } else {
      this.formGroup.controls['jobCategoryId'].setValue([]);
      // for(let b of jobcategoryList){
      //   console.log(this.formGroup.controls['jobCategoryId'].value)
      //   let index = this.formGroup.controls['jobCategoryId'].value.findIndex((el: any) => el.jobCategoryId === b.jobCategoryId)
      //  console.log(index)
      //   this.formGroup.controls['jobCategoryId'].value.splice(index, 1);
      //   this.formGroup
      // }
     
    }
  }
  mmm(e){
    console.log(e);
    console.log(e.target.value);

    console.log(this.formGroup.value.jobCategoryId)
  }


}
