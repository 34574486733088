<div class="topbar main-header d-flex justify-content-between align-items-center" >
  <div class="container-fluid">
    <!-- LOGO -->
    <div class="main-header-left">
      <a [routerLink]="'/home'"  class="main-logo desktop-logo" style="width: 180px;">
        <span>
          <img src="../../../../assets/img/logo2.png" class="logo-small" alt="logo-small">
        </span> 
      </a>
    </div>
    <!--end logo-->
    <!-- Navbar -->
    <nav class="main-img-user dropdown main-profile-menu">
      <button [matMenuTriggerFor]="menu">
        <img *ngIf="!(pimg == null || pimg == undefined )"  [src]="pimg" alt="logo-small" alt="profile"
         class="rounded-circle"
        >
         <img *ngIf="pimg == null || pimg == undefined "  src="../../../../assets/img/user.jpg"
          alt="logo-small" alt="profile" class="rounded-circle" 
         >
        <!-- {{currentUser}}<i class="fas fa-bars" ></i>  -->
    </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/editProfile', loginId ]" > <i class="fas fa-user"></i>
          Profile</button>
          <button mat-menu-item  [routerLink]="'/Chnagepassword'" ><i class="fas fa-user-cog"></i>Change Password</button>
       
        <button mat-menu-item (click)="logOut()"><i class="fas fa-sign-out-alt"></i> Logout</button>
      </mat-menu>
    </nav>
    <!-- end navbar-->
  </div>
  </div>
