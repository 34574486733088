import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobTestQuestionManageService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient) { }

  getAlljobpostQuestion(page, size, lang, groupId, serachText) {
    return this.http.get<any>(`${environment.baseurl}/getAllAssesmentQuestions?page=` + page + `&size=` + size + '&isoCode=' + lang);
  }
  getAlljobpostQuestionAdvancfilter(page, size, lang, groupId, serachText) {
    return this.http.get<any>(`${environment.baseurl}/searchAssesmentQuestions?page=` + page + `&size=` + size + '&isoCode=' + lang
      + '&groupId=' + groupId + '&searchText=' + serachText);
  }
  addJobPostQuestion(data) {
    // return this.http.post<any>(`https://backintrec.codesrv.com:8085/api/addAssesmentQuestions?assesmentQuestionId=0&questionTitle=%3Cspan%3EQuestion%20title%3C%2Fspan%3E&languageId=14&questionGroupId=33&questionTypeId=11&options=option1sadasdasd%2COption2&correctAnswer=Option2&answerExplanation=%3Cspan%3EQuestion's%20Answer%20Explanation%3C%2Fspan%3E&companyId=0&parentId=0` , '');
    return this.http.post<any>(`${environment.baseurl}/addAssesmentQuestions`, data);
  }
  deleteQuestionImage(id) {
    return this.http.delete<any>(`${environment.baseurl}/deleteAssesmentQuestionImage?imageId=` + id);
  }
  addnewjobpsot() {
    return this.http.post<any>(`${environment.baseurl}/addAssesmentQuestions?`, '');
  }
  getJobPostQuestById(id) {
    return this.http.get<any>(`${environment.baseurl}/getAssesmentQuestionByIdEdit?questionId=` + id);
  }
  editJobPostQuestion(id, data) {
    return this.http.put<any>(`${environment.baseurl}/updateAssesmentQuestion?assesmentQuestionId=` + id, data);
  }
  bulkUploadJobPostQuestion(data) {
    return this.http.post<any>(`${environment.baseurl}/uploadBulkAssesmentQuestion`, data);
  }
  statusChangeJobPostQuestion(id) {
    return null;
    // return this.http.post<any>(`${environment.baseurl}/uploadBulkAssesmentQuestion`, data);
  }
  getQuestionListByGroupId(page, size, lang, id) {
    return this.http.get<any>(`${environment.baseurl}/getQuestionByGroupId?page=` + page + `&size=` + size + '&isoCode=' + lang + '&groupId=' + id);
  }

  // DELETE QUESITON
  deleteQuestionById(QId) {
    return this.http.delete<any>(`${environment.baseurl}/deleteAssesmentQuestion?qId=` + QId);
  }
  //  test creation
  getAlljobTestList(page, size, lang) {
    return this.http.get<any>(`${environment.baseurl}/getAllAssesmentTest?page=` + page + `&size=` + size + '&isoCode=' + lang);
  }
  getAlljobTestListAdvacnfilter(page, size, lang, groupId, serachText, type) {
    return this.http.get<any>(`${environment.baseurl}/testFilter?page=` + page + `&size=` + size + '&isoCode=' + lang
      + '&categoryId=' + groupId + '&testName=' + serachText + '&type=' + type);
  }
  addJobTestCreationFirst(data) {
    return this.http.post<any>(`${environment.baseurl}/createNewAssesemenTest`, data);
  }
  getAssesmentTestBystId(Id) {
    return this.http.get<any>(`${environment.baseurl}/getAssesmentTestByTestId?testId=` + Id);
  }
  editJobTestCreationFirst(id, data) {
    return this.http.put<any>(`${environment.baseurl}/updateAssesmentTest?testId=` + id, data);
  }
  addQuestionCreateTest(id, data, isTestCreateSuccess) {
    return this.http.put<any>(`${environment.baseurl}/addQuestionToTest?assesmentTestId=` + id + '&isTestCreateSuccess=' + isTestCreateSuccess, data);
  }
  getAssestmentTestDetailsById(Id) {
    return this.http.get<any>(`${environment.baseurl}/getQuestionByTestId?testId=` + Id);
  }
  UpdateTestCreationQuestion(Id, data, testCreation) {
    return this.http.put<any>(`${environment.baseurl}/updateQuestionFromTest?assesmentTestId=` + Id + '&isTestCreateSuccess=' + testCreation, data);
  }
  deleteTest(id) {
    return this.http.delete<any>(`${environment.baseurl}/deleteAssesmentTest?testId=` + id);
  }
  testStatusChnage(id) {
    return this.http.put<any>(`${environment.baseurl}/changeAssesmentTestStatus?testId=` + id, '');
  }
  jobcatgorySelection(id, lang) {
    return this.http.get<any>(`${environment.baseurl}/isTestAlreadyExists?jobCategoryId=` + id + '&isoCode=' + lang);
  }
  QuestionStatusChnage(id) {
    return this.http.put<any>(`${environment.baseurl}/chnageAssesementQuestionStatus?qId=` + id, '');
  }
  copyTest(data) {
    return this.http.post<any>(`${environment.baseurl}/copyAssesmentTest`, data);
  }
  getSubQuestionByParentId(id) {
    return this.http.get<any>(`${environment.baseurl}/getSubQuestionByParentId?parentId=` + id);
  }
  addInterviewQuestion(data) {
    return this.http.post<any>(`${environment.baseurl}/addInterviewQuestion`, data);
  }
  getAllInterviewQuestion(page, size, lang, val) {
    return this.http.get<any>(`${environment.baseurl}/getInterViewQuestion?page=` + page + `&size=` + size + '&isoCode=' + lang + '&isVideoQuestion=' + val);
  }

  deleteInterviewQuestion(id) {
    return this.http.delete<any>(`${environment.baseurl}/deleteInterviewQuestion?qId=` + id);
  }

  //  publish test  start
  GetAllPublishTestList(page, size, lang, groupId, serachText): any {
    return this.http.get<any>(`${environment.baseurl}/getAllPublishedTest?page=` + page + `&size=` + size + '&isoCode=' + lang
      + '&categoryId=' + groupId + '&testName=' + serachText);
  }
  GetAllPublishTestListAdvacnFilter(page, size, lang, groupId, serachText): any {
    return this.http.get<any>(`${environment.baseurl}/getAllPublishedTest?page=` + page + `&size=` + size + '&isoCode=' + lang
      + '&groupId=' + groupId + '&serachText=' + serachText);
  }
  getPusblishTestDetailsById(id): any {
    return this.http.get<any>(`${environment.baseurl}/getPusblishTestDetails?testId=` + id);
  }
  deletePublishTest(id): any {
    return this.http.delete<any>(`${environment.baseurl}/deletePublishedTest?testId=` + id);
  }
  publishTestById(id): any {
    return this.http.put<any>(`${environment.baseurl}/publishTest?testId=` + id, '');
  }


  getInterviewquestById(id) {
    return this.http.get<any>(`${environment.baseurl}/getInterviewQuestionById?qId=` + id);
  }

  editInterviewQuestion(id, data) {
    return this.http.put<any>(`${environment.baseurl}/updateInterviewQuestion?questionId=` + id, data);
  }
  // new question buil upload
  newQuestionbuilkUpload(data) {
    return this.http.post<any>(`${environment.baseurl}/uploadBulkAssesmentQuestion`, data);
  }

}
