import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { ManageJobsService} from 'src/app/service/ManageJobs/manage-jobs.service';


@Component({
  selector: 'app-add-edit-job-free-services',
  templateUrl: './add-edit-job-free-services.component.html',
  styleUrls: ['./add-edit-job-free-services.component.scss']
})
export class AddEditJobFreeServicesComponent implements OnInit {

  LangList=[];
  viewId:number;
  dynamicForm: FormGroup;
  submitted = false;
  data: any;
  pdata:any;
  radioValue:number=0;
  countryList= [];
  @ViewChild('attachments1') myInputVariable: ElementRef;
  countryId: any;
  fileData: File = null;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  newimgeuplod: any;


  constructor(public langService :LanguageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService :MasterDataService,
    private route: ActivatedRoute,
    public manageJobfreeservice : ManageJobsService,
    private router: Router) { }

  ngOnInit(): void {
    this.countryListData();
    this.viewId = this.route.snapshot.params['id']; 
      this.dynamicForm = this.formBuilder.group({ 
        serviceType: ['' , Validators.required],
        countrySupported: ['' , Validators.required],
        name: ['' , Validators.required],
        websiteName: ['' , Validators.required],            
        jobBoardDescription: new FormArray([]),
        status:[true],
        companyLogo:[]
      });    
      this.LangList = JSON.parse(this.langService.getLngList());
      this.LangListLoad(this.LangList.length);
      if(this.viewId){
        this.patchForm();
      }
  }
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.jobBoardDescription as FormArray; }
  LangListLoad(e) {
    const numberOfTickets = e
    if (this.t.length < numberOfTickets) {
        for (let i = this.t.length; i < numberOfTickets; i++) {
            this.t.push(this.formBuilder.group({
              languageId:[this.LangList[i].languageId],              
              description: [ '' , Validators.required],            
            }));
        } 
    } else {
        for (let i = this.t.length; i >= numberOfTickets; i--) {
            this.t.removeAt(i);
        }
    }
  }
  patchForm(){
    this.manageJobfreeservice.getById(this.viewId).subscribe((res:any)=>{
      this.pdata = res;
    if(this.pdata.status == 0){
      var data = this.pdata.response;
      // this.dynamicForm.patchValue(this.pdata.response);
      this.countryFlagImgURL = this.pdata.response.companyLogo;
      let country = ((this.pdata.response.countrySupported).split(',')).map( d => Number(d));
      this.dynamicForm.patchValue({
        jobBoardDescription: this.pdata.response.jobBoardDescription,
        countrySupported: country,
        serviceType: this.pdata.response.serviceType,
        name: this.pdata.response.name,
        websiteName: this.pdata.response.websiteName,  
        status:this.pdata.response.isActive,
        
      });
    } else {
      this.snackBar.open(res.response, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
    });
  }
  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }

  countryFlagReset() {
    this.countryFlagImgURL = '';
    this.myInputVariable.nativeElement.value = '';
  }
  countryFlagSelct(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };
    }
    this.newimgeuplod = true;
  }
  countryListData(){
    this.masterService.getAllCountryNew().subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.countryList = this.data.response;
      }
    })

  }
  onSubmit() {
    this.submitted = true;
    
    console.log(this.dynamicForm.value.jobBoardDescription) 
    if (this.dynamicForm.valid) {      
      const requests = {
        jobBoardDescription : this.dynamicForm.value.jobBoardDescription,
      };
      const formData: FormData = new FormData();

      if(this.fileData) formData.append('companyLogo' , this.fileData);
      formData.append('serviceType' , this.dynamicForm.value.serviceType);
      formData.append('websiteName' , this.dynamicForm.value.websiteName);
      formData.append('name' , this.dynamicForm.value.name);
      formData.append('countrySupported' , (this.dynamicForm.value.countrySupported).toString());
      formData.append('description' , JSON.stringify(requests));


      if (this.viewId){
        this.manageJobfreeservice.updateAddonJobBoardFree(this.viewId, formData)
          .subscribe((res: any) => {
            if(res.status == 0){
              this.snackBar.open(' Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listJobFreeService']);
            } else {
              this.snackBar.open(res.response,'',{
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['error-snackbar']
              });
            }              
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['error-snackbar']
              });
            }
          );
      } else{
        this.manageJobfreeservice.save(formData).subscribe(res => {
          this.data=res;
          if(this.data.status ==  0 ) {
            this.snackBar.open('save Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listJobFreeService']);
          }
            else {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']});
          }

        });
      }
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

}
