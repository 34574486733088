import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MemberServiceService } from '../../servicesMember/member-service.service';
import { MatDialog } from '@angular/material/dialog';
import {CommonConfirmPopupComponent} from 'src/app/component/common-confirm-popup/common-confirm-popup.component';

@Component({
  selector: 'app-list-discount',
  templateUrl: './list-discount.component.html',
  styleUrls: ['./list-discount.component.scss']
})
export class ListDiscountComponent implements OnInit {


  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number = 100;
  size: number =0;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  couponId:any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              public memberShipService : MemberServiceService,
              public dialog: MatDialog) {
                
                }

  ngOnInit(): void {
    this.displayedColumns = [ 'couponCode', 'couponDescription', 'timesCanbeUsed', 'couponType','startDate',
    'autoExpiryDate','couponValue','isActive','actions'];
  }

  tabelLoad(page , size){
    this.memberShipService.discountList(page, size).subscribe((res:any) =>{
      this.data = res;
      if(this.data.status == 0){       
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
        this.dataSource = new MatTableDataSource(this.data.response);
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteSttaic(rowid: number) {
    if (rowid > -1) {
      this.dataSource.data.splice(rowid, 1);
      this.dataSource._updateChangeSubscription();
    }
  }
  statusChange (e , element) {
    var msg;
    if(element.isActive){
      msg = 'Are you sure wants to Deactivate status?'
    } else{
      msg = 'Are you sure wants to Activate status?'
    }
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: msg,
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.memberShipService.stausChangeDiscount(element.couponId).subscribe( res =>{
          this.data = res;
          if(this.data.status == 0 ){
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.tabelLoad(this.page , this.size);
          }
        })
      } else{
        e.source.checked= element.isActive;
      }
    });
  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.categoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any) {
    // console.log(e);
    this.selectproduct = e.categoryName;
    this.selectedId = e.couponId;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search(){

    this.router.navigate(['editjobcategory',this.selectedId]);

  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }
  delete(couponId) {
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: "Are you sure wants to delete?",
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.memberShipService.deleteDiscount(couponId).subscribe(res => {
          this.data = res;
          if(this.data.status == 0 ){
            this.tabelLoad(this.page , this.size);
            this.snackBar.open('Coupon deleted succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          } else {
            this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }, error => console.log(error));
      }
    });
  }

  ngAfterViewInit(){  
     this.tabelLoad(100 , 0);
}

onChangePage(e){
  console.log(e);
  this.page =e.pageIndex;
  this.tabelLoad(e.pageIndex , e.pageSize);
}

}
