import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import { LanguageService } from 'src/app/service/language/language.service';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';

@Component({
  selector: 'app-add-update-restricted-keyword',
  templateUrl: './add-update-restricted-keyword.component.html',
  styleUrls: ['./add-update-restricted-keyword.component.scss']
})
export class AddUpdateRestrictedKeywordComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  restrictedKeyWordId: any;
  fileData: File = null;
  LangList=[];
  config: any;
  constructor(private snackBar: MatSnackBar,


    public langService :LanguageService,
              private fb: FormBuilder,
              private formBuilder: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }

  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  dynamicForm: FormGroup;

  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.restrictedKeyWordId = this.route.snapshot.params['id'];
    this.initializeForm();
  }


  // To initialize Form
  initializeForm() {
    this.dynamicForm = this.fb.group({
      multiLanguageNames: new FormArray([]),
    });

    if (this.restrictedKeyWordId){
      this.patchIndustryData();
    }
    this.LangList = JSON.parse(this.langService.getLngList());
    console.log(this.LangList);
  this.LangListLoad(this.LangList.length);
  }
  // get f() { return this.dynamicForm.controls; }
  get t() { return  this.dynamicForm.controls.multiLanguageNames as FormArray; }

     LangListLoad(e) {
      const numberOfTickets = e
      if (this.t.length < numberOfTickets) {
          for (let i = this.t.length; i < numberOfTickets; i++) {
              this.t.push(this.formBuilder.group({
                languageId:[this.LangList[i].languageId],
                keywordName: [ '' , Validators.required],
                description: [ '' , Validators.required],
              }));

          }
          // this.dynamicForm.patchValue(this.payloaddd);
      } else {
          for (let i = this.t.length; i >= numberOfTickets; i--) {
              this.t.removeAt(i);
          }
      }
  }

  // get data from jobCategoryId
  patchIndustryData(){
    this.masterService.getRestrictedKeywordById(this.restrictedKeyWordId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.dynamicForm.patchValue(
            this.data.response
          //   {
          //   // keywordName: this.data.response.keywordName ,
          //   // description: this.data.response.description ,
          // }
          );
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.dynamicForm.valid) {
      // const payload = {
      //   keywordName: this.formGroup.value.keywordName,
      //   description: this.formGroup.value.description,
      //   parentId: 0,
      // }
      if (this.restrictedKeyWordId){
        this.masterService.updateRestrictedKeyword(this.restrictedKeyWordId, this.dynamicForm.value)
          .subscribe(data => {
              this.snackBar.open('Job Industry Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listRestrictedKeywords']);
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );
      } else{
        this.masterService.createRestrictedKeyword(this.dynamicForm.value).subscribe(data => {
        //this.masterService.createRestrictedKeyword(this.dynamicForm.value).subscribe(data => {
          this.snackBar.open('Job Industry Inserted Successfully !!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/listRestrictedKeywords']);
        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }

}
