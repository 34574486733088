import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import{TranslationService} from 'src/app/service/translation/translation.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import { LanguageService } from 'src/app/service/language/language.service';

@Component({
  selector: 'app-upload-language-file',
  templateUrl: './upload-language-file.component.html',
  styleUrls: ['./upload-language-file.component.scss']
})
export class UploadLanguageFileComponent implements OnInit {
  fileData:any;
  url:any;
  fileName:any;
  fileType:any;
  format:any;
  isWeb:any;
  appName:any;
  langaugeId:any;
  resdata:any;
  LangList:any;
  selectedLangCode:any;
  constructor(public dialogRef: MatDialogRef<UploadLanguageFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translationService:TranslationService,
    private snackBar: MatSnackBar,
    public langService: LanguageService) { }

  ngOnInit(): void {
    this.LangList = JSON.parse(this.langService.getLngList());
    this.isWeb = this.data.isWeb;
    this.appName = this.data.appName;
    this.langaugeId = this.data.langaugeId;
  }

  onSelectFile(event: any): any {
    var filedetails = event.target.files[0] as File as File;
    this.fileName = filedetails.name;
    this.fileType = filedetails.type;
    this.format = (this.fileType.split('/')[0]).toLowerCase();
    if (this.format === 'application' ){
      this.fileData = filedetails;
    } else{
      alert(this.fileName+" is not a excel file.");
    }
  }
  submit():any{
    console.log("this.fileData",this.fileData);
    this.LangList.forEach(element => {
      if(element.languageId == this.langaugeId){
        this.selectedLangCode = element.languageCode;
      }
    });
    const formData: FormData = new FormData();
    formData.append('file',this.fileData);
    formData.append('isWeb',this.isWeb);
    formData.append('appName',this.appName);
    formData.append('langaugeId',this.langaugeId);
    this.translationService.uploadLanguageFile(formData).subscribe( res =>{
      this.resdata = res;
      if(this.resdata.status == 0 ){
        this.dialogRef.close(true);
      } else{
        this.snackBar.open('Please check the File Name it should be upload_language_'+this.selectedLangCode, '', {
          duration: 5000,
          panelClass: ['error-snackbar']
        });
      }
    })
  }
}
