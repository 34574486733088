<div class="container-fluid">
    <!--Page Header-->
    <div class="page-header">
        <h3 class="page-title">Media</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Media</li>
        </ol>
    </div>
    <!--Page Header-->
    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">
            <div class="main-content-body d-flex flex-column">
                <div class="d-flex table-responsive p-3">
                    <div class="mr-2">
                        <div class="btn btn-primary btn-sm rounded-lg fs-13 float-left">
                            <span [routerLink]="['/add-media']"><img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                            Add</span>
                        </div>
                    </div>
                    <!--<div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
            <input class="form-control"
                   (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
          </div>-->
                </div>
                <!-- row -->
                <div class="row row-sm row-cards row-deck">
                    <!-- col -->
                    <div class="col-md-6 col-lg-6 col-xl-3" *ngFor="let x of allMedia">
                        <div class="card mg-b-20">
                            <img class="card-img-top br-tr-0 br-tl-0 " [src]="x.mediaUrl" height="150" width="50" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title mg-b-15 tx-16">{{x.mediaTitle}}</h5>
                                <button class="float-right btn btn-light" style="margin: 10px;" (click)="deleteMedia(x.adminMediaId)"><span ><i class="fas fa-trash"></i></span></button>
                                <button class="float-right btn btn-light" style="margin: 10px;" [routerLink]="['/edit-media/', x.adminMediaId]"><span ><i class="fa fa-pencil"></i></span></button>
                                <mat-slide-toggle name="isActive" [checked]="x.isActive == true" (click)="changeMediaStatus(x.adminMediaId)" title="Charging status">
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                    <!-- /col -->
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <button class="float-center load-more btn btn-primary" (click)="loadMore(currentSize)">Load More</button>
                    </div>
                </div>
                <!-- row -->
            </div>
        </div>
    </div>
</div>