import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";
import { HomeService } from 'src/app/service/home/home.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from '@angular/common';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export type ChartOptions = {

  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DatePipe]
})

export class HomeComponent implements OnInit {
  private unsubscriber : Subject<void> = new Subject<void>();
  allSubscription: any;
  data: any = [];
  form: FormGroup;
  formGroup: FormGroup;
  myData: any;
  totalCandidate: any;
  totalEmployer: any;
  totalActiveJob: any;
  totalPostedJob: any;
  totalSubscribedCandidate: any;
  totalSubscribedEmployer: any;
  totalSubscribedUser: any;
  countOfJobs1: any;
  candidateName: any;
  email: any;
  isActive: any;
  subscription: any;
  latestCandidate: any;
  latestRecruiter: any;

  values: any;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  startDate: any ='';
  endDate: any ='';
  constructor(private homeService: HomeService,
    private fb: FormBuilder, private datePipe: DatePipe) {
    // this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    // this.endDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    this.chartOptions = {
      series: [
        {
          name: "job posted",
          // data:[countOfJobs1],          
          // data: [countOfJobs[0], countOfJobs[1], countOfJobs[2], countOfJobs[3], countOfJobs[4], countOfJobs[5],countOfJobs[6], countOfJobs[7], countOfJobs[8]],

          data: [44, 55, 57, 56, 20, 58, 63, 60, 66],

        },
      ],
      chart: {
        type: "bar",
        height: 380
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          // "" + dateOfJobPosted[0]+ "",
          // "" + dateOfJobPosted[1]+ "",
          // "" + dateOfJobPosted[2]+ "",
          // "" + dateOfJobPosted[3]+ "",
          // "" + dateOfJobPosted[4]+ "",
          // "" + dateOfJobPosted[5]+ "",
          // "" + dateOfJobPosted[6]+ "",
          // "" + dateOfJobPosted[7]+ "",
          // "" + dateOfJobPosted[8]+ "",

          //  "12 -15 Oct",
          //  "15 -18 Oct",
          // "18 -21 Oct",
          // "21 -24 Oct",
          // "24 -27 Oct",
          // "27 -30 Oct",
          // "30 -3 Oct",
          // "3 -6 Oct",
          // "6 -9 Oct",
          // "9 -12 Oct"
        ]
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val;
          }
        }
      }
    };
  }
  ngOnInit(): void {
    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
     alert(`You can't make changes or go back at this time.`);
    });
    this.getAllActiveJobs();
    this.getLatestCandidate();
    this.getLatestRecruiter();
    this.initializeForm();
    this.initializeForm1();
    // this.getSubscriptionStatus();
    this.homeService.getLatestSubscriptionStatus().subscribe((res: any) => {
      this.data = res;
      if (this.data.status == 0) {
        this.data = res.response
        this.allSubscription = res.response;
        console.log(this.allSubscription);
      } else {
        alert(res.response)
      }
    })
  }
  initializeForm(): any {
    this.form = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }
  initializeForm1(): any {
    this.formGroup = this.fb.group({
      startDate: [''],
      endDate: [''],
    });
    this.getJobPostedStatus();
  }

  getAllActiveJobs() {
    this.homeService.getAllActiveJobs().subscribe(res => {
      this.data = res;
      this.totalCandidate = this.data.response.totalCandidate;
      this.totalEmployer = this.data.response.totalEmployer;
      this.totalActiveJob = this.data.response.totalActiveJob;
      this.totalPostedJob = this.data.response.totalPostedJob;
      this.totalSubscribedUser = this.data.response.totalSubscribedUser;
      this.totalSubscribedCandidate = this.data.response.totalSubscribedCandidate;
      this.totalSubscribedEmployer = this.data.response.totalSubscribedEmployer;
    });
  }
  getLatestCandidate() {
    this.homeService.getLatestCandidate().subscribe(res => {
      this.data = res;
      this.latestCandidate = this.data.response;
    })
  }
  getLatestRecruiter() {
    this.homeService.getLatestRecruiter().subscribe(res => {
      this.data = res;
      this.latestRecruiter = this.data.response;
    });
  }

  getJobPostedStatus() {
    if(this.formGroup.value.startDate && this.formGroup.value.endDate){
      this.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'yyyy-MM-dd');
      this.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'yyyy-MM-dd');
    }
    this.homeService.getJobPostedStatus(this.startDate,this.endDate).subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        console.log(this.data.response);
        var countOfJobs = this.data.response.map(t => t.countOfJobs);
        var dateOfJobPosted = this.data.response.map(t => t.dateOfJobPosted);
        this.chartOptions = this.data.response.response
        var countOfJobs1 = "" + countOfJobs + "".replace(/[\[\]']+/g, '');
        let array = countOfJobs;
        for (let i = 0; i < array.length; i++) {
          var aa = array[i];
          console.log(array[i]);
        }
        let array1 = dateOfJobPosted;
        for (let i = 0; i < array1.length; i++) {
          var aa = array[i];
          console.log(array1[i]);
        }

        console.log(aa);
        this.chartOptions = {
          series: [
            {
              name: "job posted",
              data: [countOfJobs[0], countOfJobs[1], countOfJobs[2], countOfJobs[3], countOfJobs[4],
              countOfJobs[5], countOfJobs[6], countOfJobs[7], countOfJobs[8]
              ],
            },
          ],
          chart: {
            type: "bar",
            height: 380
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "40%",
            }
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: [
              "" + dateOfJobPosted[0] + " ",
              "" + dateOfJobPosted[1] + " ",
              "" + dateOfJobPosted[2] + " ",
              "" + dateOfJobPosted[3] + " ",
              "" + dateOfJobPosted[4] + " ",
              "" + dateOfJobPosted[5] + " ",
              "" + dateOfJobPosted[6] + " ",
              "" + dateOfJobPosted[7] + " ",
              "" + dateOfJobPosted[8] + " ",
              // "" + dateOfJobPosted[9] + " ",
              // "" + dateOfJobPosted[10] + " ",
              // "" + dateOfJobPosted[11] + " ",
              // "" + dateOfJobPosted[12] + " ",
              // "" + dateOfJobPosted[13] + "  ",
              // "" + dateOfJobPosted[14] + " ",
              // "" + dateOfJobPosted[15] + " ",
              // "" + dateOfJobPosted[16] + " ",
              // "" + dateOfJobPosted[17] + " ",
              // "" + dateOfJobPosted[18] + " ",
              // "" + dateOfJobPosted[19] + " ",
              // "" + dateOfJobPosted[20] + "",
              // "" + dateOfJobPosted[21] + "",
              // "" + dateOfJobPosted[22] + "",
              // "" + dateOfJobPosted[23] + "",
              // "" + dateOfJobPosted[24] + "",
              // "" + dateOfJobPosted[25] + "",
              // "" + dateOfJobPosted[26] + "",
              // "" + dateOfJobPosted[27] + "",
              // "" + dateOfJobPosted[28] + "",
              // "" + dateOfJobPosted[29] + "",
            ]
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "" + val;
              }
            }
          }
        };
      }
    })

  }

  getSubscriptionStatus() {
    // this.allSubscription = [];
    this.startDate = this.datePipe.transform(this.form.value.startDate, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.form.value.endDate, 'yyyy-MM-dd');
    if (this.form.valid) {
      this.homeService.getSubscription(this.startDate, this.endDate).subscribe((res: any) => {
        this.data = res;
        if (this.data.status == 0) {
          this.data = res.response
          this.allSubscription = res.response;
          console.log(this.allSubscription);
        } else {
          alert(res.response)
        }
      }, (error: any) => {
        alert(error.error.message);
      });

    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
