<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;" >
     <div class="page-header px-0">
       <h3 class="page-title"><span  *ngIf="!id">Add </span>
         <span  *ngIf="id">Edit </span> Voucher</h3>
       <ol class="breadcrumb mb-0">
         <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
         <li class="breadcrumb-item " [routerLink]="['/listVouchar']" >Voucher List</li>
         <li class="breadcrumb-item active" >
           <span  *ngIf="!id">Add </span>
           <span  *ngIf="id">Edit </span>
           Voucher </li>
       </ol>
     </div>
   </div>       
   <!--Page Header-->
     <div class="card">
       <div class="card-body">
   
         <form [formGroup]="formGroup" >
           <div class="row">
            <div class="form-group row col-sm-6">
              <label class="col-sm-12">Voucher Type</label>
              <div class="col-sm-12 ">
                <mat-select
                  formControlName="voucherType" required
                  placeholder="Please Select Voucher"
                  class="form-control" >
                  <mat-option 
                              value="FIXED" aria-label="FIXED">
                    FIXED
                  </mat-option>
                  <mat-option 
                              value="PERCENTAGE" aria-label="PERCENTAGE">
                    PERCENTAGE
                  </mat-option>
                </mat-select>
                <mat-error>
                       <span *ngIf="!formGroup.get('voucherType').valid && formGroup.get('voucherType').touched">
                        Please Select Voucher Type </span>
                </mat-error>
  
              </div>
            </div>
            <div class="form-group row col-sm-6" >
              <label class="col-sm-12">Membership</label>
              <div class="col-sm-12 ">
                <mat-select                
                  formControlName="membershipId" required
                  placeholder="Please Select Membership"
                  class="form-control" >                  
                  <mat-option *ngFor="let r of plandetails"
                              [value]="r.membershipId" [aria-label]="r.membershipId">
                    {{r.membershipName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <span *ngIf="!formGroup.get('membershipId').valid && formGroup.get('membershipId').touched">
                                 Select membership Plan</span>
             </mat-error>
  
              </div>
            </div>
            
            <div class="col-sm-12">
              <div class="form-group row col-sm-12">
                <label class="col-sm-3">	Apply for </label>
               <div class="col-sm-9 ">
                <mat-radio-group aria-label="Select an option" 
                (change)="applyForChange($event)"
                formControlName="appliesTo" required>
                    <mat-radio-button value="INDIVIDUAL">Individual / Selected Company</mat-radio-button>
                    <mat-radio-button value="ALL">All recruiters</mat-radio-button>
                  </mat-radio-group>
                 
                  <mat-error>
                       <span *ngIf="!formGroup.get('appliesTo').valid && formGroup.get('appliesTo').touched">
                                      Please Enter Apply For</span>
                  </mat-error>
                </div>
              </div>
              <div class="form-group row col-sm-12" *ngIf="showCompanyList == true">
                <label class="col-sm-12">Company Select</label>
                <div class="col-sm-12 ">
                  <mat-select multiple
                    formControlName="companyAppliesTo"
                    placeholder="Please Select Company"
                    class="form-control" >                  
                    <mat-option *ngFor="let r of companyList"
                                [value]="r.companyId" [aria-label]="r.companyId">
                      {{r.companyName}}
                    </mat-option>
                  </mat-select>
    
                </div>
              </div>
              <div class="form-group row col-sm-12" *ngIf="countryListShow == true">
                <label class="col-sm-12">Country</label>
                <div class="col-sm-12 ">
                  <mat-select   
                  multiple             
                    formControlName="countryAppliesTo"
                    placeholder="Please Select Country"
                    class="form-control" >                  
                    <mat-option *ngFor="let r of countryList"
                                [value]="r.countryId" [aria-label]="r.countryId">
                      {{r.countryName}}
                    </mat-option>
                  </mat-select>
    
                </div>
              </div>
            </div> 

            <div class="form-group row col-sm-6">
              <label class="col-sm-12">Start Date</label>
             <div class="col-sm-12 ">
               <input  type="date"  
               [min]="fDate"
                  (change)="startDateValidtion($event)"
                  class="form-control"
                  value="" placeholder="Enter Start Date" name="startDate"
                         formControlName="startDate" required>
               <mat-error>
                  <div *ngIf="expMinDateFlag">
                      Date should not be less then Today </div>
                 <div *ngIf="!formGroup.get('startDate').valid && formGroup.get('startDate').touched">
                                    Please enter Start Date</div>
                </mat-error>
              </div>
            </div>
            <div class="form-group row col-sm-6">
              <label class="col-sm-12">Validity</label>
             <div class="col-sm-12 ">
               <input  type="date"  
                  class="form-control"
                  [min] ="startdateVlaidtion"
                  (change)="endDateValidtion($event)"
                  (keydown)="firstSpaceRemoveValid($event)"
                  value="" placeholder="Enter validity" name="validity"
                         formControlName="validity" required>
               <mat-error>
                  <div *ngIf="startDateMinDateFlag">
                      Date should not be less than end date </div>
                 <div *ngIf="!formGroup.get('validity').valid && formGroup.get('validity').touched">
                                    Please enter Validity</div>
                </mat-error>
              </div>
            </div>
           
           <div class="form-group row col-sm-6">
            <label class="col-sm-12">Validity Once Generated (days)</label>
           <div class="col-sm-12 ">
          <input  type="number" 
                class="form-control"
                value="" placeholder="Please Enter Validity" 
                name="validityOnceGenerated"
                (keydown)="firstSpaceRemoveValid($event)"
                       formControlName="validityOnceGenerated" required>
             
              <mat-error>
                   <span *ngIf="!formGroup.get('validityOnceGenerated').valid && formGroup.get('validityOnceGenerated').touched">
                                  Please Enter Validity</span>
              </mat-error>
            </div>
          </div>
           <div class="form-group row col-sm-6">
             <label class="col-sm-12">Value</label>
            <div class="col-sm-12 ">
              <input  type="number"  
                 class="form-control"
                 (keydown)="firstSpaceRemoveValid($event)"
                 value="" placeholder="Enter value" name="value"
                        formControlName="value" required>
              <mat-error>
                <span *ngIf="!formGroup.get('value').valid && formGroup.get('value').touched">
                                   Please enter value</span>
               </mat-error>
             </div>
           </div>
           

         
          <!-- <div class="form-group row col-sm-6">
            <label class="col-sm-3">Status</label>
           <div class="col-sm-9 ">
            <mat-radio-group aria-label="Select an option" 
            formControlName="status" required>
                <mat-radio-button value="true">Active</mat-radio-button>
                <mat-radio-button value="false">Deactive</mat-radio-button>
              </mat-radio-group>
            
            </div>
          </div> -->
          
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Voucher Description</label>
            <div class="col-sm-12"   >
                <textarea
                style="width: 100%;"
                rows="5"
                formControlName="description" required
                placeholder="Please Enter Description"
                name="description"
                > 

                </textarea>
              <mat-error>
                      <span *ngIf="!formGroup.get('description').valid && formGroup.get('description').touched">
                        Please Enter Description</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Voucher Image</label>
            <div class="col-sm-12 row">
              <div class="col-sm-6">
                <label for="imageUrl" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Custom Upload
                </label>
                <input type="file" name="imageUrl" #file formControlName="imageUrl"
                       accept="image/x-png,image/jpeg"
                       class="imageUploadcss" #attachments1 id="imageUrl"
                       (change)="countryFlagSelct($event,file.files)" />
              </div>
              <div class="col-sm-6" *ngIf="countryFlagImgURL">
                <div class="close" (click)="countryFlagReset()"><i
                  class="fas fa-times-circle"></i></div>
                <div class="image-preview">
                  <img [src]="countryFlagImgURL" height="100" />
                </div>
              </div>
            </div>
          </div>
         </div>
         
         <div class="">
           <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!id" (click)="onSubmit()" >Submit</button>
           <button type="submit" class="btn btn-success" (click)="onSubmit()" *ngIf="id">Update</button>
         </div>
         </form>
       </div>
     </div>
   </div>
   