import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import { MatDialog } from '@angular/material/dialog';
import {CommonConfirmPopupComponent} from 'src/app/component/common-confirm-popup/common-confirm-popup.component';
import {LanguageService} from "../../../service/language/language.service";

@Component({
  selector: 'app-list-job-board',
  templateUrl: './list-job-board.component.html',
  styleUrls: ['./list-job-board.component.scss']
})
export class ListJobBoardComponent implements OnInit {

  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId:any;
  langList =[];
  selectLang: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private masterData :MasterDataService,
              public dialog: MatDialog,
              public langService :LanguageService) {
  }


  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.langList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.langList[0].languageCode;
    this.displayedColumns = [ 'srno', 'jobBoardName', 'jobBoardDescription', 'planPriceA', 'planPriceB','planPriceC','planPriceD','isActive', 'actions'];
    this.tabelLoad(this.selectLang);
  }


  tabelLoad(langCode){
    this.masterData.getAllJobBoardList(langCode).subscribe((res:any) =>{
      this.data = res;
      console.log(this.data);
      if(this.data.response){
        this.dataSource = new MatTableDataSource(this.data.response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    })
  }
  LangChange(e){
    this.selectLang= e.value;
    this.tabelLoad(this.selectLang);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  delete(rowid: number) {
    if (rowid > -1) {
      this.dataSource.data.splice(rowid, 1);
      this.dataSource._updateChangeSubscription();
    }
  }
  changeStatus(e,element):any{
    var msg;
    if(element.isActive){
      msg = 'Are you sure wants to Deactivate status?'
    } else{
      msg = 'Are you sure wants to Activate status?'
    }
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: msg,
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.masterData.jobBoardStatusChange(element.addonJobBoardId).subscribe( res =>{
          this.data = res;
          if(this.data.status == 0 ){
            this.tabelLoad(this.selectLang);
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          }
        })
      } else{
        e.source.checked= element.isActive;
       }
    });
  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.categoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any) {
    // console.log(e);
    this.selectproduct = e.categoryName;
    this.selectedId = e.jobCategoryId;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search(){

    this.router.navigate(['editjobcategory',this.selectedId]);

  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage(){
    // this.tabelLoad(0);
  }
  lastpage(){
    // this.tabelLoad(this.totalpageLenght);
  }
  prevpage(){
    const d = this.page - 1;
    if (d > 0){
      // this.tabelLoad(d);
    }
  }
  nextpage(){
    const d = this.page + 1;
    if ( d < this.totalpageLenght){
      // this.tabelLoad(d);
    }
  }


  deleteJobBoardDetails(id) {
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: "Are you sure wants to delete?",
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.masterData.deleteJobBoardDetails(id)
        .subscribe(res => {
          this.data = res;
          if(this.data.status == 0 ){
            this.tabelLoad(this.selectLang);
            this.snackBar.open('Job board deleted succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          } else {
            this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }, error => console.log(error));
      }
    });
  }
}
