import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
export interface DialogData {
  question: any;
  qImage: any;
}


@Component({
  selector: 'app-image-question-popup',
  templateUrl: './image-question-popup.component.html',
  styleUrls: ['./image-question-popup.component.scss']
})
export class ImageQuestionPopupComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<ImageQuestionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
