<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
                <span *ngIf="!viewID">Add New</span>
                <span *ngIf="viewID">Edit</span> Media
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item ">
                    <span [routerLink]="['/media-list']">List Of Media</span>
                </li>
                <li class="breadcrumb-item active">
                    <span>
              <span *ngIf="!viewID">Add New</span>
                    <span *ngIf="viewID">Edit</span> Media
                    </span>
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12">Media Type<span>*</span></label>
                        <div class="col-sm-12 ">
                            <mat-select formControlName="mediaType" required [ngClass]="{ 'is-invalid': submitted && formGroup.get('mediaType').errors }" placeholder="Please Select Media Type" class="form-control">
                                <mat-option *ngFor="let r of allMediaType" [value]="r.value" [aria-label]="r.value">
                                    {{r.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <span *ngIf="!formGroup.get('mediaType').valid && formGroup.get('mediaType').touched">
                      Please Select Media Type</span>
                            </mat-error>
                        </div>
                    </div>

                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12">Media Title<span>*</span></label>
                        <div class="col-sm-12 ">
                            <input type="text" class="form-control" value="" placeholder="Enter Media Title" name="response" formControlName="mediaTitle">
                            <mat-error>
                                <span *ngIf="!formGroup.get('mediaTitle').valid && formGroup.get('mediaTitle').touched">
                      Please Enter Media Title</span>
                            </mat-error>

                        </div>
                    </div>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12">Media  URL</label>
                        <div class="col-sm-12 ">
                            <input type="text" class="form-control" value="" placeholder="Enter Media Url" name="redirectUrl" formControlName="redirectUrl">


                        </div>
                    </div>

                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Media<span>*</span></label>
                        <div class="col-sm-12 row">
                            <div class="col-sm-6">
                                <label for="media" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Custom Upload
                </label>
                                <input type="file" name="country" #file formControlName="media" accept="image/x-png,image/jpeg" class="imageUploadcss" #attachments1 id="media" (change)="countryFlagSelct($event,file.files)" />
                            </div>
                            <div class="col-sm-6" *ngIf="countryFlagImgURL">
                                <div class="close" (click)="countryFlagReset()"><i class="fas fa-times-circle"></i></div>
                                <div class="image-preview">
                                    <img *ngIf="countryFlagImgURL == null" height="100" />
                                    <img *ngIf="countryFlagImgURL != null" [src]="countryFlagImgURL" height="100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button [routerLink]="['/media-list']" type="submit" class="btn btn-danger  pd-x-20 mg-t-10 mar15">Back</button>
                <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!viewID">Submit</button>
                <button (click)="updateMedia()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="viewID">Update</button>
            </form>

        </div>
    </div>

</div>