<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Employer Customize List</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active" [routerLink]="['/editcustomizePlan']">Employer Customize List</li>
        </ol>
    </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
    <div class="card">
        <div class="c_table">
            <div class="mat-elevation-z8">

                <div class="d-flex table-responsive p-3">
                    <div class="mr-2">
                        <div class="btn btn-primary btn-sm rounded-lg fs-13">
                            <span [routerLink]="['/AddCustomizePlan']">
                                <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                                Add  Customize Plan</span>
                        </div>
                    </div>
                    <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                    </div>
                </div>
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="srno">
                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                        <td mat-cell *matCellDef="let element;let j = index">
                            <!-- <span *ngIf="page == 0" > {{j + 1}}</span>
                              <span *ngIf="page != 0" >{{((page * size) +   (j + 1))}}</span> -->
                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                        </td>
                    </ng-container>


                    <div>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
                            <td mat-cell *matCellDef="let element">{{element.id}}</td>
                        </ng-container>
                        <ng-container matColumnDef="companyName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> company name</th>
                            <td mat-cell *matCellDef="let element">{{element.companyName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="planName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> plan name</th>
                            <td mat-cell *matCellDef="let element">{{element.planName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="monthlyCost">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> plan cost</th>
                            <td mat-cell *matCellDef="let element">{{element.monthlyCost}}</td>
                        </ng-container>
                        <ng-container matColumnDef="planDescription">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Plan Billing</th>
                            <td mat-cell *matCellDef="let element">{{element.planDescription}}</td>
                        </ng-container>
                        <ng-container matColumnDef="roleId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <span>roleId</span>
                            </th>
                            <td mat-cell *matCellDef="let element">{{element.roleId}}</td>
                        </ng-container>
                    </div>
                    <!-- <ng-container matColumnDef="isActive">
                        <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            <span>
                                <mat-slide-toggle (change)="oldpermissionChange($event,element)" name="isActive"
                                    [checked]="element.isActive"></mat-slide-toggle>
                            </span>
                        </td> -->
                    <!-- </ng-container> -->
                    <ng-container matColumnDef="status">
                        <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            <span>
                          <mat-slide-toggle name="status"  (change)="changeStatus($event,element)" [checked]="element.status"></mat-slide-toggle>
                          </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                            <div class="d-flex justify-content-center">
                                <span class="btn btn-sm btn-primary mar15" [routerLink]="['/addEditCustomizePlan', element.id]">
                                    <i class="fas fa-user-edit"></i></span>
                                <span class="btn btn-sm btn-danger rounded-lg" (click)="deletePlan(element.id)"><i
                                        class="fas fa-trash"></i></span>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>

            </div>


        </div>
    </div>
</div>