<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
        <h3 class="page-title">Manage Language Files</h3>
        <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item active">Manage Language Files</li>
        </ol>
    </div>
</div>       
<!--Page Content Web -->

<div class="col-sm-12">
    <!-- <h4 class="page-title2">Web Language Files</h4> -->
    <div class="card">
        <div class="c_table">
        <div class="mat-elevation-z8">
            <div class="d-flex table-responsive p-3">
                <div class="col-sm-4">
                    <label>Language</label>
                    <mat-select [ngModel]="selectLang"
                      (selectionChange)="LangChange($event,true)"    
                      class="form-control" >
                    <mat-option *ngFor="let r of LangList"      
                                [value]="r.languageId" [aria-label]="r.languageId">
                    {{r.languageName}}
                    </mat-option>
                    </mat-select>
                </div>
            </div>
        
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="srno">
                <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                <td mat-cell *matCellDef="let element;let j = index">
                {{ (j + 1) }}
                </td>
            </ng-container>
            <!-- Section -->
            <ng-container matColumnDef="appName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Section</th>
                <td mat-cell *matCellDef="let element">{{element.appName}} <span *ngIf="!element.useLocation">MOBILE</span></td>
            </ng-container>
            <!-- Updated Date -->
            <ng-container matColumnDef="lastUpdatedEpochTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated Date</th>
                <td mat-cell *matCellDef="let element">{{element.lastUpdatedEpochTime | date:'mediumDate'}}</td>
            </ng-container>
            <!-- Publish Date -->
            <ng-container matColumnDef="publishedEpochTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Publish Date</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.publishedEpochTime">{{element.publishedEpochTime | date:'mediumDate'}}</span>
                    <span *ngIf="!element.publishedEpochTime">-</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th width="80px" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex justify-content-center">
                        <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/editTranslation', element.appName,element.useLocation]" matTooltip="Edit Language" matTooltipPosition="above">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15" matTooltip="Upload Excel" (click)="uploadFile(element.useLocation,element.appName,element.languageId)" matTooltipPosition="above">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15" matTooltip="Download Excel" (click)="downloadFile(element.useLocation,element.appName,element.languageId)"  matTooltipPosition="above">
                            <i class="fa fa-download" aria-hidden="true"></i>
                        </span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15" *ngIf="!element.isPublished" (click)="publishLanguageFile(element.appName,element.languageId,element.useLocation)" matTooltip="Publish Language" matTooltipPosition="above">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15 grey-btn" *ngIf="element.isPublished" matTooltip="Published" matTooltipPosition="above">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        </div>  
    </div>
</div>

<!--Page Content Mobile -->
<!-- <div class="col-sm-12">
    <div class="card">
        <div class="c_table">
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceMobile" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="srno">
                <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                <td mat-cell *matCellDef="let element;let j = index">
                {{ (j + 1) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="appName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Section</th>
                <td mat-cell *matCellDef="let element">{{element.appName}}</td>
            </ng-container>
            <ng-container matColumnDef="lastUpdatedEpochTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated Date</th>
                <td mat-cell *matCellDef="let element">{{element.lastUpdatedEpochTime | date:'mediumDate'}}</td>
            </ng-container>
            <ng-container matColumnDef="publishedEpochTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Publish Date</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.publishedEpochTime">{{element.publishedEpochTime | date:'mediumDate'}}</span>
                    <span *ngIf="!element.publishedEpochTime">-</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th width="80px" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex justify-content-center">
                        <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/editTranslation', element.appName,element.useLocation]" matTooltip="Edit Language" matTooltipPosition="above">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15" matTooltip="Upload Excel" matTooltipPosition="above">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15" matTooltip="Download Excel" matTooltipPosition="above">
                            <i class="fa fa-download" aria-hidden="true"></i>
                        </span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15" *ngIf="!element.isPublished" (click)="publishLanguageFile(element.appName,element.languageId,element.useLocation)" matTooltip="Publish Language" matTooltipPosition="above">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15 grey-btn" *ngIf="element.isPublished" matTooltip="Published" matTooltipPosition="above">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                        </span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        </div>  
    </div>
</div> -->
