<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!restrictedKeyWordId">Add </span>
        <span *ngIf="restrictedKeyWordId">Edit </span> Restricted Keyword
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listRestrictedKeywords']">List Restricted Keyword</li>
        <li class="breadcrumb-item active">
          <span *ngIf="!restrictedKeyWordId">Add </span>
          <span *ngIf="restrictedKeyWordId">Edit </span>
          Restricted Keyword
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="dynamicForm" class="row" appInvalidControlScroll>
        <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-6">
          <div [formGroup]="ticket" class="col-sm-12">
            <div class="form-group row ">
              <label class="col-sm-12">
                {{LangList[i].languageName}} Restricted Keyword </label>
              <div class="col-sm-12 ">
                <input type="text" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && ticket.get('keywordName').errors }"
                  (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter restricted keyword name"
                  name="name" formControlName="keywordName" required>
                <mat-error>
                  <span *ngIf="!ticket.get('keywordName').valid && ticket.get('keywordName').touched">
                    Please enter name</span>
                </mat-error>
                <!-- <mat-error>
             <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">
                            Please enter job  category name</span>
        </mat-error> -->
              </div>
            </div>
            <div class="form-group row ">
              <label class="col-sm-12">
                {{LangList[i].languageName}} Restricted Keyword Description </label>
              <div class="col-sm-12 ">
                <angular-editor [ngClass]="{ 'is-invalid': submitted && ticket.get('description').errors }"
                  [config]="config" formControlName="description" required (keydown)="firstSpaceRemoveValid($event)"
                  placeholder="Please Enter Restricted Keyword Description" name="description"></angular-editor>
                <mat-error>
                  <span *ngIf="!ticket.get('description').valid && ticket.get('description').touched">
                    Please enter description</span>
                </mat-error>

                <!-- <input  type="text"
          class="form-control"
          (keydown)="firstSpaceRemoveValid($event)"
          value="" placeholder="Enter job category name" name="name"
                 formControlName="description" required>-->

                <!-- <mat-error>
             <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">
                            Please enter job  category name</span>
        </mat-error> -->
              </div>
            </div>

          </div>
        </div>

        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10 " (click)="onSubmit()"
          *ngIf="!restrictedKeyWordId">Submit</button>
        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10 " (click)="onSubmit()"
          *ngIf="restrictedKeyWordId">Update</button>
      </form>

    </div>
  </div>

</div>