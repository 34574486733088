<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">Edit</span>
        job post question
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listjobTestQuestion']">List Of job post question</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!viewID">Add New</span>
            <span *ngIf="viewID">Edit</span>
            job post question</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <!-- <div class="form-group row col-sm-12">
          <label class="col-sm-12">Option number</label>
         <div class="col-sm-12 ">
          <mat-select
        [(ngModel)]="seletedOption"
        (selectionChange)="optionCreate($event)"
      placeholder="select option value"
        class="form-control" >
        <mat-option value="0">--select number Option </mat-option>
<mat-option *ngFor="let t of optionList"
          [value]="t.value" [aria-label]="t.value">
{{t.name}}
</mat-option>
</mat-select>
          </div>
        </div>         -->

      <form [formGroup]="formGroup" appInvalidControlScroll>
        <div class="row">
          <div class="row col-sm-12">
            <div class="form-group row col-sm-4">
              <label class="col-sm-12">Language Name</label>
              <div class="col-sm-12 ">
                <mat-select formControlName="languageId" required (selectionChange)="LangChange($event)"
                  [ngClass]="{ 'is-invalid': submitted && formGroup.get('languageId').errors }"
                  placeholder="Please Select Language Name" class="form-control">
                  <mat-option *ngFor="let r of LangList" [value]="r.languageId" [aria-label]="r.languageId">
                    {{r.languageName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <span *ngIf="!formGroup.get('languageId').valid && formGroup.get('languageId').touched">
                    Please Select Language Name </span>
                </mat-error>

              </div>
            </div>

            <div class="form-group row col-sm-4">
              <label class="col-sm-12">Question Type</label>
              <div class="col-sm-12 ">
                <mat-select [disabled]="viewID" formControlName="questionTypeId" required
                  (selectionChange)="QuestionTypeSelectionDropDown($event)"
                  [ngClass]="{ 'is-invalid': submitted && formGroup.get('questionTypeId').errors }"
                  placeholder="Please Select Question Type" class="form-control">
                  <mat-option *ngFor="let t of qustTypeList" [value]="t.questionTypeId" [aria-label]="t.questionTypeId">
                    {{t.questionType}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <span *ngIf="!formGroup.get('questionTypeId').valid && formGroup.get('questionTypeId').touched">
                    Please Select Question Type </span>
                </mat-error>
              </div>
            </div>
            <div class="form-group row col-sm-4">
              <label class="col-sm-12">Question Group</label>
              <div class="col-sm-12 ">
                <mat-select (openedChange)="myInput.focus()"
                (closed)="myInput.value='';search(myInput.value)" 
                  [ngClass]="{ 'is-invalid': submitted && formGroup.get('questionGroupId').errors }"
                  formControlName="questionGroupId" required placeholder="Please Select Question Group"
                  class="form-control">
                  <input class="myInput form-control" #myInput focused="'true'" type="text"
                  (keydown)="$event.stopPropagation()" 
                  (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                  <mat-option *ngFor="let r of qustGropList" [value]="r.questionGroupId"
                    [aria-label]="r.questionGroupId">
                    {{r.groupName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <span *ngIf="!formGroup.get('questionGroupId').valid && formGroup.get('questionGroupId').touched">
                    Please Select Question Group </span>
                </mat-error>
              </div>
            </div>
          </div>
          <!-- <div class="form-group row col-sm-12">
               <label class="col-sm-12">Question Title</label>
              <div class="col-sm-12"   [ngClass]="{'is-invalid': submitted && formGroup.get('questionTitle').errors }">
                <angular-editor
                formControlName="questionTitle" required
                [ngClass]="{'is-invalid': submitted && formGroup.get('questionTitle').errors }"
                placeholder="Please Enter Question Title"
                [config]="config" name="questionTitle"></angular-editor>
                 <mat-error>
                      <span *ngIf="!formGroup.get('questionTitle').valid && formGroup.get('questionTitle').touched">
                        Please Enter Question Title</span>
                 </mat-error>
               </div>
             </div> -->

          <div class="form-group row col-sm-12">
            <label class="col-sm-12">Question</label>
            <div class="col-sm-12" [ngClass]="{'is-invalid': submitted && formGroup.get('question').errors }">
              <angular-editor formControlName="question" required class="mrin questionTitleREmove" (oninput)="removeAtt($event)"
                (focus)="removeAtt($event)" (blur)="removeAtt($event)" (paste)="removeAtt($event)"
                (keypress)="removeAtt($event)"
                [ngClass]="{'is-invalid': submitted && formGroup.get('question').errors }"
                placeholder="Please Enter Question" [config]="config" name="question"></angular-editor>
              <mat-error>
                <span *ngIf="!formGroup.get('question').valid && formGroup.get('question').touched">
                  Please Enter Question</span>
              </mat-error>
            </div>
          </div>


          <div class="form-group row col-sm-12">
            <label class="col-sm-3">Image</label>
            <div class="col-sm-9 row">
              <div class="col-sm-3">
                <label for="imgquestion" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Custom Upload
                </label>
                <input type="file" name="imgquestion" formControlName="questionImage" multiple=""
                  accept="image/x-png,image/jpeg" class="imageUploadcss" id="imgquestion"
                  (change)="fileChangeEvent($event)" />
              </div>
              <div class="col-sm-9 row" *ngIf="images.length > 0 "
                style="background-color: rgba(28, 117, 188, 0.1098039216);">

                <div *ngFor='let url of images;let i = index;' class="uplodedImg col-sm-3 postiion-relative">
                  <div class="close" (click)="removeUploadedImages(i)"><i class="fas fa-times-circle"></i></div>
                  <img [src]="url" height="150">
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 mt-3 mb-3" *ngIf="GetTimeQuestionImage.length > 0">
            <div class="row" style="background-color: rgba(28, 117, 188, 0.1098039216);">
              <div *ngFor='let url of GetTimeQuestionImage;let i = index;'
                class="uplodedImg getimgaes col-sm-3 postiion-relative">
                <div class="close" (click)="removeGetImage(url.id , i)"><i class="fas fa-times-circle"></i></div>
                <img [src]="url.assesmentQuestionImage" height="150">
              </div>
            </div>
          </div>

          <div class="col-sm-12" *ngIf="typeSelect == false">
            <div class="form-group row col-sm-12">
              <label class="col-sm-12">Question's answer Explanation</label>
              <div class="col-sm-12 ">
                <angular-editor class="mrin questionTitleREmove" (oninput)="removeAtt($event)" (focus)="removeAtt($event)"
                  (blur)="removeAtt($event)" (paste)="removeAtt($event)" (keypress)="removeAtt($event)" value=""
                  placeholder="Enter Question answer Explanation" name="answerExplanation"
                  formControlName="answerExplanation" [config]="config">
                </angular-editor>
                <!-- 
                   [ngClass]="{ 'is-invalid': submitted && !(thumbImageURL) && formGroup.get('answerExplanation').errors }"
                  [required]="typeSelect == false || ! (thumbImageURL == null || thumbImageURL == '' || thumbImageURL == undefined)"  -->
                <!-- <mat-error>
                  <span *ngIf=" !(thumbImageURL) && !formGroup.get('answerExplanation').valid && formGroup.get('answerExplanation').touched">
                    Enter Question answer Explanation</span>
                </mat-error> -->
              </div>
            </div>
            <!-- ThumbImage -->
            <div class="form-group row ">
              <label class="col-sm-3">Answer Uploaded Images</label>
              <div class="col-sm-9 row align-items-center">
                <div class="col-sm-6">
                  <label for="thumbImage" class="custom-file-upload">
                    <i class="fa fa-cloud-upload"></i> Answer Upload
                  </label>
                  <input type="file" name="thumbImage" #file2 accept="image/x-png" class="imageUploadcss" #attachments2
                    id="thumbImage" (change)="AnswerfileChangeEvent($event,file2.files)" />
                </div>
                <div class="col-sm-6" *ngIf="thumbImageURL">
                  <div class="close" (click)="thumbImageReset()"><i class="fas fa-times-circle"></i></div>
                  <div class="image-preview">
                    <img [src]="thumbImageURL" height="100" />
                  </div>
                </div>
              </div>
            </div>


            <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-12">
              <div [formGroup]="ticket" class="col-sm-12 row align-items-center" style="width: 100%;">
                <div class="form-group row col-sm-10">
                  <label class="col-sm-12">
                    option{{i+1}} </label>
                  <div class="col-sm-12 optioneditor">
                    <!-- <angular-editor class="mrin optioneditor" (oninput)="removeAtt($event)" (focus)="removeAtt($event)"
                      (blur)="removeAtt($event)" (paste)="removeAtt($event)" (keypress)="removeAtt($event)"
                      [ngClass]="{ 'is-invalid': submitted && ticket.get('response').errors }"
                      (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter option" name="response"
                      id="optinda{{i}}" formControlName="response" [required]="typeSelect == false"
                      [config]="optionconfig">
                    </angular-editor> -->
                    <input type="text" class="form-control customInput"
                      [ngClass]="{ 'is-invalid': submitted && ticket.get('response').errors }"
                      (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter option" name="response"
                      id="optinda{{i}}" formControlName="response" [required]="typeSelect == false">
                    <mat-error>
                      <span *ngIf="submitted && ticket.get('response').errors">
                        Please Enter Option Name </span>
                    </mat-error>
                  </div>
                </div>
                <div class=" col-sm-1 ">
                  <mat-checkbox class="example-margin" [ngClass]="{'is-invalid': CorrecTanswerNotSlect == true}"
                    formControlName="valid" [checked]="CorrectAnserArray[i] == true"
                    (change)="onCheckboxChange($event,i)">
                  </mat-checkbox>

                </div>
                <div class=" col-sm-1 " *ngIf="!(i == 1 || i == 0)">
                  <i class="fas fa-trash" (click)="deleteOption(i)"></i>
                </div>
              </div>
            </div>
            <mat-error *ngIf="CorrecTanswerNotSlect == true">

            </mat-error>
            <div class="col-sm-12 justify-content-end d-flex" *ngIf="addBtnHide == false">
              <button type="button" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="newOptionadd()">
                <i class="fa fa-plus" aria-hidden="true"></i>Add New Option
              </button>
            </div>

          </div>



        </div>

        <button [routerLink]="['/listjobTestQuestion']" type="button"
          class="btn btn-danger  pd-x-20 mg-t-10 mar15">Back</button>
        <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="!viewID">Submit</button>
        <button (click)="onSubmit('1')" type="submit" class="btn btn-success pd-x-20 mg-t-10 mar15"
          *ngIf="!viewID && typeSelect == false">Submit & Next</button>
        <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
          *ngIf="viewID">Update</button>
      </form>

    </div>
  </div>

</div>