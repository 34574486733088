<div class="container">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
                <span>View</span> System Generated CV</h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item ">
                    <span [routerLink]="['/view-candidate/',candidateId]">Candidate Details</span>
                </li>
                <li class="breadcrumb-item active">
                    <span>
              <span>View</span> Candidate CV</span>
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="">
        <!-- <div *ngIf="showingData">
      <div class="cv-div" id="contentToConvert">
        <div class="head2">
          <div class="row">
            <div class="col-sm-8">
              <p class="title">{{cvData.name}}</p>
            </div>
            <div class="col-sm-4">
              <p class="l1">Address : {{cvData.address}},
                <span *ngIf="cvData.address2">{{cvData.address2}} </span></p>
              <p class="l1">Phone : {{cvData.phone}}</p>
              <p class="l1">Email : {{cvData.email}}</p>
            </div>
          </div>
        </div>
        <div class="head3">
          <div *ngIf="cvData.summary">
            <div class="heading-div">
              <p class="title">Summary</p>
            </div>
            <p class="desc" [innerHTML]="cvData.summary"></p>
          </div>
          <div *ngIf="cvData.compatencies">
            <div class="heading-div">
              <p class="title">Competencies</p>
            </div>
            <div class="inner-content">
              <div class="row">
                <div class="col-sm-12 newcls">
                  <ul>
                    <li *ngFor="let data of compentencyData">{{data}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="cvData.experiences.length">
            <div class="heading-div">
              <p class="title">Experience</p>
            </div>
            <div *ngFor="let data of cvData.experiences">
              <p class="desc"><span class="font-w700">{{data.jobTitle}}</span> - {{data.fromDate}} <span *ngIf="data.toDate">to {{data.toDate}}</span></p>
              <span class="desc"><span class="font-w700">{{data.companyName}},</span> {{data.city}}</span>
              <div class="desc" [innerHTML]="data.responsibility"></div>
            </div>
          </div>

          <div *ngIf="cvData.educations.length">
            <div class="heading-div">
              <p class="title">Education</p>
            </div>
            <p class="desc"  *ngFor="let data of cvData.educations">
              {{data.educationFaculty}} :
              <span class="font-w700">{{data.qualification}} -</span>
              {{data.fromDate}} to {{data.toDate}}
            </p>
          </div>
          <div *ngIf="cvData.additionalSkill">
            <div class="heading-div">
              <p class="title">Additional Skills</p>
            </div>
            <div class="inner-content">
              <div class="row">
                <div class="col-sm-12 newcls" >
                  <ul>
                    <li *ngFor="let data of skills">{{data}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="cvData.languages.length">
            <div class="heading-div">
              <p class="title">Language</p>
            </div>
            <p class="desc" *ngFor="let data of cvData.languages">{{data.language}}
              <span *ngFor="let data of data.rating">
                                <img  src="../../../assets/img/circle_star.svg" class="section-img" alt="logo">
                            </span>
            </p>
          </div>
          <div *ngIf="cvData.certificates">
            <div class="heading-div">
              <p class="title">Certifications</p>
            </div>
            <div class="inner-content">
              <div class="row">
                <div class="col-sm-12 newcls">
                  <ul *ngIf="certificates">
                    <li *ngFor="let data of certificates">{{data}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="cvData.additionalCertificatesCategory">
            <div class="heading-div">
              <p class="title">Additional Certifications</p>
            </div>
            <p class="desc">{{cvData.additionalCertificatesCategory}} : <span class="font-w700">{{cvData.additionalCertificates}}</span></p>
          </div>
          <div *ngIf="cvData.reference.length">
            <div class="heading-div">
              <p class="title">References</p>
            </div>
            <p class="desc" *ngFor="let data of cvData.reference">
              <span *ngIf="!data.onRequest" [innerHtml]="data.description"></span>
              <span *ngIf="data.onRequest">Available On Request</span>
            </p>
          </div>
        </div>
        <div class="footer-div"></div>
      </div>
    </div> -->
        <!-- <iframe [src]="cleanURL(cvUploadUrl)" width="1000" height="1000" *ngIf="showingIframData"></iframe> -->
        <div class="white-bg">
            <div class="mb-5 text-center">
                <div class="row mb-4">
                    <div class="col-md-8 mx-auto">
                        <h2 class="text2 text-color text-center text-uppercase mb-3 mt-3">System Generated CV </h2>
                        <iframe [src]="uploadCvUrl | safe1" width="100%" height="1000">
    </iframe>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center  mb-3">
                        <!-- <button type="submit" class="btn button-outline">SKIP</button> -->
                        <button type="submit" class="btn btn-primary btn-sm rounded-lg fs-13" [routerLink]="['/view-candidate/', candidateId]"> Back To Candidate List</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>