<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Manage Request CV List</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active">Manage Request CV List</li>
        </ol>
    </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
    <div class="card">
        <div class="c_table">
            <div class="mat-elevation-z8">
                <div class="d-flex table-responsive p-3">
                    <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                    </div>
                </div>
                <table mat-table [dataSource]="dataSource" matSort class="table-striped contact-table">
                    <ng-container matColumnDef="srno">
                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                        <td mat-cell *matCellDef="let element;let j = index">
                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                        </td>
                    </ng-container>

                    <div>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                            <td mat-cell *matCellDef="let element">{{element.firstName }} {{element.lastName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                            <td mat-cell *matCellDef="let element">{{element.candidateEmail}}</td>
                        </ng-container>
                        <ng-container matColumnDef="mobileNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number</th>
                            <td mat-cell *matCellDef="let element">{{element.mobileNumber}}</td>
                        </ng-container>
                    </div>
                    <!-- <ng-container matColumnDef="actions">
                        <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                            <div class="d-flex justify-content-center">
                                <span class="btn btn-sm btn-primary rounded-lg mar15" [routerLink]="['/contactViewList', element.requestId]">
                                <img src="../../../../assets/img/eye.svg" alt="eye">
                              </span>
                                <span class="btn btn-sm btn-danger rounded-lg" (click)="deleteContact(element.requestId)"><i
                                  class="fas fa-trash"></i></span>
                            </div>
                        </td>
                    </ng-container> -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>





            </div>


        </div>
    </div>
</div>