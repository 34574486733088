export class DynamicLabelClass {
  ckeditorconfig: any;
  ckeditorconfigForOption: any;
  dynamicLabel: any;
  sidebar: any[];
  dashboard: any[];
  userlevelLink: any[];
  manageTeam: any[];
  manageMaster: any[];
  managePersonalityTest: any[];
  manageRoleSetting: any[];
  manageSetting: any[];
  manageBalance: any[];
  darkStyle: any[];
  customerSupport: { line: number; value: string; link: string; label: string; index: number; }[];
  companySetting: any[];
  reports: { line: number; value: string; link: string; label: string; index: number; }[];

  testTagsConfiguration: any;
  
  constructor() {
    // this.dynamicLabel = JSON.parse( localStorage.getItem('dynamicLabelName'));

    // this.userlevelLink = [
    // {value: this.dynamicLabel[1].labelName, level: 100},
    // {value: this.dynamicLabel[2].labelName, level: 2},
    // {value: this.dynamicLabel[3].labelName, level: 101},
    // {value: this.dynamicLabel[4].labelName, level: 3},
    // {value: this.dynamicLabel[5].labelName, level: 102},
    // {value: this.dynamicLabel[6].labelName, level: 4},
    // {value: this.dynamicLabel[7].labelName, level: 5},
    // {value: this.dynamicLabel[8].labelName, level: 6}];
    this.darkStyle = [
      {
        'featureType': 'all',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#242f3e'
          }
        ]
      },
      {
        'featureType': 'all',
        'elementType': 'labels.text.stroke',
        'stylers': [
          {
            'lightness': -80
          }
        ]
      },
      {
        'featureType': 'administrative',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#746855'
          }
        ]
      },
      {
        'featureType': 'administrative.locality',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#d59563'
          }
        ]
      },
      {
        'featureType': 'poi',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#d59563'
          }
        ]
      },
      {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#263c3f'
          }
        ]
      },
      {
        'featureType': 'poi.park',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#6b9a76'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'color': '#2b3544'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#9ca5b3'
          }
        ]
      },
      {
        'featureType': 'road.arterial',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'color': '#38414e'
          }
        ]
      },
      {
        'featureType': 'road.arterial',
        'elementType': 'geometry.stroke',
        'stylers': [
          {
            'color': '#212a37'
          }
        ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'color': '#746855'
          }
        ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'geometry.stroke',
        'stylers': [
          {
            'color': '#1f2835'
          }
        ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#f3d19c'
          }
        ]
      },
      {
        'featureType': 'road.local',
        'elementType': 'geometry.fill',
        'stylers': [
          {
            'color': '#38414e'
          }
        ]
      },
      {
        'featureType': 'road.local',
        'elementType': 'geometry.stroke',
        'stylers': [
          {
            'color': '#212a37'
          }
        ]
      },
      {
        'featureType': 'transit',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#2f3948'
          }
        ]
      },
      {
        'featureType': 'transit.station',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#d59563'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [
          {
            'color': '#17263c'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [
          {
            'color': '#515c6d'
          }
        ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels.text.stroke',
        'stylers': [
          {
            'lightness': -20
          }
        ]
      }
    ];
    this.dashboard = [
      { line: 1, value: 'DASHBOARD', link: '/home', label: 'Dashboard', index: 1 },
    ];
    this.manageTeam = [
      { line: 2, value: 'LIST_Team_Member', link: '/teamList', label: 'List Team Member', index: 2 },
    ];
    this.manageRoleSetting = [
      { line: 3, value: 'Manaage_Role_Access', link: '/rollList', label: 'Manage Role & Access', index: 3 },
      { line: 4, value: 'System_Configuration', link: '/rollList', label: 'System Configuration', index: 3 },
    ];
    this.manageMaster = [
      { line: 5, value: 'Job_Category', link: '/listjobcategory', label: 'Manage Job Category', index: 4 },
      { line: 6, value: 'Job_Sub_Category', link: '/listjobsubcategory', label: 'Manage Job Sub Category', index: 4 },
      { line: 7, value: 'Job_Industry', link: '/listIndusrty', label: 'Manage Job Industry', index: 4 },
      { line: 8, value: 'Country_Data', link: '/listCountry', label: 'Manage Country Data', index: 4 },
      { line: 9, value: 'Skill_Sets', link: '/listSkills', label: 'Manage Skill Sets', index: 4 },
    ];
    this.managePersonalityTest = [
      { line: 10, value: 'Category_List', link: '/listCategory', label: 'Category List', index: 5 },
      { line: 11, value: 'Manage_Question', link: '/listQuestions', label: 'Manage Questions', index: 5 },
    ];

    this.sidebar = [...this.dashboard, ...this.manageTeam, ...this.manageRoleSetting, ...this.manageMaster, ...this.managePersonalityTest,
    ];
    this.ckeditorconfig = {
      editable: true,
      spellcheck: true,
      height: '14rem',
      minHeight: '7rem',
      maxHeight: '60rem',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      sanitize: true,
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [],
      customClasses: [
        // {
        //   name: 'quote',
        //   class: 'quote',
        // },
        // {
        //   name: 'redText',
        //   class: 'redText'
        // },
        // {
        //   name: 'titleText',
        //   class: 'titleText',
        //   tag: 'h1',
        // },
      ],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        [
          'undo',
          'redo',
          'strikeThrough',
          'subscript',
          'superscript',
          'customClasses',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'heading',
          'fontName'
        ],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode'
        ]
      ]
    };
    this.ckeditorconfigForOption = {
      editable: true,
      spellcheck: true,
      height: '2.5rem',
      minHeight: '2.5rem',
      maxHeight: '5.5rem',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: false,
      placeholder: 'Enter text here...',
      sanitize: true,
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [],
      customClasses: [
        // {
        //   name: 'quote',
        //   class: 'quote',
        // },
        // {
        //   name: 'redText',
        //   class: 'redText'
        // },
        // {
        //   name: 'titleText',
        //   class: 'titleText',
        //   tag: 'h1',
        // },
      ],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        [
          'undo',
          'redo',
          'strikeThrough',
          'subscript',
          'superscript',
          'customClasses',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'heading',
          'fontName'
        ],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode'
        ]
      ]
    };
    this.testTagsConfiguration =  {
      editable: true,
      spellcheck: true,
      // height: '14rem',
      minHeight: '7rem',
      maxHeight: '60rem',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      sanitize: true,
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [],
      customClasses: [
      ],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        [
          'undo',
          'redo',
          'strikeThrough',
          'subscript',
          'superscript',
          'customClasses',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          // 'heading',
          'fontName'
        ],
        [
          // 'fontSize',
          'textColor',
          'backgroundColor',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode'
        ]
      ]
    };
  }
}
