import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from "../../../service/language/language.service";
import {MasterDataService} from "../../../service/masterData/master-data.service";

@Component({
  selector: 'app-add-update-template',
  templateUrl: './add-update-template.component.html',
  styleUrls: ['./add-update-template.component.scss']
})
export class AddUpdateTemplateComponent implements OnInit {
  addTemplate: FormGroup;
  id: any;
  CMSData: any;
  msg: any;
  typeoftemp: any[] = [];
  roles: any[] = [];
  scenariolist: any[] = [];
  parameterlist: any[] = [];
  selectscn: any;
  selectType: any;
  selectRole: any;
  emailStatus: boolean;
  role: any;
  templateType: any;
  LangList: [];
  templateId: any;
  TemplateData: any;
  parameterRecruiterlist: any[] = [];
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
              private router: Router, private snackBar: MatSnackBar, private langService:LanguageService, private masterService:MasterDataService

  ) { }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Page Description...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['backgroundColor']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  isActive: any;
  selectLanguage: any;
  ngOnInit() {
    this.emailStatus = true;
    this.paramtername();
    this.id = this.route.snapshot.params['id'];
    if(this.id != undefined){
      this.templateId = this.id;
    }else{
      this.templateId = 0;
    }
    this.LangList = JSON.parse(this.langService.getLngList());
    this.roles = [
      {name: 'Admin' , value: 'ADMIN'},
      {name: 'Company/Recruiter' , value: 'COMPANY_RECRUITER'},
      {name: 'Candidate' , value: 'CANDIDATE'},
    ];

    this.typeoftemp = [
      {name: 'Email Template' , value: 'EMAIL_TEMPLATE'},
      {name: 'SMS Template' , value: 'SMS_TEMPLATE'},
      {name: 'Push Notification Template' , value: 'PUSH_NOTIFICATION_TEMPLATE'},
    ];

    this.addTemplate = this.formBuilder.group({
      languageId: ['', Validators.required],
      templateName: ['', Validators.required],
      subject: ['', Validators.required],
      toRecipent: [''],
      roles: ['', Validators.required],
      scenerios: ['', Validators.required],
      typeOfTemplate: ['', Validators.required],
      text: ['', Validators.required],
      isActive: ['', Validators.required]
    });
    if (this.id) {
      this.getTemplateById();
    } else {
      this.selectscn = '0';
      this.selectType = '0';
      this.selectRole = '0';
      this.selectLanguage = '0';
    }
  }
  paramtername() {
    this.parameterlist = [
      {name: 'First Name', value: 'firstName'},
      {name: 'Last Name', value: 'lastName'},
      {name: 'Email', value: 'email'},
      {name: 'Job Role', value: 'jobRole'},
      {name: 'Mobile Number', value: 'mobileNumber'},
      {name: 'About User', value: 'aboutMe'},
      {name: 'Country Code', value: 'countryCode'},
      {name: 'Profile Image', value: 'profileImage'},
      {name: 'Profile Video', value: 'profileVideo'},
      {name: 'Password', value: 'password'},
    ];

    this.parameterRecruiterlist = [
      {name: 'Comapany  Name', value: 'companyName'},
      {name: 'Email', value: 'userEmail'},
      {name: 'Register Address', value: 'registerAddress'},
      {name: 'Register Address2', value: 'registerAddress2'},
      {name: 'Register Address3', value: 'registerAddress3'},
      {name: 'City', value: 'city'},
      {name: 'PostalCode', value: 'postalCode'},
      {name: 'Tax Number', value: 'taxNumber'},
      {name: 'Bill Address', value: 'billAddress'},
      {name: 'Bill Address2', value: 'billAddress2'},
      {name: 'Bill Address3', value: 'billAddress3'},
      {name: 'Bill Postal Code', value: 'billPostalCode'},
      {name: 'Company Profile', value: 'companyProfileImage'},
      {name: 'Banner', value: 'companyBackgroundImage'},
      {name: 'Website', value: 'companyWebsite'},
      {name: 'Country Name', value: 'countryName'},
      {name: 'Company ID', value: 'intrecCompanyId'},
      {name: 'Company Profile', value: 'companyProfileImage'},
      {name: 'Mobile Number', value: 'userMobileNumber'},
      {name: 'Facebook ID', value: 'facebookId'},
      {name: 'Twitter Id', value: 'twitterId'},
    ];

  }
  templateTypeChange(e) {
    this.templateType = e;
    if(this.role == "ADMIN"){
      if (e == 'EMAIL_TEMPLATE' || e == 'SMS_TEMPLATE'  || e == 'PUSH_NOTIFICATION_TEMPLATE' ) {
        this.scenariolist =  [
          {name: 'New Member registration' , value: 'A_NEW_MEMBER_REGISTRATION'},
          {name: 'App Downloaded. Thank you message' , value: 'A_APP_DOWNLOAD'},
          {name: 'Form Submition' , value: 'A_FORM_SUBMISSION'},
          {name: 'New Partners Registration' , value: 'A_NEW_PARTNERS_REGISTRATION'},
          {name: 'New Job Not Finished Creating' , value: 'A_NEW_JOB_NOT_FINISHED_CREATING'},
          {name: 'Job Finish Creating' , value: 'A_JOB_FINISH_CREATING'},
          {name: 'Company/ Recruiter document check finished' , value: 'A_COMPANY_RECRUITER_DOCUMENT_CHECK_FINISHED'},
          {name: 'Profile Not Completed' , value: 'A_PROFILE_NOT_COMPLETED'},
          {name: 'Job Has Been Posted' , value: 'A_JOB_HAD_BEEN_POSTED'},
          {name: 'Notification of Interview' , value: 'A_NOTIFICATION_OF_INTERVIEW'},
          {name: 'Notification of Personality test not completed' , value: 'A_NOTIFICATION_OF_PERSONALITY_TEST_NOT_COMPLETED'},
          {name: 'Notification of Final Interview' , value: 'A_NOTIFICATION_OF_FINAL_INTERVIEW'},
          {name: 'Notification of Finished Recruitment Candidate' , value: 'A_NOTIFICATION_OF_FINISHED_RECRUITMENT_CANDIDATE'},
          {name: 'Invoice Due' , value: 'A_INVOICE_DUE'},
          {name: 'Payment Received' , value: 'A_PAYMENT_RECEIVED'},
          {name: 'Job Post Has Expired' , value: 'A_JOB_POST_HAS_EXPIRED'},
          {name: 'Request For Feedback And Reviews' , value: 'A_REQUEST_FOR_FEEDBACK_AND_REVIEW'},
          {name: 'Thank you for feedback and reviews' , value: 'A_THANK_YOU_FOR_FEEDBACK_AND_REVIEW'},
          {name: 'Discount and Vouchers' , value: 'A_DISCOUNT_AND_VOUCHERS'},
          {name: 'Newletter' , value: 'A_NEWLETTER'},
          {name: 'Logged notification' , value: 'A_LOGGED_NOTIFICATION'},
          {name: 'Invite a friend' , value: 'A_INVITE_A_FRIEND'},
          {name: 'New Job notification' , value: 'A_NEW_JOB_NOTIFICATION'},
        ];
      }
      else {
        this.scenariolist = [];
      }
    }
    else if(this.role == "COMPANY_RECRUITER") {
      if (e == 'EMAIL_TEMPLATE') {
        this.scenariolist =  [
          {name: 'New Member registration' , value: 'R_NEW_MEMBER_REGISTRATION'},
          {name: 'App Downloaded. Thank you message' , value: 'R_APP_DOWNLOAD'},
          {name: 'New Partners Registration' , value: 'R_NEW_PARTNERS_REGISTRATION'},
          {name: 'Company/ Recruiter document check finished' , value: 'R_COMPANY_RECRUITER_DOCUMENT_CHECK_FINISHED'},
          {name: 'Notification of Interview' , value: 'R_NOTIFICATION_OF_INTERVIEW'},
          {name: 'Notification of Personality test not completed' , value: 'R_NOTIFICATION_OF_PERSONALITY_TEST_NOT_COMPLETED'},
          {name: 'Notification of Finished Recruitment Candidate' , value: 'R_NOTIFICATION_OF_FINISHED_RECRUITMENT_CANDIDATE'},
          {name: 'Logged notification' , value: 'R_LOGGED_NOTIFICATION'},
          {name: 'New Job notification' , value: 'R_NEW_JOB_NOTIFICATION'},
        ];
      }
      else if (e == 'SMS_TEMPLATE') {
        this.scenariolist =  [
          {name: 'New Member registration' , value: 'R_NEW_MEMBER_REGISTRATION'},
          {name: 'App Downloaded. Thank you message' , value: 'R_APP_DOWNLOAD'},
          {name: 'Form Submition' , value: 'R_FORM_SUBMISSION'},
          {name: 'New Partners Registration' , value: 'R_NEW_PARTNERS_REGISTRATION'},
          {name: 'Job Finish Creating' , value: 'R_JOB_FINISH_CREATING'},
          {name: 'Company/ Recruiter document check finished' , value: 'R_COMPANY_RECRUITER_DOCUMENT_CHECK_FINISHED'},
          {name: 'Profile Not Completed' , value: 'R_PROFILE_NOT_COMPLETED'},
          {name: 'Job Has Been Posted' , value: 'R_JOB_HAD_BEEN_POSTED'},
          {name: 'Notification of Interview' , value: 'R_NOTIFICATION_OF_INTERVIEW'},
          {name: 'Notification of Personality test not completed' , value: 'R_NOTIFICATION_OF_PERSONALITY_TEST_NOT_COMPLETED'},
          {name: 'Notification of Finished Recruitment Candidate' , value: 'R_NOTIFICATION_OF_FINISHED_RECRUITMENT_CANDIDATE'},
          {name: 'Payment Received' , value: 'R_PAYMENT_RECEIVED'},
          {name: 'Job Post Has Expired' , value: 'R_JOB_POST_HAS_EXPIRED'},
          {name: 'Request For Feedback And Reviews' , value: 'R_REQUEST_FOR_FEEDBACK_AND_REVIEW'},
          {name: 'Thank you for feedback and reviews' , value: 'R_THANK_YOU_FOR_FEEDBACK_AND_REVIEW'},
          {name: 'Discount and Vouchers' , value: 'R_DISCOUNT_AND_VOUCHERS'},
          {name: 'Newletter' , value: 'R_NEWLETTER'},
          {name: 'Logged notification' , value: 'R_LOGGED_NOTIFICATION'},
          {name: 'Invite a friend' , value: 'R_INVITE_A_FRIEND'},
          {name: 'New Job notification' , value: 'R_NEW_JOB_NOTIFICATION'},
        ];
      }
      else  if (e == 'PUSH_NOTIFICATION_TEMPLATE') {
        this.scenariolist =  [
          {name: 'New Member registration' , value: 'R_NEW_MEMBER_REGISTRATION'},
          {name: 'Form Submition' , value: 'R_FORM_SUBMISSION'},
          {name: 'New Partners Registration' , value: 'R_NEW_PARTNERS_REGISTRATION'},
          {name: 'Notification of Interview' , value: 'R_NOTIFICATION_OF_INTERVIEW'},
          {name: 'Notification of Personality test not completed' , value: 'R_NOTIFICATION_OF_PERSONALITY_TEST_NOT_COMPLETED'},
          {name: 'Notification of Finished Recruitment Candidate' , value: 'R_NOTIFICATION_OF_FINISHED_RECRUITMENT_CANDIDATE'},
          {name: 'Payment Received' , value: 'R_PAYMENT_RECEIVED'},
          {name: 'Discount and Vouchers' , value: 'R_DISCOUNT_AND_VOUCHERS'},
          {name: 'Logged notification' , value: 'R_LOGGED_NOTIFICATION'},
        ];
      }
    else {
        this.scenariolist = [];
      }
    }
    else if(this.role == "CANDIDATE") {
      if (e == 'EMAIL_TEMPLATE') {
        this.scenariolist =  [
          {name: 'App Downloaded. Thank you message' , value: 'C_APP_DOWNLOAD'},
          {name: 'New Partners Registration' , value: 'C_NEW_PARTNERS_REGISTRATION'},
          {name: 'New Job Not Finished Creating' , value: 'C_NEW_JOB_NOT_FINISHED_CREATING'},
          {name: 'Job Finish Creating' , value: 'C_JOB_FINISH_CREATING'},
          {name: 'Company/ Recruiter document check finished' , value: 'C_COMPANY_RECRUITER_DOCUMENT_CHECK_FINISHED'},
          {name: 'Job Has Been Posted' , value: 'C_JOB_HAD_BEEN_POSTED'},
          {name: 'Invoice Due' , value: 'C_INVOICE_DUE'},
          {name: 'Payment Received' , value: 'C_PAYMENT_RECEIVED'},
          {name: 'Job Post Has Expired' , value: 'C_JOB_POST_HAS_EXPIRED'},
          {name: 'Discount and Vouchers' , value: 'C_DISCOUNT_AND_VOUCHERS'},
          {name: 'Logged notification' , value: 'C_LOGGED_NOTIFICATION'},
        ];
      }
      else if (e == 'SMS_TEMPLATE') {
        this.scenariolist =  [
          {name: 'App Downloaded. Thank you message' , value: 'C_APP_DOWNLOAD'},
          {name: 'Form Submition' , value: 'C_FORM_SUBMISSION'},
          {name: 'New Partners Registration' , value: 'C_NEW_PARTNERS_REGISTRATION'},
          {name: 'New Job Not Finished Creating' , value: 'C_NEW_JOB_NOT_FINISHED_CREATING'},
          {name: 'Job Finish Creating' , value: 'C_JOB_FINISH_CREATING'},
          {name: 'Company/ Recruiter document check finished' , value: 'c_COMPANY_RECRUITER_DOCUMENT_CHECK_FINISHED'},
          {name: 'Profile Not Completed' , value: 'C_PROFILE_NOT_COMPLETED'},
          {name: 'Job Has Been Posted' , value: 'C_JOB_HAD_BEEN_POSTED'},
          {name: 'Notification of Interview' , value: 'C_NOTIFICATION_OF_INTERVIEW'},
          {name: 'Notification of Personality test not completed' , value: 'C_NOTIFICATION_OF_PERSONALITY_TEST_NOT_COMPLETED'},
          {name: 'Notification of Finished Recruitment Candidate' , value: 'C_NOTIFICATION_OF_FINISHED_RECRUITMENT_CANDIDATE'},
          {name: 'Invoice Due' , value: 'C_INVOICE_DUE'},
          {name: 'Payment Received' , value: 'C_PAYMENT_RECEIVED'},
          {name: 'Job Post Has Expired' , value: 'C_JOB_POST_HAS_EXPIRED'},
          {name: 'Request For Feedback And Reviews' , value: 'C_REQUEST_FOR_FEEDBACK_AND_REVIEW'},
          {name: 'Thank you for feedback and reviews' , value: 'C_THANK_YOU_FOR_FEEDBACK_AND_REVIEW'},
          {name: 'Discount and Vouchers' , value: 'C_DISCOUNT_AND_VOUCHERS'},
          {name: 'Newletter' , value: 'C_NEWLETTER'},
          {name: 'Logged notification' , value: 'C_LOGGED_NOTIFICATION'},
          {name: 'Invite a friend' , value: 'C_INVITE_A_FRIEND'},
        ];
      }
      else if (e == 'PUSH_NOTIFICATION_TEMPLATE') {
        this.scenariolist =  [
          {name: 'Form Submition' , value: 'C_FORM_SUBMISSION'},
          {name: 'New Partners Registration' , value: 'C_NEW_PARTNERS_REGISTRATION'},
          {name: 'New Job Not Finished Creating' , value: 'C_NEW_JOB_NOT_FINISHED_CREATING'},
          {name: 'Job Finish Creating' , value: 'C_JOB_FINISH_CREATING'},
          {name: 'Company/ Recruiter document check finished' , value: 'C_COMPANY_RECRUITER_DOCUMENT_CHECK_FINISHED'},
          {name: 'Job Has Been Posted' , value: 'C_JOB_HAD_BEEN_POSTED'},
          {name: 'Invoice Due' , value: 'C_INVOICE_DUE'},
          {name: 'Payment Received' , value: 'C_PAYMENT_RECEIVED'},
          {name: 'Discount and Vouchers' , value: 'C_DISCOUNT_AND_VOUCHERS'},
          {name: 'Newletter' , value: 'C_NEWLETTER'},
          {name: 'Invite a friend' , value: 'C_INVITE_A_FRIEND'},
        ];
      }
      else {
        this.scenariolist = [];
      }
    }
    else{
      this.scenariolist = [];
    }

  }
  getTemplateById() {
      this.masterService.getTemplateDetails(this.id).subscribe(data => {
        this.TemplateData = data;
        if(this.TemplateData.status == 0){
          this.templateTypeChange(this.TemplateData.response.templateType);
          this.addTemplate.patchValue({
            templateName: this.TemplateData.response.templateName,
            toRecipent:this.TemplateData.response.toRecipent,
            subject: this.TemplateData.response.subject,
            typeOfTemplate: this.TemplateData.response.typeOfTemplate,
            scenerios: this.TemplateData.response.scenerios,
            text: this.TemplateData.response.text,
            isActive: this.TemplateData.response.isActive,
          });

          this.selectLanguage = this.TemplateData.response.languageId;
          this.selectType = this.TemplateData.response.typeOfTemplate;
          this.selectRole = this.TemplateData.response.userRoleType;
          this.role = this.selectRole;
          this.templateTypeChange(this.TemplateData.response.typeOfTemplate);
          this.selectscn = this.TemplateData.response.scenerios;
          //this.selectRole =  this.TemplateData.response.scenerios;
        } else {
          this.snackBar.open('emailStatus -1 error', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, (error) => {
        this.snackBar.open('Error from the Server', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      });
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }
  saveTemplate() {

    const payload1 = {
      languageId: this.addTemplate.value.languageId,
      templateName: this.addTemplate.value.templateName,
      subject: this.addTemplate.value.subject,
      toRecipent: this.addTemplate.value.toRecipent,
      userRoleType: this.addTemplate.value.roles,
      scenerios: this.addTemplate.value.scenerios,
      typeOfTemplate: this.addTemplate.value.typeOfTemplate,
      text: this.addTemplate.value.text,
      templateId:this.templateId,
      isActive:this.addTemplate.value.isActive,
      isDeleted:true,
    };
    if (this.addTemplate.invalid) {
      this.validateAllFormFields(this.addTemplate);
      this.snackBar.open('Please fill all field', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    } else {
      if (this.id) {
        console.log(this.id);
        this.masterService.updateTeplate(payload1).subscribe(data => {
          this.msg = data;
          if (this.msg.emailStatus == -1) {
            this.snackBar.open('Error from the Server', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            this.snackBar.open('Update Successfully', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/notification-template']);
          }
        }, (error) => {
          this.snackBar.open('Error from the Server', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        });
      } else {
        this.masterService.saveTemplate(payload1).subscribe(data => {
          this.msg = data;
          if (this.msg.emailStatus == -1) {
            this.snackBar.open('Error from the Server', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            this.snackBar.open('Save Successfully', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/notification-template']);
          }
        }, (error) => {
          this.snackBar.open('Error from the Server', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        });
      }
    }
  }

  roleChange(value) {
    this.role = value;
    this.templateTypeChange(this.templateType)
  }
}
