import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../service/language/language.service";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { MatDialog } from "@angular/material/dialog";
import { JobTestQuestionManageService } from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { DynamicLabelClass } from "../../../helper/userLevelLink";
import { CandidateService } from "../../../service/candidateDetails/candidate.service";

@Component({
  selector: "app-candidate-view",
  templateUrl: "./candidate-view.component.html",
  styleUrls: ["./candidate-view.component.scss"],
})
export class CandidateViewComponent implements OnInit {
  intrecCompanyId: any;
  companyName: any;
  country: any;
  registerAddress: any;
  registerAddress2: any;
  registerAddress3: any;
  city: string;
  postalCode: string;
  taxNumber: any;
  billAddress: any;
  billCity: any;
  billPostalCode: any;
  companyWebsite: any;
  companyLinkedInId: string;
  facebookId: any;
  twitterId: any;
  companyInsta: any;
  userEmail: any;
  userMobileNumber: any;
  pendingStatus: boolean = false;
  jobsDetails: any;
  infoId: any;
  limit: any;
  profileImage: any;
  allCandidateDetails: any;
  firstName: any;
  lastName: any;
  aboutMe: any;
  mobileNumber: any;
  profileVideo: any;
  location: any;
  candidateSkillName: any;
  documentFile: any;
  candidateCharacterName: any;
  email: any;
  candidateCharacterlength: any;
  candidateSkillLength: any;
  countryName: any;
  countryNameLength: any;
  address: string;
  preferredLocationName: any;
  candidatePreferredLocationList: any;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private candidateService: CandidateService,
    public dialog: MatDialog,
    private router: Router
  ) {}
  expandedElement: (query: string) => void;
  viewID: any;
  fileData: File = null;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList = [];
  page: any;
  size: any;
  qustGropList = [];
  qustGropList0 = [];
  qustGropList1 = [];
  qustGropList3 = [];

  qGroup: any;
  jobCatList = [];
  qType: any;
  arrayOptions = [];
  pdata: any;
  seletedOption: any;
  myModel: any;
  config: any;
  addnewOption: any;
  submitted = false;
  selectLang: any;
  firstFormSubmited: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["srno", "document", "action"];
  displayedColumns3 = [
    // "srno",
    "candidateFirstName",
    "candidateLastName",
    "videoResume",
    "resumeBooster",
    "jobAlert",
    "startDate",
    "expiryDate",
  ];
  dataSource = new MatTableDataSource<any>();
  dataSource1 = new MatTableDataSource<any>();
  dataSource3 = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  QGData: any;
  mm: boolean = false;
  checkboxSelectArray = [];
  isTestCreateSuccess: boolean = false;
  dsdsdsdsdsdsdsd: any[] = [];
  languageId: any;
  excellentPassDescription: any;
  normalPassingScoreMax: any;
  numberOfRetry: any;
  excellentPassScore: any;
  jobcategoryName: any;
  testDescriptionForCompany: any;
  testDescriptionForCandidate: any;
  maxTimeInMin: any;
  failResultDescription: any;
  normalPassingScoreMin: any;
  pererror: boolean = false;
  JobQuniError: boolean = false;
  excellentPassScoreError: boolean = false;
  selectedAreas: any[] = [];
  questionAreas: any[] = [];
  questionAreas3: any[] = [];
  questionAreas5: any[] = [];
  selectedlanguageCode: any;
  selectedLangName: any;
  excellentScoreDisable: boolean = true;
  selectedTab = 0;
  passResultDescription: any;
  title: any;
  item: any;
  totalElements: boolean = true;
  limitData: number;
  checkStatus: 0;

  ngOnInit(): void {
    this.firstFormSubmited = false;
    this.addnewOption = 1;
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.seletedOption = 0;
    this.page = 0;
    this.size = 1000;
    this.limitData = 6;
    this.viewID = this.route.snapshot.params["id"];
    this.selectedTab = this.route.snapshot.params["tab"];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    if (this.viewID) {
      this.patchData();
    }
    this.arrayOptions = [];
    this.getStatisticsById(this.viewID);
  }

  LangChange(e) {
    for (let l of this.LangList) {
      if (l.languageId == e.value) {
        this.selectLang = l.languageCode;
      }
    }
  }

  // get data from id
  patchData() {
    this.candidateService.getCandidateDetailsById(this.viewID).subscribe(
      (res) => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          this.allCandidateDetails = this.pdata.response;
          this.firstName = this.pdata.response.firstName;
          this.lastName = this.pdata.response.lastName;
          this.profileImage = this.pdata.response.profileImage;
          this.email = this.pdata.response.email;
          this.mobileNumber = this.pdata.response.mobileNumber;
          this.profileVideo = this.pdata.response.profileVideo;
          this.location = this.pdata.response.location;
          this.preferredLocationName =
            this.pdata.response.preferredLocationName;
          this.candidatePreferredLocationList  =  this.pdata.response.candidatePreferredLocationName.split(',');
          // this.candidateSkillName = this.pdata.response.candidateSkillName;
          // this.candidateSkillLength = this.pdata.response.candidateSkillName.length;
          // this.candidateCharacterName = this.pdata.response.candidateCharacterName;
          // this.candidateCharacterlength = this.pdata.response.candidateCharacterName.length;
          // this.countryName = this.pdata.response.countryName;
          // this.countryNameLength = this.pdata.response.countryName.length;
          this.documentFile = this.pdata.response.documentFile;
          // this.address = "";
        } else {
          this.snackBar.open("response Status -1 ", "", {
            duration: 3000,
            panelClass: ["error-snackbar"],
          });
        }
      },
      (error) => console.log(error)
    );
  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getStatisticsById(viewID): any {
    this.candidateService
      .getStatisticsById(viewID)
      .subscribe((res: any) => {
        this.data = res;
        if (this.data.status == 0) {
          this.dataSource3 = new MatTableDataSource(this.data.response);
        }
      });
  }
}
