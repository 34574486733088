import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonConfirmPopupComponent } from 'src/app/component/common-confirm-popup/common-confirm-popup.component';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
@Component({
  selector: 'app-manage-job-report',
  templateUrl: './manage-job-report.component.html',
  styleUrls: ['./manage-job-report.component.scss'],
})
export class ManageJobReportComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  displayedColumns: string[];
  advancedform: FormGroup;
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList = [];
  prdlist = [];
  private data: any;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  cityList: any = [];
  cityListClone: any = [];
  jobCategoryList: any = [];
  jobCategoryListClone: any = [];
  jobCategoryId: any;
  cityId: any;
  searchForm: FormGroup;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private router: Router,

    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private masterDataservice: MasterDataService
  ) {
  }

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.displayedColumns = [
      'srno',
      'companyName',
      'jobTitle',
      'jobType',
      'jobCategory',
      'city',
      'postDate',
      'status'
    ];
    this.searchForm = this.formBuilder.group({
      downloadFile: ["CSV"]
    });
    this.getAllCities();
    this.createForm();
    this.getAlljobCategory();
  }
  createForm(): void {
    this.advancedform = this.formBuilder.group({
      jobCategoryId: [''],
      cityId: [''],
    });
    this.searchBySkill();
  }
  get f(): { [key: string]: AbstractControl } {
    return this.advancedform.controls;
  }
  downloadFile():any{
    window.location.href = `${environment.baseurl1}/subUser/exportJobListExcel?extension=`+this.searchForm.value.downloadFile;
  }
  searchBySkill() {
    this.jobCategoryId = this.advancedform.value.jobCategoryId;
    this.cityId = this.advancedform.value.cityId;
    this.masterDataservice
      .manageJobReport(this.jobCategoryId, this.cityId)
      .subscribe((res) => {
        this.data = res;
        if (this.data.status == 0) {
          this.totalrecordLength = this.data.response.totalElements;
          this.page = this.data.response.number;
          this.totalpageLenght = this.data.response.totalPages;
          this.dataSource = new MatTableDataSource(this.data.response);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      });
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Job');

    /* save to file */
    XLSX.writeFile(wb, 'JobList.xlsx');
  }
  filterApps(name: any) {
    const filterValue = name.toLowerCase();
    if (name == '') {
      return this.apps;
    } else {
      return this.apps.filter((p) =>
        p.name.toLowerCase().includes(filterValue)
      );
    }
  }
  onEnter(e: any) {
    this.selectproduct = e.name;
    this.selectedId = e.id;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search() {
    this.router.navigate(['editTeam', this.selectedId]);
  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getAllCities(): any {
    this.masterDataservice.getAllcityByCountry().subscribe((data: any) => {
      if (data.status === 0) {
        this.cityList = data.response;
        this.cityListClone = this.cityList;
      }
    });
  }
  citySearch(query: string): any {
    const d = query.toLowerCase();
    const result = this.citySelect(d);
    if (query === '') {
      this.cityList = this.cityListClone;
    } else {
      // @ts-ignore
      this.cityList = result;
    }
  }
  citySelect(query: string): any[] {
    const result: any[] = [];
    for (const b of this.cityListClone) {
      // @ts-ignore
      if (b.cityName.toLowerCase().indexOf(query) > -1) {
        result.push(b);
      }
    }
    // var arr = this.advancedform.value.cityId;
    // if (arr.length) {
    //   arr.forEach((ele: any) => {
    //     this.cityListClone.forEach((obj: any) => {
    //       if (ele == obj.cityId) {
    //         result.push(obj);
    //       }
    //     });
    //   });
    // }
    return result;
  }
  getAlljobCategory(): any {
    this.masterDataservice
      .getAllCategoryList(0, 500, 'en', 'true')
      .subscribe((data: any) => {
        if (data.status == 0) {
          this.jobCategoryList = data.response.dataSet;
          this.jobCategoryListClone = this.jobCategoryList;
        }
      });
  }
  categorySearch(query: string): any {
    const d = query.toLowerCase();
    const result = this.categorySelect(d);
    if (query == '') {
      this.jobCategoryList = this.jobCategoryListClone;
    } else {
      // @ts-ignore
      this.jobCategoryList = result;
    }
  }
  categorySelect(query: string): any[] {
    const result: any[] = [];
    for (const b of this.jobCategoryListClone) {
      // @ts-ignore
      if (b.categoryName.toLowerCase().indexOf(query) > -1) {
        result.push(b);
      }
    }
    return result;
  }
}
