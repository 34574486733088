import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../service/language/language.service';
import { MatDialog } from '@angular/material/dialog';
import { CandidateService } from '../../../service/candidateDetails/candidate.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-candidate-cv-list',
  templateUrl: './candidate-cv-list.component.html',
  styleUrls: ['./candidate-cv-list.component.scss'],
})
export class CandidateCvListComponent implements OnInit {
  data: Object;
  fileResume: any;
  candidateId: any;
  displayedColumns: string[] = [
    'srno',
    'cvName',
    'cvTitle',
    'createddate',
    'actions',
  ];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  viewID: any;
  uploadCvUrl: any;
  cvScoreView: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private candidateService: CandidateService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.candidateId = this.route.snapshot.params['id'];
    // this.getAllCvByCandidateId();
  }

  // getAllCvByCandidateId() {
  //   this.candidateService
  //     .getAllCvByCandidateId(this.viewID)
  //     .subscribe((res: any) => {
  //       if (res.status == 0) {
  //         this.dataSource = new MatTableDataSource(res.response);
  //         this.dataSource.sort = this.sort;
  //         this.dataSource.paginator = this.paginator;
  //       }
  //     });
  // }
  // downloadCV(id):any{
  //   this.candidateService.getCvById(id).subscribe( (res:any) =>{
  //     if(res.status == 0 ){
  //       if(res.response.cvUploadUrl){
  //         this.uploadCvUrl= res.response.cvUploadUrl;
  //         saveAs(this.uploadCvUrl, 'CV.pdf');
  //       }
  //     }
  //   })
  // }
  downloadCV(): void {
    this.candidateService
      .getcandidateResume(this.candidateId)
      .subscribe((res: any) => {
        if (res.status === 0) {
          if (res.response.fileResume) {
            if (
              res.response.fileResume !== null ||
              res.response.fileResume !== '' ||
              res.response.fileResume !== undefined
            ) {
              this.fileResume = res.response.fileResume;
              saveAs(this.fileResume, 'Resume-file.pdf');
            }
          } else {
            this.snackBar.open(
              'Candidate Uploade Resume Not Available !!',
              '',
              {
                duration: 3000,
                panelClass: ['error-snackbar'],
              }
            );
          }
        } else {
          this.snackBar.open('Candidate Uploade Resume Not Available !!', '', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        }
      });
  }
  VideoCV(): void {
    this.candidateService
      .getcandidateResume(this.candidateId)
      .subscribe((res: any) => {
        if (res.status === 0) {
          if (res.response.videoResume) {
            const url = res.response.videoResume;
            this.uploadCvUrl = url;
            this.router.navigate(['/view-candidate-Video/', this.candidateId]);
          } else {
            this.snackBar.open('Candidate Video Resume Not Available !!', '', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          }
          // console.log('::::::::::::::::::=========', this.uploadCvUrl);
          // this.profileService.emitJobNextBtnFn(7);
          // window.scrollTo(0, 0);
        } else {
          this.snackBar.open('Candidate Video Resume Not Available !!', '', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        }
      });
  }
  systemCV(): void {
    this.candidateService
      .getcandidateResume(this.candidateId)
      .subscribe((res: any) => {
        if (res.status === 0) {
          if (res.response.systemGeneratedResume) {
            const url = res.response.systemGeneratedResume;
            this.uploadCvUrl = url;
            this.router.navigate(['/view-candidate-cv/', this.candidateId]);
          } else {
            this.snackBar.open(
              'Candidate System Generate Resume Not Available !!',
              '',
              {
                duration: 3000,
                panelClass: ['error-snackbar'],
              }
            );
          }
          // console.log('::::::::::::::::::=========', this.uploadCvUrl);
          // this.profileService.emitJobNextBtnFn(7);
          // window.scrollTo(0, 0);
        } else {
          this.snackBar.open(
            'Candidate System Generate Resume Not Available !!',
            '',
            {
              duration: 3000,
              panelClass: ['error-snackbar'],
            }
          );
        }
      });
  }
}
