import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {LoginService} from 'src/app/service/login/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isOnline: boolean;
  isLoggedIn$: Observable<boolean>;
  currentUser: any;
  loginid: any;
  avalablebalance: any;
  data: any;
  userLevel: any;
  pimg: any;
  popupinterval: any;
  loginId: any;   

  constructor(
    private loginService: LoginService,
    private router: Router, private snackBar: MatSnackBar) {}

  ngOnInit() {
  console.log(localStorage.getItem('Id'))
  this.loginId = this.loginService.loginId();
  this.currentUser = 'admin';

  }

  logOut() {  
    localStorage.removeItem('LanguageList');
    this.snackBar.open('Logout Successfully', '', {
      duration: 3000,
      panelClass: ['success-snackbar']
    });
    this.loginService.logoutFromButton();
    this.router.navigate(['/login']);
  }



}
