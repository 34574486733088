import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../service/language/language.service";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { JobTestQuestionManageService } from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import { DynamicLabelClass } from "../../../helper/userLevelLink";

@Component({
  selector: 'app-add-update-job-plan',
  templateUrl: './add-update-job-plan.component.html',
  styleUrls: ['./add-update-job-plan.component.scss']
})
export class AddUpdateJobPlanComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;

  viewID: any;
  fileData: File = null;
  testImgURL: any;
  questionImage: any;
  testImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList: Array<any> = [];
  page: number = 0;
  size: number = 500;
  qustGropList: Array<any> = [];
  qustGropList1: Array<any> = [];
  qGroup: any;
  qustTypeList: Array<any> = [];
  qustTypeList1: Array<any> = [];
  qType: any;
  oprincustome: Array<any> = [];
  resultKewords: Array<any> = [];
  pdata: any;
  seletedOption: number = 0;
  myModel: any;
  config: any;
  addnewOption: number = 1;
  submitted = false;
  addBtnHide: boolean = false;
  selectedAreas: Array<any> = [];
  CorrecTanswerNotSlect = false;
  CorrectAnserArray = [false, false, false, false, false];
  typeSelect: boolean;
  lang: any;
  keywaordList: any;
  keywaordList1: any;
  allKeywords: any;
  allComplete: boolean = false;
  menus: any;
  mrunal = [];
  keyWordListShow = false;
  capDivVal: boolean = false;
  jobDivVal: boolean = false;
  laguageName: any;
  feature: any;
  isLinkeCheck: any;

  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    private router: Router) { }


  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.viewID = this.route.snapshot.params['id'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.initializeForm();
  }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      jobPostPlanMultilngs: new FormArray([]),
      // planDescription: new FormArray([]),
      // languageId: ['', Validators.required],
      // priceDescription: [''],
      feature: new FormArray([]),
      planId: [0],
      currency: new FormArray([])
    });

    if (this.viewID) {
      this.patchData();
    }
    this.LangListLoad(this.LangList.length, this.addnewOption);
    this.featureListLoop(this.LangList.length, 1);
    this.setCurrencyData();
  }
  get f() { return this.formGroup.controls; }
  get t() { return this.f.feature as FormArray; }
  get planNameArray(): FormArray { return this.formGroup.get("jobPostPlanMultilngs") as FormArray; }
  // get pd(): FormArray { return this.formGroup.get("planDescription") as FormArray; }
  get currency(): FormArray {
    return this.formGroup.get("currency") as FormArray;
  }
  setCurrencyData(): any {
    this.currency.insert(0, this.fb.group({ code: ['USD'], countryName: ['United States of America'], currencyId: [4], id: [0], name: ['Dollars'], planId: [0], symbol: ['$'], value: [0] }));
    this.currency.insert(1, this.fb.group({ code: ['GBP'], countryName: ['United Kingdom'], currencyId: [2], id: [0], name: ['Pounds'], planId: [0], symbol: ['£'], value: [0] }));
    this.currency.insert(2, this.fb.group({ code: ['EUR'], countryName: ['Euro'], currencyId: [1], id: [0], name: ['Euro'], planId: [0], symbol: ['€'], value: [0] }));
    this.currency.insert(3, this.fb.group({ code: ['PLN'], countryName: ['Poland'], currencyId: [3], id: [0], name: ['Zlotych'], planId: [0], symbol: ['zł'], value: [0] }));
  }
  removeOption(i): any {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e, opt): any {
    const numberOfTickets = e;
 
    if (this.planNameArray.length < numberOfTickets) {
      for (let i = this.planNameArray.length; i < numberOfTickets; i++) {
        this.planNameArray.insert(i, this.fb.group({ 
          languageId:[this.LangList[i].languageId],  
          planName: ['' , Validators.required],   
          planDescription: ['']      
        }));
        // this.pd.insert(i, this.fb.group({ 
        //   languageId:[this.LangList[i].languageId],  
        //   value: [''] }));
      }
    } else {
      for (let i = this.planNameArray.length; i >= numberOfTickets; i--) {
        this.planNameArray.removeAt(i);
        // this.pd.removeAt(i);
      }
    }
  }

  featureListLoop( langloopcount , featureOptioncount ){
    const numberOfTickets =  langloopcount;
    if (this.t.length <featureOptioncount) {
      for (let i = this.t.length; i < featureOptioncount; i++) {
        var l = 0;
        this.t.push(this.fb.group({
          featureLangList: new FormArray([]),
          serviceType: ['0'],
          // isLink: [false]
        }));
        console.log(this.LangList);
        if (l < langloopcount) {
          for (let j = l; j < langloopcount ; j++) {
         
            (((this.formGroup.get('feature') as FormArray).at(i) as FormGroup).get('featureLangList') as FormArray).insert(j, this.fb.group({
              featureName: [''],
              languageId:[this.LangList[j].languageId],  

            }))
            l = (((this.formGroup.get('feature') as FormArray).at(i) as FormGroup).get('featureLangList') as FormArray).length;
          }
        }
      }
    }
  }

  patchData(): any {
    var planList = [];
    var planDesc = [];
    var optionList = [];
    this.masterDataservice.getJobPlanById(this.viewID)
      .subscribe((res:any) => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          this.addnewOption =res.response.feature.length;
          this.featureListLoop(this.LangList.length ,res.response.feature.length);
          this.formGroup.patchValue(res.response);

          // var multiLangList = this.pdata.response.jobPostPlanMultilngs;
          // this.feature = multiLangList[0].planFeature.split(",");
          // if (this.feature.length == 5) {
          //   this.addBtnHide = true;
          // } else { this.addBtnHide = false; }
          // this.LangListLoad(this.LangList.length, this.feature.length);
          // this.feature.forEach((obj, i) => {
          //   var opt = [];
          //   var serviceTypePatchlist =[];
          //   var isLinkPatchList = []
          //   multiLangList.forEach((element, j) => {
          //     planList.push({ value: element.planName });
          //     planDesc.push({ value: element.planDescription });
          //     var value = element.planFeature.split(",");
          //     console.log(element)
          //     opt.push({ response: value[i]});
          //     console.log(opt)
          //      serviceTypePatchlist.push(element.serviceType)
          //     isLinkPatchList.push(Boolean(element.isLink))
          //   });
          //   optionList.push({ featureLangList: opt , serviceType:serviceTypePatchlist[i] , isLink: isLinkPatchList[i] });
          //   console.log(optionList)
          // });
          // this.formGroup.patchValue({
          //   planName: planList,
          //   planDescription: planDesc,
          //   feature: optionList,
          //   currency: this.pdata.response.currency
          // });
          console.log(this.formGroup)
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }


  onSubmit(submiType): any {
    this.submitted = true;
    // var arr = [];
    // console.log(JSON.stringify(this.formGroup.value));
    // console.log(this.formGroup.controls.opttion.value)
    // for (let i = arr.length; i < this.LangList.length; i++) {
    //   this.oprincustome = [];
    //   for (let j = 0; j < (this.formGroup.get('feature') as FormArray).length; j++) {
    //     var dd = ((((this.formGroup.get('feature') as FormArray).at(j) as FormGroup).get('featureLangList') as FormArray).at(i) as FormGroup).value;
    //     this.oprincustome.push(dd.response);
    //   }
    //  arr.push({
    //     "addedBy": "ADMIN",
    //     "isActive": true,
    //     "languageId": this.LangList[i].languageId,
    //     "planDescription": (((this.formGroup.get('planDescription') as FormArray).at(i) as FormGroup).value).value,
    //     "planFeature": (this.oprincustome).toString(),
    //     "planId": this.viewID,
    //     "planLangId": 0,
    //     "planName": (((this.formGroup.get('planName') as FormArray).at(i) as FormGroup).value).value,
    //     'serviceType': ((this.formGroup.get('feature') as FormArray).at(i) as FormGroup).get('serviceType').value,
    //     'isLink':((this.formGroup.get('feature') as FormArray).at(i) as FormGroup).get('isLink').value
    //   })
    // }
    // const payload = {
    //   currency: this.formGroup.value.currency,
    //   jobPostPlanMultilngs: arr
    // };
    // console.log("payload", payload);

    if(this.formGroup.valid){
      if (this.viewID) {
        this.masterDataservice.updateJobPlan(this.viewID, this.formGroup.value)
          .subscribe((data: any) => {
            if (data.status == 0) {
              this.snackBar.open('Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listJobPlans']);
            } else {
              this.snackBar.open(data.response.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }
  
          },
            (err) => {
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );
  
      } else {
        this.masterDataservice.addJobPlan(this.formGroup.value).subscribe(res => {
          console.log(res.response);
          if (res.status == 0) {
            this.snackBar.open('Insert Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listJobPlans']);
          } else {
            this.snackBar.open(res.response.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        });
      }
    } else {
      this.snackBar.open('enter all Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  
  }

  firstSpaceRemoveValid(e): any {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

  newOptionadd(): any {
    console.log(this.addnewOption)
    if (this.addnewOption < 5) {
      this.addnewOption = this.addnewOption + 1;
      // this.LangListLoad(this.LangList.length, this.addnewOption);
     this.featureListLoop(this.LangList.length, this.addnewOption);
      this.addBtnHide = false;
    } else {
      this.addBtnHide = true;
    }
    if (this.addnewOption == 5) {
      this.addBtnHide = true;
    } else {
      this.addBtnHide = false;
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }
  search(query: string): any {
    const result = this.select(query.toLowerCase());
    if (query == '') {
      this.qustGropList = this.selectedAreas;
    } else {
      this.qustGropList = result;
    }
  }
  select(query: string): string[] {
    const result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  deleteOption(i) {
    this.t.removeAt(i);
    
  }

}
