<div class="container-fluid">
   <!--Page Header-->
   <div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
      <h3 class="page-title"><span  *ngIf="!id">Add </span>
        <span  *ngIf="id">Edit </span> Language</h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listLang']" >Language List</li>
        <li class="breadcrumb-item active" >
          <span  *ngIf="!id">Add </span>
          <span  *ngIf="id">Edit </span>
          Language </li>
      </ol>
    </div>
  </div>       
  <!--Page Header-->
    <div class="card">
      <div class="card-body">
  
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
          <div class="row">
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Language Name</label>
           <div class="col-sm-12 ">
          <input  type="text" 
                class="form-control"
                value="" placeholder="Please Enter Language Name" 
                name="languageName"
                (keydown)="firstSpaceRemoveValid($event)"
                       formControlName="languageName" required>
             
              <mat-error>
                   <span *ngIf="!formGroup.get('languageName').valid && formGroup.get('languageName').touched">
                                  Please Enter Language name</span>
              </mat-error>
            </div>
          </div>
          <div class="form-group row col-sm-6">
            <label class="col-sm-12">Language Code</label>
           <div class="col-sm-12 ">
             <input  type="text"  
                class="form-control"
                (keydown)="firstSpaceRemoveValid($event)"
                value="" placeholder="Enter Language Code" name="languageCode"
                       formControlName="languageCode" required>
             <mat-error>
               <span *ngIf="!formGroup.get('languageCode').valid && formGroup.get('languageCode').touched">
                                  Please enter language Code</span>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="">
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!id">Submit</button>
        </div>
          
          <!-- <button type="submit" class="btn btn-success" *ngIf="id">Update</button> -->
        </form>
  
  
  
  
      </div>
    </div>
  
  </div>
  