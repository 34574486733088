import { Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LanguageService } from 'src/app/service/language/language.service';
import{TranslationService} from 'src/app/service/translation/translation.service';
import {CommonConfirmPopupComponent} from 'src/app/component/common-confirm-popup/common-confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import {UploadLanguageFileComponent} from '../upload-language-file/upload-language-file.component';
import {MatSnackBar} from "@angular/material/snack-bar";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-manage-language',
  templateUrl: './manage-language.component.html',
  styleUrls: ['./manage-language.component.scss']
})
export class ManageLanguageComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  dataSourceMobile = new MatTableDataSource();
  data;
  LangList:any = [];
  selectLang:number;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  engName;
  languageJson:any;
  selectedLangCode:any;
  constructor(public langService: LanguageService,
              public translationService:TranslationService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = 14;
    this.displayedColumns = ['srno', 'appName', 'lastUpdatedEpochTime', 'publishedEpochTime', 'action'];
    this.tabelLoad(this.selectLang);
  }

  LangChange(e,flag){
    this.selectLang =e.value;
    this.tabelLoad(this.selectLang);
  }
  tabelLoad(selectedLang){
    this.translationService.getManageLanguageData(selectedLang).subscribe( res =>{
      this.data = res;
      if(this.data.status == 0 ){
        this.dataSource = new MatTableDataSource(this.data.response);
        this.dataSource.sort = this.sort;
      }
    })
  }
  publishLanguageFile(appName,langId,isWeb):any{
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
    width: '600px',
    height: 'auto',
    data: {
      message: "Are you sure wants to publish?",
      type: 'confirmation'
    },
    panelClass: 'confirmpopup',
  });
  dialogRef.afterClosed().subscribe(res => {
    if (res == true){
      this.translationService.publishJson(appName, langId,isWeb).subscribe( res =>{
        this.data = res;
        if(this.data.status == 0 ){
          this.snackBar.open('Published Successfully !', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.tabelLoad(langId);
        }
      })
    }
  });
  }
  uploadFile(isWeb,appName,langaugeId):any{
    const dialogRef = this.dialog.open(UploadLanguageFileComponent, {
      width: '600px',
      height: 'auto',
      data: {
        isWeb:isWeb,
        appName:appName,
        langaugeId:langaugeId
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.snackBar.open('Uploaded Successfully !', '', {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
      }
    });
  }

  downloadFile(isWeb,appName,langaugeId):any{
    this.languageJson = [];
    this.LangList.forEach(element => {
      if(element.languageId == langaugeId){
        this.selectedLangCode = element.languageCode;
      }
    });
    this.translationService.getJsonForExcel(appName,langaugeId,isWeb).subscribe( res =>{
      this.data = res;
      if(this.data.status == 0 ){
        this.languageJson.push({"section":"Section Name","pageName":"Page Name","key":"Key Name","defaultEnglish":"English","nameBylanguage":this.selectedLangCode+" Language"});
        this.data.response.forEach(element => {
          this.languageJson.push(element);
        });
        this.ExportTOExcel();
      }
    })
  }
  ExportTOExcel() {
    const fileName = 'download_language_'+this.selectedLangCode+'.xlsx';
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.languageJson);
    ws['!rows'] = [];
    ws['!rows'][0] = { hidden: true };
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet');
		XLSX.writeFile(wb, fileName);
    this.snackBar.open('Download Successfully !', '', {
      duration: 3000,
      panelClass: ['success-snackbar']
    });
  }
}