import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {map, startWith} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { LanguageService } from 'src/app/service/language/language.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-blog-view-list',
  templateUrl: './blog-view-list.component.html',
  styleUrls: ['./blog-view-list.component.scss']
})
export class BlogViewListComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId:any;
  langList =[];
  selectLang: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('attachments1') myInputVariable: ElementRef;
  fileData: File = null;
  countryFlagImagePath: any;
  BData: any;
  
  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              public langService :LanguageService,
              private masterDataservice :MasterDataService)
  { this.appCtrl = new FormControl();
  }

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;   
    this.langList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.langList[0].languageCode;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.displayedColumns = ['srno','blogTitle', 'heading','subHeading', 'isActive', 'actions'];
    this.tabelLoad(this.page);
  }

  tabelLoad(page){
    this.masterDataservice.getAllBlogMedia(page, this.size).subscribe( res =>{
      this.data =res;
      console.log(this.data);
      if(this.data.status == 0 ){
        this.totalrecordLength = 0;
        this.page = 0;
        this.totalpageLenght = 1;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;  
        this.apps = this.data.response;
        this.filteredApps = this.appCtrl.valueChanges .pipe(
          startWith(''),
          map(app => app ? this.filterApps(app) : this.apps.slice())
        );
      } else {
        this.snackBar.open('server status -1', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    },(error)=>{
      this.snackBar.open(error.error.message, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  oldpermissionChange (e , element) {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
        this.masterDataservice.changeBlogMediaStatusById(element.blogId).subscribe((data) => {
          // @ts-ignore
          if (data == 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(this.page);
            this.snackBar.open('status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {
        this.masterDataservice.changeBlogMediaStatusById(element.blog).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });

          } else {
            e.source.checked = true;
            this.tabelLoad(this.page);
            this.snackBar.open('status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = false;
      }
    }

  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.categoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any) {
    // console.log(e);
    this.selectproduct = e.categoryName;
    this.selectedId = e.jobCategoryId;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search(){

    this.router.navigate(['editjobcategory',this.selectedId]);

  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage(){
    this.tabelLoad(0);
  }
  lastpage(){
    this.tabelLoad(this.totalpageLenght);
  }
  prevpage(){
    const d = this.page - 1;
    if (d > 0){
      this.tabelLoad(d);
    }
  }
  nextpage(){
    const d = this.page + 1;
    if ( d < this.totalpageLenght){
      this.tabelLoad(d);
    }
  }

  delete(id) {
    if (confirm('Active Are you sure')) {
    this.masterDataservice.deleteBlogMediaById(id)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.tabelLoad(this.page);
          this.snackBar.open('deleted succesfully!!!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
    }
  }
  LangChange(e){
    this.selectLang= e.value;
    this.tabelLoad(this.page);
  }

  bulkUpload(){
    if(this.fileData)
      {
        const formData: FormData = new FormData();
        formData.append('file' , this.fileData);
        formData.append('isForceAddNewlanguage' , 'false');        
        this.masterDataservice.jobCategoryBulkUpload(formData).subscribe(res=>{
          this.BData =res;
          if(this.BData.status == 0){
            this.snackBar.open('File Successfuly uploaded.', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.tabelLoad(this.page);
          } else {
            this.snackBar.open(this.BData.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        },(error)=>{
          this.snackBar.open(error.error.message, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        });
        
      }
    // jobCategoryBulkUpload
  }

  fileSelect(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      this.bulkUpload();
      // tslint:disable-next-line: variable-name
      /*reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };*/
    }
    // this.newimgeuplod = true;
    console.log(this.fileData);
  }
}