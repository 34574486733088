import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { MatDialog } from '@angular/material/dialog';
import {CommonConfirmPopupComponent} from 'src/app/component/common-confirm-popup/common-confirm-popup.component';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  prdlist=[];
  private data: any;
  seachcatogry: FormGroup;
   appCtrl: FormControl;
   filteredApps: Observable<any[]>;
   apps = [];
   selectproduct: any;
   page : number;
   size: number;
   totalpageLenght: number;
   totalrecordLength: number;
   selectedId: any;
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder, 
    public dialog: MatDialog,   
      private masterDataservice :MasterDataService) {
        this.appCtrl = new FormControl();
      }

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.displayedColumns = ['srno','type','name','email' ,'mobileNumber','requestType','source','date','actions'];
    this.tabelLoad(this.page);
  }
  tabelLoad(page){
    this.masterDataservice.getAllContactList(page, this.size).subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){

        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.number;
        this.totalpageLenght = this.data.response.totalPages;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;        
      }
    })


  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


filterApps(name: any) {
  const filterValue = name.toLowerCase();
  if(name == ''){
    return this.apps;
  } else {
    return  this.apps.filter(p => p.name.toLowerCase().includes(filterValue));
  }
}
onEnter(e: any) {
  this.selectproduct = e.name;
  this.selectedId = e.id;
  // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
}
search(){

  this.router.navigate(['editTeam',this.selectedId]);

}
keyDownFunction(event) {
  if (event.keyCode === 13) {
    this.search();
  }
}

firstpage(){
  this.tabelLoad(0);
}
lastpage(){
  this.tabelLoad(this.totalpageLenght);
}
prevpage(){
  const d = this.page - 1;
  if (d > 0){
    this.tabelLoad(d);
  }
}
nextpage(){
  const d = this.page + 1;
  if ( d < this.totalpageLenght){
    this.tabelLoad(d);
  }
}


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  deleteContact(requestId) {
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: "Are you sure wants to delete?",
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.masterDataservice.deleteContactDetails(requestId).subscribe( res =>{
          this.data = res;
          if(this.data.status == 0 ){
            this.snackBar.open('Deleted Successfully !', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.tabelLoad(this.page);
          }
        })
      }
    });
  }
}
