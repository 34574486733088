import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MasterDataService } from '../../../../service/masterData/master-data.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { LanguageService } from 'src/app/service/language/language.service';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';

@Component({
  selector: 'app-list-competency-category',
  templateUrl: './list-competency-category.component.html',
  styleUrls: ['./list-competency-category.component.scss']
})
export class ListCompetencyCategoryComponent implements OnInit {
  dataSource = new MatTableDataSource();
  competencyList = [];
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  page: number =0;
  size: number =10;
  displayedColumns: string[];
  totalpageLenght: number;
  totalrecordLength: number;
  private data: any;
  filteredApps: Observable<any[]>;
  apps = [];
  langList = [];
  selectLang: any;
  config:any;
  

  constructor(private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public langService: LanguageService,
    private masterDataservice: MasterDataService) { this.appCtrl = new FormControl(); }

  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.langList = JSON.parse(this.langService.getLngList());
    console.log(this.langList);
    this.selectLang = this.langList[0].languageCode;
    this.displayedColumns = ['srno', 'competencyCategoryName', 'parentName', 'isActive', 'actions'];
    // this.tabelLoad(this.page, this.selectLang);
  }

  tabelLoad(page, lang): any {
    this.masterDataservice.getAllCompetencyCategory(page, this.size, lang).subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        this.apps = this.data.response.dataSet;
        this.filteredApps = this.appCtrl.valueChanges.pipe(
          startWith(''),
          map(app => app ? this.filterApps(app) : this.apps.slice())
        );
      }
    });

  }
  filterApps(name: any): any {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if (name == '') {
      return this.apps;
    } else {
      return this.apps.filter(p => p.competencyCategoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }

  deleteCategoryDetails(competencyCategoryId): any {
    if (confirm('Active Are you sure')) {
      this.masterDataservice.deleteCometencyCategory(competencyCategoryId)
        .subscribe(res => {
          this.data = res;
          if (this.data.status == 0) {
            this.tabelLoad(0, this.selectLang);
            this.snackBar.open('Competency Category deleted succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          } else {
            this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }, error => console.log(error));
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  chnagePermissions(e, element): any {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
        console.log(element.competencyCategoryId);
        this.masterDataservice.changeCompetencyCategoryStaus(element.competencyCategoryId).subscribe((data) => {
          // @ts-ignore
          if (data == 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(0, this.selectLang);
            this.snackBar.open('Status Changed Succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {
        this.masterDataservice.changeCompetencyCategoryStaus(element.competencyCategoryId).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });

          } else {
            e.source.checked = true;
            this.tabelLoad(0, this.selectLang);
            this.snackBar.open('Status Changed Succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = false;
      }
    }
  }
  LangChange(e): any {
    this.selectLang = e.value;
    this.tabelLoad(0, this.selectLang);
  }
  ngAfterViewInit() {
    this.tabelLoad(this.page, this.selectLang);
  }
  onChangePage(e) {
    this.page = e.pageIndex;
    this.tabelLoad(e.pageIndex, this.selectLang);
  }
}
