import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { MemberServiceService } from '../../servicesMember/member-service.service';

@Component({
  selector: 'app-add-edit-voucher',
  templateUrl: './add-edit-voucher.component.html',
  styleUrls: ['./add-edit-voucher.component.scss']
})
export class AddEditVoucherComponent implements OnInit {

  id: any;
  fDate : any;
  startdateVlaidtion: any;
  expMinDateFlag: boolean = false;
  startDateMinDateFlag: boolean = false;
  today = new Date();
  showCompanyList: boolean = false;
  plandetails =[];
  companyList= [];
  
  @ViewChild('attachments1') myInputVariable: ElementRef;
  countryId: any;
  fileData: File = null;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  countryList= [];
  
  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              public memberShipService : MemberServiceService,
              private route: ActivatedRoute,
              public datepipe: DatePipe,
              private masterDataservice :MasterDataService,
              private router: Router) { }
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  config:any;
  countryListShow: boolean = true;

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getList();
    this.countryListData();
    this.getAllCompanyList();
    this.initializeForm();
    this.fDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  }

  // To initialize Form
  initializeForm() {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.formGroup = this.fb.group({
      membershipId: ['' , Validators.required],
      description: ['' , Validators.required],
      appliesTo: ['ALL', Validators.required],
      value: [ '' , Validators.required],
      voucherType: [ '' , Validators.required],
      validityOnceGenerated: [ '' , Validators.required],
      startDate: [ '' , Validators.required],
      validity: [ '' , Validators.required],
      companyAppliesTo: [],
      countryAppliesTo: [],
      imageUrl : [],
      voucherId:[0],
      voucherCriteriaId:[1]
    });

    if (this.id){
      this.patchData();
    }
  }
  countryListData(){
    this.masterDataservice.getAllCountryNew().subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.countryList = this.data.response;
      }
    })

  }
  countryFlagReset() {
    this.countryFlagImgURL = '';
    this.myInputVariable.nativeElement.value = '';
  }
  countryFlagSelct(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };
    }
    this.newimgeuplod = true;
  }
  getList(){ 
    this.memberShipService.getallMemeberShipdetails().subscribe((res:any)=>{
      if(res.status == 0){     
        console.log(res.response)
        this.plandetails = res.response;
      } else {
        alert(res.response);
      }
    } , (error:any)=>{
      alert(error.error.message);
    }
  );
  }
  patchData(){
    this.memberShipService.getVoucherById(this.id).subscribe( (res:any)=>{
      if(res.status == 0){
        this.formGroup.patchValue({
          membershipId: res.response.membershipId,
          description: res.response.description ,
          appliesTo: res.response.appliesTo ,
          value: res.response.value ,
          voucherType: res.response.voucherType ,
          validityOnceGenerated: res.response.validityOnceGenerated ,
          startDate: this.datepipe.transform(res.response.startDate, 'yyyy-MM-dd'),
          validity: this.datepipe.transform(res.response.validity, 'yyyy-MM-dd'),
          voucherId: res.response.voucherId ,
          voucherCriteriaId: res.response.voucherCriteriaId ,
        });
        this.countryFlagImgURL= res.response.imageUrl;
        if(res.response.companyAppliesTo){
          var arr = res.response.companyAppliesTo.split(',');
          for (let a in arr ) {arr[a] = parseInt(arr[a]);}
          this.formGroup.patchValue({companyAppliesTo:arr });
        }
        if(res.response.countryAppliesTo){
          var arr2 = res.response.countryAppliesTo.split(',');
          for (let a in arr2 ) {arr2[a] = parseInt(arr2[a]);}
          this.formGroup.patchValue({countryAppliesTo: arr2});
        }
        if(res.response.appliesTo == 'INDIVIDUAL'){
          this.showCompanyList =true;
          this.countryListShow = false;
        } else {
          this.countryListShow = true;
          this.showCompanyList = false;
        }
      } else {
        this.snackBar.open(res.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    } , (error:any)=>{
      this.snackBar.open(error.error.response, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }
  applyForChange(e){
    console.log(e);
    if(e.value == 'INDIVIDUAL'){
      this.showCompanyList =true;
      this.countryListShow = false;
    } else {
      this.countryListShow = true;
      this.showCompanyList = false;
    }
  }
  getAllCompanyList(){
    this.masterDataservice.getAllUserList(0, 5000).subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.companyList = this.data.response.dataSet.filter((d) => {
          if (d.userRoleType == 'RECRUITER' && d.companyName!=null) {
            return d;
          }
        });

      }
  });
}

  onSubmit() {
    if (this.formGroup.valid) {
      var expDate = (new Date(this.formGroup.value.validity).getTime()).toString();
      var startDate = (new Date(this.formGroup.value.startDate).getTime()).toString();
      const formData: FormData = new FormData();
      formData.append('appliesTo' , this.formGroup.value.appliesTo);
      formData.append('companyAppliesTo' , this.showCompanyList==true?(this.formGroup.value.companyAppliesTo).toString():null);
      formData.append('countryAppliesTo' , this.countryListShow==true?(this.formGroup.value.countryAppliesTo).toString():null);
      formData.append('description' , this.formGroup.value.description);
      if(this.fileData) {formData.append('imageUrl' , this.fileData);}
      formData.append('membershipId' , this.formGroup.value.membershipId);
      formData.append('startDate' , startDate);
      formData.append('status' , "ACTIVE");
      formData.append('validity' , expDate);
      formData.append('validityOnceGenerated' , this.formGroup.value.validityOnceGenerated);
      formData.append('value' , this.formGroup.value.value);
      formData.append('voucherCriteriaId' , this.formGroup.value.voucherCriteriaId);
      formData.append('voucherId' , this.formGroup.value.voucherId);
      formData.append('voucherType' , this.formGroup.value.voucherType);
      console.log("formData",formData);
      if(this.id){
        this.memberShipService.editVoucher(formData).subscribe( (data: any) => {
          if(data.status == 0)
          {
            this.snackBar.open('Updated Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listVouchar']);
          }
          else{
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        });
      } else {
        this.memberShipService.addVoucher(formData).subscribe( (data: any) => {
          if(data.status == 0)
          {
            this.snackBar.open('Added Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listVouchar']);
          }
          else{
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        });
      }
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }

  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }
  startDateValidtion(e): any {
    console.log(e.target.value);
    this.startdateVlaidtion = e.target.value;
    var dt = new Date(e.target.value);
    dt.setDate(dt.getDate() + 1);
    var changeDate = this.datepipe.transform(dt, 'yyyy-MM-dd');
    
    const d = this.datepipe.transform(this.today, 'yyyy-MM-dd'); 
    console.log(d);
    if(d >= changeDate){
      this.expMinDateFlag = true;
    } else{
      this.expMinDateFlag = false;
    }

  }
  endDateValidtion(e){
    var dt = new Date(e.target.value);
    dt.setDate(dt.getDate() + 1);
    var changeDate = this.datepipe.transform(dt, 'yyyy-MM-dd');
    
    const d = this.datepipe.transform(this.formGroup.controls.validity.value, 'yyyy-MM-dd'); 
    console.log(d);
    if(d >= changeDate){
      this.startDateMinDateFlag = true;
    } else{
      this.startDateMinDateFlag = false;
    }
  }

}
