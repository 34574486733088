import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MultilevelNodes, MultilevelMenuService } from 'ng-material-multilevel-menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  config: any;
  @Output()
  newAuctionReload: EventEmitter<string> = new EventEmitter<string>();
  dashbaord =[
    {
      label: 'Dashboard',
      imageIcon: '/assets/img/monitor-outline.svg',
      // faIcon: 'fas fa-tv',
      link: '/home'
    },
  ];
  /*membership =[
    {
      label: 'MemberShip List',
      imageIcon: '/assets/img/monitor-outline.svg',
      link: '/membershipList'
    },
    {
      label: 'Coupon List',
      imageIcon: '/assets/img/monitor-outline.svg',
      link: '/listDiscount'
    },
    /!*{
      label: 'voucher List',
      imageIcon: '/assets/img/monitor-outline.svg',
      link: '/listVouchar'
    },*!/
    
  ];*/
  // payment =[
  //   {
  //     label: 'Payment List',
  //     imageIcon: '/assets/img/monitor-outline.svg',
  //     link: '/paymentList'
  //   }
  // ];
  manageTeam = [
    {
      label: 'Team List',
     imageIcon:'/assets/img/cube-outline.svg',
      // faIcon: 'fas fa-users',
      link: '/teamList'
      // items: [
      //   {
      //     label: 'List Team Member',
      //     link: '/teamList'
      //   },
      // ]
    },   
    // {
    //   label: 'Candidate Request CV ',
    //  imageIcon:'/assets/img/cube-outline.svg',
    //   link: '/requestCvList'     
    // },
    // {
    //   label: 'Manage Job ',
    //  imageIcon:'/assets/img/cube-outline.svg',
    //   // faIcon: 'fas fa-users',
    //   link: '/jobList'
    // },
  ];
  manageUser = [
    {
      label: 'Manage User',
      imageIcon: '/assets/img/cube-outline.svg',
      faIcon: 'fas fa-users',
      link: '',
      items: [
        {
          label: 'Manage Candidate',
          link: '/candidate-list',
          faIcon: ''
        },  
        {
          label: 'Manage Employer',
          faIcon: '',
            items: [
              {
                label: 'Employer List',
                link: '/user-list',
                faIcon: ''
              },
              {
                label: 'Job Approval ',
                link: '/job-approval-list',
                faIcon: ''
              },             
            ]
          },
      
        // {
        //   label: 'Manage Job',
        //   link: '/jobList',
        //   faIcon: ''
        // },
     
      ]
    },
  ];

  userRequest = [
    {
      label: 'User Request',
      imageIcon: '/assets/img/cube-outline.svg',
      faIcon: 'fas fa-users',
      link: '',
      items: [
    {
      label: 'Candidate CV Request ',
      link: '/requestCvList',
      faIcon: ''
    },
    {
      label: 'Contact Request',
      link: '/contactList',
      faIcon: ''
    },
  ]
}
  ];
  ManageSystemSetting=[
    {
    label: 'System Setting',
      faIcon: 'fas fa-cogs',
      items: [
        {
          label: 'Manage Role & Access',
          link: '/rollList',
          faIcon: ''
        },
        {
          label: 'Manage Media',
          link: '/media-list',
          faIcon: ''
        },
        // {
        //   label: 'Manage Notifications',
        //   link: '/notification-template',
        //   faIcon: ''
        // },
        {
          label: 'Manage Contact Us DashBoard ',
          link: '/contact-us',
          faIcon: ''
        },
        {
          label: 'Manage User Policy ',
          link: '/user-policy',
          faIcon: ''
        },
        {
          label: 'Daily Job Limit ',
          link: '/daily-limit',
          faIcon: ''
        },
       /* {
          label: 'system configure',
          link: '/rollList',
          faIcon: ''
        },*/
        /*{
          label: 'Translator',
          link: '/translation',
          faIcon: ''
        },*/
      ]
    }];
    reports = [
      {
      label: 'Reports',
        faIcon: 'fa fa-file-excel-o',
        items: [
          {
            label: 'Manage Jobs',
            link: '/jobList',
            faIcon: ''
          },
          {
            label: 'Manage Resume Boosted', 
            link: '/rusume-boosted-list',
            faIcon: ''
          },
          {
            label: 'Employer Membership',
            link: '/employer-membership-list',
            faIcon: ''
          },
          {
            label: 'Candidate Membership',
            link: '/candidate-membership-list',
            faIcon: ''
          },
        ]
      }];
    ManageMembership = [
      {
      label: 'Manage Membership',
        faIcon: 'fas fa-balance-scale',
        items: [
          {
            label: 'Employer Membership',
            faIcon: '',
              items: [
                {
                  label: 'Employer Plan',
                  link: '/employerPlanList',
                  faIcon: ''
                },

                // {
                //   label: 'Candidate Plan',
                //   link: '/candidate-plan-list',
                //   faIcon: ''
                // },
                // {
                //   label: 'Addon Plan',
                //   link: '/addonPlanList',
                //   faIcon: ''
                // },
                // {
                //   label: 'Discount Coupon',
                //   link: '/coupon-list',
                //   faIcon: ''
                // },
                {
                  label: 'Customize Plan',
                  link: '/customize-plan-list',
                  faIcon: ''
                },
               
              ]
            },
          // {
          //   label: 'Employer Plan',
          //   link: '/employerPlanList',
          //   faIcon: ''
          // },

          {
            label: 'Candidate Plan',
            link: '/candidate-plan-list',
            faIcon: ''
          },
          // {
          //   label: 'Addon Plan',
          //   link: '/addonPlanList',
          //   faIcon: ''
          // },
          // {
          //   label: 'Discount Coupon',
          //   link: '/coupon-list',
          //   faIcon: ''
          // },
          // {
          //   label: 'Customize Plan',
          //   link: '/customize-plan-list',
          //   faIcon: ''
          // },
         
        ]
      }];

  MangeMasterData=[
    {
      label: 'Master Data',
      faIcon: 'fas fa-sitemap',
      items: [
        /*{
          label: 'List Language',
          link: '/listLang',
          faIcon: ''
        },*/
        {
          label: 'Manage Job Category',
          link: '/listjobsubcategory',
          faIcon: ''
        },
      /*  {
          label: 'Job Category Report',
          link: '/jobcategoryReport',
          faIcon: ''
        },   */
        {
          label: 'List Industry',
          link: '/listIndusrty',
          faIcon: ''
        },
        {
          label: 'List Location Data',
          link: '/location-list',
          faIcon: ''
        },
        {
          label: 'List country Data',
          link: '/listCountryData',
          faIcon: ''
        },
        {
          label: 'List Zone Data',
          link: '/listZoneData',
          faIcon: ''
        },
        {
          label: 'List State Data',
          link: '/listStateData',
          faIcon: ''
        },
        {
          label: 'List District Data',
          link: '/listDistrictData',
          faIcon: ''
        },
        {
          label: 'List City Data',
          link: '/listCityData',
          faIcon: ''
        },
        {
          label: 'List Skills set',
          link: '/listSkillSet',
          faIcon: ''
        },
       /* {
          label: 'Competency Category',
          link: '/listCompetencyCategory',
          faIcon: ''
        },*/
        // {
        //   label: 'Competency Keyword',
        //   link: '/listCompetancyKeywords',
        //   faIcon: ''
        // },
       /* {
          label: 'Restricted Keyword',
          link: '/listRestrictedKeywords',
          faIcon: ''
        },*/
        {
          label: 'Manage Employement Type',
          link: '/listEmployementType',
          faIcon: ''
        },
        {
          label: 'Manage Contract Type',
          link: '/listContractType',
          faIcon: ''
        },
        // {
        //   label: 'Manage Education Level',
        //   link: '/listEducationLevel',
        //   faIcon: ''
        // },
        {
          label: 'Manage Education',
          link: '/listEducation',
          faIcon: ''
        },
        {
          label: 'Manage Working Condition',
          link: '/listWorkingCondition',
          faIcon: ''
        },
        {
          label: 'Manage Pay Offered',
          link: '/listPayOffered',
          faIcon: ''
        },
        {
          label: 'Manage Benefits Type',
          link: '/listBenefitsType',
          faIcon: ''
        },
        {
          label: 'Manage Blogs',
          link: '/blog-list',
          faIcon: ''
        }
      ]
    },
  ];

  /*ManageTranslationData=[
    {
      label: 'Manage Translation',
      faIcon: 'fas fa-language',
      link: '/manageLanguage'
      // [
      //   {
      //     label: 'Manage Language File',
      //     link: '/manageLanguage',
      //     faIcon: ''
      //   },
      //   // {
      //   //   label: 'Add Translation',
      //   //   link: '/addTranslation',
      //   //   faIcon: ''
      //   // },
      // ]
    },
  ];*/

  /*MangeJobPlans=[
    {
      label: 'Manage Job Post Plans',
      faIcon: 'fas fa-vr-cardboard',
      items: [
        {
          label: 'Job Post Plan',
          link: '/listJobPlans',
          faIcon: ''
        },
        {
          label: 'Job Board Add-on',
          link: '/listJobBoard',
          faIcon: ''
        },
        {
          label: 'Job Free Services',
          link: '/listJobFreeService',
          faIcon: ''
        },
        
      ]
    }];*/
  /*PersonalityTest =[
    {
      label: 'Personality Test',
      faIcon: 'fas fa-file-alt',
      items: [
        {
          label: 'Category List',
          link: '/listPTcategory',
          faIcon: ''
        },
        {
          label: 'Manage Question',
          link: '/mangeQuestion',
          faIcon: ''
        },
        {
          label: 'Recruier OCEAN Description',
          link: '/listrecruiterOcen',
          faIcon: ''
        },
        {
          label: 'candidate OCEAN Description',
          link: '/listCandidateOcean',
          faIcon: ''
        },
        
        {
          label: 'Manage OCEAN',
          link: '/listPersonalityCategoryValues',
          faIcon: ''
        },
        {
          label: 'Manage ICC',
          link: '/list-iccvalues',
          faIcon: ''
        },
      ]
    },
  ]
  TestAssesment=[
    {
      label: 'Test & Assesment',
      faIcon: 'fas fa-book-open',
      items: [
        {
          label: 'Question Type',
          link: '/listQuestionType',
          faIcon: ''
        },
        {
          label: 'Question Group',
          link: '/listQuestionGroup',
          faIcon: ''
        },
        {
          label: 'Create Question',
          // imageIcon:'/assets/img/question-mark-circle-outline.svg',
          link: '/listjobTestQuestion',
          faIcon: ''
        },
        {
          label: 'Create Test ',
          // imageIcon:'/assets/img/list-outline.svg',
          link: '/listJobTestCreation/en',
          faIcon: ''
        },


      ]
    },
  ];
  InterviewQuestion =[
    {
      label: 'Manage Interview Question',
      faIcon: 'fas fa-file-alt',
      items: [
        // {
        //   label: 'Add MCQ',
        //   link: '/addInterviewQuestion',
        //   faIcon: ''
        // },
        {
          label: 'List MCQ',
          link: '/listInterviewQuestion',
          faIcon: ''
        },
        // {
        //   label: 'Add Video',
        //   link: '/addVideoInterviewQuestion',
        //   faIcon: ''
        // },
        {
          label: 'List Video',
          link: '/listvideoInterviewQuestion/en',
          faIcon: ''
        },
        {
          label: 'Interview Setting',
          link: '/listInterviewSetting',
          faIcon: ''
        },



      ]
    },
  ]*/
  jobTestQuestion = [
    {
      label: 'list Test Question',
      imageIcon: '/assets/img/question-mark-circle-outline.svg',
      link: '/listjobTestQuestion',
    },
    {
      label: 'List Test ',
      imageIcon: '/assets/img/list-outline.svg',
      link: '/listJobTestCreation/en',
    },
  ];

  sidebar: MultilevelNodes[] = [
    {
      label: 'Dashboard',
      // imageIcon: '/assets/img/bg.png',
      faIcon: 'fas fa-tv',
      link: '/home'
    },
    {
      label: 'Manage Team',
      faIcon: 'fas fa-users',
      items: [
        {
          label: 'List Team Member',
          link: '/teamList'
        },
      ]
    },
    {
      label: 'Manage System Setting',
      faIcon: 'fas fa-cogs',
      items: [
        {
          label: 'Manage Role & Access',
          link: '/rollList',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'system configure',
          link: '/rollList',
          faIcon: 'fas fa-allergies'
        },
        // {
        //   label: 'System Configuration',
        //   faIcon: 'fas fa-ambulance',
        //   items: [
        //     {
        //       label: 'Mange Role&Access',
        //       link: '/listsuperdistributor',
        //       faIcon: 'fas fa-allergies'
        //     },
        //   ]
        // },
      ]
    },
    {
      label: 'Master Data',
      faIcon: 'fas fa-sitemap',
      items: [
       /* {
          label: 'List Language',
          link: '/listLang',
          faIcon: 'fas fa-allergies'
        },*/
        {
          label: 'Manage Job Category',
          link: '/listjobsubcategory',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'List Industry',
          link: '/listIndusrty',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'List country Data',
          link: '/listCountryData',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'List state Data',
          link: '/listStateData',
          faIcon: 'fas fa-allergies'
        },
        
        {
          label: 'List Skills set',
          link: '/listSkillSet',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'Competency Category',
          link: '/listCompetencyCategory',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'Competency Keyword',
          link: '/listCompetancyKeywords',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'Restricted Category',
          link: '/listRestrictedKeywords',
          faIcon: 'fas fa-allergies'
        },
      ]
    },
    {
      label: 'Personality Test',
      faIcon: 'fas fa-file-alt',
      items: [
        {
          label: 'category List',
          link: '/listPTcategory',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'Manage Question',
          link: '/mangeQuestion',
          faIcon: 'fas fa-allergies'
        },
        // {
        //   label: 'Manage Personality Test Category',
        //   faIcon: 'fas fa-allergies',
        //   items :[
        //     {
        //       label: 'Catogery List',
        //       link: '/listPTcategory',
        //       faIcon: 'fas fa-allergies'
        //     },
        //   ]
        // },
        // {
        //   label: 'Manage Personality Test',
        //   faIcon: 'fas fa-ambulance',
        //   items: [
        //     {
        //       label: 'Manage Question',
        //       link: '/mangeQuestion',
        //       faIcon: 'fas fa-allergies'
        //     },
        //   ]
        // },

      ]
    },
    {
      label: 'Test & Assesment',
      faIcon: 'fas fa-book-open',
      items: [
        {
          label: 'Question Type',
          link: '/listQuestionType',
          faIcon: 'fas fa-allergies'
        },
        {
          label: 'Test Level',
          link: '/listQuestionGroup',
          faIcon: 'fas fa-allergies'
        },

      ]
    },
    {
      label: 'Interview Question',
      faIcon: 'fas fa-book-open',
      items: [
        {
          label: 'Question List',
          link: '/listInterviewQuestion',
          faIcon: 'fas fa-allergies'
        },


      ]
    },

  ];

  constructor( private multilevelMenuService: MultilevelMenuService,
    private router: Router,private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.config = {
      interfaceWithRoute: false,
      classname: 'side-menu-class',
      fontColor: `#14112d`,
      selectedListFontColor: `#4c84ff`,
      paddingAtStart: false,
      // listBackgroundColor: `rgb(208, 241, 239)`,
      // backgroundColor: `rgb(208, 241, 239)`,
      highlightOnSelect: false,
      collapseOnSelect: true,
      useDividers: false,
      rtlLayout: false
    };
  }
  setExpandCollapseStatus(type) {
    this.multilevelMenuService.setMenuExapandCollpaseStatus(type);
  }
  selectedItem(url: any) {
    this.router.navigate([url.link]);
    // this.newAuctionReload.emit('reload');
  }

}
