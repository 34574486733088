<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
  <div class="page-header px-0">
    <h3 class="page-title">List Of Job Test</h3>
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
      <li class="breadcrumb-item active">List Of Job Test</li>
    </ol>
  </div>
</div>
<!--Page Header-->

<div class="col-sm-12">
  <div class="card">
    <mat-tab-group (selectedTabChange)="tabClick($event)" [(selectedIndex)]="selectedTab">
      <mat-tab label="Created Test List">
        <div class="c_table">
          <div class="mat-elevation-z8">
            <div class="card mb-3">
              <h3 class="col-sm-12 mt-3 text-upper">Advance Filter</h3>
              <div class="d-flex table-responsive p-3">
                <div class="mr-2 col-sm-2">
                  <!-- (selectionChange)="LangChange($event)" -->
                  <mat-select [(ngModel)]="selectLang" (selectionChange)="LangChange($event)" class="form-control">
                    <mat-option *ngFor="let r of LangList" [value]="r.languageCode" [aria-label]="r.languageCode">
                      {{r.languageName}}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="mr-2 col-sm-3">
                  <mat-select (openedChange)="myInput.focus()" 
                  (closed)="myInput.value='';search(myInput.value)" 
                  placeholder="Please select job category"
                  [(ngModel)]="selectJobcategoryId"
                   class="form-control">
                   <input class="myInput form-control"
                    #myInput focused="'true'" type="text"
                    (keydown)="$event.stopPropagation()"
                    (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                     <mat-option value=''>All</mat-option>
                   <mat-option *ngFor="let t of jobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                     {{t.displayName}}
                   </mat-option>
                 </mat-select>

                </div>
                <div class="mr-2 border-0 d-none d-md-block">
                  <input class="form-control" [(ngModel)]="searchQuestionText" placeholder="Search Here">
                </div>
                <div class="col-sm-2">
                  <div 
                  matTooltip="search " matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                  class="btn btn-primary btn-sm rounded-lg fs-13" (click)="submit()">Search </div>
                </div>
                <div class="col-sm-2">
                  <div
                  matTooltip="Reset your advance filter" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                  class="btn btn-danger btn-sm rounded-lg fs-13" (click)="reset()">Reset </div>
                </div>
              </div>
            </div>
            <div class="d-flex table-responsive p-3">
              <div class="mr-2">
                <div 
                matTooltip="craete new test " matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                [routerLink]="['/addJobTestCreation']"
                class="btn btn-primary btn-sm rounded-lg fs-13">
                  <span>
                    <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                    Add</span>
                </div>
              </div>
              <!-- <div class="mr-2 col-sm-2">
                <mat-select [ngModel]="selectLang" (selectionChange)="LangChange($event)" class="form-control">
                  <mat-option *ngFor="let r of LangList" [value]="r.languageCode" [aria-label]="r.languageCode">
                    {{r.languageName}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
              </div> -->
            </div>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
              *ngIf="dataSource.data.length > 0 ">
              <!-- <ng-container matColumnDef="srno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                <td mat-cell *matCellDef="let element;let j = index">
                  <span *ngIf="testCurrentPage == 0"> {{j + 1}}</span>
                  <span *ngIf="testCurrentPage != 0">{{((testCurrentPage * size) + (j + 1))}}</span>
                 
              </ng-container> -->

              <div>
                <ng-container matColumnDef="assesementTestId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                  <td mat-cell *matCellDef="let element">{{(element.assesementTestId)}}</td>
                </ng-container>

                <ng-container matColumnDef="parentCatgoryname">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Parent category </th>
                  <td mat-cell *matCellDef="let element">{{(element.jobcategoryName).split('>')[0]}}</td>
                </ng-container>
                <ng-container matColumnDef="jobcategoryName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Subcategory name</th>
                  <td mat-cell *matCellDef="let element">{{(element.jobcategoryName).split('>')[1]}}</td>
                </ng-container>

                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>title</th>
                  <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.title"></span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="totalNumberOfQuestions">
                  <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> total No. Of Questions</th>
                  <td width="80px" mat-cell *matCellDef="let element">{{element.totalNumberOfQuestions}}</td>
                </ng-container>
                <ng-container matColumnDef="maxTimeInMin">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> maxTime In (Min)</th>
                  <td mat-cell *matCellDef="let element">{{element.maxTimeInMin}}</td>
                </ng-container>
                <ng-container matColumnDef="numberOfRetry">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> No. Of Retry</th>
                  <td mat-cell *matCellDef="let element">{{element.numberOfRetry}}</td>
                </ng-container>
                <ng-container matColumnDef="normalPassingScoreMax">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Passing Score</th>
                  <td mat-cell *matCellDef="let element"> {{element.normalPassingScoreMin}}% -
                    {{element.normalPassingScoreMax}}% </td>
                </ng-container>
                <ng-container matColumnDef="excellentPassScore">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> excellent Pass Score</th>
                  <td mat-cell *matCellDef="let element">{{element.excellentPassScore}}%</td>
                </ng-container>
                <ng-container matColumnDef="isTestCreateSuccess">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Test status </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="badge"
                      [ngClass]="{'badge-warning' : element.isTestCreateSuccess == false ,'badge-success' : element.isTestCreateSuccess == true}">
                      {{element.isTestCreateSuccess == false ? 'Draft' : 'Created' }}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="createdEpochTime">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header  disableClear> created date </th>
                  <td mat-cell *matCellDef="let element">
                  
                    <div>  {{element.createdEpochTime | date:'medium'}} </div>
                    <!-- {{element.createdEpochTime | date:'medium':'UTC +2'}} -->
                    
                    <!-- {{element.createdEpochTime | date:'longDate'}} -->
                  </td>
                </ng-container>

              </div>
              <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.isTestCreateSuccess == true">
                    <mat-slide-toggle (change)="oldpermissionChange($event,element)" name="isActive"
                      [checked]="element.isActive"></mat-slide-toggle>
                  </span>

                </td>
              </ng-container>



              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-left">
                  <div class="d-flex justify-content-start">
                    <span *ngIf="element.isTestCreateSuccess == true"
                    matTooltip="publish Test to view candiate time" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                      class="btn btn-sm btn-success btn-block rounded-lg "
                      (click)="publishTest(element.assesementTestId)">
                      Publish
                      <!-- <img src="../../../../assets/img/copy-fill.svg" alt="eye"> -->
                    </span>
                    <span class="btn btn-sm btn-primary rounded-lg mar5"
                    matTooltip="Copy test for different category" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                    (click)="copyTestpopup(element)">
                      <img src="../../../../assets/img/copy-outline.svg" alt="copy">
                    </span>
                    <span class="btn btn-sm btn-primary rounded-lg "
                    matTooltip="edit/view test " matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                      [routerLink]="['/editJobTestCreation', element.assesementTestId ,'1']">
                      <img src="../../../../assets/img/eye.svg" alt="eye">
                    </span>
                    <span 
                    matTooltip="Delete Test" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"                    
                    class="btn btn-sm btn-danger mar5 rounded-lg" (click)="delete(element.assesementTestId)"><i
                        class="fas fa-trash"></i></span>
                  </div>
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <app-nodata *ngIf="dataSource.data.length == 0 "></app-nodata>
            <mat-paginator #paginatorRef (page)="onChangePage($event)" [length]="totalpageLenght" [pageSize]="30"
              [pageSizeOptions]="[30]" showFirstLastButtons></mat-paginator>
            <!-- <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator> -->
            <!-- <div class="d-flex justify-content-end align-items-center">
              <div class="c_page"><b>Current Page: </b> {{page}} </div>
              <div class="t_recd" ><b>Total Records: </b>  {{totalrecordLength}}</div>
              <ul class="pagination">
                <li (click)="firstpage()" ><i class="fas fa-angle-double-left" ></i></li>
                <li (click)="prevpage()" ><i class="fas fa-arrow-left"></i></li>
                <li (click)="nextpage()" ><i class="fas fa-arrow-right" ></i> </li>
                <li (click)="lastpage()"> <i class="fas fa-angle-double-right"></i></li>
            </ul>
            </div> -->

          </div>
        </div>
      </mat-tab>
      <mat-tab label="Publish Test List">
        <div class="c_table">
          <div class="mat-elevation-z8">
            <div class="card mb-3">
              <h3 class="col-sm-12 mt-3 text-upper">Advance Filter</h3>
              <div class="d-flex table-responsive p-3">
                <div class="mr-2 col-sm-2">
                  <!-- (selectionChange)="LangChange($event)" -->
                  <mat-select [(ngModel)]="PublishSelectLang"
                    (selectionChange)="publishLangChange($event)" class="form-control">
                    <mat-option *ngFor="let r of LangList" [value]="r.languageCode" [aria-label]="r.languageCode">
                      {{r.languageName}}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="mr-2 col-sm-3">
                  <mat-select (openedChange)="myInput.focus()"
                  (closed)="myInput.value='';search(myInput.value)" 
                  (closed)="myInput.value='';search(myInput.value)" 
                  [(ngModel)]="selectPUBLISHJobcategoryId"
                  placeholder="Please select job category"
                    class="form-control">
                    <input class="myInput form-control" #myInput focused="'true'" type="text"
                    (keydown)="$event.stopPropagation()"  
                    (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                    <mat-option value=''>All</mat-option>
                    <mat-option *ngFor="let t of jobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                      {{t.displayName}}
                    </mat-option>
                  </mat-select>


                </div>
                <div class="mr-2 border-0 d-none d-md-block">
                  <input class="form-control" [(ngModel)]="publishSerchText" placeholder="Search Here">
                </div>
                <div class="col-sm-2">
                  <div class="btn btn-primary btn-sm rounded-lg fs-13" (click)="publishtestFiltersubmit()">Search
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="btn btn-danger btn-sm rounded-lg fs-13" (click)="publishReset()">Reset
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex table-responsive p-3">
             

              <!-- <div class="mr-2 col-sm-2">
                <mat-select [ngModel]="selectLang" (selectionChange)="PublishLangChange($event)" class="form-control">
                  <mat-option *ngFor="let r of LangList" [value]="r.languageCode" [aria-label]="r.languageCode">
                    {{r.languageName}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
              </div> -->
            </div>
            <table mat-table [dataSource]="PublishDataSource" matSort #sorter2="matSort" class="mat-elevation-z8"
              *ngIf="PublishDataSource.data.length > 0 ">
              <!-- <ng-container matColumnDef="srno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                <td mat-cell *matCellDef="let element;let j = index">
                  <span *ngIf="publishCurrentPage == 0"> {{j + 1}}</span>
                  <span *ngIf="publishCurrentPage != 0">{{((publishCurrentPage * publishSize) + (j + 1))}}</span>
                 
                </td>
              </ng-container> -->

              <div>
                
                <ng-container matColumnDef="assesementTestId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                  <td mat-cell *matCellDef="let element">{{element.assesementTestId}}</td>
                </ng-container>

                <ng-container matColumnDef="jobcategoryName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Job category name</th>
                  <td mat-cell *matCellDef="let element">{{element.jobcategoryName}}</td>
                </ng-container>

                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>title</th>
                  <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.title"></span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="totalNumberOfQuestions">
                  <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> total No. Of Questions</th>
                  <td width="80px" mat-cell *matCellDef="let element">{{element.totalNumberOfQuestions}}</td>
                </ng-container>
                <ng-container matColumnDef="maxTimeInMin">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> maxTime In (Min)</th>
                  <td mat-cell *matCellDef="let element">{{element.maxTimeInMin}}</td>
                </ng-container>
                <ng-container matColumnDef="numberOfRetry">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> No. Of Retry</th>
                  <td mat-cell *matCellDef="let element">{{element.numberOfRetry}}</td>
                </ng-container>
                <ng-container matColumnDef="normalPassingScoreMax">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Passing Score</th>
                  <td mat-cell *matCellDef="let element"> {{element.normalPassingScoreMin}}% -
                    {{element.normalPassingScoreMax}}% </td>
                </ng-container>
                <ng-container matColumnDef="excellentPassScore">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> excellent Pass Score</th>
                  <td mat-cell *matCellDef="let element">{{element.excellentPassScore}}%</td>
                </ng-container>

              </div>
              <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.isTestCreateSuccess == true">
                    <mat-slide-toggle (change)="oldpermissionChange($event,element)" name="isActive"
                      [checked]="element.isActive"></mat-slide-toggle>
                  </span>

                </td>
              </ng-container>



              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-left">
                  <div class="d-flex justify-content-start">

                    <span 
                    matTooltip="publish test view" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                    class="btn btn-sm btn-primary rounded-lg "
                      [routerLink]="['/viewPublishTest', element.assesementTestId ,'1']">
                      <img src="../../../../assets/img/eye.svg" alt="eye">
                    </span>
                    <span 
                    matTooltip="Delete publish test" matTooltipClass='blue-tooltip'
                              matTooltipPosition="above"
                    class="btn btn-sm btn-danger mar5 rounded-lg"
                      (click)="publishDelete(element.assesementTestId)"><i class="fas fa-trash"></i></span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="createdEpochTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header  disableClear> created date </th>
                <td mat-cell *matCellDef="let element">
                  {{element.createdEpochTime | date:'medium'}}
                  <!-- {{element.createdEpochTime | date:'medium':'UTC +2'}} -->
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="PublishdisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: PublishdisplayedColumns;"></tr>
            </table>
            <app-nodata *ngIf="PublishDataSource.data.length == 0 "></app-nodata>
            <mat-paginator #paginatorRefpublish (page)="onTestChangePage($event)" [length]="publishTotalrecordLength"
              [pageSize]="30" [pageSizeOptions]="[30]" showFirstLastButtons></mat-paginator>
            <!-- <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator> -->


          </div>


        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>