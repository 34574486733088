<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!jobCategoryId">Add </span>
                <span *ngIf="jobCategoryId">Edit </span> job Category
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/listjobsubcategory']">job Category List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!jobCategoryId">Add </span>
                    <span *ngIf="jobCategoryId">Edit </span> job Category
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">

            <form [formGroup]="dynamicForm">

                <div class="row">

                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Select Parent Category</label>
                        <div class="col-sm-12 ">
                            <input #searchInput="" class="form-control" aria-label="Apps" [matAutocomplete]="auto" [formControl]="appCtrl" placeholder="Please Select Parent Catogery" [(ngModel)]="SelectAutoSelectID" style="margin-bottom: 14px;">
                            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
                                <mat-option (onSelectionChange)="onEnter(r)" *ngFor="let r of categoryList | async" [value]="r.displayName" [aria-label]="r.displayName">
                                    {{r.displayName}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>

                    <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-6">
                        <div [formGroup]="ticket" class="col-sm-12" style="width: 100%;">
                            <div class="form-group row ">
                                <label class="col-sm-12">
                  Enter Sub Category Name </label>
                                <div class="col-sm-12 ">
                                    <input type="text" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value="" placeholder="Enter Sub Category Name" name="name" formControlName="name" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Icon</label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Job Category Icon" name="jobCategoryIcon" formControlName="jobCategoryIcon">
                            <!-- <mat-error>
                <span *ngIf="!dynamicForm.get('jobCategoryIcon').valid && dynamicForm.get('jobCategoryIcon').touched">
                  Please Enter Job Category Icon</span>
              </mat-error> -->
                        </div>
                    </div>
                </div>
                <div class="">
                    <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()" *ngIf="!jobCategoryId">Submit</button>
                    <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()" *ngIf="jobCategoryId">Update</button>
                </div>

            </form>
        </div>
    </div>
</div>