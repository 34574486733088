<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title">
                <span *ngIf="!viewID">Add New</span>
                <span *ngIf="viewID">View</span> Candidate Details</h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item ">
                    <span [routerLink]="['/candidate-list']">List Of Candidate</span>
                </li>
                <li class="breadcrumb-item active">
                    <span>
              <span *ngIf="!viewID">Add New</span>
                    <span *ngIf="viewID">View</span> Candidate Details</span>
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="row">
        <div class="col-lg-3">
            <div class="card mg-b-20">
                <div class="card-body">
                    <div class="pl-0">
                        <div class="main-profile-overview">
                            <div class="main-img-user profile-user">
                                <img alt="Responsive image" src="../../assets/img/account_blue.svg" *ngIf="profileImage == null || profileImage == undefined">
                                <img alt="" *ngIf="profileImage != null || profileImage != undefined" src="{{ profileImage }}">
                                <!-- <a href="JavaScript:void(0);" class="fas fa-camera profile-edit"></a>-->
                            </div>
                            <div class="justify-content-between mg-b-20 mt-2 text-center">
                                <div>
                                    <h5 class="main-profile-name"> <span>{{firstName}} {{lastName}}</span></h5>
                                    <p class="main-profile-name-text text-muted"><span></span></p>
                                    <p class="main-profile-name-text text-muted">Candidate</p>
                                    <!-- <p class="user-info-rating mt-2 tx-12">
                    <a href="#"><i class="fa fa-star text-warning"> </i></a>
                    <a href="#"><i class="fa fa-star text-warning"> </i></a>
                    <a href="#"><i class="fa fa-star text-warning"> </i></a>
                    <a href="#"><i class="fa fa-star text-warning"> </i></a>
                    <a href="#"><i class="far fa-star text-warning"> </i></a>
                  </p>-->
                                </div>
                            </div>
                            <div class="mt-2 text-center">
                                <!--                  <a href="#" class="btn btn-info"><i class="fa fa-rss"></i> Follow</a>-->
                            </div>
                        </div>
                        <!-- main-profile-overview -->
                    </div>
                </div>
                <div class="card-footer p-0">
                    <!-- <div class="row m-0">
            <div class="col-sm-4 border-right text-center p-0">
              <div class="p-2">
                <div class="mb-0 h6"><i class="icon icon-camera text-primary"></i></div>
                <div class="text-muted mb-0 tx-13">32 photos</div>
              </div>
            </div>
            <div class="col-sm-4 border-right text-center p-0">
              <div class="p-2">
                <div class="mb-0 h6"><i class="icon icon-people text-primary"></i></div>
                <div class="text-muted mb-0 tx-13">87 friends</div>
              </div>
            </div>
            <div class="col-sm-4 text-center p-0">
              <div class="p-2">
                <div class="mb-0 h6"><i class="icon icon-heart text-primary"></i></div>
                <div class="text-muted mb-0 tx-13">2.3k likes</div>
              </div>
            </div>
          </div>-->
                </div>
            </div>
            <!-- <div class="card mg-b-20">
        <div class="card-body">
          <div class="main-content-label tx-13 mg-b-25"> Social </div>
          <div class="main-profile-social-list">
            <div class="media">
              <div class="media-icon bg-info-transparent text-info">
                <i class="fa fa-twitter"></i>
              </div>
              <div class="media-body">
                <span>Twitter</span>
                <a href="#" target="_blank"><span></span></a>
              </div>
            </div>
            <div class="media">
              <div class="media-icon bg-warning-transparent text-warning">
                <i class="fa fa-facebook"></i>
              </div>
              <div class="media-body">
                <span>Facebook Id</span>
                <a href="#" target="_blank"><span></span></a>
              </div>
            </div>
            <div class="media">
              <div class="media-icon bg-success-transparent text-success">
                <i class="fa fa-instagram"></i> </div>
              <div class="media-body">
                <span>Instagram</span>
                <a href="#" target="_blank"><span></span></a>
              </div>
            </div>
            <div class="media">
              <div class="media-icon bg-info-transparent text-info">
                <i class="fa fa-linkedin"></i>
              </div>
              <div class="media-body">
                <span>Linked In</span>
                <a href="#" target="_blank"><span></span></a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
        </div>

        <div class="card col-lg-9">
            <div class="card-body" style="padding: 0;">

                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="Candidate Details">
                        <div class="" style="padding: 1.25em;">
                            <div *ngIf="viewID">
                                <div class="mt-1 product-spec">
                                    <label class="main-content-label tx-13 mg-b-20" *ngIf="profileVideo">About</label>
                                    <div class="main-profile-bio mb-0" *ngIf="profileVideo">
                                        <video [src]="profileVideo" width="440" height="250" autoplay controls></video>
                                    </div>
                                    <br>
                                    <label class="main-content-label tx-13 mg-b-20">Candidate Details</label>
                                    <div class="card-body border-top">
                                        <div class="table-responsive">
                                            <table class="table row table-borderless mb-0">
                                                <tbody class="col-lg-12 col-xl-6" style="padding: 10px !important;">
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">Candidate Name : </span> <span>{{firstName}} {{lastName}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">Mobile Number : </span> <span [innerHtml]="mobileNumber"></span>
                                                        </td>
                                                    </tr>
                                                    <tr style="background-color: #ffffff;">
                                                        <td class="border-top-0"><span class="font-weight-semibold">Email Id : </span> <span [innerHtml]="email"></span>
                                                        </td>
                                                    </tr>
                                                    <!-- <tr style="background-color: #ffffff;">
                          <td class="border-top-0"><span class="font-weight-semibold">Address : </span> <span [innerHtml]="address"></span>
                          </td>
                        </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <!-- <label class="main-content-label tx-13 mg-b-20">Character Details</label> -->
                                    <!-- <br> -->
                                    <!-- <div class="alert alert-outline-danger mg-b-0" role="alert" *ngIf="candidateCharacterlength == 0">
                                        <button aria-label="Close" class="close" data-dismiss="alert" type="button"><span area-hidden="true"></span></button> Charactor's not available.
                                    </div>
                                    <div class="tags border-top" *ngIf="candidateCharacterlength != 0"><br>
                                        <span class="tag tag-rounded jobTabs" *ngFor="let g of candidateCharacterName"> {{g}}</span>
                                    </div> -->
                                    <!-- <br> -->

                                    <!-- <label class="main-content-label tx-13 mg-b-20">Skills Details</label> -->
                                    <!-- <br> -->
                                    <!-- <div class="alert alert-outline-danger mg-b-0" role="alert" *ngIf="candidateSkillLength == 0">
                                        <button aria-label="Close" class="close" data-dismiss="alert" type="button"><span area-hidden="true"></span></button> Skill's not available.
                                    </div>
                                    <div class="tags border-top" *ngIf="candidateSkillLength != 0"><br>
                                        <span class="tag tag-rounded jobTabs" *ngFor="let s of candidateSkillName"> {{s}}</span>
                                    </div> -->
                                    <br>

                                    <label class="main-content-label tx-13 mg-b-20">Prefered Location's</label>
                                    <br>
                                    <div class="card-body border-top" *ngIf="!candidatePreferredLocationList">

                                        <p>Candidate Preferred Location Not Available</p>
                                    </div>
                                    <div class="card-body border-top" *ngIf="candidatePreferredLocationList">
                                        <ul>
                                            <li *ngFor="let element of candidatePreferredLocationList">
                                                {{element}}
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <div class="alert alert-outline-danger mg-b-0" role="alert">
                                        <button aria-label="Close" class="close" data-dismiss="alert" type="button"><span area-hidden="true"></span> {{preferredLocationName}}</button>
                                    </div> -->
                                    <!-- <div class="tags " *ngIf="countryNameLength != 0"><br>
                                        <span class="tag tag-rounded jobTabs" *ngFor="let c of countryName"> {{c}}</span>
                                    </div> -->
                                    <br>

                                    <div class="card-body border-top">
                                        <label class="main-content-label tx-13 mg-b-20">Contact</label>
                                        <div class="card-body border-top">
                                            <div class="main-profile-contact-list d-md-flex">
                                                <div class="media">
                                                    <div class="media-icon bg-primary-transparent text-primary">
                                                        <i class="fa fa-phone"></i>
                                                    </div>
                                                    <div class="media-body">
                                                        <span>Mobile</span>
                                                        <div> <span [innerHtml]="mobileNumber"></span></div>
                                                    </div>
                                                </div>
                                                <div class="media">
                                                    <div class="media-icon bg-success-transparent text-success">
                                                        <i class="fa fa-slack"></i>
                                                    </div>
                                                    <div class="media-body">
                                                        <span>Email Id</span>
                                                        <div><span [innerHtml]="email"></span> </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="media">
                        <div class="media-icon bg-info-transparent text-info">
                          <i class="fa fa-map-marker"></i>
                        </div>
                        <div class="media-body">
                          <span>Current Address</span>
                          <div><span>{{ address }}</span></div>
                        </div>
                      </div> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="CV's">
                        <app-candidate-cv-list></app-candidate-cv-list>
                    </mat-tab>
                    <!-- <mat-tab label="Personality Result">
            <app-candidate-personality-test-result></app-candidate-personality-test-result>
          </mat-tab>
          <mat-tab label="Assessment Result">
            <app-candidate-assessment-test-result></app-candidate-assessment-test-result>
          </mat-tab>-->

                    <mat-tab label="Statistics">
                        <div class="jobs-dashboard">
                            <div class="">
                                <div class="">
                                    <div class="">
                                        <div class="main-content">
                                            <div class="d-flex justify-content-end mb-4">
                                                <!-- <button class="btn btn-primary" style="color: #fff; border-radius: 5px;" (click)="downloadPDF()">Download Report</button> -->
                                            </div>
                                            <div #content class="content">
                                                <table mat-table [dataSource]="dataSource3" matSort class="table-striped contact-table">
                                                    <!-- <ng-container matColumnDef="srno"> -->
                                                    <!-- <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                                                        <td mat-cell *matCellDef="let element;let j = index">
                                                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                                                        </td> -->
                                                    <!-- </ng-container> -->
                                                    <ng-container matColumnDef="candidateFirstName">
                                                        <th width="" mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
                                                        <td mat-cell *matCellDef="let element;let j = index">
                                                            <p class="mb-0">{{element.candidateFirstName}}</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="candidateLastName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p class="mb-0">{{element.candidateLastName}}</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="videoResume">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>video Resume</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p class="mb-0" *ngIf="element.videoResume == true">YES</p>
                                                            <p class="mb-0" *ngIf="element.videoResume == false">NO</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="resumeBooster">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>resume Booster</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p class="mb-0" *ngIf="element.resumeBooster == true">YES</p>
                                                            <p class="mb-0" *ngIf="element.resumeBooster == false">NO</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="jobAlert">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>job Alert</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <p class="mb-0" *ngIf="element.jobAlert == true">YES</p>
                                                            <p class="mb-0" *ngIf="element.jobAlert == false">NO</p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="startDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>start Date</th>
                                                        <td mat-cell *matCellDef="let element">{{element.startDate | date:'dd-MM-yyyy'}}</td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="expiryDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>expiry Date</th>
                                                        <td mat-cell *matCellDef="let element">{{element.expiryDate | date:'dd-MM-yyyy'}}</td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

                                                </table>
                                                <!-- <mat-paginator #paginatorRef [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 40,50,100,200,300,400]" showFirstLastButtons></mat-paginator> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>
    </div>


</div>