import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';


const auctliveurl = environment.baseurl + '/address/getCityNamesByCountry';
const searchtestname = environment.baseurl + '/testSearch';
const addTranslation = environment.baseurl + '/addTranslation';

const apiPaths = new Map<string, any>([
  [auctliveurl, { numberOfHitsPermitted: 0, currentHits: 0 }],
  [searchtestname, { numberOfHitsPermitted: 0, currentHits: 0 }],
  [addTranslation, { numberOfHitsPermitted: 0, currentHits: 0 }],
],
);

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private loadings = false;
  private noShowAPIPaths: Map<string, boolean> = apiPaths;

  loadingStatus: Subject<boolean> = new Subject();

  get apiPaths(): Map<string, boolean> {
    return this.noShowAPIPaths;
  }
  get loading(): boolean {
    return this.loadings;
  }
  set loading(value) {
    this.loadings = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
     this.loading = true;
  }

  stopLoading() {
     setTimeout(() => {
      this.loading = false; 
      console.log('loader hide');
    }, 1000);

  }

}
