import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs/internal/Observable";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CandidateService } from "../../../service/candidateDetails/candidate.service";
import { MasterDataService } from "src/app/service/masterData/master-data.service";
import { MatDialog } from "@angular/material/dialog";
import { CommonConfirmPopupComponent } from "src/app/component/common-confirm-popup/common-confirm-popup.component";
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-candidate-list",
  templateUrl: "./candidate-list.component.html",
  styleUrls: ["./candidate-list.component.scss"],
})
export class CandidateListComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList = [];
  prdlist = [];
  private data: any;
  seachcat;
  seachcatogry: FormGroup;
  searchForm: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  stateList: any = [];
  stateListClone: any = [];
  districtList: any = [];
  districtListClone: any = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private candidateService: CandidateService,
    private masterDataService: MasterDataService
  ) {
    this.appCtrl = new FormControl();
  }
  ngOnInit(): void {
    this.getAllState();
    this.getAllDistrict();
    this.page = 0;
    this.size = 100;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.searchForm = this.formBuilder.group({
      state: [""],
      name: [""],
      MobileNo:[""],
      emailId:[""],
      source:[""],
      district: [""],
      downloadFile: ["CSV"]
    });
    this.displayedColumns = [
      "srno",
      "name",
      "email",
      "source",
      "mobileNumber",
      "district",
      "stateName",
      "createddate",
      "status",
      "actions",
    ];
    this.searchByState(this.page);
  }

  resetForm():any{
    this.searchForm.patchValue({
      state: '',
      name: '',
      MobileNo: '',
      emailId: '',
      source: '',
      district: '',
    })
    this.searchByState(this.page);
  }

  searchByState(page): any {
    this.candidateService
      .getAllCandidateList(page, this.size, this.searchForm.value.state,this.searchForm.value.name,this.searchForm.value.MobileNo,
        this.searchForm.value.emailId,this.searchForm.value.source,this.searchForm.value.district)
      .subscribe((res) => {
        this.data = res;
        if (this.data.status == 0) {
          this.totalrecordLength = this.data.response.totalElements;
          this.page = this.data.response.number;
          this.totalpageLenght = this.data.response.totalElements;
          this.dataSource = new MatTableDataSource(this.data.response.dataSet);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.page;
            this.paginator.length = res['response']?.totalElements;
          }); 
        }
      });
  }

  downloadFile():any{
    // this.candidateService.exportCandidateListExcel(this.searchForm.value.downloadFile).subscribe((res) => {
    //  // saveAs(res, 'Candidate List.'+this.searchForm.value.downloadFile);
    //  //window.open(res,'_blank');
    // })
    window.location.href = `${environment.baseurl1}/candidate/exportCandidateListExcel?extension=`+this.searchForm.value.downloadFile;
  }
 onChangePage(e){
    console.log(e);
    this.page =e.pageIndex;

    this.searchByState(e.pageIndex);
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  delete(rowid: number) {
    if (rowid > -1) {
      this.dataSource.data.splice(rowid, 1);
      this.dataSource._updateChangeSubscription();
    }
  }

  filterApps(name: any) {
    const filterValue = name.toLowerCase();
    if (name == "") {
      return this.apps;
    } else {
      return this.apps.filter((p) =>
        p.name.toLowerCase().includes(filterValue)
      );
    }
  }
  onEnter(e: any) {
    this.selectproduct = e.name;
    this.selectedId = e.id;
  }
  search() {
    this.router.navigate(["editTeam", this.selectedId]);
  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  // firstpage() {
  //   this.tabelLoad(0);
  // }
  // lastpage() {
  //   this.tabelLoad(this.totalpageLenght);
  // }
  // prevpage() {
  //   const d = this.page - 1;
  //   if (d > 0) {
  //     this.tabelLoad(d);
  //   }
  // }
  // nextpage() {
  //   const d = this.page + 1;
  //   if (d < this.totalpageLenght) {
  //     this.tabelLoad(d);
  //   }
  // }
  getAllDistrict(): any {
    this.masterDataService.getAllDistrict().subscribe((data: any) => {
      if (data.status === 0) {
        this.districtList = data.response;
        this.districtListClone = this.districtList;
      }
    });
  }
  getAllState(): any {
    this.masterDataService.getAllStateByCountry().subscribe((data: any) => {
      if (data.status === 0) {
        this.stateList = data.response;
        this.stateListClone = this.stateList;
      }
    });
  }
  stateSearch(query: string): any {
    const d = query.toLowerCase();
    const result = this.stateSelect(d);
    if (query === "") {
      this.stateList = this.stateListClone;
    } else {
      // @ts-ignore
      this.stateList = result;
    }
  }
  stateSelect(query: string): any[] {
    const result: any[] = [];
    for (const b of this.stateListClone) {
      // @ts-ignore
      if (b.stateName.toLowerCase().indexOf(query) > -1) {
        result.push(b);
      }
    }
    return result;
  }

  districtSearch(query: string): any {
    const d = query.toLowerCase();
    const result = this.districtSelect(d);
    if (query === "") {
      this.districtList = this.districtListClone;
    } else {
      // @ts-ignore
      this.districtList = result;
    }
  }
  districtSelect(query: string): any[] {
    const result: any[] = [];
    for (const b of this.districtListClone) {
      // @ts-ignore
      if (b.name.toLowerCase().indexOf(query) > -1) {
        result.push(b);
      }
    }
    return result;
  }
  changeStatus(e, element) {
    var msg;
    if (element.isActive) {
      msg = "Are you sure wants to Deactivate status?";
    } else {
      msg = "Are you sure wants to Activate status?";
    }
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: "600px",
      height: "auto",
      data: {
        message: msg,
        type: "confirmation",
      },
      panelClass: "confirmpopup",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        this.masterDataService.userStatuChange(element.id).subscribe((res) => {
          this.data = res;
          if (this.data.isActive == 0) {
            this.snackBar.open(this.data.response, "", {
              duration: 3000,
              panelClass: ["success-snackbar"],
            });
            this.searchByState(this.page);
          }
        });
      } else {
        e.source.checked = element.isActive;
      }
    });
  }

  deleteUsers(id):any {
    if (confirm("Are you sure wants to delete this User?") == true) {
      this.masterDataService.deleteCandidate(id).subscribe((res) => {
        this.data = res;
        if (this.data.status == 0) {
          this.snackBar.open(this.data.response, "", {
            duration: 3000,
            panelClass: ["success-snackbar"],
          });
          this.searchForm.patchValue({name:''});
          this.searchByState(this.page);
        }
      });
    }
  }
}
