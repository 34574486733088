import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MasterDataService} from "../../../service/masterData/master-data.service";

@Component({
  selector: 'app-add-update-team',
  templateUrl: './add-update-team.component.html',
  styleUrls: ['./add-update-team.component.scss']
})
export class AddUpdateTeamComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  hide = true;
  id: any;
  fileData: File = null;
  formGroup: FormGroup;
  data: any;
  roleData: any;
  roleList = [];
  addedState: any;
  status: boolean;
  demo = [];
  isActive: boolean;
  emailpresent: boolean;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getAllRoleList();
    if (this.id) {
      this.initializeForm1();
    }
    else {
      this.initializeForm();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57  )) {
      return false;
    }
    return true;
  }

  getAllRoleList(){
    this.roleList =[];
      this.masterService.getAllRoleList(0, 100).subscribe( res=>{
        this.roleData = res;
       if(this.roleData.status == 0){
         //this.roleList = this.roleData.response.dataSet;
         this.roleList = this.roleData.response.dataSet.filter((d) => {
             if (d.isActive == true ) {
               return d;
             }
           });
        } else {
         this.snackBar.open('response Status -1 ', '', {
           duration: 3000,
           panelClass: ['error-snackbar']
         });
        }
      })
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      name: [ '' , Validators.required],
      email: [ '' , Validators.required],
      mobileNumber: [ '', Validators.required],
      roleId: [ '' , Validators.required],
      password: [ '', Validators.required],
    });

    if (this.id){
      this.patchUSerData();
    }
  }

  // To initialize Form
  initializeForm1() {
    this.formGroup = this.fb.group({
      name: [ '' , Validators.required],
      email: [ '' , Validators.required],
      mobileNumber: [ '', Validators.required],
      roleId: [ '' , Validators.required],
    });

    if (this.id){
      this.patchUSerData();
    }
  }

  deleteUsersDetails


  // get data from id
  patchUSerData(){
    this.masterService.getProfileDetailsById(this.id)
      .subscribe(res => {
        this.data = res;
        console.log(this.data);
        if(this.data.status == 0 ){
          this.formGroup.patchValue({
            name: this.data.response.name ,
            email: this.data.response.email ,
            mobileNumber :this.data.response.mobileNumber,
            roleId :this.data.response.roleId,
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }
  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const formData: FormData = new FormData();
      formData.append('name' , this.formGroup.value.name);
      formData.append('email' , this.formGroup.value.email);
      formData.append('mobileNumber' , this.formGroup.value.mobileNumber);
      if (this.id ) {
      }
      else {
        formData.append('password', this.formGroup.value.password);
      }
      formData.append('roleId' , this.formGroup.value.roleId);

      if (this.id){
        this.masterService.updateProfileDeatils(this.id, formData)
          .subscribe((res: any) => {
             if(res.status === 0)
             {
               this.snackBar.open('Team Details Updated Successfully', '', {
                 duration: 3000,
                 panelClass: ['success-snackbar']
               });
               this.router.navigate(['/teamList']);
             }
             else{
               this.snackBar.open(res.response, '', {
                 duration: 3000,
                 panelClass: ['error-snackbar']
               });
             }

            },
            (err) => {
              this.snackBar.open(err.error.response,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{
        const data = this.formGroup.value;
        this.masterService.createUser(formData).subscribe((res: any) => {
          console.log(res);
          if(res.status === 0)
          {
            this.snackBar.open('Team Inserted Successfully ', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/teamList']);
          }
          else {
            this.snackBar.open(res.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        },
          (err) => {
            this.snackBar.open(err.error.response, '', {
              duration: 3000,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
              panelClass: ['error-snackbar']
            });
          }
          );
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }
  get email() {
    return this.formGroup.get('email');
  }

  emailCheckUnique() {
    console.log(this.formGroup.controls.email);
    if (this.formGroup.controls.email.value.match(this.emailPattern) == null){
      this.formGroup.controls.email.setErrors({ notEquivalent: true });
    } else {
      this.formGroup.controls.email.setErrors(null);
    }
  }

}
