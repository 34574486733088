<div [ngClass]="{'main-signin-wrapper' : router.url == '/login' || router.url == '/dummyExcelSheet'}">
    <app-spinner *ngIf="!(router.url === '/login' || router.url === '/startpage' || router.url === '/home' || router.url === '/dummyExcelSheet')"></app-spinner>
    <!-- <button class="navbar-toggler collapsed btn custom-hamburger" type="button"
  data-toggle="collapse" data-target="#navbarGrey"
  aria-controls="navbarGrey" aria-label="Toggle navigation"
  [attr.aria-expanded]="!isCollapsed" *ngIf="!(router.url === '/login' || router.url === '/startpage')">
  <i *ngIf="opened == false" class="fas fa-bars" ></i>
  <i *ngIf="opened == true" class="fas fa-times" ></i>
  </button> -->
    <!-- sidebar-toggle-->
    <!-- (click)="sidenav.toggle()" -->
    <div class="app-sidebar__toggle custom-hamburger " data-toggle="sidebar" data-toggle="collapse" data-target="#navbarGrey" aria-controls="navbarGrey" aria-label="Toggle navigation" [attr.aria-expanded]="!isCollapsed" *ngIf="!(router.url === '/login' || router.url === '/startpage' || router.url === '/dummyExcelSheet')">
        <span *ngIf="opened == false" class="open-toggle">
        <img src="../assets/img/menu-outline.svg" alt="menu" class="header-icons">
      </span>
        <span *ngIf="opened == true" class="close-toggle">
      <img class="header-icons" src="../assets/img/close-outline.svg" alt="close">
      </span>
    </div>
    <!-- /sidebar-toggle-->

    <app-header *ngIf="!(router.url === '/login' || router.url === '/startpage' || router.url === '/dummyExcelSheet')"></app-header>
    <div class="wrapper custom-sidebar">
        <mat-sidenav-container>
            <!-- side ,over ,push -->
            <mat-sidenav *ngIf="!(router.url === '/login' || router.url === '/startpage' || router.url === '/dummyExcelSheet')" #sidenav mode="side" [(opened)]="opened" (opened)="events.push('open!')" (closed)="events.push('close!')">
                <!-- (newAuctionReload)="sidenav.toggle()" -->
                <app-sidebar></app-sidebar>
            </mat-sidenav>
            <mat-sidenav-content>
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
        <!-- <app-footer></app-footer> -->
    </div>
</div>