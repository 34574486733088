import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-daily-limit',
  templateUrl: './daily-limit.component.html',
  styleUrls: ['./daily-limit.component.scss']
})
export class DailyLimitComponent implements OnInit {
  hide = true;
  LangList: [];
  rewardSettingForm: FormGroup;
  data: any;
  pdata: any;
  id: any;
  submitted = false;
  status: boolean;
dailylimitres:any;
  isActive: boolean;
  companyList: any;
  companyListClone: any = [];
  page: any;
  size: any;
  addOnUpdate: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.rewardSettingForm = this.formBuilder.group({
      applicationLimit: ['', Validators.required],
    
    })
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getdailylimit();
    
  }
  submit() {
    if (this.rewardSettingForm.valid) {
      if (this.addOnUpdate) {
        let data = {
          applicationLimit: this.rewardSettingForm.value.applicationLimit,
          limitId: this.dailylimitres.limitid ,

          // pointPerAppointment: this.rewardSettingForm.value.pointPerAppointment,
          // redemptionPoint: this.rewardSettingForm.value.redemptionPoint,
          // rewardSettingId: this.rewardSettingRes?.rewardSettingId ? this.rewardSettingRes?.rewardSettingId : 0
        }
        console.log(this.rewardSettingForm.value.applicationLimit,"Value of a radio ");
        this.masterService.dailylimit(data).subscribe((res) => {
          if (res.status == 0) {
            this.snackBar.open("Update Successfully.", 'x', { duration: 2000 });
          }
          else{
            this.snackBar.open("Error while updating.", 'x', { duration: 2000 });
          }
        })
      }
      else {
        let data = {
          applicationLimit: this.rewardSettingForm.value.applicationLimit,
        limitid: 0
        }
        console.log(this.rewardSettingForm.value.applicationLimit,"Value of a radio ");
        
        this.masterService.dailylimit(data).subscribe((res) => {
          if (res.status == 0) {
            this.snackBar.open("Save Successfully.", 'x', { duration: 2000 });
          }
          else{
            this.snackBar.open("Error while saving.", 'x', { duration: 2000 });
          }
        })
      }
    }
    else{
      this.rewardSettingForm.markAllAsTouched();
      this.snackBar.open("Please Fill All Details", 'x', { duration: 2000 });
    }
  
  }
  getdailylimit() {
    this.masterService.getdailylimit().subscribe((res) => {
      console.log(res, "setting de")
      if (res['status'] == 0)  {
        this.addOnUpdate = true;
        this.dailylimitres = res['response'];
   
        console.log(this.dailylimitres.applicationLimit,"IS Reward");
        this.rewardSettingForm.patchValue({
          applicationLimit: this.dailylimitres.applicationLimit,
        })
      }
    })
  }
    // if (this.id) {
    //   this.contactData();
    // }
}

  // contactData() {
  //   this.masterService.getContactById(this.id).subscribe(
  //     (res) => {
  //       this.data = res;
  //       if (this.data.status == 0) {
  //         const data = this.data.response;
  //         this.formGroup.patchValue({
          
  //           email: this.data.response.email,
          
  //         });
 
  //       } else {
  //         this.snackBar.open('response Status -1 ', '', {
  //           duration: 3000,
  //           panelClass: ['error-snackbar'],
  //         });
  //       }
  //       console.log(this.id);
  //     },
  //     (error) => console.log(error)
  //   );
// }
 