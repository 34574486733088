import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-view-competency-keyword',
  templateUrl: './view-competency-keyword.component.html',
  styleUrls: ['./view-competency-keyword.component.scss']
})
export class ViewCompetencyKeywordComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;
  fileData: File = null;
  categoryList: any[];
  competencyCategoryList: any[];
  private roleData: any;
  competencyCategoryId: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  selectLang: any;

  constructor(private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private masterService: MasterDataService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  public show:boolean = false;
  public show1:boolean = true;


  ngOnInit(): void {
    this.competencyCategoryId = this.route.snapshot.params['id'];
    this.selectLang = this.route.snapshot.params['lng'];
    this.initializeForm();
    this.patchCompetencyKeywordData();
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      keywords: ['', Validators.required],
    });

  }

  // get data from competency keyword details by id
  patchCompetencyKeywordData() {
    this.masterService.getCompetencyKeywordDetailsByIdNew(this.competencyCategoryId, this.selectLang)
      .subscribe(res => {
        this.data = res;
        if (this.data.status == 0) {
          this.formGroup.patchValue({
            keywords: this.data.response
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }


}
