<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Discount Coupon List</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active" [routerLink]="['/edit-coupon']">Discount Coupon List</li>
        </ol>
    </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
    <div class="card">
        <div class="c_table">
            <div class="mat-elevation-z8">

                <div class="d-flex table-responsive p-3">
                    <div class="mr-2">
                        <div class="btn btn-primary btn-sm rounded-lg fs-13">
                            <span [routerLink]="['/add-coupon']">
                                <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                                Add Coupon </span>
                        </div>
                    </div>
                    <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)"
                            placeholder="Search Here">
                    </div>
                </div>
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="srno">
                        <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                        <td mat-cell *matCellDef="let element;let j = index">
                            <!-- <span *ngIf="page == 0" > {{j + 1}}</span>
                              <span *ngIf="page != 0" >{{((page * size) +   (j + 1))}}</span> -->
                            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                        </td>
                    </ng-container>

                    <div>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
                            <td mat-cell *matCellDef="let element">{{element.id}}</td>
                        </ng-container>

                        <ng-container matColumnDef="coupanCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> coupon code</th>
                            <td mat-cell *matCellDef="let element">{{element.couponCode}}</td>
                        </ng-container>
                        <ng-container matColumnDef="discountType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> discount type</th>
                            <td mat-cell *matCellDef="let element">
                                <span  *ngIf="element.discountType == true">Percentage</span>
                                <span  *ngIf="element.discountType == false">Fixed</span>
                            </td>
                       
                        </ng-container>
                        <ng-container matColumnDef="validFrom">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> valid date</th>
                            <td mat-cell *matCellDef="let element">{{element.validFrom |  date:'dd-MM-yyyy'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="validTo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> end date</th>
                            <td mat-cell *matCellDef="let element">{{element.validTo |  date:'dd-MM-yyyy'}}</td>
                        </ng-container>
                    </div>
                    <ng-container matColumnDef="isActive">
                        <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">
                            <span>
                                <mat-slide-toggle (change)="oldpermissionChange($event,element)" name="isActive"
                                    [checked]="element.isActive"></mat-slide-toggle>
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                            <div class="d-flex justify-content-center">
                                <span class="btn btn-sm btn-primary mar15"
                                    [routerLink]="['/edit-coupon', element.discountId]">
                                    <i class="fas fa-user-edit"></i></span>
                                <span class="btn btn-sm btn-danger rounded-lg" (click)="deleteCoupan(element.discountId)"><i
                                        class="fas fa-trash"></i></span>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]"
                    showFirstLastButtons></mat-paginator>
            </div>


        </div>
    </div>
</div>