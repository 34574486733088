import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { LanguageService } from 'src/app/service/language/language.service';

@Component({
  selector: 'app-add-update-contact',
  templateUrl: './add-update-contact.component.html',
  styleUrls: ['./add-update-contact.component.scss']
})
export class AddUpdateContactComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  hide = true;
  LangList: [];
  formGroup: FormGroup;
  data: any;
  pdata: any;
  id: any;
  submitted = false;
  status: boolean;

  isActive: boolean;
  companyList: any;
  companyListClone: any = [];
  page: any;
  size: any;
  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.initializeForm();
  }
  get f() {
    return this.formGroup.controls;
  }
  get t() {
    return this.f.planList as FormArray;
  }

  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      id: [0, Validators.required],
      email: ['', Validators.required],
      landline: ['', Validators.required],
      tollFreeNo: ['', Validators.required],
   
    });
    if (this.id) {
      this.contactData();
    }
  }


  contactData() {
    this.masterService.getContactById(this.id).subscribe(
      (res) => {
        this.data = res;
        if (this.data.status == 0) {
          const data = this.data.response;
          this.formGroup.patchValue({
            id: this.data.response.id,
            email: this.data.response.email,
            landline: this.data.response.landline,
            tollFreeNo: this.data.response.tollFreeNo,
          });
 
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        }
        console.log(this.id);
      },
      (error) => console.log(error)
    );
  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

  onSubmit(): any {
    this.submitted = true;
    if (this.formGroup.valid) {     
        this.masterService
          .addUpdateConatct(this.formGroup.value)
          .subscribe((res) => {
            this.data = res;
            if (this.data.status == 0) {
              this.snackBar.open('Contact us  Created  Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar'],
              });
              this.router.navigate(['/contact-us']);
            } else {
              this.snackBar.open(this.data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar'],
              });
            }
          });
    
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }
}
