import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';


@Component({
  selector: 'app-list-all-city-data',
  templateUrl: './list-all-city-data.component.html',
  styleUrls: ['./list-all-city-data.component.scss']
})
export class ListAllCityDataComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject;
  districtId: any;
  formGroup: FormGroup;
dataSource = new MatTableDataSource;
  page: number;
  size: number;
  displayedColumns: string[];
  private data: any;
  @ViewChild(MatSort, {static:true}) sort:MatSort;
  @ViewChild(MatPaginator, {static:true}) paginator:MatPaginator;
  totalrecordLength: any;
  totalpageLenght: any;
  searchText: String|null='';
  searchControl = new FormControl();

  constructor(
    private fb: FormBuilder,
    private masterDataService:MasterDataService,
  ) {   this.searchControl.valueChanges.pipe(
    debounceTime(400), // Adjust the debounce time as needed
    distinctUntilChanged()
  ).subscribe(value => {
    this.applySearchFilter(value);

});}

  ngOnInit(): void {
    this.page = 0;
    this.size = 10; 
    this.displayedColumns = ['srno','countryName','stateName','districtName','cityName', 'actions'];
    this.tableLoad("",this.page);
  }

  
//Add by vivek
  //To initialize Form
ngOnDestroy(): void {
  this.destroy$.next();
  this.destroy$.complete();
}

tableLoad(applySearchFilter, page){
  this.masterDataService.getAllManageCity(applySearchFilter,page,this.size).pipe(takeUntil(this.destroy$)).subscribe(res =>{
    this.data = res;
    if(this.data.status == 0 ){
      this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
      this.dataSource = new MatTableDataSource(this.data.response.dataSet);
      //this.dataSource.sort = this.sort;
      //this.dataSource.paginator = this.paginator;
 }
    
  })
}



  //To Submit Form Data
  applyFilter(filterValue:String){
    this.dataSource.filter = filterValue.trim().toLowerCase();
     }

     applySearchFilter(searchText:String){
      this.searchText = searchText;
      this.page = 0;
      this.size = 10; 
      this.tableLoad(searchText,this.page);
       }
 
     firstSpaceRemoveValid(e){
       if(e.which === 32 && e.target.selectionStart === 0)
         return false;
     }
     onChangePage(e) {
      this.page = e.pageIndex;
      this.size = e.pageSize;
      this.tableLoad(this.searchText,e.pageIndex);
    }
}

