<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!degreeId">Add </span>
                <span *ngIf="degreeId">Edit </span>Education
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/listEducation']">Education List</li>
                <li class="breadcrumb-item active"><span *ngIf="!degreeId">Add </span><span *ngIf="degreeId">Edit </span> Education </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="dynamicForm">
                <div class="row">
                    <div class="form-group row col-sm-6">

                        <label class="col-sm-12">Education Name </label>
                        <div class="col-sm-12 ">
                            <input type="text" class="form-control" value="" placeholder="Enter Education Name " name="degreeName" formControlName="degreeName" required>
                            <mat-error>
                                <span *ngIf="!dynamicForm.get('degreeName').valid && dynamicForm.get('degreeName').touched">
                                    Please Enter Education Name</span>
                            </mat-error>
                        </div>

                    </div>
                    
                    <div class="col-md-4">
                        <div class="form-group mb-4">
                            <label class="text5 text-color-dark mb-2">Marital Status<span class="astrisk-red">*</span></label>
                            <mat-select placeholder="Select" class="form-control mb-1" [ngClass]="{ 'is-invalid': submitted && f.levelOfEducation.errors }" formControlName="levelOfEducation" required>
                                <mat-option *ngFor="let item of levelOfEducationList" [value]="item">{{item}}</mat-option>
                            </mat-select>
                            <mat-error>
                                <div *ngIf="(submitted && f.levelOfEducation.errors) || !f.levelOfEducation.valid && f.levelOfEducation.touched">
                                    Please Select Marital Status</div>
                            </mat-error>
                        </div>
                    </div>

                </div>

                <div class="">
                    <button type="mit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()" *ngIf="!degreeId">Submit</button>
                    <button type="mit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()" *ngIf="degreeId">Update</button>
                </div>
            </form>
        </div>
    </div>
</div>