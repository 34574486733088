import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import { LanguageService } from 'src/app/service/language/language.service';
import { DynamicLabelClass } from 'src/app/helper/userLevelLink';

@Component({
  selector: 'app-add-update-industry',
  templateUrl: './add-update-industry.component.html',
  styleUrls: ['./add-update-industry.component.scss']
})
export class AddUpdateIndustryComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  jobIndustryId: any;
  fileData: File = null;
  LangList=[];
  config: any;

  constructor(private snackBar: MatSnackBar,
    public langService :LanguageService,
              private fb: FormBuilder,
              private formBuilder: FormBuilder,
              private masterService :MasterDataService,
              private route: ActivatedRoute,
              private router: Router) { }

  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  dynamicForm: FormGroup;

  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.jobIndustryId = this.route.snapshot.params['id'];
    console.log(this.jobIndustryId);

    this.initializeForm();
  }


  // To initialize Form
  initializeForm() {
    this.dynamicForm = this.fb.group({
      multiLanguageNames: new FormArray([]),
    });

    if (this.jobIndustryId){
      this.patchIndustryData();
    }
    this.LangList = JSON.parse(this.langService.getLngList());
    console.log(this.LangList);
  this.LangListLoad(this.LangList.length);
  }
  // get f() { return this.dynamicForm.controls; }
  get t() { return  this.dynamicForm.controls.multiLanguageNames as FormArray; }

     LangListLoad(e) {
      const numberOfTickets = e
      if (this.t.length < numberOfTickets) {
          for (let i = this.t.length; i < numberOfTickets; i++) {
              this.t.push(this.formBuilder.group({
                languageId:[this.LangList[i].languageId],
                name: [ '' , Validators.required],
                descriptionName: [ '' , Validators.required],
              }));

          }
          // this.dynamicForm.patchValue(this.payloaddd);
      } else {
          for (let i = this.t.length; i >= numberOfTickets; i--) {
              this.t.removeAt(i);
          }
      }
  }

  // get data from jobCategoryId
  patchIndustryData(){
    this.masterService.getJobIndustryByid(this.jobIndustryId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.dynamicForm.patchValue(
            this.data.response
          );

          this.dynamicForm.patchValue({
            multiLanguageNames :this.data.response.multiLanguageNames
          })


        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }

  onSubmit() {
    if (this.dynamicForm.valid) {
      if (this.jobIndustryId){
        this.masterService.updateJobIndustryDetails(this.jobIndustryId, this.dynamicForm.value)
            .subscribe((data:any) => {
              if(data.status == 0)
              {
                this.snackBar.open('Job Industry Details Update Successfully !!', '', {
                  duration: 3000,
                  panelClass: ['success-snackbar']
                });
                this.router.navigate(['/listIndusrty']);
              }
              else {
                this.snackBar.open(data.response, '', {
                  duration: 3000,
                  panelClass: ['error-snackbar']
                });
              }

            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else{
        this.masterService.createJobIndustry(this.dynamicForm.value).subscribe((data:any) => {
          if(data.status == 0) {
            this.snackBar.open('Job Industry Inserted Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listIndusrty']);
          }
          else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }
}
