<div class="">
  <div class="col-sm-12 pl-0 pr-0 ml-0 mr-0">
    <div class="col-lg-3 d-block mx-auto">
      <div class="card ">
        <div class="card-body">
          <div class="">
            <div class=""><div class="logo logo-admin"><img src="assets/img/logo2.png" 
                alt="logo" width="200" class="auth-logo"></div></div>
                <div>
                  <h4>Please sign in to continue</h4>
                </div>
            <div id = "error" *ngIf="retryError">The Username or password was not correct, please try again</div>
            <form class="text-left mt-3" [formGroup]="firstFormGroup" >             
              <div class="form-group text-left row">
                <label class="col-sm-12">User Name </label>
                <div class="col-sm-12">
                 
                    <!-- <mat-label>Enter Your Username</mat-label> -->
                    <input matInput type="text"  value="" 
                    placeholder="Enter User Name" 
                    class="form-control"
                    formControlName="username" required>
                    <mat-error>
                      <span *ngIf="!firstFormGroup.get('username').valid && firstFormGroup.get('username').touched">
                                  Please enter username</span>
              </mat-error>
                    

                 
                </div>
              </div>
              <div class="form-group row password_fl ">
                <label class="col-sm-12 ">Password</label>
                <div class="col-sm-12 ">
                  <!-- <mat-form-field  appearance="outline"> -->
                    <!-- <mat-label>Enter Your Password</mat-label> -->
                    <input class="form-control"  matInput placeholder="Enter your password"
                     [type]="hide ? 'password' : 'text'" formControlName="password" required>
                    <button class="eyeIcon btn" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error>
                      <span *ngIf="!firstFormGroup.get('password').valid && firstFormGroup.get('password').touched">
                                  Please enter Password</span>
              </mat-error>
                  <!-- </mat-form-field> -->
                </div>
              </div>
              <!-- <div class="form-group row mt-4">
                <!- <div class="col-sm-6">
                  <mat-checkbox>Remember me</mat-checkbox>
                </div> ->

                <div class="col-sm-6 text-right"><div (click)="forgotpassword()" class="font-13"><i class="fas fa-lock"></i> Forgot password?</div></div>

              </div> -->
              <button type="submit" class="btn btn-main-primary btn-block" (click)="onSubmit()" >Login <i class="fas fa-sign-in-alt ml-1"></i></button>
            </form>
            <!--end form-->
          </div>

        </div>
        <!--end card-body-->
      </div>
      <!--end card-->

    </div>
    <!--end auth-page-->
  </div>
  <!--end col-->
</div>
