import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MasterDataService } from '../../../service/masterData/master-data.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonConfirmPopupComponent } from 'src/app/component/common-confirm-popup/common-confirm-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList = [];
  prdlist = [];
  private data: any;
  seachcatogry: FormGroup;
  searchForm: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  stateList: any = [];
  stateListClone: any = [];
  districtList: any = [];
  districtListClone: any = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private masterDataservice: MasterDataService
  ) {
    this.appCtrl = new FormControl();
  }
  ngOnInit(): void {
    this.getAllState();
    this.getAllDistrict();
    this.page = 0;
    this.size = 100;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.searchForm = this.formBuilder.group({
      state: [''],
      name: [''],
      companyName:[''],
      MobileNo:[""],
      emailId:[""],
      source:[""],
      district: [""],
      downloadFile: ['CSV']
    });
    this.displayedColumns = [
      'srno',
      'name',
      'companyName',
      'email',
      'source',
      'mobileNumber',
      "district",
      'stateName',
      'jobRole',
      'createddate',
      'status',
      'actions',
    ];
    this.tabelLoad(this.page);
  }

  resetForm():any{
    this.searchForm.patchValue({
      state: '',
      name: '',
      companyName: '',
      MobileNo: '',
      emailId: '',
      source: '',
      district: '',
    })
    this.tabelLoad(this.page);
  }

  onChangePage(e){
    console.log(e);
    this.page =e.pageIndex;
    this.tabelLoad(e.pageIndex);
  }

  tabelLoad(page): any {
    this.masterDataservice
      .getAllUserList1(page, this.size, this.searchForm.value.state,this.searchForm.value.name,this.searchForm.value.MobileNo,
        this.searchForm.value.emailId,this.searchForm.value.source,this.searchForm.value.district, this.searchForm.value.companyName)
      .subscribe((res) => {
        this.data = res;
        if (this.data.status === 0) {
          const dd = this.data.response.dataSet.filter((d) => {
            if (d.userRoleType === 'EMPLOYER') {
              return d;
            }
          });
        
          this.totalrecordLength = this.data.response.totalElements;
          this.page = this.data.response.number;
          this.totalpageLenght = this.data.response.totalElements;
          this.dataSource = new MatTableDataSource(this.data.response.dataSet);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.page;
            this.paginator.length = res['response']?.totalElements;
          }); 
        }
      });
  }

  applyFilter(filterValue: string): any {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  delete(rowid: number): any {
    if (rowid > -1) {
      this.dataSource.data.splice(rowid, 1);
      this.dataSource._updateChangeSubscription();
    }
  }

  downloadFile():any{
    window.location.href = `${environment.baseurl1}/recruiter/exportEmployerListExcel?extension=`+this.searchForm.value.downloadFile;
  }

  oldpermissionChange(e, element): any {
    console.log(element.id);
    if (element.isActive === true) {
      if (confirm('deactive Are you sure')) {
        this.masterDataservice.teamStatuChange(element.id).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(this.page);
            this.snackBar.open('User status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('active Are you sure')) {
        this.masterDataservice.teamStatuChange(element.id).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          } else {
            e.source.checked = true;
            this.tabelLoad(this.page);
            this.snackBar.open('User status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
          }
        });
      } else {
        e.source.checked = false;
      }
    }
  }
  filterApps(name: any): any {
    const filterValue = name.toLowerCase();
    if (name === '') {
      return this.apps;
    } else {
      return this.apps.filter((p) =>
        p.name.toLowerCase().includes(filterValue)
      );
    }
  }
  onEnter(e: any): any {
    this.selectproduct = e.name;
    this.selectedId = e.id;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search(): any {
    this.router.navigate(['editTeam', this.selectedId]);
  }
  keyDownFunction(event): any {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage(): any {
    this.tabelLoad(0);
  }
  lastpage(): any {
    this.tabelLoad(this.totalpageLenght);
  }
  prevpage(): any {
    const d = this.page - 1;
    if (d > 0) {
      this.tabelLoad(d);
    }
  }
  nextpage(): any {
    const d = this.page + 1;
    if (d < this.totalpageLenght) {
      this.tabelLoad(d);
    }
  }

  // deleteUsers(id): any {
  //   if (confirm('are you you want to delete????')) {
  //     this.masterDataservice.deleteUsersDetails(id).subscribe(
  //       (res) => {
  //         this.data = res;
  //         if (this.data.status === 0) {
  //           this.tabelLoad(this.page);
  //           this.snackBar.open('deleted succesfully!!!', '', {
  //             duration: 3000,
  //             panelClass: ['success-snackbar'],
  //           });
  //         } else {
  //           this.snackBar.open('response Status -1 ', '', {
  //             duration: 3000,
  //             panelClass: ['error-snackbar'],
  //           });
  //         }
  //       },
  //       (error) => console.log(error)
  //     );
  //   } else {
  //     // alert('sd');
  //   }
  // }
  ngAfterViewInit(): any {
    this.dataSource.sort = this.sort;
  }
  searchByState(): any {}
  getAllDistrict(): any {
    this.masterDataservice.getAllDistrict().subscribe((data: any) => {
      if (data.status === 0) {
        this.districtList = data.response;
        this.districtListClone = this.districtList;
      }
    });
  }
  getAllState(): any {
    this.masterDataservice.getAllStateByCountry().subscribe((data: any) => {
      if (data.status === 0) {
        this.stateList = data.response;
        this.stateListClone = this.stateList;
      }
    });
  }
  stateSearch(query: string): any {
    const d = query.toLowerCase();
    const result = this.stateSelect(d);
    if (query === '') {
      this.stateList = this.stateListClone;
    } else {
      // @ts-ignore
      this.stateList = result;
    }
  }
  stateSelect(query: string): any[] {
    const result: any[] = [];
    for (const b of this.stateListClone) {
      // @ts-ignore
      if (b.stateName.toLowerCase().indexOf(query) > -1) {
        result.push(b);
      }
    }
    // var arr = this.advancedform.value.cityId;
    // if (arr.length) {
    //   arr.forEach((ele: any) => {
    //     this.cityListClone.forEach((obj: any) => {
    //       if (ele == obj.cityId) {
    //         result.push(obj);
    //       }
    //     });
    //   });
    // }
    return result;
  }
  districtSearch(query: string): any {
    const d = query.toLowerCase();
    const result = this.districtSelect(d);
    if (query === "") {
      this.districtList = this.districtListClone;
    } else {
      // @ts-ignore
      this.districtList = result;
    }
  }
  districtSelect(query: string): any[] {
    const result: any[] = [];
    for (const b of this.districtListClone) {
      // @ts-ignore
      if (b.name.toLowerCase().indexOf(query) > -1) {
        result.push(b);
      }
    }
    return result;
  }
  changeStatus(e, element): any {
    let msg;
    if (element.isActive) {
      msg = 'Are you sure wants to Deactivate status?';
    } else {
      msg = 'Are you sure wants to Activate status?';
    }
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: msg,
        type: 'confirmation',
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        this.masterDataservice
          .userStatuChange(element.id)
          .subscribe((res) => {
            this.data = res;
            if (this.data.isActive === 0) {
              this.snackBar.open(this.data.response, '', {
                duration: 3000,
                panelClass: ['success-snackbar'],
              });
              this.tabelLoad(this.page);
            }
          });
      } else {
        e.source.checked = element.isActive;
      }
    });
  }
  deleteUsers(id):any {
    if (confirm("Are you sure wants to delete this User?") == true) {
      this.masterDataservice.deleteRecruiter(id).subscribe((res) => {
        this.data = res;
        if (this.data.status == 0) {
          this.snackBar.open(this.data.response, "", {
            duration: 3000,
            panelClass: ["success-snackbar"],
          });
          this.searchForm.patchValue({name:''});
          this.tabelLoad(this.page);
        }
      });
    }
  }
}
