<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!discountId">Add </span>
                <span *ngIf="discountId">Edit </span> Discount Coupon
            </h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/coupon-list']">Manage Coupon List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!discountId">Add </span>
                    <span *ngIf="discountId">Edit </span> Coupon
                </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">Coupon Code </label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="Enter Coupon Code " name="couponCode" formControlName="couponCode" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('couponCode').valid && formGroup.get('couponCode').touched">
                                    Please Enter Coupon Code</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">No.Of Times Used By Same User </label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="Enter No.Of Times Used By Same User" name="couponPerUser"
                                formControlName="couponPerUser" required>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('couponPerUser').valid && formGroup.get('couponPerUser').touched">
                                    Please Enter No.Of Times Used By Same User</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Coupon Type </label>
                        <div class="col-sm-12 ">
                            <mat-select type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control"
                                 placeholder="Select Coupon Type " name="discountType"
                                formControlName="discountType" required>
                                <mat-option value="true">Percentage</mat-option>
                                <mat-option value="false">Fixed</mat-option>
                            </mat-select>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('discountType').valid && formGroup.get('discountType').touched">
                                    Please Select Coupon Type </span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Coupon Value </label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="Enter Coupon Value" name="discountValue" formControlName="discountValue"
                                required>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('discountValue').valid && formGroup.get('discountValue').touched">
                                    Please Enter Coupon Value</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Start Date </label>
                        <div class="col-sm-12 ">
                            <input type="date" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="dd/mm/yyyy" name="validFrom" formControlName="validFrom" required>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('validFrom').valid && formGroup.get('validFrom').touched">
                                    Please Enter Start Date</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Expiry Date </label>
                        <div class="col-sm-12 ">
                            <input type="date" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="dd/mm/yyyy" name="validTo"   formControlName="validTo" required>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('validTo').valid && formGroup.get('validTo').touched">
                                    Please Enter Expiry Date</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Used Coupons </label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value=""
                                placeholder="Enter Used Coupons" name="usedCoupon" formControlName="usedCoupon"
                                required>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('usedCoupon').valid && formGroup.get('usedCoupon').touched">
                                    Please Enter Used Coupons</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Discount valid for </label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="discountValidFor" >
                                <mat-radio-button value="recruiter" >Recruiter
                                </mat-radio-button>
                                <mat-radio-button value="candidate" >Candidate
                                </mat-radio-button>
                                <mat-radio-button value="both">Both
                                </mat-radio-button>

                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="form-group row col-sm-12">
                        <label class="col-sm-12"> Coupon Description </label>
                        <div class="col-sm-12 ">
                            <textarea type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control"
                                value="" placeholder="Enter Coupon Description" name="couponDesc"
                                formControlName="couponDesc" required></textarea>
                            <mat-error>
                                <span
                                    *ngIf="!formGroup.get('couponDesc').valid && formGroup.get('couponDesc').touched">
                                    Please Enter Coupon Description</span>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="">
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                        *ngIf="!discountId">SUBMIT</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
                        *ngIf="discountId">UPDATE</button>
                </div>
            </form>
        </div>
    </div>
</div>