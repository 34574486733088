import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-list-all-district-data',
  templateUrl: './list-all-district-data.component.html',
  styleUrls: ['./list-all-district-data.component.scss']
})
export class ListAllDistrictDataComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject;
  private debounceTimeInMs = 10000 //2 Seconds
  districtId: any;
  formGroup: FormGroup;
  dataSource = new MatTableDataSource;
  page: number;
  size: number;
  displayedColumns: string[];
  private data: any;
  @ViewChild(MatSort, {static:true}) sort:MatSort;
  @ViewChild(MatPaginator, {static:true}) paginator:MatPaginator;
  totalrecordLength: any;
  totalpageLenght: any;
  searchControl = new FormControl();
  districtName:String|null='';
  constructor(
    private fb: FormBuilder,
    private masterDataService:MasterDataService,
  ) { 

    this.searchControl.valueChanges.pipe(
      debounceTime(400), // Adjust the debounce time as needed
      distinctUntilChanged()
    ).subscribe(value => {
      this.applySearchFilter(value);

  });
}

  ngOnInit(): void {
    this.page = 0;
    this.size = 10; 
    this.displayedColumns = ['srno','countryName','stateName','districtName','stateCode', 'actions'];
    this.tableLoad("",this.page);
  }

  
//Add by vivek
  //To initialize Form
ngOnDestroy(): void {
  this.destroy$.next();
  this.destroy$.complete();
}


tableLoad(applySearchFilter, page){
  this.masterDataService.getAllManageDistrict(applySearchFilter,this.page,this.size).pipe(takeUntil(this.destroy$)).subscribe(res =>{
    this.data = res;
    if(this.data.status == 0 ){
      this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
      this.dataSource = new MatTableDataSource(this.data.response.dataSet);
      this.dataSource.sort = this.sort;
      //this.dataSource.paginator = this.paginator;
 }
    
  })
}


  //To Submit Form Data
  applyFilter(filterValue:String){
    this.dataSource.filter = filterValue.trim().toLowerCase();
     }

     applySearchFilter(districtName:String){
          this.districtName = districtName;
          this.page = 0;
          this.size = 10; 
          this.tableLoad(districtName,this.page);
       }
 
     firstSpaceRemoveValid(e){
       if(e.which === 32 && e.target.selectionStart === 0)
         return false;
     }
     onChangePage(e) {
      this.page = e.pageIndex;
      this.size = e.pageSize;
      this.tableLoad(this.districtName,this.page);
    }
  }

