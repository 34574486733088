import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import{TranslationService} from 'src/app/service/translation/translation.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-translation',
  templateUrl: './add-translation.component.html',
  styleUrls: ['./add-translation.component.scss']
})
export class AddTranslationComponent implements OnInit {
  formGroup:FormGroup;
  formGroupJson:FormGroup;
  sectionList:any;
  submitted:boolean=false;
  data:any;
  pageNameList:any = [];
  engLangJson:any;
  otherLangJson:any;
  sectionJson:any;
  constructor(private fb: FormBuilder,
    public translationService:TranslationService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.initializeForm();
    this.sectionList = ['COMMON','RECRUITER','CANDIDATE']
  }
  initializeForm(): any {
    this.formGroup = this.fb.group({
      section: [ '' , Validators.required],
      environment: [ '' , Validators.required],
      pageName:['', Validators.required],
      keyName: ['', Validators.required],
      engLang:['', Validators.required],
      spanLang:[''],
      polLang:[''],
    });
    this.formGroupJson = this.fb.group({
      section: [ ''],
      engLang:[''],
      otherLang:[''],
    });
  }
  onSubmit():any{
    this.submitted = true;
    if(this.formGroup.valid){
      var payload ={
        "appName": this.formGroup.value.section,
        "keyName": this.formGroup.value.keyName,
        "languageMangeId": 0,
        "languageNameDetails": [
          {
            "languageId": 14,
            "value": this.formGroup.value.engLang
          },
          {
            "languageId": 15,
            "value": this.formGroup.value.spanLang
          },
          {
            "languageId": 16,
            "value": this.formGroup.value.polLang
          }
        ],
        "pageName": this.formGroup.value.pageName,
        "useLocation": this.formGroup.value.environment
    }
    this.translationService.addLanguage(payload).subscribe( res =>{
      this.data = res;
      if(this.data.status == 0 ){
        this.snackBar.open(this.data.response, '', {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
      }
      else {
        this.snackBar.open(this.data.status, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    })
    }
  }
  pageNameSearch(value):any{
    if(value){
      this.getpageNameSuggestions(value);
    }
  }
  getpageNameSuggestions(value):any{
    this.translationService.getPageName(value).subscribe( (res: any) => {
      if (res.status == 0){
        this.pageNameList = res.response;
      }
    });
  }
  convertIntoExcel():any{
    var engLang = JSON.parse(this.formGroupJson.value.engLang);
    const keysOfMap = Object.keys(engLang);
    const kevalu = Object.values(engLang);
    const arrayOfArrays: any[] = [];
    for (const keyOfMap in keysOfMap){
      const innerLoop= kevalu[keyOfMap];
      const inerLoopKey = Object.keys(innerLoop);
      const innerLoopValue = Object.values(innerLoop);
      for (let keyv in inerLoopKey){
        arrayOfArrays.push({Section: this.formGroupJson.value.section , pagename:keysOfMap[keyOfMap] ,  keyName: inerLoopKey[keyv] , defaultEnglish: innerLoopValue[keyv] , languageValue: innerLoopValue[keyv] })
      }
    } 
    if(this.formGroupJson.value.otherLang){
      var otherLang = JSON.parse(this.formGroupJson.value.otherLang);
      const keysOfMap2 = Object.keys(otherLang);
      const kevalu2 = Object.values(otherLang);
      const arrayOfArraysOtherLang :any =[];
      for (const keyOfMap2 in keysOfMap2){
        const innerLoop2= kevalu2[keyOfMap2];
        const inerLoopKey2 = Object.keys(innerLoop2);
        const innerLoopValue2 = Object.values(innerLoop2);
        for (let keyv2 in inerLoopKey2){
          arrayOfArraysOtherLang.push({keyName: inerLoopKey2[keyv2] ,languageValue: innerLoopValue2[keyv2]});
        }
      } 
      arrayOfArrays.forEach(element => {
        arrayOfArraysOtherLang.forEach(obj => {
          if(element.keyName == obj.keyName){
            element.languageValue = obj.languageValue;
          }
        });
      });
    }
    const fileName = 'translationFile.xlsx';
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(arrayOfArrays);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Language');
		XLSX.writeFile(wb, fileName);
  }
}
