import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {map, startWith} from "rxjs/operators";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LanguageService } from 'src/app/service/language/language.service';

@Component({
  selector: 'app-list-question-type',
  templateUrl: './list-question-type.component.html',
  styleUrls: ['./list-question-type.component.scss']
})
export class ListQuestionTypeComponent implements OnInit {

  dataSource = new MatTableDataSource();
  competencyList = [];
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  page: number;
  size: number;
  displayedColumns: string [];
  totalpageLenght: number;
  totalrecordLength: number;
  private data: any;
  filteredApps: Observable<any[]>;
  apps = []
  LangList =[];
  selectLang: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  
  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              public langService :LanguageService,
              private masterDataservice: MasterDataService)
  { this.appCtrl = new FormControl();}

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.LangList = JSON.parse(this.langService.getLngList());  
    this.selectLang = this.LangList[0].languageCode;
    this.displayedColumns = ['srno', 'questionType', 'typeDescription' ,'isActive', 'actions'];
    this.tabelLoad(this.page);
  }

  tabelLoad(page) {
    this.masterDataservice.getAllQuestionTypeList(page,this.size).subscribe(res=>{
      this.data = res;
      if( this.data.status == 0 ){
        this.totalrecordLength = 0;
        this.page = 0;
        this.totalpageLenght = 0;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.apps = this.data.response.dataSet;
        this.filteredApps = this.appCtrl.valueChanges .pipe(
          startWith(''),
          map(app => app ? this.filterApps(app) : this.apps.slice())
        );
      }
    });

  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.competencyCategoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }

  deleteQuestionTypeDetails(questionTypeId) {
    if (confirm('Are you sure ?')) {
    this.masterDataservice.deleteQuestionType(questionTypeId)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
          this.tabelLoad(this.page);
          this.snackBar.open('Question type deleted succesfully!!!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  oldpermissionChange (e , element) {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
        this.masterDataservice.questionStatuChange(element.questionTypeId).subscribe((data) => {
          // @ts-ignore
          if (data == 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(this.page);
            this.snackBar.open('Question type status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {
        this.masterDataservice.questionStatuChange(element.questionTypeId).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });

          } else {
            e.source.checked = true;
            this.tabelLoad(this.page);
            this.snackBar.open('Question type status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = false;
      }
    }

  }
}
