<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
      <h3 class="page-title"><span  *ngIf="!competencyCategoryId">Add </span>
        <span  *ngIf="competencyCategoryId">Edit </span> Competency Keywords</h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listCompetencyCategory']" > List Competency Keywords</li>
        <li class="breadcrumb-item active" >
          <span  *ngIf="!competencyCategoryId">Add </span>
          <span  *ngIf="competencyCategoryId">Edit </span>
          Competency Keywords </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="form-group row col-sm-12">
          <label class="col-sm-12">Competency Category</label>
          <div class="col-sm-12">
            <mat-form-field class="custom_select">
              <mat-select formControlName="cvaCompetencyCategoryId" required
                          class="form-control" >
                <mat-option *ngFor="let r of competencyCategoryList"
                            [value]="r.competencyCategoryId" [aria-label]="r.competencyCategoryId">
                  {{r.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-12">
          <div [formGroup]="ticket" class="col-sm-12" >
            <div class="form-group row ">
              <label class="col-sm-12">
                {{LangList[i].languageName}} Competency Keyword </label>
              <div class="col-sm-12 ">
                <input  type="text"
                        class="form-control"
                        (keydown)="firstSpaceRemoveValid($event)"
                        value="" placeholder="Enter Competency Keyword Name" name="name"
                        formControlName="name" required>
              </div>
            </div>
            <div class="form-group row ">
              <label class="col-sm-12">
                {{LangList[i].languageName}} Competency Keyword Description </label>
              <div class="col-sm-12 ">
                <angular-editor
                  formControlName="descriptionName" required  (keydown)="firstSpaceRemoveValid($event)"
                  placeholder="Enter Competency Keyword Description"
                  name="descriptionName"></angular-editor>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-success" >Submit</button>
      </form>




    </div>
  </div>

</div>
