import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/service/language/language.service';
import { JobTestQuestionManageService} from 'src/app/service/jobPostTestandQuestion/job-test-question-manage.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageQuestionPopupComponent } from '../image-question-popup/image-question-popup.component';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { QuestionImportpopupComponent } from '../question-importpopup/question-importpopup.component';

@Component({
  selector: 'app-list-job-post-question',
  templateUrl: './list-job-post-question.component.html',
  styleUrls: ['./list-job-post-question.component.scss']
})
export class ListJobPostQuestionComponent implements OnInit {

  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number = 0;
  size: number = 30;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId:any;
  LangList=[];
  selectLang: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('attachments1') myInputVariable: ElementRef;
  fileData: File = null;
  countryFlagImagePath: any;
  BData: any;
  selectQuestionGroup: any = '';
  qustGropList: Array<any> = [];
  selectedAreas: Array<any> = [];
  searchQuestionText: any = '';

  constructor(private router: Router,
              private snackBar: MatSnackBar,
              public langService: LanguageService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              private masterDataservice: MasterDataService,
              private jobpostQuestionTestService: JobTestQuestionManageService) { this.appCtrl = new FormControl(); }

  ngOnInit(): void {
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    this.displayedColumns = ['assesmentQuestionId', 'groupName', 'question','questionLevel','addedBy', 'isActive','createdEpochTime','actions'];
    this.QuestiongroupList(0,500 , this.selectLang);
    // this.tabelLoad(this.page, this.selectLang);
  }
  ngAfterViewInit(){  
    this.tabelLoad(this.page , this.selectLang);
  }

  tabelLoad(page, lang){
    // window.scrollTo(0,0);
    this.jobpostQuestionTestService.getAlljobpostQuestionAdvancfilter(page, this.size, lang , this.selectQuestionGroup , this.searchQuestionText).subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        window.scrollTo(0,0);
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
      //   this.apps = this.data.response.dataSet;
      //   this.filteredApps = this.appCtrl.valueChanges .pipe(
      //     startWith(''),
      //     map(app => app ? this.filterApps(app) : this.apps.slice())
      //   );
      // } else {
      //   this.snackBar.open('server status -1', '', {
      //     duration: 3000,
      //     panelClass: ['error-snackbar']
      //   });
      }
    })


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // delete(rowid: number) {
  //   if (rowid > -1) {
  //     this.dataSource.data.splice(rowid, 1);
  //     this.dataSource._updateChangeSubscription();
  //   }
  // }
  oldpermissionChange (e , element) {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
        this.jobpostQuestionTestService.QuestionStatusChnage(element.assesmentQuestionId).subscribe((data) => {
          // @ts-ignore
          if (data == 'error') {
            e.source.checked = true;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            e.source.checked = false;
            this.tabelLoad(this.page, this.selectLang);
            this.snackBar.open('status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {
        this.jobpostQuestionTestService.QuestionStatusChnage(element.assesmentQuestionId).subscribe((data) => {
          // @ts-ignore
          if (data === 'error') {
            e.source.checked = false;
            this.snackBar.open('Server Error happened please try later.', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });

          } else {
            e.source.checked = true;
            this.tabelLoad(this.page, this.selectLang);
            this.snackBar.open('status changed succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

          }
        });
      } else {
        e.source.checked = false;
      }
    }

  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.groupName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any) {
    // console.log(e);
    this.selectproduct = e.groupName;
    this.selectedId = e.assesmentQuestionId;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  // search(){

  //   this.router.navigate(['editIndusrty',this.selectedId]);

  // }
  // keyDownFunction(event) {
  //   if (event.keyCode === 13) {
  //     this.search();
  //   }
  // }

  firstpage(){
    this.tabelLoad(0 , this.selectLang);
  }
  lastpage(){
    this.tabelLoad(this.totalpageLenght, this.selectLang);
  }
  prevpage(){
    const d = this.page - 1;
    if (d > 0){
      this.tabelLoad(d, this.selectLang);
    }
  }
  nextpage(){
    const d = this.page + 1;
    if ( d < this.totalpageLenght){
      this.tabelLoad(d , this.selectLang);
    }
  }
  onChangePage(e){
    console.log(e);
    this.page =e.pageIndex;
    this.tabelLoad(e.pageIndex , this.selectLang);
    // this.tabelLoad(e.pageIndex , e.pageSize , this.selectLang);
  }

  LangChange(e){
    this.selectLang =e.value;
    this.QuestiongroupList(0, 30 , this.selectLang) ;
    // this.tabelLoad(0,this.selectLang);
  }
  
  bulkUpload(){
    if(this.fileData)
      {
        const formData: FormData = new FormData();
        formData.append('file' , this.fileData);
        formData.append('isForceAddNewlanguage' , 'false');        
        this.jobpostQuestionTestService.bulkUploadJobPostQuestion(formData).subscribe(res=>{
          this.BData =res;
          if(this.BData.status == 0){
            this.snackBar.open('File Successfuly uploaded.', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.tabelLoad(this.page, this.selectLang);
          } else {
            this.snackBar.open(this.BData.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        },(error)=>{
          this.snackBar.open(error.error.message, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        });
        
      }
    // jobCategoryBulkUpload
  }

  fileSelect(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      this.bulkUpload();
      // tslint:disable-next-line: variable-name
      /*reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };*/
    }
    // this.newimgeuplod = true;
    console.log(this.fileData);
  }

  imageQuestionPreview(element): any{
    const dialogRef = this.dialog.open(ImageQuestionPopupComponent, {
      width: '800px',
      height: 'auto',
      data: {
        question: element.question,
        qImage: element.questionImage,
      },
      panelClass: 'defaultClass',
    });
    dialogRef.afterClosed().subscribe(sresult => {
      // console.log(sresult);
      if (sresult == true){
      }
    });
  }

  deleteQuestionbyId(Id){
    if(confirm("Are you sure you want to delete Question ?")){
      this.jobpostQuestionTestService.deleteQuestionById(Id).subscribe( (res: any) =>{      
        if(res.status == 0  ){
          this.snackBar.open('Delete successfully ', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.tabelLoad(0, this.selectLang);
        } else {
          this.snackBar.open(res.response, '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        }
      }, (error:any) =>{
        this.snackBar.open(error.error.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      })
    }
    }

    QuestiongroupList(page,size , selectLang) {
      this.masterDataservice.getAllQuestionLevelList(page,size ,selectLang).subscribe(res=>{
        this.data = res;
        if( this.data.status == 0 ){
          this.qustGropList = this.data.response.dataSet;
          this.selectedAreas = this.qustGropList;
        }
      });
  
    }
    search(query: string): any{
      const result = this.select(query.toLowerCase());
      if ( query == ''){
        this.qustGropList = this.selectedAreas;
      } else {
        this.qustGropList = result;
      }
    }
    select(query: string): string[]{
      const result: string[] = [];
      for (let a of this.selectedAreas){
        if (a.groupName.toLowerCase().indexOf(query) > -1){
          result.push(a);
        }
      }
      return result;
    }

    submit(){
      this.tabelLoad(0, this.selectLang);
    }
    reset(){
      this.selectLang = 'en';
      this.selectQuestionGroup = '';
      this.searchQuestionText = '';
      this.tabelLoad(0, this.selectLang);
    }
  
    truncateChar(text: string): string {
      let charlimit = 200;
      if(!text || text.length <= charlimit )
      {
          return text;
      }
  
    let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, charlimit) + "...";
    return shortened;
  }

  questionImageUploadPopup(): any{
    const dialogRef = this.dialog.open(QuestionImportpopupComponent, {
      width: '800px',
      height: 'auto',
      data: {
        LangList: this.LangList,
        qustGropList: this.qustGropList,
      },
      panelClass: 'defaultClass',
    });
    dialogRef.afterClosed().subscribe(sresult => {
      // console.log(sresult);
      if (sresult == true){
        this.tabelLoad(0, this.selectLang);
      }
    });
  }
}
