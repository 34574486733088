<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!id">Add </span>
                <span *ngIf="id">Edit </span> Employer Plan</h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/employerPlanList']">Manage Membership List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!id">Add </span>
                    <span *ngIf="id">Edit </span> Employer Plan </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">Plan Name </label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Plan Name " name="planName" formControlName="planName" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('planName').valid && formGroup.get('planName').touched">
                                    Please Enter Plan Name</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Plan Description </label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Plan Description" name="planDescription" formControlName="planDescription" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('planDescription').valid && formGroup.get('planDescription').touched">
                                    Please Enter Plan Description</span>
                            </mat-error>

                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12"> Cost </label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Monthly Cost" name="monthlyCost" formControlName="monthlyCost" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('monthlyCost').valid && formGroup.get('monthlyCost').touched">
                                    Please Enter  Cost</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Duration(In Days)</label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter TrialPeriod-NoCost" name="subscriptionDuration" formControlName="subscriptionDuration" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('subscriptionDuration').valid && formGroup.get('subscriptionDuration').touched">
                                    Please Enter  Duration in days</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Number Of Users</label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Number Of Users" name="numbersOfUsers" formControlName="numbersOfUsers" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('numbersOfUsers').valid && formGroup.get('numbersOfUsers').touched">
                                    Please Enter Number Of Users</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Number of Job Posting </label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Number of Job Posting" name="numberOfJobPosting" formControlName="numberOfJobPosting" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('numberOfJobPosting').valid && formGroup.get('numberOfJobPosting').touched">
                                    Please Enter Number of Job Posting</span>
                            </mat-error>
                        </div>
                    </div>
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Type</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="numberOfJobPostingType">
                                <mat-radio-button value="true" [checked]="radioValue1 == true">Monthly</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue1 == false">Yearly</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Number Of Resume Access</label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Number Of Resume Access" name="numberOfResumeAccess" formControlName="numberOfResumeAccess" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('numberOfResumeAccess').valid && formGroup.get('numberOfResumeAccess').touched">
                                    Please Enter Number Of Resume Access</span>
                            </mat-error>
                        </div>
                    </div>
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Type</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="numberOfResumeAccessType">
                                <mat-radio-button value="true" [checked]="radioValue2 == true">Monthly</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue2 == false">Yearly</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Bulk Email</label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Bulk Email" name="bulkEmail" formControlName="bulkEmail" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('bulkEmail').valid && formGroup.get('bulkEmail').touched">
                                    Please Enter Bulk Email</span>
                            </mat-error>
                        </div>
                    </div>
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Type</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="bulkEmailType">
                                <mat-radio-button value="true" [checked]="radioValue3 == true">Monthly</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue3 == false">Yearly</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Bulk Sms</label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Bulk Sms" name="bulkSms" formControlName="bulkSms" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('bulkSms').valid && formGroup.get('bulkSms').touched">
                                    Please Enter Bulk Sms</span>
                            </mat-error>
                        </div>
                    </div>
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Type</label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="bulkSmsType">
                                <mat-radio-button value="true" [checked]="radioValue4 == true">Monthly</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue4 == false">Yearly</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">CV in Inbox </label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="cvInInbox">
                                <mat-radio-button value="true" [checked]="radioValue5 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue5 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Candidate Calling </label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="candidateCalling">
                                <mat-radio-button value="true" [checked]="radioValue6 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue6 == false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <!-- <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Interview Schedule </label>
                        <div class="col-sm-12 ">
                            <mat-radio-group aria-label="Select an option" formControlName="interviewScheduled">
                                <mat-radio-button value="true" [checked]="radioValue7 == true">Yes</mat-radio-button>
                                <mat-radio-button value="false" [checked]="radioValue7 == true">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->

                </div>
                <div class="">
                    <button type="submit" class="btn btn-danger pd-x-20 mg-t-10" routerLink="/employerPlanList" tabindex="0" style="margin-right: 10px;">Back</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!id">SUBMIT</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="id">UPDATE</button>
                </div>
            </form>
        </div>
    </div>
</div>