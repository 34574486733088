import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './component/homePage/home/home.component';
import { LoginComponent } from './component/login/login.component';
import { AddUpdateCountryDataComponent } from './component/manageMasterData/countryData/add-update-country-data/add-update-country-data.component';
import { ListAllCountryDataComponent } from './component/manageMasterData/countryData/list-all-country-data/list-all-country-data.component';
import { AddUpdateIndustryComponent } from './component/manageMasterData/Industry/add-update-industry/add-update-industry.component';
import { ListAllIndustryComponent } from './component/manageMasterData/Industry/list-all-industry/list-all-industry.component';
import { AddUpdateJobCategoryComponent } from './component/manageMasterData/jobCategory/add-update-job-category/add-update-job-category.component';
import { ListAllJobCatogeryComponent } from './component/manageMasterData/jobCategory/list-all-job-catogery/list-all-job-catogery.component';
import { AddUpdateSkillSetComponent } from './component/manageMasterData/skillSet/add-update-skill-set/add-update-skill-set.component';
import { ListAllSkillSetComponent } from './component/manageMasterData/skillSet/list-all-skill-set/list-all-skill-set.component';
import { AddUpdateRoleComponent } from './component/manageSystem/ManageRoleAccess/add-update-role/add-update-role.component';
import { RoleListComponent } from './component/manageSystem/ManageRoleAccess/role-list/role-list.component';
import { AddUpdateTeamComponent } from './component/manageTeam/add-update-team/add-update-team.component';
import { TeamListComponent } from './component/manageTeam/team-list/team-list.component';
import { MangeQuestionComponent } from './component/PersonalityTest/mange-question/mange-question.component';
import { AddUpdatePTCategoryComponent } from './component/PersonalityTest/personalityTestcatogery/add-update-ptcategory/add-update-ptcategory.component';
import { ListPTCategoryComponent } from './component/PersonalityTest/personalityTestcatogery/list-ptcategory/list-ptcategory.component';
import { AddUpdateProfileComponent } from './component/profile/add-update-profile/add-update-profile.component';
import { ChangePasswordComponent } from './component/profile/change-password/change-password.component';
import { AddUpdateQuestionComponent } from './component/PersonalityTest/add-update-question/add-update-question.component';
import { ListAllJobSubcategoryComponent } from './component/manageMasterData/jobSubCategory/list-all-job-subcategory/list-all-job-subcategory.component';
import { AddUpdateJobSubcategoryComponent } from './component/manageMasterData/jobSubCategory/add-update-job-subcategory/add-update-job-subcategory.component';
import { ListCompetencyCategoryComponent } from './component/manageMasterData/competencyTestCategory/list-competency-category/list-competency-category.component';
import { AddOrUpdateCometencyComponent } from './component/manageMasterData/competencyTestCategory/add-or-update-cometency/add-or-update-cometency.component';
import { ListLanguageComponent } from './component/manageLanguage/list-language/list-language.component';
import { AddLanguageComponent } from './component/manageLanguage/add-language/add-language.component';
import { AddUpdateCompetencyKeywordComponent } from './component/manageMasterData/competencyKeywords/add-update-competency-keyword/add-update-competency-keyword.component';
import { ListCompetencyKeywordComponent } from './component/manageMasterData/competencyKeywords/list-competency-keyword/list-competency-keyword.component';
import { ViewCompetencyKeywordComponent } from './component/manageMasterData/competencyKeywords/view-competency-keyword/view-competency-keyword.component';
import { ListQuestionTypeComponent } from './component/manageMasterData/questionType/list-question-type/list-question-type.component';
import { AddUpdateQuestionTypeComponent } from './component/manageMasterData/questionType/add-update-question-type/add-update-question-type.component';
import { AddUpdateQuestionLevelComponent } from './component/manageMasterData/questionLevel/add-update-question-level/add-update-question-level.component';
import { ListQuestionLevelComponent } from './component/manageMasterData/questionLevel/list-question-level/list-question-level.component';
import { ListRestrictedKeywordComponent } from './component/manageMasterData/restrictedKeywords/list-restricted-keyword/list-restricted-keyword.component';
import { AddUpdateRestrictedKeywordComponent } from './component/manageMasterData/restrictedKeywords/add-update-restricted-keyword/add-update-restricted-keyword.component';
import { ListJobPostQuestionComponent } from './component/managejobPostQuestion/list-job-post-question/list-job-post-question.component';
import { AddupdateJobPostQuestionComponent } from './component/managejobPostQuestion/addupdate-job-post-question/addupdate-job-post-question.component';
import { ListJobPostTestComponent } from './component/managejobPostQuestion/list-job-post-test/list-job-post-test.component';
import { AddUpdateJobTestCreationComponent } from './component/managejobPostQuestion/add-update-job-test-creation/add-update-job-test-creation.component';
import { AddUpdateSubComprehnsiveComponent } from './component/managejobPostQuestion/add-update-sub-comprehnsive/add-update-sub-comprehnsive.component';
import { PublishTestViewComponent } from './component/managejobPostQuestion/publish-test-view/publish-test-view.component';
import { ListInterviewQuestionComponent } from "./component/managejobPostQuestion/list-interview-question/list-interview-question.component";
import { AddUpdateInterviewQuestionComponent } from "./component/managejobPostQuestion/add-update-interview-question/add-update-interview-question.component";
import { AddUpdatePersonalityCategoryValuesComponent } from './component/managePersonalityCategoryValues/add-update-personality-category-values/add-update-personality-category-values.component';
import { ListPersonalityCategoryValuesComponent } from './component/managePersonalityCategoryValues/list-personality-category-values/list-personality-category-values.component';
import { UserListComponent } from "./component/manageUser/user-list/user-list.component";
import { AddUpdateUserComponent } from "./component/manageUser/add-update-user/add-update-user.component";
import { ListIccComponent } from "./component/manageICC/list-icc/list-icc.component";
import { AddUpdateIccComponent } from "./component/manageICC/add-update-icc/add-update-icc.component";
import { AddUpdateVideoInterviewQuestionComponent } from "./component/managejobPostQuestion/add-update-video-interview-question/add-update-video-interview-question.component";
import { LisVideoInterviewQuestionComponent } from "./component/managejobPostQuestion/lis-video-interview-question/lis-video-interview-question.component";
import { ListSettingInterviewQuestionComponent } from "./component/interviewSetting/list-setting-interview-question/list-setting-interview-question.component";
import { AddUpdateSettingInterviewQuestionComponent } from "./component/interviewSetting/add-update-setting-interview-question/add-update-setting-interview-question.component";
import { ListJobPlanComponent } from "./component/manageJobPlans/list-job-plan/list-job-plan.component";
import { AddUpdateJobPlanComponent } from "./component/manageJobPlans/add-update-job-plan/add-update-job-plan.component";
import { ListJobBoardComponent } from "./component/manageJobBoard/list-job-board/list-job-board.component";
import { AddUpdateJobBoardComponent } from "./component/manageJobBoard/add-update-job-board/add-update-job-board.component";
import { TranslationComponent } from "./component/translation/translation.component";
import { ViewUserDetailsComponent } from "./component/manageUser/view-user-details/view-user-details.component";
import { JobDetailsDetailsComponent } from "./component/manageUser/job-details-details/job-details-details.component";
import { CandidateListComponent } from "./component/manageCandidate/candidate-list/candidate-list.component";
import { CandidateViewComponent } from "./component/manageCandidate/candidate-view/candidate-view.component";
import { CandidateCvViewComponent } from "./component/manageCandidate/candidate-cv-view/candidate-cv-view.component";
import { CandidatePersonalityTestResultComponent } from "./component/manageCandidate/candidate-personality-test-result/candidate-personality-test-result.component";
import { ListTemplateComponent } from "./component/manageNotificationTemplate/list-template/list-template.component";
import { AddUpdateTemplateComponent } from "./component/manageNotificationTemplate/add-update-template/add-update-template.component";
import { ListMediaComponent } from "./component/manageMedia/list-media/list-media.component";
import { AddUpdateMediaComponent } from "./component/manageMedia/add-update-media/add-update-media.component";
import { MembershipAddEditComponent } from './component/memberShipDetails/membership-add-edit/membership-add-edit.component';
import { MembershipListComponent } from './component/memberShipDetails/membership-list/membership-list.component';

import { ManageLanguageComponent } from "./component/manageTranslation/manage-language/manage-language.component";
import { EditTranslationComponent } from "./component/manageTranslation/edit-translation/edit-translation.component";
import { AddTranslationComponent } from "./component/manageTranslation/add-translation/add-translation.component";
import { AddEditDiscountComponent } from './component/memberShipDetails/discount/add-edit-discount/add-edit-discount.component';
import { ListDiscountComponent } from './component/memberShipDetails/discount/list-discount/list-discount.component';
import { AddEditVoucherComponent } from './component/memberShipDetails/Voucher/add-edit-voucher/add-edit-voucher.component';
import { VoucherListComponent } from './component/memberShipDetails/Voucher/voucher-list/voucher-list.component';
import { CandidateCvScoreViewComponent } from './component/manageCandidate/candidate-cv-score-view/candidate-cv-score-view.component';
import { ListAllEmploymentTypeComponent } from './component/manageMasterData/employementType/list-all-employment-type/list-all-employment-type.component';
import { AddUpdateEmploymentTypeComponent } from './component/manageMasterData/employementType/add-update-employment-type/add-update-employment-type.component';
import { ListAllContractTypeComponent } from './component/manageMasterData/contractType/list-all-contract-type/list-all-contract-type.component';
import { AddUpdateContractTypeComponent } from './component/manageMasterData/contractType/add-update-contract-type/add-update-contract-type.component';
import { ListAllEducationLevelComponent } from './component//manageMasterData/educationLevel/list-all-education-level/list-all-education-level.component';
import { AddUpdateEducationLevelComponent } from './component/manageMasterData/educationLevel/add-update-education-level/add-update-education-level.component';
import { ListAllWorkingConditionComponent } from './component/manageMasterData/workingCondition/list-all-working-condition/list-all-working-condition.component';
import { AddUpdateWorkingConditionComponent } from './component/manageMasterData/workingCondition/add-update-working-condition/add-update-working-condition.component';
import { ListAllPayOfferedComponent } from './component/manageMasterData/payOffered/list-all-pay-offered/list-all-pay-offered.component';
import { AddUpdatePayOfferedComponent } from './component/manageMasterData/payOffered/add-update-pay-offered/add-update-pay-offered.component';
import { ListAllBenefitsComponent } from './component/manageMasterData/benefits/list-all-benefits/list-all-benefits.component';
import { AddUpdateBenefitsComponent } from './component/manageMasterData/benefits/add-update-benefits/add-update-benefits.component';
import { PaymentListComponent } from './component/managePayment/payment-list/payment-list.component';
import { AddUpdatePaymentComponent } from './component/managePayment/add-update-payment/add-update-payment.component';
import { CategoryJobCreationReportComponent } from './component/manageMasterData/jobCategory/category-job-creation-report/category-job-creation-report.component';
import { AddEditJobFreeServicesComponent } from './component/manageJobPlans/manageJobFreeService/add-edit-job-free-services/add-edit-job-free-services.component';
import { ListJobFreeServicesComponent } from './component/manageJobPlans/manageJobFreeService/list-job-free-services/list-job-free-services.component';
import { AddEditocenDescriptionComponent } from './component/managePersonalityCategoryValues/add-editocen-description/add-editocen-description.component';
import { OcenDescriptionListComponent } from './component/managePersonalityCategoryValues/ocen-description-list/ocen-description-list.component';
import { AddEditocenCANDIDATEDescriptionComponent } from './component/managePersonalityCategoryValues/add-editocen-candidatedescription/add-editocen-candidatedescription.component';
import { CANDIDATEocenDescriptionListComponent } from './component/managePersonalityCategoryValues/candidateocen-description-list/candidateocen-description-list.component';
import { EmployerPlanListComponent } from './component/mangeMembership/EmployerPlan/employer-plan-list/employer-plan-list.component';
import { EmployerPlanAddUpdateComponent } from './component/mangeMembership/EmployerPlan/employer-plan-add-update/employer-plan-add-update.component';
import { AddUpdateCandidatePlanComponent } from './component/mangeMembership/CandidatePlan/add-update-candidate-plan/add-update-candidate-plan.component';
import { ListCandidatePlanComponent } from './component/mangeMembership/CandidatePlan/list-candidate-plan/list-candidate-plan.component';
import { ListAddonPlanComponent } from './component/mangeMembership/AddonPlan/list-addon-plan/list-addon-plan.component';
import { AddUpdateAddonPlanComponent } from './component/mangeMembership/AddonPlan/add-update-addon-plan/add-update-addon-plan.component';
import { ContactListComponent } from './component/ManageContact/contact-list/contact-list.component';
import { ContactViewListComponent } from './component/ManageContact/contact-view-list/contact-view-list.component';
import { ManageJobsService } from './service/ManageJobs/manage-jobs.service';
import { ListLocationComponent } from './component/manageMasterData/manageLocation/list-location/list-location.component';
import { AddUpdateLocationComponent } from './component/manageMasterData/manageLocation/add-update-location/add-update-location.component';
import { DiscountListComponent } from './component/mangeMembership/Manage-Discount/discount-list/discount-list.component';
import { ManageDiscountComponent } from './component/mangeMembership/Manage-Discount/manage-discount/manage-discount.component';
import { RequestCvComponent } from './component/request-cv/request-cv.component';
import { ManageJobReportComponent } from './component/manage-job-report/manage-job-report.component';
import { ListAllEducationComponent } from './component/manageMasterData/educations/list-all-education/list-all-education.component';
import { AddUpdateEducationComponent } from './component/manageMasterData/educations/add-update-education/add-update-education.component';
import { ListCustomizPlanComponent } from './component/mangeMembership/custimize-plan/list-customiz-plan/list-customiz-plan.component';
import { AddUpdateCustomizPlanComponent } from './component/mangeMembership/custimize-plan/add-update-customiz-plan/add-update-customiz-plan.component';
import { ListContactdashboardComponent } from './component/manageDashboardContact/list-contactdashboard/list-contactdashboard.component';
import { AddUpdateContactComponent } from './component/manageDashboardContact/add-update-contact/add-update-contact.component';
import { ListRusumeBoostedComponent } from './component/manageRusumeBoosted/list-rusume-boosted/list-rusume-boosted.component';
import { RecuiterMembershipComponent } from './component/manage-reports/recuiter-membership/recuiter-membership.component';
import { JobApproovalViewComponent } from './component/job-approoval/job-approoval-view/job-approoval-view.component';
import {  JobApproovalListComponent} from './component/job-approoval/job-approoval-list/job-approoval-list.component';
import { CandidateMembershipComponent } from './component/manage-job-report/candidate-membership/candidate-membership.component';
import { UserPolicyComponent } from './component/user-policy/user-policy/user-policy.component';
import { AddPolicyComponent } from './component/user-policy/add-policy/add-policy.component';
import { AddUpdateBlogComponent } from './component/manageBlog/add-update-blog/add-update-blog.component';
import { BlogViewListComponent } from './component/manageBlog/blog-view-list/blog-view-list.component';
import { DummyExcelSheetComponent } from './component/dummy-excel-sheet/dummy-excel-sheet.component';
import { ListNewsEventComponent } from './component/manageNewsEvent/list-news-event/list-news-event.component';
import { AddUpdateNewsEventComponent } from './component/manageNewsEvent/add-update-news-event/add-update-news-event.component';
import { DailyLimitComponent } from './component/daily-limit/daily-limit.component';
import { AddUpdateStateDataComponent } from './component/manageMasterData/stateData/add-update-state-data/add-update-state-data.component';
import { ListAllStateDataComponent } from './component/manageMasterData/stateData/list-all-state-data/list-all-state-data.component';
import { AddUpdateZoneDataComponent } from './component/manageMasterData/zoneData/add-update-zone-data/add-update-zone-data.component';
import { ListAllZoneDataComponent } from './component/manageMasterData/zoneData/list-all-zone-data/list-all-zone-data.component';
import { AddUpdateDistrictDataComponent } from './component/manageMasterData/districtData/add-update-district-data/add-update-district-data.component';
import { ListAllDistrictDataComponent } from './component/manageMasterData/districtData/list-all-district-data/list-all-district-data.component';
import { AddUpdateCityDataComponent } from './component/manageMasterData/cityData/add-update-city-data/add-update-city-data.component';
import { ListAllCityDataComponent } from './component/manageMasterData/cityData/list-all-city-data/list-all-city-data.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'dummyExcelSheet', component: DummyExcelSheetComponent },
  { path: 'teamList', component: TeamListComponent },
  { path: 'addTeam', component: AddUpdateTeamComponent },
  { path: 'editTeam/:id', component: AddUpdateTeamComponent },
  { path: 'rollList', component: RoleListComponent },
  /// Employer Membership Plan 
  { path: 'employerPlanList', component: EmployerPlanListComponent },
  { path: 'addNewEmployerPlan', component: EmployerPlanAddUpdateComponent },
  { path: 'editEmployerPlan/:id', component: EmployerPlanAddUpdateComponent },
  { path: 'contactList', component: ContactListComponent },
  { path: 'contactViewList/:id', component: ContactViewListComponent },
  { path: 'daily-limit', component: DailyLimitComponent },
  // End  Employer Membership Plan

  /// Candidate Membership Plan 
  { path: 'candidate-plan-list', component: ListCandidatePlanComponent },
  { path: 'AddNewCandidatePlan', component: AddUpdateCandidatePlanComponent },
  { path: 'addEditCandidatePlan/:id', component: AddUpdateCandidatePlanComponent },
  // End Membership Plan

  /// Candidate Membership Plan 
  { path: 'addonPlanList', component: ListAddonPlanComponent },
  { path: 'AddNewAddonPlan', component: AddUpdateAddonPlanComponent },
  { path: 'addEditAddonPlan/:id', component: AddUpdateAddonPlanComponent },
  // End Membership Plan

  /// cusromize Plan 
  { path: 'customize-plan-list', component: ListCustomizPlanComponent },
  { path: 'AddCustomizePlan', component: AddUpdateCustomizPlanComponent },
  { path: 'addEditCustomizePlan/:id', component: AddUpdateCustomizPlanComponent },

  // Candidate Membership 
  { path: 'candidate-membership-list', component: CandidateMembershipComponent },

  // job approval
  { path: 'job-approval-list', component: JobApproovalListComponent},
  { path: 'job-description/:id', component: JobApproovalViewComponent },

    /// Conatct US 
    { path: 'contact-us', component: ListContactdashboardComponent },
    { path: 'AddcontactUs', component: AddUpdateContactComponent },
    { path: 'editContactUs/:id', component: AddUpdateContactComponent },
  

  { path: 'addNewRole', component: AddUpdateRoleComponent },
  { path: 'editRole/:id', component: AddUpdateRoleComponent },
  { path: 'changePassword', component: ChangePasswordComponent },
  { path: 'editProfile/:id', component: AddUpdateProfileComponent },
  { path: 'login', component: LoginComponent },
  // master data
  { path: 'listjobcategory', component: ListAllJobCatogeryComponent },
  { path: 'jobcategoryReport', component: CategoryJobCreationReportComponent },
  { path: 'editjobcategory/:id', component: AddUpdateJobCategoryComponent },
  { path: 'addjobcategory', component: AddUpdateJobCategoryComponent },
  { path: 'listjobsubcategory', component: ListAllJobSubcategoryComponent },
  { path: 'editjobsubcategory/:id', component: AddUpdateJobSubcategoryComponent },
  { path: 'addjobsubcategory', component: AddUpdateJobSubcategoryComponent },
  { path: 'listIndusrty', component: ListAllIndustryComponent },
  { path: 'addIndusrty', component: AddUpdateIndustryComponent },
  { path: 'editIndusrty/:id', component: AddUpdateIndustryComponent },
  { path: 'listCountryData', component: ListAllCountryDataComponent },
  { path: 'addCountryData', component: AddUpdateCountryDataComponent },
  { path: 'editCountryData/:id', component: AddUpdateCountryDataComponent },
  { path: 'listSkillSet', component: ListAllSkillSetComponent },
  { path: 'addSkillSet', component: AddUpdateSkillSetComponent },
  { path: 'editSkillSet/:id', component: AddUpdateSkillSetComponent },
  { path: 'addStateData', component: AddUpdateStateDataComponent },
  { path: 'listStateData', component: ListAllStateDataComponent },
  { path: 'editStateData/:id', component: AddUpdateStateDataComponent },
  { path: 'addZoneData', component: AddUpdateZoneDataComponent },
  { path: 'listZoneData', component: ListAllZoneDataComponent },
  { path: 'editZoneData/:id', component: AddUpdateZoneDataComponent },
  { path: 'addDistrictData', component: AddUpdateDistrictDataComponent },
  { path: 'listDistrictData', component: ListAllDistrictDataComponent },
  { path: 'editDistrictData/:id', component: AddUpdateDistrictDataComponent },
  { path: 'addCityData', component: AddUpdateCityDataComponent },
  { path: 'listCityData', component: ListAllCityDataComponent },
  { path: 'editCityData/:id', component: AddUpdateCityDataComponent },
  
  //Manage Location
  { path: 'location-list', component: ListLocationComponent },
  { path: 'add-location', component: AddUpdateLocationComponent },
  { path: 'editLocation/:id', component: AddUpdateLocationComponent },

  //Manage Blog
  { path: 'blog-list', component: BlogViewListComponent },
  { path: 'add-blog', component: AddUpdateBlogComponent },
  { path: 'edit-blog/:id', component: AddUpdateBlogComponent },

  //Employement Type
  { path: 'listEmployementType', component: ListAllEmploymentTypeComponent },
  { path: 'editEmployementType/:id', component: AddUpdateEmploymentTypeComponent },
  { path: 'addEmployementType', component: AddUpdateEmploymentTypeComponent },
  //Contract Type
  { path: 'listContractType', component: ListAllContractTypeComponent },
  { path: 'editContractType/:id', component: AddUpdateContractTypeComponent },
  { path: 'addContractType', component: AddUpdateContractTypeComponent },
  //Education Level
  { path: 'listEducationLevel', component: ListAllEducationLevelComponent },
  { path: 'editEducationLevel/:id', component: AddUpdateEducationLevelComponent },
  { path: 'addEducationLevel', component: AddUpdateEducationLevelComponent },

    //Education 
    { path: 'listEducation', component: ListAllEducationComponent },
    { path: 'editEducation/:id', component: AddUpdateEducationComponent },
    { path: 'addEducation', component: AddUpdateEducationComponent },
  //Working Condition
  { path: 'listWorkingCondition', component: ListAllWorkingConditionComponent },
  { path: 'editWorkingCondition/:id', component: AddUpdateWorkingConditionComponent },
  { path: 'addWorkingCondition', component: AddUpdateWorkingConditionComponent },
  //Pay Offered
  { path: 'listPayOffered', component: ListAllPayOfferedComponent },
  { path: 'editPayOffered/:id', component: AddUpdatePayOfferedComponent },
  { path: 'addPayOffered', component: AddUpdatePayOfferedComponent },
  //Benefits Type
  { path: 'listBenefitsType', component: ListAllBenefitsComponent },
  { path: 'editBenefitsType/:id', component: AddUpdateBenefitsComponent },
  { path: 'addBenefitsType', component: AddUpdateBenefitsComponent },
  //
  { path: 'listPTcategory', component: ListPTCategoryComponent },
  { path: 'addPTcategory', component: AddUpdatePTCategoryComponent },
  { path: 'editPTcategory/:id', component: AddUpdatePTCategoryComponent },
  { path: 'mangeQuestion', component: MangeQuestionComponent },
  { path: 'editPersonalityQuestion/:id', component: AddUpdateQuestionComponent },
  { path: 'listCompetencyCategory', component: ListCompetencyCategoryComponent },
  { path: 'addCompetencyCategory', component: AddOrUpdateCometencyComponent },
  { path: 'editaddCompetencyCategory/:id', component: AddOrUpdateCometencyComponent },
  // language list master data
  { path: 'listLang', component: ListLanguageComponent },
  { path: 'addLang', component: AddLanguageComponent },
  { path: 'editLang/:id', component: AddLanguageComponent },


  { path: 'listCompetancyKeywords', component: ListCompetencyKeywordComponent },
  { path: 'addCompetancyKeywords', component: AddUpdateCompetencyKeywordComponent },
  { path: 'viewCompetancyKeywords/:id/:lng', component: ViewCompetencyKeywordComponent },

  { path: 'listQuestionType', component: ListQuestionTypeComponent },
  { path: 'addQuestionType', component: AddUpdateQuestionTypeComponent },
  { path: 'editQuestionType/:id', component: AddUpdateQuestionTypeComponent },

  { path: 'listQuestionGroup', component: ListQuestionLevelComponent },
  { path: 'addQuestionGroup', component: AddUpdateQuestionLevelComponent },
  { path: 'viewQuestionGroup/:id', component: AddUpdateQuestionLevelComponent },

  { path: 'listRestrictedKeywords', component: ListRestrictedKeywordComponent },
  { path: 'addRestrictedKeywords', component: AddUpdateRestrictedKeywordComponent },
  // jobpost question
  { path: 'listjobTestQuestion', component: ListJobPostQuestionComponent },
  { path: 'addJobTestQuestion', component: AddupdateJobPostQuestionComponent },
  { path: 'editJobTestQuestion/:id', component: AddupdateJobPostQuestionComponent },
  { path: 'editComprehnsionQuestion/:id', component: AddUpdateSubComprehnsiveComponent },
  // jobpost test creation
  { path: 'listJobTestCreation/:lang', component: ListJobPostTestComponent },
  { path: 'addJobTestCreation', component: AddUpdateJobTestCreationComponent },
  { path: 'editJobTestCreation/:id/:tab', component: AddUpdateJobTestCreationComponent },
  { path: 'viewPublishTest/:id/:tab', component: PublishTestViewComponent },


  // interview question module
  // mcq
  { path: 'listInterviewQuestion', component: ListInterviewQuestionComponent },
  { path: 'addInterviewQuestion', component: AddUpdateInterviewQuestionComponent },
  { path: 'editInterviewQuestion/:id', component: AddUpdateInterviewQuestionComponent },
  // video quesiton
  { path: 'addVideoInterviewQuestion', component: AddUpdateVideoInterviewQuestionComponent },
  { path: 'editVideoInterviewQuestion/:id', component: AddUpdateVideoInterviewQuestionComponent },
  { path: 'listvideoInterviewQuestion/:lang', component: LisVideoInterviewQuestionComponent },

  // personality test category values
  { path: 'listPersonalityCategoryValues', component: ListPersonalityCategoryValuesComponent },
  { path: 'addPersonalityCategoryValues', component: AddUpdatePersonalityCategoryValuesComponent },
  { path: 'editPersonalityCategoryValues/:id', component: AddUpdatePersonalityCategoryValuesComponent },

  // icc module
  { path: 'list-iccvalues', component: ListIccComponent },
  { path: 'addIccValues', component: AddUpdateIccComponent },
  { path: 'editIccValues/:id', component: AddUpdateIccComponent },

  //User list
  { path: 'user-list', component: UserListComponent },
  { path: 'edit-user/:id', component: AddUpdateUserComponent },
  { path: 'view-user/:id', component: ViewUserDetailsComponent },
  { path: 'view-job-details/:id/:jobId', component: JobDetailsDetailsComponent },
  //User list
  { path: 'user-policy', component: UserPolicyComponent },
  { path: 'Add-policy', component: AddPolicyComponent },
  { path: 'edit-policy/:id', component: AddPolicyComponent },

  //Interview question setting
  { path: 'listInterviewSetting', component: ListSettingInterviewQuestionComponent },
  { path: 'addInterviewSetting', component: AddUpdateSettingInterviewQuestionComponent },
  { path: 'editInteviewSetting/:id', component: AddUpdateSettingInterviewQuestionComponent },

  //Job plan module
  { path: 'listJobPlans', component: ListJobPlanComponent },
  { path: 'addJobPlan', component: AddUpdateJobPlanComponent },
  { path: 'editJobPlan/:id', component: AddUpdateJobPlanComponent },

  //Job board module
  { path: 'listJobBoard', component: ListJobBoardComponent },
  { path: 'addJobBoard', component: AddUpdateJobBoardComponent },
  { path: 'editJobBoard/:id', component: AddUpdateJobBoardComponent },
  // Mange discount
  { path: 'coupon-list', component: DiscountListComponent },
  { path: 'add-coupon', component: ManageDiscountComponent },
  { path: 'edit-coupon/:id', component: ManageDiscountComponent },

  //Transalation
  { path: 'translation', component: TranslationComponent },

  //Candidate module
  { path: 'candidate-list', component: CandidateListComponent },
  { path: 'view-candidate/:id', component: CandidateViewComponent },
  { path: 'view-candidate-cv/:id', component: CandidateCvViewComponent },
  { path: 'view-candidate-result/:id', component: CandidatePersonalityTestResultComponent },
  { path: 'view-candidate-Video/:id', component: CandidateCvScoreViewComponent },

  //Notification template module
  { path: 'notification-template', component: ListTemplateComponent },
  { path: 'add-notification-template', component: AddUpdateTemplateComponent },
  { path: 'edit-notification-template/:id', component: AddUpdateTemplateComponent },


  // Rusume Boosted Module
  {path: 'rusume-boosted-list' , component: ListRusumeBoostedComponent},

    // Recuiter  Membership  Module
    {path: 'employer-membership-list' , component: RecuiterMembershipComponent},
    
  { path: 'media-list', component: ListMediaComponent },
  { path: 'add-media', component: AddUpdateMediaComponent },
  { path: 'edit-media/:id', component: AddUpdateMediaComponent },

  // MEMBERSHIP PLAN
  { path: 'membershipList', component: MembershipListComponent },
  { path: 'addEditMemberShip/:id', component: MembershipAddEditComponent },


  { path: 'listDiscount', component: ListDiscountComponent },
  { path: 'addDiscount', component: AddEditDiscountComponent },
  { path: 'editDiscount/:id', component: AddEditDiscountComponent },
  { path: 'listVouchar', component: VoucherListComponent },
  { path: 'addVouchar', component: AddEditVoucherComponent },
  { path: 'editVouchar/:id', component: AddEditVoucherComponent },

  //Manage Transalation
  { path: 'manageLanguage', component: ManageLanguageComponent },
  { path: 'editTranslation/:appName/:isWeb', component: EditTranslationComponent },
  { path: 'addTranslation', component: AddTranslationComponent },

  //Manage payment
  { path: 'paymentList', component: PaymentListComponent },
  { path: 'addPayment', component: AddUpdatePaymentComponent },
  { path: 'editPayment/:id', component: AddUpdatePaymentComponent },

  // manage job free plan services 
  { path: 'listJobFreeService', component: ListJobFreeServicesComponent },
  { path: 'addJobFreeService', component: AddEditJobFreeServicesComponent },
  { path: 'editJobFreeService/:id', component: AddEditJobFreeServicesComponent },

  // OCEN recruiter list add edit

  { path: 'listrecruiterOcen', component: OcenDescriptionListComponent },
  { path: 'addrecruiterOcen', component: AddEditocenDescriptionComponent },
  { path: 'editrecruiterOcen/:id', component: AddEditocenDescriptionComponent },

  { path: 'listCandidateOcean', component: CANDIDATEocenDescriptionListComponent },
  { path: 'addCandidateOcean', component: AddEditocenCANDIDATEDescriptionComponent },
  { path: 'editCandidateOcean/:id', component: AddEditocenCANDIDATEDescriptionComponent },
  { path: 'requestCvList', component: RequestCvComponent },
  { path: 'jobList', component: ManageJobReportComponent },

  //Manage News & Events
  { path: 'news-events-list', component: ListNewsEventComponent },
  { path: 'add-news-events', component: AddUpdateNewsEventComponent },
  { path: 'edit-news-events/:id', component: AddUpdateNewsEventComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routing = RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
});
