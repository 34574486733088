import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JobTestQuestionManageService } from 'src/app/service/jobPostTestandQuestion/job-test-question-manage.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-question-importpopup',
  templateUrl: './question-importpopup.component.html',
  styleUrls: ['./question-importpopup.component.scss']
})
export class QuestionImportpopupComponent implements OnInit {

  selectQuestionGroup: any;
  selectLang: any;
  LangList = [];
  qustGropList = [];
  selectedAreas= [];
  qustTypeList =[];
  qType: any;
  fileData: any;
  countryFlagImagePath: any;
  selectQuestionType: any;




  constructor( public dialogRef: MatDialogRef<QuestionImportpopupComponent>,
    private masterDataservice: MasterDataService,
    private snackBar: MatSnackBar,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.LangList = this.data.LangList;
    console.log(this.LangList)
    this.qustGropList = this.data.qustGropList;
    this.selectedAreas = this.qustGropList;
    this.questionTypeList();
  }
  questionTypeList(): any {
    this.masterDataservice.getAllQuestionTypeList(0 , 20).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {
        this.qustTypeList = this.qType.response.dataSet.filter((d) => {
          if (d.isActive == true) {
            if (!(d.questionTypeId == 10 || d.questionTypeId == 7 || d.questionTypeId == 5 || d.questionTypeId == 4)) {
              return d;
            }           
          }
        });
        console.log(this.qustTypeList);
      }
    });
  }
  search(query: string): any{
    const result = this.select(query.toLowerCase());
    if ( query == ''){
      this.qustGropList = this.selectedAreas;
    } else {
      this.qustGropList = result;
    }
  }
  select(query: string): string[]{
    const result: string[] = [];
    for (let a of this.selectedAreas){
      if (a.groupName.toLowerCase().indexOf(query) > -1){
        result.push(a);
      }
    }
    return result;
  }

  LangChange(e){
    console.log(e)
    this.selectLang = e.value;
    this.QuestiongroupList(0,500 , e.value.languageCode);
  }
  fileSelect(event, files) { 
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.countryFlagImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      console.log(this.fileData)
      // this.bulkUpload();
      // tslint:disable-next-line: variable-name
      /*reader.onload = (_event) => {
        this.countryFlagImgURL = reader.result;
      };*/
    }
    // this.newimgeuplod = true;
    console.log(this.fileData);
  }
  save(){
    const formData: FormData = new FormData();
    formData.append('languageId', this.selectLang.languageId);
    formData.append('questionTypeId', this.selectQuestionType);
    formData.append('questionGrouId', this.selectQuestionGroup);
    formData.append('file', this.fileData);

    if(!(this.selectQuestionType == '' || this.selectQuestionType == null || this.selectQuestionGroup == null || this.selectQuestionGroup == '' || this.fileData == undefined || this.fileData == null || this.fileData == '')){
    this.jobpostQuestionTestService.newQuestionbuilkUpload(formData).subscribe( (res:any)=>{
      if(res.status == 0){
        this.snackBar.open(res.response, '', {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
        this.dialogRef.close(true);
      } else {
        this.snackBar.open(res.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      } 
    } , (error:any)=>{
      this.snackBar.open(error.error.response, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  } else {
    this.snackBar.open('select All fileds', '', {
      duration: 3000,
      panelClass: ['error-snackbar']
    });
  }
  }
  QuestiongroupList(page,size , selectLang) {
    this.masterDataservice.getAllQuestionLevelList(page,size ,selectLang).subscribe(res=>{
      this.data = res;
      if( this.data.status == 0 ){
        this.qustGropList = this.data.response.dataSet;
        this.selectedAreas = this.qustGropList;
      }
    });

  }

}
