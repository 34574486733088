<div class="bg-gray-200">
    <div class="modal d-block pos-static">
        <div role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">Question Preview</h6>
                    <button aria-label="Close" class="close" 
                    [mat-dialog-close]="false"
                    data-dismiss="modal" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <mat-dialog-content>
                        <div [innerHtml]="data.question"></div>
                        <div>
                            <img [src]="data.qImage" [alt]="data.question" style="max-height:300px">
                        </div>
                    </mat-dialog-content>                  
                </div>
                <div class="modal-footer">
                    <!-- <button class="btn btn-indigo" type="button">Save changes</button> -->
                     <button class="btn btn-outline-light" 
                     [mat-dialog-close]="false"
                     type="button">Close</button>
                </div>
            </div>
        </div>
    </div>
    </div>
    