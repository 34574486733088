import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { ImageQuestionPopupComponent } from "../../managejobPostQuestion/image-question-popup/image-question-popup.component";
import { DynamicLabelClass } from "../../../helper/userLevelLink";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../service/language/language.service";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { MatDialog } from "@angular/material/dialog";
import { JobTestQuestionManageService } from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import { MatPaginator } from "@angular/material/paginator";
import { CopyButtonPopupComponent } from "../../managejobPostQuestion/copy-button-popup/copy-button-popup.component";
import { DocumentPopupComponent } from "../document-popup/document-popup.component";
// import { jsPDF } from "jspdf";
import { MatSort } from "@angular/material/sort";
import * as jspdf from "jspdf";
@Component({
  selector: "app-add-update-user",
  templateUrl: "./add-update-user.component.html",
  styleUrls: ["./add-update-user.component.scss"],
})
export class AddUpdateUserComponent implements OnInit {
  @ViewChild("content") content: ElementRef;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  rojgarmantraId: any;
  companyName: any;
  phoneNumber: any;
  email: any;
  country: any;
  currentAddress: any;
  registeredAddress: any;
  officeAddress: any;
  registerAddress3: any;
  cityName: any;
  stateName: any;
  postalCode: string;
  gstNumber: any;
  billAddress: any;
  billCity: any;
  billPostalCode: any;
  website: any;
  linkedIn: any;
  twitter: any;
  instagram: any;
  facebook: any;
  allRecruiterData: any;
  userEmail: any;
  userMobileNumber: any;
  pendingStatus: boolean = false;
  approvedStatus: boolean = false;
  rejectedStatus: boolean = false;
  allRecruiterDetails: any;
  teamDetails: any;
  teamDetailsByUserId: any;
  jobsDetails: any;
  infoId: any;
  limit: any;
  imageDivShow: boolean = false;
  iFrameShow: boolean = false;
  page: number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  totalpageLenght1: number;
  totalrecordLength1: number;
  totalpageLenght2: number;
  totalrecordLength2: number;
  totalpageLenght3: number;
  totalrecordLength3: number;
  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    public dialog: MatDialog,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    private router: Router
  ) {}
  get f() {
    return this.dyanmicQuestionForm.controls;
  }
  get t() {
    return this.f.assesmentTestMultiQuestions as FormArray;
  }
  columnsToDisplay = ["seqNo", "question"];
  expandedElement: (query: string) => void;
  viewID: any;
  fileData: File = null;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  dyanmicQuestionForm: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList = [];

  qustGropList = [];
  qustGropList0 = [];
  qustGropList1 = [];
  qustGropList2 = [];
  qustGropList3 = [];
  qustGropList4 = [];
  qustGropList5 = [];
  qGroup: any;
  jobCatList = [];
  qType: any;
  arrayOptions = [];
  pdata: any;
  seletedOption: any;
  myModel: any;
  config: any;
  addnewOption: any;
  submitted = false;
  selectLang: any;
  firstFormSubmited: boolean;
  companyId: any;
  displayedColumns: string[];
  displayedColumns1: string[];
  displayedColumns2: string[];
  displayedColumns3: string[];
  dataSource = new MatTableDataSource();
  dataSource1 = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();
  dataSource3 = new MatTableDataSource();
  dummyarray = [
    {
      jobTitle: "System Administration",
      category: "29-July-2021",
      noOfHiring: "210",
      postedDate: "13/12/2021",
      noOfResponse: "1",
      status: "Active",
    },
    {
      jobTitle: "System Administration",
      category: "29-July-2021",
      noOfHiring: "210",
      postedDate: "13/12/2021",
      noOfResponse: "1",
      status: "Active",
    },
    {
      jobTitle: "System Administration",
      category: "29-July-2021",
      noOfHiring: "210",
      postedDate: "13/12/2021",
      noOfResponse: "1",
      status: "Active",
    },
    {
      jobTitle: "System Administration",
      category: "29-July-2021",
      noOfHiring: "210",
      postedDate: "13/12/2021",
      noOfResponse: "1",
      status: "Active",
    },
    {
      jobTitle: "System Administration",
      category: "29-July-2021",
      noOfHiring: "210",
      postedDate: "13/12/2021",
      noOfResponse: "1",
      status: "Active",
    },
  ];
  selection = new SelectionModel<any>(true, []);
  QGData: any;
  mm: boolean = false;
  assesmentTestId: any;
  createTestData: any;
  testQData: any;
  firstCreateData: any;
  checkboxSelectedCount: number = 0;
  checkboxSelectArray = [];
  ToatlQuestionNo: number;
  QuestionSubmitBtnHide: boolean = false;
  isTestCreateSuccess: boolean = false;
  SelectedMainQuestionList: any[] = [];
  dsdsdsdsdsdsdsd: any[] = [];
  Jcat: any;
  languageId: any;
  excellentPassDescription: any;
  companyDescription: any;
  normalPassingScoreMax: any;
  numberOfRetry: any;
  excellentPassScore: any;
  jobcategoryName: any;
  testDescriptionForCompany: any;
  testDescriptionForCandidate: any;
  maxTimeInMin: any;
  failResultDescription: any;
  normalPassingScoreMin: any;
  pererror: boolean = false;
  JobQuniError: boolean = false;
  excellentPassScoreError: boolean = false;
  addOptionButtonHide: boolean = false;
  selectedAreas: any[] = [];

  questionAreas: any[] = [];
  questionAreas1: any[] = [];
  questionAreas2: any[] = [];
  questionAreas3: any[] = [];
  questionAreas4: any[] = [];
  questionAreas5: any[] = [];
  customQuestionGroupArray: any[] = [];
  selectedlanguageCode: any;
  selectedLangName: any;
  maxScoreDisable: boolean = true;
  excellentScoreDisable: boolean = true;
  UpdateCreatedTest: boolean = false;
  selectedTab = 0;
  moreCountthanQuestion: boolean = false;
  passResultDescription: any;
  title: any;
  subquestionCount = 0;
  viewIDOfSubRecruiter: boolean = false;
  item: any;
  isShown: boolean = false;
  jobId: any;
  ngOnInit(): void {
    this.firstFormSubmited = false;
    this.addnewOption = 1;
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.seletedOption = 0;
    this.page = 0;
    this.size = 500;
    this.limitData = 6;
    this.viewID = this.route.snapshot.params["id"];
    // this.jobId = this.route.snapshot.params["jobId"];
    this.companyId = this.route.snapshot.params["id"];
    this.selectedTab = this.route.snapshot.params["tab"];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    console.log(this.LangList);
    // this.questionGroupList();
    // this.openContentOne(this.selectedTab);
    // this.questionTypeList(this.selectLang);
    this.initializeForm();
    this.arrayOptions = [];
    // this.addOption();
    this.displayedColumns = [
      "jobTitle",
      // "category",
      "noOfHiring",
      "postedDate",
      "jobEndDate",
      "noOfResponse",
      "status",
      "action",
    ];
    this.displayedColumns1 = [
      "name",
      "gender",
      "mobileNo",
      "location",
      "designation",
    ];
    this.displayedColumns2 = [
      "srno",
      "planName",
      "planStartDate",
      "expiryDate",
      "subscriptionStatus",
      "paymentStatus",
    ];
    this.displayedColumns3 = [
      "srno",
      "month",
      "jobPosting",
      "noOfLogin",
      "sms",
      "bulkEmail",
      "databaseAccess",
    ];
  }

  // LangChange(e) {
  //   for (let l of this.LangList) {
  //     if (l.languageId == e.value) {
  //       this.selectLang = l.languageCode;
  //       this.questionTypeList(this.selectLang);
  //     }
  //   }
  // }
  // questionTypeList(selectLang) {
  //   this.masterDataservice.getAllCategoryList(this.page, this.size, selectLang, true).subscribe(res => {
  //     this.qType = res;
  //     if (this.qType.status == 0) {
  //       this.jobCatList = this.qType.response.dataSet.filter((d) => {
  //         // && d.isTestCreated == false
  //         if (d.isSubCategory == true) {
  //           return d;
  //         }
  //       });
  //       this.selectedAreas = this.jobCatList;
  //       console.log(this.jobCatList);
  //     }
  //   });
  // }

  search(query: string) {
    console.log("query", query);
    let result = this.select(query);
    if (query == "") {
      this.jobCatList = this.selectedAreas;
    } else {
      this.jobCatList = result;
    }
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }
  searchQuestionGrop(query: string, index) {
    console.log("query", query);
    let result = this.QuestionFromselect(query, index);
    if (query == "") {
      this.qustGropList[index] = this.questionAreas[index];
    } else {
      this.qustGropList[index] = result;
    }
  }
  QuestionFromselect(query: string, index): string[] {
    let result: string[] = [];
    for (let a of this.questionAreas[index]) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }
  minScrorValidation($event) {
    const entervalue = $event.target.value;
    if (this.formGroup.controls.normalPassingScoreMin.value != "") {
      this.maxScoreDisable = false;
    } else {
      this.maxScoreDisable = true;
    }
  }

  // questionGroupList(index) {
  //   this.masterDataservice.getAllQuestionLevelList(this.page, this.size, this.selectLang).subscribe(res => {
  //     this.qGroup = res;
  //     if (this.qGroup.status == 0) {
  //       console.log(this.qGroup.response);
  //       this.qustGropList[index] = this.qGroup.response.dataSet.filter(d => {
  //         if (this.customQuestionGroupArray.length > 0) {
  //           for (let b of this.customQuestionGroupArray) {
  //             if (b != d.assesmentQuestionId) {
  //               return d;
  //             }
  //           }
  //         } else {
  //           return d;
  //         }

  //       });
  //       console.log(this.qustGropList[index]);
  //       this.questionAreas[index] = this.qustGropList[index];
  //     }
  //   });
  // }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      title: ["", Validators.required],
      languageId: ["", Validators.required],
      jobCategoryId: ["", Validators.required],
      testDescriptionForCompany: ["", Validators.required],
      testDescriptionForCandidate: ["", Validators.required],
      totalNumberOfQuestions: ["", Validators.required],
      maxTimeInMin: ["", Validators.required],
      normalPassingScoreMax: ["", Validators.required],
      normalPassingScoreMin: ["", Validators.required],
      passResultDescription: ["", Validators.required],
      failResultDescription: ["", Validators.required],
      numberOfRetry: ["", Validators.required],
      excellentPassScore: ["", Validators.required],
      excellentPassDescription: ["", Validators.required],
    });

    this.dyanmicQuestionForm = this.fb.group({
      assesmentTestMultiQuestions: new FormArray([]),
    });

    if (this.viewID) {
      this.patchData();
    }
    this.LangListLoad(this.addnewOption);
  }

  removeOption(i) {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e) {
    const numberOfTickets = e;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(
          this.fb.group({
            groupId: null,
            questionIds: [],
          })
        );
      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  // get data from id
  totalElements: boolean = true;
  limitData: number;
  checkStatus: 0;

  patchData() {
    this.firstFormSubmited = true;
    this.masterDataservice.getCompanyDetailsById(this.viewID).subscribe(
      (res) => {
        this.pdata = res;
        if (this.pdata.status == 0) {
   
          // this.formGroup.patchValue(this.pdata.response);
          this.allRecruiterDetails = this.pdata.response;
          this.allRecruiterData = this.pdata.response.companyProfileImage;
          this.rojgarmantraId = this.pdata.response.intrecCompanyId;
          this.companyName = this.pdata.response.companyName;
          this.companyDescription = this.pdata.response.companyDescription;
          this.currentAddress = this.pdata.response.currentAddress;
          this.cityName = this.pdata.response.cityName;
          this.stateName = this.pdata.response.stateName;
          this.postalCode = this.pdata.response.postalCode;
          this.gstNumber = this.pdata.response.gstNumber;
          this.registeredAddress = this.pdata.response.registeredAddress;
          this.website = this.pdata.response.website;
          this.linkedIn = this.pdata.response.linkedIn;
          this.facebook = this.pdata.response.facebook;
          this.instagram = this.pdata.response.instagram;
          this.twitter = this.pdata.response.twitter;
          this.phoneNumber = this.pdata.response.phoneNumber;
          this.email = this.pdata.response.email;
          this.officeAddress = this.pdata.response.officeAddress;
          // this.getPoaDocumentByCompanyIdData(this.pdata.response.infoId);
          // this.getTeamsByCompanyIdData(this.pdata.response.infoId);
          this.getJobByCompanyId(this.pdata.response.companyId);
          this.getTeamMemberById(this.pdata.response.companyId, this.page);
          this.getPlanSubscriptionById(
            this.pdata.response.companyId,
            this.page
          );
          this.getStatisticsById(this.pdata.response.companyId);
          this.infoId = this.pdata.response.infoId;
          this.companyId = this.pdata.response.companyId;
          this.limitData = 6;
          // this.getAllJobsByCompanyIdData(this.infoId ,this.limitData);
        } else {
          this.snackBar.open("response Status -1 ", "", {
            duration: 3000,
            panelClass: ["error-snackbar"],
          });
        }
      },
      (error) => console.log(error)
    );
  }

  getPoaDocumentByCompanyIdData(infoID) {
    this.masterDataservice
      .getPoaDocumentByCompanyId(infoID)
      .subscribe((res) => {
        this.data = res;
        if (this.data.status == 0) {
          this.dataSource = new MatTableDataSource(this.data.response);
        }
      });
  }

  onSubmit(submiType) {
    if (this.formGroup.valid) {
      if (this.viewID) {
        this.jobpostQuestionTestService
          .editJobTestCreationFirst(this.viewID, this.formGroup.value)
          .subscribe(
            (data: any) => {
              if (data.status == 0) {
                this.snackBar.open("Update Successfully !!", "", {
                  duration: 3000,
                  panelClass: ["success-snackbar"],
                });

                this.router.navigate([
                  "/editJobTestCreation",
                  this.viewID,
                  "1",
                ]);
                this.firstFormSubmited = true;
                // this.openContentOne('1');
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = "reload";
                this.router.navigate([
                  "/editJobTestCreation",
                  this.viewID,
                  "1",
                ]);
              } else {
                this.snackBar.open(data.response, "", {
                  duration: 3000,
                  panelClass: ["success-snackbar"],
                });
              }
            },
            (err) => {
              this.snackBar.open(err.error.message, "", {
                duration: 3000,
                verticalPosition: "top", // 'top' | 'bottom'
                horizontalPosition: "center", //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ["error-snackbar"],
              });
            }
          );
      } else {
        this.jobpostQuestionTestService
          .addJobTestCreationFirst(this.formGroup.value)
          .subscribe((res: any) => {
            this.firstCreateData = res;
            if (this.firstCreateData.status == 0) {
              this.viewID = this.firstCreateData.response;
              this.ToatlQuestionNo =
                this.formGroup.controls.totalNumberOfQuestions.value;
              this.snackBar.open(" Successfully !!", "", {
                duration: 3000,
                panelClass: ["success-snackbar"],
              });
              this.router.navigate(["/editJobTestCreation", this.viewID, "1"]);
              this.firstFormSubmited = true;
              // this.openContentOne('1');
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = "reload";
              this.router.navigate(["/editJobTestCreation", this.viewID, "1"]);
            } else {
              this.snackBar.open(this.firstCreateData.response, "", {
                duration: 3000,
                panelClass: ["error-snackbar"],
              });
              this.firstFormSubmited = false;
            }
          });
      }
    } else {
      this.snackBar.open("Please Enter All Details", "", {
        duration: 3000,
        panelClass: ["error-snackbar"],
      });
    }
  }

  getJobByCompanyId(companyId) {
    this.masterDataservice
      .getJobByCompanyId(companyId)
      .subscribe((res: any) => {
        this.data = res;
        if (this.data.status == 0) {
          this.dataSource = new MatTableDataSource(this.data.response);
        }
      });
  }
  getTeamMemberById(companyId, page) {
    this.masterDataservice
      .getTeamMemberId(companyId, page, this.size)
      .subscribe((res: any) => {
        this.data = res;
        if (this.data.status == 0) {
          // this.totalrecordLength1 = this.data.response.totalElements;
          // this.page = this.data.response.number;
          // this.totalpageLenght1 = this.data.response.totalPages;
          this.dataSource1 = new MatTableDataSource(this.data.response.dataSet);
          // this.dataSource1.sort = this.sort;
          // this.dataSource1.paginator = this.paginator;
        }
      });
  }
  getPlanSubscriptionById(companyId, page) {
    this.masterDataservice
      .getPlanSubscriptionById(companyId, page, this.size)
      .subscribe((res: any) => {
        this.data = res;
        if (this.data.status == 0) {
          this.totalrecordLength2 = this.data.response.totalElements;
          this.page = this.data.response.number;
          this.totalpageLenght2 = this.data.response.totalPages;
          this.dataSource2 = new MatTableDataSource(this.data.response.dataSet);
          this.dataSource2.sort = this.sort;
          this.dataSource2.paginator = this.paginator;
        }
      });
  }

  getStatisticsById(companyId) {
    this.masterDataservice
      .getStatisticsById(companyId)
      .subscribe((res: any) => {
        this.data = res;
        if (this.data.status == 0) {
          this.dataSource3 = new MatTableDataSource(this.data.response);
        }
      });
  }

  public downloadPDF(): any {
    // const doc = new jsPDF();
    // doc.addHTML(this.content.nativeElement, function () {
    //   doc.save("obrz.pdf");
    // });
  }
  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }
  maxScrorValidation($event: any): any {
    const entervalue = $event.target.value;
    if (entervalue != "") {
      this.excellentScoreDisable = false;
    } else {
      this.excellentScoreDisable = true;
    }
    if (entervalue != null || entervalue !== "" || entervalue.length < 3) {
      if (entervalue == 0) {
        return false;
      }
      if (
        Number(this.formGroup.controls.normalPassingScoreMin.value) <
        Number(entervalue)
      ) {
        this.pererror = false;
        this.excellentScoreDisable = false;
        this.formGroup.controls.normalPassingScoreMax.setErrors(null);
      } else {
        this.pererror = true;
        this.excellentScoreDisable = true;
        this.formGroup.controls.normalPassingScoreMax.setErrors({
          incorrect: true,
        });
      }
    }
  }
  ExcellentPassScoreValidation($event: any): any {
    const entervalue = $event.target.value;
    if (entervalue != null || entervalue !== "" || entervalue.length < 3) {
      console.log(this.formGroup.controls.normalPassingScoreMax.value);
      console.log(entervalue);
      if (
        Number(this.formGroup.controls.normalPassingScoreMax.value) <=
        Number(entervalue)
      ) {
        console.log(this.excellentPassScoreError);
        this.excellentPassScoreError = false;
        this.formGroup.controls.excellentPassScore.setErrors(null);
      } else {
        console.log(this.excellentPassScoreError);
        this.excellentPassScoreError = true;
        this.formGroup.controls.excellentPassScore.setErrors({
          incorrect: true,
        });
      }
    }
  }
  optionCreate(e): any {
    console.log(e);
  }
  newOptionadd(): any {
    console.log(this.addnewOption);
    // this.logSelection(this.addnewOption - 1);
    // this.questionGroupList(this.addnewOption);
    // if(this.addnewOption < 5){
    this.addnewOption = this.addnewOption + 1;
    this.LangListLoad(this.addnewOption);
    // this.questionGroupList(this.addnewOption);
    this.addOptionButtonHide = false;
  }
  onSubmitQuestion(e): any {
    // this.logSelection(this.addnewOption - 1);
    console.log(this.dyanmicQuestionForm.value);
    if (this.ToatlQuestionNo == this.checkboxSelectedCount) {
      this.isTestCreateSuccess = true;
    } else {
      this.isTestCreateSuccess = false;
    }

    if (this.ToatlQuestionNo < this.checkboxSelectedCount) {
      this.snackBar.open("question Count not match", "", {
        duration: 3000,
        panelClass: ["error-snackbar"],
      });
      return;
    }

    if (this.viewID) {
      this.jobpostQuestionTestService
        .UpdateTestCreationQuestion(
          this.viewID,
          this.dyanmicQuestionForm.value,
          this.isTestCreateSuccess
        )
        .subscribe((res) => {
          this.createTestData = res;
          if (this.createTestData.status == 0) {
            this.dsdsdsdsdsdsdsd = [];
            if (e == 1 || e == "1" || e == 2 || e == "2") {
              const d =
                e == "1" ? "test Created Successfully" : "draft Successfully";
              this.snackBar.open(d, "", {
                duration: 3000,
                panelClass: ["success-snackbar"],
              });
              this.router.navigate(["/listJobTestCreation", this.selectLang]);
            }
          } else {
            this.snackBar.open("Something went Wrong server status -1", "", {
              duration: 3000,
              panelClass: ["success-snackbar"],
            });
          }
        });
    } else {
      this.jobpostQuestionTestService
        .addQuestionCreateTest(
          this.viewID,
          this.dyanmicQuestionForm.value,
          this.isTestCreateSuccess
        )
        .subscribe((res) => {
          this.createTestData = res;
          if (this.createTestData.status == 0) {
            if (e == 1 || e == "1") {
              this.snackBar.open("test Created Successfully", "", {
                duration: 3000,
                panelClass: ["error-snackbar"],
              });
              this.router.navigate(["/listJobTestCreation", this.selectLang]);
            }
          } else {
            this.snackBar.open("Something went Wrong server status -1", "", {
              duration: 3000,
              panelClass: ["success-snackbar"],
            });
          }
        });
    }
  }

  isAllSelected(index) {
    const numSelected = this.selection[index].selected.length;
    const numRows = this.dataSource[index].data.length;
    return numSelected === numRows;
  }

  jobcatSelection(e) {
    this.jobpostQuestionTestService
      .jobcatgorySelection(e.value, this.selectLang)
      .subscribe((res) => {
        this.Jcat = res;
        if (this.Jcat.status == 0) {
          if (this.Jcat.response == true) {
            this.formGroup.controls.jobCategoryId.setErrors({
              incorrect: true,
            });
            this.JobQuniError = true;
            this.snackBar.open(
              "You have already Created a Asssement Test in this category - You cant create More than one Test in One SubCategory",
              "",
              {
                duration: 3000,
                panelClass: ["error-snackbar"],
              }
            );
          } else {
            this.formGroup.controls.jobCategoryId.setErrors(null);
            this.JobQuniError = false;
          }
        } else {
          this.formGroup.controls.jobCategoryId.setErrors(null);
          this.JobQuniError = false;
          this.snackBar.open("response Status -1 ", "", {
            duration: 3000,
            panelClass: ["error-snackbar"],
          });
        }
      });
  }

  demo(e) {
    const d = e.target.value;
    // console.log(d);
    // if(d > 0) return false;
  }
  openContentOne(e) {
    this.selectedTab = e;
  }

  imageQuestionPreview(element): any {
    const dialogRef = this.dialog.open(ImageQuestionPopupComponent, {
      width: "800px",
      height: "auto",
      data: {
        question: element.question,
        qImage: element.questionImage,
      },
      panelClass: "defaultClass",
    });
    dialogRef.afterClosed().subscribe((sresult) => {
      // console.log(sresult);
      if (sresult == true) {
      }
    });
  }

  rejectStatus(elements) {
    if (confirm("Are you sure document reject ?")) {
      this.masterDataservice
        .documentStatusChangedById(elements.id, "rejected")
        .subscribe((res) => {
          this.data = res;
          if (this.data.status == 0) {
            this.getPoaDocumentByCompanyIdData(elements.companyId);
            this.snackBar.open("Document Rejected Successfully", "", {
              duration: 3000,
              panelClass: ["success-snackbar"],
            });
          } else {
            this.snackBar.open("response Status -1 ", "", {
              duration: 3000,
              panelClass: ["error-snackbar"],
            });
          }
        });
    } else {
    }
  }

  acceptStatus(elements) {
    if (confirm("Are you sure document approved ?")) {
      this.masterDataservice
        .documentStatusChangedById(elements.id, "approved")
        .subscribe((res) => {
          this.data = res;
          if (this.data.status == 0) {
            this.getPoaDocumentByCompanyIdData(elements.companyId);
            this.snackBar.open("Document Approved Successfully", "", {
              duration: 3000,
              panelClass: ["success-snackbar"],
            });
          } else {
            this.snackBar.open("response Status -1 ", "", {
              duration: 3000,
              panelClass: ["error-snackbar"],
            });
          }
        });
    } else {
    }
  }

  documentpopup(element): any {
    if (element.verifytatus == "pending") {
      this.pendingStatus = true;
    } else {
      this.pendingStatus = false;
    }

    if (element.verifytatus == "approved") {
      this.approvedStatus = true;
    } else {
      this.approvedStatus = false;
    }

    if (element.verifytatus == "rejected") {
      this.rejectedStatus = true;
    } else {
      this.rejectedStatus = false;
    }
    const d = "Are You sure you want to approve & rejected document?";
    if (confirm(d)) {
      const fileExtension = element.companyDocument.substr(
        element.companyDocument.lastIndexOf(".") + 1
      );
      if (fileExtension == "pdf") {
        this.imageDivShow = false;
        this.iFrameShow = true;
      } else {
        this.imageDivShow = true;
        this.iFrameShow = false;
      }
      const dialogRef = this.dialog.open(DocumentPopupComponent, {
        width: "800px",
        height: "auto",
        data: {
          id: element.id,
          companyDocument: element.companyDocument,
          companyId: this.rojgarmantraId,
          isPending: this.pendingStatus,
          isApproved: this.approvedStatus,
          isRejected: this.rejectedStatus,
          iFrameShow: this.iFrameShow,
          imageDivShow: this.imageDivShow,
        },
        panelClass: "defaultClass",
      });
      dialogRef.afterClosed().subscribe((sresult) => {
        if (sresult == true) {
          //this.getPoaDocumentByCompanyIdData();
        }
      });
    }
  }

  getTeamsByCompanyIdData(infoId) {
    this.masterDataservice
      .getTeamsByCompanyIdData(infoId)
      .subscribe((res: any) => {
        if (res.status == 0) {
          this.teamDetails = res.response.filter((d) => {
            if (d.id != this.viewID) {
              return d;
            }
          });
        }
      });
  }

  getMoreRecords(limitData) {
    this.limitData = this.limitData + limitData;
    console.log(this.limitData);
    this.getAllJobsByCompanyIdData(this.infoId, this.limitData);
  }

  private getAllJobsByCompanyIdData(infoId, limitData) {
    this.masterDataservice
      .getAllJobsByCompanyId(infoId, 0, limitData)
      .subscribe((res: any) => {
        if (res.status == 0) {
          this.jobsDetails = "";
          this.jobsDetails = res.response.dataSet;
          this.totalElements = res.response.totalElements;
        }
      });
  }
}
