import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MasterDataService } from "../../../../service/masterData/master-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from 'src/app/service/language/language.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-update-job-subcategory',
  templateUrl: './add-update-job-subcategory.component.html',
  styleUrls: ['./add-update-job-subcategory.component.scss']
})
export class AddUpdateJobSubcategoryComponent implements OnInit {
  jobCategoryId: any;
  categoryList: Observable<any[]>;
  private roleData: any;
  categoryList1: [];
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList = [];
  dynamicForm: FormGroup;
  submitted = false;
  pdata: any;
  apps = [];
  appCtrl: FormControl;
  filterArrayLength = 21;
  SelectAutoSelectID: any;

  constructor(public langService: LanguageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router) {
    this.appCtrl = new FormControl();
  }

  ngOnInit() {
    this.jobCategoryId = this.route.snapshot.params['id'];
    this.getAllCategoryList();
    this.dynamicForm = this.formBuilder.group({
      jobCategoryIcon:[''],
      parentId: 0,
      multiLanguageNames: new FormArray([]),
      appCtrl: new FormControl(),
  
    });
    this.LangList = (JSON.parse(this.langService.getLngList())).sort((a, b) => (a.languageId - b.languageId));
    console.log(this.LangList);
    this.LangListLoad(this.LangList.length);
    // if(this.jobCategoryId){
    //   this.patchForm();
    // }
    // for (let i = 0; i < this.payloaddd.multiLanguageNames.length; i++) {           
    //     this.dynamicForm.patchValue(this.payloaddd.multiLanguageNames);        
    //  }
  }

  // convenience getters for easy access to form fields
  get f() { return this.dynamicForm.controls; }
  get t() {
    //  console.log(this.f.multiLanguageNames);
    return this.f.multiLanguageNames as FormArray;
  }

  patchForm() {
    this.langService.getJobCategoryByIdForEdit(this.jobCategoryId).subscribe(res => {
      this.pdata = res;
      if (this.pdata.status == 0) {
        const patchsortdat = this.pdata.response.multiLanguageNames.sort((a, b) => (a.languageId - b.languageId))

        console.log(this.pdata);
        console.log(this.apps)
        for (let b of this.apps) {
          if (b.parentId == this.pdata.response.parentId) {
            console.log(b)

            // this.appCtrl.patchValue(b.displayName);
            // this.SelectAutoSelectID = b
            // console.log(b)
            if (b.parentId != this.pdata.response.jobcategoryId) {
              this.SelectAutoSelectID = b.parentId;
              this.appCtrl.patchValue(b.displayName);
            }

          }
        }
        this.dynamicForm.patchValue({
          jobCategoryIcon: this.pdata.response.jobCategoryIcon,
        });
        this.dynamicForm.patchValue({
          parentId: this.pdata.response.parentId == this.pdata.response.jobcategoryId ? 0 : this.pdata.response.parentId,
          multiLanguageNames: patchsortdat,
        });
        console.log(this.dynamicForm.value)
        //  this.appCtrl.patchValue(this.pdata.response.parentName);

        // this.dynamicForm.controls.appCtrl.setValue(this.settingDetail.defaultDealerId);
        // if(this.pdata.response.parentId != 0){

        // }     
        // this.parentDropShow = true;
        // this.isActive = true;
      } else {
        this.snackBar.open('response Status -1 ', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  LangListLoad(e) {
    const numberOfTickets = e
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.formBuilder.group({
          languageId: [this.LangList[i].languageId],
          name: ['', Validators.required],
          // categoryDescription: [ '' , Validators.required],                
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);   
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }
  getAllCategoryList() {
    let dd = [];
    this.masterService.getAllCategoryList(0, 500, 'en' , true).subscribe(res => {
      this.roleData = res;
      if (this.roleData.status == 0) {
        console.log()
        dd = this.roleData.response.dataSet.filter((d) => {
          if (d.isSubCategory == false) {
            return d;
          }
        });
        this.apps = dd;
        console.log(this.apps)
        this.categoryList = this.appCtrl.valueChanges
          .pipe(
            startWith(''),
            map(app => app ? this.filterApps(app) : this.apps.slice())
          );
        console.log(this.categoryList)
        if (this.jobCategoryId) {
          this.patchForm();
        }
        // this.categoryList = this.roleData.response.dataSet;
        // console.log(this.categoryList);
      } else {
        this.snackBar.open('response Status -1 ', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    })
  }
  // parentSubSelection(e){
  //   console.log(e);
  //   const d = e.checked;
  //   if(d == true){
  //     this.parentDropShow = true;
  //   } else {
  //     this.parentDropShow = false;
  //   }
  // }

  onSubmit() {
    this.submitted = true;
    console.log(this.dynamicForm.value.multiLanguageNames.sort((a, b) => (a.languageId - b.languageId)));
    // console.log(JSON.stringify(this.dynamicForm.value, null, 4));
    // this.dynamicForm.controls['parentId'].setValue(this.SelectAutoSelectID);
    if (this.dynamicForm.valid) {
      if (this.jobCategoryId) {
        this.masterService.updateCategoryDeatils(this.jobCategoryId, this.dynamicForm.value)
          .subscribe((res: any) => {
            if (res.status == 0) {
              this.snackBar.open('Job Category Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/listjobsubcategory']);
            } else {
              this.snackBar.open(res.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            }

          },
            (err) => {
              this.snackBar.open(err.error.message == '' ? 'server Error' : err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else {
        this.masterService.createCategory(this.dynamicForm.value).subscribe(res => {
          this.data = res;
          if (this.data.status == 0) {
            this.snackBar.open('Job Category Inserted Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listjobsubcategory']);
          }
          else {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });
      }

    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

    console.log(JSON.stringify(this.dynamicForm.value, null, 4))
    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.dynamicForm.value, null, 4));
  }

  onReset() {
    // reset whole form back to initial state
    this.submitted = false;
    this.dynamicForm.reset();
    this.t.clear();
  }

  onClear() {
    // clear errors and reset ticket fields
    this.submitted = false;
    this.t.reset();
  }

  onEnter(e: any) {
    // console.log(e);
    this.SelectAutoSelectID = e.parentId;

    this.dynamicForm.controls['parentId'].setValue(e.parentId);
  }
  filterApps(name: any) {
    console.log(name);
    if (name == '') {
      return this.apps;
    } else {
      return this.apps.filter(p => p.displayName.indexOf(name.toLowerCase()) === 0)
    }
    ;
  }
  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

}
