<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!viewId">Add </span>
        <span *ngIf="viewId">Edit </span> Competency Category
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listCompetencyCategory']"> List Competency Category</li>
        <li class="breadcrumb-item active">
          <span *ngIf="!viewId">Add </span>
          <span *ngIf="viewId">Edit </span>
          Competency Category
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="dynamicForm" appInvalidControlScroll>
        <div class="row">
          <div class="form-group row col-sm-12 checkDiv">
            <label class="col-sm-12">Select Parent Category</label>
            <div class="col-sm-12 ">
              <!-- [ngClass]="{ 'is-invalid': submitted && dynamicForm.get('parentId').errors }" required -->
              <mat-select 
              [disabled]="dynamicForm.get('parentId').value == 0 || dynamicForm.get('parentId').value == '0'"
              (openedChange)="myInput.focus()" (closed)="myInput.value='';search(myInput.value)"
                (selectionChange)="onEnter($event)" formControlName="parentId"
              
                placeholder="Please select job category" class="form-control">
                <input class="myInput form-control" #myInput focused="'true'" type="text"
                (keydown)="$event.stopPropagation()"  
                (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                  <mat-option value="0">NA</mat-option>
                <mat-option (onSelectionChange)="onEnter(r)" *ngFor="let r of categoryList"
                  [value]="r.competencyCategoryId" [aria-label]="r.displayName">
                  {{r.displayName}}
                </mat-option>
              </mat-select>

              <!-- <input #searchInput=""
              class="form-control"
                aria-label="Apps" [matAutocomplete]="auto"
                                   [formControl]="appCtrl"
                                   placeholder="Please Select Parent Catogery"
                                   [(ngModel)]="SelectAutoSelectID"                              >
                                  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                  >
                                    <mat-option (onSelectionChange)="onEnter(r)"
                                     *ngFor="let r of categoryList | async"
                                     [value]="r.displayName" [aria-label]="r.displayName"
                                    >
                                     {{r.displayName}}
                                    </mat-option>
                                  </mat-autocomplete>     -->
            </div>
          </div>
          <div *ngFor="let ticket of t.controls; let i = index" class="form-group col-sm-12">
            <div [formGroup]="ticket" class="col-sm-12" style="width: 100%;">
              <div class="form-group col-sm-12">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Competency Category Name </label>
                <div class="col-sm-12 ">
                  <input 
                  [ngClass]="{ 'is-invalid': submitted && ticket.get('name').errors }"
                  type="text" class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                    placeholder="Enter Competency Category name" name="name" formControlName="name" required>

                </div>
              </div>

              <div class="form-group col-sm-12">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Competency Category Description </label>
                <div class="col-sm-12 ">
                  <!--                <input  type="text"-->
                  <!--                        class="form-control"-->
                  <!--                        value="" placeholder="Enter Competency Category Description" name="descriptionName"-->
                  <!--                        formControlName="descriptionName" required>-->
                  <angular-editor formControlName="descriptionName" required
                  [ngClass]="{ 'is-invalid': submitted && ticket.get('descriptionName').errors }"
                  [config]="config"
                    placeholder="Please Enter Category Description" name="descriptionName"></angular-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()"
            *ngIf="!viewId">Submit</button>
          <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()"
            *ngIf="viewId">Update</button>
        </div>

      </form>





    </div>
  </div>

</div>