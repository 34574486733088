import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonConfirmPopupComponent } from 'src/app/component/common-confirm-popup/common-confirm-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-user-policy',
  templateUrl: './user-policy.component.html',
  styleUrls: ['./user-policy.component.scss']
})
export class UserPolicyComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number;
  size: number;
  langList = [];
  selectLang: any;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  id: any;
  privacyPolicyId: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private masterDataservice: MasterDataService
  ) {
    this.appCtrl = new FormControl();
  }

  ngOnInit(): void {
    this.privacyPolicyId = this.route.snapshot.params['id'];
    this.page = 0;
    this.size = 500;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.displayedColumns = [
      'srno',
      'policyType',
      'userType',
      'actions'
    ];
    this.tabelLoad(this.page);
  }

  tabelLoad(page): any {
    this.masterDataservice.getAllPolicyList(page, this.size).subscribe(
      (res) => {
        this.data = res;
        if (this.data.status == 0) {
          this.totalrecordLength = this.data.response.dataSet;
          this.page = this.data.response.number;
          this.totalpageLenght = this.data.response.totalPages;
          this.dataSource = new MatTableDataSource(this.data.response.dataSet);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } else {
          this.snackBar.open('server status -1', '', {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        }
      },
      (error) => {
        this.snackBar.open(error.error.message, '', {
          duration: 3000,
          panelClass: ['error-snackbar'],
        });
      }
    );
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  delete(rowid: number) {
    if (rowid > -1) {
      this.dataSource.data.splice(rowid, 1);
      this.dataSource._updateChangeSubscription();
    }
  }

  // LangChange(e){
  //   this.selectLang= e.value;
  //   this.tabelLoad(this.page, this.selectLang);
  // }
  changeStatus(e, element) {
    var msg;
    if (element.status) {
      msg = 'Are you sure wants to Deactivate status?';
    } else {
      msg = 'Are you sure wants to Activate status?';
    }
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: msg,
        type: 'confirmation',
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        this.masterDataservice.planStatuChange(element.id).subscribe((res) => {
          this.data = res;
          if (this.data.status == 0) {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
            this.tabelLoad(this.page);
          }
        });
      } else {
        e.source.checked = element.status;
      }
    });
  }
  filterApps(name: any) {
    const filterValue = name.toLowerCase();
    if (name == '') {
      return this.apps;
    } else {
      return this.apps.filter((p) =>
        p.name.toLowerCase().includes(filterValue)
      );
    }
  }
  onEnter(e: any) {
    this.selectproduct = e.name;
    this.selectedId = e.id;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search() {
    this.router.navigate(['editEmployerPlan', this.selectedId]);
  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage() {
    this.tabelLoad(0);
  }
  lastpage() {
    this.tabelLoad(this.totalpageLenght);
  }
  prevpage() {
    const d = this.page - 1;
    if (d > 0) {
      this.tabelLoad(d);
    }
  }
  nextpage() {
    const d = this.page + 1;
    if (d < this.totalpageLenght) {
      this.tabelLoad(d);
    }
  }

  deletePolicy(privacyPolicyId) {
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: 'Are you sure wants to delete?',
        type: 'confirmation',
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        this.masterDataservice
          .deletePolicy(privacyPolicyId)
          .subscribe((res) => {
            this.data = res;
            if (this.data.status == 0) {
              this.snackBar.open('Deleted Successfully !', '', {
                duration: 3000,
                panelClass: ['success-snackbar'],
              });
              this.tabelLoad(this.page);
            }
          });
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
