import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JobTestQuestionManageService } from 'src/app/service/jobPostTestandQuestion/job-test-question-manage.service';
import { LanguageService } from 'src/app/service/language/language.service';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { CommonConfirmPopupComponent } from '../../common-confirm-popup/common-confirm-popup.component';
import { CopyButtonPopupComponent } from '../copy-button-popup/copy-button-popup.component';
@Component({
  selector: 'app-list-job-post-test',
  templateUrl: './list-job-post-test.component.html',
  styleUrls: ['./list-job-post-test.component.scss']
})
export class ListJobPostTestComponent implements OnInit {

  displayedColumns: string[];
  PublishdisplayedColumns: string[];
  dataSource = new MatTableDataSource();
  PublishDataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList = [];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number = 0;
  size: number = 30;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId: any;
  LangList = [];
  selectLang: any;
  PublishSelectLang: any;
  selectedTab: number = 0;

  publishpage: number = 0;
  publishSize: number = 30;
  publishTotalrecordLength: number;
  publishTotalpageLenght: number;

  publishSerchText : string = '';


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('sorter2', { static: true }) sorter2: MatSort;
  @ViewChild('PublishPaginator', { static: true }) PublishPaginator: MatPaginator;
  PData: any;
  testCurrentPage: number = 0;
  publishCurrentPage: number = 0;
 

  selectedAreas: Array<any> = [];
  searchQuestionText: any = '';
  jobCatList = [];
  qType: any;
  selectJobcategoryId:any = '';
  selectPUBLISHJobcategoryId: any = '';

  constructor(private router: Router,
    private snackBar: MatSnackBar,
    public langService: LanguageService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private masterDataservice: MasterDataService,
    private jobpostQuestionTestService: JobTestQuestionManageService) { this.appCtrl = new FormControl(); }

  ngOnInit(): void {
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.LangList = JSON.parse(this.langService.getLngList());
    // this.selectLang = this.LangList[0].languageCode;

    this.selectLang = this.route.snapshot.params['lang'];
    this.PublishSelectLang = this.selectLang;
    this.questionTypeList(this.selectLang);
    this.displayedColumns = ['assesementTestId', 'parentCatgoryname','jobcategoryName', 'title',
      'totalNumberOfQuestions', 'maxTimeInMin', 'numberOfRetry',
      'normalPassingScoreMax', 'createdEpochTime',
      'isTestCreateSuccess', 'isActive', 'actions'];

    this.PublishdisplayedColumns = ['assesementTestId', 'jobcategoryName', 'title',
      'totalNumberOfQuestions', 'maxTimeInMin', 'numberOfRetry',
      'normalPassingScoreMax', 'createdEpochTime', 'isActive', 'actions'];
    // this.tabelLoad(this.page, this.selectLang);
  }


  tabelLoad(page, lang): any {
    
    this.jobpostQuestionTestService.getAlljobTestListAdvacnfilter(page, this.size, lang, this.selectJobcategoryId, this.searchQuestionText , 'UNPUBLISHED').subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.totalPages;
        this.totalpageLenght = this.data.response.totalElements;
        this.testCurrentPage = res.response.number;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        //   this.apps = this.data.response.dataSet;
        //   this.filteredApps = this.appCtrl.valueChanges .pipe(
        //     startWith(''),
        //     map(app => app ? this.filterApps(app) : this.apps.slice())
        //   );
        // } else {
        //   this.snackBar.open('server status -1', '', {
        //     duration: 3000,
        //     panelClass: ['error-snackbar']
        //   });
      }
    })


  }

  applyFilter(filterValue: string): any {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // delete(rowid: number) {
  //   if (rowid > -1) {
  //     this.dataSource.data.splice(rowid, 1);
  //     this.dataSource._updateChangeSubscription();
  //   }
  // }
  oldpermissionChange(e, element): any {
    if (element.isTestCreateSuccess == true) {
      if (element.isActive == true) {
        if (confirm('Deactive Are you sure')) {
          this.jobpostQuestionTestService.testStatusChnage(element.assesementTestId).subscribe((data) => {
            // @ts-ignore
            if (data == 'error') {
              e.source.checked = true;
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });
            } else {
              e.source.checked = false;
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.tabelLoad(0, this.selectLang);
            }
          });
          console.log(e.source.checked);
        } else {
          e.source.checked = true;
        }
      } else {
        if (confirm('Active Are you sure')) {
          this.jobpostQuestionTestService.testStatusChnage(element.assesementTestId).subscribe((data) => {
            // @ts-ignore
            if (data === 'error') {
              e.source.checked = false;
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['error-snackbar']
              });

            } else {
              e.source.checked = true;
              this.tabelLoad(0, this.selectLang);
              this.snackBar.open(data.response, '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });

            }
          });
        } else {
          e.source.checked = false;
        }
      }
    } else {

    }



  }
  filterApps(name: any): any {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if (name == '') {
      return this.apps;
    } else {
      return this.apps.filter(p => p.jobcategoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any): any {
    // console.log(e);
    this.selectproduct = e.jobcategoryName;
    this.selectedId = e.jobIndustryId;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  // search(): any{
  //   this.router.navigate(['editIndusrty', this.selectedId]);

  // }
  // keyDownFunction(event): any {
  //   if (event.keyCode === 13) {
  //     this.search();
  //   }
  // }

  firstpage(): any {
    this.tabelLoad(0, this.selectLang);
  }
  lastpage(): any {
    this.tabelLoad(this.totalpageLenght, this.selectLang);
  }
  prevpage(): any {
    const d = this.page - 1;
    if (d > 0) {
      this.tabelLoad(d, this.selectLang);
    }
  }
  nextpage(): any {
    const d = this.page + 1;
    if (d < this.totalpageLenght) {
      this.tabelLoad(d, this.selectLang);
    }
  }


  delete(id): any {
    if (confirm(' Are you sure ?')) {
      this.jobpostQuestionTestService.deleteTest(id)
        .subscribe(res => {
          this.data = res;
          if (this.data.status == 0) {
            this.tabelLoad(0, this.selectLang);
            this.snackBar.open('deleted succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          } else {
            this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }, error => console.log(error));
    }

  }
  LangChange(e): any {
    console.log(e);
    this.selectLang = e.value;
    this.questionTypeList(this.selectLang);
    // this.tabelLoad(0, this.selectLang);
  }

     copyTestpopup(element): any {
    const d = 'Are You sure you want to Copy the Test ' + element.title + ' ?';
    if (confirm(d)) {
      const dialogRef = this.dialog.open(CopyButtonPopupComponent, {
        width: '800px',
        height: 'auto',
        data: {
          TestId: element.assesementTestId,
          title: element.title,
          jobCategory: element.jobcategoryName,
          lang: this.selectLang,
        },
        panelClass: 'defaultClass',
      });
      dialogRef.afterClosed().subscribe(sresult => {
        // console.log(sresult);
        if (sresult == true) {
          this.tabelLoad(0, this.selectLang);
        }
      });
    }
  }
  tabClick(tab): any {
    console.log(tab.index);
    this.selectedTab = tab.index;
    if (tab.index == 1) {
      this.publisTestList(0, this.PublishSelectLang);
    } else {
      this.tabelLoad(0, this.selectLang);
    }
  }
  publisTestList(page, PublishSelectLang): any {
    if (this.selectedTab == 1) {
      this.jobpostQuestionTestService.GetAllPublishTestList(page, this.publishSize, PublishSelectLang , this.selectPUBLISHJobcategoryId , this.publishSerchText).subscribe(res => {
        this.PData = res;
        if (this.PData.status == 0) {
          this.publishTotalrecordLength = res.response.totalElements;
          this.publishpage = res.response.totalPages;
          this.publishTotalpageLenght = res.response.totalElements;
          this.publishCurrentPage = res.response.number;
          this.PublishDataSource = new MatTableDataSource(this.PData.response.dataSet);
          // this.PublishDataSource.sort = this.sorter2;
          // this.PublishDataSource.paginator = this.PublishPaginator;
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      });
    }

  }
  publishTest(id): any {
    if (confirm(' Are you sure you want to publish test ?')) {
      this.jobpostQuestionTestService.publishTestById(id).subscribe((res: any) => {
        if (res.status == 0) {
          this.selectedTab = 1;
          this.publisTestList(0, this.PublishSelectLang);
        } else {
          this.snackBar.open(res.response, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      })
    }
    // const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
    //   width: '600px',
    //   height: 'auto',
    //   data: {
    //     id:
    //   },
    //   panelClass: 'defaultClass',
    // });
    // dialogRef.afterClosed().subscribe(sresult => {
    //   // console.log(sresult);
    //   if (sresult == true){
    //     // this.tabelLoad(this.page, this.selectLang);
    //   }
    // });
  }

  PublishLangChange(e) {
    console.log(e);
    this.PublishSelectLang = e.value;
    this.publisTestList(0, this.PublishSelectLang);
  }
  publishDelete(id): any {
    console.log(id);
    if (confirm(' Are you sure ?')) {
      this.jobpostQuestionTestService.deletePublishTest(id)
        .subscribe(res => {
          this.data = res;
          if (this.data.status == 0) {
            this.tabelLoad(0, this.selectLang);
            this.publisTestList(0, this.PublishSelectLang);
            this.snackBar.open('deleted succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          } else {
            this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }, error => console.log(error));
    }

  }

  onTestChangePage(e) {
    this.publishpage = e.pageIndex;
    this.publisTestList(e.pageIndex, this.PublishSelectLang);
  }
  ngAfterViewInit() {
    this.tabelLoad(this.page, this.selectLang);
    this.publisTestList(this.publishpage, this.PublishSelectLang);

  }
  onChangePage(e) {
    console.log(e);
    this.page = e.pageIndex;
    this.tabelLoad(e.pageIndex, this.selectLang);
    // this.tabelLoad(e.pageIndex , e.pageSize , this.selectLang);
  }
  questionTypeList(selectLang): any {
    this.masterDataservice.getAllCategoryList(this.page, this.size, selectLang , true).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {
        this.jobCatList = this.qType.response.dataSet.filter((d) => {
          // && d.isTestCreated == false
          if (d.isSubCategory == true) {
            return d;
          }
        });       

        this.selectedAreas = this.jobCatList;
      }
    });
  }

  search(query: string): any {
    const se = query.toLowerCase();
    let result = this.select(se);
    if (query == '') {
      this.jobCatList = this.selectedAreas;
    } else {
      this.jobCatList = result;
    }
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
  submit() {
    this.tabelLoad(0, this.selectLang);
  }
  reset(){
    this.selectLang = 'en';
    this.selectJobcategoryId = '';
     this.searchQuestionText = '';
    this.tabelLoad(0, this.selectLang);
  }
  publishtestFiltersubmit(){
    this.publisTestList(0, this.PublishSelectLang);
  }
  publishReset(){
    this.PublishSelectLang = 'en';
    this.selectPUBLISHJobcategoryId = '';
    this.publishSerchText = '';
    this.publisTestList(0, this.PublishSelectLang); 
  }
  publishLangChange(e){
    console.log(e);
    this.PublishSelectLang = e.value;
    this.questionTypeList(this.PublishSelectLang);
  }
}
