<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;" >
      <div class="page-header px-0">
        <h3 class="page-title"><span  *ngIf="!competencyCategoryId">Add </span>
          <span  *ngIf="competencyCategoryId">View </span> Competency  Keyword</h3>
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
          <li class="breadcrumb-item " [routerLink]="['/listCompetancyKeywords']" > List Competency  Keyword</li>
          <li class="breadcrumb-item active" >
            <span  *ngIf="!competencyCategoryId">Add </span>
            <span  *ngIf="competencyCategoryId">View </span>
            Competency  Keyword </li>
        </ol>
      </div>
    </div>
    <!--Page Header-->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="formGroup">
        <div class="form-group row">
          <label for="example-text-input" class="col-sm-3 col-form-label text-right colonclass">Keywords </label>
          <div class="col-sm-9 ">
            <mat-form-field appearance="outline">
              <mat-label>Keywords </mat-label>
              <textarea matInput value=""  name="keywords"
                        formControlName="keywords" style="height:300px" readonly></textarea>
            </mat-form-field>
          </div>
        </div>
        <a [routerLink]="['/listCompetancyKeywords']" type="submit" class="btn btn-warning" >Back</a>
      </form>




    </div>
  </div>

</div>
