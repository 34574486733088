import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LanguageService} from "../../../service/language/language.service";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import { MatDialog } from '@angular/material/dialog';
import {CommonConfirmPopupComponent} from 'src/app/component/common-confirm-popup/common-confirm-popup.component';
import {PaymentService} from '../../../service/payment/payment.service';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  data;
  page : number;
  size: number;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private masterData :MasterDataService,
    public langService :LanguageService,
    public dialog: MatDialog,
    public paymentService:PaymentService) { }

 ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.displayedColumns = ['srno', 'paymentGatewayName','paymentGatewayShortName', 'description','status','actions'];
    this.tabelLoad();
  }
  tabelLoad(){
    this.paymentService.getPaymentList(this.page,this.size).subscribe((res:any) =>{
      this.data = res;
      if(this.data.response){
        this.dataSource = new MatTableDataSource(this.data.response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteJobPlanDetails(id) {
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: "Are you sure wants to delete?",
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.paymentService.deletePayment(id).subscribe(res => {
          this.data = res;
          if(this.data.status == 0 ){
            this.tabelLoad();
            this.snackBar.open('Payment deleted succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          } else {
            this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }, error => console.log(error));
      }
    });
  }
  changeStatus(e,element):any{
    var msg;
    if(element.isActive){
      msg = 'Are you sure wants to Deactivate status?'
    } else{
      msg = 'Are you sure wants to Activate status?'
    }
    const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        message: msg,
        type: 'confirmation'
      },
      panelClass: 'confirmpopup',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == true){
        this.paymentService.changeStatusPayment(element.paymentGatewayId).subscribe( res =>{
          this.data = res;
          if(this.data.status == 0 ){
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.tabelLoad();
          }
        })
      } else{
        e.source.checked= element.isActive;
      }
    });
  }
}
