import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonConfirmPopupComponent } from 'src/app/component/common-confirm-popup/common-confirm-popup.component';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-candidate-membership',
  templateUrl: './candidate-membership.component.html',
  styleUrls: ['./candidate-membership.component.scss']
})
export class CandidateMembershipComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList = [];
  prdlist = [];
  private data: any;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page: number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  cityList: any = [];
  cityListClone: any = [];
  jobCategoryList: any = [];
  jobCategoryListClone: any = [];
  jobCategoryId: any;
  cityId: any;
  apiResponse: any = [];
  statusList: any;
  subscriptionStatus: any = 'Active';
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private masterDataservice: MasterDataService
  ) {}

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.displayedColumns = [
      'srno',
      'companyName',
      'planName',
      'startDate',
      'expiryDate',
      'subscriptionStatus',
    ];
    this.statusList = [
      { name: 'Active', value: 'Active' },
      { name: 'Expired', value: 'Expired' },
    ];
    this.searchForm = this.formBuilder.group({
      downloadFile: ["CSV"]
    });
    this.getAllCandidateMembership();
    // this.byDefulat(event);
  }

  downloadFile():any{
    window.location.href = `${environment.baseurl1}/v1/companySubscription/exportCandidateMembershipListExcel?extension=`+this.searchForm.value.downloadFile + `&subscriptionStatus=` + this.subscriptionStatus;
  }

  byDefulat(event): any {
    event = 'Active';
    if (event === 'Active') {
      this.chnageStatus(event);
    }
  }
  chnageStatus($event: any): any {
    const filterData = _.filter(this.apiResponse, (item) => {
      return (
        item.subscriptionStatus.toLowerCase() === $event.value.toLowerCase()
      );
    });
    this.masterDataservice
      .getAllCandidateMembership1(this.subscriptionStatus)
      .subscribe((res) => {
        this.apiResponse = res.response;
        this.totalrecordLength = res.response.totalElements;
        this.page = res.response.number;
        this.totalpageLenght = res.response.totalPages;
        this.dataSource = new MatTableDataSource(res.response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  getAllCandidateMembership(): any {
    this.masterDataservice.getAllCandidateMembership().subscribe((res) => {
      this.data = res;
      if (this.data.status == 0) {
        this.totalrecordLength = this.data.response.totalElements;
        this.page = this.data.response.number;
        this.totalpageLenght = this.data.response.totalPages;
        this.dataSource = new MatTableDataSource(this.data.response);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Job');

    /* save to file */
    XLSX.writeFile(wb, 'Recruiter Membership.xlsx');
  }
  filterApps(name: any) {
    const filterValue = name.toLowerCase();
    if (name == '') {
      return this.apps;
    } else {
      return this.apps.filter((p) =>
        p.name.toLowerCase().includes(filterValue)
      );
    }
  }
  onEnter(e: any) {
    this.selectproduct = e.name;
    this.selectedId = e.id;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search() {
    this.router.navigate(['editTeam', this.selectedId]);
  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
