import { state } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MasterDataService } from 'src/app/service/masterData/master-data.service';

@Component({
  selector: 'app-add-update-state-data',
  templateUrl: './add-update-state-data.component.html',
  styleUrls: ['./add-update-state-data.component.scss']
})
export class AddUpdateStateDataComponent implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject;

  stateId: any;
  formGroup: FormGroup;
  countryListClone: any;
  countryList: any;
  stateListClone: any;
  stateList: any;
  data :any;
  zoneList:any;

  constructor(
    private fb: FormBuilder,
    private masterDataService: MasterDataService,
    private snackBar:MatSnackBar,
    private router: Router,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.stateId = this.route.snapshot.params['id'];
    this.getAllCountry();
    this.initializeForm();
    
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
//Add by vivek
  //To initialize Form

  initializeForm(){
    this.formGroup = this.fb.group({
      countryId:['',Validators.required],
      stateName:['',Validators.required],
      zoneId:['', Validators.required],
      stateCode:['',Validators.required]
      
    });
     this.formGroup.get('countryId').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((country:String|null) => {
      this.formGroup.get('zoneId').reset();
       const countryId = +(country || 0)
       this.masterDataService.getZoneByCountryId(countryId).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.zoneList = res.response || [];
         if (res.status === 0){
          this.stateListClone = this.stateList;
          }
         });
     })

    if (this.stateId){
      this.patchStateData();
    }

  }

  //Get data of state Using State ID

  patchStateData(){
    this.masterDataService.getStateById(this.stateId).pipe(takeUntil(this.destroy$)).subscribe(res=>{
      this.data = res;
      if(this.data.status ==0){
        this.formGroup.patchValue({
          countryId: this.data.response.country.countryId,
          stateId: this.data.response.stateId,
          stateName: this.data.response.stateName,
          zoneId:this.data.response.zone.zoneId,
          stateCode:this.data.response.stateCode
        });
      }else {
        this.snackBar.open('response Status -1 ', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    },error => console.log(error));
  }


  //To Submit Form Data
  onSubmit(){
    if (this.formGroup.valid) {
      const formValue = this.formGroup.value;

      // const formData: FormData = new FormData();
      // if(this.stateId){
      //   formData.append('stateId',this.stateId);}
      //   formData.append('countryId',this.formGroup.value.stateName);
      //   formData.append('stateName',this.formGroup.value.stateName);
      //   //formData.append('zoneId',this.formGroup.value.zoneId);
      //   //formData.append('stateCode', this.formGroup.value.stateCode);
      const payload: any = {
        countryId: formValue.countryId,
        stateName: formValue.stateName,
        zoneId: formValue.zoneId,
        stateCode:formValue.stateCode
      };

      // Conditionally add stateId if it exists
      if (this.stateId) {
        payload.stateId = this.stateId;
      }
      
      if(this.stateId){
        this.masterDataService.createNewState(payload).subscribe((data:any)=>{
          if(data.status == 0){
            this.snackBar.open('State Details Updated SucessFully !!','',{
              duration:3000,
              panelClass:['success-snackbar']
            });
            this.router.navigate(['/listStateData']);
          }
          else{
            this.snackBar.open(data.response, '',{
              duration:3000,
              panelClass:['error-snackbar']
            })
          }
        })
        }else{
          this.masterDataService.createNewState(payload).subscribe((data:any)=>{
            if(data.status == 0){
              this.snackBar.open('State Inserted SucessFully !!','',{
                duration:3000,
                panelClass:['success-snackbar']
              });
              this.router.navigate(['/listStateData']);
            }
            else{
              this.snackBar.open(data.response, '',{
                duration:3000,
                panelClass:['error-snackbar']
              })
            }
          })
        }
      }
    }

    firstSpaceRemoveValid(e){
      if(e.which === 32 && e.target.selectionStart === 0)
        return false;
    }

    countrySelect(query: string): any[] {
      const result: any[] = [];
      for (const b of this.countryListClone) {
        // @ts-ignore
        if (b.countryName.toLowerCase().indexOf(query) > -1) {
          result.push(b);
        }
      }
      return result;
    }
    countrySearch(query: string): any {
      const d = query.toLowerCase();
      const result = this.countrySelect(d);
      if (query == '') {
        this.countryList = this.countryListClone;
      } else {
        // @ts-ignore
        this.countryList = result;
      }
    }

    zoneSelect(query: string): any[] {
      const result: any[] = [];
      for (const b of this.countryListClone) {
        // @ts-ignore
        if (b.countryName.toLowerCase().indexOf(query) > -1) {
          result.push(b);
        }
      }
      return result;
    }
    zoneSearch(query: string): any {
      const d = query.toLowerCase();
      const result = this.zoneSelect(d);
      if (query == '') {
        this.countryList = this.countryListClone;
      } else {
        // @ts-ignore
        this.countryList = result;
      }
    }

    
    



    stateSearch(query: string): any {
      const d = query.toLowerCase();
      const result = this.stateSelect(d);
      if (query == '') {
        this.stateList = this.stateListClone;
      } else {
        // @ts-ignore
        this.stateList = result;
      }
    }
    stateSelect(query: string): any[] {
      const result: any[] = [];
      for (const b of this.stateListClone) {
        // @ts-ignore
        if (b.stateName.toLowerCase().indexOf(query) > -1) {
          result.push(b);
        }
      }
      return result;
    }
    getAllCountry() {
      this.masterDataService.getAllCountryNew().pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
        if (data.status == 0) {
          this.countryList = data.response;
          this.countryListClone = this.countryList; 
        }
      });
    }
}
