import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../service/language/language.service";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { JobTestQuestionManageService } from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import { DynamicLabelClass } from "../../../helper/userLevelLink";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { ImageQuestionPopupComponent } from "../../managejobPostQuestion/image-question-popup/image-question-popup.component";

@Component({
  selector: 'app-add-update-personality-category-values',
  templateUrl: './add-update-personality-category-values.component.html',
  styleUrls: ['./add-update-personality-category-values.component.scss']
})
export class AddUpdatePersonalityCategoryValuesComponent implements OnInit {
  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    public dialog: MatDialog,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    private router: Router) { }
  get f() { return this.dyanmicQuestionForm.controls; }
  get t() { return this.f.assesmentTestMultiQuestions as FormArray; }
  columnsToDisplay = ["seqNo", "question"];
  expandedElement: (query: string) => void;
  viewID: any;
  fileData: File = null;
  countryFlagImgURL: any;
  countryFlagImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  dyanmicQuestionForm: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList = [];
  page: any;
  size: any;
  qustGropList = [];
  qustGropList0 = [];
  qustGropList1 = [];
  qustGropList2 = [];
  qustGropList3 = [];
  qustGropList4 = [];
  qustGropList5 = [];

  qGroup: any;
  jobCatList = [];
  subjobCatList = [];
  qType: any;
  arrayOptions = [];
  pdata: any;
  seletedOption: any;
  myModel: any;
  config: any;
  addnewOption: any;
  submitted = false;
  selectLang: any;
  firstFormSubmited: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'position', 'question', 'action'];
  dataSource = new MatTableDataSource<any>();
  dataSource1 = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  QGData: any;
  mm: boolean = false;
  assesmentTestId: any;
  createTestData: any;
  testQData: any;
  firstCreateData: any;
  checkboxSelectedCount: number = 0;
  checkboxSelectArray = [];
  ToatlQuestionNo: number;
  QuestionSubmitBtnHide: boolean = false;
  isTestCreateSuccess: boolean = false;
  SelectedMainQuestionList: any[] = [];
  dsdsdsdsdsdsdsd: any[] = [];
  Jcat: any;
  languageId: any;
  excellentPassDescription: any;
  normalPassingScoreMax: any;
  numberOfRetry: any;
  excellentPassScore: any;
  jobcategoryName: any;
  testDescriptionForCompany: any;
  testDescriptionForCandidate: any;
  maxTimeInMin: any;
  failResultDescription: any;
  normalPassingScoreMin: any;
  pererror: boolean = false;
  JobQuniError: boolean = false;
  excellentPassScoreError: boolean = false;
  addOptionButtonHide: boolean = false;
  selectedAreas: any[] = [];

  questionAreas: any[] = [];
  questionAreas1: any[] = [];
  questionAreas2: any[] = [];
  questionAreas3: any[] = [];
  questionAreas4: any[] = [];
  questionAreas5: any[] = [];
  customQuestionGroupArray: any[] = [];
  selectedlanguageCode: any;
  selectedLangName: any;
  maxScoreDisable: boolean = true;
  excellentScoreDisable: boolean = true;
  UpdateCreatedTest: boolean = false;
  selectedTab = 0;
  moreCountthanQuestion: boolean = false;
  passResultDescription: any;
  title: any;
  subquestionCount = 0;
  subjobCategoryId: any;
  jobSubCategoryId: any;

  //  question
  item: any;
  openness: any[] = [];
  opennesValues: Array<any> = [];
  conscientiousness: any[] = [];
  extroversion: any[] = [];
  agreeableness: any[] = [];
  neuroticism: any[] = [];
  subcatclone = [];
  mainCreatedList = [];
  firstval = ['1', '2', '3'];
  secondval = ['2', '3', '4'];
  thirdval = ['3', '4', '5'];



  ngOnInit(): void {
    this.firstFormSubmited = false;
    this.addnewOption = 1;
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.seletedOption = 0;
    this.page = 0;
    this.size = 500;
    this.maincatelod();
    this.viewID = this.route.snapshot.params['id'];
    this.selectedTab = this.route.snapshot.params['tab'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectLang = this.LangList[0].languageCode;
    console.log(this.LangList);
    // this.questionGroupList();
    this.openContentOne(this.selectedTab);
    this.questionTypeList(this.selectLang);
    this.initializeForm();
    this.arrayOptions = [];
    this.openness = ['1', '2', '3', '4', '5'];
    // this.addOption();
  }

  LangChange(e) {
    for (let l of this.LangList) {
      if (l.languageId == e.value) {
        this.selectLang = l.languageCode;
        this.questionTypeList(this.selectLang);
      }
    }

  }
  questionTypeList(selectLang) {
    this.masterDataservice.getAllCategoryList(this.page, this.size, selectLang , true).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {

        this.jobCatList = this.qType.response.dataSet.filter((d) => {
          // && d.isTestCreated == false
          if (d.isSubCategory == false) {
            return d;
          }
        });
        this.selectedAreas = this.jobCatList;
        console.log(this.jobCatList);
      }
    });
  }


  subJobcategoryList(selectLang, parentId) {
    this.masterDataservice.getAllCategoryList(this.page, this.size, selectLang , true).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {

        let filtersubcat = this.qType.response.dataSet.filter((d) => {
          // && d.isTestCreated == false
          if (d.parentId == parentId) {
            return d;
          }
        });
        if (this.viewID) {
          this.subjobCatList = filtersubcat;
        } else {
          this.subjobCatList = filtersubcat.filter(e => this.mainCreatedList.map((o) => o.jobSubCategoryId).indexOf(e.jobCategoryId) < 0);
        }


        this.subcatclone = this.subjobCatList;
      }
    });
  }

  search(query: string) {
    console.log('query', query)
    let result = this.select(query.toLowerCase());
    if (query == '') {
      this.jobCatList = this.selectedAreas;
    } else {
      this.jobCatList = result;
    }
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }


  subcatsearch(query: string) {
    console.log('query', query)
    let result = this.subcatselect(query.toLowerCase());
    if (query == '') {
      this.subjobCatList = this.subcatclone;
    } else {
      this.subjobCatList = result;
    }
  }
  subcatselect(query: string): string[] {
    let result: string[] = [];
    console.log(this.subcatclone)
    for (let a of this.subcatclone) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }

  searchQuestionGrop(query: string, index) {
    console.log('query', query)
    let result = this.QuestionFromselect(query, index);
    if (query == '') {
      this.qustGropList[index] = this.questionAreas[index];
    } else {
      this.qustGropList[index] = result;
    }
  }
  QuestionFromselect(query: string, index): string[] {
    let result: string[] = [];
    for (let a of this.questionAreas[index]) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result
  }
  minScrorValidation($event) {
    const entervalue = $event.target.value;
    if (this.formGroup.controls.normalPassingScoreMin.value != '') {
      this.maxScoreDisable = false;
    } else {
      this.maxScoreDisable = true;
    }
  }



  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      jobCategoryId: ['', Validators.required],
      jobSubCategoryId: ['', Validators.required],
      formopennesValues: ['', Validators.required],
      formconscientiousness: ['', Validators.required],
      formextroversion: ['', Validators.required],
      formagreeableness: ['', Validators.required],
      formneuroticism: ['', Validators.required],

    });

    this.dyanmicQuestionForm = this.fb.group({
      assesmentTestMultiQuestions: new FormArray([]),
    });

    if (this.viewID) {
      this.patchData();
    }
    this.LangListLoad(this.addnewOption);
  }

  removeOption(i) {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e) {
    const numberOfTickets = e
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.fb.group({
          groupId: null,
          questionIds: [],
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }



  // get data from jobCategoryId
  menus: Array<any> = [];
  menusone: Array<any> = [];
  menustwo: Array<any> = [];
  menusthree: Array<any> = [];
  menusfour: Array<any> = [];
  patchData() {
    this.firstFormSubmited = true;
    this.masterDataservice.getPersonalitySelectyionById(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          this.subJobcategoryList(this.selectLang, this.pdata.response.jobCategoryId);
          this.jobSubCategoryId = this.pdata.response.jobSubCategoryId;
        
          this.formGroup.patchValue({
            formagreeableness: this.pdata.response.a.split(","),
            formconscientiousness: this.pdata.response.c.split(","),
            formextroversion: this.pdata.response.e.split(","),
            formneuroticism: this.pdata.response.n.split(","),
            formopennesValues: this.pdata.response.o.split(","),
            jobCategoryId: this.pdata.response.jobCategoryId,
            jobSubCategoryId: this.pdata.response.jobSubCategoryId
          });
          
          // this.agreeableness = this.pdata.response.a.split(",");
          // this.conscientiousness = this.pdata.response.c.split(",");
          // this.extroversion = this.pdata.response.e.split(",");
          // this.neuroticism = this.pdata.response.n.split(",");
          // this.opennesValues = this.pdata.response.o.split(",");


          // this.agreeableness = this.agreeableness;
          // this.extroversion = this.extroversion;
          // this.conscientiousness = this.conscientiousness;
          // this.opennesValues = this.opennesValues;
          // this.neuroticism = this.neuroticism;
          

        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));


  }



  onSubmit() {
    console.log(this.formGroup.value)
    if (this.formGroup.valid) {
      if (this.viewID) {
        const payload = {
          jobCategoryId: this.formGroup.value.jobCategoryId,
          subCategoryId: this.formGroup.value.jobSubCategoryId,
          // a: this.agreeableness.toString(),
          // c: this.conscientiousness.toString(),
          // e: this.extroversion.toString(),
          // n: this.neuroticism.toString(),
          // o: this.opennesValues.toString(),
          a: this.formGroup.value.formagreeableness.toString(),
          c: this.formGroup.value.formconscientiousness.toString(),
          e: this.formGroup.value.formextroversion.toString(),
          n: this.formGroup.value.formneuroticism.toString(),
          o: this.formGroup.value.formopennesValues.toString(),
        };

        console.log(payload);
        this.masterDataservice.editPersonalitySelectyion(this.viewID, payload).subscribe((data: any) => {
          if (data.status == 0) {
            this.snackBar.open('Personality Selection Update Successfully.', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listPersonalityCategoryValues']);
          } else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          }

        },
          (err) => {
            this.snackBar.open(err.error.message, '', {
              duration: 3000,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
              panelClass: ['error-snackbar']
            });
          }
        );

      } else {
        const payload = {
          jobCategoryId: this.formGroup.value.jobCategoryId,
          jobSubCategoryId: this.formGroup.value.jobSubCategoryId,
          // a: this.agreeableness.toString(),
          // c: this.conscientiousness.toString(),
          // e: this.extroversion.toString(),
          // n: this.neuroticism.toString(),
          // o: this.opennesValues.toString(),
          a: this.formGroup.value.formagreeableness.toString(),
          c: this.formGroup.value.formconscientiousness.toString(),
          e: this.formGroup.value.formextroversion.toString(),
          n: this.formGroup.value.formneuroticism.toString(),
          o: this.formGroup.value.formopennesValues.toString(),
        };
        this.masterDataservice.addPersonalitySelection(payload).subscribe((data: any) => {
          if (data.status == 0) {
            this.snackBar.open('Personality Selection Inserted Successfully.', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/listPersonalityCategoryValues']);
          } else {
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          }

        },
          (err) => {
            this.snackBar.open(err.error.message, '', {
              duration: 3000,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
              panelClass: ['error-snackbar']
            });
          }
        );
      }
    } else {
      this.snackBar.open('please fill all details ', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }


  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }


  jobcatSelection(e) {
    this.subJobcategoryList(this.selectLang, e.value);
  }

  demo(e) {
    const d = e.target.value;
    // console.log(d);
    // if(d > 0) return false;

  }
  openContentOne(e) {
    this.selectedTab = e;
  }

  imageQuestionPreview(element): any {
    const dialogRef = this.dialog.open(ImageQuestionPopupComponent, {
      width: '800px',
      height: 'auto',
      data: {
        question: element.question,
        qImage: element.questionImage,
      },
      panelClass: 'defaultClass',
    });
    dialogRef.afterClosed().subscribe(sresult => {
      // console.log(sresult);
      if (sresult == true) {
      }
    });
  }

  onCheckboxChangeOne(e, i) {
    if (e.target.checked) {
      this.opennesValues.push(e.target.value);
    } else {
      var index = this.opennesValues.indexOf(e.target.value);
      this.opennesValues.splice(index, 1);
    }
    console.log(this.opennesValues);

  }

  onCheckboxChangeTwo(e, i) {
    if (e.target.checked) {
      this.conscientiousness.push(e.target.value);
    } else {
      var index = this.conscientiousness.indexOf(e.target.value);
      this.conscientiousness.splice(index, 1);
    }
    console.log(this.conscientiousness);

  }
  onCheckboxChangeThree(e, i) {
    if (e.target.checked) {
      this.extroversion.push(e.target.value);
    } else {
      var index = this.extroversion.indexOf(e.target.value);
      this.extroversion.splice(index, 1);
    }
    console.log(this.extroversion);

  }
  onCheckboxChangeFour(e, i) {
    if (e.target.checked) {
      this.agreeableness.push(e.target.value);
    } else {
      var index = this.agreeableness.indexOf(e.target.value);
      this.agreeableness.splice(index, 1);
    }
    console.log(this.agreeableness);

  }
  onCheckboxChangeFive(e, i) {
    if (e.target.checked) {
      this.neuroticism.push(e.target.value);
    } else {
      var index = this.neuroticism.indexOf(e.target.value);
      this.neuroticism.splice(index, 1);
    }
    console.log(this.neuroticism);

  }

  maincatelod() {
    this.masterDataservice.getAllPersonalitySelectyion(0, 500).subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.mainCreatedList = this.data.response;
      }
    })
  }
}
