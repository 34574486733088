<div class="c_table" *ngIf="!resultView">
    <div class="mat-elevation-z8">
      <div class="d-flex table-responsive p-3">
        <div class="mr-2">
        </div>
        <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="srno" >
          <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
          <td mat-cell *matCellDef="let element;let j = index">
            {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
          </td>
        </ng-container>
        <div>
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let element">{{element.jobcategoryName}}</td>
          </ng-container>

          <ng-container matColumnDef="testName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Name</th>
            <td mat-cell *matCellDef="let element">{{element.title}}</td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Date</span>
            </th>
            <td mat-cell *matCellDef="let element">{{element.lastUpdatedEpochTime | date:'medium'}}
            </td>
          </ng-container>
        </div>
        <ng-container matColumnDef="actions">
          <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
            <div class="d-flex justify-content-center">
               <span class="btn btn-sm btn-primary rounded-lg mar15"  (click)="resultView=true;getAllPreEmploymentResult(element.employmentTestId)" matTooltip="View Result" matTooltipPosition="above">
                    <img src="../../../../assets/img/eye.svg" alt="eye">
                </span>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <!--Result View-->
  <div *ngIf="resultView && overallResult">
    <div class="result-div">
    <div class="head">
        <img style="height: 140px;" src="../../../assets/img/winner_test.png" alt="logo">
        <p class="heading">CONGRATULATIONS !</p>
        <p class="heading2">You Have Got <span>{{overallResult.overAllPercentage  | number:'1.0-2'}}%</span> Total Percentage In The Test</p>
        <p class="heading3">Your Remaining Result Are Here</p>
    </div>
    <div class="row head2">
        <div class="row">
        <div class="col-sm-12 mr-t15" *ngFor="let x of overallResult.assesmentTestGroupResultList">
            <div class="d-flex">
            <div class="c-div y-color">{{x.groupPercentage  | number:'1.0-2'}}%</div>
            <div class="t-div">
                <p class="t2">{{x.groupName}}</p>
                <span class="t3">{{x.groupDescription}}</span>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    <div *ngIf="resultView" class="c-btn">
        <button class="btn btn-primary btn-sm rounded-lg" (click)="resultView=false;">Cancel</button>
      </div>
  </div>
  

