import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('start', { static: false }) public sidenav: MatSidenav;
  
  title = 'rojgarAdminTestCode';
  events: string[] = [];
  opened: boolean;
  isCollapsed: boolean;
  constructor(public router: Router){
    this.opened = true;
    console.log(this.opened)
  }
  reloadComponent(){
    // this.sidenav.toggle();
  }

}
