  <div class="container-fluid">
    <!--Page Header-->
    <div class="page-header">
      <h3 class="page-title">View Profile</h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><a href="#">Teams</a></li>
        <li class="breadcrumb-item active" aria-current="page">View Profile</li>
      </ol>
    </div>
    <!--Page Header-->
    <!-- Row -->
    <div class="row row-sm">
      <div class="col-lg-4">
        <div class="card mg-b-20">
          <div class="card-body">
            <div class="pl-0">
              <div class="main-profile-overview">
                <div class="main-img-user profile-user">
                  <img alt="" src="../../assets/img/account_blue.svg" *ngIf="profileImage == null || profileImage == undefined">
                  <img alt="" src="{{profileImage}}" *ngIf="profileImage != null || profileImage != undefined">
                </div>
                <div class="justify-content-between mg-b-20 mt-2 text-center">
                  <div>
                    <h5 class="main-profile-name">{{firstName}}{{lastName}}</h5>
                    <p class="main-profile-name-text text-muted">{{jobRole}}</p>
                    <p class="user-info-rating mt-2 tx-12"> <a href="#"><i class="fa fa-star text-warning"> </i></a> <a href="#"><i class="fa fa-star text-warning"> </i></a> <a href="#"><i class="fa fa-star text-warning"> </i></a> <a href="#"><i class="fa fa-star text-warning"> </i></a> <a href="#"><i class="far fa-star text-warning"> </i></a> </p>
                  </div>
                </div>
                <!--<div class="mt-2 text-center"> <a href="#" class="btn btn-info"><i class="fa fa-rss"></i> Follow</a> </div>-->
              </div>
              <!-- main-profile-overview -->
            </div>
          </div>
          <div class="card-footer p-0">
            <div class="row m-0">
              <div class="col-sm-4 border-right text-center p-0">
                <div class="p-2">
                  <div class="mb-0 h6"><i class="icon icon-camera text-primary"></i></div>
                  <div class="text-muted mb-0 tx-13">32 photos</div>
                </div>
              </div>
              <div class="col-sm-4 border-right text-center p-0">
                <div class="p-2">
                  <div class="mb-0 h6"><i class="icon icon-people text-primary"></i></div>
                  <div class="text-muted mb-0 tx-13">87 friends</div>
                </div>
              </div>
              <div class="col-sm-4 text-center p-0">
                <div class="p-2">
                  <div class="mb-0 h6"><i class="icon icon-heart text-primary"></i></div>
                  <div class="text-muted mb-0 tx-13">2.3k likes</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mg-b-20">
          <div class="card-body">
            <div class="main-content-label tx-13 mg-b-25"> Social </div>
            <div class="main-profile-social-list">
              <div class="media">
                <div class="media-icon bg-warning-transparent text-warning"> <i class="fa fa-twitter"></i> </div>
                <div class="media-body"> <span>Twitter</span> <a href="">twitter.com</a> </div>
              </div>
              <div class="media">
                <div class="media-icon bg-success-transparent text-success"> <i class="fa fa-facebook"></i> </div>
                <div class="media-body"> <span>Facebook</span> <a href="">facebook.com</a> </div>
              </div>
              <div class="media">
                <div class="media-icon bg-info-transparent text-info"> <i class="fa fa-instagram"></i> </div>
                <div class="media-body"> <span>Instagram</span> <a href="">instagram.com</a> </div>
              </div>
              <div class="media">
                <div class="media-icon bg-danger-transparent text-danger"> <i class="fa fa-linkedin"></i> </div>
                <div class="media-body"> <span>Linked In</span> <a href="">linkedin.com/</a> </div>
              </div>
            </div>
            <!-- main-profile-social-list -->
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="mb-4 main-content-label">Personal Information</div>
            <form  [formGroup]="formGroup" class="form-horizontal">
              <div class="mb-4 main-content-label">Name</div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">First Name</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="First Name" formControlName="firstName"> </div>
                    <mat-error>
                         <span *ngIf="!formGroup.get('firstName').valid && formGroup.get('firstName').touched">
                          Please Enter First Name </span>
                    </mat-error>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">last Name</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
                    <mat-error>
                         <span *ngIf="!formGroup.get('lastName').valid && formGroup.get('lastName').touched">
                          Please Enter Last Name </span>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Designation</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="Designation" formControlName="jobRole">
                    <mat-error>
                         <span *ngIf="!formGroup.get('jobRole').valid && formGroup.get('jobRole').touched">
                          Please Enter Designation </span>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="mb-4 main-content-label">Contact Info</div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Email<i>(required)</i></label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="Email" formControlName="email">
                    <mat-error>
                         <span *ngIf="!formGroup.get('email').valid && formGroup.get('email').touched">
                          Please Enter Email </span>
                    </mat-error>
                  </div>
                </div>
              </div>
             <!-- <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Website</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="Website" value="@spruko.w"> </div>
                </div>
              </div>-->
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Phone</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="phone number" formControlName="mobileNumber">
                    <mat-error>
                         <span *ngIf="!formGroup.get('mobileNumber').valid && formGroup.get('mobileNumber').touched">
                          Please Enter Phone no </span>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">City</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="City" formControlName="cityName">
                    <mat-error>
                         <!--<span *ngIf="!formGroup.get('city').valid && formGroup.get('city').touched">
                          Please Enter City</span>-->
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Country</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="Country" formControlName="countryName">
                    <mat-error>
                         <span *ngIf="!formGroup.get('countryName').valid && formGroup.get('countryName').touched">
                          Please Enter Country</span>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Address</label>
                  </div>
                  <div class="col-md-9">
                    <textarea class="form-control" name="example-textarea-input" rows="2" placeholder="Address" formControlName="location"></textarea>
                    <mat-error>
                         <span *ngIf="!formGroup.get('location').valid && formGroup.get('location').touched">
                          Please Enter Address</span>
                    </mat-error>
                  </div>
                </div>
              </div>
              <!--<div class="mb-4 main-content-label">Social Info</div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Twitter</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="twitter" value="twitter.com/spruko.me"> </div>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Facebook</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="facebook" value="https://www.facebook.com/Nixlot"> </div>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Google+</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="google" value="spruko.com"> </div>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Linked in</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="linkedin" value="linkedin.com/in/spruko"> </div>
                </div>
              </div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Github</label>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="form-control" placeholder="github" value="github.com/sprukos"> </div>
                </div>
              </div>-->
              <!--<div class="mb-4 main-content-label">About Yourself</div>
              <div class="form-group ">
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Biographical Info</label>
                  </div>
                  <div class="col-md-9">
                    <textarea class="form-control" name="example-textarea-input" rows="4" placeholder="">pleasure rationally encounter but because pursue consequences that are extremely painful.occur in which toil and pain can procure him some great pleasure..</textarea>
                  </div>
                </div>
              </div>-->
            </form>
          </div>
         <!-- <div class="card-footer">
            <button class="btn btn-primary waves-effect waves-light" routerLink="/edit-user/">Back</button>
          </div>-->
        </div>
      </div>
      <!-- /Col -->
    </div>
  </div>
