<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title"><span *ngIf="!jobIndustryId">Add </span>
        <span *ngIf="jobIndustryId">Edit </span> Job Industry
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item " [routerLink]="['/listIndusrty']">Job Industry List</li>
        <li class="breadcrumb-item active">
          <span *ngIf="!jobIndustryId">Add </span>
          <span *ngIf="jobIndustryId">Edit </span>
          Job Industry
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">

      <form [formGroup]="dynamicForm" appInvalidControlScroll>

        <div class="row">
          <div *ngFor="let ticket of t.controls; let i = index" class="form-group row col-sm-6">
            <div [formGroup]="ticket" class="col-sm-12">
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Job Industry name </label>
                <div class="col-sm-12 ">
                  <input type="text" [ngClass]="{ 'is-invalid': submitted && ticket.get('name').errors }"
                    class="form-control" (keydown)="firstSpaceRemoveValid($event)" value=""
                    placeholder="Enter job Industry name" name="name" formControlName="name" required>
                  <mat-error>
                    <span *ngIf="!ticket.get('name').valid && ticket.get('name').touched">
                      Please enter job Industry name</span>
                  </mat-error>
                </div>
              </div>
              <div class="form-group row ">
                <label class="col-sm-12">
                  {{LangList[i].languageName}} Job Category Industry Description </label>
                <div class="col-sm-12 ">
                  <angular-editor formControlName="descriptionName" required
                    [ngClass]="{ 'is-invalid': submitted && ticket.get('descriptionName').errors }" [config]="config"
                    placeholder="Please Enter Job Category Industry Description" name="descriptionName">
                  </angular-editor>
                
                </div>
              </div>

            </div>
          </div>
        </div>


        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10 " (click)="onSubmit()"
          *ngIf="!jobIndustryId">Submit</button>
        <button type="submit" class="btn btn-main-primary pd-x-20 mg-t-10 " (click)="onSubmit()"
          *ngIf="jobIndustryId">Update</button>
      </form>




    </div>
  </div>

</div>