<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">View</span>
        Test
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listJobTestCreation' , selectLangIsoCode]">List Of test creation</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!viewID">Add New</span>
            <span *ngIf="viewID">View</span>
            Test</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body">
      <ul class="list-unstyled mb-0">
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Language </div>
          <div class="col-sm-9 mb-2">{{selectedLang}} </div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Category </div>
          <div class="col-sm-9 mb-2">{{pData?.jobcategoryName}} </div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Test Name </div>
          <div class="col-sm-9 mb-2"><span [innerHtml]="pData?.title"></span>
          </div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">No. Of Question </div>
          <div class="col-sm-9 mb-2">{{pData?.totalNumberOfQuestions}}</div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Max Time </div>
          <div class="col-sm-9 mb-2">{{pData?.maxTimeInMin}} min</div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Number Of Retry </div>
          <div class="col-sm-9 mb-2">{{pData?.numberOfRetry}}</div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">test Description For Company </div>
          <div class="col-sm-9 mb-2 ">
            <span class="testViewInherit" [innerHtml]="pData?.testDescriptionForCompany"></span>
          </div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">test Description For Candidate </div>
          <div class="col-sm-9 mb-2  ">
            <span class="testViewInherit" [innerHtml]="pData?.testDescriptionForCandidate"></span>
          </div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Fail Description </div>
          <div class="col-sm-9 mb-2 ">
            <div> <b>< {{pData?.normalPassingScoreMin}}%</b> </div>
            <div class="testViewInherit" [innerHtml]="pData?.failResultDescription"> </div>
          </div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Pass Scoring & Description </div>
          <div class="col-sm-9 mb-2">
            <div><b> {{pData?.normalPassingScoreMin}}% - {{pData?.normalPassingScoreMax}}% </b></div>
            <div class="testViewInherit" [innerHtml]="pData?.passResultDescription"> </div>
          </div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Very good Score</div>
          <div class="col-sm-9 mb-2">
            <div> <b> {{pData?.normalPassingScoreMax + 1}}% - {{pData?.excellentPassScore}}%</b> </div>           
          </div>
        </li>
        <li class="row border-bottom-0">
          <div class="col-sm-3 tx-gray-800 tx-12 tx-medium tx-uppercase mb-2">Excellent Scoring & Description </div>
          <div class="col-sm-9 mb-2 ">
            <div> <b> > {{pData?.excellentPassScore}}% </b></div>
            <div class="testViewInherit" [innerHtml]="pData?.excellentPassDescription"> </div>
          </div>
        </li>
        
      </ul>
      <!-- question start -->

      <h3 class="page-title">
        Question List
      </h3>
      <br>
      <div class="" *ngFor="let b of questionDataList;let i = index">
        <div>
          <div class="">
            <div class="d-flex"> <label>{{i+1}}.Group Name: <b> &nbsp;{{b.groupName}}</b></label></div>
            <div class="d-flex" *ngIf="!(b.description == null || b.description == '')"> <label style="flex: 1;">Group
                Description:&nbsp;</label>
              <div style="flex: 9;" [innerHtml]="b.description"></div>
            </div>
          </div>

          <!-- MCQ START -->
          <div class="MainQustionStart" *ngIf="b.comprehensiveQuestionDetails.length > 0">
            <!-- <h3 class="page-title">comprehensive Question Details </h3> -->
            <div class="compMain" *ngFor=" let comMain of b.comprehensiveQuestionDetails; let comIndex = index">
              <!-- <label>Q{{comMain.qnosort}}.  </label> -->
              <label class="mr-2">Question </label>
              <div>
                <span class="questionTitleREmove" [innerHtml]="comMain.question"></span>&nbsp;
                <!-- <span>&nbsp;<b><i>Question Type:{{comMain.questionLevel}}</i></b></span> -->
                <div *ngIf="comMain.questionImages != undefined">
                  <div class="d-flex" *ngIf="(comMain.questionImages.length > 0)">
                    <div class="img_flex" *ngFor="let comMainsd of comMain.questionImages">
                      <img style="max-height: 300px;" [src]="comMainsd.assesmentQuestionImage" alt="quesrionImage">
                    </div>
                  </div>
                </div>

              </div>
              <!-- <div *ngIf="!(comMain.imageUrl == null || comMain.imageUrl == '') " >
                        <img style="max-height: 300px;" [src]="comMain.imageUrl" alt="quesrionImage">
                      </div> -->

              <div class="comInner" *ngIf="comMain.questionDetails.length > 0">
                <div class="main-content-label mg-b-5">Sub Question</div>
                <div class="card-footer" *ngFor="let innerCom of comMain.questionDetails;let inComIndex = index">

                  <label class="mr-2">Q{{innerCom.qnosort}}. 
                    <span class="questionTitleREmove" [innerHtml]="innerCom.question"></span>
                    <!-- <span>&nbsp;<b><i>Question Type: {{innerCom.questionLevel}}</i></b></span> -->
                    <div *ngIf="innerCom.questionImages != undefined">
                      <div class="d-flex" *ngIf="(innerCom.questionImages.length > 0) ">
                        <div class="img_flex" *ngFor="let innerComsd of innerCom.questionImages">
                          <img style="max-height: 300px;" [src]="innerComsd.assesmentQuestionImage" alt="quesrionImage">
                        </div>
                      </div>
                    </div>

                    <!-- <div *ngIf="!(innerCom.imageUrl == null || innerCom.imageUrl == '') ">
                                        <img style="max-height: 300px;" [src]="innerCom.imageUrl" alt="quesrionImage">
                                    </div>                                     -->
                  </label>
                  <div class="options" *ngFor="let inerComOption  of innerCom.options;let inerComOptionIndex = index">
                    <div> <span class="optionsTitle">
                        <b><i>
                            <span *ngIf="inerComOptionIndex == 0">A .</span>
                            <span *ngIf="inerComOptionIndex == 1">B .</span>
                            <span *ngIf="inerComOptionIndex == 2">C .</span>
                            <span *ngIf="inerComOptionIndex == 3">D .</span>
                            <span *ngIf="inerComOptionIndex == 4">E .</span>
                            <span *ngIf="inerComOptionIndex == 5">F .</span>
                            <span *ngIf="inerComOptionIndex == 6">G .</span>
                            <!-- Option{{inerComOptionIndex +1}} -->
                          </i></b>
                      </span>
                      <span [innerHtml]="inerComOption"></span>
                      <!-- {{inerComOption}} -->
                    </div>
                  </div>
                  <br>
                </div>
              </div>


              <div>

              </div>
            </div>
          </div>
          <!-- MCQ END -->

          
          <div class="MainQustionStart" *ngIf="b.nonCoprehensiveDetails.length > 0">
            <div *ngFor="let m of b.nonCoprehensiveDetails; let nonI = index;">
              <div class="d-flex">
                <div class="mr-2"> <label>Q{{m.qnosort}}.</label></div>
                <div>
                  <div>
                    <span class="questionTitleREmove" [innerHtml]="m.question"></span>
                    <!-- <span>&nbsp;<b><i>Question Type: {{m.questionLevel}}</i></b></span> -->
                    <div *ngIf="m.questionImages != undefined">
                      <div class="d-flex" *ngIf="(m.questionImages.length > 0) ">                        
                        <div class="img_flex" *ngFor="let sd of m.questionImages">
                          <img style="max-height: 300px;" [src]="sd.assesmentQuestionImage" alt="quesrionImage">
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="options" *ngFor="let option  of m.options;let opI = index">
                    <div style="margin: 5px 0;">
                      <!-- <span class="optionsTitle"><b><i>Option{{opI +1}}</i></b>  .</span>  -->
                      <span class="optionsTitle"><b><i>
                            <span *ngIf="opI == 0">A .</span>
                            <span *ngIf="opI == 1">B .</span>
                            <span *ngIf="opI == 2">C .</span>
                            <span *ngIf="opI == 3">D .</span>
                            <span *ngIf="opI == 4">E .</span>
                            <span *ngIf="opI == 5">F .</span>
                            <span *ngIf="opI == 6">G .</span>
                          </i></b></span>
                      <span [innerHtml]="option"></span>
                      <!-- {{option}}  -->
                    </div>
                  </div>
                </div>

              </div>



              <br>
            </div>
          </div>


        </div>
        <!-- <br> -->

      </div>
      <!-- question end -->
    </div>
  </div>
</div>