<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;" >
     <div class="page-header px-0">
       <h3 class="page-title">Add Translation</h3>
       <ol class="breadcrumb mb-0">
         <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
         <li class="breadcrumb-item active">Add Translation </li>
       </ol>
     </div>
   </div>
   <!--Page Content-->
  <div class="card">
    <div class="card-body">
        <form [formGroup]="formGroup" >
            <div class="row">
                <div class="form-group col-sm-6">
                    <label>Section</label>
                    <div>
                        <mat-select required
                        placeholder="Select Section"
                                    class="form-control" formControlName="section">
                        <mat-option *ngFor="let r of sectionList"
                                    [value]="r" [aria-label]="r">
                            {{r}}
                        </mat-option>
                        </mat-select>
                        <mat-error>
                        <span *ngIf="(submitted && formGroup.get('section').errors?.required) || (!formGroup.get('section').valid && formGroup.get('section').touched)">
                                    Please Select Section </span>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label>Environment</label>
                    <div>
                        <mat-select required
                        placeholder="Select environment"
                                    class="form-control" formControlName="environment">
                        <mat-option value="true">Web</mat-option>
                        <mat-option value="false">Mobile</mat-option>
                        </mat-select>
                        <mat-error>
                        <span *ngIf="(submitted && formGroup.get('environment').errors?.required) || (!formGroup.get('environment').valid && formGroup.get('environment').touched)">
                                    Please Select Environment </span>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label>Page Name</label>
                    <input type="text" class="form-control"
                        placeholder="Enter Page Name" 
                        (keyup)="pageNameSearch($event.target.value)"
                        formControlName="pageName"
                        [matAutocomplete]="auto" required>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of pageNameList" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                    <mat-error>
                        <span *ngIf="(submitted && formGroup.get('pageName').errors?.required) || (!formGroup.get('pageName').valid && formGroup.get('pageName').touched)">
                                        Please Enter Page Name</span>
                    </mat-error>
                </div>
                <div class="form-group col-sm-6">
                    <label>Key Name</label>
                    <input type="text"
                        class="form-control" placeholder="Enter Key Name" name="keyName"
                        formControlName="keyName" required>
                    <mat-error>
                        <span *ngIf="(submitted && formGroup.get('keyName').errors?.required) || (!formGroup.get('keyName').valid && formGroup.get('keyName').touched)">
                                        Please Enter Key Name</span>
                    </mat-error>
                </div>
                <div class="form-group col-sm-4">
                    <label>English Value</label>
                    <input type="text"
                        class="form-control" formControlName="engLang"
                        value="" placeholder="Enter Value" name="engLang"
                                required>
                    <mat-error>
                        <span *ngIf="(submitted && formGroup.get('engLang').errors?.required) || (!formGroup.get('engLang').valid && formGroup.get('engLang').touched)">
                                        Please Enter English Value</span>
                    </mat-error>
                </div>
                <div class="form-group col-sm-4">
                    <label>Spanish</label>
                    <input type="text"
                        class="form-control" formControlName="spanLang"
                        value="" placeholder="Enter Value" name="spanLang">
                </div>
                <div class="form-group col-sm-4">
                    <label>Polish Value</label>
                    <input type="text"
                        class="form-control" formControlName="polLang"
                        value="" placeholder="Enter Value" name="polLang">
                </div>
            </div>
            <div class="">
                <button  type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="onSubmit()">SUBMIT</button>
            </div>
        </form>
    </div>
  </div>
  <div class="col-sm-12" style="display: inline-block;" >
    <div class="page-header px-0">
      <h3 class="page-title">Convert JSON into Excel file</h3>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
        <form [formGroup]="formGroupJson" >
            <div class="row">
                <div class="form-group col-sm-6">
                    <label>Section</label>
                    <div>
                        <mat-select required
                        placeholder="Select Section"
                                    class="form-control" formControlName="section">
                        <mat-option *ngFor="let r of sectionList"
                                    [value]="r" [aria-label]="r">
                            {{r}}
                        </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="form-group col-sm-6"></div>
                <div class="form-group col-sm-6">
                    <label>English Language</label>
                    <textarea rows="10"  class="form-control" formControlName="engLang"></textarea>
                </div>
                <div class="form-group col-sm-6">
                    <label>Other Language</label>
                    <textarea rows="10"  class="form-control" formControlName="otherLang"></textarea>
                </div>
            </div>
        </form>
        <div class="">
            <button  type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" (click)="convertIntoExcel()">SUBMIT</button>
        </div>
    </div>
  </div>
</div>