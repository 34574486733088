<div class="container-fluid">
    <!--Page Header-->
    <div class="col-sm-12" style="display: inline-block;">
        <div class="page-header px-0">
            <h3 class="page-title"><span *ngIf="!id">Add </span>
                <span *ngIf="id">Edit </span> Addon Plan</h3>
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item " [routerLink]="['/addonPlanList']">Manage Membership List</li>
                <li class="breadcrumb-item active">
                    <span *ngIf="!id">Add </span>
                    <span *ngIf="id">Edit </span> Addon Plan </li>
            </ol>
        </div>
    </div>
    <!--Page Header-->
    <div class="card">
        <div class="card-body">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="form-group col-sm-6 row">
                        <label class="col-sm-12">Plan Name </label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Plan Name " name="planName" formControlName="planName" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('planName').valid && formGroup.get('planName').touched">
                                    Please Enter Plan Name</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Plan Description </label>
                        <div class="col-sm-12 ">
                            <input type="text" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Plan Description" name="planDescription" formControlName="planDescription" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('planDescription').valid && formGroup.get('planDescription').touched">
                                    Please Enter Plan Description</span>
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row col-sm-6">
                        <label class="col-sm-12">Monthly Cost </label>
                        <div class="col-sm-12 ">
                            <input type="number" (keydown)="firstSpaceRemoveValid($event)" class="form-control" value="" placeholder="Enter Monthly Cost" name="monthlyCost" formControlName="monthlyCost" required>
                            <mat-error>
                                <span *ngIf="!formGroup.get('monthlyCost').valid && formGroup.get('monthlyCost').touched">
                                    Please Enter Monthly Cost</span>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="">
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="!id">SUBMIT</button>
                    <button (click)="onSubmit()" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10" *ngIf="id">UPDATE</button>
                </div>
            </form>
        </div>
    </div>
</div>