import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import {PaymentService} from '../../../service/payment/payment.service';

@Component({
  selector: 'app-add-update-payment',
  templateUrl: './add-update-payment.component.html',
  styleUrls: ['./add-update-payment.component.scss']
})
export class AddUpdatePaymentComponent implements OnInit {
  viewID:any;
  formGroup: FormGroup;
  data:any;
  countryList:any;
  imgURL:any;
  imagePath:any;
  fileData:any;
  userId;
  currencyList = [
    {code: 'USD',countryName: 'United States of America',currencyId:4,name:'Dollars',symbol: '$'},
    {code: 'GBP',countryName: 'United Kingdom',currencyId:2,name:'Pounds',symbol: '£'},
    {code: 'EUR',countryName: 'Euro',currencyId:1,name:'Euro',symbol: '€'},
    {code: 'PLN',countryName: 'Poland',currencyId:3,name:'Zlotych',symbol: 'zł'}
  ]
  @ViewChild('attachments1') myInputVariable: ElementRef;
  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private masterDataservice :MasterDataService,
    private snackBar: MatSnackBar,
    public paymentService:PaymentService,
    private router: Router) { }

  ngOnInit(): void {
    this.viewID = this.route.snapshot.params['id'];
    this.userId=localStorage.getItem('Id');
    this.countryListData();
    this.initializeForm();
  }
  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      nickName: ['', Validators.required],
      apiKey: ['', Validators.required],
      secretKey: ['', Validators.required],
      currency: ['', Validators.required],
      country: ['', Validators.required],
      imageUrl:[],
      userId:['', Validators.required],
      password:['', Validators.required],
      status:[true, Validators.required],
      description:['', Validators.required]
    });

    if (this.viewID){
       this.patchData();
    }
  }
  patchData(){
    this.paymentService.getPaymentById(this.viewID).subscribe( (res:any)=>{
      if(res.status == 0){
        var arr = [];
        var arr2 = [];
        this.formGroup.patchValue({
          name: res.response.paymentGatewayName,
          nickName:res.response.paymentGatewayShortName,
          apiKey: res.response.apiKey,
          secretKey: res.response.secretKey,
          userId:res.response.userId,
          password:res.response.password,
          status:res.response.status,
          description:res.response.description,
        });
        res.response.currencySupported.forEach(obj => {
            arr.push(obj.currencyId);
        });
        res.response.countrySupported.forEach(obj => {
          arr2.push(obj.countryId);
        });
        this.formGroup.patchValue({
          currency:arr,
          country: arr2,
        });
        this.imgURL= res.response.gatewayIcon;
      } else {
        this.snackBar.open(res.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    } , (error:any)=>{
      this.snackBar.open(error.error.response, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    });
  }
  firstSpaceRemoveValid(e): any{
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }
  countryListData(){
    this.masterDataservice.getAllCountryNew().subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.countryList = this.data.response;
      }
    })
  }
  imgReset() {
    this.imgURL = '';
    this.myInputVariable.nativeElement.value = '';
  }
  imageSelect(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.imagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      };
    }
  }
  onSubmit():any{
    if (this.formGroup.valid) {
      const formData: FormData = new FormData();
      if(this.viewID){
        formData.append('id' ,this.viewID);
      }
      formData.append('gatewayId' ,this.viewID?this.viewID:0);
      formData.append('paymentGatewayName' , this.formGroup.value.name);
      formData.append('paymentGatewayShortName' , this.formGroup.value.nickName);
      formData.append('description' , this.formGroup.value.description);
      formData.append('apiKey' , this.formGroup.value.apiKey);
      formData.append('userId' , this.formGroup.value.userId);
      formData.append('password' , this.formGroup.value.password);
      formData.append('secretKey' , this.formGroup.value.secretKey);
      formData.append('countrySupported' ,  this.formGroup.value.country);
      formData.append('currencySupported' , this.formGroup.value.currency);
      formData.append('updatedBy' , this.userId);
      if(this.fileData) {formData.append('gatewayIcon' , this.fileData);}
     
      if(this.viewID){
        this.paymentService.updatePayment(formData).subscribe( (data: any) => {
          if(data.status == 0)
          {
            this.snackBar.open('Updated Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/paymentList']);
          }
          else{
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        });
      } else {
        this.paymentService.addPayment(formData).subscribe( (data: any) => {
          if(data.status == 0)
          {
            this.snackBar.open('Added Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/paymentList']);
          }
          else{
            this.snackBar.open(data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        });
      }
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }
}
