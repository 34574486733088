import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../service/language/language.service";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { JobTestQuestionManageService } from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import { DynamicLabelClass } from "../../../helper/userLevelLink";
import { CommonFucntionService } from "../../../service/commonFuncaiton/common-fucntion.service";

@Component({
  selector: 'app-add-update-video-interview-question',
  templateUrl: './add-update-video-interview-question.component.html',
  styleUrls: ['./add-update-video-interview-question.component.scss']
})
export class AddUpdateVideoInterviewQuestionComponent implements OnInit {

  @ViewChild('attachments1') myInputVariable: ElementRef;

  viewID: any;
  fileData: File = null;
  testImgURL: any;
  questionImage: any;
  testImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList: Array<any> = [];
  page: number = 0;
  size: number = 500;
  compentcyCategoryList: Array<any> = [];
  compentcyCategoryList1: Array<any> = [];
  qGroup: any;
  qustTypeList: Array<any> = [];
  qustTypeList1: Array<any> = [];
  qType: any;
  arrayOptions: Array<any> = [];
  oprincustome: Array<any> = [];
  resultKewords: Array<any> = [];
  pdata: any;
  seletedOption: number = 0;
  myModel: any;
  config: any;
  addnewOption: number = 2;
  submitted = false;
  addBtnHide: boolean = false;
  selectedAreas: Array<any> = [];
  CorrecTanswerNotSlect = false;
  CorrectAnserArray = [false, false, false, false, false];
  typeSelect: boolean;
  lang: any;
  keywaordList: any;
  keywaordList1: any;
  allKeywords: any;
  allComplete: boolean = false;
  menus: any;
  mrunal = [];
  keyWordListShow = false;
  capDivVal: boolean = false;
  jobDivVal: boolean = false;
  laguageName: any = 'en';
  comptencyListClone = [];
  createdVideoInterviewList = [];
  uniq :any;


  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    public commonFucntion: CommonFucntionService,
    private router: Router) { }



  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.viewID = this.route.snapshot.params['id'];
    this.LangList = JSON.parse(this.langService.getLngList());
    //this.questionGroupList();
    this.questionTypeList();
     this.getAllCvaCompetencyCategory();
    this.initializeForm();
    // this.getAllVideocreatedList();
    this.arrayOptions = [];
  }

  questionTypeList(): any {
    this.masterDataservice.getAllQuestionTypeList(this.page, this.size).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {
        this.qustTypeList1 = this.qType.response.dataSet.slice(0, 2);
        this.qustTypeList = this.qType.response.dataSet.filter((d) => {
          if (d.isActive == true) {
            return d;
          }
        });
      }
    });
  }

  getAllCvaCompetencyCategory(): any {
    this.masterDataservice.getAllCompetencyCategory(this.page, this.size, this.laguageName).subscribe(res => {
      this.qGroup = res;
      if (this.qGroup.status == 0) {
        this.compentcyCategoryList = this.qGroup.response.dataSet.filter((d) => {
          if (d.isSubCategory == true) {
            return d;
          }
        });
        //this.compentcyCategoryList = this.qGroup.response.dataSet;
        console.log(this.createdVideoInterviewList)
        // if (this.viewID) {
        //   this.compentcyCategoryList = compatnecyfilter;
        // } else {
        //   this.compentcyCategoryList = compatnecyfilter.filter(e => this.createdVideoInterviewList.map((o) => o.competencyCategoryId).indexOf(e.competencyCategoryId) < 0);
        // }
        console.log(this.compentcyCategoryList)
        this.comptencyListClone = this.compentcyCategoryList;
      }
    });
  }

  //  
  competncysearch(query: string): any {
  
    const result = this.compentcyselect(query.toLowerCase());
    if (query == '') {
      this.compentcyCategoryList = this.comptencyListClone;
    } else {
      this.compentcyCategoryList = result;
    }
  }
  compentcyselect(query: string): string[] {
    const result: string[] = [];
    for (let a of this.comptencyListClone) {
      if (a.displayName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }
  // 

  search(query: string): any {
    const result = this.select(query.toLowerCase());
    if (query == '') {
      this.compentcyCategoryList = this.selectedAreas;
    } else {
      this.compentcyCategoryList = result;
    }
  }
  select(query: string): string[] {
    const result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      question: ['', Validators.required],
      languageId: ['', Validators.required],
      competencyCategoryId: [''],
      questionImage: [''],
      companyId: [0],
      TypeOfQuestion: [''],
      options: new FormArray([]),
      parentId: [0],
    });

    if (this.viewID) {
      this.patchData();
    }
    // this.LangListLoad(this.addnewOption);
  }
  get f() { return this.formGroup.controls; }
  get t() { return this.f.options as FormArray; }

  removeOption(i): any {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e): any {
    const numberOfTickets = e;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.fb.group({
          response: [''],
          valid: false,
        }));
      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  // get data from jobCategoryId
  competancySelect: boolean;
  keywordds: any;
  keywordBtnHide: boolean = false;
  iskeyWord: any;
  keywordAllBtnHide: boolean = false;
  radioValue: number;

  patchData(): any {
    this.jobpostQuestionTestService.getInterviewquestById(this.viewID)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0) {
          this.keywordds = this.pdata.response.keywords;

          if (this.pdata.response.questionLevel == 'COMPREHENSIVE') {
            this.typeSelect = true;
          } else {
            this.typeSelect = false;
          }
          // this.LangListLoad(this.pdata.response.options.length);
          this.addnewOption = this.pdata.response.options.length;

          this.myModel = this.pdata.response.correctAnswer;
          const ddd = this.pdata.response.options;
          const newaray = [];
          let m: any;
          let ctans = false;
          ddd.forEach((b, i) => {
            if (b == this.myModel) {
              m = true;
              this.CorrectAnserArray.splice(i, 1, true);
            } else {
              m = '';
              this.CorrectAnserArray.splice(i, 1, false);
            }
            newaray.push({
              'response': b,
              'valid': m,
            });
          });
          console.log(this.CorrectAnserArray);
          console.log(newaray);
          this.keywordds = this.pdata.response.keywords;
          this.resultKewords = this.keywordds;

          //this.setAll(true);
          console.log(this.resultKewords);
          this.formGroup.patchValue({
            question: this.pdata.response.question,
            languageId: this.pdata.response.languageId,
            competencyCategoryId: this.pdata.response.competencyCategoryId,
            jobCategoryId: this.pdata.response.jobCategoryId,
            // questionImage: this.pdata.response.questionImage,
            options: newaray,
            questionTypeId: this.pdata.response.questionTypeId,
            correctAnswer: this.pdata.response.correctAnswer,
            answerExplanation: this.pdata.response.answerExplanation,
            companyId: this.pdata.response.companyId,
            parentId: this.pdata.response.parentId,
          });

          for (let l of this.LangList) {
            if (this.pdata.response.languageId == l.languageId) {
              this.laguageName = l.languageCode;
              this.formGroup.patchValue({
                languageId: l,
              })
            }
          }

          if (this.pdata.response.typeOfQuestion == 'COMPETENCY') {
            this.radioValue = 2;
            // this.onItemChange(this.pdata.response.competencyCategoryId);
            this.getAllCvaCompetencyCategory();
            this.capDivVal = true;
            this.jobDivVal = false;
          }


        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }


  onSubmit(submiType): any {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      // const dd = this.formGroup.value.options;
      // this.oprincustome = [];
      // for (let b of dd) {
      //   this.oprincustome.push(this.commonFucntion.removeHtmlTag(b.response));
      // }
      if(!(this.viewID)){
        this.uniq = this.formGroup.value.competencyCategoryId.reduce((uniqArr, item) => {
          return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]}
          ,[]
          );
      }

      const formData: FormData = new FormData();
      formData.append('question', this.commonFucntion.removeHtmlTag(this.formGroup.value.question));
      formData.append('languageId', this.formGroup.value.languageId.languageId);
      // @ts-ignore
      formData.append('questionTypeId', 5);
      formData.append('compenetencyId', !this.viewID ? this.uniq : this.formGroup.value.competencyCategoryId);
      formData.append('isGeneralQuestion', 'COMPETENCY');
      // @ts-ignore
      formData.append('isVideoQuestion', true);
      formData.append('companyId', this.formGroup.value.companyId == null ? 0 : this.formGroup.value.companyId);
      formData.append('correctAnswer', '');
      formData.append('parentId', this.formGroup.value.parentId == null ? 0 : this.formGroup.value.parentId);
      formData.append('options', (this.oprincustome).toString());
      // @ts-ignore
      formData.append('keywords', this.resultKewords);

      if (this.viewID) {
        this.jobpostQuestionTestService.editInterviewQuestion(this.viewID, formData)
          .subscribe(data => {
            this.snackBar.open('Update Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            if (submiType == 0) {
              this.router.navigate(['/listvideoInterviewQuestion' , this.laguageName]);
            } else {
              this.submitted = false;
              this.formGroup.controls.question.reset();
              this.formGroup.controls.answerExplanation.reset();
              this.formGroup.controls.correctAnswer.reset();
              this.t.reset();
            }

          },
            (err) => {
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else {
        this.jobpostQuestionTestService.addInterviewQuestion(formData).subscribe(res => {
          console.log(res.response);
          this.snackBar.open(' Successfully !!', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          if (submiType == 0) {
            this.router.navigate(['/listvideoInterviewQuestion' , this.laguageName]);
          } else {
            this.submitted = false;
            this.formGroup.controls.question.reset();
            this.formGroup.controls.answerExplanation.reset();
            this.formGroup.controls.correctAnswer.reset();
            this.t.reset();
          }
        });
      }

    } else {
      this.snackBar.open('Please fill all details.', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

  firstSpaceRemoveValid(e): any {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

  newOptionadd(): any {
    if (this.addnewOption < 5) {
      this.addnewOption = this.addnewOption + 1;
      console.log(this.addnewOption);
      this.LangListLoad(this.addnewOption);
      this.addBtnHide = false;
    } else {
      this.addBtnHide = true;
    }
    if (this.addnewOption == 5) {
      this.addBtnHide = true;
    } else {
      this.addBtnHide = false;
    }

  }




  onItemChange(id) {
    console.log(id);
    this.masterDataservice.getCompetencyKeywordDetailsByIdNew(id, 'en').subscribe(res => {
      this.qGroup = res;
      if (this.qGroup.status == 0) {
        this.keyWordListShow = true;
        this.competancySelect = true;
        console.log(this.qGroup.response);
        const d = this.qGroup.response.replace(/\s/g, '');
        if (this.qGroup.respons != "") {
          this.keywaordList = d.split(",");
          this.keywaordList1 = d.split(",");
          this.mrunal = this.keywaordList;
          this.selectedAreas = this.compentcyCategoryList;
          console.log(d);
          if (d != "") {
            if (this.iskeyWord == 0) {
              this.keywordBtnHide = false;
            } else {
              this.keywordBtnHide = true;
            }
          } else {
            this.keywordBtnHide = false;
          }


          if (this.keywaordList1.length == this.keywordds.length) {
            this.allComplete = true;
          } else {
            this.allComplete = false;
          }
        }
        // this.allComplete = true;
      }
      //this.setAll(true);

    });
  }



  setAll(e: any) {
    if (e.checked) {
      console.log(this.keywaordList1);
      this.resultKewords = this.mrunal;
      this.allComplete = true;
    } else {
      this.allComplete = false;
      this.resultKewords = [];
    }
    console.log(this.resultKewords);
    console.log(this.keywaordList1);
  }


  setAllMe(e, key): any {
    if (e.checked) {
      this.resultKewords.push(key);
    } else {
      console.log(key);
      console.log(this.resultKewords);
      this.resultKewords.splice(key, 1);
    }
    //this.allComplete =e.checked;
    console.log(this.keywaordList1);
    console.log(this.resultKewords);

  }

  onCheckboxChangeNew(e, i, key) {
    console.log(this.keywaordList);
    console.log(this.resultKewords);
    if (e.checked) {
      this.allComplete = false;
      this.resultKewords.push(key);
    } else {
      var index = this.resultKewords.indexOf(key);
      this.resultKewords.splice(index, 1);
    }
    if (this.keywaordList1.length == this.resultKewords.length) {
      this.allComplete = true;
    } else {
      this.allComplete = false;
    }

    console.log(this.resultKewords);
  }

  onLanguageChange(value) {
    console.log(value)
    this.laguageName = value.languageCode;
    // this.getAllVideocreatedList();
    this.getAllCvaCompetencyCategory();
    
  }
 
  getAllVideocreatedList() {
    this.jobpostQuestionTestService.getAllInterviewQuestion(0, 500, this.laguageName, 'true').subscribe(res => {
      this.data = res;
      if (this.data.status == 0) {
        this.createdVideoInterviewList = this.data.response.dataSet;
        this.getAllCvaCompetencyCategory();
      }
    })
  }
  removeAtt(e, contrlname) {
    this.commonFucntion.removeAttributeFromHtml(e);
    return this.commonFucntion.removeHtmlTag(e.target.innerHTML);

  }
  removeAttpasete(e, contrlname) {
    console.log(e)
    this.formGroup.controls[contrlname].patchValue(this.commonFucntion.removeHtmlTag(e.target.innerHTML))
  }
  SelectAll (jobcategoryList , e:any){
    console.log(jobcategoryList.length);
    if(e.checked == true){
      let d =  [];
      for(let b of jobcategoryList){
        d.push(b.competencyCategoryId);
      }
      console.log(d);
      this.formGroup.controls['competencyCategoryId'].setValue(d);
    } else {
      this.formGroup.controls['competencyCategoryId'].setValue([]);    
    }
  }
}
