<div class="container-fluid">
    <!-- breadcrum start -->
    <div class="row">
        <div class="col-sm-12">
            <div class="page-title-box">
                <div class="float-right">
                    <ol class="breadcrumb">
                        <!-- <li class="breadcrumb-item">Mange Company setting </li> -->
                        <li class="breadcrumb-item "  [routerLink]="'/home'">
                            <i class="fas fa-home" ></i> </li>
                        <li class="breadcrumb-item">
                            <span *ngIf="!id">Add New </span> <span *ngIf="id">Edit </span> Profile</li>
                    </ol>
                </div>
                <h4 class="page-title">
                    <span *ngIf="!id">Add New </span> <span *ngIf="id">Edit </span> Profile</h4>
        </div>
    </div>
    </div>
    <!-- breadcrum end -->
    <div class="card">
        <div class="card-body">

            <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                 <div class="form-group row">
                    <label for="example-text-input" class="col-sm-3 col-form-label text-right colonclass">name </label>
                    <div class="col-sm-9 ">
                        <mat-form-field appearance="outline">
                            <mat-label>Enter name </mat-label>
                            <input matInput type="text"  value="" placeholder="Enter name" name="name"
                            formControlName="name" required>
                        </mat-form-field>
                        <mat-error>
                            <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">
                                Please enter name</span>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="example-text-input" class="col-sm-3 col-form-label text-right colonclass">email </label>
                    <div class="col-sm-9 ">
                        <mat-form-field appearance="outline">
                            <mat-label>Enter email </mat-label>
                            <input matInput type="text"  value="" placeholder="Enter email" name="email"
                            formControlName="email" required>
                        </mat-form-field>

                        <!-- <mat-error *ngIf="email.errors?.notEquivalent">
                            Valid Email address E.x: admin@xyz.com
                        </mat-error> -->
                        <mat-error>
                            <span *ngIf="emailpresent">Email Id Already Present</span>
                            <span style="text-align: left;"
                                *ngIf="!formGroup.get('email').valid && formGroup.get('email').touched">Please
                                enter email or user ID</span>
                        </mat-error>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="example-text-input" class="col-sm-3 col-form-label text-right colonclass">mobile Number </label>
                    <div class="col-sm-9 ">
                        <mat-form-field appearance="outline">
                            <mat-label>Enter mobileNumber </mat-label>
                            <input matInput type="text"  value=""
                            (keypress)="numberOnly($event)"
                             placeholder="Enter mobileNumber" name="mobileNumber"
                            formControlName="mobileNumber" required>
                        </mat-form-field>
                        <mat-error>
                            <span *ngIf="!formGroup.get('mobileNumber').valid && formGroup.get('mobileNumber').touched">
                                Please enter mobileNumber</span>
                        </mat-error>
                    </div>
                </div>

                <!--<div class="form-group row">
                    <label for="example-text-input" class="col-sm-3 col-form-label colonclass text-right">Enter roleId</label>
                    <div class="col-sm-9 ">

                        <mat-form-field class="custom_select">
                            <mat-label>Please Select roleId</mat-label>
                            <mat-select formControlName="roleId" required
                                class="form-control" >
                                <mat-option>&#45;&#45;</mat-option>
                                <mat-option *ngFor="let r of roleList"
                                    [value]="r.stateName" [aria-label]="r.id">
                                    {{r.roleId}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error>
                            <span
                                *ngIf="!formGroup.get('roleId').valid && formGroup.get('roleId').touched">Please
                                Select role </span>
                        </mat-error>


                    </div>
                </div>-->
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-right">Profile Image :</label>
                    <div class="col-sm-9 row">
                        <div class="col-sm-4">
                            <label for="aadhar" class="custom-file-upload">
                                <i class="fa fa-cloud-upload"></i> Custom Upload
                            </label>
                            <input type="file" name="adhaar" #file formControlName="profileImage"
                             accept="image/x-png,image/jpeg"
                                class="imageUploadcss" #attachments1 id="aadhar"
                                (change)="adhacardselct($event,file.files)" />
                                <!-- <mat-error *ngIf="profilcontrlImg.errors?.notfoundAdharImage">
                                    Please Select Adharcard Image
                                </mat-error>                -->
                        </div>
                        <div class="col-sm-3" *ngIf="aadharImgURL">
                            <div class="close" (click)="aadharReset()"><i
                                    class="fas fa-times-circle"></i></div>
                            <div class="image-preview">
                                <img [src]="aadharImgURL" height="100" />
                            </div>


                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="example-text-input" class="col-sm-3 col-form-label text-right"> Status :</label>
                    <div class="col-sm-9 ">
                        <mat-slide-toggle  formControlName="isActive" name="isActive"
                            [checked]="isActive"></mat-slide-toggle>
                    </div>
                </div>
                <!--<div class="form-group row">
                    <label for="example-text-input" class="col-sm-3 col-form-label text-right"> isDeleted :</label>
                    <div class="col-sm-9 ">
                        &lt;!&ndash; {{isDeleted}}           &ndash;&gt;
                    </div>
                </div>-->

                <button type="submit" class="btn btn-success">Submit</button>

            </form>




        </div>
    </div>

</div>
