import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  getPaymentList(page, size) {
    return this.http.get(`${environment.baseurl}/paymentgateway/getPayments?page=`+ page+`&size=` + size);
  }
  updatePayment(data){
    return this.http.put(`${environment.baseurl}/paymentgateway/updatePayment`,data);
  }
  addPayment(data){
    return this.http.post(`${environment.baseurl}/paymentgateway/addPayment` ,data);
  }
  deletePayment(id){
    return this.http.delete(`${environment.baseurl}/paymentgateway/deletePayment?id=`+ id);
  }
  getPaymentById(id) {
    return this.http.get(`${environment.baseurl}/paymentgateway/getPaymentsById?id=`+ id);
  }
  changeStatusPayment(id) {
    // @ts-ignore
    return this.http.put(`${environment.baseurl}/paymentgateway/changeStatus?id=`+ id);
  }
}
