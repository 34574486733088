<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
        <h3 class="page-title">Candidate Membership List</h3>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
            <li class="breadcrumb-item active">Candidate Membership List</li>
        </ol>
    </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
    <div class="card">
        <!-- <div class="col-lg-3 mt-4">
            <div class="responce-banner mb-4">
                <mat-select [(value)]="subscriptionStatus" [(ngModel)]="subscriptionStatus" (selectionChange)="chnageStatus($event)" placeholder="Select Status " class="form-control">
                    <mat-option *ngFor="let data of statusList" [value]="data.value">
                        {{data.name}}</mat-option>
                </mat-select>
            </div>
        </div> -->
        <div class="c_table">
            <div class="mat-elevation-z8">
                <div class="col-12 mt-4 mb-4">
                    <div class="row">
                        <div class="col-6">
                            <form [formGroup]="searchForm">
                                <div class="row">
                                    <div class="col-6 text-left">
                                        <div class="input-group">
                                        <mat-select class="form-control" formControlName="downloadFile">
                                            <mat-option value="CSV">CSV</mat-option>
                                            <mat-option value="XLSX">XLSX</mat-option>
                                            <mat-option value="XLS">XLS</mat-option>
                                        </mat-select>
                                        </div>
                                    </div>
                                    <div class="col-6 text-left">
                                        <button class="btn btn-primary" (click)="downloadFile()">Download File</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                      <div class="col-3">
                        <div class="responce-banner">
                            <mat-select [(value)]="subscriptionStatus" [(ngModel)]="subscriptionStatus" (selectionChange)="chnageStatus($event)" placeholder="Select Status " class="form-control">
                                <mat-option *ngFor="let data of statusList" [value]="data.value">
                                    {{data.name}}</mat-option>
                            </mat-select>
                        </div>
                      </div>
                      <div class="col-3 text-end">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                      </div>
                    </div>
                </div>
                <!-- <div class="d-flex table-responsive p-3">
                    <div class="mr-3">
                        <button mat-raised-button color="primary" (click)="ExportTOExcel()">Export as Excel</button></div>
                    <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                        <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                    </div>
                </div> -->
                <div class="example-container mat-elevation-z8 " #TABLE>
                    <table mat-table #table [dataSource]="dataSource" matSort class="table-striped contact-table">
                        <ng-container matColumnDef="srno">
                            <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                            <td mat-cell *matCellDef="let element;let j = index">
                                {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                            </td>
                        </ng-container>

                        <div>


                            <ng-container matColumnDef="companyName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Candidate Name</th>
                                <td mat-cell *matCellDef="let element">{{element.candidateFirstName }} {{element.candidateLastName}}</td>
                            </ng-container>

                            <ng-container matColumnDef="planName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> plan Name</th>
                                <td mat-cell *matCellDef="let element">{{element.planName}}</td>
                            </ng-container>




                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> start Date</th>
                                <td mat-cell *matCellDef="let element">
                                    <p class="mb-0">{{element.startDate | date:'dd-MM-yyyy'}}</p>
                                </td>
                            </ng-container>



                            <ng-container matColumnDef="expiryDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> expiry Date</th>
                                <td mat-cell *matCellDef="let element">{{element.expiryDate | date:'dd-MM-yyyy'}}</td>
                            </ng-container>
                            <ng-container matColumnDef="subscriptionStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                <td mat-cell *matCellDef="let element">
                                    <span style="color: green;" *ngIf="element.subscriptionStatus == 'Active' ">{{element.subscriptionStatus }}</span>
                                    <span style="color: red;" *ngIf="element.subscriptionStatus == 'Expired' ">{{element.subscriptionStatus }}</span>
                                </td>
                            </ng-container>


                        </div>





                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>





            </div>


        </div>
    </div>
</div>