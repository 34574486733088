<div class="col-sm-12">
<div class="row">
    <div class="col-sm-12">
      <div class="page-title-box">
        <div class="float-right">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a  [routerLink]="'/home'">
              <i class="fas fa-home" ></i> </a></li>
  
          </ol>
        </div>
        <h4 class="page-title"> Job Category Report </h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
  
      <mat-card>
        <mat-card-content>
          <div class="c_table">
            <div class="mat-elevation-z8">
              <div class="row filterblock align-items-center">
                <div class="col-sm-4 pull-left">
                  <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                    <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                  </div>
                  <!-- <mat-form-field appearance="outline" class="table_filter">
                    <mat-label>Search </mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                  </mat-form-field> -->
                  </div> 
                  <div class="mr-2 col-sm-2">
                    <mat-select [(ngModel)]="selectLang" (selectionChange)="LangChange($event)" class="form-control">
                      <mat-option *ngFor="let r of langList" [value]="r.languageCode" [aria-label]="r.languageCode">
                        {{r.languageName}}
                      </mat-option>
                    </mat-select>
                  </div>               
              </div>
  
  
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  
                  <ng-container matColumnDef="jobCategoryName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Category Name</th>
                    <td mat-cell *matCellDef="let element">{{element.jobCategoryName}}</td>
                  </ng-container>
  
                  <ng-container matColumnDef="categoryDescription">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let element">{{element.categoryDescription}}</td>
                  </ng-container>
                  <ng-container matColumnDef="jobSubCategoryName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Category </th>
                    <td mat-cell *matCellDef="let element">{{element.jobSubCategoryName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="icc">
                    <th width="120px" mat-header-cell *matHeaderCellDef mat-sort-header>icc </th>
                    <td width="120px" mat-cell *matCellDef="let element">
                      <span class="yes" *ngIf="element.icc == true "><i class="fas fa-check-circle" ></i></span>
                      <span class="no" *ngIf="element.icc == false "><i class="fas fa-times-circle" ></i></span>
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="personality">
                    <th width="120px" mat-header-cell *matHeaderCellDef mat-sort-header>personality </th>
                    <td width="120px" mat-cell *matCellDef="let element">
                      <span class="yes" *ngIf="element.personality == true "><i class="fas fa-check-circle" ></i></span>
                      <span class="no" *ngIf="element.personality == false "><i class="fas fa-times-circle" ></i></span>
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="testAssesement">
                    <th width="120px" mat-header-cell *matHeaderCellDef mat-sort-header>test Assesement</th>
                    <td width="120px" mat-cell *matCellDef="let element">
                      <span class="yes" *ngIf="element.testAssesement == true "><i class="fas fa-check-circle" ></i></span>
                      <span class="no" *ngIf="element.testAssesement == false "><i class="fas fa-times-circle" ></i></span>
                     </td>
                  </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
             
  
              <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>
  
            </div>
  
  
          </div>
        </mat-card-content>
      </mat-card>
  
    </div>
  
  </div>
  </div>