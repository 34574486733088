import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../service/language/language.service";
import { MasterDataService } from "../../../service/masterData/master-data.service";
import { JobTestQuestionManageService } from "../../../service/jobPostTestandQuestion/job-test-question-manage.service";
import { DynamicLabelClass } from "../../../helper/userLevelLink";
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Component({
  selector: 'app-add-update-sub-comprehnsive',
  templateUrl: './add-update-sub-comprehnsive.component.html',
  styleUrls: ['./add-update-sub-comprehnsive.component.scss']
})
export class AddUpdateSubComprehnsiveComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;

  parentId: any;
  fileData: File = null;
  testImgURL: any;
  questionImage: any;
  testImagePath: any;
  newimgeuplod: any;
  formGroup: FormGroup;
  data: any;
  addedState: any;
  status: boolean;
  isActive: boolean;
  emailpresent: boolean;
  LangList = [];
  page: number = 0;
  size: number = 500;
  qustGropList = [];
  qGroup: any;
  qustTypeList = [];
  qType: any;
  arrayOptions: Array<any> = [];
  pdata: any;
  seletedOption: number = 0;
  myModel: any;
  config: any;
  optionconfig: any;

  addnewOption: number = 2;
  submitted = false;
  addBtnHide: boolean = false;
  selectedAreas = [];
  CorrecTanswerNotSlect = false;
  CorrectAnserArray = [false, false, false, false, false];
  typeSelect: boolean = false;
  languageId: any;
  questionGroupId: any;
  questionTypeId: any;
  dataSource = new MatTableDataSource([]);
  oprincustome: Array<any> = [];
  displayedColumns: string[] = ['srno', 'question','createdEpochTime', 'action'];
  selectedLangName: any;
  jobcategoryName: any;

  question: any;
  childId: any;
  subData: any;
  questGroupName: any;
  questionType: any;
  selectedQuestionTypeId: any;
  fileName: any;
  fileType: any;
  url: any;
  format: any;
  images = [];
  newBlobArray = [];
  GetTimeQuestionImage = [];
  GetTimeQuestionImagechild = [];
  selectlangIsoCode : string = 'en';

  fileData2:any;
  thumbImagePath:any;
  thumbImageURL:any;
  newthumbImageupload:any;
  @ViewChild('attachments2') myInputVariable2: ElementRef;


   
  constructor(private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public langService: LanguageService,
    private masterDataservice: MasterDataService,
    private el: ElementRef,
    private jobpostQuestionTestService: JobTestQuestionManageService,
    private router: Router) { }

  ngOnInit(): void {
    this.config = new DynamicLabelClass().ckeditorconfig;
    this.optionconfig = new DynamicLabelClass().ckeditorconfigForOption;
    this.parentId = this.route.snapshot.params['id'];
    this.LangList = JSON.parse(this.langService.getLngList());
    this.selectlangIsoCode = this.LangList[0].languageCode;
    console.log(this.LangList);
    this.initializeForm();
    this.getSubQuestionList();
  }
  getSubQuestionList(): any {
    this.jobpostQuestionTestService.getSubQuestionByParentId(this.parentId).subscribe(res => {
      this.subData = res;
      if (this.subData.status == 0) {
        this.dataSource = new MatTableDataSource(this.subData.response);
      } else {
        this.snackBar.open(this.subData.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }
  questionTypeList(): any {
    this.masterDataservice.getAllQuestionTypeList(this.page, this.size).subscribe(res => {
      this.qType = res;
      if (this.qType.status == 0) {
        this.qustTypeList = this.qType.response.dataSet.filter((d) => {
          if (d.isActive == true) {
            return d;
          }
        });
        for (let b of this.qustTypeList) {
          console.log(b)
          if (this.questionTypeId == b.questionTypeId) {
            this.questGroupName = b.groupName;
          }
        }
        console.log(this.qustTypeList);
      }
    });
  }
  questionGroupList(page, size , selectedLangName): any {
    this.masterDataservice.getAllQuestionLevelList(page, size , selectedLangName).subscribe(res => {
      this.qGroup = res;
      if (this.qGroup.status == 0) {
        this.qustGropList = this.qGroup.response.dataSet;
        this.selectedAreas = this.qustGropList;
        for (let b of this.qustGropList) {
          if (this.questionGroupId == b.questionGroupId) {
            this.questGroupName = b.groupName;
          }
        }
      }
    });
  }
  search(query: string): any {

    let result = this.select(query.toLowerCase());
    if (query == '') {
      this.qustGropList = this.selectedAreas;
    } else {
      this.qustGropList = result;
    }
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.selectedAreas) {
      if (a.groupName.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result
  }

  // To initialize Form
  initializeForm(): any {
    this.formGroup = this.fb.group({
      languageId: ['', Validators.required],
      questionGroupId: ['', Validators.required],
      question: ['', Validators.required],
      options: new FormArray([]),
      questionTypeId: ['', Validators.required],
      correctAnswer: [''],
      answerExplanation: [''],
      questionImage: [''],
      companyId: [0],
      parentId: [0],
    });

    // if (this.parentId){
    this.patchCountryData();
    // }
    this.LangListLoad(this.addnewOption);
  }
  get f() { return this.formGroup.controls; }
  get t() { return this.f.options as FormArray; }

  removeOption(i): any {
    this.formGroup.removeControl(i);
  }
  LangListLoad(e): any {
    const numberOfTickets = e;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.fb.group({
          response: [''],
          valid: false,
        }));

      }
      // this.dynamicForm.patchValue(this.payloaddd);
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  // addOption() {
  //   const control = <FormArray>this.formGroup.get("options");
  //   const newGroup = this.fb.group({
  //     response: "",
  //     valid: ""
  //   });
  //   control.push(newGroup);
  //   this.arrayOptions.push(this.formGroup.controls.options.value);
  // }


  // get data from jobCategoryId
  // isTestCreateSuccess: boolean;
  // valueRadio: boolean;
  patchChild(id): any {
    this.childId = id;
    // this.CorrectAnserArray = [];

    this.jobpostQuestionTestService.getJobPostQuestById(this.childId).subscribe((res: any) => {
      if (res.status == 0) {
        if (!(res.response.options == null || res.response.options == undefined || res.response.options == '')) {
          this.LangListLoad(res.response.options.length);
          this.addnewOption = res.response.options.length;
          // for option creation patch start
          const ddd = res.response.options;
          this.myModel = res.response.correctAnswer;
          const newaray = [];
          let m: any;
          let ctans = false;
          ddd.forEach((b, i) => {
            if (b == this.myModel) {
              m = true;
              this.CorrectAnserArray.splice(i, 1, true);
            } else {
              m = '';
              this.CorrectAnserArray.splice(i, 1, false);
            }
            newaray.push({
              'response': b,
              'valid': m,
            });
          });
          console.log(this.CorrectAnserArray);
          console.log(newaray);
          this.GetTimeQuestionImagechild = res.response.questionImages;
          this.thumbImageURL = res.response.answerImageUrl;
          this.formGroup.patchValue({
            question: res.response.question,
            languageId: res.response.languageId,
            questionGroupId: res.response.questionGroupId,
            questionImage: res.response.questionImage,
            options: newaray,
            questionTypeId: res.response.questionTypeId,
            correctAnswer: res.response.correctAnswer,
            answerExplanation: res.response.answerExplanation,
            companyId: res.response.companyId,
            parentId: res.response.parentId,
          });
  
        } else {
          this.snackBar.open('Option value receiving null not able to set option value ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
       
        // for option creation patch end


      } else {
        this.snackBar.open('response Status -1 ', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }
  patchCountryData(): any {
    this.jobpostQuestionTestService.getJobPostQuestById(this.parentId)
      .subscribe(res => {
        this.pdata = res;
        if (this.pdata.status == 0) {

          this.GetTimeQuestionImage = this.pdata.response.questionImages;
          // this.LangListLoad(this.pdata.response.options.length);
          // this.addnewOption =  this.pdata.response.options.length;

          for (let b of this.LangList) {
            if (b.languageId == this.pdata.response.languageId) {
              this.selectedLangName = b.languageName;
              this.selectlangIsoCode = b.languageCode;
            }
          }
          
          this.questionGroupId = this.pdata.response.questionGroupId;
          this.questionTypeId = this.pdata.response.questionTypeId;
          this.question = this.pdata.response.question;
          // this.questionTypeList();
          this.questionGroupList(0 ,500 , this.selectlangIsoCode);

          console.log(this.GetTimeQuestionImage)
          this.languageId = this.pdata.response.languageId;
          this.questionGroupId = this.pdata.response.questionGroupId,
            this.questionTypeId = this.pdata.response.questionTypeId;

          // this.formGroup.patchValue({
          //   question: this.pdata.response.question,
          //   languageId: this.pdata.response.languageId,
          //   questionGroupId: this.pdata.response.questionGroupId,
          //   questionImage: this.pdata.response.questionImage,
          //   options: newaray,
          //   questionTypeId: this.pdata.response.questionTypeId,
          //   correctAnswer: this.pdata.response.correctAnswer,
          //   answerExplanation: this.pdata.response.answerExplanation,
          //   companyId: this.pdata.response.companyId,
          //   parentId: this.pdata.response.parentId,
          // });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, error => console.log(error));
  }


  onSubmit(submiType): any {
    this.submitted = true;
    console.log(this.CorrectAnserArray);
    const dddsd = this.CorrectAnserArray.indexOf(true);
    console.log(dddsd);
    this.formGroup.controls.answerExplanation.setValidators(null);
    this.formGroup.controls.answerExplanation.updateValueAndValidity();
    this.formGroup.get('answerExplanation').clearValidators();
    // if (!(this.thumbImageURL == null || this.thumbImageURL == '' || this.thumbImageURL == undefined)) {
    //   this.formGroup.controls.answerExplanation.setValidators(null);
    //   this.formGroup.controls.answerExplanation.updateValueAndValidity();
    //   this.formGroup.get('answerExplanation').clearValidators();
    // }

    if (dddsd >= 0) {
      const crtAnsdd = this.formGroup.value.options[dddsd].response;
      this.formGroup.controls.correctAnswer.setValue(crtAnsdd);
      const dd = this.formGroup.value.options;
      this.oprincustome = [];
      for (let b of dd) {
        this.oprincustome.push({ option: this.strip_tags(b.response, 'b', 'i', 'ul' , 'li' , 'br' , 'div' , 'span' , 'p')  });
        // this.oprincustome.push(b.response);
      }
      this.formGroup.controls.languageId.setValue(this.languageId);
      this.formGroup.controls.questionGroupId.setValue(this.questionGroupId);
      this.formGroup.controls.parentId.setValue(this.parentId);
      this.formGroup.controls.questionTypeId.setValue(this.questionTypeId);
      console.log(this.formGroup.controls.correctAnswer.value)
    } else {
      
     
      console.log(this.formGroup.controls.correctAnswer.value)
      const mm = this.formGroup.controls.correctAnswer.value;
      if (mm == '' || mm == null) {
        this.CorrecTanswerNotSlect = true;
      } else {
        this.CorrecTanswerNotSlect = false;
      }
  
      const d = (mm == '' || mm == null) ? 'Please Select Correct Answer' : 'Please Enter All Details';
      this.snackBar.open(d, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      this.submitted = true;
      this.formGroup.markAllAsTouched();
      // this.scrollToFirstInvalidControl();
      return;
    }
    
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      const requests = {
        requests: this.oprincustome,
      };
      const formData: FormData = new FormData();
      // formData.append('assesmentQuestionId', (this.childId == null || this.childId == undefined || this.childId == '') ? 0 : this.childId);
      // formData.append('question', this.formGroup.value.question);
      // formData.append('languageId', this.formGroup.value.languageId);
      // formData.append('questionGroupId', this.formGroup.value.questionGroupId);
      // formData.append('questionTypeId', this.formGroup.value.questionTypeId);
      // formData.append('opt', JSON.stringify(requests));
      // formData.append('correctAnswer', this.formGroup.value.correctAnswer);
      // formData.append('answerExplanation', this.formGroup.value.answerExplanation);
      // formData.append('companyId', this.formGroup.value.companyId == null ? 0 : this.formGroup.value.companyId);
      // formData.append('parentId', this.formGroup.value.parentId == null ? 0 : this.formGroup.value.parentId);
      if (this.newBlobArray.length > 0) {
        for (let b of this.newBlobArray) {
          formData.append('questionImage', b);
        }
      }

      formData.append('assesmentQuestionId', (this.childId == null || this.childId == undefined || this.childId == '') ? 0 : this.childId);
      formData.append('question',  this.strip_tags(this.formGroup.value.question, 'b', 'i', 'ul' , 'li' , 'br' , 'div' , 'span' , 'p'));
      formData.append('languageId', this.formGroup.value.languageId);
      formData.append('questionGroupId', this.formGroup.value.questionGroupId);
      formData.append('questionTypeId', this.formGroup.value.questionTypeId);
     formData.append('opt', JSON.stringify(requests));
      formData.append('correctAnswer', this.strip_tags(this.formGroup.value.correctAnswer, 'b','strong', 'i', 'ul' , 'li'));
      formData.append('answerExplanation',this.formGroup.value.answerExplanation == null ? this.formGroup.value.answerExplanation :  this.strip_tags(this.formGroup.value.answerExplanation, 'b', 'strong', 'i', 'ul' , 'li'));
      formData.append('companyId', this.formGroup.value.companyId == null ? 0 : this.formGroup.value.companyId);
      formData.append('parentId', this.formGroup.value.parentId == null ? 0 : this.formGroup.value.parentId);
      if (this.fileData2) {
        formData.append('answerImage', this.fileData2);
      }
      // if(this.GetTimeQuestionImage.length > 0){
      //   formData.append('getTimeQuestionImage' , JSON.stringify(this.GetTimeQuestionImage));
      // }
      // 

      console.log(formData);

      if (this.childId) {
        this.jobpostQuestionTestService.editJobPostQuestion(this.childId, formData)
          .subscribe(data => {
            window.scrollTo(0, 0);
            this.images = [];
            this.newBlobArray = [];
            this.GetTimeQuestionImage = [];
            this.oprincustome = [];
            this.CorrectAnserArray = [false, false, false, false, false];
            this.childId = null;
            this.fileData2 = null;
              this.thumbImagePath = null;
              this.thumbImageURL = null;
            this.snackBar.open('Update Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            if (submiType == 0) {
              this.router.navigate(['/listjobTestQuestion']);
            } else {
              this.submitted = false;
              this.formGroup.controls.question.reset();
              this.formGroup.controls.answerExplanation.reset();
              this.formGroup.controls.correctAnswer.reset();
              this.t.reset();
              this.formGroup.reset()
              // this.CorrectAnserArray = [];
              // this.oprincustome= [];
            }

          },
            (err) => {
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
                panelClass: ['error-snackbar']
              });
            }
          );

      } else {
        this.jobpostQuestionTestService.addJobPostQuestion(formData).subscribe((res: any) => {
          console.log(res.response);
          if (res.status == 0) {
            window.scrollTo(0, 0);
            this.images = [];
            this.newBlobArray = [];
            this.GetTimeQuestionImage = [];
            this.oprincustome = [];
            this.childId = null;
            this.fileData2 = null;
              this.thumbImagePath = null;
              this.thumbImageURL = null;
            this.CorrectAnserArray = [false, false, false, false, false];
            this.snackBar.open(' Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });

            if (submiType == 0) {
              if (this.typeSelect == true) {
                this.router.navigate(['/editJobTestCreation/' + res.response]);
                this.getSubQuestionList();
              }
              else {
                this.router.navigate(['/listjobTestQuestion']);
              }

            } else {
              this.getSubQuestionList();
              this.submitted = false;
              this.formGroup.controls.question.reset();
              this.formGroup.controls.answerExplanation.reset();
              this.formGroup.controls.correctAnswer.reset();
              this.CorrectAnserArray = [false, false, false, false, false];
              this.t.reset();
              // this.CorrectAnserArray = [];
              // this.oprincustome= [];
            }
          } else {
            this.snackBar.open(res.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }

        });

      }

    } else {
      this.submitted = true;
      this.formGroup.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      const mm = this.formGroup.controls.correctAnswer.value;
      if (mm == '' || mm == null) {
        this.CorrecTanswerNotSlect = true;
      } else {
        this.CorrecTanswerNotSlect = false;
      }
      const d = (mm == '' || mm == null) ? 'Please Select Correct Answer' : 'Please Enter All Details';
      this.snackBar.open(d, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      return;
    }
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(100),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
    // firstInvalidControl.focus(); //without smooth behavior
  }
  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  firstSpaceRemoveValid(e): any {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }
  optionCreate(e): any {
    console.log(e);
  }
  newOptionadd(): any {
    if (this.addnewOption < 5) {
      this.addnewOption = this.addnewOption + 1;
      console.log(this.addnewOption);
      this.LangListLoad(this.addnewOption);
      this.addBtnHide = false;
    } else {
      this.addBtnHide = true;
    }
    if (this.addnewOption == 5) {
      this.addBtnHide = true;
    } else {
      this.addBtnHide = false;
    }

  }

  onCheckboxChange(e, i) {
    console.log(e);
    console.log(this.addnewOption);

    this.CorrecTanswerNotSlect = false;
    // console.log(this.CorrectAns[i]);
    // this.CorrectAns[i]= e.checked;
    // console.log( this.CorrectAns[i]);
    if (e.checked) {
      this.CorrectAnserArray.splice(i, 1, true);
      let m = 0;
      for (m = 0; m < 5; m++) {
        if (m != i) {
          this.CorrectAnserArray.splice(m, 1, false);
        }
      }
      // this.CorrectAns[i] =e.target.checked;
      // this.days.push(e.target.value);
    } else {
      // this.days.splice(this.rightsaName[i].value, 1);
    }

    console.log(this.CorrectAnserArray);

  }

  testImageSelct(event, files) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.testImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      // tslint:disable-next-line: variable-name
      reader.onload = (_event) => {
        this.questionImage = reader.result;
      };
    }
    this.newimgeuplod = true;

    console.log(this.fileData);
  }

  testImageReset(): any {
    this.questionImage = '';
    this.myInputVariable.nativeElement.value = '';
  }

  isComprehnsive($event): any {
    if ($event.checked == true) {
      let c = confirm("Are you sure add comprehnsive question??");
      if (c === true) {
        this.router.navigate(['/listjobTestQuestion']);
      } else {
        // this.valueRadio = false;
        // this.valueRadio = null;
      }
    }
    else {

    }
  }

  onSubmit1(val): any {
    if (val === 0) {
      this.typeSelect = true;
      this.onSubmit(val);
    }
    else {
      this.typeSelect = false;
    }
  }
  b64toBlob(dataURI): any {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    console.log(dataURI)
    // [blob], this.dat.imageName, {type: 'image/png'}
    return new File([ia], "comprehsinve.png", { type: 'image/png' });

    // return new Blob([ab], "mrunal.png", { type: 'image/jpeg' });
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          console.log(event);
          this.images.push(event.target.result);
          console.log(this.b64toBlob(event.target.result))
          this.newBlobArray.push(this.b64toBlob(event.target.result))
          // console.log(this.newBlobArray)
          this.formGroup.patchValue({
            questionImage: this.newBlobArray,
          });
        }
        console.log(this.newBlobArray)
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  removeGetImage(id, i) {
    if (confirm('Are you sure Want to delete Question Image ?')) {
      this.jobpostQuestionTestService.deleteQuestionImage(id).subscribe((res: any) => {
        if (res.status == 0) {
          this.snackBar.open('Delete successfully ', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.GetTimeQuestionImage = this.GetTimeQuestionImage.filter(d => {
            if (id != d.id) {
              return d;
            }
          });
        } else {
          this.snackBar.open(res.response, '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
      }, (error: any) => {
        this.snackBar.open(error.error.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      })
    }
  }
  deleteQuestion(Id) {
    if (confirm("Are you sure you want to delete Question ?")) {
      this.jobpostQuestionTestService.deleteQuestionById(Id).subscribe((res: any) => {
        if (res.status == 0) {
          this.snackBar.open('Delete successfully ', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.getSubQuestionList();
        } else {
          this.snackBar.open(res.response, '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
        }
      }, (error: any) => {
        this.snackBar.open(error.error.response, '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      })
    }
  }

  removeAtt(e) {

    document.querySelectorAll('.mrin .angular-editor-textarea div').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea table').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    });
    document.querySelectorAll('.mrin .angular-editor-textarea tr').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea td').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea p').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
    })
    document.querySelectorAll('.mrin .angular-editor-textarea span').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');

    })
    document.querySelectorAll('.mrin .angular-editor-textarea img').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('src');
      // item.remove();      
    })
    document.querySelectorAll('.mrin .angular-editor-textarea mat-checkbox').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea mat-form-field').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea input').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea select').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();

    })
    document.querySelectorAll('.mrin .angular-editor-textarea button').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea label').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    document.querySelectorAll('.mrin .angular-editor-textarea a').forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('href');
      // item.remove();
    })
    document.querySelectorAll(".mrin .angular-editor-textarea div, .mrin .angular-editor-textarea label, .mrin .angular-editor-textarea a ,.mrin .angular-editor-textarea button ,.mrin .angular-editor-textarea select , .mrin .angular-editor-textarea input , .mrin .angular-editor-textarea mat-checkbox , .mrin .angular-editor-textarea span ").forEach(item => {
      // item.remove()
      item.removeAttribute('style');
      item.removeAttribute('class');
      // item.remove();
    })
    // this.jobDescriptionFromGroup.controls[formcontrolname].setValue(pastedText);
    // return d;
  }
  deleteOption(i) {
    this.t.removeAt(i);
    this.addnewOption = this.addnewOption - 1;
    if (this.addnewOption == 5) {
      this.addBtnHide = true;
    } else {
      this.addBtnHide = false;
    }
  }
  LangChange(e){
    this.selectlangIsoCode = e;
    this.questionGroupList(0, 500 , e);
  }
  AnswerfileChangeEvent(event, files) {
    if (event.target.files.length > 0) {
      this.fileData2 = event.target.files[0] as File as File;
      const reader = new FileReader();
      this.thumbImagePath = files;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.thumbImageURL = reader.result;
      };
    }
    this.newthumbImageupload = true;
  }
  thumbImageReset() {
    this.thumbImageURL = '';
    this.myInputVariable2.nativeElement.value = '';
  }
  strip_tags(html, ...args) {
    return html.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
      return args.includes(tag) ? '<' + endMark + tag + '>' :'';
    }).replace(/<!--.*?-->/g, '');
  }

}
