<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;">
  <div class="page-header px-0">
    <h3 class="page-title">Manage Personality Selection List</h3>
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
      <li class="breadcrumb-item active" [routerLink]="['/editPersonalityCategoryValues']">Manage Personality Selection
        List</li>
    </ol>
  </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
  <div class="card">
    <div class="c_table">
      <div class="mat-elevation-z8">
        <div class="d-flex table-responsive p-3">
          <div class="mr-2">
            <div class="btn btn-primary btn-sm rounded-lg fs-13">
              <span [routerLink]="['/addPersonalityCategoryValues']">
                <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                Add</span>
            </div>
          </div>
          <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
            <input class="form-control" (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
          </div>
        </div>
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="srno">
            <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
            <td mat-cell *matCellDef="let element;let j = index">
              <!-- <span *ngIf="page == 0" > {{j + 1}}</span>
              <span *ngIf="page != 0" >{{((page * size) +   (j + 1))}}</span> -->
              {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
            </td>
          </ng-container>

          <div>
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
              <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container>

            <ng-container matColumnDef="jobCategoryName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Category Name</th>
              <td mat-cell *matCellDef="let element">{{element.jobCategoryName}}</td>
            </ng-container>

            <ng-container matColumnDef="jobSubCategoryName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub Job category Name</th>
              <td mat-cell *matCellDef="let element">{{element.jobSubCategoryName}}</td>
            </ng-container>


            <ng-container matColumnDef="o">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> O</th>
              <td mat-cell *matCellDef="let element">{{element.o}}</td>
            </ng-container>

            <ng-container matColumnDef="c">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>C</span>

              </th>
              <td mat-cell *matCellDef="let element">{{element.c}}</td>
            </ng-container>

            <ng-container matColumnDef="e">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>E</span>

              </th>
              <td mat-cell *matCellDef="let element">{{element.e}}</td>
            </ng-container>

            <ng-container matColumnDef="a">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>A</span>

              </th>
              <td mat-cell *matCellDef="let element">{{element.a}}</td>
            </ng-container>

            <ng-container matColumnDef="n">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>N</span>

              </th>
              <td mat-cell *matCellDef="let element">{{element.n}}</td>
            </ng-container>


          </div>


          <ng-container matColumnDef="actions">
            <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
              <div class="d-flex justify-content-center">
                <span class="btn btn-sm btn-primary rounded-lg mar15"
                  [routerLink]="['/editPersonalityCategoryValues', element.id]">
                  <img src="../../../../assets/img/eye.svg" alt="eye">
                </span>
                <span class="btn btn-sm btn-danger rounded-lg" (click)="deleteUsers(element.id)"><i
                    class="fas fa-trash"></i></span>
              </div>


            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons>
        </mat-paginator>
        <!-- <div class="d-flex justify-content-end align-items-center">
          <div class="c_page"><b>Current Page: </b> {{page}} </div>
          <div class="t_recd" ><b>Total Records: </b>  {{totalrecordLength}}</div>
          <ul class="pagination">
            <li (click)="firstpage()" ><i class="fas fa-angle-double-left" ></i></li>
            <li (click)="prevpage()" ><i class="fas fa-arrow-left"></i></li>
            <li (click)="nextpage()" ><i class="fas fa-arrow-right" ></i> </li>
            <li (click)="lastpage()"> <i class="fas fa-angle-double-right"></i></li>
        </ul>
        </div> -->





      </div>


    </div>
  </div>
</div>