import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import { FormArray,FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {LanguageService} from 'src/app/service/language/language.service';

@Component({
  selector: 'app-add-update-addon-plan',
  templateUrl: './add-update-addon-plan.component.html',
  styleUrls: ['./add-update-addon-plan.component.scss']
})
export class AddUpdateAddonPlanComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  hide = true;
  LangList:[];
  formGroup: FormGroup;
  data: any;
  pdata:any; 
  id:any;
  submitted = false;
  status: boolean;
  radioValue:Boolean;
  isActive: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService :MasterDataService,
    private route: ActivatedRoute,
    private router: Router,
    public langService :LanguageService,
  ) { }

  ngOnInit(): void {
  this.id = this.route.snapshot.params['id'];
  this.initializeForm();
  }
  get f() { return this.formGroup.controls; }
  get t() { return this.f.planList as FormArray; }


  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      planId:[0 ,Validators.required],
      id:[0 ,Validators.required],
      planName: [ '' , Validators.required],
      planDescription: [ '' , Validators.required],
      monthlyCost: [ '' , Validators.required]

    });     
    if(this.id){
      this.patchPlanData();
    }  
  }
  // get data from Plan ID
 patchPlanData(){
    this.masterService.getAddonPlanDetailsById(this.id)
      .subscribe(res => {
        this.data = res;
        if(this.data.status == 0 ){
             var data = this.data.response;                
            this.formGroup.patchValue({
            value:this.data.response.value, 
            planId:this.data.response.planId,
            id:this.data.response.id, 
            planName: this.data.response.planName ,
            planDescription: this.data.response.planDescription ,
            monthlyCost :this.data.response.monthlyCost          
          }); 
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
        console.log(this.id);
      }, error => console.log(error));
  }

  firstSpaceRemoveValid(e){
    if (e.which === 32 &&  e.target.selectionStart === 0) {
      return false;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.formGroup.valid) {   
      if (this.id){
        this.masterService.updateAddonPlanDeatils(this.id, this.formGroup.value)
          .subscribe((data:any) => {
              this.snackBar.open(' Addon Plan Details Update Successfully !!', '', {
                duration: 3000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/addonPlanList']);
            },
            (err) => {
              this.snackBar.open(err.error.message,'',{
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['error-snackbar']
              });
            }
          );
      } else{
        this.masterService.createAddonPlan(this.formGroup.value).subscribe(res => {
          this.data=res;
          if(this.data.status ==  0 ) {
            this.snackBar.open(' Addon Plan Created  Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/addonPlanList']);
          }
            else {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']});
          }
        });
      }
    } else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }
}