import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../service/masterData/master-data.service";
import {LanguageService} from "../../../service/language/language.service";

@Component({
  selector: 'app-list-template',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.scss']
})
export class ListTemplateComponent implements OnInit {

  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  categoryList=[];
  private data: any;
  seachcatogry: FormGroup;
  appCtrl: FormControl;
  filteredApps: Observable<any[]>;
  apps = [];
  selectproduct: any;
  page : number;
  size: number;
  totalpageLenght: number;
  totalrecordLength: number;
  selectedId: any;
  jobCategoryId:any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selectLang: any;
  LangList: [];

  constructor(private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private masterData :MasterDataService,
              private langService:LanguageService ) {
  }


  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.LangList = JSON.parse(this.langService.getLngList());
    this.displayedColumns = [ 'srno', 'templateName', 'typeOfTemplate', 'subject', 'role', 'isActive', 'actions'];
    this.selectLang = 'en';
    this.tabelLoad();
  }

  LangChange(e): any{
    this.selectLang = e.value;
    this.tabelLoad();
  }

  tabelLoad(){
    this.masterData.getAllTemplateList(this.page,this.size,this.selectLang).subscribe((res:any) =>{
      this.data = res;
      if(this.data.response){
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    })


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  delete(rowid: number) {
    if (rowid > -1) {
      this.dataSource.data.splice(rowid, 1);
      this.dataSource._updateChangeSubscription();
    }
  }
  oldpermissionChange (e , element) {
    if (element.isActive == true) {
      if (confirm('Deactive Are you sure')) {
      } else {
        e.source.checked = true;
      }
    } else {
      if (confirm('Active Are you sure')) {

      } else {
        e.source.checked = false;
      }
    }

  }
  filterApps(name: any) {
    // console.log(name);
    // console.log(this.apps);
    const filterValue = name.toLowerCase();
    if(name == ''){
      return this.apps;
    } else {
      return  this.apps.filter(p => p.categoryName.toLowerCase().includes(filterValue));
    }

    // return this.apps.filter(p =>  p.productName.indexOf(name.toLowerCase()) === 0);
  }
  onEnter(e: any) {
    // console.log(e);
    this.selectproduct = e.categoryName;
    this.selectedId = e.jobCategoryId;
    // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
  }
  search(){

    this.router.navigate(['editjobcategory',this.selectedId]);

  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  firstpage(){
    // this.tabelLoad(0);
  }
  lastpage(){
    // this.tabelLoad(this.totalpageLenght);
  }
  prevpage(){
    const d = this.page - 1;
    if (d > 0){
      // this.tabelLoad(d);
    }
  }
  nextpage(){
    const d = this.page + 1;
    if ( d < this.totalpageLenght){
      // this.tabelLoad(d);
    }
  }


  deleteTemplate(id) {
    if (confirm('Are you sure delete template?')) {
      this.masterData.deleteTemplate(id)
        .subscribe(res => {
          this.data = res;
          if (this.data.status == 0) {
            this.tabelLoad();
            this.snackBar.open('Template deleted succesfully!!!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
          } else {
            this.snackBar.open('response Status -1 ', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }, error => console.log(error));
    }else{

    }
  }

}
