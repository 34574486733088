        <!--Page Header-->
        <div class="col-sm-12" style="display: inline-block;" >
            <div class="page-header px-0">
              <h3 class="page-title">List Of Coupon</h3>
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item active">List Of Coupon</li>
              </ol>
            </div>
          </div>       
          <!--Page Header-->
          <div class="col-sm-12">
   <div class="card">
            <div class="c_table">
              <div class="mat-elevation-z8">
                <div class="d-flex table-responsive p-3">
                  <div class="mr-2">
                    <div class="btn btn-primary btn-sm rounded-lg fs-13">
                      <span   [routerLink]="['/addDiscount']" >
                       <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                        Add</span>
                    </div>
                  </div>                     
                  <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                    <input class="form-control" 
                    (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                  </div>
                </div>
              
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                   <ng-container matColumnDef="couponCode">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Coupon Code</th>
                      <td mat-cell *matCellDef="let element">{{element.couponCode}}</td>
                    </ng-container>
    
                    <ng-container matColumnDef="couponDescription">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description</th>
                      <td mat-cell *matCellDef="let element">{{element.couponDescription}}</td>
                    </ng-container>
    
                    <ng-container matColumnDef="timesCanbeUsed">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Times Can be Used </th>
                      <td mat-cell *matCellDef="let element">{{element.timesCanbeUsed}}</td>
                    </ng-container>
                    <ng-container matColumnDef="couponType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Coupon Type </th>
                        <td mat-cell *matCellDef="let element">{{element.couponType}}</td>
                      </ng-container>
                      <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date </th>
                        <td mat-cell *matCellDef="let element">{{element.startDate | date:'longDate'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="autoExpiryDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date </th>
                        <td mat-cell *matCellDef="let element">{{element.autoExpiryDate | date:'longDate'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="couponValue">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Value </th>
                        <td mat-cell *matCellDef="let element">                            
                            <span *ngIf=" element.couponType == 'FIXED' "><i class="fas fa-dollar-sign"></i></span>
                            {{element.couponValue}} <span *ngIf=" element.couponType == 'PERCENTAGE' ">%</span></td>
                      </ng-container>
    

                  <ng-container matColumnDef="isActive">
                    <th width="80px" mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                                <span >
                                  <mat-slide-toggle (change)="statusChange($event,element)" name="isActive"
                                                    [checked]="element.isActive"></mat-slide-toggle>
                                </span>
    
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                      <div class="d-flex justify-content-center">
                                  <span class="btn btn-sm btn-primary rounded-lg mar15"
                                        [routerLink]="['/editDiscount', element.couponId]">
                                  <i class="fas fa-eye"></i></span>
                                  <span class="btn btn-sm btn-primary rounded-lg mar15"
                              (click)="delete(element.couponId)">
                        <i class="fas fa-trash"></i></span>
                      </div>  
    
                    </td>
                  </ng-container>
    
    
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
    
                <mat-paginator #paginatorRef (page)="onChangePage($event)" [length]="totalpageLenght" [pageSize]="30"
                [pageSizeOptions]="[30]" showFirstLastButtons></mat-paginator>
                <!-- <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator> -->
    
              </div>
            </div>  
    </div>
    </div>
    