import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {CandidateService} from "../../../service/candidateDetails/candidate.service";
import {Options} from "@angular-slider/ngx-slider";


@Component({
  selector: 'app-candidate-personality-test-result',
  templateUrl: './candidate-personality-test-result.component.html',
  styleUrls: ['./candidate-personality-test-result.component.scss']
})
export class CandidatePersonalityTestResultComponent implements OnInit {
  currentPauseCount: any;
  pausemessgae: string;
  ainfo: any;
  einfo: any;
  cinfo: any;
  oinfo: any;
  ninfo: any;
  operationDiv:boolean = false;
  ominValue: number = 1;
  omaxValue: number = 1;
  cminValue: number = 1;
  cmaxValue: number = 1;
  eminValue: number = 1;
  emaxValue: number = 1;
  nminValue: number = 1;
  nmaxValue: number = 1;
  aminValue: number = 1;
  amaxValue: number = 1;
  minValue: number = 1;
  questionId: number = 1;
  questionName:any;
  candiadateId: any;
  testStart: boolean =  true;
  selectedAnswer: any;
  personalityCategoryId: any;
  timeLeft: number = 900;
  interval;
  subscribeTimer: any;
  options: Options = {
    floor: 1,
    ceil: 5,
    readOnly: true,
  }
  selectedTab = {id: 0 , tabName : 'Description'};
  testType: any;
  amessage: any;
  cmessage: any;
  emessage: any;
  nmessage: any;
  omessage: any;
  timerAlert: boolean = false;
  alertTiming: number = 0;
  testDisable: boolean = false;

  constructor(public dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute,
              public  candidateService : CandidateService,
             ) { }

  ngOnInit(): void {
    this.candiadateId = this.route.snapshot.params['id'];
    this.getResult();
  }
  getResult(): any{
    this.candidateService.getPersonalityResult(this.candiadateId).subscribe( (res:any) =>{
      if(res.status == 0){
        this.testDisable=true;
        this.testType = 'completed' ;
        this.ominValue =  res.response.ominRange;
        this.omaxValue = res.response.oresult;
        this.omessage = res.response.oresultDesc;
        this.oinfo = res.response.odesc;

        this.cminValue = res.response.cminRange ;
        this.cmaxValue = res.response.cresult;
        this.cmessage = res.response.cresultDesc;
        this.cinfo = res.response.cdesc;

        this.eminValue = res.response.eminRange ;
        this.emaxValue = res.response.eresult;
        this.emessage = res.response.eresultDesc;
        this.einfo = res.response.edesc;

        this.nminValue = res.response.nminRange;
        this.nmaxValue = res.response.nresult;
        this.nmessage = res.response.nresultDesc;
        this.ninfo = res.response.ndesc;

        this.aminValue =  res.response.aminRange;
        this.amaxValue = res.response.aresult;
        this.amessage = res.response.aresultDesc;
        this.ainfo = res.response.adesc;

      } else{
        alert(res.response);
      }
    }, (error: any) =>{
      alert(error.error.response);
    });
  }


}
