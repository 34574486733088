<!--Page Header-->
<div class="col-sm-12" style="display: inline-block;" >
  <div class="page-header px-0">
    <h3 class="page-title">List Of Job Board</h3>
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
      <li class="breadcrumb-item active">List Of Job Board</li>
    </ol>
  </div>
</div>
<!--Page Header-->
<div class="col-sm-12">
  <div class="card">
    <div class="c_table">
      <div class="mat-elevation-z8">
        <div class="d-flex table-responsive p-3">
          <div class="mr-2">
            <div class="btn btn-primary btn-sm rounded-lg fs-13">
                    <span   [routerLink]="['/addJobBoard']" >
                     <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                      Add</span>
            </div>
          </div>
          <div class="mr-2 col-sm-2">
            <mat-select [ngModel]="selectLang"
            (selectionChange)="LangChange($event)"    
            class="form-control" >
              <mat-option *ngFor="let r of langList"      
                          [value]="r.languageCode" [aria-label]="r.languageCode">
              {{r.languageName}}
              </mat-option>
          </mat-select>
          </div>
          <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
            <input class="form-control"
                   (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
          </div>
        </div>

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="srno">
            <th width="60px" mat-header-cell *matHeaderCellDef> Sr.No.</th>
            <td mat-cell *matCellDef="let element;let j = index">
              {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
            </td>
          </ng-container>

          <div>

            <ng-container matColumnDef="jobBoardName">
              <th width="150px" mat-header-cell *matHeaderCellDef mat-sort-header>Job Board Name</th>
              <td mat-cell *matCellDef="let element">{{element.jobBoardName}}</td>
            </ng-container>

            <ng-container matColumnDef="jobBoardDescription">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Board Description </th>
              <td mat-cell *matCellDef="let element" [innerHTML]="element.jobBoardDescription"></td>
            </ng-container>

          </div>
          <div>
            <ng-container matColumnDef="planPriceA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>USD($)</th>
              <td mat-cell *matCellDef="let element">{{element.currency[0].symbol}}{{element.currency[0].value}}</td>
            </ng-container>
            <ng-container matColumnDef="planPriceB">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>GBP(£) </th>
              <td mat-cell *matCellDef="let element">{{element.currency[1].symbol}}{{element.currency[1].value}}</td>
            </ng-container>
            <ng-container matColumnDef="planPriceC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>EUR(€)</th>
              <td mat-cell *matCellDef="let element">{{element.currency[2].symbol}}{{element.currency[2].value}}</td>
            </ng-container>
            <ng-container matColumnDef="planPriceD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>PLN(zł)</th>
              <td mat-cell *matCellDef="let element">{{element.currency[3].symbol}}{{element.currency[3].value}}</td>
            </ng-container>
          </div>
          <ng-container matColumnDef="isActive">
            <th width="70px" mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              <span>
              <mat-slide-toggle name="isActive"  (change)="changeStatus($event,element)" [checked]="element.isActive"></mat-slide-toggle>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
              <div class="d-flex justify-content-center">
                          <span class="btn btn-sm btn-primary mar15"
                                routerLink="/editJobBoard/{{element.addonJobBoardId}}">
                          <i class="fas fa-user-edit"></i></span>
                <span  class="btn btn-sm btn-danger" (click)="deleteJobBoardDetails(element.addonJobBoardId)"><i
                  class="fas fa-trash"></i></span>

              </div>


            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!-- <div class="d-flex justify-content-end align-items-center">
          <div class="c_page"><b>Current Page: </b> {{page}} </div>
          <div class="t_recd" ><b>Total Records: </b>  {{totalrecordLength}}</div>
          <ul class="pagination">
            <li (click)="firstpage()" ><i class="fas fa-angle-double-left" ></i></li>
            <li (click)="prevpage()" ><i class="fas fa-arrow-left"></i></li>
            <li (click)="nextpage()" ><i class="fas fa-arrow-right" ></i> </li>
            <li (click)="lastpage()"> <i class="fas fa-angle-double-right"></i></li>
        </ul>
        </div> -->


        <mat-paginator  #paginatorRef [pageSize]="50" [pageSizeOptions]="[50, 100, 200, 400]" showFirstLastButtons></mat-paginator>

      </div>
    </div>
  </div>
</div>
