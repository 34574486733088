<div class="container-fluid my-cv">
  <div class="row">
    <p class="common-heading"></p>
    <br>
    <!-- new setup  end-->
    <div>
      <div>
        <div class="per-tab3">
          <p class="l1">Candidate Personality Analysis</p>
          <div class="content">
            <div class="row mr-t10">
              <div class="col-sm-1">
                <div class="cir-div background-blue">O</div>
              </div>
              <div class="col-sm-5">
                <div class="left-div">
                  <p>Openness
                    <img class="question-icon"
                         matTooltipClass='red-tooltip'
                         [matTooltip]="oinfo"
                         matTooltipPosition="above" src="../../../../assets/img/question_circle.svg" alt="logo">
                  </p>
                    <span>
                      {{omessage}}
                    </span>
                </div>
              </div>
              <div class="col-sm-6 p-l0">
                <div class="right-div">
                  <div class="left-txt">
                    <p>  Practical </p>
                    <p>  Conventional </p>
                    <p>  Prefers Routine</p>
                  </div>
                  <div class="slider-div">
                    <ngx-slider [(value)]="omaxValue" [options]="options"></ngx-slider>
                  </div>
                  <div class="right-txt">
                    <p> Curious</p>
                    <p> Wide Interest</p>
                    <p> Prefers Variety</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mr-t30">
              <div class="col-sm-1">
                <div class="cir-div bg-green">C</div>
              </div>
              <div class="col-sm-5">
                <div class="left-div border-green">
                  <p class="color-green">Conscientiousness
                    <img class="question-icon"
                         [matTooltip]="cinfo"
                         matTooltipClass='red-tooltip'
                         matTooltipPosition="above" src="../../../../assets/img/question_circle.svg" alt="logo">
                  </p>
                  <span>{{cmessage}}</span>
                </div>
              </div>
              <div class="col-sm-6 p-l0">
                <div class="right-div">
                  <div class="left-txt">
                    <p> Impulsive </p>
                    <p> Careless </p>
                    <p> Disorganised </p>
                  </div>
                  <div class="slider-div green-slider">
                    <ngx-slider [(value)]="cmaxValue"
                                [options]="options"></ngx-slider>
                  </div>
                  <div class="right-txt">
                    <p>Dependable</p>
                    <p>Careful </p>
                    <p>Organised</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mr-t30">
              <div class="col-sm-1">
                <div class="cir-div bg-orange">E</div>
              </div>
              <div class="col-sm-5">
                <div class="left-div border-orange">
                  <p class="color-orange">Extroversion
                    <img class="question-icon"
                         [matTooltip]="einfo"
                         matTooltipClass='red-tooltip'
                         matTooltipPosition="above" src="../../../../assets/img/question_circle.svg" alt="logo">
                  </p>
                  <span>
                      {{emessage}}
                  </span>
                </div>
              </div>
              <div class="col-sm-6 p-l0">
                <div class="right-div">
                  <div class="left-txt">
                    <p>Quiet</p>
                    <p>Reserved</p>
                    <p>Withdrawn</p>
                  </div>
                  <div class="slider-div orange-slider">
                    <ngx-slider [(value)]="emaxValue"
                                [options]="options"></ngx-slider>
                  </div>
                  <div class="right-txt">
                    <p>Outgoing </p>
                    <p>Sociable</p>
                    <p>Adventurous</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mr-t30">
              <div class="col-sm-1">
                <div class="cir-div bg-violet">A</div>
              </div>
              <div class="col-sm-5">
                <div class="left-div border-violet">
                  <p class="color-violet">Agreeableness
                    <img class="question-icon"
                         [matTooltip]="ainfo"
                         matTooltipClass='red-tooltip'
                         matTooltipPosition="above" src="../../../../assets/img/question_circle.svg" alt="logo">
                  </p>
                  <span>
                      {{amessage}}
                  </span>
                </div>
              </div>
              <div class="col-sm-6 p-l0">
                <div class="right-div">
                  <div class="left-txt">
                    <p>Critical</p>
                    <p>Cynical</p>
                    <p>Suspicious</p>
                  </div>
                  <div class="slider-div violet-slider">
                       <ngx-slider [(value)]="amaxValue"
                                [options]="options"></ngx-slider>
                  </div>
                  <div class="right-txt">
                    <p>Helpful</p>
                    <p>Trusting</p>
                    <p>Empathetic</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mr-t30">
              <div class="col-sm-1">
                <div class="cir-div bg-dark-orange">N</div>
              </div>
              <div class="col-sm-5">
                <div class="left-div border-dark-orange">
                  <p class="color-dark-orange">Neuroticism
                    <img class="question-icon"
                         [matTooltip]="ninfo"
                         matTooltipClass='red-tooltip'
                         matTooltipPosition="above"
                         src="../../../../assets/img/question_circle.svg" alt="logo">
                  </p>
                  <span>
                    {{nmessage}}
                  </span>
                </div>
              </div>
              <div class="col-sm-6 p-l0">
                <div class="right-div">
                  <div class="left-txt">
                    <p>Calm</p>
                    <p>Even Tempered</p>
                    <p>Secure</p>
                  </div>

                  <div class="slider-div dark-orange-slider">
                     <ngx-slider [(value)]="nmaxValue"
                                [options]="options"></ngx-slider>
                  </div>
                  <div class="right-txt">
                    <p>Anxious</p>
                    <p>Irritable</p>
                    <p>Vulnurable</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
