        <!--Page Header-->
        <div class="col-sm-12" style="display: inline-block;" >
            <div class="page-header px-0">
              <h3 class="page-title">List Of Zone</h3>
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
                <li class="breadcrumb-item active">List Of Zone</li>
              </ol>
            </div>
          </div>
          <!--Page Header-->
  
    <div class="col-sm-12">
   <div class="card">
          <div class="c_table">
            <div class="mat-elevation-z8">
              <div class="d-flex table-responsive p-3">
                <div class="mr-2">
                  <div class="btn btn-primary btn-sm rounded-lg fs-13">
                    <span   [routerLink]="['/addZoneData']" >
                     <img src="../../../../assets/img/plus-circle-outline.svg" alt="add">
                      Add</span>
                  </div>
                </div>
                <!-- <div class="mr-2 col-sm-2">
                  <mat-select [ngModel]="selectLang"
                  (selectionChange)="LangChange($event)"
                  class="form-control" >
        <mat-option *ngFor="let r of LangList"
                    [value]="r.languageCode" [aria-label]="r.languageCode">
          {{r.languageName}}
        </mat-option>
      </mat-select>
                </div>  -->
                 <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                  <input class="form-control"
                  (keyup)="applyFilter($event.target.value)" placeholder="Search Here">
                </div> 
              </div>
  
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="srno">
                  <th width="80px" mat-header-cell *matHeaderCellDef mat-sort-header> Sr.No.</th>
                  <td mat-cell *matCellDef="let element;let j = index">
                    <!-- <span *ngIf="page == 0" > {{j + 1}}</span>
                    <span *ngIf="page != 0" >{{((page * size) +   (j + 1))}}</span> -->
                    {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (j + 1) }}
                  </td>
                </ng-container>
  
                <div>
  
                  <ng-container matColumnDef="zoneName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Zone Name</th>
                    <td mat-cell *matCellDef="let element">{{element.zoneName}}</td>
                  </ng-container>
  
                  <ng-container matColumnDef="countryName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Country  Name</th>
                    <td mat-cell *matCellDef="let element">{{element.country.countryName}}</td>
                  </ng-container>
   
                </div>
  
                <ng-container matColumnDef="actions">
                  <th width="100px" mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row ;let j = index;let element" class="text-center">
                    <div class="d-flex justify-content-center">
                        <span class="btn btn-sm btn-primary rounded-lg"
                              [routerLink]="['/editZoneData', element.zoneId]">
                        <i class="fas fa-pencil-alt"></i></span>
                        <span class="btn btn-sm btn-primary rounded-lg mar15"
                        (click)="delete(element.countryId)">
                        <i class="fas fa-trash"></i></span>
                    </div>
                  </td>
                </ng-container>
   
  
               <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <!-- <div class="d-flex justify-content-end align-items-center">
                <div class="c_page"><b>Current Page: </b> {{page}} </div>
                <div class="t_recd" ><b>Total Records: </b>  {{totalrecordLength}}</div>
                <ul class="pagination">
                  <li (click)="firstpage()" ><i class="fas fa-angle-double-left" ></i></li>
                  <li (click)="prevpage()" ><i class="fas fa-arrow-left"></i></li>
                  <li (click)="nextpage()" ><i class="fas fa-arrow-right" ></i> </li>
                  <li (click)="lastpage()"> <i class="fas fa-angle-double-right"></i></li>
              </ul>
              </div> -->
  
  
              <mat-paginator  #paginatorRef [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 200, 400]" showFirstLastButtons></mat-paginator>
  
            </div>
  
  
          </div>
    </div>
  </div>
  