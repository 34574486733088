import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MasterDataService} from "../../../../service/masterData/master-data.service";
import {map, startWith} from "rxjs/operators";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LanguageService } from 'src/app/service/language/language.service';
import { MatDialog } from '@angular/material/dialog';
import {CommonConfirmPopupComponent} from 'src/app/component/common-confirm-popup/common-confirm-popup.component';


@Component({
  selector: 'app-list-location',
  templateUrl: './list-location.component.html',
  styleUrls: ['./list-location.component.scss']
})
export class ListLocationComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  accessListArray: any;
  userLevel: any;
  private data: any;
  seachcatogry: FormGroup;
   appCtrl: FormControl;
   filteredApps: Observable<any[]>;
   apps = [];
   selectproduct: any;
   page : number;
   size: number;
   totalpageLenght: number;
   totalrecordLength: number;
   selectedId: any;
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,  
    public dialog: MatDialog,  
      private masterDataservice :MasterDataService) {
        this.appCtrl = new FormControl();
      }

  ngOnInit(): void {
    this.page = 0;
    this.size = 500;
    this.seachcatogry = this.formBuilder.group({
      appCtrl: new FormControl(),
    });
    this.displayedColumns = ['srno', 'locationCountry','locationState' ,'name','isActive','actions'];
    this.tabelLoad(this.page);
  }

  tabelLoad(page){
    this.masterDataservice.getAllLocation(page, this.size).subscribe( res =>{
      this.data =res;
      if(this.data.status == 0 ){
        this.totalrecordLength = this.data.response.dataSet;
        this.page = this.data.response.number;
        this.totalpageLenght = this.data.response.totalPages;
        this.dataSource = new MatTableDataSource(this.data.response.dataSet);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;        
       
      } else {
        this.snackBar.open('server status -1', '', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    },(error)=>{
      this.snackBar.open(error.error.message, '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    })


  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  delete(rowid: number) {
    if (rowid > -1) {
      this.dataSource.data.splice(rowid, 1);
      this.dataSource._updateChangeSubscription();
   }
  }
  oldpermissionChange (e , element) {
    console.log(element.id);
    if (element.isActive == true) {
        if (confirm('deactive Are you sure')) {
            this.masterDataservice.teamStatuChange(element.locationId).subscribe((data) => {
                // @ts-ignore
              if (data == 'error') {
                    e.source.checked = true;
                    this.snackBar.open('Server Error happened please try later.', '', {
                        duration: 3000,
                        panelClass: ['error-snackbar']
                    });
                } else {
                    e.source.checked = false;
                    this.tabelLoad(this.page);
                    this.snackBar.open('Plan status changed succesfully!!!', '', {
                        duration: 3000,
                        panelClass: ['success-snackbar']
                    });

                }
            });
        } else {
            e.source.checked = true;
        }
    } else {
        if (confirm('active Are you sure')) {
            this.masterDataservice.teamStatuChange(element.id).subscribe((data) => {
                // @ts-ignore
              if (data === 'error') {
                    e.source.checked = false;
                    this.snackBar.open('Server Error happened please try later.', '', {
                        duration: 3000,
                        panelClass: ['error-snackbar']
                    });

                } else {
                    e.source.checked = true;
                    this.tabelLoad(this.page);
                    this.snackBar.open('Plan status changed succesfully!!!', '', {
                        duration: 3000,
                        panelClass: ['success-snackbar']
                    });

                }
            });
        } else {
            e.source.checked = false;
        }
    }

}
filterApps(name: any) {
  const filterValue = name.toLowerCase();
  if(name == ''){
    return this.apps;
  } else {
    return  this.apps.filter(p => p.name.toLowerCase().includes(filterValue));
  }
}
onEnter(e: any) {
  this.selectproduct = e.name;
  this.selectedId = e.locationId;
  // this.seachcatogry.controls.appCtrl.setValue(e.medicalSuppliesId);
}
search(){

  this.router.navigate(['editEmployerPlan',this.selectedId]);

}
keyDownFunction(event) {
  if (event.keyCode === 13) {
    this.search();
  }
}

firstpage(){
  this.tabelLoad(0);
}
lastpage(){
  this.tabelLoad(this.totalpageLenght);
}
prevpage(){
  const d = this.page - 1;
  if (d > 0){
    this.tabelLoad(d);
  }
}
nextpage(){
  const d = this.page + 1;
  if ( d < this.totalpageLenght){
    this.tabelLoad(d);
  }
}

deletePlan(locationId) {
  const dialogRef = this.dialog.open(CommonConfirmPopupComponent, {
    width: '600px',
    height: 'auto',
    data: {
      message: "Are you sure wants to delete?",
      type: 'confirmation'
    },
    panelClass: 'confirmpopup',
  });
  dialogRef.afterClosed().subscribe(res => {
    if (res == true){
      this.masterDataservice.deleteLocation(locationId).subscribe( res =>{
        this.data = res;
        if(this.data.status == 0 ){
          this.snackBar.open('Deleted Successfully !', '', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.tabelLoad(this.page);
        }
      })
    }
  });
}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
