<div class="container-fluid">
  <!--Page Header-->
  <div class="col-sm-12" style="display: inline-block;">
    <div class="page-header px-0">
      <h3 class="page-title">
        <span *ngIf="!viewID">Add New</span>
        <span *ngIf="viewID">Edit</span>
        Personality Selection
      </h3>
      <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><span [routerLink]="['/home']">Dashboard</span></li>
        <li class="breadcrumb-item ">
          <span [routerLink]="['/listPersonalityCategoryValues']">List Of Personality Selection</span>
        </li>
        <li class="breadcrumb-item active">
          <span>
            <span *ngIf="!viewID">Add New</span>
            <span *ngIf="viewID">Edit</span>
            Personality Selection</span>
        </li>
      </ol>
    </div>
  </div>
  <!--Page Header-->
  <div class="card">
    <div class="card-body" style="padding: 0;">

      <div class="" style="padding: 1.25em;">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="form-group row col-sm-12">
              <label class="col-sm-12">Job Category</label>
              <div class="col-sm-12 ">
                <mat-select (openedChange)="myInput.focus()" (closed)="myInput.value='';search(myInput.value)"
                  (selectionChange)="jobcatSelection($event)" [disabled]="viewID"
                  [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobCategoryId').errors }"
                  formControlName="jobCategoryId" required placeholder="Please select job category"
                  class="form-control">
                  <input class="myInput form-control" #myInput focused="'true'" type="text"
                  (keydown)="$event.stopPropagation()"  
                  (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                  <!-- <mat-select-filter [array]="variables" (filteredReturn)="filteredList1 =$event"></mat-select-filter> -->
                  <mat-option *ngFor="let t of jobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                    {{t.displayName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <span *ngIf="!formGroup.get('jobCategoryId').valid && formGroup.get('jobCategoryId').touched">
                    Please select job category </span>
                </mat-error>
                <mat-error *ngIf="JobQuniError == true">
                  <div>Please Select other Category</div>
                </mat-error>
              </div>
            </div>
            <div class="form-group row col-sm-12">
              <label class="col-sm-12">Job Sub Category</label>
              <div class="col-sm-12 ">
                <mat-select (openedChange)="myInputsubcat.focus()"
                  (closed)="myInputsubcat.value='';subcatsearch(myInputsubcat.value)"
                  [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobSubCategoryId').errors }"
                  [disabled]="viewID" formControlName="jobSubCategoryId" required
                  placeholder="Please select job subcategory" class="form-control" [multiple]="!viewID">

                  <input class="myInput form-control" #myInputsubcat focused="'true'" type="text"
                  (keydown)="$event.stopPropagation()"  
                  (keyup)="subcatsearch($event.target.value)" autocomplete="off" placeholder="search">
                  <!-- <mat-select-filter [array]="variables" (filteredReturn)="filteredList1 =$event"></mat-select-filter> -->
                  <mat-option *ngFor="let t of subjobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                    {{t.displayName}}
                  </mat-option>
                </mat-select>

                <!-- <mat-select (openedChange)="myInput.focus()" (closed)="myInput.value='';search(myInput.value)"
                  [ngClass]="{ 'is-invalid': submitted && formGroup.get('jobSubCategoryId').errors }"
                  formControlName="jobSubCategoryId" required placeholder="Please select job subcategory"
                  class="form-control" *ngIf="viewID">
                  <input class="myInput form-control" #myInput focused="'true'" type="text"
                    (keyup)="search($event.target.value)" autocomplete="off" placeholder="search">
                  <mat-option *ngFor="let t of subjobCatList" [value]="t.jobCategoryId" [aria-label]="t.jobCategoryId">
                    {{t.displayName}}
                  </mat-option>
                </mat-select> -->

                <mat-error>
                  <span *ngIf="!formGroup.get('jobSubCategoryId').valid && formGroup.get('jobSubCategoryId').touched">
                    Please select job sub category </span>
                </mat-error>
                <mat-error *ngIf="JobQuniError == true">
                  <div>Please Select other SubCategory</div>
                </mat-error>
              </div>
            </div>

            <div class="form-group row col-sm-12">
              <div class="col-sm-12">
                <label>Openness to Experience : </label>
                <div class="row">
                  
                    <mat-radio-group aria-label="Select an option" formControlName="formopennesValues">
                      <mat-radio-button [value]="firstval" >1-3</mat-radio-button>
                      <mat-radio-button  [value]="secondval">2-4</mat-radio-button>
                      <mat-radio-button  [value]="thirdval">3-5</mat-radio-button>
                    </mat-radio-group>
                    <!-- <div>
                      <input type="radio" id="age1" name="age" [value]="firstval">
                    <label for="age1">1- 3</label>
                    </div>
                    
                    <input type="radio" id="age2" name="age" [value]="secondval">
                    <label for="age2">2 - 4</label><br>
                    <input type="radio" id="age3" name="age" [value]="thirdval">
                    <label for="age3">3 - 5</label>
                  </div> -->

                  <!-- <div *ngFor="let d of openness; let i=index" class="col-sm-1">
                    <label class="custom_checkbox">
                      <input type="checkbox" [value]="d"
                        [disabled]="(opennesValues.length == 3 &&  opennesValues.indexOf(d) < 0) || (i == 4 || i == 3)"
                        [checked]="opennesValues.indexOf(d) >= 0" (change)="onCheckboxChangeOne($event,d)" />
                      <span class="checkmark"></span>
                    </label>
                  </div> -->
                </div>
              </div>

              <div class="col-sm-12">
                <label>Conscientiousness : </label>
                <div class="row">
                  <mat-radio-group aria-label="Select an option" formControlName ="formconscientiousness">
                    <mat-radio-button [value]="firstval" >1-3</mat-radio-button>
                    <mat-radio-button  [value]="secondval">2-4</mat-radio-button>
                    <mat-radio-button  [value]="thirdval">3-5</mat-radio-button>
                  </mat-radio-group>
                  <!-- <div *ngFor="let d of openness; let i=index" class="col-sm-1">
                    <label class="custom_checkbox">
                      <input type="checkbox" [value]="d"
                        [disabled]="(conscientiousness.length == 3 &&  conscientiousness.indexOf(d) < 0 )|| (i == 4 || i == 3)"
                        [checked]="conscientiousness.indexOf(d) >= 0" (change)="onCheckboxChangeTwo($event,d)" />
                      <span class="checkmark"></span>
                    </label>
                  </div> -->
                </div>
              </div>

              <div class="col-sm-12">
                <label>Extroversion : </label>
                <div class="row">
                  <mat-radio-group aria-label="Select an option" formControlName ="formextroversion">
                    <mat-radio-button [value]="firstval" >1-3</mat-radio-button>
                    <mat-radio-button  [value]="secondval">2-4</mat-radio-button>
                    <mat-radio-button  [value]="thirdval">3-5</mat-radio-button>
                  </mat-radio-group>
                  <!-- <div *ngFor="let d of openness; let i=index" class="col-sm-1">
                    <label class="custom_checkbox">
                      <input type="checkbox" [value]="d"
                        [disabled]="(extroversion.length == 3 &&  extroversion.indexOf(d) < 0) || (i == 4 || i == 3)"
                        [checked]="extroversion.indexOf(d) >= 0" (change)="onCheckboxChangeThree($event,d)" />
                      <span class="checkmark"></span>
                    </label>
                  </div> -->
                </div>
              </div>

              <div class="col-sm-12">
                <label>Agreeableness : </label>
                <div class="row">
                  <mat-radio-group aria-label="Select an option" formControlName ="formagreeableness">
                    <mat-radio-button [value]="firstval" >1-3</mat-radio-button>
                    <mat-radio-button  [value]="secondval">2-4</mat-radio-button>
                    <mat-radio-button  [value]="thirdval">3-5</mat-radio-button>
                  </mat-radio-group>
                  <!-- <div *ngFor="let d of openness; let i=index" class="col-sm-1">
                    <label class="custom_checkbox">
                      <input type="checkbox" [value]="d"
                        [disabled]="(agreeableness.length == 3 &&  agreeableness.indexOf(d) < 0 )|| (i == 4 || i == 3)"
                        [checked]="agreeableness.indexOf(d) >= 0" (change)="onCheckboxChangeFour($event,d)" />
                      <span class="checkmark"></span>
                    </label>
                  </div> -->
                </div>
              </div>

              <div class="col-sm-12">
                <label>Neuroticism : </label>
                <div class="row">
                  <mat-radio-group aria-label="Select an option" formControlName ="formneuroticism">
                    <mat-radio-button [value]="firstval" >1-3</mat-radio-button>
                    <mat-radio-button  [value]="secondval">2-4</mat-radio-button>
                    <mat-radio-button  [value]="thirdval">3-5</mat-radio-button>
                  </mat-radio-group>
                  <!-- <div *ngFor="let d of openness; let i=index" class="col-sm-1">
                    <label class="custom_checkbox">
                      <input type="checkbox" [value]="d"
                        [disabled]="(neuroticism.length == 3 &&  neuroticism.indexOf(d) < 0) || (i == 4 || i == 3)"
                        [checked]="neuroticism.indexOf(d) >= 0" (change)="onCheckboxChangeFive($event,d)" />
                      <span class="checkmark"></span>
                    </label>
                  </div> -->
                </div>
              </div>
            </div>

          </div>
          <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
            *ngIf="!viewID">Submit</button>
          <!-- <button (click)="onSubmit('1')" type="submit" class="btn btn-success pd-x-20 mg-t-10 mar15" >Submit & Next</button> -->
          <button (click)="onSubmit('0')" type="submit" class="btn btn-main-primary pd-x-20 mg-t-10"
            *ngIf="viewID">Update</button>
        </form>
        <!-- <div>
              <button mat-raised-button (click)="openContentOne('1')"> go Back content 1</button>
            </div> -->
      </div>

      <!-- *ngIf="firstFormSubmited == true" -->

    </div>

  </div>


</div>