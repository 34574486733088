import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MasterDataService } from "../../../../service/masterData/master-data.service";
import { LanguageService } from 'src/app/service/language/language.service';
import { timestamp } from 'rxjs/operators';

@Component({
  selector: 'app-manage-discount',
  templateUrl: './manage-discount.component.html',
  styleUrls: ['./manage-discount.component.scss']
})
export class ManageDiscountComponent implements OnInit {
  @ViewChild('attachments1') myInputVariable: ElementRef;
  hide = true;
  LangList: [];
  formGroup: FormGroup;
  data: any;
  pdata: any;
  discountId: any;
  id: any;
  submitted = false;
  status: boolean;
  radioValue: Boolean;
  isActive: boolean;
  userData: any;
  element

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private masterService: MasterDataService,
    private route: ActivatedRoute,
    private router: Router,
    public langService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.discountId = this.route.snapshot.params['id'];
    this.id = localStorage.getItem('id')
    this.id = this.route.snapshot.params['id'];

    this.initializeForm();
  }
  get f() { return this.formGroup.controls; }



  // To initialize Form
  initializeForm() {
    this.formGroup = this.fb.group({
      createdBy: [],
      discountId: [0],
      couponCode: ['', Validators.required],
      discountType: ['', Validators.required],
      discountValidFor: ['', Validators.required],
      discountValue: ['', Validators.required],
      validFrom: ['', Validators.required],
      validTo: ['', Validators.required],
      couponPerUser: ['', Validators.required],
      usedCoupon: ['', Validators.required],
      couponDesc: ['', Validators.required],
    });
    if (this.discountId) {
      this.patchDiscountCouponData();
    }
  }
  // get data from Plan ID
  patchDiscountCouponData() {
    this.masterService.getByIdDiscountCoupon(this.id)
      .subscribe(res => {
        this.data = res;
        if (this.data.status == 0) {
          var data = this.data.response;
          this.formGroup.patchValue({
            discountId: this.data.response.discountId,
            couponCode: this.data.response.couponCode,
            discountType: this.data.response.discountType,
            discountValidFor: this.data.response.discountValidFor,
            discountValue: this.data.response.discountValue,
            validFrom: this.data.response.validFrom,
            validTo: this.data.response.validTo,
            couponPerUser: this.data.response.couponPerUser,
            usedCoupon: this.data.response.usedCoupon,
            couponDesc: this.data.response.couponDesc,
          });
        } else {
          this.snackBar.open('response Status -1 ', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        }
        console.log(this.id);
      }, error => console.log(error));
  }

  firstSpaceRemoveValid(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
      return false;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      if (this.discountId) {
        this.masterService.updateDiscountCoupon(this.discountId, this.formGroup.value)
          .subscribe((data: any) => {
            this.snackBar.open(' Addon Plan Details Update Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/coupon-list']);
          },
            (err) => {
              this.snackBar.open(err.error.message, '', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['error-snackbar']
              });
            }
          );
      }
      else {
        var payload = {
          createdBy: this.id,
          discountId: this.formGroup.value.discountId,
          couponCode: this.formGroup.value.couponCode,
          discountType: this.formGroup.value.discountType,
          discountValidFor: this.formGroup.value.discountValidFor,
          discountValue: this.formGroup.value.discountValue,
          validFrom: this.formGroup.value.validFrom,
          validTo: this.formGroup.value.validTo,
          couponPerUser: this.formGroup.value.couponPerUser,
          usedCoupon: this.formGroup.value.usedCoupon,
          couponDesc: this.formGroup.value.couponDesc,
        }
        this.masterService.createDiscountCoupon(payload).subscribe(res => {
          this.data = res;
          if (this.data.status == 0) {
            this.snackBar.open('Coupon Create Successfully !!', '', {
              duration: 3000,
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/coupon-list']);

          }
          else {
            this.snackBar.open(this.data.response, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        });
      }
    }
    else {
      this.snackBar.open('Please Enter All Details', '', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }
}