import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageJobsService {

  constructor(private http: HttpClient) { }

  getAllList(){
    return this.http.get<any>(`${environment.baseurl}/allListJobBoardFree`);
  }
  getById(Id){
    return this.http.get<any>(`${environment.baseurl}/jobBoardsFreeGetById?id=`+Id);
  }
  save(data){
    return this.http.post<any>(`${environment.baseurl}/saveAddonJobBoardFree` , data);
  }
  updateAddonJobBoardFree(id , data){
    return this.http.put<any>(`${environment.baseurl}/updateAddonJobBoardFree?id=`+id , data);
  }
  statusChange(Id){
    return this.http.put<any>(`${environment.baseurl}/changeStatusAddonJobBoardFree?id=`+ Id , '');
  }
  deleteJobfressService(Id){
  return this.http.delete<any>(`${environment.baseurl}/deleteJobBoardFree?id=`+Id);
  }  
}
